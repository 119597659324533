import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

export type Args = {
  token: string;
};

const [useRedeemJoinLink, redeemJoinLink] = createAsyncAction(
  "auth/redeemJoinLink",
  async ({ token }: Args) => {
    return await API.call<string>({
      endpoint: `join-link/${token}/redeem`,
      method: "POST",
      entity: API.Entity.space,
    });
  }
);

export default useRedeemJoinLink;
export { redeemJoinLink };
