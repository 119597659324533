import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

const [useCreateSpace, createSpace] = createAsyncAction(
  "spaces/createSpace",
  async (name: string) => {
    return await API.call<number>({
      endpoint: "spaces",
      method: "POST",
      entity: API.Entity.space,
      data: {
        name: name,
      },
    });
  }
);

export default useCreateSpace;
export { createSpace };
