import { API } from "@thenounproject/lingo-core";
import { SearchQueryFilterOption } from "../types";
import { createQueryAction } from "@actions/actionCreators";

type Data = {
  filters: SearchQueryFilterOption[];
};

const [useAssetLibraryFilters, fetchAssetLibraryFilters] = createQueryAction<void, Data>(
  {
    entity: "search",
    action: "fetchAssetLibraryFilters",
    denormalize: false,
  },
  async () => {
    return await API.call<Data>({
      endpoint: `search/meta?context=library`,
      method: "GET",
    });
  }
);
export default useAssetLibraryFilters;
export { fetchAssetLibraryFilters };
