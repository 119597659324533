import React from "react";
import styled from "styled-components";
import { ChromePicker } from "react-color";

import { Box } from "@thenounproject/lingo-core";

type PickerDirection = "top" | "bottom";

const PickerWrapper = styled(Box).attrs<{ pickerDirection: PickerDirection }>({
  position: "absolute",
  zIndex: 10,
})`
  ${(props: { pickerDirection: PickerDirection }) =>
    props.pickerDirection === "top" && `top: -8px; transform: translateY(-100%);`}
  ${(props: { pickerDirection: PickerDirection }) =>
    props.pickerDirection === "bottom" && `top: 24px;`}
`;

const PickerCloser = styled.div`
  background: transparent;
  border: none;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
`;

type Props = {
  pickerOpen: boolean;
  setPickerClosed: () => void;
  pickerDirection: PickerDirection;
  currentColor: string;
  handleColorSelect: (color: { hex: string }) => void;
};

const ColorPickerComponent: React.FC<Props> = ({
  pickerOpen,
  setPickerClosed,
  pickerDirection,
  currentColor,
  handleColorSelect,
}) => {
  if (!pickerOpen) return null;
  return (
    <>
      <PickerCloser onClick={setPickerClosed} aria-label="picker closer" />
      <PickerWrapper pickerDirection={pickerDirection} aria-label="color picker">
        <ChromePicker color={currentColor} onChangeComplete={handleColorSelect} disableAlpha />
      </PickerWrapper>
    </>
  );
};

export default ColorPickerComponent;
