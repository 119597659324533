import QueryString from "query-string";

import { API } from "@thenounproject/lingo-core";

import { Inspectable } from "@constants/Inspector";
import { downloadFile } from "./useDownloadFile";
import { createAsyncAction } from "./actionCreators";

function getData(inspectables: Inspectable[], portalId: string, isAdmin: boolean) {
  const assetIds = inspectables.map(i => i.asset.id),
    itemIds = inspectables
      .map(i => {
        if (i.item) return i.item.id;
        return null;
      })
      .filter(Boolean);

  const version = inspectables.find(f => f.item)?.item?.version || 0;

  const queryString = QueryString.stringify({
    uuids: isAdmin ? assetIds.join(",") : itemIds.join(","),
    response: "json",
    version,
    portal_uuid: portalId,
  });

  const endpoint = isAdmin ? `assets/download?${queryString}` : `items/download?${queryString}`;

  return {
    endpoint,
    assetIds,
    itemIds,
  };
}

type Args = {
  inspectables: Inspectable[];
  portalId?: string;
  isAdmin?: boolean;
};

const [useDownloadMutlipleAssets, downloadMutlipleAssets] = createAsyncAction(
  "assets/downloadMultiple",
  async ({ inspectables, portalId, isAdmin }: Args, thunkApi) => {
    const { endpoint } = getData(inspectables, portalId, isAdmin);
    const res = await API.call<{ url: string }>({
      endpoint,
      method: "GET",
    });
    const { url } = res.result;
    thunkApi.dispatch(downloadFile(url));
    return res;
  }
);

export default useDownloadMutlipleAssets;
export { downloadMutlipleAssets };
