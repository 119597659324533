import React, { useCallback } from "react";
import { Flex, Button } from "@thenounproject/lingo-core";
import HeaderAvatar from "../HeaderAvatar";
import useNavPoint from "@hooks/useNavPoint";
import useModals, { ModalTypes } from "@redux/actions/useModals";
import useCurrentUser from "@queries/useCurrentUser";

import useShowSuperNav from "./useShowSuperNav";

type Props = {
  button?: React.ReactNode;
  canSearch?: boolean;
};

const MobileSubNav: React.FC<Props> = ({ button, canSearch = true }) => {
  const navPoint = useNavPoint();
  const { user } = useCurrentUser();
  const showSuperNav = useShowSuperNav(navPoint, user);

  const { showModal } = useModals();
  const openSearch = useCallback(() => {
    showModal(ModalTypes.SEARCH_MODAL, {});
  }, [showModal]);

  return (
    <Flex
      width="100%"
      background="toolbarBackground"
      borderBottom="default"
      alignItems="center"
      flexShrink={0}
      px="m"
      py="m">
      <Flex width="100%" justifyContent="space-between" alignItems="center">
        <HeaderAvatar />
        <Flex flexShrink="0" pl="8px" gap="12px">
          {canSearch && !showSuperNav && (
            <Button buttonStyle="tertiary" icon="navigation.search" onClick={openSearch} />
          )}
          {button}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default React.memo(MobileSubNav) as React.FC<Props>;
