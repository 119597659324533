import { AnyObject, API, APIResultList, PortalItemType } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";
import _cloneDeep from "lodash/cloneDeep";

export type ItemData =
  | { type: PortalItemType.heading; text: string }
  | { type: PortalItemType.note; text: string }
  | { type: PortalItemType.kit; kitId: string };

export type InsertPosition = {
  insertIndex: number;
  targetIndex: number;
  dragPosition: string;
  insertType: string;
  displayOrder?: string;
};

type Args = {
  portalId: string;
  items: ItemData[];
  insertPosition: InsertPosition;
};

const [useCreatePortalItems, createPortalItems] = createAsyncAction(
  "portalItems/create",
  async ({ portalId, items, insertPosition }: Args) => {
    const newItems = items.map(item => {
      const data: AnyObject = {
        type: item.type,
        display_order: insertPosition.displayOrder ? insertPosition.displayOrder : "append",
        portal_uuid: portalId,
      };
      if (item.type === PortalItemType.heading) {
        data.data = { content: item.text };
      } else if (item.type === PortalItemType.note) {
        data.data = { content: item.text };
      } else if (item.type === PortalItemType.kit) {
        data.kit_uuid = item.kitId;
      }
      return data as ItemData;
    });

    const res = await API.call<APIResultList<"portalItems", string>>({
      endpoint: `sync/portal_items`,
      method: "POST",
      data: { portal_items: [...newItems] },
      entity: API.Entity.portalItem,
    });

    return {
      result: res.result,
      entities: res.entities,
      insertIndex: insertPosition.insertIndex,
      newItems,
    };
  }
);

export default useCreatePortalItems;
export { createPortalItems };
