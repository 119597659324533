import { Environment } from "shared";

const credentials = {
    prod: {
      clientId: "757670575963-6aqlipbn3o43uoqj5t6j8vh0egkim5n3.apps.googleusercontent.com",
      developerKey: "AIzaSyCgQwgpyMhZQeafNRw_CEbmsOiT7xIKr90",
      appId: "757670575963",
    },
    qa: {
      clientId: "658373343516-ftnffp62c1k178bdrt6sqapase10h70o.apps.googleusercontent.com",
      developerKey: "AIzaSyBaEGeomICKRDm3gqdNha0LWGa952dfxbY",
      appId: "658373343516",
    },
  },
  isProd = _lingoConfig.environment === Environment.production,
  GOOGLE_CREDENTIALS = isProd ? credentials.prod : credentials.qa;

export default GOOGLE_CREDENTIALS;
