import { createAction } from "@redux/actions/actionCreators";

export enum SearchContext {
  kit = "kit",
  portal = "portal",
  global = "global",
}

export type SearchContextState = {
  context: SearchContext;
  portalId?: string;
  kitId?: string;
};

const [useSetSearchContext, setSearchContext] =
  createAction<SearchContextState>("search/setSearchContext");

export default useSetSearchContext;
export { setSearchContext };
