import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import QueryString from "query-string";
import {
  Text,
  Box,
  Button,
  Flex,
  TabBar,
  Notice,
  ActivityIndicator,
  styles,
  Space,
  SpacePermission,
} from "@thenounproject/lingo-core";

import useBillingData from "@redux/actions/billing/useBillingData";
import useAvailablePlans, { Plan } from "@redux/actions/billing/useAvailablePlans";

import EmptyState from "../EmptyState";
import pricingData from "../../marketing/data/pricing_SELF_SERVE";

import { renderPlanCost } from "../../constants/plans";
import ModalBody from "../ModalBody";
import ModalHeader from "../ModalHeader";
import ModalFooter from "../ModalFooter";
import { isArraySubset } from "shared";

const { color } = styles;

type PlanCardProps = { opacity: string; selected: boolean; cursor: string };
const PlanCard = styled(Flex).attrs<PlanCardProps>({
  mx: "4px",
  flex: "1 1 0",
  flexDirection: "column",
  textAlign: "left",
  color: "grayDarkest",
  border: "1px solid grayLighter",
  overflow: "hidden",
  background: "white",
  borderRadius: "default",
})<PlanCardProps>`
  opacity: ${props => props.opacity};
  // Shadow produces a border
  box-shadow: ${props => (props.selected ? `0 0 0 2px ${props.theme.primaryColor}` : null)};
  cursor: ${props => props.cursor};

  h2 {
    color: ${color.black};
  }

  @media (max-width: 768px) {
    width: calc(50% - 8px);
    flex: none;
    margin-top: 24px;
  }
`;

const PlanCardFooter = styled(Box).attrs({
  p: "m",
  borderTop: "default",
  background: "grayLightest",
  textAlign: "left",
})``;

type Props = {
  space: Space;
  // if true, it's possible for onPlanSelected to be called with null to indicate a "skip"
  allowSkipping?: boolean;
  onPlanSelected: (plan: Plan | "enterprise") => void;
  inline?: boolean;
  recommendedPlan?: string;
};

const SelectPlanModal: React.FC<Props> = ({
  space,
  onPlanSelected,
  allowSkipping,
  inline,
  recommendedPlan,
}) => {
  const preselectedPlan = recommendedPlan ?? QueryString.parse(window.location.search).plan,
    defaultInterval = preselectedPlan && preselectedPlan.includes("year") ? "year" : "month";
  const [selectedInterval, setSelectedInterval] = useState(defaultInterval),
    [selectedPlan, setSelectedPlan] = useState<Plan>();

  const {
    status,
    error,
    data: { plans, owner } = {},
  } = useAvailablePlans({ spaceId: space.id }, { refetchOnMount: true });

  const { data: account } = useBillingData({ spaceId: space.id }, { refetchOnMount: true });

  useEffect(() => {
    if (!selectedPlan && plans) {
      const plan =
        plans.find(p => p.planId === preselectedPlan) ||
        plans.find(p => p.availability === "current");
      if (plan) {
        setSelectedInterval(plan.interval);
      }
      setSelectedPlan(plan);
    }
  }, [plans, preselectedPlan, selectedPlan]);

  const canManageBilling = space?.access?.permissions?.includes(SpacePermission.manageBilling);
  const balance = account?.balance ?? 0,
    credit = balance < 0 ? (balance / -100).toFixed(2) : 0;

  const requestEnterprise = () => {
    onPlanSelected("enterprise");
  };

  const submit = () => {
    const plan = plans.find(p => p.planId === selectedPlan.planId);
    onPlanSelected(plan);
  };

  function toggleInterval(interval) {
    const newInterval = interval;
    let newPlan;

    if (selectedPlan) {
      const { featureGroups } = plans.find(p => p.planId === selectedPlan.planId) || {};
      newPlan = plans.find(plan => {
        return (
          plan.interval === newInterval &&
          plan.featureGroups.length === featureGroups.length &&
          isArraySubset(plan.featureGroups, featureGroups)
        );
      });
    }

    setSelectedInterval(newInterval);
    setSelectedPlan(newPlan);
  }

  /**
   * Rendering
   */

  function renderContent() {
    if (error) return <EmptyState title="Something went wrong" subtitle={error.message} />;
    if (status === "pending") {
      return (
        <Box height="150">
          <ActivityIndicator center />
        </Box>
      );
    }
    const visiblePlans = plans.filter(p => p.interval === selectedInterval);

    return (
      <Fragment>
        {credit !== 0 ? (
          <Notice
            noticeStyle="info"
            mb="l"
            message={
              <>
                You have a <strong>${credit}</strong> credit on your account.
              </>
            }
          />
        ) : null}
        <Flex flexShrink="0" justifyContent="space-between" flexWrap="wrap" width="100%">
          {visiblePlans.map(plan => renderPlanCard(plan))}
        </Flex>
      </Fragment>
    );
  }

  function renderPlanCard(plan: Plan) {
    const { availability, planId } = plan,
      activeUsers = space.counts.users,
      planCost = renderPlanCost(plan, activeUsers),
      isPerUserPlan = Boolean(planCost.pricePerUser);

    return (
      <PlanCard
        data-testid="plan-card"
        position="relative"
        key={planId}
        onClick={availability === "available" ? () => setSelectedPlan(plan) : null}
        opacity={getPlanOpacity(plan)}
        selected={isPlanSelected(plan)}
        cursor={plan.availability === "available" ? "pointer" : null}>
        {renderCardHeader(plan, planCost)}
        <Box p="m">
          {isPerUserPlan ? (
            <>
              <Text color="grayDarkest" font="ui.smallBold">
                Additional users
              </Text>
              <Text color="grayDarkest">{planCost.pricePerUser}</Text>
            </>
          ) : null}

          <Text mt={isPerUserPlan && "m"} font="ui.smallBold" color="grayDarkest">
            Unique features
          </Text>
          {renderUniqueFeatures(plan)}
        </Box>
        <Box flexGrow={10} />
        {renderBadge(plan)}
        {renderCardFooter(plan, activeUsers, planCost.fullPrice)}
      </PlanCard>
    );
  }

  function renderCardHeader(plan: Plan, planCost: ReturnType<typeof renderPlanCost>) {
    const { selfServe } = plan,
      basePrice = selfServe ? planCost.basePrice : "Bespoke pricing",
      includes = selfServe ? `Includes ${planCost.includedUsers}` : "Tailored to your needs";

    return (
      <Box borderBottom="default" p="m">
        <Text as="h2" font="ui.title" mb="xs">
          {plan.planName}
        </Text>
        <Text>{basePrice}</Text>
        <Text color="grayDarkest">{includes}</Text>
      </Box>
    );
  }

  function renderCardFooter(plan: Plan, activeUsers: number, price: string) {
    switch (plan.availability) {
      case "available":
      case "current":
        return (
          <PlanCardFooter>
            <Text>Total for {activeUsers} users</Text>
            <Text>{price}</Text>
          </PlanCardFooter>
        );
      case "unavailable":
        return (
          <PlanCardFooter>
            <Text>Current usage exceeds this plan</Text>
          </PlanCardFooter>
        );
      case "request": {
        return (
          <PlanCardFooter>
            <Button fullWidth text="Contact Us" onClick={requestEnterprise} />
          </PlanCardFooter>
        );
      }

      default:
        throw new Error("Unexpected plan");
    }
  }

  function renderBadge(plan: Plan) {
    switch (plan.availability) {
      case "current":
        return (
          <Text
            font="ui.smallBold"
            textAlign="center"
            background="grayLight"
            borderRadius="default"
            py="xxs"
            px="m"
            mb="m"
            width="unset"
            alignSelf="center">
            Current plan
          </Text>
        );
      default:
        if (recommendedPlan && plan.planId.includes(recommendedPlan.replace("-month", ""))) {
          return (
            <Text
              font="ui.smallBold"
              textAlign="center"
              color="white"
              background="info"
              borderRadius="default"
              py="xxs"
              px="m"
              mb="m"
              width="unset"
              alignSelf="center">
              Recommended
            </Text>
          );
        }
        return null;
    }
  }

  function isPlanSelected(plan: Plan) {
    if (plan.planId === selectedPlan?.planId) return true;
    if (plan.availability === "current" && !selectedPlan) return true;
    return false;
  }

  function renderUniqueFeatures(plan: Plan) {
    const data = pricingData.plans.find(
      p =>
        [p.monthly.id, p.yearly.id].includes(plan.planId) ||
        (plan.planId.includes("ent") && p.id === "enterprise")
    );
    const style = {
      color: "grayDarkest",
      mb: "xs",
    };
    return (
      <Box>
        {data.previous_plan ? <Text {...style}>{`All ${data.previous_plan} features`}</Text> : null}
        {data.storage ? <Text {...style}>{`${data.storage} GB Storage`}</Text> : null}
        {data.uniqueFeatures.map(f => (
          <Text key={f.name.replace(" ", "-")} {...style}>
            {f.name}
          </Text>
        ))}
      </Box>
    );
  }

  function getPlanOpacity(plan: Plan) {
    switch (plan.availability) {
      case "unavailable":
      case "current":
        return "0.5";
      case "request":
      case "available":
        return "1";
      default:
        throw new Error("Unexpected plan availability");
    }
  }

  function renderSkipStep() {
    if (!allowSkipping) return null;
    return (
      <Button
        mt="s"
        text="Skip this step"
        buttonStyle="tertiary"
        size="small"
        onClick={() => onPlanSelected(null)}
      />
    );
  }

  function renderContactOwnerNotice() {
    const subject = encodeURIComponent("Upgrading our Lingo subscription");
    const body = encodeURIComponent(
      "I'd like to upgrade our Lingo subscription to get access to additional features."
    );
    return (
      <Notice
        mt="l"
        message={
          <Text font="ui.small">
            You don&apos;t have permission to update this Space&apos;s plan.{" "}
            <Button
              buttonStyle="tertiary"
              size="small"
              text={`Contact ${owner.name}`}
              link={`mailto:${owner.email}?subject=${subject}&body=${body}`}
            />{" "}
            to continue.
          </Text>
        }
        noticeStyle="warning"
      />
    );
  }

  return (
    <Fragment>
      <ModalHeader
        title="Select plan"
        styleOverrides={{ borderBottom: inline ? "none" : "default" }}
        message={
          <>
            Upgrading{" "}
            <strong>
              {space.name} ({space.counts.users} users)
            </strong>
          </>
        }>
        <TabBar>
          <TabBar.Item
            text="Monthly"
            selected={selectedInterval === "month"}
            onClick={() => toggleInterval("month")}
          />
          <TabBar.Item
            text="Yearly (2 months free)"
            selected={selectedInterval === "year"}
            onClick={() => toggleInterval("year")}
            data-test="toggle-label"
          />
        </TabBar>
      </ModalHeader>
      <ModalBody pl="l" pr="l">
        {renderContent()}
        {!canManageBilling && status === "fulfilled" && renderContactOwnerNotice()}
      </ModalBody>
      {canManageBilling && (
        <ModalFooter
          primary={{
            id: "select-button",
            text: "Continue",
            disabled: selectedPlan?.availability !== "available",
            onClick: submit,
          }}
          styleOverrides={inline ? { borderTop: "none", background: "none" } : {}}
        />
      )}
      {renderSkipStep()}
    </Fragment>
  );
};

export default SelectPlanModal;
