import React from "react";
import styled from "styled-components";
import { Button } from "@thenounproject/lingo-core";

import useInspectableActions from "@features/context-menus/hooks/useInspectableActions";

import { Inspectable } from "@constants/Inspector";

type Props = {
  inspectables: Inspectable[];
  canEdit: boolean;
};

const ActionButton = styled(Button).attrs({
  mt: "s",
  buttonStyle: "secondary",
  width: "100%",
})``;

const InspectorActions: React.FC<Props> = ({ inspectables, canEdit }) => {
  const { moveItems, copyItems, deleteItems, copyShareLink } = useInspectableActions(inspectables);

  function copyLinkButton() {
    if (!copyShareLink) return null;
    return (
      <ActionButton text="Copy link" onClick={copyShareLink} icon="action.link" size="small" />
    );
  }

  function moveToButton() {
    if (!canEdit) return null;
    return <ActionButton text="Move to" onClick={moveItems} icon="action.move_to" size="small" />;
  }

  function copyToButton() {
    if (!canEdit) return null;
    return <ActionButton text="Copy to" onClick={copyItems} icon="action.copy_to" size="small" />;
  }

  function deleteButton() {
    if (!canEdit) return null;
    return <ActionButton text="Delete" onClick={deleteItems} icon="action.trash" size="small" />;
  }

  return (
    <>
      {copyLinkButton()}
      {moveToButton()}
      {copyToButton()}
      {deleteButton()}
    </>
  );
};

export default InspectorActions;
