import React, { useState } from "react";
import styled from "styled-components";
import { Button, Input, Select, Asset } from "@thenounproject/lingo-core";

import AssetDownloadOptionThumbnail from "./AssetDownloadOptionThumbnail";
import ExportOption from "./ExportOption";
import { DownloadAsset } from "./AssetDownloadPopup";

const CustomSizeInput = styled(Input).attrs({
  styleOverrides: {
    mb: "none",
    ml: "xs",
    flexShrink: 1,
    width: "100%",
  },
})``;

type Props = {
  asset: Asset;
  downloadAsset: DownloadAsset;
};

const CustomExportOption: React.FC<Props> = ({ asset, downloadAsset }) => {
  function getCustomTypeOptions() {
    if (!asset.meta.filecuts) return null;
    const { availableTypes } = asset.meta.filecuts;
    return availableTypes.filter(({ enabled }) => enabled);
  }

  const availableTypes = getCustomTypeOptions();

  const [customSizeInput, setCustomSizeInput] = useState("1x");
  const [customTypeSelect, setCustomTypeSelect] = useState(
    (availableTypes && availableTypes.length && availableTypes[0].type) || ""
  );

  function downloadCustomAsset(e: React.MouseEvent) {
    if (e) e.preventDefault();
    if (!customSizeInput) return;
    downloadAsset(customTypeSelect, customSizeInput, undefined, e);
  }

  if (!availableTypes?.length) return null;

  const customTypesByName = availableTypes.reduce((reduced, typeObj) => {
    reduced[typeObj.type] = typeObj;
    return reduced;
  }, {});
  const opts = availableTypes.map(t => {
    return { value: t.type, label: t.type };
  });
  const canResize = type => customTypesByName[type] && customTypesByName[type].resizable;

  return (
    <ExportOption data-test="dl-custom">
      <AssetDownloadOptionThumbnail asset={asset} />
      <Select
        buttonStyle="dropdown"
        options={opts}
        size="regular"
        value={customTypeSelect}
        onChange={t => {
          setCustomTypeSelect(t);
          setCustomSizeInput(canResize(t) ? customSizeInput : "1x");
        }}
      />
      <CustomSizeInput
        disabled={!canResize(customTypeSelect)}
        placeholder="2x, 300w"
        value={customSizeInput}
        onChange={e => setCustomSizeInput(e.target.value)}
      />
      <Button
        flexShrink="0"
        ml="xs"
        id="dl-button-custom"
        data-hover="shown"
        onClick={downloadCustomAsset}
        icon="download"
      />
    </ExportOption>
  );
};

export default CustomExportOption;
