import React, { useEffect } from "react";
import { buildURL, Text, Flex } from "@thenounproject/lingo-core";
import QueryString from "query-string";
import useLogout from "@actions/auth/useLogout";

export default function Logout() {
  const [logout] = useLogout();
  useEffect(() => {
    const { next } = QueryString.parse(window.location.search);
    void logout().then(() => {
      if (next) {
        const decodedUrl = decodeURIComponent(next as string);
        window.location.href = decodedUrl;
      } else {
        window.location.href = buildURL("/");
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex width="100%" height="100vh" alignItems="center" justifyContent="center">
      <Text color="grayDarker" font="ui.title">
        Logging out...
      </Text>
    </Flex>
  );
}
