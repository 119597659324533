import React from "react";
import MediaQuery from "react-responsive";

import { DEVICE_WIDTHS } from "./consts";

export const TabletUp: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <MediaQuery minWidth={DEVICE_WIDTHS.tablet + 1}>{children}</MediaQuery>;
};

export const TabletDown: React.FC<React.PropsWithChildren> = ({ children }) => {
  return <MediaQuery maxWidth={DEVICE_WIDTHS.tablet}>{children}</MediaQuery>;
};
