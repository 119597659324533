import { v4 as genUuid } from "uuid";
import { API, ItemType, Item, APIResultList } from "@thenounproject/lingo-core";
import { mutateLayoutByInserts } from "@helpers/layoutValidation";
import { InsertType } from "@constants/InsertType";
import createAsyncAction from "../actionCreators/createAsyncAction";
import { fetchSectionItems } from "./useSectionItems";

export type NewItem = {
  uuid?: string;
  type: ItemType;
  kit_uuid: string;
  section_uuid?: string;
  item_uuid?: string;
  display_order?: string | number;
  [key: string]: any;
};

export type InsertConfig = {
  newItems: NewItem[];
  sectionId?: string;
  itemId?: string;
  insertIndex: number;
  targetIndex: number;
  guidePosition?: string;
  dragPosition?: string;
  insertType: InsertType;
};

type ItemUpdate = {
  uuid: string;
  display_order?: string;
  data?: {
    display_size?: number;
    background_color?: string;
    display_style?: Item["data"]["displayStyle"];
  };
};

/**
 * Batch updates an array of items, optionally create new item(s)
 * @param {Array} updatedItems Array of items updates
 * @param {Number} insertConfig Object containing sectionId, newItems, insertIndex, insertType, guidePosition, dragPosition
 */

type Args = {
  updatedItems: ItemUpdate[];
  insertConfig?: InsertConfig;
};

const [useBatchSaveItems, batchSaveItems] = createAsyncAction(
  "items/batchSave",
  async (args: Args, thunkApi) => {
    const { updatedItems, insertConfig } = args;
    const { newItems = [], insertIndex = null, sectionId } = insertConfig ?? {};
    let mutationUpdates = [];
    /**
     * If there is more than one new item, overwrite the display_order
     * to be after the previous item in the array.
     */
    const orderedNewItems = newItems.map((currentItem, idx) => {
      if (idx > 0) {
        currentItem.display_order = `after:${newItems[idx - 1].uuid}`;
      }
      currentItem.uuid = (currentItem.uuid || (genUuid() as string)).toUpperCase();
      return currentItem;
    });
    /**
     * Get any changes from mutating the current layout with either updates or new items.
     * There shouldn't be any valid cases where both new items & updates are passed through
     * this action simultanously.
     */

    if (newItems.length && sectionId) {
      const state = thunkApi.getState();
      const {
        data: { items: itemIds },
      } = fetchSectionItems.getQueryData(state.entities.items.queries, {
        sectionId,
        version: 0,
      })[0];
      if (itemIds) {
        const items = itemIds.map(id => state.entities.items.objects[id]);
        ({ updates: mutationUpdates } = mutateLayoutByInserts(insertConfig, items));
      }
    } else {
      mutationUpdates = insertConfig?.newItems ?? [];
    }

    const res = await API.call<APIResultList<"items", string>>({
      endpoint: "sync/items",
      method: "POST",
      entity: API.Entity.item,
      data: {
        items: [...updatedItems, ...mutationUpdates],
      },
    });

    return {
      newItems: orderedNewItems.map(({ uuid, type }) => {
        return { type, uuid };
      }),
      insertIndex,
      result: res.result.items,
      entities: res.entities,
    };
  }
);

export default useBatchSaveItems;
export { batchSaveItems };
