import { API, APIResultList, base64 } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  userIds: number[];
  spaceId: number;
  role: string;
};

const [useBatchUpdateSpaceMembers, batchUpdateSpaceMembers] = createAsyncAction(
  "spaceMembers/batchUpdate",
  async ({ spaceId, userIds, role }: Args, thunkApi) => {
    const spaceMembers = thunkApi.getState().entities.spaceMembers.objects;
    // Create a map of the previous roles to list of users
    // { admin: [1-123] }
    const previousRoles = userIds.reduce(
      (acc, userId) => {
        const key = [spaceId, userId].join("-");
        const currentRole = spaceMembers[key]?.role;
        if (!acc[currentRole]) acc[currentRole] = [];
        acc[currentRole].push(key);
        return acc;
      },
      {} as Record<string, string[]>
    );

    const query = base64.encode({ include: ["kits"] });
    const res = await API.call<APIResultList<"members", string>>({
      endpoint: `spaces/${spaceId}/members`,
      query: { query },
      method: "PUT",
      entity: API.Entity.spaceMember,
      data: {
        members: userIds.map(id => ({ user_id: id, role })),
      },
    });
    return { ...res, previousRoles };
  }
);

export default useBatchUpdateSpaceMembers;
export { batchUpdateSpaceMembers };
