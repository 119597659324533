import { noop } from "lodash";
import React from "react";

export type InspectorModes = "simple" | "detailed";
export type GalleryAssetViewModes = "hidden" | "detailed";

export const inspectorStorageKey = "lingo_inspector_preference";
export const galleryAssetViewStorageKey = "lingo_gallery_asset_view_preference";

export type ViewModesState = {
  inspectorMode: InspectorModes;
  galleryAssetViewMode: GalleryAssetViewModes;
};

export type ViewModesContextType = {
  galleryAssetViewMode: ViewModesState["galleryAssetViewMode"];
  inspectorMode: ViewModesState["inspectorMode"];
  setInspectorMode: React.Dispatch<React.SetStateAction<ViewModesState["inspectorMode"]>>;
  setGalleryAssetViewMode: React.Dispatch<
    React.SetStateAction<ViewModesState["galleryAssetViewMode"]>
  >;
};

export const initialState: ViewModesState = {
  inspectorMode: (localStorage.getItem(inspectorStorageKey) as InspectorModes) || "simple",
  galleryAssetViewMode:
    (localStorage.getItem(galleryAssetViewStorageKey) as GalleryAssetViewModes) || "detailed",
};

export const ViewModeContext = React.createContext<ViewModesContextType>({
  galleryAssetViewMode: initialState.galleryAssetViewMode,
  inspectorMode: initialState.inspectorMode,
  setInspectorMode: noop,
  setGalleryAssetViewMode: noop,
});
