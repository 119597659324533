import React from "react";
import { Flex, Button, BoxProps, FlexProps } from "@thenounproject/lingo-core";

interface FooterButtonProps {
  id?: string;
  text: string;
  onClick: () => void;
  disabled?: boolean;
}

type Props = {
  primary: FooterButtonProps;
  secondary?: FooterButtonProps;
  styleOverrides?: BoxProps & FlexProps;
};

const ModalFooter: React.FC<Props> = ({ primary, secondary, styleOverrides }) => {
  return (
    <Flex
      py="16px"
      px={"80px"}
      variations={{ "mq.s": { px: "16px" } }}
      borderTop="default"
      background="grayLightest"
      alignItems="center"
      justifyContent="flex-end"
      flexShrink="0"
      {...styleOverrides}>
      {secondary ? (
        <Button id="modal-footer-secondary-button" buttonStyle="secondary" mr="s" {...secondary} />
      ) : null}

      <Button fullWidth={!secondary} id={"modal-footer-primary-button"} {...primary} />
    </Flex>
  );
};

export default ModalFooter;
