import QueryString from "query-string";
import { useMemo } from "react";
import {
  getCustomDomainComponents,
  ErrorCode,
  SpacePreview,
  PortalPreview,
} from "@thenounproject/lingo-core";
import { parseHostname } from "shared";
import { useLocation } from "react-router-dom";

import useSpacePreview from "@redux/actions/spaces/useSpacePreview";
import useIdentifyContextPreview from "@redux/actions/spaces/useIdentifyContextPreview";
import { parseIdentifier } from "@redux/utils/identifiers";

type Result = {
  tokenError?: number;
  nextSpaceIdentifier?: string | number;
  token?: AuthToken;
  portalId?: string;
  kitId?: string;
  autoPassword?: string;
  redirectUrl: string;
  authType: AuthType;
  signupStep?: SignupStep;
  spacePreview?: SpacePreview;
  portalPreview?: PortalPreview;
  previewIsLoading: boolean;
};

export enum AuthType {
  login = "login",
  signup = "signup",
  enterPassword = "enter-password",
  enterEmail = "enter-email",
  joinSpace = "join-space",
  sso = "sso",
  linkAccount = "link-account",
  join = "join",
  invitation = "invitation",
  passwordReset = "password-reset",
}

export enum SignupStep {
  verificationSent = "verification-sent",
}

type AuthTokenType =
  | "magic-login"
  | "join-link"
  | "join-email"
  | "invitation"
  | "link-google"
  | "link-sso"
  | "password-reset";

const TOKEN_GENERATED_PREVIEWS: AuthTokenType[] = [
  "invitation",
  "link-sso",
  "link-google",
  "join-link",
];

const KNOWN_TOKEN_ERRORS = [
  ErrorCode.invitationAlreadyAccepted,
  ErrorCode.invitationNotFound,
  ErrorCode.joinLinkNotValid,
] as number[];

export type AuthToken = {
  token: string;
  type: AuthTokenType;
};

type Props = {
  authType: AuthType;
  signupStep?: SignupStep;
};

// Determines the auth state based on the URL
export default function useAuthState({ authType, signupStep }: Props): Result {
  // Get variables from the url

  const location = useLocation(),
    query = QueryString.parse(location.search),
    redirectTo = query.next as string;

  const { identifier } = getCustomDomainComponents(),
    context = useMemo(() => getTargetContext(redirectTo), [redirectTo]),
    autoPassword = context.autoPassword,
    redirectUrl = context.cleanedUrl,
    token = useMemo(() => {
      // If we are are followiing a magic login link, that takes precedence
      const currentUrlToken = parseUrl(location.pathname);
      if (currentUrlToken?.type === "magic-login") {
        return currentUrlToken;
      }

      let fromUrl = context.cleanedUrl;
      if (fromUrl && !fromUrl.startsWith("/")) {
        try {
          fromUrl = new URL(fromUrl).pathname;
        } catch {
          // that's ok
        }
      }
      return parseUrl(fromUrl) || parseUrl(location.pathname);
    }, [context.cleanedUrl, location.pathname]);

  const nextSpaceIdentifier = useMemo(() => {
    if (TOKEN_GENERATED_PREVIEWS.includes(token?.type) && authType !== AuthType.login)
      return token.token;
    if (context.spaceId) return context.spaceId;
    if (authType !== AuthType.passwordReset) {
      return token?.token;
    }
    return undefined;
  }, [authType, context.spaceId, token]);

  // Fetching the space/portal previews
  // We still need to hit this endpoint to fetch spaces for tokens
  const spacePreviewResult = useSpacePreview(
    {
      spaceId: nextSpaceIdentifier,
    },
    { skip: !nextSpaceIdentifier }
  );

  const contextPreview = useIdentifyContextPreview(
    {
      hostname: identifier ?? context.domainIdentifier,
      spaceId: context.spaceId,
      portalId: context.portalId,
      kitId: context.kitId,
      itemId: context.itemId,
      sectionId: context.sectionId,
    },
    { skip: !(context.kitId || context.portalId || context.domainIdentifier) }
  );

  const spacePreview = spacePreviewResult.data ?? contextPreview?.data?.space,
    portalPreview = authType === AuthType.enterPassword ? contextPreview?.data?.portal : null,
    error = spacePreviewResult.error ?? contextPreview?.error,
    isLoading = spacePreviewResult.isLoading || contextPreview?.isLoading;

  const tokenError = useMemo(() => {
    if (KNOWN_TOKEN_ERRORS.includes(error?.code)) {
      return error?.code;
    }
    if ("join-link" === token?.type && error?.code === ErrorCode.spaceNotFound) {
      return error?.code;
    }
  }, [error?.code, token]);

  return {
    authType,
    signupStep,
    token,
    tokenError,
    kitId: contextPreview?.data?.kitId ?? context.kitId,
    portalId: contextPreview?.data?.portalId ?? context.portalId,
    nextSpaceIdentifier:
      nextSpaceIdentifier ??
      contextPreview?.data?.spaceId ??
      spacePreview?.id ??
      portalPreview?.spaceId ??
      context.spaceId,
    autoPassword,
    redirectUrl,
    spacePreview,
    portalPreview,
    previewIsLoading: (identifier ?? nextSpaceIdentifier) && isLoading,
  };
}

function parseUrl(url: string): AuthToken {
  if (url) {
    const next = url.split("?")[0].split("/");
    if (next[1] === "login" && next[2] && !["sso", "mac"].includes(next[2]))
      return { token: next[2], type: "magic-login" };
    if (next[1] === "join") return { token: next[3], type: "join-link" };
    if (next[1] === "invitation") return { token: next[2], type: "invitation" };
    if (next[1] === "link-account" && next[2] === "google")
      return { token: next[3], type: "link-google" };
    if (next[1] === "link-account") return { token: next[2], type: "link-sso" };
    if (next[1] === "password-reset" && next[2]) {
      return { token: next[2], type: "password-reset" };
    }
  }
}

export function getTargetContext(fromUrl: string): {
  domainIdentifier?: string;
  spaceId?: number;
  portalId?: string;
  kitId?: string;
  itemId?: string;
  sectionId?: string;
  autoPassword?: string;
  cleanedUrl?: string;
} {
  if (!fromUrl) return {};
  const redirectComponents = QueryString.parseUrl(decodeURIComponent(fromUrl)),
    { kit_token, tkn, password, ...otherQuery } = redirectComponents.query,
    redirectQuery = QueryString.stringify(otherQuery),
    { url } = redirectComponents;

  let path = url;
  if (!path.startsWith("/")) {
    path = new URL(url).pathname;
  }

  function getIdentifier(match: RegExp) {
    return parseIdentifier(path.match(match)?.[1]);
  }

  const portalId = getIdentifier(/\/p\/([^/|?|$]+)/),
    kitId = getIdentifier(/\/k\/([^/|?|$]+)/),
    sectionId = getIdentifier(/\/s\/([^/|?|$]+)/),
    itemId = getIdentifier(/\/a\/([^/|?|$]+)/),
    _spaceId = path.match(/^\/(\d+\/?)/)?.[1],
    _spaceIdInt = _spaceId && parseInt(_spaceId),
    spaceId = Number.isNaN(_spaceIdInt) ? null : _spaceIdInt;

  let cleanedUrl = redirectComponents.url;
  if (redirectQuery) cleanedUrl += `?${redirectQuery}`;
  return {
    spaceId,
    portalId,
    kitId,
    sectionId,
    itemId,
    domainIdentifier: parseHostname(redirectComponents.url).identifier,
    autoPassword: (kit_token || tkn || password) as string,
    cleanedUrl,
  };
}
