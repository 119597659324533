import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  spaceId: number | string;
  kitId: string;
  sourceId: string;
};

const [usePollForDuplicateKit, pollForDuplicateKit] = createAsyncAction(
  "kits/pollForDuplicateKit",
  async ({ kitId, spaceId }: Args) => {
    return await API.call<string>({
      endpoint: `kits/${spaceId}/${kitId}`,
      method: "GET",
      entity: API.Entity.kit,
    });
  }
);

export default usePollForDuplicateKit;
export { pollForDuplicateKit };
