import _sortBy from "lodash/sortBy";

import { API, APIResultList, Item } from "@thenounproject/lingo-core";

import { mutateLayoutByUpdates } from "@helpers/layoutValidation";
import { sortSectionItems } from "@redux/actions/sections/sortSectionItems";
import { createAsyncAction } from "../actionCreators";

/**
 * Batch updates an array of item ids with a new status
 * @param {Array} items Array of objects containing item id
 * @param {String} status trashed | deleted | active
 * @param {Array} sectionId Pass the section id if we're deleting items from a section
 * @param {Array} galleryOutline Outline of the gallery items from which the action was called
 */
type Args = {
  items: string[];
  status: Item["status"];
  sectionId?: string;
  galleryOutline?: any;
};

const [useBatchUpdateItemStatus, batchUpdateItemStatus] = createAsyncAction(
  "items/batchUpdateItemStatus",
  async ({ items, status, sectionId, galleryOutline }: Args, thunkApi) => {
    /**
     * Basic updates from the status update
     */
    const itemData = items.map(id => {
      return { uuid: id, status };
    });

    let mutationUpdates = [];

    /**
     * If we're deleting items from a section,
     * we need to check for any mutation updates.
     *
     * Only runs if a galleryOutline is passed.  There are instances where
     * we'd like to delete items & have no need calculate mutations.
     */
    if (status === "trashed" && sectionId && galleryOutline) {
      const {
        entities: {
          sections: { objects: sections },
          items: { objects: stateItems },
        },
      } = thunkApi.getState();
      const updatedItems = items.map(id => {
        return { id, status };
      });
      ({ updates: mutationUpdates } = mutateLayoutByUpdates(
        { updatedItems, galleryOutline },
        sections[`${sectionId}-0`],
        stateItems
      ));
    }

    const result = await API.call<APIResultList<"items", string>>({
      endpoint: "sync/items",
      method: "POST",
      entity: API.Entity.item,
      data: {
        items: [...itemData, ...mutationUpdates],
      },
    });

    // TODO: This probably won't work anymore since the API call has been run
    // but the result hasn't  been passed through the reducer yet.
    // Sort the sections containing any restored items
    if (status !== "active") return result;
    const sectionsWithRestoredItems = (Object.values(result.entities.items) as Item[])
      .filter(i => i.status === "active")
      .map(i => i.sectionId)
      .filter(Boolean);
    if (sectionsWithRestoredItems.length) {
      thunkApi.dispatch(sortSectionItems([...new Set(sectionsWithRestoredItems)]));
    }
    return result;
  }
);
export default useBatchUpdateItemStatus;
export { batchUpdateItemStatus };
