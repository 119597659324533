import React, { useMemo, useEffect, useCallback, useState, useContext } from "react";
import { SearchFilterInput } from "@thenounproject/lingo-core";

import useAssetLibraryFilters from "@features/library/queries/assetLibraryFilters";
import { RowSize, SearchSortTypes } from "@features/library/types";

import {
  initialState,
  AssetLibraryContext,
  ASSET_ROW_STORAGE_KEY,
  CUSTOM_FIELD_ROW_STORAGE_KEY,
  AssetLibraryContextType,
} from "./AssetLibraryContext";
import { useObject } from "@hooks/useObject";
import { cloneDeep } from "lodash";
import { useSelectSpace } from "@selectors/entities/spaces";
import useAssetLibraryPresets from "@features/library/queries/assetLibraryPresets";
import { filtersAreEqual } from "@features/library/components/AssetLibraryNav";
import useSearchQuery from "@hooks/useSearchQuery";

import useAssetViews from "@redux/actions/views/useAssetViews";
import { useSelectedItemsContext } from "@contexts/SelectedItemsProvider";

export default function AssetLibraryProvider({ children }: { children: React.ReactNode }) {
  const { data: filterData } = useAssetLibraryFilters();
  const { clearSelection } = useSelectedItemsContext();
  const availableFilters = filterData?.filters || [];

  const space = useSelectSpace();

  const { data: views } = useAssetViews({ spaceId: space?.id });
  const viewId = useSearchQuery<number>("view", parseInt);

  const [sort, setSort] = useState<SearchSortTypes>(initialState.sort);
  const [assetRowSize, setAssetRowSize] = useState<RowSize>(initialState.assetRowSize);
  const [showTableCustomFields, setShowTableCustomFields] = useState<boolean>(
    initialState.showTableCustomFields
  );
  const [appliedFilters, setAppliedFilters] = useState<SearchFilterInput[]>(
    initialState.appliedFilters
  );
  const { data: presetsData } = useAssetLibraryPresets(
    { spaceId: space?.id },
    { skip: !space?.id }
  );

  const presetViews = useMemo(() => presetsData?.presets || [], [presetsData]);
  const activeView = useMemo(() => views?.find(v => v.id === viewId), [viewId, views]);
  const activePreset = useMemo(
    () => presetViews?.find(v => filtersAreEqual(v.filters, appliedFilters)),
    [appliedFilters, presetViews]
  );

  useEffect(() => {
    clearSelection({ type: "assetLibrary" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedFilters]); // Having the clearSelection dependency here causes an infinite loop

  useEffect(() => {
    localStorage.setItem(ASSET_ROW_STORAGE_KEY, assetRowSize);
  }, [assetRowSize]);

  useEffect(() => {
    localStorage.setItem(CUSTOM_FIELD_ROW_STORAGE_KEY, String(showTableCustomFields));
  }, [showTableCustomFields]);

  const setFilters = useCallback((f: SearchFilterInput[]) => {
    setAppliedFilters(f);
  }, []);

  const addFilter = useCallback(
    (f: SearchFilterInput) => {
      const newFilters = [...appliedFilters, f];
      setAppliedFilters(newFilters);
    },
    [appliedFilters]
  );

  const removeFilter = useCallback(
    (index: number) => {
      const filters = cloneDeep(appliedFilters);
      const newFilters = [...filters.slice(0, index), ...filters.slice(index + 1)];
      setAppliedFilters(newFilters);
    },
    [appliedFilters]
  );

  const clearFilters = useCallback(() => {
    setAppliedFilters([]);
  }, []);

  const setSortBy = useCallback((type: SearchSortTypes) => {
    setSort(type);
  }, []);

  const context: AssetLibraryContextType = useObject({
    availableFilters,
    appliedFilters,
    addFilter,
    removeFilter,
    clearFilters,
    setFilters,
    sort,
    setSortBy,
    assetRowSize,
    setAssetRowSize,
    showTableCustomFields,
    setShowTableCustomFields,
    activeView,
    presetViews,
    activePreset,
    viewId,
  });

  return <AssetLibraryContext.Provider value={context}>{children}</AssetLibraryContext.Provider>;
}

export const useAssetLibraryContext = () => useContext(AssetLibraryContext);
