import QueryString from "query-string";
import { Asset, Space, API, base64, SearchFilterInput } from "@thenounproject/lingo-core";

import { SearchQuery, SearchSortTypes } from "@features/library/types";
import { createQueryAction } from "@actions/actionCreators";
import { DEFAULT_PAGE_SIZE } from "@redux/actions/actionCreators/createQueryAction";

type LibraryAssetsArgs = {
  spaceId: Space["id"];
  filters: SearchFilterInput[];
  sort: SearchSortTypes;
};

export type LibraryAssetResults = {
  assets: Asset[];
  total: number;
};

type NormalizedResult = {
  assets: string[];
  total: number;
};

const [useLibraryAssets, fetchLibraryAssets] = createQueryAction<
  LibraryAssetsArgs,
  LibraryAssetResults,
  NormalizedResult
>(
  {
    entity: "assets",
    action: "queryLibraryAssets",
    denormalize: { entity: null, assets: "assets" },
    pagingKey: "assets",
  },
  async ({ args, paging }, thunkApi) => {
    let offset = 0;
    if (paging.page > 1) {
      const queries = thunkApi.getState().entities.assets.queries;
      offset = fetchLibraryAssets.getQueryData(queries, args)[0]?.data?.assets.length || 0;
    }
    const queryString = buildInfiniteQueryString(offset, args.filters, args.sort);
    const res = await API.call<NormalizedResult>({
      endpoint: `search/spaces/${args.spaceId}?${queryString}`,
      method: "GET",
      entity: API.Entity.assetSearch,
    });

    return res;
  }
);

const buildInfiniteQueryString = (
  offset: number,
  filters: SearchFilterInput[],
  sort: SearchSortTypes
) => {
  const query: SearchQuery = {
    filters,
    queries: {
      assets: {
        offset,
        limit: DEFAULT_PAGE_SIZE,
        context: "library",
        type: "assets",
        sort,
      },
    },
  };
  return QueryString.stringify({
    query: base64.encode(query),
  });
};

export default useLibraryAssets;
export { fetchLibraryAssets };
