import { SearchFilterTypes, SearchFilterInput, AssetView } from "@thenounproject/lingo-core";

export type SearchFilterPreset = Pick<AssetView, "name" | "filters">;

type SearchQueries = {
  [key: string]: {
    type: string;
    context?: string;
    limit?: number;
    offset?: number;
    sort?: string;
    filter_type?: string;
    value?: string;
  };
};

export type SearchQuery = {
  filters: SearchFilterInput[];
  queries: SearchQueries;
};

export type SearchQueryFilterOption = {
  type: SearchFilterTypes;
  prefix: string;
  fieldName?: string;
  fieldId?: number;
};

export enum SearchSortTypes {
  relevance = "relevance",
  alpha = "alpha",
  alphaDesc = "-alpha",
  recent = "recent",
  recentDesc = "-recent",
  size = "size",
  sizeDesc = "-size",
  resolution = "resolution",
  resolutionDesc = "-resolution",
  type = "type",
  typeDesc = "-type",
}

export enum TagSearchSortTypes {
  alpha = "alpha",
  alphaDesc = "-alpha",
  count = "count",
  countDesc = "-count",
}

export type RowSize = "small" | "medium" | "large";
