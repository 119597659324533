import React, { useState, useCallback, useMemo, useEffect } from "react";
import styled from "styled-components";

import {
  Text,
  Box,
  Flex,
  Icon,
  NavPill,
  Space,
  Kit,
  KitVersion,
  Section,
  buildURL,
  HeadingItem,
  slugifyName,
  Portal,
  useNavigation,
} from "@thenounproject/lingo-core";

import { useInViewContext } from "../../contexts/InViewProvider";

import useNotifications from "@actions/useNotifications";
import KitNavItems from "./KitNavItems";
import { KitNavSectionData } from "./KitNavItems/KitNavSectionItem";
import KitNavVersionMenu from "./KitNavItems/KitNavVersionMenu";
import useKitOutline from "@redux/actions/kitVersions/useKitOutline";
import useCreateSection from "@redux/actions/sections/useCreateSection";
import { SubNavWrapper } from "./SubNav";
import useConfigureMode from "../hooks/useConfigureMode";

function getSectionShareLink(section: Section, kit: Kit, space: Space, portal: Portal) {
  const queryParams = { kit_token: kit.shareToken || undefined };
  const idName = slugifyName(section.name, section.shortId);
  return buildURL(`/s/${idName}/`, { space, portal, isPublic: true }, queryParams);
}

function getHeadingShareLink(
  section: Section,
  heading: HeadingItem,
  kit: Kit,
  space: Space,
  portal: Portal
) {
  const queryParams = { kit_token: kit.shareToken || undefined };
  return buildURL(`/s/${section.urlId}/`, { space, portal }, queryParams, heading.shortId);
}

function getUrlFromSection(section: Section, kit: Kit, space: Space, portal: Portal) {
  const queryParams = {
    kit_token: kit.shareToken || undefined,
    v: section.version,
  };

  return buildURL(`/s/${section.shortId}/`, { space, portal }, queryParams);
}

const VersionNotice = styled(Flex).attrs({
  p: "xs",
  mt: "s",
  alignItems: "center",
  background: "info",
  borderRadius: "default",
})``;

export interface VersionWithExtra extends KitVersion {
  isRecommended: boolean;
  isActive: boolean;
  identifier: string;
}

type KitNavProps = {
  sectionId: string;
  space: Space;
  portal?: Portal;
  kit: Kit;
  kitVersion: KitVersion;
};

const KitNav: React.FC<KitNavProps> = ({ space, kit, kitVersion, sectionId, portal }) => {
  const { data: kitOutline } = useKitOutline({
    kitId: kit.kitId,
    version: kitVersion.version,
    spaceId: kit.spaceId,
  });
  return KitNavInternals({ space, kit, kitVersion, sectionId, kitOutline, portal });
};

type InternalProps = KitNavProps & { kitOutline: ReturnType<typeof useKitOutline>["data"] };

export const KitNavInternals: React.FC<InternalProps> = ({
  space,
  portal,
  kit,
  kitVersion,
  sectionId,
  kitOutline,
}) => {
  const [createSection] = useCreateSection();
  const sections = kitOutline?.sections as unknown as Section[];

  const activeVersion = kitVersion;

  const { configureMode } = useConfigureMode();
  const canEditSections =
    !configureMode &&
    activeVersion?.version === 0 &&
    kit?.access?.permissions.includes("edit_content");

  const { headers: headersInView } = useInViewContext();
  const [latestActiveHeading, setLatestActiveHeading] = useState<string>();

  // get the first heading in view for active section
  const activeHeadingShortId = useMemo(() => {
    const activeSection = sections?.find(s => s.id === sectionId);
    const activeHeading = activeSection?.headers?.find(s =>
      headersInView.includes(s.shortId)
    )?.shortId;
    return activeHeading;
  }, [headersInView, sectionId, sections]);

  useEffect(() => {
    if (activeHeadingShortId && activeHeadingShortId !== latestActiveHeading) {
      setLatestActiveHeading(activeHeadingShortId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeHeadingShortId]);

  const navigation = useNavigation();

  const { showNotification } = useNotifications();

  const addNewSection = useCallback(
    async (sectionName: string) => {
      const {
        error,
        response: { result = "", entities: { sections: newSections = {} } = {} } = {},
      } = await createSection({ kitId: kit.kitId, name: sectionName });
      if (error) {
        showNotification({ message: error.message, level: "error" });
      } else {
        navigation.push(getUrlFromSection(newSections[result] as Section, kit, space, portal));
      }
    },
    [createSection, kit, navigation, portal, showNotification, space]
  );

  const sectionData: KitNavSectionData[] = useMemo(() => {
    if (!sections) return [];
    return sections.map(s => {
      return {
        section: s,
        allSectionIds: sections.map(s => s.id),
        kit: kit,
        kitPath: buildURL(`/k/${kit.shortId}/`, { space, portal }),
        selected: s.id === sectionId,
        canEdit: canEditSections,
        shareLink: getSectionShareLink(s, kit, space, portal),
        headers: s.headers?.map(h => {
          return {
            header: h,
            section: s,
            kit: kit,
            inView: latestActiveHeading === h.shortId,
            canEdit: canEditSections,
            shareLink: getHeadingShareLink(s, h, kit, space, portal),
          };
        }),
      };
    });
  }, [canEditSections, kit, latestActiveHeading, portal, sectionId, sections, space]);

  function renderKitOutline() {
    if (!sections) return null;

    const addSectionField = canEditSections ? (
      <NavPill.Input
        onSubmit={addNewSection}
        placeholder="Add Section"
        defaultText="Untitled Section"
      />
    ) : null;

    return (
      <>
        <Box as="ul" px="l" pb="m" pt="l" overflow="auto">
          <KitNavItems sections={sectionData} />
          {addSectionField}
        </Box>
      </>
    );
  }

  const showLegacyBadge =
    activeVersion &&
    activeVersion?.version !== kit.recommendedVersion &&
    activeVersion?.version > 0;
  return (
    <SubNavWrapper className="intercom-kit-navigator">
      <Box px="l">
        {
          <KitNavVersionMenu
            kit={kit}
            space={space}
            portal={portal}
            activeVersion={activeVersion}
          />
        }
        {showLegacyBadge ? (
          <VersionNotice>
            <Icon iconId="info" fill="white" mr="xs" />
            <Text color="white">Legacy version</Text>
          </VersionNotice>
        ) : null}
      </Box>

      {renderKitOutline()}
    </SubNavWrapper>
  );
};

export default KitNav;
