import { MarketingButtons } from "../../components/MarketingButtons";
import { PageContent } from "../../pages/templates/PageLayout";

import Images from "../images";

const content: PageContent = {
  style: "light",
  sections: [
    {
      type: "HeroSection",
      title: "Integrations & Apps",
      style: "light",
      layoutDirection: "vertical",
      subtitle:
        "Work from the web or your desktop and connect Lingo to the tools you already use so you can supercharge your workflow.",
      image: {
        src: Images.Integrations.hero,
        alt: "",
      },
      primaryButton: MarketingButtons.getStarted,
      secondaryButton: MarketingButtons.bookDemo,
    },
    {
      type: "MediaSection",
      title: "Web app",
      subtitle: "Manage your assets directly from your browser.",
      image: {
        src: Images.Integrations.web,
        alt: "Launch the Lingo web app",
      },
      primaryButton: {
        text: "Launch web app",
        link: "/space",
        icon: "action.external-link",
      },
    },
    {
      type: "CardSection",
      title: "Integrations",
      // subtitle: "Assets created in Figma can be automatically synced to Lingo.",
      items: [
        {
          title: "Figma",
          body: "Assets created in Figma can be automatically synced to Lingo. ",
          image: {
            src: Images.Integrations.figma,
            alt: "Figma",
          },
          links: [{ text: "Learn more", link: "/integrations/figma" }],
        },
        {
          title: "Dropbox",
          body: "Import files from dropbox",
          image: {
            src: Images.Integrations.dropbox,
            alt: "Dropbox",
          },
          links: [{ text: "Learn more", link: "/integrations/dropbox" }],
        },
        {
          title: "Google Drive",
          body: "Import files from Google Drive",
          image: {
            src: Images.Integrations.google,
            alt: "Google Drive",
          },
          links: [{ text: "Learn more", link: "/integrations/google" }],
        },
        {
          title: "Lingo API",
          body: "Use the Lingo API to access your content programmatically and build your own digital asset management workflows.",
          image: {
            src: Images.Integrations.api,
            alt: "Lingo API",
          },
          links: [{ text: "Learn more", link: "/integrations/api" }],
        },
      ],
    },
    {
      type: "CtaSection",
    },
  ],
};
export default content;
