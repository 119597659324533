/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useState, useCallback, FormEvent } from "react";
import { Button, Input, Text, SpacePreview, Notice } from "@thenounproject/lingo-core";

import { AuthFormWrapper, authButtonStyle, AuthFormMessage } from "./AuthElements";
import useRequestPasswordReset from "@redux/actions/auth/useRequestPasswordReset";
import useResetPassword from "@redux/actions/auth/useResetPassword";
import AuthFormTitle from "./AuthFormTitle";

enum ViewState {
  requesting = "requesting",
  requested = "requested",
  resetting = "resetting",
  complete = "complete",
}

type Props = {
  token?: { token: string };
  space?: SpacePreview;
};

function PasswordReset({ token, space }: Props) {
  const { token: tokenString } = token || {};

  const [requestPasswordReset] = useRequestPasswordReset();
  const [resetPassword] = useResetPassword();

  const [formState, setFormState] = useState(
      tokenString ? ViewState.resetting : ViewState.requesting
    ),
    [email, setEmail] = useState(""),
    [newPassword, setNewPassword] = useState(""),
    [errors, setErrors] = useState<{
      email?: string;
      general?: string;
      confirm_password?: string;
    }>({}),
    [processing, setProcessing] = useState(false),
    [newPasswordConfirm, setNewPasswordConfirm] = useState(""),
    onSubmitResetRequest = useCallback(
      async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setErrors({});
        setProcessing(true);
        const res = await requestPasswordReset({ email });
        setProcessing(false);
        if (res.error) {
          setErrors({
            email: res.error.message,
          });
        } else {
          setFormState(ViewState.requested);
        }
      },
      [requestPasswordReset, email]
    ),
    onSubmitNewPassword = useCallback(
      async (e: FormEvent) => {
        e.preventDefault();

        if (newPassword !== newPasswordConfirm) {
          setErrors({
            confirm_password: "The two password fields didn't match.",
          });
          return;
        }
        setErrors({});
        setProcessing(true);
        const res = await resetPassword({ token: tokenString, password: newPassword });

        setProcessing(false);
        if (res.error) {
          setErrors({
            general: res.error.message,
          });
        } else {
          setErrors({});
          setNewPassword("");
          setNewPasswordConfirm("");
          setFormState(ViewState.complete);
        }
      },
      [newPassword, newPasswordConfirm, resetPassword, tokenString]
    );

  function renderRequestForm() {
    return (
      <AuthFormWrapper>
        <AuthFormTitle title="Forgot your password?" space={space} />
        <form data-testid="password-request-form" onSubmit={onSubmitResetRequest}>
          <Input
            id="email"
            onChange={e => {
              setEmail(e.target.value);
            }}
            value={email}
            autoComplete="username"
            type="email"
            label="Account email address"
            placeholder="your-sign-up-email@example.com"
            styleOverrides={{ pb: "m" }}
            message={errors.email}
            inputStyle={errors.email ? "error" : null}
          />
          <Button
            id="submit-button"
            {...authButtonStyle}
            type="submit"
            text="Request password reset"
            disabled={processing}
          />
          <AuthFormMessage>
            <Button
              id="login-button"
              text="Back to login"
              buttonStyle="tertiary"
              size="small"
              link={`/login/${window.location.search || ""}`}
            />
          </AuthFormMessage>
        </form>
      </AuthFormWrapper>
    );
  }

  function renderRequestComplete() {
    return (
      <AuthFormWrapper>
        <AuthFormTitle title="Check your inbox" space={space} />

        <Text textAlign="center">
          We&apos;ve sent you an email with instructions to reset your password. Please check your
          Spam inbox if you don&apos;t receive it shortly.
        </Text>

        <AuthFormMessage mt="m">
          <span>
            Still need help?
            <Button
              id="context-button"
              text="Contact customer support"
              buttonStyle="tertiary"
              size="small"
              link="/contact"
            />
          </span>
        </AuthFormMessage>
      </AuthFormWrapper>
    );
  }

  function renderResetForm() {
    return (
      <AuthFormWrapper>
        <AuthFormTitle title="Enter a new password" space={space} />
        <form data-testid="password-reset-form" onSubmit={onSubmitNewPassword}>
          <Input
            styleOverrides={{ pb: "m" }}
            id="new_password"
            autoComplete="new-password"
            type="password"
            label="New password"
            value={newPassword}
            onChange={e => {
              setErrors({});
              setNewPassword(e.target.value);
            }}
          />
          <Input
            styleOverrides={{ pb: "m" }}
            id="new_password_confirm"
            type="password"
            autoComplete="new-password"
            label="New password confirmation"
            value={newPasswordConfirm}
            onChange={e => {
              setErrors({});
              setNewPasswordConfirm(e.target.value);
            }}
            message={errors.confirm_password}
            inputStyle={errors.confirm_password ? "error" : null}
          />

          {errors.general && <Notice mb="l" message={errors.general} noticeStyle="error" />}
          <Button
            id="submit-button"
            {...authButtonStyle}
            type="submit"
            title="Update password"
            text="Update password"
            disabled={processing}
          />
          <AuthFormMessage>
            <Button
              id="login-button"
              text="Back to login"
              buttonStyle="tertiary"
              size="small"
              link={`/login/${window.location.search || ""}`}
            />
          </AuthFormMessage>
        </form>
      </AuthFormWrapper>
    );
  }

  function renderResetComplete() {
    return (
      <AuthFormWrapper>
        <AuthFormTitle title="Password updated" space={space} />

        <Text textAlign="center">
          Your password has been reset. Please login with you new password to access your account.
        </Text>
        <Button {...authButtonStyle} id="login-button" text="Login" link="/login" />
      </AuthFormWrapper>
    );
  }

  switch (formState) {
    case ViewState.requesting:
      return renderRequestForm();
    case ViewState.requested:
      return renderRequestComplete();
    case ViewState.resetting:
      return renderResetForm();
    case ViewState.complete:
      return renderResetComplete();
    default:
      return null;
  }
}

export default PasswordReset;
