import { PageContent } from "../../pages/templates/PageLayout";

const content: PageContent = {
  style: "lightGradient",
  sections: [
    {
      type: "FormSection",
      title: "Learn about Lingo",
      subtitle:
        "We're happy to answer your questions. Secure an appointment with one of our reps so we can learn more about your needs.",
      badge: "Contact sales",
      form: "sales",
      position: { mb: "none", pb: "200" },
    },
    { type: "CustomerLogosSection", position: { mt: "-xxl" } },
  ],
};

export default content;
