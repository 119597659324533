import { Button, Flex } from "@thenounproject/lingo-core";
import React from "react";

type Props = {
  onSave: () => void;
  onCancel: () => void;
  disabled: boolean;
  isProcessing?: boolean;
};
const ThemeEditorPanelHeader: React.FC<Props> = ({ onSave, onCancel, disabled, isProcessing }) => {
  return (
    <Flex
      px="m"
      py="m"
      justifyContent="center"
      gap="8px"
      background="white"
      borderBottom="default"
      position="sticky"
      zIndex={99999}
      top={0}>
      <Button width="100%" text="Cancel" size="small" buttonStyle="secondary" onClick={onCancel} />
      <Button
        text={isProcessing ? "Saving" : "Save"}
        size="small"
        onClick={onSave}
        disabled={disabled}
        width="100%"
      />
    </Flex>
  );
};

export default ThemeEditorPanelHeader;
