import styled from "styled-components";

const ExportOption = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 4px;
  transition: opacity 0.5s ease;
  opacity: 1;
  pointer-events: all;
  &:hover {
    button[data-hover="shown"] {
      opacity: 1;
    }
  }
  button[data-hover="shown"] {
    transition: opacity 0.5s ease;
    opacity: 0;
  }
`;
export default ExportOption;
