import { Item } from "@thenounproject/lingo-core";
import { createAction } from "../actionCreators";

type Args = {
  item: Item;
  format: string;
};

const [useTrackColorUsage, trackColorUsage] = createAction<Args>("analytics/trackColorUsage");

export default useTrackColorUsage;
export { trackColorUsage };
