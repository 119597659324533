import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Flex } from "@thenounproject/lingo-core";

type Props = {
  enabled: boolean;
  children: React.ReactNode;
};

type WrapperProps = {
  enabled: boolean;
};

const EasingWrapper = styled(Flex).attrs<WrapperProps>({
  height: "100%",
  width: "100%",
})<WrapperProps>`
  transition: opacity 0.3s ease;
  opacity: ${props => (props.enabled ? "1" : "0")};
`;

const EaseOutDerender: React.FC<Props> = ({ enabled, children }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (enabled) setVisible(true);
    else {
      const timer = setTimeout(() => setVisible(false), 300);
      return () => clearTimeout(timer);
    }
  }, [enabled]);

  return <EasingWrapper enabled={enabled}>{visible && children}</EasingWrapper>;
};

export default EaseOutDerender;
