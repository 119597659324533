import { Middleware, UnknownAction } from "@reduxjs/toolkit";
import { enqueueUpload } from "@redux/actions/uploads/enqueueUpload";
import { uploadFile } from "@redux/actions/uploads/uploadFile";
import { type AppStore } from "@redux/store/reduxStore";

const uploads: Middleware = (store: AppStore) => next => (action: UnknownAction) => {
  const res = next(action);
  if (enqueueUpload.match(action)) {
    const { upload } = action.payload;
    const {
      uploads: { processingQueue, uploads },
    } = store.getState();

    // If the upload does not have an error AND it doesn't have a batchId OR it does have a batchId but there are no other
    const shouldStart =
      !upload.error &&
      (!upload.batchId ||
        !processingQueue.some(
          u => u !== upload.id && uploads[u].upload.batchId === upload.batchId
        ));
    if (shouldStart) {
      void store.dispatch(uploadFile(action.payload));
    }
  } else if (uploadFile.fulfilled.match(action)) {
    // if there are more uploads in the queue, dispatch the next one
    const { upload: completedUpload } = action.meta.arg;
    const { uploads } = store.getState();

    if (completedUpload.batchId) {
      const nextInBatch = uploads.processingQueue.find(
        u => uploads.uploads[u].upload.batchId === completedUpload.batchId
      );
      if (nextInBatch) {
        void store.dispatch(uploadFile(uploads.uploads[nextInBatch]));
      }
    }
  }
  return res;
};
export default uploads;
