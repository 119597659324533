import { API, PortalItem } from "@thenounproject/lingo-core";

import { createAsyncAction } from "../actionCreators";
import { fetchContentTypes } from "../useFetchContentTypes";
import { type UploadAction, uploadActionForFile } from "../uploads";
import { enqueueUpload } from "../uploads/enqueueUpload";

type Args = {
  portalItem: PortalItem;
  file: File;
};

const [useUpdatePortalFileAsset, updatePortalFileAsset] = createAsyncAction(
  "portalItems/updateFileAsset",
  async ({ portalItem, file }: Args, thunkApi) => {
    const contentTypes = await fetchContentTypes.lazy(thunkApi, {
      itemType: portalItem?.type,
      assetType: portalItem?.asset?.type,
    });

    const { upload, asset } = uploadActionForFile(file, contentTypes, {
      portalId: portalItem?.portalId,
      spaceId: portalItem?.spaceId ?? portalItem.asset?.spaceId,
    });

    const uploadAction: UploadAction = {
      upload,
      requestData: {
        endpoint: `portal_items/${portalItem.id}`,
        entity: API.Entity.portalItem,
        method: "PUT",
        file,
        data: { asset },
      },
    };
    thunkApi.dispatch(enqueueUpload(uploadAction));
  }
);

export default useUpdatePortalFileAsset;
export { updatePortalFileAsset };
