/**
 * Custom fields reducer
 */

import _merge from "lodash/merge";

import { CustomField } from "@thenounproject/lingo-core";

import createEntityReducer from "../helpers/createEntityReducer";

export default createEntityReducer<CustomField>(
  "fields",
  _queryBuilder => {
    // Nothing to do yet
  },
  objectBuilder => {
    objectBuilder.addDefaultCase((state, action: any) => {
      const fields = action.payload?.entities?.fields ?? action.response?.entities?.fields;
      if (fields) {
        return _merge(state, fields);
      }
      return state;
    });
  }
);
