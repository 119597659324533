import { configureStore } from "@reduxjs/toolkit";
import { API } from "@thenounproject/lingo-core";

import analytics from "../middleware/analytics";
import polling from "../middleware/polling";
import downloadFile from "../middleware/downloadFile";
import notifications from "../middleware/notifications";
import uploads from "../middleware/uploads";
import rootReducer from "../reducers";
import { reduxEnhancer } from "../../adapters/sentry";
import mutations from "@redux/middleware/mutations";
import { cookies } from "@redux/middleware/cookies";

const isProd = process.env.NODE_ENV === "production";

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([
      cookies,
      API.middleware,
      downloadFile,
      polling,
      analytics,
      notifications,
      uploads,
      mutations,
    ]),
  enhancers: getDefault => {
    const enhancers = getDefault();
    if (isProd) enhancers.push(reduxEnhancer);
    return enhancers;
  },
  // show redux devtools in non-production dev and qa environments
  devTools:
    _lingoConfig?.environment !== "production"
      ? { name: "Lingo_V1", trace: true, traceLimit: 50 }
      : false,
});

if (module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept("../reducers", () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const nextReducer = require("../reducers").default;
    store.replaceReducer(nextReducer);
  });
}

export { store };

export type AppStore = typeof store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type GetState = () => RootState;
