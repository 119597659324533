import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";
/**
 * Retry asset processing for a file
 */

type Args = {
  assetId: string;
};

const [useReprocessAsset, reprocessAsset] = createAsyncAction(
  "assets/reprocessAsset",
  async ({ assetId }: Args) => {
    const res = await API.call<string>({
      endpoint: `assets/${assetId}/process`,
      method: "POST",
      entity: API.Entity.asset,
    });
    return res;
  }
);

export default useReprocessAsset;
export { reprocessAsset };
