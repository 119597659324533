import { API, PortalMember } from "@thenounproject/lingo-core";

import { createQueryAction } from "@actions/actionCreators";

type Args = {
  portalId: string;
};

type NormalizedResult = {
  members: string[];
  total: number;
};

type Result = {
  members: PortalMember[];
  total: number;
};

const [usePortalMembers, queryPortalMembers] = createQueryAction<Args, Result, NormalizedResult>(
  {
    entity: "portalMembers",
    action: "query",
    condition: ({ args: { portalId } }) => Boolean(portalId),
    pagingKey: "members",
    denormalize: {
      entity: null,
      members: {
        entity: "portalMembers",
        user: "users",
      },
    },
  },
  async ({ args }) =>
    await API.call<NormalizedResult>({
      endpoint: `portals/${args.portalId}/members`,
      method: "GET",
      entity: API.Entity.portalMember,
      query: { spaceRole: true },
    })
);

export default usePortalMembers;
export { queryPortalMembers };
