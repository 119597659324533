import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  toSectionId: string;
  headingId: string;
  createReferences: boolean;
};

const [useDuplicateHeading, duplicateHeading] = createAsyncAction(
  "items/duplicateHeading",
  async ({ toSectionId, headingId, createReferences }: Args) => {
    return await API.call<{ items: string[] }>({
      endpoint: `headings/${headingId}/duplicate`,
      method: "PUT",
      entity: API.Entity.item,
      data: {
        section_uuid: toSectionId,
        create_references: createReferences,
      },
    });
  }
);

export default useDuplicateHeading;
export { duplicateHeading };
