import React from "react";
import styled from "styled-components";
import { Box, SpaceThemeNames } from "@thenounproject/lingo-core";
import LazyImage from "../../../marketing/components/LazyImage";

import getImageDimensionPadding from "@helpers/getImageDimensionPadding";

const titleThemeVals = {
  [SpaceThemeNames.classic]: {
    textAlign: "center",
  },
};

const Wrapper = styled(Box).attrs<{ maxWidth: number }>(props => {
  const overrides = titleThemeVals[props.theme.themeName];
  return { maxWidth: props.maxWidth, width: "100%", ...overrides };
})`
  img {
    max-width: 100%;
  }
`;

type Props = {
  height?: number;
  width?: number;
  url: string;
};

const SpaceHeaderLogo: React.FC<Props> = ({ height, width, url }) => {
  const maxWidth = width < 400 ? width : 400;
  return (
    <Wrapper maxWidth={maxWidth}>
      {width && height ? (
        <LazyImage
          src={url}
          imagePadding={getImageDimensionPadding(width, height)}
          alt="Space logo"
        />
      ) : (
        <img src={url} alt="Space logo" />
      )}
    </Wrapper>
  );
};

export default SpaceHeaderLogo;
