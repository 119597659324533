import { Asset, Item, Tag, ItemType, RequireAtLeastOne } from "@thenounproject/lingo-core";

export enum InspectorSource {
  kit = "kit",
  library = "library",
  tags = "tags",
}

export type Inspectable = RequireAtLeastOne<{
  asset?: Asset;
  item?: Item;
  tag?: Tag;
}>;

export function isAssetInspectable(i: Inspectable) {
  return !i.item || i.item?.type === ItemType.asset;
}
