import { combineReducers } from "@reduxjs/toolkit";
import _merge from "lodash/merge";
import _remove from "lodash/remove";
import { createReducer } from "@reduxjs/toolkit";
import { AssetView } from "@thenounproject/lingo-core";
import { mergeQuery } from "../helpers/mergeQuery";
import { QueryData } from "@redux/actions/actionCreators/createQueryAction";
import { createView } from "@redux/actions/views/useCreateView";
import { fetchAssetViews } from "@redux/actions/views/useAssetViews";
import { deleteView } from "@redux/actions/views/useDeleteView";

const queries = createReducer<Record<string, QueryData<unknown>>>({}, builder => {
  builder
    .addCase(createView.fulfilled, (state, action) => {
      const newView = action.payload.entities.views[action.payload.result];
      fetchAssetViews.getQueryData(state, { spaceId: newView.spaceId }).forEach(query => {
        query.data.push(action.payload.result);
      });
    })
    .addCase(deleteView.fulfilled, (state, action) => {
      const newView = action.payload.entities.views[action.payload.result];
      fetchAssetViews.getQueryData(state, { spaceId: newView.spaceId }).forEach(query => {
        _remove(query.data, id => id === action.payload.result);
      });
    })
    .addDefaultCase((state, action) => {
      mergeQuery(state, action, "views");
    });
});

const objects = createReducer<Record<string, AssetView>>({}, builder => {
  builder.addDefaultCase((state, action: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const views = action.response?.entities?.views ?? action.payload?.entities?.views;
    if (views) {
      return _merge(state, views);
    }
    return state;
  });
});

export default combineReducers({
  queries,
  objects,
});
