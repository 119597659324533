import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  kitId: string;
  spaceId: string | number;
};
const [useDuplicateKit, duplicateKit] = createAsyncAction(
  "kits/duplicate",
  async ({ kitId, spaceId }: Args) => {
    return await API.call<string>({
      endpoint: `/kits/${spaceId}/${kitId}/duplicate`,
      method: "POST",
      entity: API.Entity.kitVersion,
    });
  }
);
export default useDuplicateKit;
export { duplicateKit };
