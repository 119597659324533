/**
 * Brings up the asset download options in a popup by clicking an element (typically a button).
 *
 * This component uses the "render prop" approach. The button used to open the popup should be
 * rendered within a function passed in as props.children (aka render prop). The actual element that
 * the user clicks needs to use buttonProps.
 *
 * <AssetDownloadPopup item={...} popupVPos="alignTop" popupHPos="floatRight">
 *   {({ buttonProps }) => {
 *     return <Button {...buttonProps} text="Download" />
 *   }}
 * </AssetDownloadPopup>;
 *
 * Also provided to the render function are noCuts and canManageCuts, which provide data on whether
 * all cuts have been disabled and whether the user has the ability to manage cuts, respectively
 *
 * <AssetDownloadPopup item={...} popupVPos="alignTop" popupHPos="floatRight">
 *   {({ buttonProps, noCuts, canManageCuts }) => {
 *     return (
 *        <Button
 *          {...buttonProps}
 *          text={canManageCuts ? "Download/Manage" : "Download"}
 *          icon={!noCuts ? "triangle-double" : "download"}
 *        />
 *      );
 *   }}
 * </AssetDownloadPopup>;
 */

import React, { Fragment, useState, useCallback } from "react";
import styled from "styled-components";
import {
  Flex,
  AssetType,
  PopupMenu,
  Text,
  Button,
  AnyObject,
  ErrorCode,
  SpacePermission,
} from "@thenounproject/lingo-core";

import CustomExportOption from "./CustomExportOption";
import PresetExportOption from "./PresetExportOption";
import { Inspectable } from "@constants/Inspector";
import useGenerateFilecut from "@actions/useGenerateFilecut";
import useNotifications from "@actions/useNotifications";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import useDownloadFile from "@actions/useDownloadFile";
import useNavPoint from "@hooks/useNavPoint";

const ExportMenu = styled(PopupMenu).attrs({
  source: "inspector-asset-download",
  p: "m",
  width: 287,
})``;

const ExportTitle = styled(Flex).attrs({
  mb: "s",
  justifyContent: "space-between",
})``;

const SketchTitle = styled(Text).attrs({
  color: "grayDark",
  mb: "s",
  font: "ui.small",
})``;

export type DownloadAsset = (
  type: string,
  size: string,
  dpi?: number,
  e?: React.MouseEvent
) => void;

export type AssetDownloadPopupRenderProps = {
  canManageCuts: boolean;
  noCuts: boolean;
  buttonProps: AnyObject & { onClick: (e: React.MouseEvent) => void };
};

type Props = {
  inspectable: Inspectable;
  canEdit?: boolean;
  popupVPos: string;
  popupHPos: string;
  children?: (renderProps: AssetDownloadPopupRenderProps) => React.ReactNode;
};

const AssetDownloadPopup: React.FC<Props> = ({
  inspectable,
  canEdit = false,
  popupVPos,
  popupHPos,
  children,
}) => {
  const canManageCuts = canEdit;
  const [exportMenuOpen, setExportMenuOpen] = useState(false);
  const { asset } = inspectable;
  const noCuts = !asset.meta.filecuts;
  const [generateFilecut] = useGenerateFilecut();
  const { showNotification } = useNotifications();
  const { showModal, dismissModal } = useShowModal();
  const downloadFile = useDownloadFile();
  const { space, portalId } = useNavPoint();

  const downloadAsset: DownloadAsset = useCallback(
    async (fileType: string, dimensions: string, dpi?: number, e?: React.MouseEvent) => {
      if (e) e.preventDefault();

      const sizeIfDifferent = dimensions !== "1x" ? ` @${dimensions}` : "",
        typeIfDifferent =
          sizeIfDifferent || fileType.toLowerCase() !== asset.type.toLowerCase()
            ? ` ${fileType}`
            : "";

      const assetName = asset.name || asset.meta.font?.displayName || "Unnamed asset";

      setExportMenuOpen(false);

      const useLibrary =
        space?.access?.permissions?.includes(SpacePermission.viewAssetLibrary) === true;

      const action = await generateFilecut({
        inspectable,
        fileType,
        dimensions,
        dpi,
        portalId,
        isAdmin: useLibrary,
      });
      if (action.error) {
        const { message } = action.error || {};
        if (action.error.code === ErrorCode.downloadRequiresIdentity) {
          return showModal(ModalTypes.INFO_PROMPT, {
            callback: () => downloadAsset(fileType, dimensions, dpi, e),
          });
        }
        if (action.error.code === ErrorCode.downloadRequestPending) {
          return showModal(ModalTypes.CONFIRMATION, {
            title: "Request Pending",
            message,
            buttonText: "Continue",
            buttonProcessingText: "Continue",
            onConfirm: dismissModal,
          });
        }
        if (action.error.code === ErrorCode.downloadRequiresVerifiedIdentity) {
          return showModal(ModalTypes.CONFIRMATION, {
            title: "Request Approved",
            message,
            buttonText: "Continue",
            buttonProcessingText: "Continue",
            onConfirm: dismissModal,
          });
        }
        if (action.error.code === ErrorCode.downloadRequestDenied) {
          return showModal(ModalTypes.CONFIRMATION, {
            title: "Request Denied",
            message,
            buttonText: "Continue",
            buttonProcessingText: "Continue",
            onConfirm: dismissModal,
          });
        }
        showNotification({
          message:
            message ||
            "Unfortunately, there was a problem starting your download.  Please try again later.",
          level: "error",
        });
      } else {
        showNotification({
          message: `Downloading ${assetName}${typeIfDifferent}${sizeIfDifferent}.`,
        });
        downloadFile(action.response.result.url);
      }
    },
    [
      asset.type,
      asset.name,
      generateFilecut,
      inspectable,
      space?.access?.role,
      showNotification,
      showModal,
      dismissModal,
      downloadFile,
    ]
  );

  const openManageModal = useCallback(() => {
    setExportMenuOpen(false);
    showModal(ModalTypes.MANAGE_FILECUTS, { inspectable });
  }, [inspectable, showModal]);

  function renderExportOptions() {
    const { meta } = asset,
      fromSketch = AssetType.sketchTypes.has(asset.type);

    const presetOptions =
      meta.filecuts &&
      meta.filecuts.presets.map((preset, i) => (
        <PresetExportOption
          asset={asset}
          type={preset.type}
          description={preset.description}
          size={preset.size}
          dpi={preset.dpi}
          downloadAsset={downloadAsset}
          key={i}
          data-testid={`dl-btn-${i}`}
        />
      ));

    return (
      <Fragment>
        {fromSketch ? (
          <SketchTitle>Sketch data unavailable to download from the web</SketchTitle>
        ) : null}
        {presetOptions}
        <CustomExportOption asset={asset} downloadAsset={downloadAsset} />
      </Fragment>
    );
  }

  const closeMenu = useCallback(() => setExportMenuOpen(false), []);
  return (
    <Fragment>
      {children({
        buttonProps: {
          "data-popup-source": exportMenuOpen && "inspector-asset-download",
          onClick: () => (noCuts ? downloadAsset(asset.type, "1x") : setExportMenuOpen(true)),
        },
        noCuts,
        canManageCuts,
      })}

      {exportMenuOpen && !noCuts && (
        <ExportMenu vPos={popupVPos} hPos={popupHPos} close={closeMenu} stayOpenOnClick>
          <ExportTitle>
            <Text font="ui.smallBold">Download as</Text>
            {canManageCuts && (
              <Button
                text="Manage"
                buttonStyle="tertiary"
                size="small"
                data-test="open-filecut-manager"
                onClick={openManageModal}
              />
            )}
          </ExportTitle>
          {renderExportOptions()}
        </ExportMenu>
      )}
    </Fragment>
  );
};

export default AssetDownloadPopup;
