import React from "react";
import styled from "styled-components";
import { Flex, SpaceThemeNames, utils, styles } from "@thenounproject/lingo-core";

import KitNavSectionItem, { KitNavSectionData } from "./KitNavSectionItem";
import KitNavHeaderItem from "./KitNavHeaderItem";

type Props = {
  sections: KitNavSectionData[];
};

const Wrapper = styled(Flex).attrs({ as: "nav", flexDirection: "column" })``;

type HeaderListWrapperProps = {
  shown: boolean;
  maxHeight: number;
};

const HeaderListWrapper = styled(Flex).attrs<HeaderListWrapperProps>(props => {
  return {
    flexDirection: "column",
    width: "100%",
    transition: "height 0.3s ease",
    maxHeight: props.shown ? `${props.maxHeight}px` : "0px",
    overflow: "hidden",
  };
})<HeaderListWrapperProps>``;

const getChildNavInnerThemeStyles = (props: utils.ThemedProps) => {
  return {
    [SpaceThemeNames.classic]: {
      gap: "8px",
      pl: "12px",
      mt: "8px",
    },
    [SpaceThemeNames.scher]: {
      gap: "16px",
      pl: "12px",
      pb: "12px",
    },
    [SpaceThemeNames.wyman]: { gap: "8px", pl: "12px", pt: "4px", pb: "8px" },
  }[props.theme.themeName];
};

const ChildNavInner = styled(Flex).attrs(props => {
  return { flexDirection: "column", ...getChildNavInnerThemeStyles(props) };
})``;

type SectionWrapperProps = {
  selected: boolean;
  hasChildren: boolean;
};

const getSectionWrapperThemeStyles = (props: SectionWrapperProps & utils.ThemedProps) => {
  return (
    {
      [SpaceThemeNames.classic]: {
        mb: props.selected && props.hasChildren ? "16px" : "4px",
      },
      [SpaceThemeNames.scher]: {
        mb: props.selected && props.hasChildren ? "8px" : "0px",
      },
      [SpaceThemeNames.wyman]: {
        mb: props.selected && props.hasChildren ? "16px" : "4px",
        borderRadius: utils.getBorderRadius(SpaceThemeNames.wyman, null),
        border: props.selected ? `1px solid ${styles.color.grayLight}` : "1px solid transparent",
      },
    }[props.theme.themeName] || {}
  );
};

const SectionWrapper = styled(Flex).attrs<SectionWrapperProps>(props => {
  return {
    flexDirection: "column",
    transition: "all 0.3s ease",
    ...getSectionWrapperThemeStyles(props),
  };
})<SectionWrapperProps>``;

const KitNavItems: React.FC<Props> = ({ sections }) => {
  return (
    <Wrapper>
      {sections.map((sectionData, idx) => {
        const headersForSection =
          sectionData.headers?.map(header => (
            <KitNavHeaderItem key={header.header.id} {...header} />
          )) || [];
        return (
          <SectionWrapper
            selected={sectionData.selected}
            hasChildren={Boolean(headersForSection.length)}
            key={sectionData.section.name.concat(String(idx))}>
            <KitNavSectionItem {...sectionData} />
            {Boolean(headersForSection.length) && (
              <HeaderListWrapper
                shown={sectionData.selected}
                maxHeight={52 * headersForSection.length}>
                <ChildNavInner>{headersForSection}</ChildNavInner>
              </HeaderListWrapper>
            )}
          </SectionWrapper>
        );
      })}
    </Wrapper>
  );
};
export default React.memo(KitNavItems) as typeof KitNavItems;
