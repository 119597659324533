import React, { ComponentProps, Fragment, useMemo } from "react";
import {
  copy,
  PopupMenu,
  Tooltip,
  Button,
  SpacePermission,
  PortalItem,
  PortalItemType,
  KitPermission,
  buildURL,
  pluralize,
  Portal,
} from "@thenounproject/lingo-core";

import usePortalInspectableActions from "@features/context-menus/hooks/usePortalInspectableActions";
import { useSelectSpace } from "@selectors/entities/spaces";
import useUpsell, { AccessStatus } from "@hooks/useUpsell";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import { NoteBackgroundColorOptions } from "./components/NoteBackgroundColorOptions";

type Props = {
  // Menu
  position?: {
    x: string | number;
    y: string | number;
  };
  popupSource?: string;
  onCloseMenu: () => void;
  // Data
  portal: Portal;
  inspectables: PortalItem[];
  canEdit: boolean;
  onEditItem?: (itemId: string) => void;

  vPos?: ComponentProps<typeof PopupMenu>["vPos"];
  hPos?: ComponentProps<typeof PopupMenu>["hPos"];
};

const PortalItemsContextMenu: React.FC<Props> = ({
  position,
  popupSource,
  onCloseMenu,
  inspectables,
  canEdit,
  onEditItem,
  vPos,
  hPos,
  portal,
}) => {
  const contextMenuSource = "gallery-context-menu";
  const portalItemsContextMenuOptions = PortalItemsContextMenuOptions({
    inspectables: inspectables,
    canEdit: canEdit,
    onEditItem: onEditItem,
    portal,
  });

  if (!portalItemsContextMenuOptions) return null;
  const { x: xPos, y: yPos } = position || { x: "0", y: "0" };
  return (
    <Fragment>
      {position ? (
        <PopupMenu.ContextMenuAnchor
          xPos={xPos}
          yPos={yPos}
          data-popup-source={contextMenuSource}
        />
      ) : null}
      <PopupMenu
        data-testid="context-menu-popup"
        source={popupSource ?? contextMenuSource}
        close={onCloseMenu}
        vPos={vPos || "floatBelow"}
        hPos={hPos || "alignLeft"}>
        <PortalItemsContextMenuOptions
          inspectables={inspectables}
          canEdit={canEdit}
          onEditItem={onEditItem}
          portal={portal}
        />
      </PopupMenu>
    </Fragment>
  );
};
export default PortalItemsContextMenu;

type PortalItemProps = {
  portal: Portal;
  inspectables: PortalItem[];
  canEdit: boolean;
  onEditItem?: (itemId: string) => void;
};

export const PortalItemsContextMenuOptions: React.FC<PortalItemProps> = ({
  inspectables,
  canEdit,
  onEditItem,
  portal,
}) => {
  const space = useSelectSpace();
  const { showModal } = useShowModal();
  const { deleteItems, editItemBackground, replaceFile } = usePortalInspectableActions(
    inspectables,
    portal.id
  );

  const { accessStatus: advancedAnalyticsAccessStatus, openUpgradeModal } = useUpsell(
    SpacePermission.viewAdvancedAnalytics
  );
  const mustUpgradeAdvancedAnalytics =
    advancedAnalyticsAccessStatus === AccessStatus.insufficientPlan;

  const everyInspectableIsKit = inspectables.every(i => i?.type === PortalItemType.kit);

  function copyTextContentItem() {
    if (inspectables.length !== 1) return null;
    const textContent = inspectables[0]?.data.content || null;
    if (!textContent) return null;
    const title = "Copy";
    return (
      <PopupMenu.Item
        key={title}
        title={title}
        onClick={() => {
          copy(textContent);
        }}
      />
    );
  }

  function editContentItem() {
    if (!canEdit) return null;
    if (!onEditItem) return null;
    if (inspectables.length !== 1) return null;
    const [portalItem] = inspectables;
    const types = [PortalItemType.note, PortalItemType.heading];
    if (!types.includes(portalItem.type as PortalItemType)) return null;
    const title = "Edit";
    return (
      <PopupMenu.Item
        key={title}
        title={title}
        onClick={() => {
          onEditItem(portalItem.id);
        }}
      />
    );
  }

  function deleteItem() {
    if (!canEdit) return null;
    const title = everyInspectableIsKit
      ? `Remove ${pluralize("kit", inspectables.length)} from portal`
      : "Delete";
    return <PopupMenu.Item key={title} title={title} onClick={deleteItems} />;
  }

  function renderViewKitsInsights() {
    if (advancedAnalyticsAccessStatus === AccessStatus.insufficientRole) return null;
    if (inspectables.length !== 1) return null;
    if (!everyInspectableIsKit) return null;
    const kit = inspectables[0]?.kit;
    return (
      <React.Fragment key="kit-menu-view-insights">
        <PopupMenu.Item
          title="View insights"
          disabled={mustUpgradeAdvancedAnalytics}
          onClick={() =>
            showModal(ModalTypes.INSIGHTS_KIT, { kitId: kit.kitId, kitName: kit.name, space })
          }
          data-tooltip-source="insights-kit-menu-item"
        />
        {mustUpgradeAdvancedAnalytics && (
          <Tooltip source="insights-kit-menu-item" direction={Tooltip.Direction.Right}>
            Insights are not available on your current plan.{" "}
            <Button
              buttonStyle="tertiary"
              themeOverrides={{ primaryColor: "white" }}
              fontStyle="ui.smallBold"
              text="View plans."
              onClick={openUpgradeModal}
            />
          </Tooltip>
        )}
      </React.Fragment>
    );
  }

  function renderReplaceFile() {
    if (!canEdit) return null;
    if (!replaceFile) return null;
    if (inspectables.length !== 1) return null;
    return <PopupMenu.Item key="Replace File" title="Replace File" onClick={replaceFile} />;
  }

  function renderShareKit() {
    if (inspectables.length !== 1) return null;
    if (!everyInspectableIsKit) return null;
    const kit = inspectables[0]?.kit;
    const permissions = kit?.access?.permissions || [];
    return permissions.includes(KitPermission.changeOwnStatus) ? (
      <PopupMenu.Item
        key="kit-menu-share"
        title="Share kit"
        onClick={() => showModal(ModalTypes.SHARE_KIT, { kitId: kit.kitId })}
      />
    ) : (
      <PopupMenu.Item
        key="kit-menu-copy-share-link"
        title="Copy share link"
        onClick={() => copy(buildURL(`/k/${kit.shortId}`, { space }))}
      />
    );
  }

  function renderEditKit() {
    if (inspectables.length !== 1) return null;
    if (!everyInspectableIsKit) return null;
    const kit = inspectables[0]?.kit;
    const permissions = kit?.access?.permissions || [];
    const canEdit = permissions.includes(KitPermission.edit);
    if (!canEdit) return null;
    if (!showModal) return null;
    return (
      <PopupMenu.Item
        key="kit-menu-edit-kit"
        title="Edit kit"
        onClick={() => showModal(ModalTypes.EDIT_KIT, { kit: kit })}
      />
    );
  }

  const selectedColor = useMemo(() => {
    if (!inspectables.length) {
      return;
    }
    // Check if all items have the same background color
    const sameBackgroundColor = inspectables.every(
      i => i?.data?.backgroundColor === inspectables[0]?.data?.backgroundColor
    );

    if (sameBackgroundColor && inspectables[0]?.data?.backgroundColor !== "default")
      return inspectables[0]?.data?.backgroundColor;

    // Return red so that users can also select using color spectrum slider
    return "#ff0000";
  }, [inspectables]);

  function backgroundColorNote() {
    if (!canEdit) return null;
    if (!editItemBackground) return null;
    return (
      <React.Fragment key="note-bg-options">
        <NoteBackgroundColorOptions
          selectedColor={selectedColor}
          editItemBackground={editItemBackground}
        />
      </React.Fragment>
    );
  }

  // Rendering multi item menu
  if (inspectables.length === 0) return null;
  let title = null;
  if (inspectables.length > 1) {
    title = `${inspectables.length} items selected`;
  }

  const firstLevelMenuItems = [
    copyTextContentItem(),
    renderShareKit(),
    renderViewKitsInsights(),
  ].filter(Boolean);

  const secondLevelMenuItems = [
    editContentItem(),
    backgroundColorNote(),
    renderEditKit(),
    renderReplaceFile(),
  ].filter(Boolean);

  const thirdLevelMenuItems = [deleteItem()].filter(Boolean);

  /**
   * If there are no actions to show, return null
   */
  if ([...firstLevelMenuItems, ...secondLevelMenuItems, ...thirdLevelMenuItems].length === 0) {
    return null;
  }

  return (
    <Fragment>
      {firstLevelMenuItems.length > 0 && (
        <PopupMenu.Section title={title}>{firstLevelMenuItems}</PopupMenu.Section>
      )}
      {secondLevelMenuItems.length > 0 && (
        <PopupMenu.Section>{secondLevelMenuItems}</PopupMenu.Section>
      )}
      {thirdLevelMenuItems.length > 0 && (
        <PopupMenu.Section>{thirdLevelMenuItems}</PopupMenu.Section>
      )}
    </Fragment>
  );
};
