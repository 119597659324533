import React, { ComponentProps } from "react";
import { Text, Flex, Icon, BoxProps, FlexProps, Button } from "@thenounproject/lingo-core";

import GalleryDropZone from "./kits/GalleryDropZone";
import PortalDropZone from "./portals/PortalDropZone";

const styleReference = {
  large: {
    titleStyles: { as: "h1", font: "ui.mediumTitle", mb: "s" },
    subtitleStyles: { as: "h2", font: "ui.pageTitle", color: "grayDarkest" },
  },
  regular: {
    titleStyles: { as: "h2", font: "ui.title", mb: "xxs", color: "grayDarker" },
    subtitleStyles: {
      as: "h3",
      font: "ui.regular",
      color: "grayDarker",
      maxWidth: "400px",
    },
  },
  small: {
    titleStyles: {
      as: "h3",
      font: "ui.regularBold",
      mb: "xxs",
      color: "grayDarker",
    },
    subtitleStyles: {
      font: "ui.regular",
      color: "grayDarker",
      maxWidth: "400px",
    },
  },
};

type Props = {
  title?: string;
  subtitle?: string;
  extra?: React.ReactNode;
  dropZoneProps?: React.ComponentProps<typeof GalleryDropZone>;
  portalDropZoneProps?: React.ComponentProps<typeof PortalDropZone>;
  iconProps?: React.ComponentProps<typeof Icon>;
  size?: "large" | "regular" | "small";
  styleOverrides?: BoxProps & FlexProps;
  button?: ComponentProps<typeof Button>;
  onDragEnter?: React.DragEventHandler<HTMLDivElement>;
  onDragOver?: React.DragEventHandler<HTMLDivElement>;
  onDragLeave?: React.DragEventHandler<HTMLDivElement>;
  onDrop?: React.DragEventHandler<HTMLDivElement>;
};

export default function EmptyState({
  iconProps = null,
  title,
  subtitle,
  size = "regular",
  button = null,
  extra = null,
  dropZoneProps = null,
  portalDropZoneProps = null,
  styleOverrides,
  onDragEnter,
  onDragOver,
  onDragLeave,
  onDrop,
}: Props) {
  const sizeStyles: React.ComponentProps<typeof Text> =
    styleReference[size] || styleReference.regular;

  function renderImage() {
    if (!iconProps) return null;
    return <Icon size="60px" fill="gray" my={size === "small" ? "s" : "m"} {...iconProps} />;
  }

  return (
    <Flex
      alignItems="center"
      py="120px"
      flexDirection="column"
      textAlign="center"
      position="relative"
      width={"100%"}
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      {...styleOverrides}>
      {renderImage()}
      {dropZoneProps && dropZoneProps.kitId ? (
        <GalleryDropZone {...dropZoneProps} catchAll />
      ) : null}
      {portalDropZoneProps && portalDropZoneProps.portalId ? (
        <PortalDropZone {...portalDropZoneProps} catchAll />
      ) : null}
      <Text {...sizeStyles.titleStyles}>{title}</Text>
      <Text {...sizeStyles.subtitleStyles}>{subtitle}</Text>
      {button && <Button mt="l" {...button} />}
      {extra}
    </Flex>
  );
}
