import { MarketingButtons } from "../../components/MarketingButtons";
import { PageContent } from "../../pages/templates/PageLayout";
import { exampleLinks } from "./exampleLinks";
import featureCards from "../featureCards";
import Images from "../images";

const data: PageContent = {
  style: "light",
  sections: [
    {
      type: "HeroSection",
      title: "Digital brand hub",
      subtitle: `Stop the barrage of asset requests. Gather all your brand assets and documentation in one central place, and empower your team to create on their own. [See an example](${exampleLinks.digitalBrandHub}).`,
      primaryButton: MarketingButtons.getStarted,
      secondaryButton: MarketingButtons.bookDemo,
      image: {
        src: Images.Solutions.brandHub_hero,
        alt: "Brand hub",
      },
    },
    {
      type: "MediaSection",
      title: "Achieve brand consistency at scale",
      subtitle:
        "Establish a single source of truth for your brand so you can tell your brand's unique story.",
      layoutDirection: "image-left",
      body: {
        style: "tabList",
        items: [
          {
            title: "A home for all your brand assets",
            body: "Product shots, logos, videos, fonts, colors and any other brand asset can be stored and managed in Lingo. ",
            image: {
              src: Images.Solutions.brandHub_allAssets,
              alt: "A home for all your brand assets",
            },
          },
          {
            title: "Content and Context. Together.",
            body: "Lingo is part document, part asset library. Add contextual information like usage guidelines right next to your assets to ensure they're used correctly. ",
            image: {
              src: Images.Solutions.brandHub_contentContext,
              alt: "Content and Context. Together.",
            },
          },
          {
            title: "Advanced custom branding",
            body: "Make your brand hub look and feel official by choosing a custom font, color and web link.",
            image: {
              src: Images.Solutions.brandHub_customBranding,
              alt: "Advanced custom branding",
            },
          },
          {
            title: "Self-serve",
            body: "Lingo's powerful search and automatic file conversion will empower your team members to find the file they need in the format and size they need, all without needing your help. ",
            image: {
              src: Images.Solutions.brandHub_selfServe,
              alt: "Self serve assets",
            },
          },
        ],
      },
    },
    {
      type: "CardSection",
      title: "More powerful features to build and manage your brand hub",
      footerButton: {
        link: "/product/features",
        text: "View all features",
        buttonStyle: "outline",
      },
      items: [featureCards.unlimitedKits, featureCards.customDomains, featureCards.brandPortal],
    },
    { type: "CtaSection" },
  ],
};

export default data;
