/**
 * Overrides colors on elements defined in CSS with the current space theme.
 *
 * This is a hack until we migrate these CSS classes to React components, and can style consistently
 * with styled-components.
 */

import { createGlobalStyle } from "styled-components";

const Themer = createGlobalStyle`
.text-link { color: ${props => props.theme.primaryColor}; }
.text-link:hover { color: ${props => props.theme.primaryColorDark}; }

/* Buttons */
.button:not(.secondary) { color: ${props => props.theme.primaryColorTint}; background: ${props =>
  props.theme.primaryColor}; }
.button:hover:not(.secondary):not([disabled]) { background-color: ${props =>
  props.theme.primaryColorDark}; }

/* Modals */
.close:hover { color: ${props => props.theme.primaryColor}; }

/* 3rd party */
.intercom-launcher, .intercom-launcher-frame {
  transition: .4s ease margin;
  background-color: ${props => props.theme.primaryColor} !important;
}
.intercom-messenger {
  transition: .4s ease margin;
}
.intercom-lightweight-app {
  z-index: 999 !important;
}
`;

export default Themer;
