import React, { useCallback } from "react";
import { Button, PopupMenu, LingoError, ErrorCode, useBoolean } from "@thenounproject/lingo-core";

import useNotifications from "@actions/useNotifications";
import useSaveAssetMetadata from "@redux/actions/assets/useSaveAssetMetadata";
import useReprocessAsset from "@actions/assets/useReprocessAsset";
import useBatchUpdateItemStatus from "@actions/items/useBatchUpdateItemStatus";

type Props = {
  itemId: string;
  assetId: string;
  compact: boolean;
  error: LingoError;
  inline?: boolean;
  canEdit?: boolean;
};

const GalleryAssetErrorButton: React.FC<Props> = ({
  itemId,
  assetId,
  error: { message, code },
  compact,
  inline,
  canEdit = false,
}) => {
  const { showNotification } = useNotifications();
  const [batchUpdateItemStatus] = useBatchUpdateItemStatus();
  const [saveAssetMetadata] = useSaveAssetMetadata();
  const [reprocessAsset] = useReprocessAsset();

  const [menuOpen, onOpenMenu, onCloseMenu] = useBoolean(false);
  const sourceId = "figma-error-button-".concat(assetId);
  const buttonThemeOverrides = {
    primaryColor: compact ? "error" : "errorLight",
    primaryColorDark: compact ? "error" : "errorLight",
    primaryColorTint: "error",
  };
  const styles = compact || inline ? {} : { position: "absolute", top: "4px", right: "4px" };

  const deleteItem = useCallback(
    () => batchUpdateItemStatus({ items: [itemId], status: "deleted" }),
    [batchUpdateItemStatus, itemId]
  );

  const retryAsset = useCallback(async () => {
    const { error } = await reprocessAsset({ assetId });
    if (error) {
      showNotification({
        message: error.message,
        level: "error",
      });
    } else {
      showNotification({
        message: "We’ll try to import this asset again. You can continue using the app normally.",
      });
    }
  }, [reprocessAsset, assetId, showNotification]);

  const unlinkFigmaItem = useCallback(async () => {
    const { error: saveError } = await saveAssetMetadata({
      assetId,
      data: { meta: { figma: null } },
    });
    if (!saveError)
      showNotification({
        message: "Asset unlinked from Figma. You can download and use it normally.",
      });
  }, [assetId, saveAssetMetadata, showNotification]);

  const renderMenuItems = () => {
    switch (code) {
      case ErrorCode.figmaNotFound:
        return (
          <>
            <PopupMenu.Item onClick={retryAsset} title="Retry" />
            <PopupMenu.Item onClick={unlinkFigmaItem} title="Unlink asset from Figma" />
            <PopupMenu.Item onClick={deleteItem} title="Delete asset" />
          </>
        );

      case ErrorCode.serverError:
        return (
          <>
            <PopupMenu.Item onClick={retryAsset} title="Retry" />
            <PopupMenu.Item onClick={deleteItem} title="Delete asset" />
          </>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <Button
        {...styles}
        size={"small"}
        buttonStyle={compact ? "tertiary" : "primary"}
        themeOverrides={buttonThemeOverrides}
        data-popup-source={sourceId}
        onClick={onOpenMenu}
        icon="info.error"
        data-testid="gallery-asset-error-button"
        disabled={!canEdit}
      />
      <PopupMenu open={menuOpen} source={sourceId} close={onCloseMenu}>
        <PopupMenu.Dialog text={message} />
        {renderMenuItems()}
      </PopupMenu>
    </>
  );
};

export default GalleryAssetErrorButton;
