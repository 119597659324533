import { AnyObject, Portal, Space } from "@thenounproject/lingo-core";
import { useMemo, useEffect } from "react";

const sizes = ["16", "32", "180"];

function getIconLink(size: string): HTMLLinkElement | null {
  return document.querySelector<HTMLLinkElement>(`link[sizes="${size}x${size}"]`);
}

function setThemeFavicons(avatars: Space["avatars"]) {
  sizes.forEach(size => {
    const el = getIconLink(size);
    if (el) {
      el.href = avatars[`avatar${size}`];
    }
  });

  document
    .querySelector('link[rel="shortcut icon"]')
    ?.setAttribute("rel", "shortcut icon-disabled");

  document
    .querySelector('link[rel="icon"][type="image/x-icon"]')
    ?.setAttribute("rel", "icon-disabled");
}

function setDefaultFavicons(defaults: AnyObject) {
  sizes.forEach(size => {
    const el = getIconLink(size);
    if (el) {
      el.href = defaults[size];
    }
  });
  document
    .querySelector('link[rel="shortcut icon-disabled"]')
    ?.setAttribute("rel", "shortcut icon");

  document
    .querySelector('link[rel="icon-disabled"][type="image/x-icon"]')
    ?.setAttribute("rel", "icon");
}

export default function useCustomFavicon(
  space: Pick<Space, "avatars">,
  portal: Pick<Portal, "images">
) {
  const defaults = useMemo(() => {
    return sizes.reduce((acc, size) => {
      const el = getIconLink(size);
      acc[size] = el?.href;
      return acc;
    }, {});
  }, []);

  let _avatars = portal?.images?.avatars?.avatar ? portal.images.avatars : null;
  if (!_avatars) {
    _avatars = space?.avatars?.avatar ? space.avatars : null;
  }
  useEffect(() => {
    if (_avatars) {
      setThemeFavicons(_avatars);
    } else {
      setDefaultFavicons(defaults);
    }
  }, [_avatars, defaults]);
}
