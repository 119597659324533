/**
 * Renders grid of kits.
 */

import React, { useCallback } from "react";
import styled from "styled-components";
import { KitCard, Box, Kit, useNavigation, buildURL, Portal } from "@thenounproject/lingo-core";

import { KitContextMenuItems } from "@features/context-menus/KitContextMenu";

import KitCardCompact from "./KitCardCompact";
import useReorderKitCollectionItems from "@redux/actions/kitCollections/useReorderKitCollectionItems";
import useUpdateKitFavorite from "@redux/actions/kits/useUpdateKitFavorite";
import useNavPoint from "@hooks/useNavPoint";

type KitListWrapperProps = {
  columns: number;
  gap: string;
  maxRows?: number;
  fullWidth?: boolean;
};

const KitListWrapper = styled(Box).attrs<KitListWrapperProps>({
  width: "100%",
  display: "grid",
})<KitListWrapperProps>`
  gap: ${props => props.gap};
  grid-template-columns: ${props => `repeat(${props.columns ? props.columns : 3},minmax(0, 1fr))`};
  grid-template-rows: 1fr;
  grid-auto-rows: ${props => (props.maxRows ? props.maxRows - 1 : "auto")};
  overflow-y: ${props => (props.maxRows ? "hidden" : "visible")};
  @media screen and (max-width: 768px) {
    ${props =>
      props.fullWidth
        ? "grid-template-columns: repeat(1, minmax(0, 1fr))"
        : "grid-template-columns: repeat(2, minmax(0, 1fr))"};
  }
  @media screen and (max-width: 560px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

type Props = {
  kits: Kit[];
  kitCardSize: "normal" | "compact";
  columns: number;
  gap: string;
  maxRows?: number;
  kitCollectionId?: string;
  identifier?: string;
  showKitDetails?: boolean;
  overrideTheme?: boolean;
  fullWidth?: boolean;
};

const KitList: React.FC<Props> = ({
  kitCardSize,
  columns,
  gap,
  maxRows,
  kits,
  kitCollectionId,
  identifier,
  showKitDetails,
  overrideTheme,
  fullWidth,
}) => {
  const { space } = useNavPoint();
  const [reorderKitCollectionItems] = useReorderKitCollectionItems();

  const [setKitFavorite] = useUpdateKitFavorite();
  const navigation = useNavigation();

  const setFavorited = useCallback(
    (kitId: string, isFavorite: boolean) => () => {
      const { id: spaceId } = space;
      void setKitFavorite({ spaceId, kitId, isFavorite });
    },
    [space, setKitFavorite]
  );

  const navigateToKit = useCallback(
    (kit: Kit, portal?: Portal) => {
      const url = buildURL(`/k/${kit.urlId}`, { space, portal });
      navigation.push(url);
    },
    [navigation, space]
  );

  const renderKitMenuItems = (kit: Kit) => {
    return (
      <KitContextMenuItems kit={kit} space={space} draft={undefined} activeVersion={undefined} />
    );
  };

  const handleCardClick = (kit: Kit) => {
    navigateToKit(kit);
  };

  const handlePillClick = (kit: Kit, portal: Portal) => {
    navigateToKit(kit, portal);
  };

  const handleFavoriteToggle = (kitId: string, isFavorite: boolean) => {
    setFavorited(kitId, isFavorite)();
  };

  const kitElements = kits.map(k => {
    if (k.status !== "active" || !k.access.permissions) return null;
    if (kitCardSize === "compact") {
      return (
        <KitCardCompact
          key={k.kitId}
          id={k.kitId}
          kit={k}
          onClick={() => handleCardClick(k)}
          setFavorited={() => handleFavoriteToggle(k.kitId, !k.access.isFavorite)}
          menuItems={renderKitMenuItems(k)}
          identifier={identifier}
          showKitDetails={showKitDetails}
        />
      );
    } else {
      return (
        <KitCard
          key={k.kitId}
          id={k.kitId}
          kit={k}
          space={space}
          fullWidth={fullWidth}
          onClick={() => handleCardClick(k)}
          onPillClick={handlePillClick}
          setFavorited={() => handleFavoriteToggle(k.kitId, !k.access.isFavorite)}
          menuItems={renderKitMenuItems(k)}
          kitCollectionId={kitCollectionId}
          reorderKitCollectionItems={reorderKitCollectionItems}
          overrideTheme={overrideTheme}
        />
      );
    }
  });

  return (
    <KitListWrapper columns={columns} gap={gap} maxRows={maxRows} fullWidth={fullWidth}>
      {kitElements}
    </KitListWrapper>
  );
};

export default KitList;
