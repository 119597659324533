/**
 * Reorders gallery items & validates the new layout.
 */

import { API, PortalItem } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";
import { queryPortalItems } from "./usePortalItems";
import { showNotification } from "../useNotifications";

import _cloneDeep from "lodash/cloneDeep";
import _without from "lodash/without";

export type Args = {
  reorderItemIds: string[];
  displayOrder: string;
  portalId: string;
};

/**
 * Mutates an array of section items for a reorder
 * @param {Object} config object containing itemIds, displayOrder, guidePosition, dragPosition, validSelfDrop, reorderItemGuidePosition, reorderItemIndex
 * @param {Object} section section from redux state
 * @param {Array} items items from redux state
 */

export function mutateLayoutByReorder(
  { reorderItemIds, displayOrder }: Args,
  portalItems: PortalItem[]
) {
  const portalItemIds = portalItems.map(item => String(item.id));
  const proxiedDisplayOrder = displayOrder;

  /**
   * The reorderItemIds can be in any order as selected by the user.
   * If there is more than one item being reordered, make sure the
   * order is maintained by sorting them by their index in current state.
   */
  const orderedReorderItemIds = _cloneDeep(reorderItemIds);
  if (orderedReorderItemIds.length > 1) {
    const indexMap = portalItemIds.reduce((acc, id, idx) => {
      acc[id] = idx;
      return acc;
    }, {});
    orderedReorderItemIds.sort((a, b) => indexMap[a] - indexMap[b]);
  }

  const [direction, targetId] = displayOrder.split(":");
  const reorderedPortalItemIds = _without(portalItemIds, ...orderedReorderItemIds);

  let index = reorderedPortalItemIds.indexOf(targetId);
  if (direction === "after") index += 1;

  if (orderedReorderItemIds.includes(targetId)) {
    index = portalItemIds.indexOf(targetId);
    orderedReorderItemIds.forEach(itemId => {
      if (itemId === targetId) return;
      if (portalItemIds.indexOf(itemId) < portalItemIds.indexOf(targetId)) {
        index -= 1;
      }
    });
  }

  reorderedPortalItemIds.splice(index, 0, ...orderedReorderItemIds);

  return { reorderedPortalItemIds, displayOrder: proxiedDisplayOrder };
}

const [useReorderPortalItems, reorderPortalItems] = createAsyncAction(
  "portalItems/reorderectionItems",
  async (args: Args, thunkApi) => {
    const { entities } = thunkApi.getState();
    const itemQuery = queryPortalItems.getQueryData(entities.portalItems.queries, {
      portalId: args.portalId,
    });
    const items = itemQuery[0].data.portalItems.map(id => entities.portalItems.objects[id]);

    const result = mutateLayoutByReorder(args, items);
    /**
     * If mutateLayoutByReorder returns falsy, no API call is needed
     */
    if (!result) return;
    const { reorderedPortalItemIds, displayOrder } = result;
    const data = {
      position: displayOrder,
      items_to_reorder: reorderedPortalItemIds.map(id => {
        return { uuid: id };
      }),
    };
    try {
      const res = await API.call<{ items: string[] }>({
        endpoint: "portal_items/reorder",
        method: "PUT",
        entity: API.Entity.portalItem,
        data,
      });
      return { ...res, reorderedPortalItemIds };
    } catch (err) {
      if (err.message) {
        thunkApi.dispatch(showNotification({ message: err.message, level: "error" }));
      }
      throw err;
    }
  }
);

export default useReorderPortalItems;
export { reorderPortalItems };
