import React, { useCallback } from "react";

import { Button, Flex, Input, useClipboard, Color } from "@thenounproject/lingo-core";

import { Inspectable } from "@constants/Inspector";
import useTrackColorUsage from "@redux/actions/analytics/trackColorUsage";

type Props = {
  inspectable: Inspectable;
  color: Color;
  format: string;
};

const ColorValueInputGroup: React.FC<Props> = ({ inspectable, color, format }) => {
  const { onCopy, hasCopied } = useClipboard(color[format] || null, 2000);
  const trackColorUsage = useTrackColorUsage();

  const _onClick = useCallback(() => {
    if (inspectable.item) {
      trackColorUsage({ item: inspectable.item, format });
    }
    onCopy();
  }, [format, inspectable.item, onCopy, trackColorUsage]);

  if (!color[format]) return null;

  const buttonIconStyle = hasCopied
    ? { tintColor: "success", icon: "checkmark" }
    : { icon: "clipboard" };

  return (
    <Flex mb="xs">
      <Input value={color[format]} type="text" readOnly />
      <Button
        onClick={_onClick}
        buttonStyle="tertiary"
        size="small"
        themeOverrides={{ primaryColor: "black" }}
        pl="s"
        {...buttonIconStyle}
      />
    </Flex>
  );
};

export default ColorValueInputGroup;
