import React, { Fragment, MutableRefObject } from "react";
import Helmet from "react-helmet";
import {
  Text,
  Kit,
  KitVersion,
  Item,
  ActivityIndicator,
  PagingIndicator,
} from "@thenounproject/lingo-core";

import GalleryItems from "./GalleryItems";
import EmptyState from "../EmptyState";
import useUpdateItem from "@redux/actions/items/useUpdateItem";
import useRecoveredItems from "@redux/actions/items/useRecoveredItems";
import { useViewModeContext } from "@contexts/ViewModeProvider";

type Props = {
  // Passed
  kit: Kit;
  kitVersion: KitVersion;
  getItemUrl: (item: Item) => string;
  onSelectItem: (event: React.MouseEvent, itemId: string, items: Item[]) => void;
  setInspectorState: (itemIds: string[]) => void;
  selectedItems: string[];
  scrollContainerRef: MutableRefObject<HTMLDivElement>;
  canEditContent: boolean;
};

const KitRecoveredAssets: React.FC<Props> = ({
  kit,
  kitVersion,
  getItemUrl,
  onSelectItem,
  setInspectorState,
  selectedItems,
  scrollContainerRef,
  canEditContent,
}) => {
  const [updateItem] = useUpdateItem();
  const {
    data: items,
    isLoading,
    fetchNextPage,
    error: loadingError,
  } = useRecoveredItems({
    kitId: kit.kitId,
    spaceId: kit.spaceId,
  });

  const recoveredItemCount = kitVersion.counts.recovery;
  const hasMoreAssets = items?.length >= recoveredItemCount ? false : true;

  const { galleryAssetViewMode } = useViewModeContext();

  function renderContent() {
    if (recoveredItemCount === 0) {
      return (
        <EmptyState
          title="No assets have been recovered recently"
          subtitle="Click the ‘Done’ button to exit the Recovered Assets section."
        />
      );
    } else if (isLoading && !items?.length) {
      return <ActivityIndicator mt="xxl" center />;
    } else if (loadingError && !items?.length) {
      return (
        <EmptyState
          title="Unable to load recovered assets"
          subtitle={loadingError.message}
          iconProps={{ iconId: "error", fill: "error" }}
          button={{ text: "Retry", onClick: fetchNextPage }}
        />
      );
    }
    return (
      <>
        <GalleryItems
          items={items}
          getItemUrl={getItemUrl}
          onSelectItem={onSelectItem}
          setInspectorState={setInspectorState}
          selectedItems={selectedItems}
          scrollContainerRef={scrollContainerRef}
          canEditContent={canEditContent}
          updateItem={updateItem}
          assetViewMode={galleryAssetViewMode}
        />
        <PagingIndicator
          hasMore={hasMoreAssets}
          data={items}
          error={loadingError}
          fetchNextPage={fetchNextPage}
          isLoading={isLoading}
        />
      </>
    );
  }

  return (
    <Fragment>
      <Helmet title={`${kit.name}: Recovered Assets`} />
      <Text as="h1" font="gallery.title" mb="xl">
        Recovered Assets
      </Text>
      {renderContent()}
    </Fragment>
  );
};

export default KitRecoveredAssets;
