import React, { useState, useCallback } from "react";
import styled from "styled-components";

import {
  Button,
  SpaceThemeNames,
  useSpaceThemeName,
  Kit,
  Box,
  PopupMenu,
  KitVersion,
  Space,
  KitPermission,
  Portal,
} from "@thenounproject/lingo-core";

import GalleryViewToggle from "./GalleryViewToggle";
import SearchBarButton from "../search/SearchBarButton";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import { KitContextMenuItems } from "@features/context-menus/KitContextMenu";

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  display: contents;
`;

type Props = {
  kit: Kit;
  space: Space;
  portal?: Portal;
  kitVersion: KitVersion;
};

const KitHeaderActions: React.FC<Props> = ({ kit, space, portal, kitVersion }) => {
  const [showOverflowMenu, setShowOverflowMenu] = useState(false);
  const themeName = useSpaceThemeName();
  const { showModal } = useShowModal();
  const activeVersion = kitVersion;

  const toggleOverflowMenu = useCallback(() => {
    setShowOverflowMenu(!showOverflowMenu);
  }, [showOverflowMenu]);

  const openShareModal = useCallback(() => {
    if (portal) {
      showModal(ModalTypes.SHARE_PORTAL, { portalId: portal?.id, kitId: kit.kitId });
    } else {
      showModal(ModalTypes.SHARE_KIT, { kitId: kit.kitId });
    }
  }, [kit.kitId, portal, showModal]);

  if (!kit) return null;
  const permissions = kit?.access?.permissions ?? [];

  function renderShareButton() {
    if (!permissions.includes(KitPermission.changeOwnStatus)) return null;
    return <Button size="small" text="Share Kit" ml="s" flexShrink="0" onClick={openShareModal} />;
  }
  function renderSearch() {
    const canSearch = permissions.includes(KitPermission.searchAssets);
    return canSearch ? (
      <SearchBarButton
        size={themeName === SpaceThemeNames.wyman ? "small" : "regular"}
        iconSize={16}
        font="ui.small"
        p="5px 8px"
        width="250px"
      />
    ) : null;
  }
  function renderButtons() {
    return <GalleryViewToggle />;
  }

  // Overflow button
  function renderOverflowButton() {
    return (
      <Box ml="s">
        <Button
          size="small"
          icon="overflow"
          onClick={toggleOverflowMenu}
          data-popup-source="kit-overflow"
          buttonStyle="secondary"
        />
        {showOverflowMenu ? (
          <PopupMenu
            source="kit-overflow"
            vPos="floatBelow"
            hPos="alignLeft"
            close={toggleOverflowMenu}>
            <KitContextMenuItems
              kit={kit}
              space={space}
              draft={kitVersion.version === 0 ? kitVersion : null}
              activeVersion={activeVersion?.version}
            />
          </PopupMenu>
        ) : null}
      </Box>
    );
  }
  return (
    <Container data-inspector-clear="true">
      {renderSearch()}
      {renderOverflowButton()}
      {renderButtons()}
      {renderShareButton()}
    </Container>
  );
};

export default KitHeaderActions;
