/**
 * Kit collections reducer
 */

import _mergeWith from "lodash/mergeWith";
import _remove from "lodash/remove";

import { KitCollection } from "@thenounproject/lingo-core";

import createEntityReducer from "../helpers/createEntityReducer";

import { createKitCollection } from "@actions/kitCollections/useCreateKitCollection";
import { deleteKitCollection } from "@actions/kitCollections/useDeleteKitCollection";
import { fetchKitCollections } from "@redux/actions/kitCollections/useKitCollections";

const getKitCollectionsFromResponse = (action): Record<string, KitCollection> => {
  return action?.response?.entities?.kitCollections || action?.payload?.entities?.kitCollections;
};

export default createEntityReducer<KitCollection>(
  "kitCollections",
  queryBuilder => {
    queryBuilder
      .addCase(createKitCollection.fulfilled, (state, action) => {
        const { result } = action.payload;
        fetchKitCollections.getQueryData(state, { spaceId: action.meta.arg.spaceId }).forEach(q => {
          q.data.push(result);
        });
      })
      .addCase(deleteKitCollection.fulfilled, (state, action) => {
        const { result, entities } = action.payload;
        const removedCollection = entities.kitCollections[result];
        fetchKitCollections
          .getQueryData(state, { spaceId: removedCollection.spaceId })
          .forEach(q => {
            _remove(q.data, id => id === result);
          });
      });
  },
  objectbuilder => {
    objectbuilder.addDefaultCase((state, action) => {
      const kitCollections = getKitCollectionsFromResponse(action);
      if (kitCollections)
        _mergeWith(state, kitCollections, (_value, srcValue, key) => {
          if (key === "items" && srcValue !== undefined) {
            return srcValue;
          }
          return undefined;
        });
    });
  }
);
