import _sortBy from "lodash/sortBy";
import { API, Kit, base64 } from "@thenounproject/lingo-core";
import { createQueryAction } from "@actions/actionCreators";

type Args = {
  spaceId: number | string;
  sort?: string;
  favoritesOnly?: boolean;
  portalId?: string;
};

const [useKits, fetchKits] = createQueryAction<
  Args,
  { kits: Kit[]; total: number },
  { kits: string[]; total: number }
>(
  {
    entity: "kits",
    action: "query",
    condition: ({ args: { spaceId } }) => Boolean(spaceId),
    pagingKey: "kits",
    denormalize: {
      entity: null,
      kits: "kits",
    },
  },
  async ({ args, paging }) => {
    const query = base64.encode({
      sort: args.sort || "name",
      favorites_only: args.favoritesOnly || false,
      portal_uuid: args.portalId,
      // HARD CODED DEFAULT_PAGE_SIZE VALUE
      // TODO: implement pagination
      limit: 1000,
      offset: 1000 * (paging?.page - 1),
    });
    return await API.call<{ kits: string[]; total: number }>({
      endpoint: `spaces/${args.spaceId}/kits`,
      method: "GET",
      entity: API.Entity.kit,
      query: { query },
    });
  }
);

export default useKits;
export { fetchKits };
