import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type AssetMetaUpdate = {
  uuid: string;
  meta: {
    name?: string;
    background_color?: string;
  };
};

const [useBatchUpdateAssetsMetadata, batchUpdateAssetsMetadata] = createAsyncAction(
  "assets/batchUpdateAssetsMetadata",
  async (updatedAssetsMeta: AssetMetaUpdate[]) => {
    const response = await API.call<string>({
      endpoint: `sync/assets`,
      method: "POST",
      entity: API.Entity.asset,
      data: {
        assets: updatedAssetsMeta,
      },
    });
    return response;
  }
);

export default useBatchUpdateAssetsMetadata;
export { batchUpdateAssetsMetadata };
