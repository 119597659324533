import { API, APIResultList } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  portalId: string;
  userIds: number[];
};

const [useRemovePortalMembers, removePortalMembers] = createAsyncAction(
  "portalMembers/removeMulti",
  async ({ portalId, userIds }: Args) => {
    return await API.call<APIResultList<"members", string>>({
      endpoint: `portals/${portalId}/members`,
      method: "POST",
      entity: API.Entity.portalMember,
      data: {
        members: userIds.map(id => ({ user_id: id, status: "revoked" })),
      },
    });
  }
);

export default useRemovePortalMembers;
export { removePortalMembers };
