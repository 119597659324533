import _unique from "lodash/uniq";
import { API, APIResultList } from "@thenounproject/lingo-core";
import { selectSpace } from "@selectors/entities/spaces";
import { createAsyncAction } from "@redux/actions/actionCreators";

type Args = {
  spaceId: number;
  emails: string[];
  role: "admin" | "member" | "limited_member";
  kitRoles: Record<string, string>;
  note: string;
  portals?: string[];
};

const [useSendInvitations, sendInvitations] = createAsyncAction(
  "invitations/send",
  async ({ spaceId, emails, role, kitRoles, note, portals }: Args, thunkApi) => {
    const space = selectSpace(thunkApi.getState(), { spaceId });

    // Create a list of kits for members and limited_members
    // The API will fill in any missing kits when the invite is accepted with "guest".
    const kits =
      role === "admin"
        ? []
        : Object.entries(kitRoles).map(([kitId, role]) => ({
            uuid: kitId,
            role,
          }));
    // Only need to send portal uuids for limited_members
    const portalUuids =
      role === "limited_member"
        ? [...new Set(portals)].map(uuid => {
            return { uuid: uuid };
          })
        : null;

    const spotsTaken = space.counts.users + space.counts.invitations,
      spotsAvailable = space.maxUsers === null ? Infinity : space.maxUsers - spotsTaken,
      invitations = emails
        .map(e => {
          return {
            email: e,
            space_id: spaceId,
            join_with: {
              role: role,
              portals: portalUuids,
              kits,
            },
            note: note,
          };
        })
        .slice(0, spotsAvailable);

    return await API.call<APIResultList<"invitations", string>>({
      endpoint: `spaces/${spaceId}/invite`,
      method: "POST",
      entity: API.Entity.invitation,
      data: {
        invitations,
      },
    });
  }
);

export default useSendInvitations;
export { sendInvitations };
