import { API, Space, AssetView, ErrorCode } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  spaceId: Space["id"];
} & Pick<AssetView, "name" | "filters">;

const [useCreateView, createView] = createAsyncAction(
  "views/create",
  async ({ spaceId, name, filters }: Args) => {
    if (!name?.trim())
      throw {
        code: ErrorCode.invalidParams,
        error: "Name is required",
      };
    return await API.call<string>({
      endpoint: "/views",
      method: "POST",
      data: {
        space_id: spaceId,
        name,
        filters,
      },
      entity: API.Entity.assetView,
    });
  }
);

export default useCreateView;
export { createView };
