import { MarketingButtons } from "../../components/MarketingButtons";
import { PageContent } from "../../pages/templates/PageLayout";
import featureCards from "../featureCards";

import Images from "../images";

const content: PageContent = {
  style: "light",
  sections: [
    {
      type: "HeroSection",
      title: "Insights",
      style: "light",
      layoutDirection: "vertical",
      subtitle: "Measure the performance and impact of your assets with powerful analytics.",
      image: {
        src: Images.Product.insights_hero,
        alt: "",
      },
      primaryButton: MarketingButtons.getStarted,
      secondaryButton: MarketingButtons.bookDemo,
    },
    {
      type: "MediaSection",
      title: "Granular analytics",
      subtitle:
        "Use Insights to gain a detailed understanding of what assets are engaging so you can fine tune your content creation",
      image: {
        src: Images.Product.insights_optimize,
        alt: "See what assets are engaging",
      },
    },
    {
      type: "MediaSection",
      title: "Powerful reporting",
      subtitle: "Generate CSV reports that provide detailed asset, kit and user usage data.",
      layoutDirection: "image-left",
      image: {
        src: Images.Product.insights_content,
        alt: "See what assets are engaging",
      },
      body: {
        style: "iconList",
        items: [
          {
            title: "See who's downloading your assets",
            body: "Know which users are downloading which assets.",
          },
          {
            title: "Monitor kit activity",
            body: "See the traffic breakdown between your different kits, and understand which content is being accessed the most frequently.",
          },
          {
            title: "Collect data from public users",
            body: "Require public users to provide their email address and other information before downloading assets.",
          },
        ],
      },
    },
    {
      type: "CardSection",
      title: "More features",
      items: [featureCards.brandPortal, featureCards.downloadRequests, featureCards.publicSharing],
      footerButton: {
        text: "View all features",
        buttonStyle: "outline",
        link: "/product/features",
      },
    },
    {
      type: "CtaSection",
    },
  ],
};
export default content;
