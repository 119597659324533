import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  itemIds: string[];
  toSectionId: string;
  createReferences: boolean;
};

const [useDuplicateItems, duplicateItems] = createAsyncAction(
  "items/duplicate",
  async ({ toSectionId, itemIds, createReferences }: Args) => {
    return await API.call<{ items: string[] }>({
      endpoint: "items/duplicate",
      method: "PUT",
      data: {
        uuids: itemIds,
        section_uuid: toSectionId,
        create_references: createReferences,
      },
      entity: API.Entity.item,
    });
  }
);
export default useDuplicateItems;
export { duplicateItems };
