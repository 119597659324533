import React from "react";
import { useLocation } from "react-router-dom";
import QueryString from "query-string";
import { Button, Text, ErrorCode } from "@thenounproject/lingo-core";

import { AuthContent } from "./AuthElements";
import EmptyState from "../EmptyState";
import { AuthHeader } from "./AuthHeader";
import Footer from "../Footer";

const ErrorView: React.FC = () => {
  const location = useLocation(),
    queryString = QueryString.parse(location.search),
    { code } = queryString;

  let { title = "An error occured", message = "" } = queryString,
    subtext;

  switch (Number(code)) {
    case ErrorCode.invalidSsoToken:
      title = "Unable to enable SSO";
      message = "We were unable to retrieve your SSO account.";
      subtext = (
        <Text mt="xxl">
          Your link may have expired,{" "}
          <Button link="/login/sso" text="Try again." buttonStyle="tertiary" />
        </Text>
      );
      break;
    case ErrorCode.organizationNotFound:
    case ErrorCode.organizationNotConfigured:
      title = "Something went wrong";
      message =
        "It looks like there was an error with your identity provider's single sign-on configuration.";

      subtext = (
        <Text mt="xxl">
          Try contacting an admin from your organization for help, or take a look at{" "}
          <Button text="these help articles" link="/help" buttonStyle="tertiary" />.
        </Text>
      );
      break;
    case ErrorCode.organizationInviteNeeded:
      title = "Invitation Needed";
      message = "You need an invitation to be able to join this organization.";

      subtext = <Text mt="xxl">Try contacting an admin from your organization for help.</Text>;
      break;
    default:
      title = "Something went wrong";
      message = "Please try again";
  }

  return (
    <AuthContent withHeader>
      <AuthHeader />
      <EmptyState title={title} subtitle={message} extra={subtext} size="large" />
      <Footer />
    </AuthContent>
  );
};

export default ErrorView;
