import { noop } from "lodash";
import React from "react";
import type { InViewHandlerInput } from "./InViewProvider";

export const InViewContext = React.createContext<{
  headers: string[];
  onEntry: (args: InViewHandlerInput) => void;
  onExit: (args: InViewHandlerInput) => void;
}>({
  headers: [],
  onEntry: noop,
  onExit: noop,
});

InViewContext.displayName = "InViewContext";
