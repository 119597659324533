import React, { useState, useCallback, useEffect, useRef } from "react";
import _isEqual from "lodash/isEqual";
import {
  Flex,
  Box,
  Button,
  Text,
  SearchFilterTypes,
  buildURL,
  useNavigation,
  KitPermission,
  PortalPermission,
  SpacePermission,
} from "@thenounproject/lingo-core";

import AccordionSection from "../AccordionSection";
import { Inspectable } from "@constants/Inspector";
import useSaveAssetMetadata from "@redux/actions/assets/useSaveAssetMetadata";
import InspectorTagPill from "./InspectorTagPill";
import InspectorAssetTagInput from "./InspectorAssetTagInput";
import useNotifications from "@actions/useNotifications";
import useToggleSearchStep, { SearchSteps } from "@actions/search/useToggleSearchStep";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import useReplaceQueryFilters from "@redux/actions/search/useReplaceQueryFilters";
import useNavPoint from "@hooks/useNavPoint";

// This height shows eight rows of tags
export const MAX_TAGS_CONTAINER_HEIGHT = 226;
const showAutoTags = Boolean(window.localStorage.getItem("showAutoTags"));

/**
 * joins arrays of asset tags, ignoring case-insensitive duplicates
 * @param {Array} existing An array of exisitng tags to add to
 * @param {Array} newTags An array of new tags to add
 */
export function joinTags(existing: string[], newTags: string[]) {
  const compare = new Set(existing.map(t => t.trim()));
  const t = newTags.filter(i => {
    const include = Boolean(i) && !compare.has(i);
    compare.add(i);
    return include;
  });
  return [...existing, ...t];
}

export type Props = {
  inspectable: Inspectable;
  canEdit: boolean;
};

export default function InspectorAssetTags({ inspectable, canEdit }: Props) {
  const { asset } = inspectable;

  const { showModal } = useShowModal();
  const { showNotification } = useNotifications();
  const { space, portal, kit } = useNavPoint();
  const navigation = useNavigation();
  const [processing, setProcessing] = useState(false),
    [expanded, setExpanded] = useState(false),
    [saveAssetMetadata] = useSaveAssetMetadata(),
    replaceQueryFilters = useReplaceQueryFilters(),
    toggleSearchStep = useToggleSearchStep();

  const disableSelection = !(
    space?.access?.permissions?.includes(SpacePermission.searchAssets) ||
    portal?.access?.permissions?.includes(PortalPermission.searchAssets) ||
    kit?.access?.permissions?.includes(KitPermission.searchAssets)
  );

  const ref = useRef<HTMLDivElement>(null);
  const shouldBeExpandable = ref.current?.scrollHeight > MAX_TAGS_CONTAINER_HEIGHT;

  useEffect(() => {
    setExpanded(false);
  }, [inspectable.asset.id]);

  const tags = asset.keywords
    .split(",")
    .map(t => t.trim())
    .filter(t => Boolean(t));

  const saveTags = useCallback(
    async (newTags: string[]) => {
      if (_isEqual(newTags, tags) || processing) return;
      setProcessing(true);
      const result = await saveAssetMetadata({
        assetId: asset.id,
        data: {
          keywords: newTags.join(","),
        },
      });
      setProcessing(false);
      if (result.error) {
        showNotification({ message: result.error.message, level: "error" });
      }
    },
    [tags, processing, saveAssetMetadata, asset.id, showNotification]
  );

  const removeTag = useCallback(tag => saveTags(tags.filter(t => t !== tag)), [saveTags, tags]);

  const onTagSelect = useCallback(
    newTags => {
      return saveTags(joinTags(tags, newTags));
    },
    [saveTags, tags]
  );

  if (!canEdit && tags.length === 0) return null;

  function renderInput() {
    if (!canEdit) return null;
    return <InspectorAssetTagInput onTagSelect={onTagSelect} />;
  }

  const renderTagList = () => {
    const _tags = tags;
    let autoKeywords = [];
    if (showAutoTags) {
      autoKeywords = asset.autoKeywords?.split(",") || [];
    }

    if (!_tags.length && !autoKeywords.length && !canEdit) return null;

    function renderTag(tag: string, editable) {
      const onClick = () => {
        replaceQueryFilters([
          {
            type: SearchFilterTypes.keyword,
            id: `kw:${tag}`,
            name: "Keyword",
            style: "text",
            display: tag,
            value: tag,
          },
        ]);
        toggleSearchStep(SearchSteps.RESULTS);
        showModal(ModalTypes.SEARCH_MODAL);
      };
      const onDelete = canEdit ? () => removeTag(tag) : null;
      return (
        <InspectorTagPill
          key={tag}
          tag={tag}
          onClick={disableSelection ? null : onClick}
          onDelete={onDelete}
          canEdit={editable}
        />
      );
    }

    const hiddenProps = !expanded
      ? { maxHeight: MAX_TAGS_CONTAINER_HEIGHT, overflow: "hidden" }
      : {};
    return (
      <Box mt="m" data-testid="inspector-asset-tags" ref={ref} {...hiddenProps}>
        <Flex as="ul" flexWrap="wrap">
          {_tags.map(tag => renderTag(tag, canEdit))}
          {autoKeywords.length ? (
            <Text mt="s" font="ui.smallBold" color="grayDarker" width="100%">
              Automated tags
            </Text>
          ) : null}
          {autoKeywords.map(tag => renderTag(tag, false))}
        </Flex>
      </Box>
    );
  };

  const buttonProps = space?.access?.permissions?.includes(SpacePermission.manageTags)
    ? {
        buttonText: "Manage tags",
        onButtonClick: () => navigation.push(buildURL("/library/tags", { space })),
      }
    : {};
  return (
    <AccordionSection title="Tags" contentId={asset.id} disableControls={true} {...buttonProps}>
      {renderInput()}
      {renderTagList()}
      {shouldBeExpandable && (
        <Button
          text={expanded ? "Show less" : "Show more"}
          buttonStyle="tertiary"
          mt="s"
          onClick={() => setExpanded(!expanded)}
        />
      )}
    </AccordionSection>
  );
}
