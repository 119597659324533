import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  userId: number;
  spaceId: number;
  role: string;
};

const [useUpdateSpaceMember, updateSpaceMember] = createAsyncAction(
  "spaceMembers/updateRole",
  async ({ spaceId, userId, role }: Args, thunkApi) => {
    const previousRole =
      thunkApi.getState().entities.spaceMembers.objects[`${spaceId}-${userId}`].role;
    const res = await API.call<string>({
      endpoint: `spaces/${spaceId}/members/${userId}?include=kits`,
      method: "PUT",
      entity: API.Entity.spaceMember,
      data: { role },
    });
    return { ...res, previousRole };
  }
);

export { updateSpaceMember };
export default useUpdateSpaceMember;
