import { ItemType, PortalItemType } from "@thenounproject/lingo-core";
import _sortBy from "lodash/sortBy";

/**
 * Returns the side (top, bottom, left, right) that should
 * be highlighted when an insertable item is being dragged over it
 */

export type DragDirectionArgs = {
  e: React.DragEvent;
  dragRef: React.RefObject<HTMLElement>;
  itemType: ItemType | PortalItemType;
  guidePosition?: string;
  itemDisplayStyle?: string;
};

type DragDirection = "top" | "bottom" | "left" | "right";

export function getDragDirection({
  e,
  dragRef,
  itemType,
  guidePosition,
  itemDisplayStyle,
}: DragDirectionArgs): DragDirection {
  const dragEventX = e.pageX,
    dragEventY = e.pageY;

  let bottom: number,
    top: number,
    left: number,
    right: number,
    sideX: DragDirection = "left",
    sideY: DragDirection = "top";

  /**
   * Null check the ref for tests
   */
  if (dragRef.current) {
    ({ bottom, top, left, right } = dragRef.current.getBoundingClientRect());
    sideX =
      Math.abs(left - dragEventX) < Math.abs(right - dragEventX)
        ? ("left" as const)
        : ("right" as const);
    sideY =
      Math.abs(top - dragEventY) < Math.abs(bottom - dragEventY)
        ? ("top" as const)
        : ("bottom" as const);

    /**
     * If it's a guide, we just need to find the most dominant side of the current drag
     */
    if (guidePosition) {
      return _sortBy(
        [
          { name: "bottom" as const, value: Math.abs(bottom - dragEventY) },
          { name: "top" as const, value: Math.abs(top - dragEventY) },
          { name: "left" as const, value: Math.abs(left - dragEventX) },
          { name: "right" as const, value: Math.abs(right - dragEventX) },
        ],
        "value"
      )[0].name;
    }
  }

  if (itemDisplayStyle === "full_width") return sideY;

  /**
   * If it's not a guide or asset, return the X or Y side based on item type
   */
  const fullWidthTypes = [
    ItemType.heading,
    ItemType.note,
    ItemType.codeSnippet,
    ItemType.supportingImage,
    ItemType.gallery,
    PortalItemType.heading,
    PortalItemType.note,
    PortalItemType.supportingImage,
  ];

  return fullWidthTypes.includes(itemType) ? sideY : sideX;
}
