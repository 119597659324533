import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  educational?: boolean;
  announcement?: boolean;
  newsletter?: boolean;
};

const [useUpdateEmailPrefs, updateEmailPrefs] = createAsyncAction(
  "user/updateEmailPrefs",
  async (prefs: Args) => {
    return await API.call<{ user: number }>({
      endpoint: `me/email-prefs/${window.location.search}`,
      method: "PUT",
      data: prefs,
      entity: API.Entity.user,
    });
  }
);

export default useUpdateEmailPrefs;
export { updateEmailPrefs };
