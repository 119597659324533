import React from "react";
import FontFaceObserver from "fontfaceobserver";

interface Props {
  url: string;
  family: string;
}

export enum FontStates {
  idle = "idle",
  success = "success",
  failure = "failure",
}

type FontState = FontStates.idle | FontStates.success | FontStates.failure;

export const useCustomFont = ({ url, family }: Props): FontState => {
  const [stylesheetLoaded, setStylesheetLoaded] = React.useState(false);
  const [fontState, setFontState] = React.useState(FontStates.idle);

  React.useEffect(() => {
    setFontState(FontStates.idle);
    setStylesheetLoaded(false);
  }, [url]);

  React.useEffect(() => {
    const link = document.createElement("link");

    if (url) {
      link.href = url;
      link.rel = "stylesheet";
      link.onload = () => setStylesheetLoaded(true);
      document.head.appendChild(link);
    }

    return () => {
      if (link.remove && url) {
        link.remove();
      }
    };
  }, [family, url]);

  React.useEffect(() => {
    if (stylesheetLoaded && fontState !== FontStates.success) {
      new FontFaceObserver(family).load().then(
        () => {
          setFontState(FontStates.success);
        },
        () => {
          console.error(`Failed to load ${family}`);
          setFontState(FontStates.failure);
        }
      );
    }
  }, [family, fontState, stylesheetLoaded]);

  return stylesheetLoaded ? fontState : FontStates.idle;
};
