import React, { useEffect } from "react";
import { Flex, buildPath, SpaceTheme, useNavigation } from "@thenounproject/lingo-core";

import QueryString from "query-string";
import styled, { createGlobalStyle } from "styled-components";
import _isEqual from "lodash/isEqual";
import _merge from "lodash/merge";
import _cloneDeep from "lodash/cloneDeep";
import { Helmet } from "react-helmet";

import { SpaceThemePreviews } from "../types";
import ThemeEditorHeader from "../components/ThemeEditorHeader";
import ThemeEditorControlPanel from "../components/SpaceThemeEditorControlPanel";
import ThemeEditorHomeView from "../components/ThemeEditorHomeView";
import ThemeEditorGalleryView from "../components/ThemeEditorGalleryView";
import { useSelectSpace } from "@selectors/entities/spaces";

import useNotifications from "@actions/useNotifications";
import useUpdateSpaceTheme from "@redux/actions/spaces/useUpdateSpaceTheme";
import useThemeFonts from "@hooks/useThemeFonts";
import { useThemeDataContext } from "@contexts/ThemeDataProvider";

const IntercomThemer = createGlobalStyle<{ theme: SpaceTheme }>`
  .intercom-launcher, .intercom-launcher-frame {
    transition: background-color .3s ease;
    background-color: ${props => props.theme.primaryColor} !important;
  }
`;

const Wrapper = styled(Flex).attrs({
  position: "relative",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  flexDirection: "column",
  background: "white",
})``;

const OverflowWrapper = styled(Flex).attrs({
  flex: "100% 1 1",
  overflow: "auto",
})``;

export const OverflowWrapperPreview = styled(OverflowWrapper)`
  cursor: not-allowed;
  * {
    pointer-events: none;
    user-select: none;
  }
`;

const SpaceThemeEditor: React.FC = () => {
  const navigate = useNavigation();
  const space = useSelectSpace();
  const { showNotification } = useNotifications();

  const [updateSpaceTheme, { isProcessing }] = useUpdateSpaceTheme();

  const {
    updateEditingTheme,
    themeChanged,
    editingTheme,
    theme,
    endEditing,
    existingTheme,
    newFiles,
    filesChanged,
    updateFiles,
  } = useThemeDataContext();
  const newTheme = editingTheme ?? theme;
  useEffect(() => endEditing, [endEditing]);

  const [themeEditorView, setThemeEditorView] = React.useState<SpaceThemePreviews>(
    QueryString.parse(window.location.search).view === "gallery"
      ? SpaceThemePreviews.gallery
      : SpaceThemePreviews.home
  );
  const { data: fonts = [] } = useThemeFonts({ spaceId: space?.id }, { refetchOnMount: true });

  const previewProps = { space, newTheme };

  async function saveTheme() {
    if (isProcessing) return null;
    if (!newTheme.headerBackgroundColor && !newTheme.headerBackgroundImage)
      return showNotification({
        message: "Please select a header background color or image.",
        level: "error",
      });

    const theme = {
      description: newTheme.description || null,
      headerBackgroundColor: newTheme.headerBackgroundColor,
      headerTitleStyle: newTheme.headerTitleStyle,
      headerTitleColor: newTheme.headerTitleColor,
      headerDescriptionColor: newTheme.headerDescriptionColor,
      noteSuccessColor: newTheme.noteSuccessColor,
      noteInfoColor: newTheme.noteInfoColor,
      noteWarningColor: newTheme.noteWarningColor,
      assetLightColor: newTheme.assetLightColor,
      assetDarkColor: newTheme.assetDarkColor,
      primaryColor: newTheme.primaryColor,
      themeName: newTheme.themeName,
      fonts: newTheme.fonts,
    };

    if (filesChanged) {
      Object.keys(newFiles).forEach(key => {
        if (!_isEqual(existingTheme[key], newTheme[key])) {
          theme[key] = newFiles[key];
        }
      });
    }

    const { error } = await updateSpaceTheme({
      spaceId: space.id,
      updates: theme,
    });
    if (error) {
      showNotification({ message: error.message, level: "error" });
    } else {
      showNotification({ message: "Theme updated successfully.", level: "info" });
      navigate.push(buildPath("/settings", { space }));
    }
  }

  function renderPreview() {
    switch (themeEditorView) {
      case SpaceThemePreviews.home:
        return <ThemeEditorHomeView {...previewProps} />;
      case SpaceThemePreviews.gallery:
        return <ThemeEditorGalleryView />;
      default:
        return null;
    }
  }

  React.useEffect(() => {
    if (space?.theme?.active === false) {
      navigate.push(buildPath("/settings", { space }));
      showNotification({ message: "Custom branding not active.", level: "error" });
    }
  }, [navigate, showNotification, space, space?.theme?.active]);

  return (
    <>
      <IntercomThemer />
      <Helmet>
        <title>{space.name} - Theme Editor</title>
      </Helmet>
      <Wrapper>
        <ThemeEditorHeader
          {...{
            space,
            themeEditorView,
            setThemeEditorView,
            changed: themeChanged,
            saveTheme,
            loading: isProcessing,
          }}
        />
        <OverflowWrapper>
          <OverflowWrapperPreview>{renderPreview()}</OverflowWrapperPreview>
          <ThemeEditorControlPanel
            {...{
              space,
              updateTheme: updateEditingTheme,
              updateFiles,
              newTheme,
              existingTheme,
              fonts,
              themeEditorView,
            }}
          />
        </OverflowWrapper>
      </Wrapper>
    </>
  );
};

export default SpaceThemeEditor;
