import React, { useCallback } from "react";
import { Text, Input, CustomField, CustomFieldOperation } from "@thenounproject/lingo-core";

import useAssetsCustomFieldUpdate from "@redux/actions/assets/useAssetsCustomFieldUpdate";
import { validateNumber } from "@helpers/validateNumber";
import { sanitizeNumberInput } from "@helpers/sanitizeNumberInput";

export interface Props {
  field: CustomField;
  updateCustomFieldValue: ReturnType<typeof useAssetsCustomFieldUpdate>[0];
  assetFieldValue: number;
  canEdit: boolean;
  assetUuids: string[];
}

export default function NumberCustomField({
  field,
  updateCustomFieldValue,
  assetFieldValue,
  canEdit,
  assetUuids,
}: Props) {
  // Keep the variable stored as string
  // Number inputs don't provide reasonable ways to handle decimals & exponents
  const [value, setValue] = React.useState<string>(String(assetFieldValue) || "");
  const [error, setError] = React.useState<string>();

  React.useEffect(() => {
    setError(undefined);
    if (assetFieldValue === undefined) setValue("");
    else if (String(assetFieldValue) !== value) setValue(String(assetFieldValue));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetFieldValue]);

  React.useEffect(() => {
    setError(undefined);
  }, [value]);

  const handleValueUpdate = useCallback(
    async (fieldId: number, value: string) => {
      if (!validateNumber(value)) {
        setError("Please enter a valid number");
        return;
      }
      const { error } = await updateCustomFieldValue({
        assetUuids,
        fieldId,
        value: value || null,
        operation: CustomFieldOperation.set,
      });
      if (error) {
        setError(error.message);
      }
    },
    [assetUuids, updateCustomFieldValue]
  );

  const handleSave = useCallback(async () => {
    if (value !== String(assetFieldValue)) {
      await handleValueUpdate(field.id, value);
    }
  }, [assetFieldValue, field.id, handleValueUpdate, value]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(sanitizeNumberInput(e.target.value));
  }, []);

  if (!canEdit && field.public) {
    return <Text font="ui.small">{assetFieldValue !== undefined ? assetFieldValue : "None"}</Text>;
  }

  return (
    <Input
      data-testid="inspector-number-field"
      size="small"
      placeholder={"Enter a number"}
      value={value}
      onChange={handleChange}
      onSubmit={handleSave}
      onBlur={handleSave}
      type={"text"}
      inputStyle={error ? "error" : null}
      message={error}
    />
  );
}
