import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

export type Args = {
  password: string;
  spaceId: string | number;
  kitId?: string;
  portalId?: string;
};

const [useEnterPassword, enterPassword] = createAsyncAction(
  "auth/enterPassword",
  async (args: Args) => {
    return await API.call<string>({
      endpoint: "auth/password/",
      method: "POST",
      data: {
        password: args.password,
        space_id: args.spaceId,
        kit_uuid: args.kitId,
        portal_uuid: args.portalId,
      },
    });
  }
);
export default useEnterPassword;
export { enterPassword };
