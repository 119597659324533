import { API, APIToken } from "@thenounproject/lingo-core";
import { createQueryAction } from "../actionCreators";

type Args = {
  spaceId: number;
};

const [useApiTokens, fetchApiTokens] = createQueryAction<Args, APIToken[], string[]>(
  {
    entity: "apiTokens",
    action: "query",
    condition: ({ args }) => Boolean(args.spaceId),
  },
  async ({ args }) => {
    const result = await API.call<{ apiTokens: string[] }>({
      endpoint: `spaces/${args.spaceId}/api-tokens`,
      entity: API.Entity.apiToken,
      method: "GET",
    });
    return { result: result.result.apiTokens, entities: result.entities };
  }
);
export default useApiTokens;
export { fetchApiTokens };
