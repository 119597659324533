import { API, APIResultList } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  userIds: number[];
  spaceId: number;
  kitIds: string[];
  role: string;
};

const [useBatchUpdateKitMembers, batchUpdateKitMembers] = createAsyncAction(
  "kitMembers/batchUpdate",
  async ({ userIds, spaceId, kitIds, role }: Args, thunkApi) => {
    const storeKits = thunkApi.getState().entities.kits.objects;
    const kits = kitIds.reduce((acc, id) => {
      acc[id] = storeKits[id];
      return acc;
    }, {});

    // This is a weird bit of code and it should probably be updated.
    // It allows setting role to revokd and then here that is translated to status = revoked.
    // It also does some handling for shared (purchased) kits.
    const data = {
      members: kitIds
        .map(kitId =>
          userIds.map(userId => ({
            user_id: userId,
            kit_uuid: kitId,
            space_id: spaceId,
            ...(role === "revoked"
              ? { status: "revoked" }
              : kits[kitId]?.purchase
                ? { status: "active" }
                : { role, status: "active" }),
          }))
        )
        .flat(),
    };
    return await API.call<APIResultList<"members", string>>({
      endpoint: `sync/kits/members`,
      method: "POST",
      entity: API.Entity.kitMember,
      data,
    });
  }
);

export default useBatchUpdateKitMembers;
export { batchUpdateKitMembers };
