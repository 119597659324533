import _isEqual from "lodash/isEqual";
import QueryString from "query-string";

import {
  LingoError,
  ItemType,
  Space,
  Portal,
  Kit,
  Item,
  Section,
  KitVersion,
  isError,
  ErrorCode,
  Asset,
  KitCollection,
  SpacePermission,
  buildURL,
  useNavigation,
} from "@thenounproject/lingo-core";

import { identifyContext } from "@redux/actions/spaces/useIdentifyContext";
import { fetchCurrentUser } from "@queries/useCurrentUser";

import { AppDispatch, GetState } from "@redux/store/reduxStore";
import { createAction } from "@redux/actions/actionCreators";
import { useAppDispatchV1 } from "@redux/hooks";
import { GalleryContext } from "../../components/kits/Kit";
import { fetchSpace } from "@redux/actions/spaces/useSpace";
import { parseIdentifier } from "@redux/utils/identifiers";
import { fetchCustomFields } from "@redux/actions/fields/useCustomFields";

export type NavPoint = {
  type: NavPointTypes;
  loaded?: boolean;
  error?: LingoError;
  spaceId?: number;
  space?: Space;
  version?: number;
  role?: string;
  name?: string;
  portalId?: string;
  portal?: Portal;
  kitId?: string;
  kit?: Kit;
  kitVersion?: KitVersion;
  kitCollection?: KitCollection;
  kitCollectionId?: string;
  sectionId?: string;
  section?: Section;
  galleryId?: string;
  gallery?: Item;
  itemId?: string;
  item?: Item;
  asset?: Asset;
  assetId?: string;
  assetToken?: string;
  insightsPage?: string;
  galleryContext?: string;
  settingsPage?: string;
  search?: string;
};

// Adds some properties to nav use when loading
type LoadableNavPoint = NavPoint & {
  redirect?: string;
  subdomain?: string;
};

export enum NavPointTypes {
  "LibraryAsset" = "LibraryAsset",
  "Item" = "Item",
  "Gallery" = "Gallery",
  "Section" = "Section",
  "Kit" = "Kit",
  "KitVersions" = "KitVersions",
  "KitTrashedItems" = "KitTrashedItems",
  "UserSettings" = "UserSettings",
  "SpaceBilling" = "SpaceBilling",
  "SpaceSearch" = "SpaceSearch",
  "SpaceSettings" = "SpaceSettings",
  "SpaceUsers" = "SpaceUsers",
  "Insights" = "Insights",
  "NewSpace" = "NewSpace",
  "SpaceKits" = "SpaceKits",
  "KitCollection" = "KitCollection",
  "DefaultSpace" = "DefaultSpace",
  "Library" = "Library",
  "TagManagement" = "TagManagement",
  "CustomFields" = "CustomFields",
  "KitRecoveredAssets" = "KitRecoveredAssets",
  "Dashboard" = "Dashboard",
  "Portal" = "Portal",
}

export const [, setNavPoint] = createAction<NavPoint>("navPoint/set");

// MARK : Fetchers
// -------------------------------------------------------------------------------

export function useLoadNavPoint() {
  const dispatch = useAppDispatchV1();
  const navigate = useNavigation();
  return async (navPoint: NavPoint) => await dispatch(loadNavPoint(navPoint, navigate));
}

// A list of error codes that should be gracefully handled even for public users
const publicErrors: number[] = [
  ErrorCode.unknown,
  ErrorCode.serviceUnavailable,
  ErrorCode.objectNotFound,
];

export const loadNavPoint = (
  originalNavPoint: NavPoint,
  navigate: ReturnType<typeof useNavigation>
) => {
  const navPoint = { ...originalNavPoint } as LoadableNavPoint;

  return async (dispatch: AppDispatch, getState: GetState) => {
    function handleError(err: Error | LingoError) {
      if (!isError(err)) {
        throw err;
      }
      if (err.details?.access === "password" && navPoint.type !== NavPointTypes.Dashboard) {
        const target = window.location.toString();
        const queryString = QueryString.stringify({ next: target });
        navigate.replace(`/enter-password/?${queryString}`);
      } else if (getState().user.id || publicErrors.includes(err.code)) {
        dispatch(setNavPoint({ ...navPoint, loaded: true, failed: true, error: err }));
      } else {
        const target = window.location.toString();
        navigate.replace(`/login/?next=${target}`);
      }
    }
    try {
      // We fetch the user and context
      // We only really care about the result of the context fetch
      const [completeNavPoint] = await Promise.all([
        dispatch(fetchContext(navPoint)),
        dispatch(fetchUserIfNeeded()),
      ]);

      if (completeNavPoint.redirect) {
        navigate.replace(completeNavPoint.redirect);
      } else if (completeNavPoint.error) {
        handleError(completeNavPoint.error);
      } else {
        // We should try to migrate custom fields to using a hook instead of fetching here
        // and loading with selectors.
        void dispatch(fetchCustomFieldsIfNeeded(completeNavPoint));
        dispatch(setNavPoint(completeNavPoint));
      }
      return completeNavPoint;
    } catch (err) {
      handleError(err);
    }
  };
};

const fetchUserIfNeeded = () => async (dispatch: AppDispatch, getState: GetState) => {
  const {
    user: { isFetched, error },
  } = getState();
  if (isFetched || error?.code === ErrorCode.unauthorized) return;
  await dispatch(fetchCurrentUser());
};

export const fetchCustomFieldsIfNeeded =
  (navPoint: NavPoint) => async (dispatch: AppDispatch, getState: GetState) => {
    if (!navPoint.spaceId) return;

    try {
      const space = getState().entities.spaces.objects[navPoint.spaceId];
      if (!space) return;
      if (!space.features?.includes("custom_fields")) return;
      if (space.access.isPublic) {
        // If we aren't fetching anything that could show an asset, no fields neeeded
        if (!navPoint.kitId && !navPoint.assetId) return;
      }

      await fetchCustomFields.lazy(
        { dispatch, getState },
        {
          spaceId: space.id,
          kitId: navPoint.kitId,
          assetId: navPoint.assetId,
          assetToken: navPoint.assetToken,
        },
        { persistError: true }
      );
    } catch (error) {
      // do nothing
    }
  };

const fetchContext =
  (navPoint: LoadableNavPoint) => async (dispatch: AppDispatch, getState: GetState) => {
    const populatedNavPoint: LoadableNavPoint = {
      ...navPoint,
      loaded: true,
    };

    // For pages that don't need a space context, we don't need to fetch anything
    const nonSpaceContexts = [
      NavPointTypes.NewSpace,
      NavPointTypes.UserSettings,
      NavPointTypes.DefaultSpace,
    ];
    if (nonSpaceContexts.includes(navPoint.type)) {
      return populatedNavPoint;
    }

    // For pages that we know are in the space context, we can just fetch the space.
    if (
      [
        NavPointTypes.SpaceBilling,
        NavPointTypes.Dashboard,
        NavPointTypes.CustomFields,
        NavPointTypes.Insights,
        NavPointTypes.Library,
        NavPointTypes.SpaceSettings,
        NavPointTypes.SpaceUsers,
        NavPointTypes.TagManagement,
      ].includes(navPoint.type)
    ) {
      const space = await fetchSpace.lazy(
        { dispatch, getState },
        { spaceId: navPoint.subdomain ?? navPoint.spaceId }
      );
      populatedNavPoint.spaceId = space.id;
      populatedNavPoint.space = space;
      return populatedNavPoint;
    }

    // For all other pages, we fetch the entire context
    const result = await identifyContext.lazy(
      { dispatch, getState },
      {
        hostname: navPoint.subdomain,
        spaceId: navPoint.spaceId,
        kitCollectionId: navPoint.kitCollectionId,
        kitId: navPoint.kitId,
        portalId: navPoint.portalId,
        sectionId: navPoint.sectionId,
        itemId: navPoint.itemId,
        assetId: navPoint.assetId,
        assetToken: navPoint.assetToken,
        version: navPoint.version,
      }
    );

    populatedNavPoint.portalId = result.portal?.id;
    populatedNavPoint.spaceId = result.space?.id;
    populatedNavPoint.kitId = result.kit?.kitId;
    populatedNavPoint.sectionId = result.section?.id;
    populatedNavPoint.itemId = result.item?.id;
    populatedNavPoint.assetId = result.asset?.id;
    populatedNavPoint.version = result.version;
    populatedNavPoint.galleryId = result.gallery?.id;
    populatedNavPoint.kitCollectionId = result.kitCollection?.uuid;

    const space = result.space;
    const isSpaceMember = space?.access?.permissions?.includes(SpacePermission.viewDashboard);

    if (result.portal && !navPoint.portalId && navPoint.type === NavPointTypes.SpaceKits) {
      // Nav points with no path start as SpaceKits
      // If we recieved a portal back then we want to show that instead
      populatedNavPoint.type = NavPointTypes.Portal;
    }

    // If we truly can't access a kit collection due to permissions,
    // The API will return an error. This is only to handle the case where
    // the space has been migrated to portals.
    if (navPoint.kitCollectionId && !result.kitCollection) {
      if (result.portal) {
        populatedNavPoint.redirect = buildURL("/", { space, portal: result.portal });
      } else if (isSpaceMember) {
        populatedNavPoint.redirect = buildURL("/dashboard", { space });
      }
    }

    if (result.item?.type === ItemType.gallery) {
      populatedNavPoint.type = NavPointTypes.Gallery;
      populatedNavPoint.galleryId = result.item.id;
    }

    if (
      populatedNavPoint.type === NavPointTypes.SpaceKits &&
      space?.features?.includes("portals")
    ) {
      // Nav points still start as SpaceKits from the pre-portal days
      // We want to redirect to the dashboard for migrated spaces
      if (isSpaceMember) {
        populatedNavPoint.redirect = buildURL("/dashboard", { space });
      } else {
        // Non members will get a 404 since they won't get a space back from the API
        // THIS SHUOLD NEVER HAPPEN, including so we get notified in Sentry
        throw Error("Attempt to navigate to space kits for non member");
      }
    }
    return populatedNavPoint;
  };

// MARK : Getters
/* ------------------------------------------------------------------------------- */

export const buildItemUrl = (
  item: Item,
  context: Pick<NavPoint, "section" | "space" | "portal">
) => {
  if (!item) return null;
  const { space, portal, section } = context,
    currentParams = QueryString.parse(window.location.search),
    { type: itemType, version, asset } = item;

  const { shareToken } = asset || {};

  const params = {
    v: version,
    asset_token: shareToken || undefined,
    kit_token: currentParams.tkn || currentParams.kit_token || undefined,
    previous: currentParams.previous || undefined,
    context: undefined,
  };

  const directLinkTypes = [ItemType.asset, ItemType.gallery];
  if (item.status === "trashed") {
    params.context = GalleryContext.deleted;
  } else if (item.sectionId === null && item.itemId == null) {
    params.context = GalleryContext.recovered;
  } else if (item.sectionId && !directLinkTypes.includes(itemType)) {
    return buildURL(`/s/${section.urlId}`, { space, portal }, params, item.shortId);
  }

  return buildURL(`/a/${item.urlId}`, { space, portal }, params);
};

// MARK : Creators
/* ------------------------------------------------------------------------------- */

type NavPointCreator = (args: {
  params?: Record<string, string>;
  pathname?: string;
  query: QueryString.ParsedQuery<string>;
  subdomain?: string;
  error?: LingoError;
}) => NavPoint;

function getQueryParams(query: QueryString.ParsedQuery<string>) {
  let version: number;
  try {
    version = parseInt(query.v as string);
    if (Number.isNaN(version)) version = undefined;
  } catch {
    version = undefined;
  }

  return {
    version,
    search: query.q as string,
    context: query.context as string,
    assetToken: query.asset_token as string,
  };
}

export const createItemNavPoint: NavPointCreator = ({ params, query, subdomain }) => {
  const { spaceId, itemUuid, portalId } = params;
  const { version, search, context: galleryContext, assetToken } = getQueryParams(query);
  return {
    type: NavPointTypes.Item,
    loaded: false,
    spaceId: parseInt(spaceId) || undefined,
    portalId: parseIdentifier(portalId) || undefined,
    subdomain,
    itemId: parseIdentifier(itemUuid),

    assetToken,
    version,
    search,
    galleryContext,
    item: null,
    gallery: null,
    galleryOptional: true,
    kit: null,
    kitOptional: true,
    kitVersion: null,
    kitVersionOptional: true,
    section: null,
    sectionOptional: true,
    portal: null,
    portalOptional: true,
    space: null,
    spaceOptional: true,
  };
};

export const createSectionNavPoint: NavPointCreator = ({ params, query, subdomain }) => {
  const { spaceId, sectionUuid, portalId } = params,
    { version } = getQueryParams(query);
  return {
    type: NavPointTypes.Section,
    loaded: false,
    spaceId: parseInt(spaceId) || undefined,
    portalId: parseIdentifier(portalId) || undefined,
    subdomain,
    sectionId: parseIdentifier(sectionUuid),
    version,
    kit: null,
    kitVersion: null,
    section: null,
    space: null,
    spaceOptional: true,
  };
};

export const createKitNavPoint: NavPointCreator = ({ params, query, subdomain }) => {
  const { spaceId, kitUuid, portalId, kitPage } = params,
    { version, search } = getQueryParams(query);
  const type =
    {
      versions: NavPointTypes.KitVersions,
      deleted: NavPointTypes.KitTrashedItems,
      recovered: NavPointTypes.KitRecoveredAssets,
    }[kitPage] ?? NavPointTypes.Kit;
  return {
    type,
    loaded: false,
    spaceId: parseInt(spaceId) || undefined,
    portalId: parseIdentifier(portalId) || undefined,
    subdomain,
    kitId: parseIdentifier(kitUuid),
    version,
    search,
    kit: null,
    kitVersion: null,
    space: null,
    spaceOptional: true,
  };
};

export const createDashboardNavPoint: NavPointCreator = ({ params, subdomain }) => {
  const { spaceId } = params;
  return {
    type: NavPointTypes.Dashboard,
    loaded: false,
    spaceId: parseInt(spaceId) || undefined,
    subdomain,
    space: null,
  };
};

export const createPortalNavPoint: NavPointCreator = ({ params, subdomain }) => {
  const { spaceId, portalId } = params;
  return {
    type: NavPointTypes.Portal,
    loaded: false,
    spaceId: parseInt(spaceId) || undefined,
    subdomain,
    portalId: parseIdentifier(portalId),
    kit: null,
    kitVersion: null,
    space: null,
    spaceOptional: true,
  };
};

export const createUserSettingsNavPoint: NavPointCreator = ({ pathname }) => {
  const segments = pathname.split("/").filter(Boolean);
  const settingsPage = segments[segments.length - 1];

  const navPoint = {
    type: NavPointTypes.UserSettings,
    settingsPage,
    loaded: false,
    error: null,
  };

  return navPoint;
};

export const createSpaceBillingNavPoint: NavPointCreator = ({ params, subdomain }) => {
  const { spaceId } = params;
  return {
    type: NavPointTypes.SpaceBilling,
    loaded: false,
    spaceId: parseInt(spaceId) || undefined,
    subdomain,
    space: null,
  };
};

// Space search is only for legacy links
export const createSpaceSearchNavPoint: NavPointCreator = ({ params, subdomain }) => {
  const { spaceId } = params;
  return {
    type: NavPointTypes.SpaceSearch,
    loaded: false,
    spaceId: parseInt(spaceId) || undefined,
    subdomain,
    space: null,
  };
};

export const createSpaceSettingsNavPoint: NavPointCreator = ({ params, subdomain }) => {
  const { spaceId, settingsPage } = params;

  const validRoutes = [
    "basic-info",
    "notifications",
    "advanced",
    "integrations",
    "access-controls",
    "editor",
  ];
  const navPoint = {
    type: NavPointTypes.SpaceSettings,
    settingsPage,
    loaded: false,
    spaceId: parseInt(spaceId) || undefined,
    subdomain,
    space: null,
    error: null,
  };

  if (settingsPage && !validRoutes.includes(settingsPage)) {
    navPoint.error = {
      code: ErrorCode.objectNotFound,
      message: "We couldn't find anything here",
    };
  }
  return navPoint;
};

export const createSpaceUsersNavPoint: NavPointCreator = ({ params, subdomain }) => {
  const { spaceId, role } = params;
  const validRoutes = ["admin", "members", "limited-members", "invitations", "requests"];
  const navPoint = {
    type: NavPointTypes.SpaceUsers,
    role,
    loaded: false,
    spaceId: parseInt(spaceId) || undefined,
    subdomain,
    space: null,
    error: null,
  };

  if (role && !validRoutes.includes(role)) {
    navPoint.error = {
      code: ErrorCode.objectNotFound,
      message: "We couldn't find anything here",
    };
  }

  return navPoint;
};

export const createInsightsNavPoint: NavPointCreator = ({ params, subdomain }) => {
  const { spaceId, insightsPage } = params;
  const validRoutes = ["assets", "kits", "users", "storage", "portals"];
  const navPoint = {
    type: NavPointTypes.Insights,
    insightsPage,
    loaded: false,
    spaceId: parseInt(spaceId) || undefined,
    subdomain,
    space: null,
    error: null,
  };

  if (insightsPage && !validRoutes.includes(insightsPage)) {
    navPoint.error = {
      code: ErrorCode.objectNotFound,
      message: "We couldn't find anything here",
    };
  }

  return navPoint;
};

export const createNewSpaceNavPoint = (): NavPoint => {
  return {
    type: NavPointTypes.NewSpace,
    loaded: false,
  };
};

export const createSpaceKitsNavPoint: NavPointCreator = ({ params, subdomain }) => {
  const { spaceId } = params;
  return {
    type: NavPointTypes.SpaceKits,
    loaded: false,
    spaceId: parseInt(spaceId) || undefined,
    subdomain,
    space: null,
  };
};

export const createKitCollectionNavPoint: NavPointCreator = ({ params, subdomain }) => {
  const { spaceId, kitCollectionId } = params;
  return {
    type: NavPointTypes.KitCollection,
    loaded: false,
    spaceId: parseInt(spaceId) || undefined,
    kitCollectionId: parseIdentifier(kitCollectionId) || undefined,
    subdomain,
    space: null,
  };
};

export function createDefaultSpaceNavPoint({ subdomain = null }) {
  return {
    type: NavPointTypes.DefaultSpace,
    loaded: false,
    subdomain,
  };
}

export const createAssetLibraryNavPoint: NavPointCreator = ({ params, subdomain }) => {
  const { spaceId } = params;
  return {
    type: NavPointTypes.Library,
    loaded: false,
    spaceId: parseInt(spaceId) || undefined,
    subdomain,
    space: null,
  };
};

export const createAssetTagManagementNavPoint: NavPointCreator = ({ params, subdomain }) => {
  const { spaceId } = params;
  return {
    type: NavPointTypes.TagManagement,
    loaded: false,
    spaceId: parseInt(spaceId) || undefined,
    subdomain,
    space: null,
  };
};

export const createLibraryAssetNavPoint: NavPointCreator = ({ params, subdomain }) => {
  const { spaceId, assetUuid } = params;
  return {
    type: NavPointTypes.LibraryAsset,
    loaded: false,
    spaceId: parseInt(spaceId) || undefined,
    assetId: parseIdentifier(assetUuid) || undefined,
    subdomain,
    space: null,
  };
};

export const createCustomFieldsNavPoint: NavPointCreator = ({ params, subdomain }) => {
  const { spaceId } = params;
  return {
    type: NavPointTypes.CustomFields,
    loaded: false,
    spaceId: parseInt(spaceId) || undefined,
    subdomain,
    space: null,
  };
};
