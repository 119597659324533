import { API, KitVersion } from "@thenounproject/lingo-core";
import { createQueryAction } from "@redux/actions/actionCreators";

type Args = {
  spaceId: number | string;
  kitId: string;
};

const [useKitVersions, fetchKitVersions] = createQueryAction<Args, KitVersion[], string[]>(
  {
    entity: "kitVersions",
    action: "query",
  },
  async ({ args }) => {
    const res = await API.call<string>({
      endpoint: `kits/${args.spaceId}/${args.kitId}/version-history`,
      method: "GET",
      entity: API.Entity.kit,
    });
    const kit = res.entities?.kits?.[res.result];
    return { result: kit.versions, entities: res.entities };
  }
);

export default useKitVersions;
export { fetchKitVersions };
