/* eslint-disable @typescript-eslint/no-unsafe-call */
import QueryString from "query-string";
import {
  buildURL,
  Button,
  ElementPositioningProps,
  factory,
  getCustomDomainComponents,
  SpaceThemeNames,
  useWindowSize,
} from "@thenounproject/lingo-core";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTheme } from "styled-components";

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleGoogleAuth: any;
  }
}

import GOOGLE_CREDENTIALS from "../../constants/googleAuth";
export type GoogleAuthResponse = {
  clientId: string;
  credential: string;
  select_by: string;
};

const { clientId } = GOOGLE_CREDENTIALS;

type Props = {
  context: "signin" | "signup";
  onSuccess: (response: GoogleAuthResponse) => void;
} & ElementPositioningProps;

const GoogleSignInButton: React.FC<Props> = ({ context, onSuccess }) => {
  const scriptId = useMemo(() => `google-signin-${factory.genUUID()}`, []);
  const { isMobile, width } = useWindowSize();
  const domainComps = getCustomDomainComponents();

  const { themeName } = useTheme();

  useEffect(() => {
    if (domainComps.host) return;
    // useScript dosn't work here becaue it has some built in caching
    // that prevents the script from being loaded again
    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.defer = true;
    script.id = scriptId;
    document.body.append(script);

    return () => {
      document.getElementById(scriptId)?.remove();
    };
  }, [domainComps.host, scriptId]);

  window.handleGoogleAuth = useCallback(
    (data: GoogleAuthResponse) => {
      onSuccess(data);
    },
    [onSuccess]
  );
  // If we are on a custom domain we need to get over to the base domain for Google
  // to work properly. Unfotunately the user will need to click the button again
  if (domainComps.host) {
    const query = QueryString.parse(window.location.search);
    const url = buildURL(`/login`, {}, query);
    return (
      <Button
        buttonStyle="outline"
        iconBefore="integrations.google-signin"
        text={context === "signin" ? "Sign in with Google" : "Sign up with Google"}
        onClick={() => {
          window.location.replace(url);
        }}
        width="100%"
      />
    );
  }

  return (
    <>
      <div
        id="g_id_onload"
        data-client_id={clientId}
        data-callback="handleGoogleAuth"
        data-auto_prompt="false"
        data-context={context}
      />
      <div
        className="g_id_signin"
        data-type="standard"
        data-size="large"
        data-theme="outline"
        data-text={context === "signin" ? "signin_with" : "signup_with"}
        data-shape={themeName === SpaceThemeNames.scher ? "pill" : "square"}
        data-logo_alignment="center"
        data-width={isMobile ? `${width - 32}` : "400"}
      />
    </>
  );
};

export default GoogleSignInButton;
