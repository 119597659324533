/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { useState, useEffect, useCallback, Fragment } from "react";
import { Button, Input, Box, SpacePreview, Redirect } from "@thenounproject/lingo-core";

import { AuthFormWrapper, authButtonStyle, AuthFormMessage } from "./AuthElements";

import useRequestInvite from "@redux/actions/auth/useRequestInvite";
import AuthFormTitle from "./AuthFormTitle";

type Props = {
  spaceIdentifier: string | number;
  space?: SpacePreview;
};

function RequestInviteForm({ spaceIdentifier, space }: Props) {
  const [email, setEmail] = useState(""),
    [error, setError] = useState(null),
    [processing, setProcessing] = useState(false),
    [success, setSuccess] = useState(false),
    [requestInvite] = useRequestInvite(),
    submit = useCallback(async () => {
      setSuccess(false);
      setProcessing(true);
      const res = await requestInvite({ spaceId: spaceIdentifier, email });
      setProcessing(false);
      if (res.error) {
        setError(res.error.message);
      } else {
        setSuccess(true);
      }
    }, [spaceIdentifier, requestInvite, email]);

  useEffect(() => {
    setSuccess(false);
  }, [spaceIdentifier]);

  function renderForm() {
    return (
      <Box>
        <Input
          id="email"
          onChange={e => {
            setEmail(e.target.value);
          }}
          onSubmit={submit}
          value={email}
          placeholder="name@acme.com"
          type="email"
          label="Email"
          styleOverrides={{ pb: "m" }}
          message={error}
          inputStyle={error ? "error" : null}
        />
        <Button
          id="submit-button"
          {...authButtonStyle}
          onClick={submit}
          text="Enter"
          disabled={processing}
        />
      </Box>
    );
  }

  function renderHeader() {
    return (
      <AuthFormTitle
        title={success ? "Verify your email to join" : "Join with your company email"}
        message={
          success
            ? "Check your email for a verification message from Lingo. Once you verify your email you’ll create your account and join this space."
            : "Enter your company email to verify it and join this space."
        }
        space={space}
      />
    );
  }

  function renderFooter() {
    if (success) {
      return (
        <Fragment>
          <AuthFormMessage>
            <span>
              Didn&apos;t get the email?{" "}
              <Button
                id="resend-button"
                text="Resend."
                buttonStyle="tertiary"
                size="small"
                onClick={submit}
              />
            </span>
          </AuthFormMessage>

          <AuthFormMessage>
            <span>
              Or
              <Button
                text="return to login"
                buttonStyle="tertiary"
                size="small"
                link={`/login/${window.location.search || ""}`}
              />
            </span>
          </AuthFormMessage>
        </Fragment>
      );
    } else {
      return (
        <AuthFormMessage>
          <Button
            text="Back to login"
            buttonStyle="tertiary"
            link={`/login/${window.location.search || ""}`}
          />
        </AuthFormMessage>
      );
    }
  }

  if (!spaceIdentifier) {
    return <Redirect to={`/login/${window.location.search || ""}`} />;
  }

  return (
    <AuthFormWrapper>
      {renderHeader()}
      {!success && renderForm()}
      {renderFooter()}
    </AuthFormWrapper>
  );
}

export default RequestInviteForm;
