import { useMemo } from "react";

/**
 * This hook memoizes an object so you don't have to write e.g.
 * useMemo(() => ({ a, b }), [a, b])
 */

export function useObject<T extends Record<string, unknown>>(object: T) {
  const deps = Object.values(object);
  return useMemo(() => object, deps); // eslint-disable-line react-hooks/exhaustive-deps
}
