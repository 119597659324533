import styled from "styled-components";
import { Box } from "@thenounproject/lingo-core";

const SquareWrapper = styled(Box).attrs(() => ({
  height: "100%",
  width: "100%",
  p: "8px",
  borderRadius: "2px",
  position: "relative",
  pointerEvents: "none",
}))`
  * {
    pointer-events: none;
  }
`;

export default SquareWrapper;
