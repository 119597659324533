import React, { useMemo } from "react";
import { ImageView, ActivityIndicator } from "@thenounproject/lingo-core";
import { useInView } from "react-intersection-observer";
import { useTheme } from "styled-components";
import { ThumbnailProps } from "./types";
import ThumbnailWrapper from "./ThumbnailWrapper";
import { getAssetError } from "@helpers/getAssetError";
import { getAssetIcon } from "@helpers/getAssetIcon";
import getAssetBackground from "@helpers/getAssetBackground";

function ImageThumbnail({ asset }: ThumbnailProps) {
  const theme = useTheme();
  const { inView, ref } = useInView({ triggerOnce: true });
  const assetError = getAssetError(asset);
  const assetIcon = getAssetIcon(asset);

  const backgroundColor = useMemo(() => {
    return getAssetBackground(asset, theme);
  }, [asset, theme]);

  function renderThumbnail() {
    if (asset.meta.assetProcessing === "error" || assetError) {
      return (
        <ImageView width="100%" height="100%" icon={"info.error"} iconSize={24} iconFill="error" />
      );
    }

    if (inView && asset.meta.assetProcessing === "complete") {
      return (
        <ImageView
          width="100%"
          height="100%"
          src={asset.thumbnails[292]}
          icon={assetIcon}
          iconSize={24}
        />
      );
    }

    return <ActivityIndicator size="small" color="gray" center />;
  }

  return (
    <ThumbnailWrapper ref={ref} {...backgroundColor}>
      {renderThumbnail()}
    </ThumbnailWrapper>
  );
}

export default React.memo(ImageThumbnail);
