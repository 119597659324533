import React from "react";
import { GalleryAssetViewModes } from "@contexts/ViewModeContext";
import { Inspectable } from "@constants/Inspector";
import GalleryAssetMeta from "../GalleryAssetMeta";
import GalleryAssetContentFull from "./GalleryAssetContentFull";
import GalleryAssetContentSquare from "./GalleryAssetContentSquare";

type Props = {
  inspectable: Inspectable;
  downloadButton: React.ReactNode;
  assetViewMode: GalleryAssetViewModes;
  selected: boolean;
  canEdit: boolean;
};

function GalleryAssetContent({
  inspectable,
  downloadButton,
  assetViewMode,
  selected,
  canEdit,
}: Props) {
  const { item } = inspectable;

  const isSquare = item?.data?.displayStyle !== "full_width";

  if (isSquare)
    return (
      <GalleryAssetMeta
        item={inspectable.item}
        downloadButton={downloadButton}
        assetViewMode={assetViewMode}
        canEdit={canEdit}>
        <GalleryAssetContentSquare inspectable={inspectable} selected={selected} />
      </GalleryAssetMeta>
    );
  return (
    <GalleryAssetContentFull inspectable={inspectable} selected={selected} canEdit={canEdit} />
  );
}

export default React.memo(GalleryAssetContent);
