import React from "react";
import styled from "styled-components";

import { Flex, Box, buildURL, Icon } from "@thenounproject/lingo-core";

const Header = styled(Box).attrs({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  zIndex: 9999,
})``;

export const AuthHeader = () => (
  <Header data-testid="auth-page-header">
    <Box borderBottom="default" background="white" width="100%">
      <Flex
        py="m"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        maxWidth="960px"
        mx="auto"
        px="m"
        variations={{ "mq.s": { px: "16px" } }}>
        <a href={buildURL()} style={{ width: "75px", height: "35px" }} title="Home">
          <Icon iconId="logo.full" fill="primary" height="35" mt="xxs" />
        </a>
      </Flex>
    </Box>
  </Header>
);
