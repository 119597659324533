import React, { Fragment, useMemo } from "react";
import { TinyColor } from "@ctrl/tinycolor";
import styled from "styled-components";

import { Box, Flex, Text, Asset } from "@thenounproject/lingo-core";

const TransparencyBackground = styled(Box).attrs({
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
})`
  background: url(${require("../../images/web-access/pattern@2x.png")});
  background-size: 32px;
`;

const ColorArea = styled(Flex).attrs({
  position: "absolute",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  p: "s",
})`
  transition: all 0.2s ease-in;
`;

const ColorValue = styled(Text).attrs({
  maxWidth: "100%",
  textAlign: "center",
  overflow: "hidden",
  m: "0",
  truncate: true,
})``;

type Props = {
  asset: Asset;
  smallMode?: boolean;
};

export default function ColorDisplay({ asset, smallMode = false }: Props) {
  const color = asset.colors[0];

  const { hexString, textColor } = useMemo(() => {
    // Hex string
    let hex = color.hex;
    if (color.rgb.startsWith("rgba")) {
      const opacity = Math.round(parseFloat(color.rgb.split(", ")[3].replace(")", "")) * 100);
      hex += `${opacity}%`;
    }
    // Text Color
    const c = new TinyColor(color.rgb),
      _textColor =
        c.isLight() || c.getAlpha() <= 0.5 ? "rgba(42, 42, 42, .8)" : "rgba(255, 255, 255, .8)";
    return { hexString: hex.toUpperCase(), textColor: _textColor };
  }, [color]);

  function renderPrintValue(label) {
    if (!color[label]) return null;
    let value;
    if (label === "cmyk") {
      value = color[label];
      value = value.split("(")[1].split(")")[0].split(",").join(", ");
    }

    return (
      <ColorValue font="ui.small" color={textColor}>
        <Text as="span" font="ui.smallBold" color={textColor}>
          {label.toUpperCase()}
        </Text>{" "}
        {value || color[label]}
      </ColorValue>
    );
  }

  return (
    <Fragment>
      <TransparencyBackground />
      <ColorArea background={color.rgb} title={asset.name}>
        {smallMode ? (
          <ColorValue font="ui.small" color={textColor}>
            {hexString}
          </ColorValue>
        ) : (
          <Fragment>
            <ColorValue mb="xs" font="ui.regular" color={textColor}>
              {hexString}
            </ColorValue>
            {renderPrintValue("cmyk")}
            {renderPrintValue("pms")}
          </Fragment>
        )}
      </ColorArea>
    </Fragment>
  );
}
