import { API } from "@thenounproject/lingo-core";
import { showNotification } from "@actions/useNotifications";
import { createAsyncAction } from "../actionCreators";

// const { CALL_API } = API;

export type ArgTypes = {
  assetId: string;
  data: File;
};

const [useUpdateAssetThumbnail, updateAssetThumbnail] = createAsyncAction(
  "assets/selectThumbnail",
  async (arg: ArgTypes, thunkApi) => {
    const { assetId, data } = arg;
    const type = data.type.split("/")[1].toUpperCase();
    const formData = new FormData();
    formData.append("asset", data);
    formData.append("json", JSON.stringify({ type }));
    try {
      const response = await API.call<string>({
        endpoint: `assets/${assetId}/preview`,
        method: "PUT",
        entity: API.Entity.asset,
        formData,
      });
      return response;
    } catch (err) {
      thunkApi.dispatch(
        showNotification({ message: "Failed to upload custom thumbnail", level: "error" })
      );
      throw err;
    }
  }
);

export default useUpdateAssetThumbnail;
export { updateAssetThumbnail };
