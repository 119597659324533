import { Space } from "@thenounproject/lingo-core";
import { createAction } from "./actionCreators";

type Args = {
  notificationId: string;
  spaceId: Space["id"];
  seen: boolean;
};

const [useSaveNotificationSeen, saveNotificationSeen] =
  createAction<Args>("notifications/saveSeen");

export default useSaveNotificationSeen;
export { saveNotificationSeen };
