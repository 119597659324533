import { API, APIResultList, CustomFieldOperation } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

export type ArgTypes = {
  fieldId: number;
  operation: CustomFieldOperation;
  assetUuids: string[];
  value?: string | number;
  optionId?: number;
};

const [useAssetsCustomFieldUpdate, assetCustomFieldUpdate] = createAsyncAction(
  "assets/updateCustomField",
  async (arg: ArgTypes) => {
    return await API.call<APIResultList<"assets", string>>({
      endpoint: `assets/fields`,
      method: "POST",
      entity: API.Entity.asset,
      data: {
        field_id: arg.fieldId,
        operation: arg.operation,
        value: arg.value,
        option_id: arg.optionId,
        asset_uuids: arg.assetUuids,
      },
    });
  }
);

export default useAssetsCustomFieldUpdate;
export { assetCustomFieldUpdate };
