import React from "react";
import { Flex, ImageView, SpacePreview, Tooltip, Text } from "@thenounproject/lingo-core";

import AuthFormTitle from "./AuthFormTitle";

const MEMBERS_TO_SHOW = 3;
type Props = {
  space: SpacePreview;
  titlePrefix: string;
};

const SpacePreviewTitle: React.FC<Props> = ({ space, titlePrefix }) => {
  const { name, counts, members: _members = [] } = space;
  // Typecast the members since this is actually just a preview
  const members = _members as unknown as { id: number; name: string; avatar: string }[];

  const membersTrunc = members.slice(0, MEMBERS_TO_SHOW),
    moreMembers = counts?.users - MEMBERS_TO_SHOW;

  function renderAvatarPile() {
    if (!members) return null;

    const avatars = membersTrunc.map(m => (
      <React.Fragment key={m.id}>
        <Tooltip direction={Tooltip.Direction.Top} source={String(m.id)} minWidth="50">
          {m.name}
        </Tooltip>
        <ImageView
          data-tooltip-source={String(m.id)}
          alt={`Avatar for ${m.name}`}
          src={m.avatar}
          ml="-12px"
          mr="xxs"
          background="grayDarkest"
          borderRadius="50%"
          width="28px"
          height="28px"
        />
      </React.Fragment>
    ));

    const hiddenCount =
      moreMembers > 0 ? (
        <Text as="span" ml="xs" font="ui.small" color="grayDarkest">
          +{moreMembers}
        </Text>
      ) : null;

    return (
      <Flex justifyContent="center" mt="m" alignItems="center">
        {avatars}
        {hiddenCount}
      </Flex>
    );
  }

  return (
    <AuthFormTitle
      title={`${titlePrefix} ${name}`}
      message={`Teammates include ${membersTrunc
        .map(m => m.name)
        .join(", ")
        .trim()}${moreMembers > 0 ? ", and more." : "."}`}
      space={space}
      alwaysShowAvatar
      extra={renderAvatarPile()}
    />
  );
};

export default SpacePreviewTitle;
