import React, { DragEvent } from "react";
import styled from "styled-components";
import { Flex, styles, Icon, Tooltip } from "@thenounproject/lingo-core";

import { PortalInsertType } from "../../constants/portalInsertType";
import { useGetDraggingState } from "@selectors/getters";
import useSetDraggingEntity, { DragEntities } from "@actions/useSetDraggingEntity";

const InsertPanelWrapper = styled(Flex).attrs({
  justifyContent: "center",
  borderRight: "default",
  flexDirection: "column",
  alignItems: "center",
  background: "transparent",
  width: "64px",
  top: "120px",
  alignSelf: "flex-start",
  position: "sticky",
  px: "xs",
  variations: {
    "mq.l": {
      display: "none !important",
    },
  },
})`
  overflow: auto;
  margin-left: -72px;
  transform: translateX(-24px);
`;

const PanelItem = styled(Flex).attrs({
  flexShrink: 0,
  borderRadius: "default",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  width: "32px",
  height: "32px",
  my: "s",
  background: "white",
})`
  box-shadow: none;
  transition: 0.3s background ease;
  cursor: pointer;
  svg {
    margin: 0;
  }
  &[draggable="true"] {
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
    &:hover {
      background: ${styles.color.grayLighter};
    }
  }
`;

type ItemWrapperProps = {
  divider?: boolean;
};
const PanelItemWrapper = styled(Flex).attrs<ItemWrapperProps>(props => {
  return {
    borderBottom: props.divider ? "default" : undefined,
  };
})<ItemWrapperProps>``;

const panelItems = [
  {
    label: "Drag to add kits",
    icon: "content.kit",
    type: PortalInsertType.kit,
    divider: true,
  },
  {
    label: "Drag to add a heading",
    icon: "content.heading",
    type: PortalInsertType.heading,
  },
  {
    label: "Drag to add a note",
    icon: "content.note",
    type: PortalInsertType.note,
    divider: true,
  },
  {
    label: "Drag to add supporting content",
    icon: "content.supporting-image",
    type: PortalInsertType.supportingImage,
  },
];

const PortalInsertPanel: React.FC = () => {
  const setDragEntity = useSetDraggingEntity();
  const draggingState = useGetDraggingState();
  const portalDragActive = draggingState.entity === DragEntities.PORTAL_ITEM;

  function handleDragStart(e: DragEvent, insertType: PortalInsertType) {
    e.dataTransfer.setData("insertType", insertType);
    setDragEntity({ entity: DragEntities.PORTAL_ITEM });
  }

  function handleDragEnd(e: DragEvent) {
    e.preventDefault();
    setDragEntity({ entity: undefined });
  }

  const renderPanelItems = () => {
    return panelItems.map(p => {
      const labelId = "panel-item-".concat(p.type.split(" ").join("-").toLowerCase());
      return (
        <PanelItemWrapper divider={p.divider} key={labelId}>
          <PanelItem
            data-testid={labelId}
            data-tooltip-source={labelId}
            onDragStart={e => handleDragStart(e, p.type)}
            onDragEnd={handleDragEnd}
            draggable="true">
            <Icon iconId={p.icon} fill="black" />
            {!portalDragActive && (
              <Tooltip direction="right" source={labelId} border="none">
                {p.label}
              </Tooltip>
            )}
          </PanelItem>
        </PanelItemWrapper>
      );
    });
  };

  return (
    <InsertPanelWrapper className="intercom-portal-insert-panel">
      {renderPanelItems()}
    </InsertPanelWrapper>
  );
};

export default PortalInsertPanel;
