import React from "react";
import {
  Box,
  Text,
  CustomFieldTypes,
  Asset,
  CustomField,
  formatDate,
} from "@thenounproject/lingo-core";

import {
  TextCustomField,
  TextCustomFieldMulti,
  SelectCustomField,
  SelectCustomFieldMulti,
  ChecklistCustomField,
  ChecklistCustomFieldMulti,
  DateCustomField,
  DateCustomFieldMulti,
  NumberCustomField,
  NumberCustomFieldMulti,
} from "./InspectorCustomFieldsInputs";

import useAssetsCustomFieldUpdate from "@redux/actions/assets/useAssetsCustomFieldUpdate";
import { useSelectSpaceFields } from "@selectors/entities/fields";

import _groupBy from "lodash/groupBy";
import _isEqual from "lodash/isEqual";
import _intersection from "lodash/intersection";
import _flatten from "lodash/flatten";
import { cloneDeep } from "lodash";
import { useSelectSpace } from "@selectors/entities/spaces";

type Props = {
  assets: Asset[];
  canEdit: boolean;
};

const InspectorCustomFields = ({ assets, canEdit }: Props) => {
  const [updateCustomFieldValue] = useAssetsCustomFieldUpdate();
  const space = useSelectSpace();

  const canViewPrivateFields = space?.access?.isPublic === false;

  const assetUuids = assets?.map(asset => asset.id);
  const fields = useSelectSpaceFields();

  function renderCustomFieldType(field: CustomField) {
    if (assets.length > 1) {
      const sharedProps = {
        assets,
        field,
        assetUuids,
        updateCustomFieldValue,
        canEdit,
      };
      switch (field.type) {
        case CustomFieldTypes.select:
          return <SelectCustomFieldMulti {...sharedProps} />;
        case CustomFieldTypes.text:
          return <TextCustomFieldMulti {...sharedProps} />;
        case CustomFieldTypes.number:
          return <NumberCustomFieldMulti {...sharedProps} />;
        case CustomFieldTypes.checklist:
          return <ChecklistCustomFieldMulti {...sharedProps} />;
        case CustomFieldTypes.date:
          return <DateCustomFieldMulti {...sharedProps} />;
        default:
          return null;
      }
    } else {
      const assetFieldValue = assets[0].fields?.[field.id];
      const sharedProps = {
        field,
        assetUuids,
        updateCustomFieldValue,
        canEdit,
      };
      switch (field.type) {
        case CustomFieldTypes.select:
          return (
            <SelectCustomField assetFieldValue={assetFieldValue as string[]} {...sharedProps} />
          );
        case CustomFieldTypes.text:
          return <TextCustomField assetFieldValue={assetFieldValue as string} {...sharedProps} />;
        case CustomFieldTypes.number:
          return <NumberCustomField assetFieldValue={assetFieldValue as number} {...sharedProps} />;
        case CustomFieldTypes.checklist:
          return (
            <ChecklistCustomField assetFieldValue={assetFieldValue as string[]} {...sharedProps} />
          );
        case CustomFieldTypes.date:
          return <DateCustomField assetFieldValue={assetFieldValue as string} {...sharedProps} />;
        default:
          return null;
      }
    }
  }

  function renderCustomFields() {
    return fields.map(f => {
      return (
        <Box key={f.id} mb="m">
          <Text color="grayDarkest" font="ui.small" mb="2">
            {f.name}
          </Text>
          {renderCustomFieldType(f)}
        </Box>
      );
    });
  }

  function renderVersionedField(
    fieldId: number,
    fieldName: string,
    fieldValueString?: string,
    fieldValueArray?: string[]
  ) {
    return (
      <Box key={fieldId} mb="m">
        <Text color="grayDarkest" font="ui.small" mb="2">
          {fieldName}
        </Text>
        <Box>
          {Boolean(fieldValueArray?.length) &&
            fieldValueArray.map(v => (
              <Text key={`${fieldId}-${v}`} font="ui.small">
                {v}
              </Text>
            ))}
          {fieldValueString && <Text font="ui.small">{fieldValueString}</Text>}
          {!fieldValueString && !fieldValueArray?.length && (
            <Text key={`${fieldId}-none`} font="ui.small">
              None
            </Text>
          )}
        </Box>
      </Box>
    );
  }

  function renderVersionedFields() {
    const { versionedFields } = assets[0];
    let viewableFields = cloneDeep(versionedFields);
    if (!canViewPrivateFields) viewableFields = viewableFields.filter(f => f.public);

    return viewableFields.map(f => {
      let stringValue: string;
      let arrayValues: string[];
      if (f.type === CustomFieldTypes.date) {
        stringValue = f.value
          ? formatDate(new Date(f.value).toISOString(), "MMMM do, yyyy")
          : undefined;
      } else if (f.options.length) {
        arrayValues = f.options.filter(o => o.selected).map(o => o.name);
      } else stringValue = f.value ? String(f.value) : "None";
      return renderVersionedField(f.id, f.name, stringValue, arrayValues);
    });
  }

  function renderMultiAssetVersionedFields() {
    const assetsFields = assets.map(asset => asset.versionedFields).flat();
    const groupedFields = _groupBy(assetsFields, "id");

    return (
      <>
        {Object.values(groupedFields).map(fieldArray => {
          const [firstField] = fieldArray;
          const fieldType = firstField.type;
          let fieldStringValue: string;
          let fieldArrayValues: string[];

          switch (fieldType) {
            case CustomFieldTypes.select:
              {
                const assetFieldValues = fieldArray.map(f => f.options);
                const assetsSelectedOption = assetFieldValues.map(options =>
                  options.map(option => option.selected)
                );
                const fieldValuesAreEqual = assetsSelectedOption.every(f =>
                  _isEqual(f, assetsSelectedOption[0])
                );

                fieldStringValue = fieldValuesAreEqual
                  ? assetFieldValues[0].filter(o => o.selected)[0]?.name || "None"
                  : "Varied";
              }
              break;
            case CustomFieldTypes.text:
            case CustomFieldTypes.number:
              {
                const fieldValuesAreEqual = fieldArray.every(f => f.value === firstField.value);

                const fieldValuesAreNullOrUndefined = fieldArray.every(f =>
                  [null, undefined].includes(f.value)
                );

                fieldStringValue = fieldValuesAreEqual
                  ? fieldValuesAreNullOrUndefined
                    ? "None"
                    : String(firstField.value)
                  : "Varied";
              }
              break;
            case CustomFieldTypes.date:
              {
                const fieldValuesAreEqual = fieldArray.every(f => f.value === firstField.value);
                const dateValue = firstField.value
                  ? formatDate(new Date(firstField.value).toISOString(), "MMMM do, yyyy")
                  : "None";

                fieldStringValue = fieldValuesAreEqual ? dateValue : "Varied";
              }
              break;
            case CustomFieldTypes.checklist: {
              const assetsSelectedOptions = fieldArray.map(f =>
                f.options.filter(o => {
                  return o.selected;
                })
              );
              const assetsSelectedOptionsNames = assetsSelectedOptions.map(assetOptions =>
                assetOptions.map(option => option.name)
              );
              const commonFieldValues = _intersection(...assetsSelectedOptionsNames);
              const hasValues = _flatten(assetsSelectedOptionsNames).length > 0;

              if (commonFieldValues.length > 0) {
                fieldArrayValues = commonFieldValues;
              } else if (hasValues) {
                fieldStringValue = "Varied";
              } else {
                fieldStringValue = "None";
              }
            }
          }

          return renderVersionedField(
            firstField.id,
            firstField.name,
            fieldStringValue,
            fieldArrayValues
          );
        })}
      </>
    );
  }

  /**
   * If we're viewing a versioned kit, return a flat list of all the fields
   */
  const viewingVersionedAssets = assets.some(asset => Boolean(asset.sourceId));
  if (viewingVersionedAssets) {
    return (
      <Box data-testid="inspector-custom-fields-versioned">
        {assets.length > 1 ? renderMultiAssetVersionedFields() : renderVersionedFields()}
      </Box>
    );
  }

  return <Box data-testid="inspector-custom-fields">{renderCustomFields()}</Box>;
};

export default InspectorCustomFields;
