import { MarketingButtons } from "../../components/MarketingButtons";
import { PageContent } from "../../pages/templates/PageLayout";
import featureCards from "../featureCards";

import Images from "../images";

const content: PageContent = {
  style: "light",
  sections: [
    {
      type: "HeroSection",
      title: "Library",
      style: "light",
      layoutDirection: "vertical",
      subtitle:
        "The Library brings all your assets and metadata together into a single view that is optimized specifically for asset managment.",
      image: {
        src: Images.Product.library_hero,
        alt: "",
      },
      primaryButton: MarketingButtons.getStarted,
      secondaryButton: MarketingButtons.bookDemo,
    },
    {
      type: "MediaSection",
      title: "Your home for asset management",
      subtitle:
        "The Library is a central database of all your assets and metadata and gives you total control of your content.",
      image: {
        src: Images.Product.library_assetManagement,
        alt: "Content and context together",
      },
    },
    {
      type: "MediaSection",
      title: "Built in DAM functionality ",
      subtitle:
        "The Library contains all the functionality you need to manage your assets as you scale your brand.",
      image: {
        src: Images.Product.library_dam,
        alt: "Slide 1",
      },
      layoutDirection: "image-left",
      body: {
        style: "iconList",
        items: [
          {
            title: "Advanced filtering",
            body: "Apply filters to create custom views of your assets",
          },
          {
            title: "Custom fields",
            body: "Add business specific metadata to your assets so they're easier to find and manage",
          },
          {
            title: "Tag management",
            body: "Manage all your tags from a single view to ensure your tag taxonomy is intuitive and up to date",
          },
        ],
      },
    },
    {
      type: "CardSection",
      title: "More features",
      items: [featureCards.customFields, featureCards.tags, featureCards.fileConversions],
      footerButton: {
        text: "View all features",
        buttonStyle: "outline",
        link: "/product/features",
      },
    },
    {
      type: "CtaSection",
    },
  ],
};
export default content;
