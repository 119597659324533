/**
 * KitMembers are joins between kit and user within a space. They contain role & permission
 * information.
 */
import _merge from "lodash/merge";
import _remove from "lodash/remove";

import { KitMember } from "@thenounproject/lingo-core";

import createEntityReducer from "../helpers/createEntityReducer";

import { addKitMembers } from "@redux/actions/kitMembers/useAddKitMembers";
import { removeKitMember } from "@redux/actions/kitMembers/useRemoveKitMember";
import { fetchKitMembers } from "@redux/actions/kitMembers/useKitMembers";
import { updateSpaceMember } from "@redux/actions/spaceMembers/useUpdateSpaceMember";

export default createEntityReducer<KitMember>(
  "kitMembers",
  queryBuilder => {
    queryBuilder
      .addCase(addKitMembers.fulfilled, (state, action) => {
        const { spaceId, kitId } = action.meta.arg;
        const { result, entities } = action.payload;
        const addedMemberIds = result.members.filter(r => r.success).map(r => r.result);
        fetchKitMembers.getQueryData(state, { spaceId, kitId }).forEach(q => {
          addedMemberIds.forEach(kitMemberId => {
            const kitMember = entities.kitMembers[kitMemberId];
            q.data.members.push(kitMemberId);
            _remove(
              q.data.unjoinedMembers,
              id => id === [kitMember.spaceId, kitMember.userId].join("-")
            );
          });
        });
      })
      .addCase(removeKitMember.fulfilled, (state, action) => {
        const { userId, spaceId, kitId } = action.meta.arg;
        fetchKitMembers.getQueryData(state, { spaceId, kitId }).forEach(q => {
          _remove(q.data.members, id => id === action.payload.result);
          q.data.unjoinedMembers.push([spaceId, userId].join("-"));
        });
      });
  },
  objectBuilder => {
    objectBuilder
      .addCase(addKitMembers.fulfilled, (state, action) => {
        // Yet another hack to get the kit share modal working.
        // useKitMembers returns members with their space role.
        // We can assume here that if someone is being added to a kit, they are a limited member.
        const members = action.payload.entities.kitMembers;
        const withSpaceRole = Object.keys(members).reduce((res, key) => {
          res[key] = { ...members[key], spaceRole: "limited_member" };
          return res;
        }, {});
        return _merge(state, withSpaceRole);
      })
      .addCase(updateSpaceMember.fulfilled, (state, action) => {
        const kitMembers = action.payload?.entities?.kitMembers;
        if (kitMembers) {
          _merge(state, kitMembers);
        }
        // And another hack to keep space member roles in sync with kit member objects.
        // Perhaps we could normalize the spaceMember on kitMember, so this wouldn't be an issue
        // Again, the issue is that the share kit modal expects kitMembers to have the space role as well
        // which the API only returns for useKitMembers endpoint.
        const spaceMembers = action.payload?.entities?.spaceMembers ?? {};
        Object.values(spaceMembers).forEach(spaceMember => {
          Object.values(state).forEach(kitMember => {
            if (
              kitMember.userId === spaceMember.userId &&
              kitMember.spaceId === spaceMember.spaceId
            ) {
              (kitMember as KitMember & { spaceRole: string }).spaceRole = spaceMember.role;
            }
          });
        });
      })
      .addDefaultCase((state, action: any) => {
        const kitMembers =
          action.response?.entities?.kitMembers ?? action.payload?.entities?.kitMembers;
        if (kitMembers) {
          return _merge(state, kitMembers);
        }
      });
  }
);
