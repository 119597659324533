import React, { useState, useCallback, Fragment } from "react";
import { useAppSelectorV1 } from "@redux/hooks";

import { Input, Text, Box, OptionCard, Notice } from "@thenounproject/lingo-core";

import useNotifications from "@actions/useNotifications";
import useRequestEnterpriseSubscription from "@actions/billing/useRequestEnterpriseSubscription";
import useShowModal from "@redux/actions/useModals";

import ModalBody from "../ModalBody";
import ModalHeader from "../ModalHeader";
import ModalFooter from "../ModalFooter";
import type { RootState } from "@redux/store";
import { useSelectSpace } from "@redux/selectors/entities/spaces";

type Props = {
  spaceId?: number;
  onCompletion: () => void;
  inline?: boolean;
  preselectFeatures?: string[];
};

const RequestEnterpriseForm: React.FC<Props> = ({
  spaceId,
  onCompletion,
  inline = false,
  preselectFeatures,
}) => {
  const [requestEnterpriseSubscription, { isProcessing }] = useRequestEnterpriseSubscription(),
    { dismissModal } = useShowModal();

  const user = useAppSelectorV1((state: RootState) => state.user),
    space = useSelectSpace();

  const userEmail = user?.email,
    [emailError, setEmailError] = useState<string>(null),
    [estimateError, setEstimateError] = useState<string>(null),
    [interestedIn, setInterestedIn] = useState<string[]>(preselectFeatures || []),
    [error, setError] = useState<string>(null),
    [email, setEmail] = useState<string>(userEmail || ""),
    [userEstimate, setUserEstimate] = useState<string>("");

  const submitRequest = useCallback(async () => {
    if (!email) {
      setEmailError("Provide an email to contact you.");
      return;
    }
    if (!userEstimate) {
      setEstimateError("About how many people in your organization will be using Lingo?");
      return;
    }
    setEmailError(null);
    setEstimateError(null);
    setError(null);

    const res = await requestEnterpriseSubscription({
      spaceId,
      email,
      userEstimate,
      features: interestedIn.join(","),
    });
    if (res.error) {
      setError(res.error.message);
    } else {
      if (dismissModal) dismissModal();
      if (onCompletion) onCompletion();
    }
  }, [
    dismissModal,
    email,
    interestedIn,
    onCompletion,
    requestEnterpriseSubscription,
    spaceId,
    userEstimate,
  ]);

  const title = space ? `Upgrade “${space.name}” to Enterprise` : "Contact us for Enterprise";

  function renderEmailInput() {
    if (userEmail) return null;
    return (
      <Input
        styleOverrides={{ mt: "l" }}
        label="Your email"
        type="email"
        value={email}
        placeholder="you@example.com"
        onChange={e => setEmail(e.target.value)}
        onSubmit={submitRequest}
        message={emailError}
        inputStyle={emailError ? "error" : null}
      />
    );
  }
  function renderEstimateInput() {
    const userPlaceholderCount = () => {
      if (!space) return "50";
      const activeUsers = space.counts.users,
        pendingUsers = space.counts.invitations,
        totalUsers = activeUsers + pendingUsers;
      return String(totalUsers > 50 ? totalUsers : 50);
    };
    return (
      <Input
        styleOverrides={{ mt: "l" }}
        label="Estimated number of people using Lingo"
        type="number"
        value={userEstimate}
        placeholder={userPlaceholderCount()}
        onChange={e => setUserEstimate(e.target.value)}
        onSubmit={submitRequest}
        message={estimateError}
        inputStyle={estimateError ? "error" : null}
      />
    );
  }
  function renderFeatureSelection() {
    const features = [
        "Password protected links",
        "Custom domains",
        "Brand portals",
        "Single Sign-on",
        "Advanced Insights",
      ],
      toggleFeature = f => {
        if (interestedIn.includes(f)) setInterestedIn(interestedIn.filter(f1 => f1 !== f));
        else setInterestedIn([...interestedIn, f]);
      };
    return (
      <Box mt="l">
        <Text mb="xs" font="ui.smallBold">
          Enterprise features you’re interested in
        </Text>
        {features.map(f => (
          <OptionCard
            minHeight="40px"
            key={f}
            title={f}
            cardStyle="checkbox"
            selected={interestedIn.includes(f)}
            onClick={() => toggleFeature(f)}
          />
        ))}
      </Box>
    );
  }

  return (
    <Fragment>
      <ModalHeader title={title} styleOverrides={{ borderBottom: inline ? "none" : "default" }} />
      <ModalBody>
        <Text>
          Tell us how many people on your organization will be using Lingo and we will contact you
          at {userEmail ? <strong>{userEmail}</strong> : "the provided email address"} to get you
          set up with an enterprise plan.
        </Text>

        {renderEmailInput()}
        {renderEstimateInput()}
        {renderFeatureSelection()}

        {error && <Notice mt="l" noticeStyle="error" message={error} />}
      </ModalBody>
      <ModalFooter
        primary={{
          disabled: isProcessing,
          text: "Send inquiry",
          onClick: submitRequest,
        }}
        styleOverrides={inline ? { borderTop: "none", background: "none" } : {}}
      />
    </Fragment>
  );
};

export default RequestEnterpriseForm;

export function RequestEnterpriseModal(props) {
  const { showNotification } = useNotifications();
  const notify = useCallback(() => {
    showNotification({
      message:
        "Request sent. We will contact you shortly about an enterprise plan for your organization.",
      level: "info",
    });
  }, [showNotification]);
  return <RequestEnterpriseForm {...props} onCompletion={notify} />;
}
