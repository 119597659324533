/* eslint complexity: 0 */

/**
 * Reducer for spaces (including space members & billing info).
 */

import _merge from "lodash/merge";
import _union from "lodash/union";
import _mergeWith from "lodash/mergeWith";
import _remove from "lodash/remove";
import _cloneDeep from "lodash/cloneDeep";

import createEntityReducer from "../helpers/createEntityReducer";
import { Space } from "@thenounproject/lingo-core";

import { QueryData } from "@redux/actions/actionCreators/createQueryAction";
import { deleteInvitations } from "@redux/actions/invitations/useDeleteInvitations";
import { fetchSpaceMeta } from "@redux/actions/spaces/useSpaceMeta";
import { sendInvitations } from "@redux/actions/invitations/useSendInvitations";
import { deleteSpaceMembers } from "@redux/actions/spaceMembers/useDeleteSpaceMembers";
import { updateSpaceMember } from "@redux/actions/spaceMembers/useUpdateSpaceMember";
import { batchUpdateSpaceMembers } from "@redux/actions/spaceMembers/useBatchUpdateSpaceMembers";
import { camelCase } from "lodash";
import { leaveSpace } from "@redux/actions/spaceMembers/useLeaveSpace";
import { createSpace } from "@redux/actions/spaces/useCreateSpace";
import { fetchSpaces } from "@redux/actions/spaces/useSpaces";
import { deleteSpace } from "@redux/actions/spaces/useDeleteSpace";
import { fetchFigmaIntegrationStatus } from "@redux/actions/figma/useFigmaIntegrationStatus";
import { updateFigmaApiKey } from "@redux/actions/figma/useUpdateFigmaApiKey";
import { addFigmaTeam } from "@redux/actions/figma/useAddFigmaTeam";
import { removeFigmaTeam } from "@redux/actions/figma/useRemoveFigmaTeam";
import { disableFigmaIntegration } from "@redux/actions/figma/useDisableFigmaIntegration";
import { updateSpaceNotifications } from "@redux/actions/spaces/useUpdateSpaceNotifications";

import { replaceCreditCard } from "@redux/actions/billing/useReplaceCreditCard";
import { editInvoice } from "@redux/actions/billing/useEditInvoice";
import { fetchBillingData } from "@redux/actions/billing/useBillingData";

function updateMemberCount(
  state: Record<string, QueryData<unknown>>,
  spaceId,
  _key: string,
  delta: number
) {
  const key = camelCase(_key);
  fetchSpaceMeta.getQueryData(state, { spaceId }).forEach(q => {
    if (!Number.isInteger(q.data.memberCounts[key])) {
      // Make sure the key exists;
      q.data.memberCounts[key] = 0;
    }
    q.data.memberCounts[key] += delta;
  });
}

export default createEntityReducer<Space>(
  "spaces",
  queryBuilder => {
    queryBuilder
      .addCase(createSpace.fulfilled, (state, action) => {
        fetchSpaces.getQueryData(state).forEach(q => {
          q.data.push(action.payload.result);
        });
      })
      .addCase(deleteSpace.fulfilled, (state, action) => {
        fetchSpaces.getQueryData(state).forEach(q => {
          _remove(q.data, id => id === action.payload.result);
        });
      })
      .addCase(leaveSpace.fulfilled, (state, action) => {
        const spaceId = action.meta.arg.spaceId;
        fetchSpaces.getQueryData(state).forEach(q => {
          _remove(q.data, id => id === spaceId);
        });
      })
      .addCase(deleteInvitations.fulfilled, (state, action) => {
        const { spaceId } = action.meta.arg;
        const count = action.payload.result.invitations.filter(inv => inv.success).length;
        updateMemberCount(state, spaceId, "invitations", -count);
      })
      .addCase(sendInvitations.fulfilled, (state, action) => {
        const { spaceId } = action.meta.arg;
        const count = action.payload.result.invitations.filter(inv => inv.success).length;
        updateMemberCount(state, spaceId, "invitations", count);
      })
      .addCase(deleteSpaceMembers.fulfilled, (state, action) => {
        const { spaceId } = action.meta.arg;
        const { result, entities } = action.payload;
        const count = result.members
          .filter(r => r.success)
          .map(r => entities.spaceMembers[r.result])
          .reduce((res, member) => {
            const role = member.role === "owner" ? "admin" : member.role;
            if (!res[role]) res[role] = 0;
            res[role]++;
            return res;
          }, {});
        Object.entries(count).forEach(([role, delta]) => {
          updateMemberCount(state, spaceId, "totalMembers", -delta);
          updateMemberCount(state, spaceId, role, -delta);
        });
      })
      .addCase(updateSpaceMember.fulfilled, (state, action) => {
        const { result, entities, previousRole } = action.payload;
        const updatedMember = entities.spaceMembers[result];
        updateMemberCount(state, updatedMember.spaceId, previousRole, -1);
        updateMemberCount(state, updatedMember.spaceId, updatedMember.role, 1);
      })
      .addCase(batchUpdateSpaceMembers.fulfilled, (state, action) => {
        const { role: newRole, spaceId } = action.meta.arg;
        const { result, previousRoles } = action.payload;
        const updatedMemberIds = result.members.filter(r => r.success).map(r => r.result);
        const successCount = updatedMemberIds.length;
        // It's possible some of the updated members already had the new role
        // In that case we don't want to double count them
        const nonOps = previousRoles[newRole]?.length ?? 0;
        // Add the updated members to the new role
        updateMemberCount(state, spaceId, newRole, successCount - nonOps);
        // Remove the updated members from their previous roles
        Object.entries(previousRoles).forEach(([role, memberIds]) => {
          if (role === newRole) return;
          const removeCount = memberIds.filter(id => updatedMemberIds.includes(id)).length;
          updateMemberCount(state, spaceId, role, -removeCount);
        });
      })
      .addCase(updateFigmaApiKey.fulfilled, (state, action) => {
        const {
          payload: { result },
          meta: {
            arg: { spaceId },
          },
        } = action;
        fetchFigmaIntegrationStatus.getQueryData(state, { spaceId }).forEach(q => {
          q.data = result;
        });
      })
      .addCase(disableFigmaIntegration.fulfilled, (state, action) => {
        const {
          meta: {
            arg: { spaceId },
          },
        } = action;
        fetchFigmaIntegrationStatus.getQueryData(state, { spaceId }).forEach(q => {
          q.data = {
            auth: null,
            teams: [],
          };
        });
      })
      .addCase(addFigmaTeam.fulfilled, (state, action) => {
        const {
          payload: { result: newTeam },
          meta: {
            arg: { spaceId, teamId },
          },
        } = action;
        fetchFigmaIntegrationStatus.getQueryData(state, { spaceId }).forEach(q => {
          const idx = q.data.teams.findIndex(t => t.teamId === teamId);
          if (idx >= 0) {
            q.data.teams[idx] = newTeam;
          } else {
            q.data.teams.push(newTeam);
          }
        });
      })
      .addCase(removeFigmaTeam.fulfilled, (state, action) => {
        const {
          payload: {
            result: { auth },
          },
          meta: {
            arg: { spaceId, teamId },
          },
        } = action;
        fetchFigmaIntegrationStatus.getQueryData(state, { spaceId }).forEach(q => {
          _remove(q.data.teams, t => t.teamId === teamId);
          if (auth) {
            // auth comes back when removing a team in case the removed team
            // was putting the API key in an error state
            q.data.auth = auth;
          }
        });
      })
      .addCase(editInvoice.fulfilled, (state, action) => {
        const { spaceId } = action.meta.arg;
        fetchBillingData.getQueryData(state, { spaceId }).forEach(q => {
          q.data.charges = q.data.charges.map(charge =>
            charge.id === action.payload.result.id ? action.payload.result : charge
          );
        });
      })
      .addCase(replaceCreditCard.fulfilled, (state, action) => {
        const { spaceId } = action.meta.arg,
          newCard = action.payload.result;
        fetchBillingData.getQueryData(state, { spaceId }).forEach(q => {
          q.data.card = newCard;
        });
      });
  },
  objectBuilder => {
    objectBuilder
      .addCase(updateSpaceNotifications.fulfilled, (state, action: any) => {
        const {
          meta: {
            arg: { spaceId },
          },
          payload: {
            result,
            entities: { spaceMembers },
          },
        } = action;
        const space = state[spaceId];
        if (!space) return;
        state[spaceId].access.notificationPrefs = spaceMembers[result].notificationPrefs;
      })
      .addCase(leaveSpace.fulfilled, (state, action) => {
        const deletedSpaceId = action.meta.arg.spaceId;
        delete state[deletedSpaceId];
      })

      .addDefaultCase((state, action: any) => {
        const spaces = action?.response?.entities?.spaces || action?.payload?.entities?.spaces;
        if (spaces) {
          _mergeWith(state, spaces, (objValue, srcValue, key) => {
            if (["features", "permissions", "joinSettings"].includes(key)) {
              return srcValue;
            }
            if (key === "kits") {
              return _union(srcValue, objValue);
            }
            if (key === "avatars") {
              return srcValue ?? objValue;
            }
            if (key === "theme") {
              return srcValue ?? objValue;
            }
          });
        }
      });
  }
);
