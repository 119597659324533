// This is a saved result set from {api}/meta/content_types

import { combineReducers, createReducer } from "@reduxjs/toolkit";
import { QueryData } from "@redux/actions/actionCreators/createQueryAction";
import { mergeQuery } from "../helpers/mergeQuery";
/**
 * Stores the allowed content types
 *
 * {
 *    asset: [types]
 *    asset-PNG: [types] // replacment types for a PNG asset
 *    supporting_image-PNG: [types] // replacment types for a PNG supporting image
 * }
 */

const queries = createReducer<Record<string, QueryData<unknown>>>({}, builder => {
  builder.addDefaultCase((state, action) => {
    mergeQuery(state, action, "contentTypes");
  });
});
const objects = createReducer({}, _ => {});

export default combineReducers({
  queries,
  objects,
});
