import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

const [useFetchEmailPrefs, fetchEmailPrefs] = createAsyncAction(
  "user/fetchEmailPrefs",
  async () => {
    return await API.call<{ user: number }>({
      endpoint: `me/preview/${window.location.search}`,
      method: "GET",
      entity: API.Entity.user,
    });
  }
);

export default useFetchEmailPrefs;
export { fetchEmailPrefs };
