import React from "react";
import { TinyColor } from "@ctrl/tinycolor";
import styled, { useTheme } from "styled-components";
import {
  Flex,
  Text,
  ImageView,
  Space,
  utils,
  SpaceTheme,
  SpaceThemeNames,
  AnyObject,
  Box,
  MarkdownText,
  ThemeHeaderTitleOptions,
} from "@thenounproject/lingo-core";

import SearchBarButton from "../../../components/search/SearchBarButton";
import SpaceHeaderLogo from "./SpaceHeaderLogo";
import CreateButton from "./CreateButton";
import MobileSubNav from "../../../components/navigation/MobileSubNav";
import { TabletDown, TabletUp } from "@features/media-queries";

const wrapperThemeVals = {
  [SpaceThemeNames.classic]: {},
  [SpaceThemeNames.scher]: {},
  [SpaceThemeNames.wyman]: {
    px: "xl",
  },
};

const Wrapper = styled(Box).attrs<AnyObject>(props => {
  const overrides = wrapperThemeVals[props.theme.themeName];
  return { ...overrides };
})``;

const containerThemeVals = {
  [SpaceThemeNames.classic]: {
    alignItems: "center",
    aspectRatio: "3/1",
    justifyContent: "center",
    maxHeight: "728px",
  },
  [SpaceThemeNames.scher]: {
    alignItems: "flex-end",
    justifyContent: "flex-start",
    aspectRatio: "16/9",
    maxHeight: "728px",
    width: "100%",
  },
  [SpaceThemeNames.wyman]: {
    alignItems: "center",
    aspectRatio: "21/9",
    mx: "auto",
    mt: "60px",
    mb: "m",
    borderRadius: "12px",
    maxWidth: "1140px",
  },
};

const getContainerThemeOverrides = (props: utils.ThemedProps) => {
  return {
    [SpaceThemeNames.classic]: ``,
    [SpaceThemeNames.scher]: `
      @media screen and (min-width: 1364px) {
        aspect-ratio: unset;
        height: 728px
      }
    `,
    [SpaceThemeNames.wyman]: `
      @media screen and (max-width: 1020px) {
        margin-top: 52px;
      }
    `,
  }[props.theme.themeName];
};

const Container = styled(Flex).attrs<AnyObject>(props => {
  const overrides = containerThemeVals[props.theme.themeName];
  return { position: "relative", maxWidth: "100%", ...overrides };
})`
  ${props =>
    containerThemeVals[props?.theme?.themeName].aspectRatio &&
    `
      aspect-ratio: ${containerThemeVals[props?.theme?.themeName].aspectRatio};
    `};

  ${props => getContainerThemeOverrides(props)}
`;

const spaceImageThemeVals = {
  [SpaceThemeNames.wyman]: {
    borderRadius: "12px",
  },
};
const SpaceImage = styled(ImageView).attrs(props => {
  const overrides = spaceImageThemeVals[props.theme.themeName];
  return {
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    cover: true,
    ...overrides,
  };
})`
  object-fit: cover;
`;

const contentContainerThemeVals = {
  [SpaceThemeNames.classic]: {
    alignItems: "center",
    py: "xxxl",
    variations: {
      "mq.s": {
        px: "l",
      },
    },
  },
  [SpaceThemeNames.scher]: {
    alignItems: "flex-start",
    px: "xxxl",
    py: "xxxl",
    variations: {
      "mq.s": {
        p: "xxl",
      },
    },
  },
  [SpaceThemeNames.wyman]: {
    px: "xl",
    py: "xxl",
    variations: {
      "mq.s": {
        p: "xl",
      },
    },
  },
};

const ContentContainer = styled(Flex).attrs<AnyObject>(props => {
  const overrides = contentContainerThemeVals[props.theme.themeName];
  return { flexDirection: "column", width: "100%", maxWidth: "650px", ...overrides };
})``;

const titleThemeVals = {
  [SpaceThemeNames.classic]: {
    textAlign: "center",
  },
};
const Title = styled(Text).attrs(props => {
  const overrides = titleThemeVals[props.theme.themeName];
  return { as: "h1", font: "ui.mediumTitle", p: 0, ...overrides };
})``;

const subtitleThemeVals = {
  [SpaceThemeNames.classic]: {
    textAlign: "center",
    mt: "s",
  },
  [SpaceThemeNames.scher]: { textAlign: "left", mt: "m" },
  [SpaceThemeNames.wyman]: { textAlign: "left", mt: "m" },
};
const Subtitle = styled(MarkdownText).attrs(props => {
  const overrides = subtitleThemeVals[props.theme.themeName];
  return {
    font: "marketing.bodyLarge",
    elements: "basic",
    ...overrides,
  };
})`
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;

  @media (max-width: 1024px) {
    -webkit-line-clamp: 3;
  }

  a,
  button {
    text-decoration: underline;
    color: ${props => props.color};
    &:hover {
      color: ${props => props.color};
    }
  }
`;

function getDescriptionColor(theme: SpaceTheme): string {
  if (theme.headerDescriptionColor) return theme.headerDescriptionColor;
  if (theme.headerBackgroundImage) return "rgba(255, 255, 255, 1)";
  const c = new TinyColor(theme.headerBackgroundColor),
    { a: alpha } = c.toHsl();
  return c.isLight() || alpha <= 0.5 ? "rgba(42, 42, 42, 1)" : "rgba(255, 255, 255, 1)";
}

function getTitleColor(theme: SpaceTheme): string {
  if (theme.headerTitleColor) return theme.headerTitleColor;
  if (theme.headerBackgroundImage) return "rgba(255, 255, 255, 1)";
  const c = new TinyColor(theme.headerBackgroundColor),
    { a: alpha } = c.toHsl();
  return c.isLight() || alpha <= 0.5 ? "rgba(42, 42, 42, 1)" : "rgba(255, 255, 255, 1)";
}

type Props = {
  space: Space;
  name: string;
  spaceTitleRef?: any;
};

const SpaceHeader: React.FC<Props> = ({ name, spaceTitleRef, space }) => {
  const theme = useTheme();
  const themeName = theme.themeName ?? SpaceThemeNames.classic;
  const perms = space?.access?.permissions || [],
    canSearch = perms.includes("search_assets");

  const { headerBackgroundColor, headerBackgroundImage, headerLogoImage, headerTitleStyle } = theme;

  const titleColor = getTitleColor(theme);
  const descriptionColor = getDescriptionColor(theme);

  const searchBarThemeVals = {
    [SpaceThemeNames.classic]: {
      mt: "l",
    },
    [SpaceThemeNames.scher]: { mt: "xl" },
    [SpaceThemeNames.wyman]: {},
  };

  const renderTitle = () => {
    /**
     * In the client app, the image is type:
     * { width: number, height: number, url: string }
     */
    if (headerTitleStyle === ThemeHeaderTitleOptions.logo && headerLogoImage?.url) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return <SpaceHeaderLogo {...(headerLogoImage as any)} />;
    }
    /**
     * In the theme editor, the image preview is a data string;
     */
    if (headerTitleStyle === ThemeHeaderTitleOptions.logo && typeof headerLogoImage === "string") {
      return <SpaceHeaderLogo url={String(headerLogoImage)} />;
    }
    if (headerTitleStyle === ThemeHeaderTitleOptions.name || !headerTitleStyle) {
      return <Title color={titleColor}>{name}</Title>;
    }
    return null;
  };

  const renderDescription = () => {
    const text = theme.description;
    if (!text) return null;
    return <Subtitle color={descriptionColor}>{text}</Subtitle>;
  };

  return (
    <>
      <TabletUp>
        <Wrapper>
          <Container background={headerBackgroundColor || utils.getColor("grayLightest")}>
            {headerBackgroundImage && <SpaceImage src={headerBackgroundImage} />}
            <ContentContainer ref={spaceTitleRef}>
              {renderTitle()}
              {renderDescription()}
              {canSearch && themeName !== SpaceThemeNames.wyman && (
                <SearchBarButton width="400px" {...searchBarThemeVals[themeName]} />
              )}
            </ContentContainer>
          </Container>
        </Wrapper>
      </TabletUp>
      <TabletDown>
        <MobileSubNav button={<CreateButton />} />
      </TabletDown>
    </>
  );
};

export default SpaceHeader;
