import React, { Suspense } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import { loadStripe } from "@stripe/stripe-js";
import { Elements as StripeElements } from "@stripe/react-stripe-js";
import { ActivityIndicator } from "@thenounproject/lingo-core";

import Routes from "./routes";

import ErrorBoundary from "./components/ErrorBoundary";

import GAListener from "./components/GaListener";

import { store } from "@redux/store";
import { storeV1Context } from "@redux/hooks";

const Root: React.FC = () => {
  return (
    <StripeElements stripe={loadStripe(_lingoConfig.stripePublishable)}>
      <ReduxProvider store={store} context={storeV1Context}>
        <div style={{ height: "100%" }}>
          <Router>
            <GAListener>
              <ErrorBoundary>
                <Suspense
                  fallback={<ActivityIndicator size="large" center color="neutralLoader" />}>
                  <Routes />
                </Suspense>
              </ErrorBoundary>
            </GAListener>
          </Router>
        </div>
      </ReduxProvider>
    </StripeElements>
  );
};

export default Root;
