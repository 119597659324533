import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

export type Args = {
  token: string;
  password: string;
};

const [useResetPassword, resetPassword] = createAsyncAction(
  "auth/resetPassword",
  async ({ token, password }: Args) => {
    await API.call({
      endpoint: "auth/password-reset/",
      method: "POST",
      data: { token, new_password: password },
    });
  }
);

export default useResetPassword;
export { resetPassword };
