import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  spaceId: number;
  name: string;
};

const [useCreatePortal, createPortal] = createAsyncAction(
  "portals/create",
  async ({ spaceId, name }: Args) => {
    return await API.call<string>({
      endpoint: "portals",
      method: "POST",
      data: {
        space_id: spaceId,
        name,
      },
      entity: API.Entity.portal,
    });
  }
);

export default useCreatePortal;
export { createPortal };
