import { AnyObject, API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

export type LoginArgs = {
  token: string;
};

const [useGuestLogin, guestLogin] = createAsyncAction(
  "auth/guestLogin",
  async (args: LoginArgs) => {
    return await API.call<{ guest: AnyObject }>({
      endpoint: "login-guest",
      method: "POST",
      data: { token: args.token },
    });
  }
);

export default useGuestLogin;
export { guestLogin };
