import React, { useMemo } from "react";
import styled from "styled-components";
import QueryString from "query-string";
import { Button, Kit, KitVersion } from "@thenounproject/lingo-core";

import GalleryViewToggle from "./GalleryViewToggle";
import useInspectableActions from "@features/context-menus/hooks/useInspectableActions";
import { Inspectable } from "@constants/Inspector";
import useRecoveredItems from "@redux/actions/items/useRecoveredItems";

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  display: contents;
`;

type Props = {
  kit: Kit;
  kitVersion: KitVersion;
  selectedItems: string[];
};

const KitRecoveredItemActions = ({ kit, kitVersion, selectedItems }: Props) => {
  const hasItems = kitVersion.counts.recovery !== 0,
    selectedItemIds = selectedItems.map(i => i.substring(0, i.lastIndexOf("-")));

  const { data: items } = useRecoveredItems({
    kitId: kit.kitId,
    spaceId: kit.spaceId,
  });

  const inspectables: Inspectable[] = useMemo(() => {
    return selectedItems.reduce((acc, item) => {
      const id = item.substring(0, item.lastIndexOf("-"));
      const stateItem = items.find(i => i.id === id);
      if (stateItem) {
        acc.push({ item: stateItem, asset: stateItem.asset });
      }
      return acc;
    }, []);
  }, [items, selectedItems]);

  const { moveItems } = useInspectableActions(inspectables);

  if (!kit) return null;
  const permissions = kit && kit.access ? kit.access.permissions : [];
  if (!permissions.includes("edit_content")) return null;

  const query = QueryString.parse(window.location.search);
  const returnUrl = (query && (query.previous as string)) || `/k/${kit.urlId}`;

  return (
    <Container data-inspector-clear="true">
      <GalleryViewToggle styleOverrides={{ mr: "l" }} />
      <Button buttonStyle="secondary" size="small" text="Done" link={returnUrl} />
      <Button
        flexShrink={0}
        buttonStyle="primary"
        size="small"
        text="Move To Section"
        disabled={!selectedItemIds.length || !hasItems}
        ml="s"
        onClick={moveItems}
      />
    </Container>
  );
};

export default KitRecoveredItemActions;
