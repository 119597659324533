/**
 * Downloads the file from action.url
 */
import type { Middleware } from "@reduxjs/toolkit";
import { downloadFile } from "@actions/useDownloadFile";

const downloadFileMiddleware: Middleware = _store => next => action => {
  if (downloadFile.match(action)) {
    let iframe = document.getElementById("hiddenDownloader") as HTMLIFrameElement | null;
    if (!iframe) {
      iframe = document.createElement("iframe");
      iframe.id = "hiddenDownloader";
      iframe.style.visibility = "hidden";
      iframe.style.display = "none";
      iframe.style.position = "absolute";
      iframe.style.left = "-9999px";
      iframe.style.top = "-9999px";
      document.body.appendChild(iframe);
    }
    iframe.src = action.payload;
  }
  return next(action);
};

export default downloadFileMiddleware;
