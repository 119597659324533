import _remove from "lodash/remove";
import { createReducer } from "@reduxjs/toolkit";
import { enqueueUpload } from "@actions/uploads/enqueueUpload";
import { uploadFile } from "@redux/actions/uploads/uploadFile";
import { type UploadAction } from "@redux/actions/uploads";
import { dismissUploads } from "@redux/actions/useDismissUploads";

export default createReducer<{
  processingQueue: string[];
  recentUploads: string[];
  uploads: Record<string, UploadAction>;
}>({ processingQueue: [], recentUploads: [], uploads: {} }, builder => {
  builder
    .addCase(dismissUploads, state => {
      const completed = new Set(
        Object.keys(state.uploads).filter(id =>
          ["success", "failed"].includes(state.uploads[id].upload.status)
        )
      );
      state.recentUploads = state.recentUploads.filter(id => !completed.has(id));
      completed.forEach(id => delete state.uploads[id]);
    })
    .addCase(enqueueUpload, (state, action) => {
      const { upload } = action.payload;
      if (!upload.error) {
        state.processingQueue.push(upload.id);
      }
      state.recentUploads.push(upload.id);
      state.uploads[upload.id] = action.payload;
    })
    .addCase(uploadFile.pending, (state, action) => {
      const upload = action.meta.arg.upload;
      // Update the status
      state.uploads[upload.id].upload.status = "started";
    })
    .addCase(uploadFile.fulfilled, (state, action) => {
      const completedUpload = action.meta.arg.upload;
      // Remove the upload from the processing queue
      _remove(state.processingQueue, id => id === completedUpload.id);

      const { entities, result } = action.payload,
        portalItem = entities.portalItems?.[result],
        item = entities.items?.[result],
        asset =
          entities.assets?.[result] ??
          entities.assets?.[item?.assetId] ??
          entities.assets?.[portalItem?.assetId];
      const dateAddedStr = asset?.dateAdded ?? item?.dateAdded;
      const upload = state.uploads[completedUpload.id].upload;
      // Update the upload with the space ID, some upload actions only get a kit/section
      // this is a simple way to ensure the space ID is always present
      upload.insertPosition.spaceId = asset?.spaceId ?? item?.spaceId;
      upload.insertPosition.portalId = portalItem?.portalId;
      upload.status = "success";
      upload.dateAdded = dateAddedStr ? new Date(dateAddedStr) : undefined;
    })
    .addCase(uploadFile.rejected, (state, action) => {
      const upload = action.meta.arg.upload;
      // Remove the upload from the processing queue
      _remove(state.processingQueue, id => id === upload.id);
      state.uploads[upload.id].upload.error = action.payload;
      state.uploads[upload.id].upload.status = "failed";
    });
});
