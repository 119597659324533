import { API, base64, DownloadRequest, DownloadRequestStatus } from "@thenounproject/lingo-core";
import { createQueryAction } from "../actionCreators";
import { DEFAULT_PAGE_SIZE } from "../actionCreators/createQueryAction";

export type DownloadRequestStatusFetchOptions = DownloadRequestStatus | "all";

type Args = {
  spaceId: number;
  status: DownloadRequestStatusFetchOptions;
  sort: string;
};

const [useDownloadRequests, fetchDownloadRequests] = createQueryAction<
  Args,
  { total: number; requests: DownloadRequest[] },
  { total: number; requests: number[] }
>(
  {
    entity: "downloadRequests",
    action: "query",
    condition: ({ args: { spaceId } }) => Boolean(spaceId),
    pagingKey: "requests",
    denormalize: {
      entity: null,
      requests: "downloadRequests",
    },
  },
  async ({ args: { status, sort, spaceId }, paging }) => {
    return await API.call<{ total: number; requests: number[] }>({
      endpoint: `spaces/${spaceId}/download-requests`,
      method: "GET",
      entity: API.Entity.downloadRequest,
      query: {
        query: base64.encode({
          status,
          limit: DEFAULT_PAGE_SIZE,
          sort,
          page: paging.page,
        }),
      },
    });
  }
);

export default useDownloadRequests;
export { fetchDownloadRequests };
