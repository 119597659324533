import { API, KitCollection } from "@thenounproject/lingo-core";
import { createQueryAction } from "@redux/actions/actionCreators";

type Args = {
  collectionId: string;
};

const [useKitCollection, fetchKitCollection] = createQueryAction<Args, KitCollection, string>(
  {
    entity: "kitCollections",
    action: "fetch",
    denormalize: {
      items: {
        entity: "kitCollectionItems",
        kit: "kits",
      },
    },
    condition: ({ args }) => Boolean(args.collectionId),
  },
  async ({ args }) => {
    return await API.call<string>({
      endpoint: `kit_collections/${args.collectionId}`,
      method: "GET",
      entity: API.Entity.kitCollection,
    });
  }
);

export default useKitCollection;
export { fetchKitCollection };
