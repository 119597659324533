/**
 * User reducer
 */

import _merge from "lodash/merge";

import { User } from "@thenounproject/lingo-core";

import createEntityReducer from "../helpers/createEntityReducer";

export default createEntityReducer<User>(
  "users",
  _queryBuilder => {
    // nothing to do here yet...
  },
  objectBuilder => {
    objectBuilder.addDefaultCase((state, action: any) => {
      const users = action.response?.entities?.users ?? action.payload?.entities?.users;
      if (users) {
        return _merge(state, users);
      }
      return state;
    });
  }
);
