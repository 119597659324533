import React, { useState, useContext, useCallback } from "react";
import {
  SelectionContext,
  initialState,
  SelectionState,
  SelectionUpdateInput,
} from "./SelectedItemsContext";
import { useObject } from "@hooks/useObject";

export default function SelectedItemsProvider({ children }: { children: React.ReactNode }) {
  const [selectionState, setSelectionState] = useState<SelectionState>(initialState);

  const selectItems = useCallback(({ type, ids }: SelectionUpdateInput) => {
    setSelectionState(prevState => ({ ...prevState, [type]: ids }));
  }, []);

  const deselectItems = useCallback(({ type, ids }: SelectionUpdateInput) => {
    if (!ids) return;

    setSelectionState(prevState => {
      return {
        ...prevState,
        [type]: (prevState[type] as string[]).filter(id => !(ids as string[]).includes(id)),
      };
    });
  }, []);

  const clearSelection = useCallback(({ type }: SelectionUpdateInput) => {
    setSelectionState(prevState => ({ ...prevState, [type]: [] }));
  }, []);

  const context = useObject({
    selectionState,
    selectItems,
    deselectItems,
    clearSelection,
  });

  return <SelectionContext.Provider value={context}>{children}</SelectionContext.Provider>;
}

export const useSelectedItemsContext = () => useContext(SelectionContext);
