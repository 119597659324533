import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  buildURL,
  Text,
  Button,
  ActivityIndicator,
  useNavigation,
  ErrorCode,
} from "@thenounproject/lingo-core";

import { AuthContainer, AuthContent } from "../auth/AuthElements";
import { useParams } from "react-router-dom";
import useVerifySpaceEmailDomain from "@redux/actions/spaces/useVerifySpaceEmailDomain";
import useNotifications from "@actions/useNotifications";
import EmptyState from "../EmptyState";
import useVerifyEmail from "@actions/auth/useVerifyEmail";
import useSendVerificationEmail from "@redux/actions/auth/useSendVerificationEmail";

enum VerifyType {
  domain = "domain",
  email = "email",
}

function AuthVerify() {
  const [error, setError] = useState<string>();

  const { showNotification } = useNotifications(),
    navigation = useNavigation();

  const { verifyType, token } = useParams<{ verifyType: string; token: string }>();

  // Verify a join domain. If successful this will also save the domain
  // on the space. This does not verify the email of the user account.
  const [_verifyDomain] = useVerifySpaceEmailDomain(),
    verifyDomain = useCallback(async () => {
      const res = await _verifyDomain({ token });
      if (res.error?.code === ErrorCode.unauthorized) {
        showNotification({ message: "Login to continue verifying your domain" });
        navigation.push(`/login?next=/verify/domain/${token}`);
      } else if (res.error) {
        setError(res.error.message);
      } else {
        const { result, entities } = res.response;
        const space = entities.spaces[result];
        const url = buildURL("/settings/access-controls", { space });
        navigation.replace(url);
        showNotification({ message: "Join by email domain successfully added" });
      }
    }, [_verifyDomain, navigation, showNotification, token]);

  // Email verification simply verifies the email on the user account
  const [_verifyEmail] = useVerifyEmail(),
    verifyEmail = useCallback(async () => {
      const res = await _verifyEmail({ token });
      if (res.error?.code === ErrorCode.unauthorized) {
        showNotification({ message: "Login to continue verifying your email" });
        navigation.push(`/login?next=/verify/email/${token}`);
      } else if (res.error) {
        setError(res.error.message);
      } else {
        const url = buildURL("/join-spaces");
        navigation.replace(url);
        showNotification({ message: "Email verified" });
      }
    }, [_verifyEmail, navigation, showNotification, token]);

  const [resendEmail] = useSendVerificationEmail();
  const resend = useCallback(async () => {
    const res = await resendEmail();
    if (res.error) {
      showNotification({ message: res.error.message, level: "error" });
    } else {
      showNotification({ message: "Email sent. Check your inbox." });
    }
  }, [resendEmail, showNotification]);

  // calls the appropriate action based on the verifyType
  useEffect(() => {
    switch (verifyType) {
      case VerifyType.domain:
        return void verifyDomain();
      case VerifyType.email:
        return void verifyEmail();
      default:
        setError("Invalid link");
    }
  }, [verifyDomain, verifyEmail, verifyType]);

  // calls the appropriate action based on the verifyType
  function renderRetry() {
    switch (verifyType) {
      case VerifyType.domain:
        return null;
      case VerifyType.email:
        return (
          <Text textAlign="center" mt="40px" font="ui.small">
            It&apos;s possible your link expired?{" "}
            <Button
              id="resend email button"
              buttonStyle="tertiary"
              size="small"
              type="button"
              text="Resend"
              onClick={resend}
            />{" "}
            to try again.
          </Text>
        );
      default:
        return null;
    }
  }

  // MARK : Rendering
  // -------------------------------------------------------------------------------
  function renderLoading() {
    // Map the possible verifyTypes to a loading message
    const title = {
      domain: "Verifying domain",
      email: "Verifying email",
    }[verifyType];
    return (
      <Box>
        {title && (
          <Text as="h1" font="ui.title">
            {title}
          </Text>
        )}
        <ActivityIndicator height={"150px"} center />
      </Box>
    );
  }

  function renderError() {
    return (
      <EmptyState
        title="Something went wrong"
        subtitle={error}
        size="large"
        extra={
          <>
            <Button link={"/space"} mt="l" text={"Go to Spaces"} />
            {renderRetry()}
            <Text mt="l" font="ui.small">
              If you need help, please{" "}
              <Button
                buttonStyle="tertiary"
                size="small"
                text="contact us."
                link="mailto:info@lingoapp.com"
              />
            </Text>
          </>
        }
      />
    );
  }

  return (
    <AuthContainer>
      <AuthContent withHeader styleOverrides={{ flexDirection: "column", textAlign: "center" }}>
        {error ? renderError() : renderLoading()}
      </AuthContent>
    </AuthContainer>
  );
}

export default AuthVerify;
