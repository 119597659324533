/* eslint-disable no-useless-escape */
const figmaLinkPattern =
  /^(?:https:\/\/)?(?:www\.)?figma\.com\/(design|file|proto)\/([0-9a-zA-Z]{22,128})(?:\/([^\?\n\r\/]+)?((?:\?[^\/]*?node-id=([^&\n\r\/]+))?[^\/]*?)(\/duplicate)?)?$/;
export const validateFigmaLink = (link: string): boolean => Boolean(link.match(figmaLinkPattern));

export const flattenFigmaFile = (file: FigmaFile): FigmaNode[] => {
  const result = [];
  if (file.pages) {
    file.pages.forEach(page => {
      const frames = flattenNestedFigmaFrames(page.children);
      result.push(frames);
    });
  }
  return result.flat();
};

const flattenNestedFigmaFrames = (frames: FigmaNode[]): FigmaNode[] => {
  let result = [];
  frames.forEach(frame => {
    result.push(frame);
    if (Array.isArray(frame.children)) {
      result = result.concat(flattenNestedFigmaFrames(frame.children));
    }
  });
  return result;
};

export const flattenFigmaComponents = (file: FigmaFile): FigmaNode[] => {
  const result = [];
  if (file.components) {
    file.components.forEach(component => {
      if (component.children) result.push(component.children);
      else result.push(component);
    });
  }
  return result.flat();
};

export const buildFigmaNodeLink = (fileKey: string, nodeId: string): string => {
  return `https://www.figma.com/file/${fileKey}/?node-id=${nodeId}`;
};

export const getFigmaNodeDepthPadding = (depth: number | undefined): string => {
  if (!depth) return "none";

  /**
   * Put a limit of around 100px of padding.  Super users be damned.
   */
  return `${Number(depth < 8 ? depth : 7) * 16}px`;
};

export const filterFigmaNodes = (query: string, nodes: FigmaNode[]): FigmaNode[] => {
  return nodes.filter(n => n.name.toUpperCase().includes(query.toUpperCase()));
};

type FigmaNameMap = {
  [key: string]: string;
};

export const generateFigmaNameMap = (nodes: FigmaNode[]): FigmaNameMap => {
  return nodes.reduce((acc, cur) => {
    acc[cur.node_id] = cur.name;
    return acc;
  }, {});
};

export const orderFigmaNodes = (selectedNodes: string[], allNodes: string[]): string[] => {
  const orderedNodes = [];
  allNodes.forEach(node => {
    if (selectedNodes.includes(node)) {
      orderedNodes.push(node);
    }
  });
  return orderedNodes;
};

export const figmaNodeIconMap = {
  COMPONENT: "info.component",
  COMPONENT_SET: "info.component",
  FRAME: "info.frame",
  GROUP: "info.frame",
};

export const figmaNodeTypes = {
  COMPONENT: "COMPONENT",
  COMPONENT_SET: "COMPONENT_SET",
  FRAME: "FRAME",
  GROUP: "GROUP",
};
