/**
 * Setup the Sentry client.
 *
 * See docs: https://docs.sentry.io/clients/javascript/config/
 */

import * as Sentry from "@sentry/react";
// import type { RootState } from "@redux/store";

export default function setupSentry() {
  const conf = window._lingoConfig;

  if (!conf.sentryDSN) {
    console.warn("Sentry is not configured");
    return;
  }

  Sentry.init({
    dsn: conf.sentryDSN,
    environment: conf.environment,
    release: conf.sentryRelease,
    allowUrls: [/static.*\.lingoapp\.com/],
    tracesSampleRate: 0.25,
    normalizeDepth: 5,
  });
}

export const captureException = (err: any) => {
  if (err instanceof Error) {
    Sentry.captureException(err);
  } else {
    let msg: string;
    if (typeof err === "string") {
      msg = err;
    } else {
      msg = [err?.code, err?.message].filter(Boolean).join(" - ");
    }
    Sentry.captureMessage(msg ?? "Unknown error", { extra: err });
  }
};

// These action types will not be added to breadcrumbs, since they would clutter the logs
const ignoreActionTypes = [];

// These keys will be redacted from entity object in state
const ignoreEntityTypes = [];

/**
 * Reduces size of entities object from Redux state by converting each entity object into an array
 * of its keys. For instance, entities.kits will be an array of kit UUIDs, instead of objects
 * containing all kit data
 * @param {object} entities
 */
const flattenEntities = function (entities) {
  const flatEntities = {};
  Object.keys(entities).forEach(key => {
    if (ignoreEntityTypes.includes(key)) {
      flatEntities[key] = "(redacted)";
    } else {
      flatEntities[key] = Object.keys(entities[key]);
    }
  });
  return flatEntities;
};

function actionTransformer(action) {
  if (ignoreActionTypes.includes(action?.type)) return null;
  if (action?.response?.entities) {
    return {
      ...action,
      response: { ...action.response, entities: flattenEntities(action.response.entities) },
    };
  }
  return action;
}

function stateTransformer(state) {
  return { ...state, entities: flattenEntities(state.entities) };
}

export const reduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer,
  stateTransformer,
  configureScopeWithState: (scope, state) => {
    if (state.user) {
      scope.setUser({
        id: state.user.id,
        email: state.user.email,
        username: state.user.name,
      });
    }
  },
});
