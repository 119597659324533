import React from "react";
import { createDispatchHook, createSelectorHook } from "react-redux";
import type { TypedUseSelectorHook } from "react-redux";
import type { RootState, AppDispatch } from "./store";

/**
 * Create contexts for each store, and export the custom hooks for each store.
 */

export const storeV1Context = React.createContext(null);

const useV1Dispatch: () => AppDispatch = createDispatchHook(storeV1Context);
const useV1Selector: TypedUseSelectorHook<RootState> = createSelectorHook(storeV1Context);
export const useAppDispatchV1: () => AppDispatch = useV1Dispatch;
export const useAppSelectorV1: TypedUseSelectorHook<RootState> = useV1Selector;
