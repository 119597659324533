import { API, Portal, base64 } from "@thenounproject/lingo-core";
import _sortBy from "lodash/sortBy";

import { createQueryAction } from "../actionCreators";

type Args = {
  spaceId: number | string;
};

const [usePortals, fetchPortals] = createQueryAction<
  Args,
  { portals: Portal[]; total: number },
  { portals: string[]; total: number }
>(
  {
    entity: "portals",
    action: "query",
    condition: ({ args: { spaceId } }) => Boolean(spaceId),
    pagingKey: "portals",
    denormalize: {
      entity: null,
      portals: "portals",
    },
    prepareData: data => {
      if (!data) {
        return;
      }
      return {
        portals: _sortBy(data.portals, p => p.name.toLowerCase()),
        total: data.total,
      };
    },
  },
  async ({ args, paging }) => {
    const query = base64.encode({
      limit: 1000,
      offset: 1000 * (paging?.page - 1),
    });

    return await API.call<{ portals: string[]; total: number }>({
      endpoint: `spaces/${args.spaceId}/portals`,
      method: "GET",
      entity: API.Entity.portal,
      query: { query },
    });
  }
);

export default usePortals;
export { fetchPortals };
