import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  sectionId: string;
  kitId: string;
  createReferences: boolean;
};
const [useDuplicateSection, duplicateSection] = createAsyncAction(
  "sections/duplicate",
  async ({ sectionId, kitId, createReferences }: Args, thunkApi) => {
    const versionedSectionId = `${sectionId}-0`;
    const fromKitId = thunkApi.getState().entities.sections.objects[versionedSectionId].kitId;
    const res = await API.call<{ sections: string[] }>({
      endpoint: `/sections/${sectionId}/duplicate`,
      method: "PUT",
      entity: API.Entity.section,
      data: { kit_uuid: kitId, create_references: createReferences },
    });
    const newSection = res.result.sections.filter(s => s !== versionedSectionId)[0];
    return { result: newSection, fromKitId, entities: res.entities };
  }
);
export default useDuplicateSection;
export { duplicateSection };
