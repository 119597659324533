import QueryString from "query-string";
import { API, base64, SearchFilterInput, LingoError } from "@thenounproject/lingo-core";
import { createQueryAction } from "@redux/actions/actionCreators";
import { Aggregations } from "@redux/reducers/entities/search";
import getSearchContext from "./getSearchContext";

type SearchResult = {
  results: [];
  aggregations: Aggregations;
  error: null | LingoError;
  total: number;
  offset: number;
  limit: number;
  filters: SearchFilterInput[];
};

type APIResponse = {
  filters: SearchFilterInput[];
  queries: {
    query: SearchResult;
  };
};

type Args = {
  spaceId: string | number;
};

const SEARCH_PAGE_SIZE = 50;

export function createSearchHook(action: string, queryType: "items" | "jumpTo") {
  return createQueryAction<Args, SearchResult, SearchResult>(
    {
      entity: "search",
      action,
      denormalize: false,
      pagingKey: "results",
    },
    async ({ args, paging }, thunkApi) => {
      const state = thunkApi.getState();

      const { filters, sort } = state.entities.search.objects,
        query = {
          filters,
          queries: {
            query: {
              type: queryType === "items" ? "content" : "jump_to",
              offset: SEARCH_PAGE_SIZE * (paging.page - 1),
              limit: SEARCH_PAGE_SIZE,
              sort,
              ...getSearchContext(state),
            },
          },
        },
        queryString = QueryString.stringify({
          query: base64.encode(query),
        });
      const res = await API.call<APIResponse>({
        endpoint: `search/spaces/${args.spaceId}?${queryString}`,
        method: "GET",
        entity: API.Entity.search,
      });

      return {
        result: { ...res.result.queries.query, filters: res.result.filters },
        entities: res.entities,
      };
    }
  );
}
