import { SpaceThemeNames, SelectOption, ThemeHeaderTitleOptions } from "@thenounproject/lingo-core";

export interface Font {
  family: string;
  source: string;
  css_url?: string;
  category?: string;
  variants?: FontVariant[];
}

interface FontVariant {
  display_name: string;
  extension: string;
  family: string;
  font_name: string;
  g_variant: string;
  source: string;
  url: string;
  variant: string;
  version: string;
}

export enum SpaceThemePreviews {
  "home" = "Home",
  "gallery" = "Kit Gallery",
}

export enum SpaceThemeColorOptions {
  "accent" = "Accent",
  "header" = "Header Background",
  "headerDescription" = "Description Color",
  "headerTitle" = "Title Color",
  "noteInfo" = "Info",
  "noteSuccess" = "Success",
  "noteWarning" = "Warning",
  "assetLight" = "Light",
  "assetDark" = "Dark",
}

export type ColorMap = { [K in SpaceThemeColorOptions]?: { new: string; existing: string } };

export enum SpaceThemeTextOptions {
  "description" = "Description",
}

export enum SpaceThemeSelectOptions {
  "themeName" = "themeName",
  "headerTitleStyle" = "headerTitleStyle",
}

export const themeSelectOptions: SelectOption<SpaceThemeNames>[] = [
  { label: "Classic", value: SpaceThemeNames.classic },
  { label: "Scher", value: SpaceThemeNames.scher },
  { label: "Wyman", value: SpaceThemeNames.wyman },
];

export const spaceTitleSelectOptions: SelectOption<ThemeHeaderTitleOptions>[] = [
  { label: "Space Name", value: ThemeHeaderTitleOptions.name },
  { label: "Logo", value: ThemeHeaderTitleOptions.logo },
  { label: "No Title", value: ThemeHeaderTitleOptions.none },
];

export enum SpaceThemeImageOptions {
  "headerBackgroundImage" = "headerBackgroundImage",
  "headerLogoImage" = "headerLogoImage",
}

export enum SpaceThemeImageAPIKeyMap {
  "headerBackgroundImage" = "header_background_image",
  "headerLogoImage" = "header_logo_image",
}

export type NewFiles = { [K in SpaceThemeImageOptions]?: File };

export enum SpaceThemeHeaderBackgroundTypes {
  "color" = "headerBackgroundColor",
  "image" = "headerBackgroundImage",
}

export const themeBackgroundOptions: SelectOption<SpaceThemeHeaderBackgroundTypes>[] = [
  { label: "Solid Color", value: SpaceThemeHeaderBackgroundTypes.color },
  { label: "Image", value: SpaceThemeHeaderBackgroundTypes.image },
];

export const unsavedChangesModalTextProps = {
  title: "Unsaved Changes",
  message: "You have unsaved changes in the Theme Editor. Are you sure you want to leave?",
  buttonText: "Discard changes and leave",
  buttonProcessingText: "Discard changes and leave",
};

export const portalTitleSelectOptions: SelectOption<ThemeHeaderTitleOptions>[] = [
  { label: "Portal Name", value: ThemeHeaderTitleOptions.name },
  { label: "Logo", value: ThemeHeaderTitleOptions.logo },
  { label: "No Title", value: ThemeHeaderTitleOptions.none },
];
