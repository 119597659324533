/*
 * Redux reducers live here. Read Redux docs for more information:
 * http://redux.js.org/docs/basics/Reducers.html
 */

import { combineReducers } from "redux";

import dragging from "./dragging";
import emailPrefs from "./emailPrefs";
import entities from "./entities";
import modals from "./modals";
import navPoint from "./navPoint";
import notifications from "./notifications";
import uploads from "./uploads";
import user from "./user";

const rootReducer = combineReducers({
  dragging,
  emailPrefs,
  entities,
  modals,
  navPoint,
  notifications,
  uploads,
  user,
});

export default rootReducer;
