import { createQueryAction } from "@redux/actions/actionCreators";
import { API, Asset } from "@thenounproject/lingo-core";

type Args = {
  assetId: string;
  shareToken?: string;
};

const [useAsset, fetchAsset] = createQueryAction<Args, Asset, string>(
  {
    entity: "assets",
    action: "fetch",
    denormalize: {
      uploader: "users",
      items: {
        entity: "items",
        kit: "kits",
        section: "sections",
        item: "items",
      },
    },
    condition: ({ args }) => Boolean(args.assetId),
  },
  async ({ args }) => {
    return await API.call<string>({
      endpoint: `assets/${args.assetId}`,
      method: "GET",
      entity: API.Entity.asset,
      query: {
        asset_token: args.shareToken,
      },
    });
  }
);

export default useAsset;
export { fetchAsset };
