import { API, APIResultList } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  spaceId: number;
  keywords: { keyword: string; new_keyword: string }[];
};
type Result = {
  keyword: string;
  newKeyword: string;
  assetsUpdated: number;
};

const [useUpdateTags, updateTags] = createAsyncAction(
  "tags/update",
  async ({ spaceId, keywords }: Args) => {
    return await API.call<APIResultList<"keywords", Result>>({
      endpoint: `spaces/${spaceId}/keywords`,
      method: "PUT",
      // Reusing this to get things camelized for now
      entity: API.Entity.analytics,
      data: {
        keywords,
      },
    });
  }
);

export default useUpdateTags;
export { updateTags };
