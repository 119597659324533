import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";
import { selectSpace } from "@redux/selectors/entities/spaces";

function spaceHasPaidSubscription(space) {
  return (
    space?.subscription?.status === "active" &&
    ["tier-0", "trial-1", "trial-2"].includes(space.subscription.plan) === false
  );
}

type Args = {
  spaceId: number;
  plan: string;
  date: number;
  stripeToken: string;
  receiptExtra?: string;
};

const [useUpdateSubscription, updateSubscription] = createAsyncAction(
  "space/updateSubscription",
  async ({ spaceId, plan, date, stripeToken, receiptExtra }: Args, thunkApi) => {
    const space = selectSpace(thunkApi.getState(), { spaceId }),
      hasPaidSubscription = spaceHasPaidSubscription(space);

    const res = await API.call<number>({
      endpoint: `spaces/${spaceId}/subscription/`,
      method: "POST",
      data: {
        plan,
        proration_date: date,
        stripe_token: stripeToken,
        receipt_extra: receiptExtra,
      },
      entity: API.Entity.space,
    });

    return {
      ...res,
      hasPaidSubscription,
    };
  }
);

export default useUpdateSubscription;
export { updateSubscription };
