import { createSelector } from "reselect";
import { useAppSelectorV1 } from "@redux/hooks";
import { Space, CustomField } from "@thenounproject/lingo-core";

import { getCustomFields } from "../getters";
import { selectSpace } from "./spaces";

export const selectSpaceFields = createSelector(
  [selectSpace, getCustomFields],
  (space: Space, fields: Record<string, CustomField>) => {
    const allFields =
      Object.values(fields)
        .filter(s => s?.status === "active" && s?.spaceId === space?.id)
        .sort((a, b) => {
          return a.displayOrder < b.displayOrder ? -1 : 1;
        }) || [];

    if (space?.access?.isPublic === false) {
      return allFields;
    }
    return allFields.filter(f => f.public);
  }
);

export const useSelectSpaceFields = () => useAppSelectorV1(selectSpaceFields);
