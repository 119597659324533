import { API, APIResultList } from "@thenounproject/lingo-core";
import { createAsyncAction } from "@actions/actionCreators";

type Args = {
  portalItems: string[];
  portalId: string;
};

const [useDeletePortalItems, deletePortalItems] = createAsyncAction(
  "portalItems/batchDelete",
  async (args: Args) => {
    const data = { portal_items: args.portalItems.map(id => ({ uuid: id, status: "deleted" })) };
    return await API.call<APIResultList<"portalItems", string>>({
      endpoint: `sync/portal_items`,
      method: "PUT",
      entity: API.Entity.portalItem,
      data,
    });
  }
);

export default useDeletePortalItems;
export { deletePortalItems };
