import _sortBy from "lodash/sortBy";
import { API, KitCollection } from "@thenounproject/lingo-core";
import { createQueryAction } from "@actions/actionCreators";

type Args = {
  spaceId: number | string;
};

const [useKitCollections, fetchKitCollections] = createQueryAction<Args, KitCollection[], string[]>(
  {
    entity: "kitCollections",
    action: "query",
    denormalize: {
      items: {
        entity: "kitCollectionItems",
        kit: "kits",
      },
    },
    prepareData: r => _sortBy(r, k => k.name.toLowerCase()),
  },
  async ({ args }) => {
    const res = await API.call<number>({
      endpoint: `spaces/${args.spaceId}?include=kit_collections`,
      method: "GET",
      entity: API.Entity.space,
    });
    const collectionIds = res.entities.spaces[res.result].kitCollections;
    return { result: collectionIds, entities: res.entities };
  }
);

export default useKitCollections;
export { fetchKitCollections };
