import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  spaceId: number;
  newOwnerId: number;
};

const [useTransferSpace, transferSpace] = createAsyncAction(
  "spaces/transferSpace",
  async ({ spaceId, newOwnerId }: Args) => {
    return await API.call<number>({
      endpoint: `spaces/${spaceId}/transfer`,
      method: "POST",
      entity: API.Entity.space,
      data: { user_id: newOwnerId },
    });
  }
);
export default useTransferSpace;
export { transferSpace };
