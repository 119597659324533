import { API, Space, AssetView } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  spaceId: Space["id"];
  viewId: AssetView["id"];
};

const [useDeleteView, deleteView] = createAsyncAction("views/delete", async ({ viewId }: Args) => {
  return await API.call<string>({
    endpoint: `/views/${viewId}`,
    method: "DELETE",
    entity: API.Entity.assetView,
  });
});

export default useDeleteView;
export { deleteView };
