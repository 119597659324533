/**
 * Styled component used as top-level heading
 */

import React, { ComponentProps } from "react";
import styled from "styled-components";
import { Flex, Text } from "@thenounproject/lingo-core";

type Props = {
  children?: React.ReactNode;
  title?: string;
  font?: string;
  sticky?: boolean;
  className?: string;
  styleOverrides?: ComponentProps<typeof Flex>;
};

const Container = styled(Flex).attrs<Props>(props => {
  return {
    px: "m",
    py: "m",
    flexDirection: "row",
    justifyContent: "space-between",
    background: "toolbarBackground",
    top: props.sticky ? 0 : null,
    position: props.sticky ? "sticky" : null,
    zIndex: props.sticky ? 900 : 800,
    ...props.styleOverrides,
    variations: {
      "mq.s": {
        pt: "m",
      },
      ...props.styleOverrides?.variations,
    },
  };
})<Props>`
  // Makes zIndex behave properly in Safari
  // https://stackoverflow.com/questions/40895387/z-index-not-working-on-safari-fine-on-firefox-and-chrome
  transform: translate3d(0, 0, 0);
`;

const Heading: React.FC<Props> = props => {
  let { children, title, font = "ui.pageTitle", sticky = true, styleOverrides } = props;
  title = typeof props.children === "string" ? props.children : props.title;
  return (
    <Container sticky={sticky} styleOverrides={styleOverrides}>
      {title ? (
        <Text as="h1" font={font}>
          {title}
        </Text>
      ) : null}
      {typeof children === "object" ? (
        <div style={title ? {} : { width: "100%" }}>{children}</div>
      ) : null}
    </Container>
  );
};

export default Heading;
