import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  password: string;
  otp: string;
};

const [useVerifyTOTP, verifyTOTP] = createAsyncAction(
  "auth/verifyTOTP",
  async ({ password, otp }: Args) => {
    const response = await API.call<string>({
      endpoint: "me/mfa/totp/",
      method: "PUT",
      data: { password, otp },
    });
    return response;
  }
);

export default useVerifyTOTP;
export { verifyTOTP };
