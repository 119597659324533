import { MarketingButtons } from "../../components/MarketingButtons";
import { PageContent } from "../../pages/templates/PageLayout";
import featureCards from "../featureCards";

import Images from "../images";

const content: PageContent = {
  style: "light",
  sections: [
    {
      type: "HeroSection",
      title: "Custom Branding",
      style: "light",
      layoutDirection: "vertical",
      subtitle:
        "Replace Lingo's branding with your own to make your workspace feel on-brand and official.",
      image: {
        src: Images.Product.customBranding_hero,
        alt: "",
      },
      primaryButton: MarketingButtons.getStarted,
      secondaryButton: MarketingButtons.bookDemo,
    },
    {
      type: "MediaSection",
      title: "Showcase your brand",
      subtitle:
        "Present your brand to your team and your customers in a way that inspires and creates confidence.",
      image: {
        src: Images.Product.customBranding_showcase,
        alt: "Showcase your brand with custom branding",
      },
    },
    {
      type: "MediaSection",
      title: "Your brand your product",
      subtitle: "All the functionality you need to make your workspace feel like your own.",
      image: {
        src: Images.Product.customBranding_customize,
        alt: "Your brand your product",
      },
      layoutDirection: "image-left",
      body: {
        style: "iconList",
        items: [
          {
            title: "Custom domains",
            body: "Keep brand recognition top of mind with a custom domain. Easier to remember, easier to share.",
          },
          {
            title: "Custom fonts",
            body: "Make Lingo your own by using your brand's font.",
          },
          {
            title: "Themes",
            body: "Style your workspace by choosing between different design themes.",
          },
        ],
      },
    },
    {
      type: "CardSection",
      title: "More features",
      items: [featureCards.customDomains, featureCards.brandPortal, featureCards.publicSharing],
      footerButton: {
        text: "View all features",
        buttonStyle: "outline",
        link: "/product/features",
      },
    },
    {
      type: "CtaSection",
    },
  ],
};
export default content;
