/**
 * Collapsible block of content to be used in an accordion style layout.
 */

import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { Text, Icon, Box, Flex, BoxProps, FlexProps, Button } from "@thenounproject/lingo-core";

const Wrapper = styled(Box).attrs<{ first?: boolean }>(() => {
  return {
    position: "relative",
    px: "m",
    pt: "m",
    pb: "l",
  };
})<{ first: boolean }>`
  transition: height 0.3s ease;
`;

const TitleWrapper = styled(Flex).attrs<{ disableControls?: boolean; styleOverrides?: BoxProps }>(
  props => ({
    as: props.disableControls ? "div" : "button",
    justifyContent: "space-between",
    alignItems: "center",
    background: "transparent",
    border: "none",
    width: "100%",
    p: 0,
    mb: "s",
    ...props.styleOverrides,
  })
)<{ disableControls: boolean; styleOverrides?: BoxProps }>`
  cursor: ${props => (props.disableControls ? "default" : "pointer")};
`;

type ContentWrapperProps = {
  expanded: boolean;
  disableControls: boolean;
  animate: boolean;
  styleOverrides?: BoxProps;
};

const ContentWrapper = styled(Box).attrs<ContentWrapperProps>(props => ({
  maxHeight: props.expanded ? "100%" : 0,

  // This is a litle hack to allow the name/note inputs editing backgrounds
  // to extend beyond the standard accordionSection width.
  overflow: props.disableControls ? "visible" : "scroll",
  ...props.styleOverrides,
}))<ContentWrapperProps>`
  opacity: ${props => (props.expanded ? 1 : 0)};
  ${props =>
    props.animate
      ? {
          transitionDuration: "0.4s",
          transitionTimingFunction: "cubic-bezier(0.51, 0.01, 0.29, 1.2)",
          transitionProperty: "max-height, padding-top, opacity",
        }
      : null};
`;

const ToggleIcon = styled(Icon).attrs({
  size: 16,
  viewBox: "0 0 16 16",
  iconId: "triangle-collapsed",
  fill: "grayDarkest",
})``;

const IconWrapper = styled(Box).attrs<{ expanded: boolean }>(props => ({
  transform: props.expanded ? "rotate(90deg)" : "rotate(-90deg)",
}))<{ expanded: boolean }>`
  transition: transform 0.4s ease;
`;

type Props = {
  contentId?: string;
  title?: string;
  expanded?: boolean;
  setExpanded?: (expanded: boolean) => void;
  disableControls?: boolean;
  children?: React.ReactNode;
  first?: boolean;
  styleOverrides?: BoxProps;
  buttonText?: string;
  onButtonClick?: () => void;
  titleWrapperStyleOverrides?: BoxProps & FlexProps;
  contentWrapperOverrides?: BoxProps;
};

export default function AccordionSection({
  title,
  expanded = true,
  setExpanded,
  disableControls = true,
  children,
  contentId,
  first = false,
  styleOverrides,
  buttonText,
  onButtonClick,
  titleWrapperStyleOverrides,
  contentWrapperOverrides,
}: Props) {
  const [animate, setAnimate] = useState(false);
  function renderTitleComponents() {
    return (
      <>
        <Text font="ui.smallBold" color="grayDarkest">
          {title}
        </Text>
        {buttonText && onButtonClick && (
          <Button
            text={buttonText}
            fontStyle="ui.small"
            buttonStyle="tertiary"
            onClick={onButtonClick}
          />
        )}
      </>
    );
  }

  // Wrap the state change handler so we can set animate to true
  const toggleState = useCallback(() => {
    setAnimate(true);
    setExpanded(!expanded);
  }, [expanded, setExpanded]);

  // When the contentId changes (i.e. the represented selection), set aniamte to false
  // This stops animations when we change the selection, then it is set back to true when
  // the expansion toggles
  useEffect(() => {
    setAnimate(false);
  }, [contentId]);

  return (
    <Wrapper first={first} {...styleOverrides}>
      {title && (
        <TitleWrapper
          disableControls={disableControls}
          aria-pressed={disableControls ? null : expanded}
          aria-label={disableControls ? null : `Toggle "${title}" section`}
          onClick={!disableControls ? toggleState : null}
          styleOverrides={titleWrapperStyleOverrides}>
          {renderTitleComponents()}
          {!disableControls && (
            <IconWrapper expanded={expanded}>
              <ToggleIcon />
            </IconWrapper>
          )}
        </TitleWrapper>
      )}
      <ContentWrapper
        disableControls={disableControls}
        expanded={expanded}
        animate={!disableControls && animate}
        styleOverrides={contentWrapperOverrides}>
        {children}
      </ContentWrapper>
    </Wrapper>
  );
}
