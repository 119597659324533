import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  itemIds: string[];
  fromSectionId: string;
  toSectionId: string;
};

const [useMoveItems, moveItems] = createAsyncAction(
  "items/move",
  async ({ toSectionId, itemIds }: Args) => {
    return await API.call<{ items: string[] }>({
      endpoint: "items/move",
      method: "PUT",
      data: {
        uuids: itemIds,
        section_uuid: toSectionId,
      },
      entity: API.Entity.item,
    });
  }
);

export default useMoveItems;
export { moveItems };
