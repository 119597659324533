import { TinyColor } from "@ctrl/tinycolor";

type Return = {
  primaryColor: string;
  primaryColorDark: string;
  primaryColorTint: string;
  lightness: number;
};
export const parseThemeColors = (color: any): null | Return => {
  const hsl = new TinyColor(color).toHsl();
  const lightness = hsl.l;
  const hex = new TinyColor(color).toHexString();
  const primaryColor = hex;
  const primaryColorDark =
    lightness <= 0.08
      ? new TinyColor(hsl).lighten(7).toHexString()
      : new TinyColor(hsl).darken(7).toHexString();
  const primaryColorTint = lightness > 0.6 ? "black" : "white";
  return { primaryColor, primaryColorDark, primaryColorTint, lightness };
};
