import React from "react";
import { Flex } from "@thenounproject/lingo-core";

import { ThumbnailProps } from "../GalleryAssetThumbnail/types";
import FontSample from "../../FontSample";
import SquareWrapper from "./SquareWrapper";

function FontAssetSquare({ asset }: ThumbnailProps) {
  return (
    <SquareWrapper>
      <Flex height="100%" width="100%" justifyContent="center" alignItems="center">
        <FontSample asset={asset} fontSize={"48px"} content={"Aa"} isThumbnail />
      </Flex>
    </SquareWrapper>
  );
}

export default React.memo(FontAssetSquare);
