import { API, APIResultList } from "@thenounproject/lingo-core";
import { createAsyncAction } from "@actions/actionCreators";

type Args = {
  // portalId: string;
  updates: { data: { content?: string; background_color?: string } };
  portalItems: string[];
};

const [useUpdatePortalItems, updatePortalItems] = createAsyncAction(
  "portalItems/batchUpdate",
  async (args: Args) => {
    const data = { portal_items: args.portalItems.map(id => ({ uuid: id, ...args.updates })) };
    return await API.call<APIResultList<"portalItems", string>>({
      endpoint: `sync/portal_items`,
      method: "POST",
      entity: API.Entity.portalItem,
      data,
    });
  }
);

export default useUpdatePortalItems;
export { updatePortalItems };
