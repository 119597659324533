import { RootState } from "@redux/store";
import { SpacePermission } from "@thenounproject/lingo-core";
import { SearchContext } from "./useSetSearchContext";

export default function getSearchContext(state: RootState) {
  const stateContext = state.entities.search.objects.context;

  function contextPortal() {
    const { portalId } = state.navPoint;
    if (!portalId) return null;
    if (stateContext.context === SearchContext.portal) return portalId;
    const space = state.entities.spaces.objects[state.navPoint.spaceId];
    if (!space?.access?.permissions.includes(SpacePermission.searchAssets)) {
      return portalId;
    }
    return null;
  }

  function contextKit() {
    const { kitId } = state.navPoint;
    if (!kitId) return null;
    if (stateContext.context === SearchContext.kit) return kitId;
    const space = state.entities.spaces.objects[state.navPoint.spaceId];
    if (!space?.access?.permissions.includes(SpacePermission.searchAssets)) {
      return kitId;
    }
    return null;
  }

  const portalId = contextPortal();
  if (portalId) {
    return {
      context: "portal",
      portal_uuid: portalId,
    };
  }

  const kitId = contextKit();
  if (kitId) {
    return {
      context: "kit",
      kit_uuid: kitId,
    };
  }

  return {
    context: "global",
    portal_uuid: undefined,
  };
}
