import { ChargePreview } from "@redux/actions/billing/useChargePreview";

type Result = {
  credit: number;
  balance: number;
  creditApplied: number;
  willHaveCredit: boolean;
};
export function analyzeChargePreview(chargePreview: ChargePreview): Result {
  const credit = Math.abs(Math.min(chargePreview.existingCredit, 0)),
    balance = Math.max(chargePreview.existingCredit, 0),
    creditApplied =
      chargePreview.newCharges > 0 && credit > 0 ? Math.min(chargePreview.newCharges, credit) : 0,
    willHaveCredit = chargePreview.total < 0;
  return {
    credit,
    balance,
    creditApplied,
    willHaveCredit,
  };
}
