import { API, Section } from "@thenounproject/lingo-core";

import { createQueryAction } from "../actionCreators";
import { getVersionedIdentifier } from "@redux/utils/identifiers";

type Args = {
  spaceId: number | string;
  sectionId: string;
  version?: number;
};

const [useSection, fetchSection] = createQueryAction<Args, Section, string>(
  {
    entity: "sections",
    action: "fetch",
    condition: ({ args: { spaceId } }) => Boolean(spaceId),
    prefetchData: ({ args }, { getState }) => {
      if (!Number.isInteger(args.version)) {
        // maybe we look at the kit/kit access to determine the fallback version?
        return null;
      }
      const state = getState();
      const sectionId = state.entities.shortIds.sections?.[args.sectionId] ?? args.sectionId;
      const versionedId = getVersionedIdentifier(sectionId, args.version);
      const section = state.entities.sections.objects[versionedId];

      // If we don't have counts, we don't have the full section
      // which means this is just a partial section from an outline.
      if (!section?.counts) return null;

      return {
        result: versionedId,
        entities: {
          sections: {
            [versionedId]: section,
          },
        },
      };
    },
  },
  async ({ args }) => {
    return await API.call<string>({
      endpoint: `spaces/${args.spaceId}/sections/${args.sectionId}`,
      method: "GET",
      entity: API.Entity.section,
      query: {
        v: args.version,
      },
    });
  }
);

export default useSection;
export { fetchSection };
