/**
 * Reducer for managing versions of a kit.
 */

import { KitVersion } from "@thenounproject/lingo-core";
import _merge from "lodash/merge";

import { createKitVersion } from "@redux/actions/kitVersions/useCreateKitVersion";
import { fetchKitVersions } from "@redux/actions/kitVersions/useKitVersions";
import { deleteKitVersion } from "@redux/actions/kitVersions/useDeleteKitVersion";
import { duplicateKit } from "@redux/actions/kits/useDuplicateKit";
import { createSection } from "@redux/actions/sections/useCreateSection";
import { deleteSection } from "@redux/actions/sections/useDeleteSection";
import { moveSection } from "@redux/actions/sections/useMoveSection";
import { batchUpdateItemStatus } from "@redux/actions/items/useBatchUpdateItemStatus";
import { batchUpdateSections } from "@redux/actions/sections/useBatchUpdateSections";
import { clearRecentlyDeleted } from "@redux/actions/items/useClearRecentlyDeleted";
import { moveItems } from "@redux/actions/items/useMoveItems";
import createEntityReducer from "../helpers/createEntityReducer";
import { duplicateSection } from "@redux/actions/sections/useDuplicateSection";

export default createEntityReducer<KitVersion>(
  "kitVersions",
  queryBuilder => {
    queryBuilder
      .addCase(deleteKitVersion.fulfilled, (state, action) => {
        const deletedVersion = action.payload.entities.kitVersions[action.payload.result];
        const { spaceId, kitId } = deletedVersion;

        fetchKitVersions.getQueryData(state, { kitId, spaceId }).forEach(q => {
          q.data = q.data.filter(id => id !== action.payload.result);
        });
      })
      .addCase(createKitVersion.fulfilled, (state, action) => {
        const newVersion = action.payload.entities.kitVersions[action.payload.result];
        const { spaceId, kitId } = newVersion;

        // Merge the version into the kitVersions query
        fetchKitVersions.getQueryData(state, { kitId, spaceId }).forEach(q => {
          q.data.push(action.payload.result);
        });
      });
  },
  objectBuilder => {
    objectBuilder
      .addCase(createSection.fulfilled, (state, action) => {
        const {
            meta: {
              arg: { kitId },
            },
            payload: { result },
          } = action,
          versionedKitId = `${kitId}-0`;
        state[versionedKitId].sections.push(result);
      })
      .addCase(deleteSection.fulfilled, (state, action) => {
        const { result: sectionId, entities } = action.payload,
          section = entities.sections[sectionId],
          { kitId } = section,
          versionedKit = `${kitId}-0`;

        const sections = state[versionedKit].sections;
        sections.splice(sections.indexOf(sectionId), 1);
      })
      .addCase(moveSection.fulfilled, (state, action) => {
        const { sectionId, fromKitId } = action.meta.arg;

        const versionedKitId = `${fromKitId}-0`,
          versionedSectionUuid = `${sectionId}-0`;

        const sections = state[versionedKitId].sections;
        sections.splice(sections.indexOf(versionedSectionUuid), 1);
      })
      .addCase(batchUpdateSections.fulfilled, (state, action) => {
        const { result, entities } = action.payload;
        const sections = result.sections.map(i => entities.sections[i.result]);
        sections.sort((a, b) => a.displayOrder - b.displayOrder);
        const versionedKitId = `${sections[0].kitId}-0`;
        state[versionedKitId].sections = sections.map(s => `${s.id}-0`);
      })
      .addCase(duplicateSection.fulfilled, (state, action) => {
        const { result, entities } = action.payload;
        const newSection = entities.sections[result];
        const kitVersion = state[`${newSection.kitId}-${newSection.version}`];
        kitVersion?.sections.push(result);
      })
      .addCase(batchUpdateItemStatus.fulfilled, (state, action) => {
        // We need to update the trash and recovery counts
        const { result, entities } = action.payload;
        const successfulItems = result.items.filter(i => i.success).map(i => i.result);
        successfulItems.forEach(id => {
          const { kitId, version, status, sectionId, type, itemId: galleryId } = entities.items[id],
            versionedId = `${kitId}-${version}`;
          // Return state if the item is a gallery or a gallery item
          if (type === "gallery" || !!galleryId) return state;

          const counts = state[versionedId].counts;
          counts.trash += status === "trashed" ? 1 : -1;
          counts.recovery += !sectionId ? 1 : 0;
        });
      })
      .addCase(moveItems.fulfilled, (state, action) => {
        const { fromSectionId } = action.meta.arg;
        if (!fromSectionId) {
          const { result, entities } = action.payload;
          const { kitId } = entities.items[result.items[0]];
          const counts = state[`${kitId}-0`].counts;
          counts.recovery -= 1;
        }
      })
      .addCase(clearRecentlyDeleted.fulfilled, (state, action) => {
        return _merge(state, { [`${action.meta.arg.kitId}-0`]: { counts: { trash: 0 } } });
      })
      .addCase(duplicateKit.fulfilled, state => {
        // Don't insert the draft for the duplicate into state
        return state;
      })
      .addDefaultCase((state, action: any) => {
        const kitVersions = (action.payload?.entities ?? action?.response?.entities)?.kitVersions;
        if (kitVersions) _merge(state, kitVersions);
      });
  }
);
