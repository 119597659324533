import { API, APIResultList } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  spaceId: number;
  keywords: string[];
};

type Result = {
  keyword: string;
  assetsUpdated: number;
};

const [useDeleteTags, deleteTags] = createAsyncAction(
  "tags/delete",
  async ({ spaceId, keywords }: Args) => {
    return await API.call<APIResultList<"keywords", Result>>({
      endpoint: `spaces/${spaceId}/keywords`,
      method: "DELETE",
      // Reusing this to get things camelized for now
      entity: API.Entity.analytics,
      data: {
        keywords: keywords.map(keyword => ({ keyword })),
      },
    });
  }
);

export default useDeleteTags;
export { deleteTags };
