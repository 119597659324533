import { Flex, Text } from "@thenounproject/lingo-core";
import styled from "styled-components";
import { KIT_NAV_WIDTH } from "../../constants/layouts";

export const SubNavContainer = styled(Flex).attrs({
  height: "100%",
  background: "white",
  borderRight: "1px solid border",
  flex: `0 0 ${KIT_NAV_WIDTH}px`,
  pt: "l",
  width: `${KIT_NAV_WIDTH}px`,
})`
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow: visible;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const SubNavWrapper = styled(Flex).attrs({
  flexGrow: 1,
  flexDirection: "column",
  overflow: "hidden",
})``;

export const SubNavTitle = styled(Text).attrs({
  as: "h1",
  font: "ui.title",
  px: "l",
  pb: "s",
})``;
