import { API, DownloadRequestStatus } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  id: number;
  status: DownloadRequestStatus;
  spaceId: number;
  expiresAt?: number | "now";
  message?: string;
  sendEmail?: boolean;
};

const [useUpdateDownloadRequest, updateDownloadRequest] = createAsyncAction(
  "downloadRequests/update",
  async ({ spaceId, id, status, expiresAt, message, sendEmail }: Args) => {
    return await API.call<string>({
      endpoint: `spaces/${spaceId}/download-requests/${id}`,
      method: "PUT",
      entity: API.Entity.downloadRequest,
      data: {
        status,
        message,
        expires_at: expiresAt,
        send_email: sendEmail,
      },
    });
  }
);

export default useUpdateDownloadRequest;
export { updateDownloadRequest };
