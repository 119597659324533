import { PageContent } from "../../pages/templates/PageLayout";

const content: PageContent = {
  style: "lightGradient",
  sections: [
    {
      type: "FormSection",
      title: "Flexibly priced for all types of brands",
      subtitle:
        "Request a quote and see how Lingo's flexible pricing model can work for fortune 500 companies as well as small burgeoning businesses.",
      badge: "Request a quote",
      form: "quote",
      position: { mb: "none", pb: "200" },
    },
    { type: "CustomerLogosSection", position: { mt: "-xxl" } },
  ],
};

export default content;
