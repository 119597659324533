import React from "react";
import styled, { useTheme } from "styled-components";
import {
  Box,
  Space,
  SpaceTheme,
  ActivityIndicator,
  SpaceThemeNames,
  Kit,
} from "@thenounproject/lingo-core";

import SpaceHeader from "../../space-kits/components/SpaceHeader";
import AllKitsView from "../../space-kits/components/AllKitsView";
import SpaceKitCollectionNav from "../../space-kits/components/SpaceKitCollectionNav";

import { sharedThemeVals, SpaceKitsViews } from "../../space-kits/pages/SpaceKits";

import useKitCollections from "@actions/kitCollections/useKitCollections";
import useKits from "@actions/kits/useKits";
import KitList from "@features/space-kits/components/KitList";
import { themeVals as kitThemeStylingVals } from "../../space-kits/components/AllKitsView";
import { random } from "lodash";

const Wrapper = styled(Box).attrs({ width: "100%", height: "100%" })``;

type Props = {
  space: Space;
  newTheme: SpaceTheme;
};

const mockSpace = {
  id: 1,
  access: {
    permissions: ["search_assets"],
  },
} as unknown as Space;

function createMockKit() {
  return {
    kitId: random(1, 1000000).toString(),
    spaceId: 99999999,
    url: "/",
    name: "Kit Name",
    description: "A description of your kit.",
    status: "active",
    access: {
      permissions: [],
      id: 1,
      kitId: null,
      dateAdded: "2021-09-01T00:00:00.000",
      dateUpdated: "2021-09-01T00:00:00.000",
      isFavorite: null,
      isPublic: null,
      role: null,
      spaceId: null,
      status: null,
      upgradePermissions: {},
    },
    images: { cover: "/" },
    counts: { members: 10 },
    kitCollectionId: "1",
    createdAt: "2021-09-01T00:00:00.000",
    dateAdded: "2021-09-01T00:00:00.000",
    dateUpdated: "2021-09-01T00:00:00.000",
    privacy: "public",
    indexable: false,
    versions: [],
    portalAccess: true,
    recommendedVersion: 0,
    shortId: null,
    downloadsRestricted: false,
    downloadsRestrictedApproveMode: "approve_all_automatically",
  } as unknown as Kit;
}

const ThemeEditorHomeView: React.FC<Props> = ({ newTheme, space }) => {
  const theme = useTheme();
  const mockKits = [
    createMockKit(),
    createMockKit(),
    createMockKit(),
    newTheme.themeName === "scher" && createMockKit(),
  ];
  const hasPortalsFeature = space.features.includes("portals");
  const { data: kitsData, isLoading: kitsLoading } = useKits({ spaceId: space.id });
  const { kits = [] } = kitsData || {};

  const { data: kitCollections, isLoading: kitCollectionsLoading } = useKitCollections({
    spaceId: space.id,
  });

  const isLoading = kitsLoading || kitCollectionsLoading;

  if (isLoading)
    return (
      <Box width="100%" height="100%">
        <ActivityIndicator center size="large" />
      </Box>
    );

  return (
    <Wrapper>
      {!hasPortalsFeature ? (
        <SpaceHeader name={space.name} space={space} />
      ) : (
        <SpaceHeader name="Portal Name" space={mockSpace} />
      )}
      {/* Box prevents sticky here */}
      {!hasPortalsFeature && (
        <Box>
          <SpaceKitCollectionNav
            space={space}
            spaceKitsViewType={SpaceKitsViews.ALL_KITS}
            kitCollectionId={undefined}
            kitCollections={kitCollections}
          />
        </Box>
      )}
      <Box
        width="100%"
        {...sharedThemeVals[newTheme?.themeName || SpaceThemeNames.classic].contentWrapper}>
        <Box
          mt="xl"
          variations={{ "mq.s": { mt: "l" } }}
          mx="auto"
          width="100%"
          {...sharedThemeVals[theme.themeName].contentContainer}>
          {hasPortalsFeature ? (
            <KitList kits={mockKits} {...kitThemeStylingVals[newTheme.themeName].normalKitList} />
          ) : (
            <AllKitsView kits={kits} kitCollections={kitCollections} />
          )}
        </Box>
      </Box>
    </Wrapper>
  );
};

export default ThemeEditorHomeView;
