import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

export type Args = {
  headingId: string;
  fromSectionId: string;
  toSectionId: string;
};

const [useMoveHeading, moveHeading] = createAsyncAction(
  "items/moveHeading",
  async ({ headingId, toSectionId }: Args) => {
    return await API.call<{ items: string[] }>({
      endpoint: `headings/${headingId}/move`,
      method: "PUT",
      entity: API.Entity.item,
      data: {
        section_uuid: toSectionId,
      },
    });
  }
);

export default useMoveHeading;
export { moveHeading };
