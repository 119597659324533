import { useAppSelectorV1 } from "@redux/hooks";
import { NavPoint } from "@redux/legacy-actions/navPoints";
import { DraggingReducerState } from "@redux/reducers/dragging";

import type { RootState } from "@redux/store";
import { parseVersionedIdentifier } from "@redux/utils/identifiers";
import { createSelector } from "reselect";

/**
 * Returns user from state
 */
export const getUser = (state: RootState) => {
  return state.user || null;
};
export const useGetUser = () => useAppSelectorV1(getUser);

/**
 * Returns spaceIds from the user data in state
 */
// export const getUserSpaceIds = (state: RootState) => {
//   return state.user?.spaces || [];
// };

/**
 * Returns custom fields from state entities
 */
export const getAssetViews = (state: RootState) => {
  return state.entities.views.objects;
};

/**
 * Returns custom fields from state entities
 */
export const getCustomFields = (state: RootState) => {
  return state.entities.fields.objects;
};

/**
 * Returns items from state
 */
export const getItems = (state: RootState) => state.entities.items.objects;
export const useGetItems = () => useAppSelectorV1(getItems);
/**
 * Returns spaces from entities in state
 */
export const getSpaces = (state: RootState) => state.entities.spaces.objects;
export const useGetSpaces = () => useAppSelectorV1(getSpaces);

/**
 * Returns modals from state
 */
export const getModals = (state: RootState) => state.modals;
export const useGetModals = () => useAppSelectorV1(getModals);

/**
 * Returns active uploads
 */
export const getUploads = (state: RootState) => state.uploads;
export const useGetUploads = () => useAppSelectorV1(getUploads);

/**
 * Returns kits from entities in state
 */
export const getSections = (state: RootState) => state.entities.sections.objects;
export const useGetSections = () => useAppSelectorV1(getSections);
/**
 *
 * Returns kits from entities in state
 */
export const getKitCollections = (state: RootState) => state.entities.kitCollections.objects;

/**
 * Returns kits collection items from entities in state
 */
export const getKitCollectionItems = (state: RootState) =>
  state.entities.kitCollectionItems.objects;

// Portals
export const getPortals = (state: RootState) => state.entities.portals.objects;
export const useGetPortals = () => useAppSelectorV1(getPortals);

/**
 * Returns kits from entities in state
 */
export const getKits = (state: RootState) => state.entities.kits.objects;
export const useGetKits = () => useAppSelectorV1(getKits);

/**
 * Returns kit versions from entities in state
 */
export const getKitVersions = (state: RootState) => state.entities.kitVersions.objects;

export const getKitVersionId = (
  state: RootState,
  props?: { kitId?: string; version?: number }
): string => {
  if (props?.kitId) {
    return `${props?.kitId}-${props?.version || 0}`;
  }
  return `${state.navPoint?.kitId}-${state.navPoint?.version || 0}`;
};
/**
 * Returns kit members from entities in state
 */
export const getKitMembers = (state: RootState) => state.entities.kitMembers.objects;

/**
 * Returns current version from either props or nav point
 */
export const getVersion = (state: RootState, props?: { version?: number }): number =>
  props?.version || state.navPoint?.version;

/**
 * Returns the space id either from props or from the current nav point
 */
export const getKitCollectionId = (
  state: RootState,
  props?: { kitCollectionId?: string }
): string => props?.kitCollectionId || state.navPoint?.kitCollectionId;

/**
 * Returns the space id either from props or from the current nav point
 */
export const getSpaceId = (
  state: RootState,
  props?: { spaceId?: number | string }
): string | number => props?.spaceId || state.navPoint?.spaceId;

/**
 * Returns the section id either from props or from the current nav point
 */
export const getSectionId = (
  state: RootState,
  props?: { sectionId?: string; version?: number }
): string => {
  if (props?.sectionId) {
    const identifier = parseVersionedIdentifier(props.sectionId, props.version);
    return `${identifier.id}-${identifier.version || 0}`;
  }
  return `${state.navPoint?.sectionId}-${state.navPoint?.version || 0}`;
};

/**
 * Returns the kit id either from props or from the current nav point
 */
export const getKitId = (state: RootState, props?: { kitId?: string }): string =>
  props?.kitId || state.navPoint?.kitId;

/**
 * Returns current dragging state
 */
export const getDraggingState = (state: RootState): DraggingReducerState => state.dragging;
export const useGetDraggingState = (): DraggingReducerState => useAppSelectorV1(getDraggingState);

/**
 * Returns cached assets
 */
export const getAssets = (state: RootState) => state.entities.assets.objects;

export const useGetAssets = () => useAppSelectorV1(getAssets);
/**
 * Returns entire state obj
 */
export const getState = (state: RootState): RootState => state;
export const useGetState = () => useAppSelectorV1(getState);

/**
 * Returns current nav point from state
 */
export const getNavPoint = (state: RootState) => state.navPoint;
export const getNavPointInsightsPage = (state: RootState) => state.navPoint?.insightsPage;
export const useGetNavPointInsightsPage = () => useAppSelectorV1(getNavPointInsightsPage);
export const useGetNavPoint = () => useAppSelectorV1(getNavPoint);

export const selectPopulatedNavPoint = createSelector(
  [
    getSpaces,
    getKitCollections,
    getPortals,
    getKits,
    getKitVersions,
    getSections,
    getItems,
    getAssets,
    getNavPoint,
  ],
  (
    spaces,
    kitCollections,
    portals,
    kits,
    kitVersions,
    sections,
    items,
    assets,
    navPoint
  ): NavPoint => {
    if (!navPoint) return null;

    const space = spaces[navPoint.spaceId],
      portal = portals[navPoint.portalId],
      kit = kits[navPoint.kitId],
      kitVersion = kitVersions[[navPoint.kitId, navPoint.version].join("-")],
      section = sections[[navPoint.sectionId, navPoint.version].join("-")],
      kitCollection = kitCollections[navPoint.kitCollectionId],
      asset = assets[navPoint.assetId];

    let item = items[[navPoint.itemId, navPoint.version].join("-")];
    if (item?.assetId) {
      item = { ...item, asset: assets[item.assetId] };
    }

    let gallery = items[[navPoint.galleryId, navPoint.version].join("-")];
    if (gallery?.assetId) {
      gallery = { ...gallery, asset: assets[gallery.assetId] };
    }

    return {
      ...navPoint,
      space,
      kitCollection,
      portal,
      kit,
      kitVersion,
      item,
      asset,
      section,
      gallery,
    };
  }
);
