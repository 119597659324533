import { useAppDispatchV1 } from "@redux/hooks";
import { useCallback } from "react";
import { createAction, nanoid } from "@reduxjs/toolkit";

type Link = { text: string; url: string };
type Message = string;
export type Level = "info" | "error";

export type NotificationArgs = {
  message: Message;
  level?: Level;
  link?: Link;
};

export type Notification = NotificationArgs & { id: string };

export const showNotification = createAction(
  "notifications/show",
  (notification: NotificationArgs) => {
    return {
      payload: {
        id: nanoid(),
        ...notification,
      },
    };
  }
);

export const dismissNotification = createAction("notifications/dismiss", (id: string) => {
  return { payload: { id } };
});

export default function useNotifications() {
  const dispatch = useAppDispatchV1(),
    _showNotifcation = useCallback(
      (...args: Parameters<typeof showNotification>) => {
        dispatch(showNotification(...args));
      },
      [dispatch]
    ),
    _dismissNotification = useCallback(
      (...args: Parameters<typeof dismissNotification>) => {
        dispatch(dismissNotification(...args));
      },
      [dispatch]
    );

  return { showNotification: _showNotifcation, dismissNotification: _dismissNotification };
}
