import { API, Asset, Kit, PortalPreview, Section, SpacePreview } from "@thenounproject/lingo-core";

import { createQueryAction } from "@actions/actionCreators";

type Args = {
  hostname: string;
  spaceId?: number;
  kitId?: string;
  sectionId?: string;
  itemId?: string;
  assetId?: string;
  portalId?: string;
  version?: number;
  assetToken?: string;
};

type Result = {
  spaceId: number;
  portalId?: string;
  kitId: string;
  portal?: PortalPreview;
  space: SpacePreview;
  kit?: Kit;
  section?: Section;
  asset?: Asset;
  version: number;
};
type NormalizedResult = {
  spaceId: number;
  portalId?: string;
  kitId: string;
  portal?: string;
  space: number;
  kit?: string;
  section?: string;
  asset?: string;
  version: number;
};

const [useIdentifyContextPreview, identifyContextPreview] = createQueryAction<
  Args,
  Result,
  NormalizedResult
>(
  {
    entity: "spaces",
    action: "identifyDomainPreview",
    denormalize: false,
  },
  async ({ args }) => {
    const result = await API.call<NormalizedResult>({
      endpoint: `identify/preview`,
      method: "GET",
      entity: API.Entity.contextIdentificationPreview,
      query: {
        hostname: args.hostname,
        space_id: args.spaceId,
        portal_identifier: args.portalId,
        kit_identifier: args.kitId,
        section_identifier: args.sectionId,
        item_identifier: args.itemId,
        asset_identifier: args.assetId,
        v: args.version,
        asset_token: args.assetToken,
      },
    });

    return result;
  }
);

export default useIdentifyContextPreview;
export { identifyContextPreview };
