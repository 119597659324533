import { useCallback } from "react";
import { SpacePermission } from "@thenounproject/lingo-core";

import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import { useSelectSpace } from "@selectors/entities/spaces";

export enum AccessStatus {
  canAccess = "canAccess",
  insufficientPlan = "insufficientPlan",
  insufficientRole = "insufficientRole",
}

export default function useUpsell(permission: SpacePermission) {
  const space = useSelectSpace();
  const { showModal } = useShowModal();

  const canAccess = space.access.permissions.includes(permission);
  const requiredPlan = space.access.upgradePermissions[permission];

  let accessStatus = AccessStatus.insufficientRole;
  if (canAccess) {
    accessStatus = AccessStatus.canAccess;
  } else if (requiredPlan) {
    accessStatus = AccessStatus.insufficientPlan;
  }

  const openUpgradeModal = useCallback(() => {
    showModal(ModalTypes.CHANGE_SUBSCRIPTION_FLOW, {
      recommendedPlan: requiredPlan,
    });
  }, [requiredPlan, showModal]);

  return {
    openUpgradeModal,
    accessStatus,
  };
}
