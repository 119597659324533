import { createQueryAction } from "../actionCreators";
import { API, camelize } from "@thenounproject/lingo-core";

type Args = {
  spaceId: number;
};

export type FigmaIntegrationTeam = {
  dateAdded: string;
  dateUpdated: string;
  spaceId: number;
  status: "active" | "error";
  teamId: string;
  teamName: string;
  webhooks: {
    dateAdded: string;
    dateReceived: string;
    dateUpdated: string;
    eventType: "FILE_UPDATE";
    spaceId: number;
    status: "active" | "error";
    teamId: string;
    webhookId: string;
  }[];
};

export type FigmaIntegrationStatus = {
  auth?: {
    dateAdded: string;
    dateUpdated: string;
    figmaHandle: string;
    message: string;
    spaceId: number;
    status: "error" | "active";
  };
  teams?: FigmaIntegrationTeam[];
};

const [useFigmaIntegrationStatus, fetchFigmaIntegrationStatus] = createQueryAction<
  Args,
  FigmaIntegrationStatus,
  FigmaIntegrationStatus
>({ entity: "spaces", action: "fetchFigmaStatus", denormalize: false }, async ({ args }) => {
  const res = await API.call<FigmaIntegrationStatus>({
    endpoint: `spaces/${args.spaceId}/figma`,
    method: "GET",
  });
  return { result: camelize(res.result) };
});

export default useFigmaIntegrationStatus;
export { fetchFigmaIntegrationStatus };
