import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "@actions/actionCreators";

type Args = {
  assetId: string;
};

const [useRemoveAssetThumbnail, removeAssetThumbnail] = createAsyncAction(
  "assets/removeThumbnail",
  async ({ assetId }: Args) =>
    await API.call<string>({
      endpoint: `assets/${assetId}/preview`,
      method: "DELETE",
      entity: API.Entity.asset,
    })
);

export default useRemoveAssetThumbnail;
export { removeAssetThumbnail };
