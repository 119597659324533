/**
 * Styled component used to display metadata in a sidebar within AssetDetail.
 */

import styled from "styled-components";
import { styles } from "@thenounproject/lingo-core";

const { color } = styles;

const AssetMetaTable = styled.table`
  border: 1px solid ${color.grayLight};
  color: ${color.grayDarkest};
  width: 100%;
  text-align: left;
  table-layout: fixed;

  & td {
    text-align: right;
  }

  & th {
    width: 85px;
  }

  & th,
  td {
    padding: 8px;
    font-weight: normal;
  }

  & tr:nth-child(odd) {
    background: ${color.white};
  }

  & tr:nth-child(even) {
    background: ${color.grayLightest};
  }

  & .ellipsis-cell {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export default AssetMetaTable;
