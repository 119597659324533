import { endOfDay, parseISO, startOfDay, sub } from "date-fns";
import moment from "moment";

/**
 * Takes a date in string format and returns a localized Date object
 */
export const parseLocalDateFromString = (dateString: string | undefined) => {
  if (!dateString) return undefined;
  return parseISO(dateString);
};

/**
 * Returns the start and end times of the current day in UTC
 */
export const getTodayDateRange = () => {
  const d = new Date();
  const start = startOfDay(d);
  const end = endOfDay(d);
  return { gte: moment(start).utc().format(), lte: moment(end).utc().format() };
};

/**
 * Returns the start and end times of yesterday in UTC
 */
export const getYesterdayDateRange = () => {
  const d = new Date();
  const yesterday = sub(d, { days: 1 });
  const start = startOfDay(yesterday);
  const end = endOfDay(yesterday);
  return { gte: moment(start).utc().format(), lte: moment(end).utc().format() };
};

/**
 * Returns the start and end times of X days ago until the end of today in UTC
 */
export const getDaysAgoRange = (days: number) => {
  const d = new Date();
  const daysAgo = sub(d, { days });
  const start = startOfDay(daysAgo);
  const end = endOfDay(d);
  return { gte: moment(start).utc().format(), lte: moment(end).utc().format() };
};

/**
 * Gets start and end times of a date range in UTC
 */
export const getCustomDateRange = (start: Date, end: Date) => {
  return {
    gte: moment(start).utc().format(),
    lte: moment(end).utc().format(),
  };
};
