import { PortalItemType } from "@thenounproject/lingo-core";

export enum PortalInsertType {
  heading = "HEADING",
  note = "NOTE",
  supportingImage = "SUPPORTING_IMAGE",
  kit = "KIT",
}

export const portalInsertData = {
  [PortalInsertType.kit]: { type: PortalItemType.kit },
  [PortalInsertType.heading]: {
    type: PortalItemType.heading,
    data: { content: "Heading" },
  },
  [PortalInsertType.supportingImage]: { type: PortalItemType.supportingImage },
  [PortalInsertType.note]: {
    type: PortalItemType.note,
    data: { content: "Add a note..." },
  },
};
