import { API, Portal } from "@thenounproject/lingo-core";

import { createQueryAction } from "@actions/actionCreators";

type Args = {
  portalId: number | string;
};

const [usePortal, fetchPortal] = createQueryAction<Args, Portal, string>(
  {
    entity: "portals",
    action: "fetch",
    condition: ({ args: { portalId } }) => Boolean(portalId),
    prefetchData: ({ args }, { getState }) => {
      const state = getState();
      const portalId = state.entities.shortIds.portals?.[args.portalId] ?? args.portalId;
      const portal = state.entities.portals.objects[portalId];
      if (!portal) return null;
      return {
        result: portal.id,
        entities: {
          portals: {
            [portal.id]: portal,
          },
        },
      };
    },
  },
  async ({ args }) => {
    return await API.call<string>({
      endpoint: `portals/${args.portalId}`,
      method: "GET",
      entity: API.Entity.portal,
    });
  }
);

export default usePortal;
export { fetchPortal };
