import { createAction } from "@redux/actions/actionCreators";

export enum SearchSteps {
  INPUT = "INPUT",
  RESULTS = "RESULTS",
}

const [useToggleSearchStep, toggleSearchStep] =
  createAction<SearchSteps>("search/toggleSearchStep");

export default useToggleSearchStep;
export { toggleSearchStep };
