import _merge from "lodash/merge";
import { KitCollectionItem } from "@thenounproject/lingo-core";

import createEntityReducer from "../helpers/createEntityReducer";

import { removeKitFromCollection } from "@redux/actions/kitCollections/useRemoveKitFromCollection";

export default createEntityReducer<KitCollectionItem>(
  "kitCollectionItems",
  _queryBuilder => {
    // nothing to do her
  },
  objectBuilder => {
    objectBuilder
      .addCase(removeKitFromCollection.fulfilled, (state, action) => {
        delete state[[action.meta.arg.kitCollectionId, action.meta.arg.kitId].join("-")];
      })
      .addDefaultCase((state, action: any) => {
        const kitCollectionItems =
          action?.response?.entities?.kitCollectionItems ||
          action?.payload?.entities?.kitCollectionItems;
        if (kitCollectionItems) return _merge(state, kitCollectionItems);
        return state;
      });
  }
);
