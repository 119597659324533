/**
 * Orchestrates flow of components (aka wizard) to signup & create space subscription.
 *
 * This component relies on the flow reducer to manage transitions from one step to the next.
 * The main responsibilities of this component are to render the correct component (eg login,
 * create space, select plan) at the appropriate step in the flow, and to wrap up the flow
 * when the user is done (redirect & show notification).
 */

import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import QueryString from "query-string";
import { ActivityIndicator, Box, Space, useNavigation } from "@thenounproject/lingo-core";

import CreateSpace from "../spaces/CreateSpace";
import SelectSpace from "./SelectSpace";
import SelectPlanModal from "./SelectPlanModal";
import ChangeSubscriptionModal from "./ChangeSubscriptionModal";
import RequestEnterpriseForm from "./RequestEnterpriseForm";

import useNotifications from "@actions/useNotifications";
import useCurrentUser from "@queries/useCurrentUser";
import useSpaces from "@redux/actions/spaces/useSpaces";
import { AuthHeader } from "../auth/AuthHeader";
import Footer from "../Footer";

function PaidSignupFlow() {
  const [step, setStep] = useState<
      | "loading"
      | "create-space"
      | "select-space"
      | "select-plan"
      | "change-subscription"
      | "request-enterprise"
    >("loading"),
    [plan, setPlan] = useState(null),
    [space, setSpace] = useState<Space>(null);

  const navigate = useNavigation(),
    { showNotification } = useNotifications();

  const { user } = useCurrentUser();
  const { data: spaces, isLoading: loadingSpaces } = useSpaces(null, { skip: !user.id });
  const isLoading = user.isFetching || loadingSpaces;

  useEffect(() => {
    // Go to create space if we don't have any spaces to select
    if (step === "loading" && !isLoading) {
      setStep(spaces?.length ? "select-space" : "create-space");
    }
  }, [isLoading, spaces?.length, step]);

  if (!user.id && user.isFetched && !user.isFetching) {
    const target = window.location.toString(),
      qs = QueryString.stringify({ next: target });
    navigate.replace(`/login"}/?${qs}`);
    return null;
  }

  function finishFlow(message?: string) {
    navigate.push(space ? `/${space.id}/` : "/space");
    if (message) showNotification({ message });
  }

  function pageTitle() {
    switch (step) {
      case "select-space":
        return "Select a space";
      case "create-space":
        return "Create a space";
      case "select-plan":
        return "Select a plan";
      case "change-subscription":
        return "Upgrade space";
      case "request-enterprise":
        return "Request enterprise plan";
      default:
        return null;
    }
  }

  function cardWrapper(component, maxWidth = 650) {
    const style = {
      width: "100%",
      textAlign: "center",
    };

    const innerStyle = {
      maxWidth,
      m: "auto",
    };

    return (
      <Box>
        <AuthHeader />
        <Helmet title={pageTitle()} />
        <Box {...style} pt="xxl" pb="xl">
          <Box {...innerStyle}>{component}</Box>
        </Box>
        <Footer />
      </Box>
    );
  }

  if (isLoading) {
    return cardWrapper(
      <Box height="200">
        <ActivityIndicator center />
      </Box>
    );
  }

  switch (step) {
    case "loading":
      return cardWrapper(<ActivityIndicator center />);
    case "select-space":
      return cardWrapper(
        <SelectSpace
          onSpaceSelected={s => {
            setSpace(s);
            setStep("select-plan");
          }}
        />
      );
    case "create-space":
      return cardWrapper(
        <CreateSpace
          onSpaceCreated={s => {
            setSpace(s);
            setStep("select-plan");
          }}
        />
      );
    case "select-plan":
      // This is a quick fix for page refresh
      // so the render doesn't break before the fetches are done
      if (!space) return null;
      return cardWrapper(
        <SelectPlanModal
          space={space}
          inline={true}
          allowSkipping={true}
          onPlanSelected={p => {
            if (!p) finishFlow();
            if (p === "enterprise") setStep("request-enterprise");
            else {
              setPlan(p);
              setStep("change-subscription");
            }
          }}
        />,
        960
      );
    case "change-subscription":
      return cardWrapper(
        <ChangeSubscriptionModal
          data-testid="change-subscription"
          spaceId={space.id}
          plan={plan}
          onCompletion={() => finishFlow(`“${space.name}” is all set.`)}
          inline={true}
        />
      );
    case "request-enterprise":
      return cardWrapper(
        <RequestEnterpriseForm
          spaceId={space.id}
          onCompletion={() =>
            finishFlow(
              `“${space.name}” is all set! We will contact you shortly about the enterprise plan.`
            )
          }
          inline={true}
        />
      );
    default:
      return null;
  }
}

export default PaidSignupFlow;
