import { API, Invitation } from "@thenounproject/lingo-core";
import { createQueryAction } from "../actionCreators";
import { DEFAULT_PAGE_SIZE } from "../actionCreators/createQueryAction";

type Args = {
  spaceId: number;
  sort: string;
};

type NormalizedResult = {
  total: number;
  invitations: string[];
};

const [useSpaceInvitations, fetchSpaceInvitations] = createQueryAction<
  Args,
  { total: number; invitations: Invitation[] },
  NormalizedResult
>(
  {
    entity: "invitations",
    action: "query",
    pagingKey: "invitations",
    denormalize: {
      entity: null,
      invitations: "invitations",
    },
  },
  async ({ args, paging }) => {
    const res = await API.call<NormalizedResult>({
      endpoint: `spaces/${args.spaceId}/invitations`,
      method: "GET",
      entity: API.Entity.invitation,
      query: {
        sort: args.sort,
        limit: DEFAULT_PAGE_SIZE,
        offset: DEFAULT_PAGE_SIZE * (paging.page - 1),
      },
    });
    const { total, invitations } = res.result;
    return { result: { total, invitations }, entities: res.entities };
  }
);

export default useSpaceInvitations;
export { fetchSpaceInvitations };
