import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  assetId: string;
  data: {
    name?: string;
    notes?: string;
    keywords?: string;
    meta?: Record<string, unknown>;
    colors?: unknown;
    url?: string;
  };
};

const [useSaveAssetMetadata, saveAssetMetadata] = createAsyncAction(
  "assets/saveMetadata",
  async ({ assetId, data }: Args) =>
    await API.call<string>({
      endpoint: `assets/${assetId}`,
      method: "PUT",
      entity: API.Entity.asset,
      data,
    })
);

export default useSaveAssetMetadata;
export { saveAssetMetadata };
