/**
 * Brings up the color copy options in a popup by clicking an element (typically a button).
 *
 * This component uses the "render prop" approach. The button used to open the popup should be
 * rendered within a function passed in as props.children (aka render prop). The actual element that
 * the user clicks needs to use the props passed to the function.
 *
 * <ColorAssetCopyPopup inspectable={...} popupVPos="alignTop" popupHPos="floatRight">
 *   {buttonProps => {
 *     return <Button {...buttonProps} text="Copy" />
 *   }}
 * </ColorAssetCopyPopup>;
 */

import React, { Fragment, useCallback } from "react";
import styled from "styled-components";
import { Text, PopupMenu, AnyObject, useBoolean } from "@thenounproject/lingo-core";

import ColorValueInputGroup from "./ColorValueInputGroup";
import { Inspectable } from "@constants/Inspector";

const ExportMenu = styled(PopupMenu).attrs({
  source: "inspector-color-copy",
  p: "m",
  width: 287,
})``;

const ExportTitle = styled(Text).attrs({
  font: "ui.smallBold",
  mb: "s",
})``;

export type ColorCopyRenderProps = {
  onClick: (e: any) => void;
} & AnyObject;

export type Props = {
  inspectable: Inspectable;
  popupVPos: "alignTop" | "alignBottom";
  popupHPos: "floatLeft" | "floatRight";
  children: (renderProps: ColorCopyRenderProps) => React.ReactNode;
};

const ColorAssetCopyPopup = ({ inspectable, popupVPos, popupHPos, children }: Props) => {
  const [isMenuOpen, openMemu, closeMenu] = useBoolean(false);
  const toggleMenu = useCallback(
    e => {
      e.stopPropagation();
      if (isMenuOpen) {
        closeMenu();
      } else {
        openMemu();
      }
    },
    [closeMenu, isMenuOpen, openMemu]
  );

  const c = inspectable.asset.colors[0];

  return (
    <Fragment>
      {children({
        onClick: toggleMenu,
        "data-popup-source": isMenuOpen && "inspector-color-copy",
      })}

      {isMenuOpen && (
        <ExportMenu close={closeMenu} vPos={popupVPos} hPos={popupHPos} stayOpenOnClick>
          <ExportTitle>Copy as</ExportTitle>
          {["hex", "rgb", "hsb", "pms", "cmyk"].map(format => (
            <ColorValueInputGroup
              inspectable={inspectable}
              color={c}
              format={format}
              key={format}
            />
          ))}
        </ExportMenu>
      )}
    </Fragment>
  );
};

export default ColorAssetCopyPopup;
