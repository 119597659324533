import { useCallback } from "react";
import { useNavigation } from "@thenounproject/lingo-core";

const useConfigureMode = () => {
  const configureMode = location.hash === "#configure";
  const navigate = useNavigation();

  const toggleConfigureMode = useCallback(() => {
    const components = [window.location.pathname, window.location.search];
    if (!configureMode) components.push("#configure");
    navigate.push(components.join(""));
  }, [configureMode, navigate]);

  const enterConfigureMode = useCallback(() => {
    if (!configureMode) toggleConfigureMode();
  }, [configureMode, toggleConfigureMode]);

  return { configureMode, toggleConfigureMode, enterConfigureMode };
};

export default useConfigureMode;
