import { API, Item } from "@thenounproject/lingo-core";
import { createQueryAction } from "../actionCreators";

type Args = {
  sectionId: string | number;
  version: number;
};

type Result = { items: Item[]; total: number };
type NormalizedResult = { items: string[]; total: number };
const [useSectionItems, fetchSectionItems] = createQueryAction<Args, Result, NormalizedResult>(
  {
    entity: "items",
    action: "queryInSection",
    pagingKey: "items",
    denormalize: { entity: null, items: { entity: "items", asset: "assets" } },
    condition: ({ args }) => Boolean(args.sectionId),
  },
  async ({ args, paging }) => {
    const res = await API.call<string>({
      endpoint: `sections/${args.sectionId}`,
      method: "GET",
      entity: API.Entity.section,
      query: {
        limit: 200,
        v: args.version,
        page: paging.page,
      },
    });
    const section = res.entities.sections[res.result],
      items = res.entities.sections[res.result].items,
      total = section.counts.items;
    return { result: { items, total }, entities: res.entities };
  }
);

export default useSectionItems;
export { fetchSectionItems };
