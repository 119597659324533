import {
  AnyObject,
  API,
  AssetType,
  ContentType,
  ErrorCode,
  LingoError,
} from "@thenounproject/lingo-core";

import { v4 as genUuid } from "uuid";
import { fontFileExtensions } from "@constants/fontFileExtensions";
import formatBytes from "@helpers/bytesToSize";
import { getFileName, typeErrorMessage } from "@helpers/files";

export type UploadAction = {
  upload: Upload;
  requestData: {
    data: AnyObject;
    file?: File;
    endpoint: string;
    method: "POST" | "PUT";
    entity: keyof typeof API.Entity;
  };
};

export type Upload = {
  id: string;
  batchId?: string;
  insertPosition: InsertPosition;
  status: "pending" | "started" | "success" | "failed";
  name: string;
  type: string;
  size?: number;
  isAsset?: boolean;
  dateAdded?: Date;
  error?: LingoError;
  // asset?: Record<string, any>;
};

export type InsertPosition = {
  spaceId?: number;
  displayOrder?: string;
  kitId?: string;
  portalId?: string;
  sectionId?: string;
  nextItemId?: string;
  insertIndex?: number;
  itemId?: string;
};

/**
  Formats query item data based on insert position
 */
export const getItemData = (insertPosition: InsertPosition) => {
  const { displayOrder, kitId, sectionId, itemId } = insertPosition;
  const useItemSchema = !!itemId || !!kitId;
  const entity = useItemSchema ? API.Entity.item : API.Entity.asset;
  let item: AnyObject = null;

  if (kitId && !itemId) {
    item = {
      display_order: displayOrder,
      kit_uuid: kitId,
      section_uuid: sectionId,
    };
  } else if (itemId) {
    item = {
      item_uuid: itemId,
      kit_uuid: kitId,
    };
  }
  return { item, entity };
};

function getAssetData(insertPosition: InsertPosition): AnyObject {
  if (insertPosition.portalId) {
    return { portalId: insertPosition.portalId } as AnyObject;
  }
  return { spaceId: insertPosition.spaceId } as AnyObject;
}

export function uploadActionForFile(
  file: File,
  contentTypes: ContentType[],
  insertPosition: InsertPosition,
  batchId: string = null
): { upload: Upload; asset: AnyObject } {
  const { name, extension: fileExtension } = getFileName(file.name);

  const id = genUuid().toUpperCase();

  const asset = getAssetData(insertPosition);

  const upload: Upload = {
    id,
    batchId: batchId || genUuid(),
    insertPosition,
    status: "pending",
    name,
    type: fileExtension?.toUpperCase(),
    size: file.size,
  };

  if (fontFileExtensions.includes(fileExtension)) {
    asset.type = AssetType.textStyle;
    asset.meta = { font: { extension: fileExtension } };
    return { upload, asset };
  }

  const type = contentTypes.find(
    t => t.fileExtension?.toLowerCase() === fileExtension || t.aliases.includes(fileExtension)
  );

  if (!type) {
    upload.status = "failed";
    upload.error = {
      code: ErrorCode.invalidParams,
      message: typeErrorMessage(contentTypes),
    };
  } else if (type.maxSize && file.size > type.maxSize) {
    upload.status = "failed";
    upload.error = {
      code: ErrorCode.fileTooLarge,
      message: `The file must be smaller than ${formatBytes(type.maxSize)}.`,
    };
  } else if (type.assetType === AssetType.textStyle) {
    asset.type = AssetType.textStyle;
    asset.meta = { font: { extension: fileExtension } };
  } else {
    asset.type = type.assetType;
    asset.name = name;
  }
  return { upload, asset };
}

export const generateInsertPositionForUploads = (
  quantity: number,
  startingInsertPosition: InsertPosition
): InsertPosition => {
  /**
   * If its only one upload, return the default insertPosition
   */
  if (quantity === 1) return startingInsertPosition;
  // If it's not inserting to a kit, return the default insertPosition
  if (!startingInsertPosition.kitId || startingInsertPosition.itemId) return startingInsertPosition;

  /**
   * Default to appending batch uploads to the end of a section
   */
  let displayOrder = "append";
  /**
   * If the insert happens as a "before" insert on an existing item,
   * use that displayOrder for every item in a batched upload
   */
  if (startingInsertPosition.displayOrder.includes("before")) {
    displayOrder = startingInsertPosition.displayOrder;
  } else {
    /**
     * Otherwise, if there is a "nextItemId" upon which we can
     * base a "before" insert position reliably, do that
     */
    if (startingInsertPosition.nextItemId) {
      displayOrder = `before:${startingInsertPosition.nextItemId}`;
    }
  }

  return {
    ...startingInsertPosition,
    displayOrder,
    /**
     * Do not provide an insert index, parse one from the displayOrder in reducer
     */
    insertIndex: undefined,
  };
};
