import { API, Space } from "@thenounproject/lingo-core";

import { createQueryAction } from "@actions/actionCreators";

const [useSpaces, fetchSpaces] = createQueryAction<void, Space[], number[]>(
  {
    entity: "spaces",
    action: "query",
    condition: (_, api) => Boolean(api.getState().user?.id),
  },
  async () => {
    const res = await API.call<{ spaces: number[] }>({
      endpoint: `spaces/`,
      method: "GET",
      entity: API.Entity.space,
    });
    return { result: res.result.spaces, entities: res.entities };
  }
);

export default useSpaces;
export { fetchSpaces };
