import Cookie from "js-cookie";
import { setNavPoint } from "@redux/legacy-actions/navPoints";
import { AppStore } from "@redux/store/reduxStore";
import { Middleware } from "@reduxjs/toolkit";
import { selectSpace } from "@redux/selectors/entities/spaces";

export const cookies: Middleware = (store: AppStore) => next => action => {
  // Save the spaceID from the latest navpoint
  if (setNavPoint.match(action)) {
    const navPoint = action.payload;
    const space = selectSpace(store.getState(), navPoint);
    if (space?.access?.isPublic === false) {
      const env = _lingoConfig.environment,
        key = env === "production" ? "lastSpace" : `lastSpace.${env}`;
      Cookie.set(key, String(navPoint.spaceId), { expires: 365, domain: "lingoapp.com" });
    }
  }
  return next(action);
};
