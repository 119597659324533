import React, { useState } from "react";

import ThemeColorPicker from "./ThemeColorPicker";
import ThemeImagePicker from "./ThemeImagePicker";
import {
  SpaceThemeColorOptions,
  SpaceThemeImageOptions,
  SpaceThemeHeaderBackgroundTypes,
  themeBackgroundOptions,
} from "@features/theming/types";

import { SpaceTheme, Box, SpaceThemeNames, Select } from "@thenounproject/lingo-core";
import { useThemeDataContext } from "@contexts/ThemeDataProvider";
const headerImageSizeRecommendations = {
  [SpaceThemeNames.classic]: "2100px x 700px",
  [SpaceThemeNames.scher]: "1920px x 1080px",
  [SpaceThemeNames.wyman]: "2100px x 900px",
};

interface Props {
  newTheme: SpaceTheme;
  existingTheme: SpaceTheme;
  updateTheme: ReturnType<typeof useThemeDataContext>["updateEditingTheme"];
  updateFiles: ReturnType<typeof useThemeDataContext>["updateFiles"];
}

const ThemeHeaderBackgroundSelector: React.FC<Props> = ({
  existingTheme,
  newTheme,
  updateTheme,
  updateFiles,
}) => {
  const [headerBackgroundType, setHeaderBackgroundType] = useState<string>(
    existingTheme.headerBackgroundImage
      ? SpaceThemeHeaderBackgroundTypes.image
      : SpaceThemeHeaderBackgroundTypes.color
  );

  const handleChange = (val: string) => {
    setHeaderBackgroundType(val);
    if (val === SpaceThemeHeaderBackgroundTypes.color) {
      updateTheme({ [SpaceThemeHeaderBackgroundTypes.image]: null });
      updateFiles({ [SpaceThemeHeaderBackgroundTypes.image]: null });
    }
  };

  return (
    <Box>
      <Select
        options={themeBackgroundOptions}
        size="small"
        buttonStyle="dropdown"
        width="100%"
        value={headerBackgroundType}
        onChange={handleChange}
        mb="s"
      />
      {headerBackgroundType === SpaceThemeHeaderBackgroundTypes.color && (
        <ThemeColorPicker
          {...{
            existingTheme,
            newTheme,
            updateTheme,
            updateFiles,
            themeColorOption: SpaceThemeColorOptions.header,
          }}
        />
      )}
      {headerBackgroundType === SpaceThemeHeaderBackgroundTypes.image && (
        <ThemeImagePicker
          {...{
            newTheme,
            updateTheme,
            updateFiles,
            themeImageOption: SpaceThemeImageOptions.headerBackgroundImage,
            sizeRecommendation: headerImageSizeRecommendations[newTheme.themeName],
          }}
        />
      )}
    </Box>
  );
};

export default ThemeHeaderBackgroundSelector;
