import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import { type NavPoint, NavPointTypes } from "@redux/legacy-actions/navPoints";
import { UserState } from "@redux/reducers/user";

export default function useShowSuperNav(navPoint: NavPoint, user: UserState) {
  const location = useLocation();
  return useMemo(() => {
    if (
      (navPoint?.type === NavPointTypes.Portal || navPoint?.kit) &&
      location.hash === "#configure"
    )
      return false;
    if (navPoint?.type === NavPointTypes.UserSettings) return true;
    if (navPoint?.type === NavPointTypes.NewSpace) return true;
    return Boolean(user?.id);
  }, [location.hash, navPoint?.kit, navPoint?.type, user?.id]);
}
