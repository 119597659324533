import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

export type Args = {
  spaceId: number | string;
  email: string;
};

const [useRequestInvite, requestInvite] = createAsyncAction(
  "auth/requestInvite",
  async ({ spaceId, email }: Args) => {
    return await API.call<string>({
      endpoint: `spaces/${spaceId}/request-invite`,
      method: "POST",
      data: { email },
      entity: API.Entity.invitation,
    });
  }
);

export default useRequestInvite;
export { requestInvite };
