"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isArraySubset = void 0;
/**
 * Returns true if all elements of "target" exist in "arr"
 */
var isArraySubset = function (arr, target) {
    return target.every(function (v) { return arr.includes(v); });
};
exports.isArraySubset = isArraySubset;
