import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  id: string;
};

const [useDeletePortal, deletePortal] = createAsyncAction("portals/delete", ({ id }: Args) => {
  return API.call<string>({
    endpoint: `portals/${id}`,
    method: "DELETE",
    entity: API.Entity.portal,
    data: {
      status: "deleted",
    },
  });
});
export default useDeletePortal;
export { deletePortal };
