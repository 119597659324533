import Images from "./images";

const content = {
  style: "light",
  sections: [
    {
      type: "HeroSection",
      title: "Get to know Lingo",
      subtitle: "Reinventing the folder for today's visual world.",
      layoutDirection: "vertical",
      image: {
        src: Images.Product.library_hero,
        alt: "Slide 1",
      },
    },
    {
      type: "TitleSection",
      // title: "The visual home for your brand",
      subtitle: `
Where's that logo? What color should this banner be? Where's that one product shot? Can you convert this file for me? Why are you using this outdated illustration?

Chances are if you've worked on a cross-functional team before you've heard these questions and you know how frustrating they can be.
These questions are symptoms of a larger problem: teams still use a jumbled mess of folders hosted in cloud storage solutions to manage their digital assets. They're using cloud storage because digital asset managers, a product category created to specifically solve these problems, are too expensive and complex for 99% of modern businesses.

Lingo is changing this. Our small but ambitious team located in sunny Los Angeles is on a mission to build the first modern, affordable and easy to use digital asset manager. We're reinventing the folder for today's visual world so we can help teams empower creation and ensure consistency at scale.
      `,
    },
  ],
};

export default content;
