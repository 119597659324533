import { TinyColor } from "@ctrl/tinycolor";
import { SpaceTheme } from "@thenounproject/lingo-core";

export function parseDefaultTextColorFromTheme(theme: SpaceTheme): string {
  if (theme.headerBackgroundImage) return "#ffffff";
  if (!theme.headerBackgroundColor) return "#2a2a2a";
  const c = new TinyColor(theme.headerBackgroundColor),
    { a: alpha } = c.toHsl();
  return c.isLight() || alpha <= 0.5 ? "#2a2a2a" : "#ffffff";
}
