import { createSelector } from "reselect";
import { KitVersion } from "@thenounproject/lingo-core";
import { useAppSelectorV1 } from "@redux/hooks";

import { getKitVersions, getKitVersionId } from "../getters";

export const selectVersion = createSelector(
  [getKitVersions, getKitVersionId],
  (kitVersions: Record<string, KitVersion>, versionId: string) => {
    return kitVersions[versionId] || null;
  }
);

export const useSelectVersion = () => useAppSelectorV1(selectVersion);
