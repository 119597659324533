import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  assetIds: string[];
};

const [useDeleteAssetItems, deleteAssetItems] = createAsyncAction(
  "assets/deleteItems",
  async ({ assetIds }: Args) => {
    const response = await API.call<{ items: string[] }>({
      endpoint: `assets/items`,
      method: "DELETE",
      entity: API.Entity.item,
      data: {
        uuids: assetIds,
      },
    });
    return response;
  }
);

export default useDeleteAssetItems;
export { deleteAssetItems };
