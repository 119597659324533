import React, { ComponentProps } from "react";
import { Tooltip, Button } from "@thenounproject/lingo-core";

type Props = {
  onClick: () => void;
  source: string;
  featureName?: string;
  direction?: ComponentProps<typeof Tooltip>["direction"];
  plural?: boolean;
};

const UpsellTooltip = ({
  onClick,
  source,
  featureName,
  direction = Tooltip.Direction.Top,
  plural,
}: Props) => {
  return (
    <Tooltip source={source} direction={direction} width={220} maxWidth="250">
      {featureName || "This feature"} {plural ? "are" : "is"} not available on your current plan.{" "}
      <Button
        buttonStyle="tertiary"
        themeOverrides={{ primaryColor: "white", primaryColorDark: "white" }}
        fontStyle="ui.smallBold"
        text="View plans."
        onClick={onClick}
      />
    </Tooltip>
  );
};

export default UpsellTooltip;
