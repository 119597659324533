import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  token: string;
};

const [useVerifyEmail, verifyEmail] = createAsyncAction(
  "auth/verifyEmail",
  async ({ token }: Args) => {
    return await API.call<{ user: number }>({
      endpoint: `email/verify/${token}`,
      method: "POST",
      entity: API.Entity.user,
    });
  }
);

export default useVerifyEmail;
export { verifyEmail };
