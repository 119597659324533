/* eslint-disable @typescript-eslint/no-floating-promises */
/**
 * Contains form used to sign in with SSO
 */

import React, { useState, useEffect } from "react";
import QueryString from "query-string";
import {
  Button,
  Input,
  Text,
  Box,
  OptionCard,
  SpacePreview,
  openIntercom,
} from "@thenounproject/lingo-core";

import { AuthFormWrapper, AuthFormMessage } from "./AuthElements";
import useAuthLoginSSO from "@redux/actions/auth/useSSOLogin";
import AuthFormTitle from "./AuthFormTitle";
import useSpaces from "@redux/actions/spaces/useSpaces";

type Props = {
  space?: SpacePreview;
};

export default function SSOLogin({ space }: Props) {
  const qs = window.location.search || "",
    loginUrl = `/login/${qs}`,
    // We support automatic org entry either by:
    // query params:  /login/sso?organization=<orgid>
    // space preview: the space must have sso_privacy = "public" and data_privacy > "space"
    params = QueryString.parse(qs),
    orgId = space?.ssoOrganization?.name || (params.organization as string),
    next = params.next as string;

  const [organizationId, setOrganizationId] = useState(orgId || ""),
    [error, setError] = useState(null),
    [processing, setProcessing] = useState(false);

  const { data: allSpaces } = useSpaces();
  const spaces = allSpaces?.filter(s => s.ssoOrganization?.name) ?? [];
  const [loginSSO] = useAuthLoginSSO();

  // Because SAML login does it's own redirection
  // it is handled here rather than in Auth like normal login/signup
  const submitOrganization = async (id: string) => {
    if (!id) {
      setError("Please enter your SSO organization name");
      return;
    }
    setError(null);
    setProcessing(true);
    const res = await loginSSO({ organizationId: id });
    setProcessing(false);
    if (res.error) {
      setError(res.error?.message);
    } else {
      let url = res.response.result.url;
      if (next) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        const join = url.includes("?") ? "&" : "?";
        url += `${join}RelayState=${next}`;
      }
      window.location.replace(url);
    }
  };

  useEffect(() => {
    if (orgId) submitOrganization(orgId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function renderSpaces() {
    return spaces.map(s => (
      <OptionCard
        key={s.id}
        image={{ src: s.avatar, style: "circle" }}
        cardStyle="disclosure"
        title={s.name}
        onClick={() => {
          setOrganizationId(s.ssoOrganization.name);
          submitOrganization(s.ssoOrganization.name);
        }}
      />
    ));
  }

  const hasSpaces = spaces.length > 0;

  return (
    <AuthFormWrapper>
      <AuthFormTitle
        title={hasSpaces ? "Enable SSO" : "Login with SSO"}
        message={
          hasSpaces
            ? "Select the space you'd like to enable SSO for."
            : "Enter your organization's name to login using single sign-on."
        }
      />

      {hasSpaces && <Box mb="l">{renderSpaces()}</Box>}
      {hasSpaces && (
        <Text textAlign="center" mb="l">
          OR
        </Text>
      )}

      <form
        onSubmit={e => {
          e.preventDefault();
          if (processing) return;
          submitOrganization(organizationId);
        }}>
        <Input
          onChange={e => setOrganizationId(e.target.value)}
          value={organizationId}
          placeholder="Acme"
          type="text"
          maxLength={255}
          id="organization_input"
          label={hasSpaces ? "Join a new organization" : "Organization name"}
          message={error}
          inputStyle={error ? "error" : null}>
          {
            // Render the button to the right if we are also showing spaces
            hasSpaces && (
              <Box ml="s">
                <Button disabled={processing} type="submit" text="Continue" />
              </Box>
            )
          }
        </Input>

        {
          // If no spaces, show the button full width like the other auth forms
          !hasSpaces && (
            <Button fullWidth mt="xl" disabled={processing} type="submit" text="Continue" />
          )
        }
      </form>

      <AuthFormMessage mt="s">
        <Text textAlign="center" font="ui.small" color="grayDarkest">
          Interested in SSO?{" "}
          <Button
            buttonStyle="tertiary"
            text=" Contact us for Enterprise information."
            onClick={() =>
              openIntercom({
                message: "I'd like to learn more about SSO and the enterprise plan.",
              })
            }
          />
        </Text>
      </AuthFormMessage>

      <AuthFormMessage>
        <Button
          text="Log in with password instead."
          size="small"
          buttonStyle="tertiary"
          link={loginUrl}
        />
      </AuthFormMessage>
    </AuthFormWrapper>
  );
}
