import { createAsyncAction } from "../actionCreators";
import { API, camelize } from "@thenounproject/lingo-core";
import { FigmaIntegrationStatus } from "./useFigmaIntegrationStatus";

type Args = {
  spaceId: number;
  key: string;
};

const [useUpdateFigmaApiKey, updateFigmaApiKey] = createAsyncAction(
  "figma/updateFigmaApiKey",
  async ({ spaceId, key }: Args) => {
    const res = await API.call<FigmaIntegrationStatus>({
      endpoint: `spaces/${spaceId}/figma/auth`,
      method: "POST",
      data: { api_key: key },
    });
    return { result: camelize(res.result) };
  }
);

export default useUpdateFigmaApiKey;
export { updateFigmaApiKey };
