import React, { useState } from "react";
import styled from "styled-components";
import { Flex, Box, Button, PopupMenu, utils, AnyObject } from "@thenounproject/lingo-core";
import DatePicker from "react-datepicker";
import { TinyColor } from "@ctrl/tinycolor";

/**
 * Sept 1, 2022
 * @types/react-datepicker includes a version of
 * date-fns that causes TS compilation errors.
 *
 * Due to too many versions of the typings being present in the project.
 */

type DatePickerWrapperProps = {
  rangePicker?: boolean;
  styleOverrides?: string;
};

export const DatePickerWrapper = styled(Box)<DatePickerWrapperProps>`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  width: 100%;

  input {
    width: 100%;
    height: 28px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    border-radius: 4px;
    font-size: 12px;
    padding-left: 8px;
    ::placeholder {
      color: #cccccc;
    }
    &:focus {
      border: 1px solid #3769e4;
    }
  }

  .react-datepicker__day--disabled {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
  }

  .react-datepicker-popper {
    z-index: 999999999;
  }

  .react-datepicker {
    background-color: #fff;
    box-shadow:
      0px 1px 4px rgba(42, 42, 42, 0.16),
      0px 8px 16px rgba(42, 42, 42, 0.12);
    border-radius: 4px;
    display: ${props => (props.rangePicker ? "flex" : "inherit")};
  }

  .react-datepicker__header {
    border-bottom: 1px solid #ebebeb;
  }

  .react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
    padding: 0 8px;
    font-size: 12px;
    color: ${utils.getColor("grayDarkest")};
    .react-datepicker__day-name {
      width: 34px;
      display: flex;
      justify-content: center;
      padding: 8px 0;
    }
  }

  .react-datepicker__month-container {
    background: #fff;
    border: 1px solid #ebebeb;
    border-radius: 4px;
  }

  .react-datepicker__month {
    padding: 0 8px;
  }

  .react-datepicker__week {
    display: flex;
  }

  .react-datepicker__day {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    border-radius: 4px;
    &:hover {
      border: ${props => (props.rangePicker ? "" : "1px solid #cccccc")};
    }
  }

  .react-datepicker__day--in-range {
    background: ${props => new TinyColor(props.theme.primaryColor).setAlpha(0.2).toRgbString()};
    border-radius: 0px;
  }

  .react-datepicker__day--in-selecting-range {
    background: #ebebeb;
    border-radius: 0px;
    &:hover {
      border-radius: 0 4px 4px 0;
      background: #e0e0e0;
    }
  }

  .react-datepicker__day--selected {
    background: ${props => props.theme.primaryColor};
    color: ${props => props.theme.primaryColorTint};
  }

  .react-datepicker__day--range-end {
    background: ${props => props.theme.primaryColor};
    color: ${props => props.theme.primaryColorTint};
    border-radius: 0 4px 4px 0;
  }

  .react-datepicker__day--range-start {
    border-radius: 4px 0 0 4px;
  }

  .react-datepicker__day--selecting-range-start {
    border-radius: 4px 0 0 4px;
  }

  .react-datepicker__today-button {
    color: ${props => props.theme.primaryColor};
    padding: 8px 0;
    text-align: center;
    cursor: pointer;
    font-size: 12px;
  }

  .react-datepicker__day--outside-month {
    &:empty {
      opacity: 0;
    }
  }

  .react-datepicker__close-icon {
    &::before {
      content: "clear";
    }
  }
  // don't render aria alert
  .react-datepicker__aria-live {
    display: none;
  }

  ${props => props.styleOverrides}
`;

const SubMenuContainer = styled(Flex).attrs({
  position: "absolute",
  width: "100%",
  justifyContent: "center",
  top: "44px",
  background: "white",
  borderTop: "default",
  height: 38 * 8,
  borderRadius: "0 0 4px 4px",
  overflow: "hidden",
  boxShadow: " 0px 8px 16px rgba(42, 42, 42, 0.12)",
})``;

type Props = {
  selected: Date;
  onChange: (date: Date) => void;
  withPortal?: boolean;
  popperProps?: AnyObject;
  openByDefault?: boolean;
  styleOverrides?: string;
  minDate?: Date;
};

const DatePickerComponent = ({
  selected,
  onChange,
  withPortal,
  popperProps = {},
  openByDefault,
  styleOverrides = "",
  minDate,
}: Props) => {
  const monthRef = React.useRef<HTMLDivElement>(null),
    yearRef = React.useRef<HTMLDivElement>(null);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function getYears(offset) {
    const now = new Date().getUTCFullYear();
    return Array(now - (now - offset * 2))
      .fill("")
      .map((v, idx) => now + offset - idx) as Array<number>;
  }

  const years = getYears(20);

  const [menuShown, setMenuShown] = useState(false);

  function getYear(date) {
    return new Date(date).getFullYear();
  }

  function getMonth(date) {
    return months[new Date(date).getMonth()];
  }

  React.useEffect(() => {
    if (monthRef) {
      monthRef?.current?.scrollIntoView();
    }
    if (yearRef) {
      yearRef?.current?.scrollIntoView();
    }
  }, [menuShown]);

  function renderItem(value, date, onClick) {
    const isSelectedMonth = value === getMonth(date),
      isSelectedYear = value === getYear(date);
    return (
      <Box key={value} ref={isSelectedMonth ? monthRef : isSelectedYear ? yearRef : null}>
        <PopupMenu.Item
          title={value}
          checked={isSelectedMonth || isSelectedYear}
          onClick={onClick}
        />
      </Box>
    );
  }

  function renderSubMenu(date, changeYear, changeMonth) {
    if (!menuShown) return;
    return (
      <SubMenuContainer>
        <Flex flexDirection="column" width="100%" overflow="scroll" borderRight="default">
          {months.map((m, idx) => renderItem(m, date, () => changeMonth(idx)))}
        </Flex>
        <Flex flexDirection="column " width="100%" overflow="scroll">
          {years.map(y => renderItem(y, date, () => changeYear(y)))}
        </Flex>
      </SubMenuContainer>
    );
  }

  return (
    <DatePickerWrapper styleOverrides={styleOverrides}>
      <DatePicker
        dateFormatCalendar="MMMM"
        todayButton="Today"
        onClickOutside={() => setMenuShown(false)}
        selected={selected}
        onChange={onChange}
        placeholderText="mm/dd/yyyy"
        withPortal={withPortal}
        popperProps={popperProps}
        autoFocus={openByDefault || undefined}
        minDate={minDate}
        renderCustomHeader={({
          date,
          monthDate,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <Flex flexDirection="column">
            <Flex justifyContent="space-between" alignItems="center" mx="s">
              <Button
                buttonStyle="primary"
                icon="navigation.chevron-left"
                size="small"
                onClick={decreaseMonth}
                disabled={prevMonthButtonDisabled}
                themeOverrides={{
                  primaryColor: "transparent",
                  primaryColorDark: "grayLight",
                  primaryColorTint: "black",
                }}
              />
              <Button
                data-popup-source="month-year-select"
                text={monthDate.toLocaleString("en-US", {
                  month: "long",
                  year: "numeric",
                })}
                buttonStyle="tertiary"
                icon={menuShown ? "navigation.chevron-up" : "navigation.chevron-down"}
                size="small"
                fontStyle="ui.small"
                my="13"
                onClick={() => setMenuShown(!menuShown)}
                themeOverrides={{
                  primaryColor: "black",
                  primaryColorDark: "black",
                }}
              />
              <Button
                buttonStyle="primary"
                icon="navigation.chevron-right"
                size="small"
                onClick={increaseMonth}
                disabled={nextMonthButtonDisabled}
                themeOverrides={{
                  primaryColor: "transparent",
                  primaryColorDark: "grayLight",
                  primaryColorTint: "black",
                }}
              />
            </Flex>
            {renderSubMenu(date, changeYear, changeMonth)}
          </Flex>
        )}
      />
    </DatePickerWrapper>
  );
};

export default DatePickerComponent;
