/**
 * Returns a percentage string to use as padding-bottom value
 * for divs that are meant to emulate a responsive image
 *
 * getImageDimensionPadding(3,2) => "66.6666666%"
 *
 * @param {Number} width image width in pixels
 * @param {Number} height image height in pixels
 */
const getImageDimensionPadding = (width: number, height: number): string =>
  String((height / width) * 100).concat("%");
export default getImageDimensionPadding;
