import React, { useState, useCallback, useMemo, useRef } from "react";

import {
  Flex,
  Box,
  Button,
  SearchFilterTypes,
  buildURL,
  useNavigation,
  SpacePermission,
} from "@thenounproject/lingo-core";

import { joinTags } from "./InspectorAssetTags";

import { Inspectable } from "@constants/Inspector";
import useSaveMultipleAssetTags from "@redux/actions/assets/useSaveMultipleAssetTags";
import AccordionSection from "../AccordionSection";
import { useSelectSpace } from "@selectors/entities/spaces";
import InspectorTagPill from "./InspectorTagPill";
import { MAX_TAGS_CONTAINER_HEIGHT } from "./InspectorAssetTags";
import InspectorAssetTagInput from "./InspectorAssetTagInput";
import useNotifications from "@actions/useNotifications";
import useToggleSearchStep, { SearchSteps } from "@actions/search/useToggleSearchStep";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import useReplaceQueryFilters from "@redux/actions/search/useReplaceQueryFilters";

export type Props = {
  inspectables: Inspectable[];
  canEdit: boolean;
};

export default function InspectorMultiAssetTags({ inspectables, canEdit }: Props) {
  const navigation = useNavigation();
  const space = useSelectSpace();
  const { showNotification } = useNotifications();
  const { showModal } = useShowModal();
  const [processing, setProcessing] = useState(false),
    [expanded, setExpanded] = useState(false),
    [saveMultipleAssetTags] = useSaveMultipleAssetTags(),
    toggleSearchStep = useToggleSearchStep(),
    replaceQueryFilters = useReplaceQueryFilters();

  const ref = useRef<HTMLDivElement>(null);
  const shouldBeExpandable = ref.current?.scrollHeight > MAX_TAGS_CONTAINER_HEIGHT;

  // To simplify calculations later, get an array of unique keywords per asset
  const assets = useMemo(
    () =>
      inspectables
        .filter(i => i.asset)
        .map(inspectable => {
          const { asset } = inspectable;
          const kw = asset.keywords ? asset.keywords.split(",") : [];
          return { uuid: asset.id, keywords: kw };
        }),
    [inspectables]
  );

  // For ease of access to data we need to display, a map of keywords to their number of uses
  const keywords = useMemo(
    () =>
      assets.reduce((acc, asset) => {
        if (!asset.keywords.length) return acc;
        asset.keywords.forEach(keyword => {
          acc[keyword] = (acc[keyword] || 0) + 1;
        });
        return acc;
      }, {}),
    [assets]
  );

  const saveChangedTags = useCallback(
    async tags => {
      setProcessing(true);
      const result = await saveMultipleAssetTags(tags);
      setProcessing(false);
      if (result.error) {
        showNotification({ message: result.error.message, level: "error" });
      }
      return result;
    },
    [saveMultipleAssetTags, showNotification]
  );

  const addTags = useCallback(
    tags => {
      if (processing) return;

      const assetsWithNewTags = assets.reduce((array, a) => {
        const newTags = joinTags(a.keywords, tags);
        return newTags.length === a.keywords.length
          ? array
          : [...array, { ...a, keywords: newTags.join(",") }];
      }, []);
      if (!assetsWithNewTags.length) return Promise.resolve(null);

      return saveChangedTags(assetsWithNewTags);
    },
    [processing, assets, saveChangedTags]
  );

  const removeTag = tag => {
    if (processing) return;

    return saveChangedTags(
      assets.reduce((arr, a) => {
        const filtered = a.keywords.filter(k => k !== tag);
        if (filtered.length === a.keywords.length) return arr;
        return [...arr, { ...a, keywords: filtered.join(",") }];
      }, [])
    );
  };

  const renderTag = (tag: string, numTagged: number) => {
    const onClick = () => {
      replaceQueryFilters([
        {
          type: SearchFilterTypes.keyword,
          id: `kw:${tag}`,
          name: "Keyword",
          style: "text",
          display: tag,
          value: tag,
        },
      ]);
      toggleSearchStep(SearchSteps.RESULTS);
      showModal(ModalTypes.SEARCH_MODAL);
    };

    const inspectorTagPillActionProps =
      numTagged === 0 ? { onDelete: () => removeTag(tag) } : { onAdd: () => addTags([tag]) };

    return (
      <InspectorTagPill
        key={tag}
        tag={tag}
        onClick={onClick}
        canEdit={canEdit}
        disabled={processing}
        {...inspectorTagPillActionProps}
      />
    );
  };

  const hiddenProps = !expanded ? { maxHeight: MAX_TAGS_CONTAINER_HEIGHT, overflow: "hidden" } : {};

  const renderTagList = () => {
    const nonSharedTags = Object.keys(keywords)
      .filter((tag: string) => keywords[tag] % assets.length > 0)
      .sort();
    const sharedTags = Object.keys(keywords)
      .filter((tag: string) => keywords[tag] % assets.length === 0)
      .sort();

    const tags = [...sharedTags, ...nonSharedTags];

    if (!tags.length) return null;
    return (
      <Box ref={ref} mt="m" {...hiddenProps}>
        <Flex as="ul" flexWrap="wrap">
          {tags.length ? (
            <>
              {tags.map(tag => {
                const numTagged = keywords[tag] % assets.length; // 0 if all items tagged
                return renderTag(tag, numTagged);
              })}
            </>
          ) : null}
        </Flex>
      </Box>
    );
  };

  const renderTagInput = () => {
    if (!canEdit) return null;

    return <InspectorAssetTagInput onTagSelect={addTags} />;
  };

  const buttonProps = space.access?.permissions.includes(SpacePermission.manageTags)
    ? {
        buttonText: "Manage tags",
        onButtonClick: () => navigation.push(buildURL("/library/tags", { space })),
      }
    : {};

  return (
    <AccordionSection title="Shared Tags" {...buttonProps}>
      {renderTagInput()}
      {renderTagList()}
      {shouldBeExpandable && (
        <Button
          text={expanded ? "Show less" : "Show more"}
          buttonStyle="tertiary"
          mt="s"
          onClick={() => setExpanded(!expanded)}
        />
      )}
    </AccordionSection>
  );
}
