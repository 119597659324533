/**
 * Allows user to create a new space (just requires a name).
 */

import React, { useState, useCallback, FormEvent } from "react";
import Helmet from "react-helmet";
import {
  buildURL,
  Icon,
  Text,
  Input,
  Button,
  Notice,
  useNavigation,
  Flex,
} from "@thenounproject/lingo-core";

import useRequireLogin from "../hooks/useRequireLogin";
import useCreateSpace from "@redux/actions/spaces/useCreateSpace";

type Props = {
  onSpaceCreated?: (Space) => void;
};

const CreateSpace: React.FC<Props> = ({ onSpaceCreated }) => {
  const [error, setError] = useState(null),
    [processing, setProcessing] = useState(false),
    [spaceName, setSpaceName] = useState(""),
    onNameChange = useCallback(e => setSpaceName(e.target.value), []),
    [createSpace] = useCreateSpace(),
    navigation = useNavigation(),
    onSubmit = useCallback(
      async (e: FormEvent) => {
        if (e) e.preventDefault();
        setProcessing(true);
        const { response, error: responseError } = await createSpace(spaceName);
        setProcessing(false);
        if (responseError) {
          setError(responseError.message);
          return;
        }
        const newSpace = response.entities.spaces[response.result];
        if (!newSpace) return;
        if (onSpaceCreated) {
          onSpaceCreated(newSpace);
        } else {
          const url = buildURL("/", { space: newSpace });
          navigation.push(url);
        }
      },
      [createSpace, navigation, onSpaceCreated, spaceName]
    );

  useRequireLogin();

  return (
    <>
      <Helmet title="Create a new Space" />
      <Flex
        flexDirection="column"
        px="xl"
        pt="xxl"
        textAlign="center"
        maxWidth="600px"
        m="auto"
        alignItems="center"
        justifyContent="center">
        <Icon iconId="building" fill="grayDark" size={48} />
        <Text as="h1" font="ui.title">
          Create a new Space
        </Text>
        <Text mt="xs">
          Please note that each Space is accounted for separately.
          <br />
          You’ll have 30 days to try your new Space for free.
        </Text>
        <Input
          styleOverrides={{ mt: "l" }}
          label="Space name"
          id="space-title"
          type="text"
          value={spaceName}
          onChange={onNameChange}
          autoFocus
          onSubmit={onSubmit}
          placeholder="e.g. Acme or Acme Design"
          message={error}
          inputStyle={error ? "error" : null}
        />

        <Button
          fullWidth
          mt="l"
          disabled={processing}
          onClick={onSubmit}
          text={processing ? "Creating..." : "Create Space"}
        />

        <Notice
          mt="xl"
          message="Is your team already using Lingo? Ask an admin for an invite to their workspace using the email you signed up with."
          noticeStyle="neutral"
        />
      </Flex>
    </>
  );
};

export default CreateSpace;
