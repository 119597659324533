import React, { useState, useContext, useEffect } from "react";
import {
  ViewModeContext,
  initialState,
  ViewModesState,
  inspectorStorageKey,
  galleryAssetViewStorageKey,
} from "./ViewModeContext";
import { useObject } from "@hooks/useObject";

export default function ViewModeProvider({ children }: { children: React.ReactNode }) {
  const [galleryAssetViewMode, setGalleryAssetViewMode] = useState<
    ViewModesState["galleryAssetViewMode"]
  >(initialState.galleryAssetViewMode);
  const [inspectorMode, setInspectorMode] = useState<ViewModesState["inspectorMode"]>(
    initialState.inspectorMode
  );

  useEffect(() => {
    localStorage.setItem(inspectorStorageKey, inspectorMode);
  }, [inspectorMode]);

  useEffect(() => {
    localStorage.setItem(galleryAssetViewStorageKey, galleryAssetViewMode);
  }, [galleryAssetViewMode]);

  const context = useObject({
    galleryAssetViewMode,
    setGalleryAssetViewMode,
    inspectorMode,
    setInspectorMode,
  });

  return <ViewModeContext.Provider value={context}>{children}</ViewModeContext.Provider>;
}

export const useViewModeContext = () => useContext(ViewModeContext);
