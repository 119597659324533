/**
 * Reorders gallery items & validates the new layout.
 */

import { API } from "@thenounproject/lingo-core";
import { mutateLayoutByReorder } from "@helpers/layoutValidation";
import stripVersion from "@helpers/stripVersion";
import { createAsyncAction } from "../actionCreators";
import { fetchSectionItems } from "./useSectionItems";
import { showNotification } from "../useNotifications";

export type Args = {
  reorderItemIds: string[];
  displayOrder: string;
  sectionId: string;
  guidePosition: string;
  dragPosition: string;
  validSelfDrop: boolean;
};

const [useReorderSectionItems, reorderSectionItems] = createAsyncAction(
  "items/reorderSectionItems",
  async (args: Args, thunkApi) => {
    const { entities } = thunkApi.getState();
    const itemQuery = fetchSectionItems.getQueryData(entities.items.queries, {
      sectionId: args.sectionId,
      version: 0,
    });
    const items = itemQuery[0].data.items.map(id => entities.items.objects[id]);
    const result = mutateLayoutByReorder(args, items);
    /**
     * If mutateLayoutByReorder returns falsy, no API call is needed
     */
    if (!result) return;

    const { reorderedSectionItemIds, updates, displayOrder } = result;

    const data = {
      position: displayOrder,
      items_to_update: updates,
      items_to_reorder: reorderedSectionItemIds.map(uuid => {
        return { uuid: stripVersion(uuid) };
      }),
    };

    try {
      const res = await API.call<{ items: string[] }>({
        endpoint: "items/reorder",
        method: "PUT",
        entity: API.Entity.item,
        data,
      });

      return { ...res, reorderedSectionItemIds };
    } catch (err) {
      if (err.message) {
        thunkApi.dispatch(showNotification({ message: err.message, level: "error" }));
      }
      throw err;
    }
  }
);

export default useReorderSectionItems;
export { reorderSectionItems };
