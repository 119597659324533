import { Asset, LingoError } from "@thenounproject/lingo-core";

export const getAssetError = (asset?: Asset): LingoError => {
  if (!asset) return null;
  const code = asset.meta?.assetProcessingDetails?.code;
  const message = asset.meta?.assetProcessingDetails?.message || "Something went wrong.";

  /**
   * 0 = processing
   * 200 = complete
   */
  if (code > 0 && code !== 200) return { code, message };
  return null;
};
