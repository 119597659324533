import React from "react";
import { Box, Flex, BoxProps, FlexProps } from "@thenounproject/lingo-core";
import styled from "styled-components";

import Footer from "./Footer";

const Wrapper = styled(Flex).attrs<Props>(props => {
  return {
    width: "100%",
    height: "100%",
    flexDirection: "column",
    m: "0 auto",
    py: "none",
    px: "xl",
    maxWidth: "900px",
    ...props.styleOverrides,
  };
})<Props>`
  ${props =>
    props.disableTextSelection &&
    // When a user has a selection & is holding shift - nothing is selectable
    `
    user-select: none !important;
    * {
      user-select: none !important;
    }
  `}
`;

type Props = {
  children: React.ReactNode;
  poweredBy?: boolean;
  disableTextSelection?: boolean;
  styleOverrides?: BoxProps & FlexProps;
} & BoxProps &
  FlexProps;

const ContentContainer = React.forwardRef<HTMLDivElement, Props>(
  ({ children, poweredBy, disableTextSelection, styleOverrides }, ref) => {
    return (
      <Wrapper
        ref={ref}
        disableTextSelection={disableTextSelection}
        styleOverrides={styleOverrides}>
        <Box data-inspector-clear="true" flexGrow="1">
          {children}
        </Box>
        <Footer showPoweredBy={poweredBy} />
      </Wrapper>
    );
  }
);

ContentContainer.displayName = "ContentContainer";

export default ContentContainer;
