import React, { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import QueryString from "query-string";

import {
  ActivityIndicator,
  Box,
  buildURL,
  Button,
  DropdownButton,
  Kit,
  KitPermission,
  KitVersion,
  PopupMenu,
  Portal,
  Space,
  Text,
  useNavigation,
} from "@thenounproject/lingo-core";

import useKitUpsell, { AccessStatus } from "@hooks/useKitUpsell";
import useCurrentUser from "@queries/useCurrentUser";
import useKitVersions from "@redux/actions/kitVersions/useKitVersions";
import useUpdateKitUserVersion from "@redux/actions/kits/useUpdateKitUserVersion";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";

import UpsellTooltip from "../../spaces/settings/UpsellTooltip";

type Props = {
  kit: Kit;
  space: Space;
  portal?: Portal;
  activeVersion: KitVersion;
};

const List = styled(Text).attrs({
  as: "ul",
})`
  li {
    list-style: disc;
  }
`;

const KitNavVersionMenu: React.FC<Props> = ({ kit, activeVersion, space, portal }) => {
  const { user } = useCurrentUser();
  const { showModal } = useShowModal();
  const navigation = useNavigation();
  const [showMenu, setShowMenu] = useState(false);
  const toggleVersionMenu = useCallback(() => {
    setShowMenu(!showMenu);
  }, [showMenu]);

  const {
    data: _versions,
    isLoading,
    // error: loadingError,
    // fetchNextPage,
  } = useKitVersions({ spaceId: space.id, kitId: kit.kitId });

  // Get the draft out of the versions so we can render it separately

  const { draft, versions } = useMemo(() => {
    let draft: KitVersion;
    const versions = (_versions ?? []).filter(v => v.status === "active");
    const draftIndex = versions.findIndex(v => v.version === 0);
    if (draftIndex > -1) {
      [draft] = versions.splice(draftIndex, 1);
    }
    versions?.sort((a, b) => b.version - a.version);
    return { draft, versions };
  }, [_versions]);

  const { openUpgradeModal, accessStatus: versioningAccess } = useKitUpsell(
    KitPermission.manage,
    kit
  );

  const [updateKitUserVersion] = useUpdateKitUserVersion();

  function setActiveVersion(version: number) {
    if (user?.id) {
      void updateKitUserVersion({
        spaceId: kit.spaceId,
        kitId: kit.kitId,
        version,
      });
    }
    // TODO: handle portal kits

    const qs = QueryString.parse(window.location.search);
    qs.v = String(version);
    const url = buildURL(`/k/${kit.urlId}`, { space, portal }, qs);
    navigation.push(url);
  }

  const createNewVersion = useCallback(() => {
    showModal(ModalTypes.CREATE_KIT_VERSION, {
      kitId: kit.kitId,
      spaceId: kit.spaceId,
    });
  }, [kit.kitId, showModal, kit.spaceId]);

  const versionHistoryUrl = buildURL(
    `/k/${kit.urlId}/versions/`,
    { space, portal },
    { kit_token: kit.shareToken ?? undefined }
  );

  function isActiveVersion(version: KitVersion) {
    return version.version === activeVersion?.version;
  }
  function isRecommendedVersion(version: KitVersion) {
    return version.version === kit.recommendedVersion;
  }
  function versionIdentifier(version: KitVersion) {
    return version.version === 0 ? "Shared draft" : version.versionIdentifier;
  }

  function renderVersionMenu() {
    const insufficientPlan = versioningAccess === AccessStatus.insufficientPlan;

    function renderCreateButton() {
      if (versioningAccess === AccessStatus.insufficientRole) return null;
      return (
        <>
          {insufficientPlan && (
            <UpsellTooltip
              source="create-version-button"
              onClick={openUpgradeModal}
              featureName="Kit versioning"
            />
          )}
          <Box data-tooltip-source="create-version-button">
            <Button
              size="small"
              text="Create version"
              onClick={createNewVersion}
              disabled={insufficientPlan}
            />
          </Box>
        </>
      );
    }

    function renderPopupContent() {
      if (isLoading) {
        return (
          <Box height="150px">
            <ActivityIndicator center />
          </Box>
        );
      } else if (!versions?.length) {
        return renderEmpty();
      } else {
        return renderVersions();
      }
    }

    function renderDraft() {
      if (!draft) return null;
      return (
        <PopupMenu.Section>
          <PopupMenu.Item
            title="Shared draft"
            image={{
              iconId: isActiveVersion(draft) ? "checkmark" : "draft",
              size: "16",
            }}
            onClick={() => setActiveVersion(draft.version)}
            accessory={renderCreateButton()}
          />
        </PopupMenu.Section>
      );
    }

    function renderVersions() {
      return versions.map(v => (
        <PopupMenu.Item
          key={v.version}
          title={`${versionIdentifier(v)} ${isRecommendedVersion(v) ? "(Recommended)" : ""}`}
          image={{ size: 16, iconId: isActiveVersion(v) ? "checkmark" : "version" }}
          onClick={() => setActiveVersion(v.version)}
        />
      ));
    }

    function renderEmpty() {
      if (versions.length === 0 && versioningAccess !== AccessStatus.insufficientRole) {
        return (
          <Box px="m" py="s">
            <Text font="ui.small">
              Control how changes to the Kit are released to your team. Create a version to:
            </Text>
            <List ml="m" font="ui.small">
              <li>
                Use the Shared Draft as a “staging environment” where changes can be made without
                going live
              </li>
              <li>Release what’s in the Shared Draft when you’re ready to do so</li>
              <li>Communicate what is new in every version that gets released</li>
              <li>Have teammates update to the newest version when it is convenient for them</li>
            </List>
          </Box>
        );
      }
      return null;
    }

    /**
     * If the versions are still loading, show a spinner
     * Others show:
     * ----------------
     * Draft        [create version]]
     * ----------------
     * view history
     * ----------------
     * ✔ Version 1
     * Version 2 (recommended)
     */
    return (
      <PopupMenu source="version-menu" close={toggleVersionMenu} width={380}>
        {renderDraft()}
        <PopupMenu.Section
          title="Versions"
          button={{ text: "View history", link: versionHistoryUrl }}>
          {renderPopupContent()}
        </PopupMenu.Section>
      </PopupMenu>
    );
  }

  const shouldShowVersionMenu = () => {
    if (!_versions?.length) return false;
    if (_versions?.length > 1) return true;
    return versioningAccess !== AccessStatus.insufficientRole;
  };

  if (!shouldShowVersionMenu()) return null;

  return (
    <>
      <DropdownButton
        text={versionIdentifier(activeVersion)}
        data-popup-source="version-menu"
        onClick={toggleVersionMenu}
        styleOverrides={{ alignSelf: "flex-start", minWidth: "120px", width: "100%", mt: "l" }}
      />
      {showMenu ? renderVersionMenu() : null}
    </>
  );
};

export default KitNavVersionMenu;
