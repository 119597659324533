import React, { useCallback } from "react";
import {
  Checkbox,
  DropdownButton,
  PopupMenu,
  useBoolean,
  Box,
  CustomField,
  Text,
  Flex,
  CustomFieldOperation,
  Asset,
} from "@thenounproject/lingo-core";
import _uniq from "lodash/uniq";

import useNotifications from "@actions/useNotifications";

import useAssetsCustomFieldUpdate from "@redux/actions/assets/useAssetsCustomFieldUpdate";

export type Props = {
  field: CustomField;
  assetUuids: string[];
  updateCustomFieldValue: ReturnType<typeof useAssetsCustomFieldUpdate>[0];
  assets: Asset[];
  canEdit: boolean;
};

const ChecklistCustomFieldMulti = ({
  field,
  updateCustomFieldValue,
  assetUuids,
  assets,
  canEdit,
}: Props) => {
  const assetFieldValues = assets.map(asset => asset.fields?.[field.id]) as any[];
  const uniqueAssetFieldValues = _uniq(assetFieldValues.flat());
  const valuesThatExistOnAllAssets = uniqueAssetFieldValues?.filter(value =>
    assetFieldValues?.every(values => values?.includes(value))
  );

  const [menuShown, showMenu, hideMenu] = useBoolean(false);
  const { showNotification } = useNotifications();

  const handleFieldOptionUpdate = useCallback(
    async (fieldId: number, optionId: number, operation: CustomFieldOperation) => {
      const { error } = await updateCustomFieldValue({
        assetUuids,
        fieldId,
        optionId,
        operation,
      });
      if (error) {
        showNotification({ message: error.message, level: "error" });
      }
    },
    [assetUuids, updateCustomFieldValue, showNotification]
  );

  function onClickItem(isSelected, optionUuid) {
    return isSelected
      ? handleFieldOptionUpdate(field.id, optionUuid, CustomFieldOperation.remove)
      : handleFieldOptionUpdate(field.id, optionUuid, CustomFieldOperation.add);
  }

  function renderChecklist() {
    return field.options?.slice(0, 4).map(o => {
      const existsOnAllAssets = valuesThatExistOnAllAssets?.includes(String(o.id));
      const existsOnSomeAssets = uniqueAssetFieldValues?.includes(String(o.id));
      return (
        <Checkbox
          key={o.id}
          label={o.name}
          size="small"
          onClick={() => onClickItem(existsOnAllAssets, o.id)}
          isSelected={existsOnAllAssets}
          type={!existsOnAllAssets && existsOnSomeAssets ? "indeterminate" : "checkbox"}
        />
      );
    });
  }

  function renderOverflowDropdown() {
    if (field.options?.length < 5) return null;
    const options = field.options?.slice(4);
    const selectedOverflowOptions = options?.filter(o =>
      uniqueAssetFieldValues?.includes(String(o.id))
    );
    const nonSelectedOverflowOptions = options?.filter(
      o => !uniqueAssetFieldValues?.includes(String(o.id))
    );
    return (
      <>
        {selectedOverflowOptions?.map(o => {
          const existsOnAllAssets = valuesThatExistOnAllAssets?.includes(String(o.id));
          const existsOnSomeAssets = uniqueAssetFieldValues?.includes(String(o.id));
          return (
            <Checkbox
              key={o.id}
              label={o.name}
              size="small"
              onClick={() => onClickItem(existsOnAllAssets, o.id)}
              isSelected={existsOnAllAssets}
              type={!existsOnAllAssets && existsOnSomeAssets ? "indeterminate" : "checkbox"}
            />
          );
        })}

        {nonSelectedOverflowOptions?.length > 0 && (
          <>
            <Box mt="s" data-testid="inspector-checklist-dropdown">
              <DropdownButton
                text="More options"
                styleOverrides={{ width: "100%" }}
                data-popup-source={`${field.name}-dropdown`}
                onClick={showMenu}
              />
            </Box>
            <PopupMenu open={menuShown} close={hideMenu} source={`${field.name}-dropdown`}>
              {nonSelectedOverflowOptions.map(o => {
                return (
                  <PopupMenu.Item
                    key={o.id}
                    onClick={() => onClickItem(false, o.id)}
                    title={o.name}
                  />
                );
              })}
            </PopupMenu>
          </>
        )}
      </>
    );
  }

  if (!canEdit && field.public) {
    const selectedOptions = field.options?.filter(o =>
      valuesThatExistOnAllAssets.includes(String(o.id))
    );
    return (
      <Flex flexDirection="column" gap="4px">
        {selectedOptions.length ? (
          selectedOptions.map(o => (
            <Text key={o.id} font="ui.small">
              {o.name}
            </Text>
          ))
        ) : (
          <Text font="ui.small">None</Text>
        )}
      </Flex>
    );
  }

  return (
    <Box data-testid="inspector-checklist-field">
      {renderChecklist()}
      {renderOverflowDropdown()}
    </Box>
  );
};

export default ChecklistCustomFieldMulti;
