/**
 * Manages global drag state
 */

import { createReducer } from "@reduxjs/toolkit";
import * as actionTypes from "@redux/legacy-actions/actionTypes";

export enum DragEntities {
  GALLERY_ITEM = "GALLERY_ITEM",
  KIT_NAV_SECTION = "KIT_NAV_SECTION",
  KIT_NAV_HEADING = "KIT_NAV_HEADING",
  PORTAL_ITEM = "PORTAL_ITEM",
}

export type DraggingReducerState = {
  entity: DragEntities | undefined;
};

export default createReducer<DraggingReducerState>({ entity: undefined }, builder => {
  builder.addCase(actionTypes.SET_DRAGGING_ENTITY, (state, action: any) => {
    state.entity = action.entity || undefined;
  });
});
