import { MarketingButtons } from "../../components/MarketingButtons";
import { PageContent } from "../../pages/templates/PageLayout";
import featureCards from "../featureCards";

import Images from "../images";

const content: PageContent = {
  style: "light",
  sections: [
    {
      type: "HeroSection",
      title: "Kits",
      style: "light",
      layoutDirection: "vertical",
      subtitle:
        "Part document part asset library, Kits are a flexible canvas that allow you to present and arrange your assets in way that makes them easy for others to use and understand.",
      image: {
        src: Images.Product.kits_hero,
        alt: "",
      },
      primaryButton: MarketingButtons.getStarted,
      secondaryButton: MarketingButtons.bookDemo,
    },
    {
      type: "MediaSection",
      title: "Content and Context. Together",
      subtitle:
        "Ensure your assets are used correctly by providing guidelines and documentation directly in line with your assets. ",
      image: {
        src: Images.Product.kits_contentContext,
        alt: "Content and context together",
      },
    },
    {
      type: "MediaSection",
      title: "Customize Kits to fit your needs",
      subtitle:
        "Build different types of kits so you can keep all your brand assets together in a single location.",
      image: {
        src: Images.Product.kits_customKits,
        alt: "Slide 1",
      },
      layoutDirection: "image-left",
      body: {
        style: "iconList",
        items: [
          {
            title: "Brand style guide",
            body: "",
          },
          {
            title: "Photo library",
            body: "",
          },
          {
            title: "Video library",
            body: "",
          },
        ],
      },
    },
    {
      type: "CardSection",
      title: "More features",
      items: [featureCards.kitVersioning, featureCards.publicSharing, featureCards.unlimitedKits],
      footerButton: {
        text: "View all features",
        buttonStyle: "outline",
        link: "/product/features",
      },
    },
    {
      type: "CtaSection",
    },
  ],
};
export default content;
