import React from "react";
import {
  Flex,
  GalleryHeading,
  utils,
  Box,
  GalleryNote,
  factory,
  Text,
  Space,
  Section,
  Kit,
} from "@thenounproject/lingo-core";
import styled, { useTheme } from "styled-components";
import { noop } from "lodash";

import KitTitle from "../../../components/kits/KitTitle";
import { PageContentTypes } from "../../../components/kits/Kit";
import { SubNavContainer } from "../../../components/navigation/SubNav";
import { KitNavInternals } from "../../../components/navigation/KitNav";
import { SectionTitle } from "../../../components/kits/Section";
import ContentContainer from "../../../components/ContentContainer";

import { InViewTracker } from "../../../contexts/InViewProvider";
import SectionFooterNav from "../../navigation/SectionFooterNav";
import { useSelectSpace } from "@redux/selectors/entities/spaces";

const kitVersion = {
  kitId: "",
  version: 0,
  versionIdentifier: "",
  notes: "",
  creator: {
    id: 0,
    name: "",
    email: "",
    avatar: "",
    isActive: false,
    createdAt: "",
    spaces: [],
    mfa: {
      totp_enabled: false,
    },
    isFetched: false,
    isFetching: false,
  },
  dateAdded: "",
  dateUpdated: "",
  status: "",
  spaceId: 0,
  sections: [],
  counts: {
    assets: 0,
    items: 0,
    sections: 0,
    trash: 0,
    recovery: 0,
  },
};

const mockKitNavProps = {
  spaceId: 100,
  sectionId: "2",
  versionNum: 0,
  kit: {
    id: "100",
    kitId: "100",
    spaceId: 100,
    urlId: "google.com",
    access: {
      id: 100,
      kitUuid: "100",
      dateAdded: "",
      dateUpdated: "",
      isFavorite: false,
      permissions: ["search_assets", "change_own_status", "manage"],
      role: "admin",
      spaceId: 100,
      status: "active",
      kitId: "0",
      isPublic: true,
      upgradePermissions: { none: "null" },
    },
    downloadsRestricted: false,
    downloadsRestrictedApproveMode: "approve_all_automatically" as const,
    portalAccess: false,
    recommendedVersion: 0,
    description: "Pretty cool kit",
    name: "Design System",
    images: { none: [] },
    privacy: "public",
    indexable: false,
    shortId: "100",
    status: "active",
    dateAdded: "",
    dateUpdated: "",
    versions: [],
    counts: {
      members: 100,
    },
  } as Kit,
  userId: "1",
  space: {} as Space,
  draft: {},
  versions: [
    {
      kitId: "100",
      version: 0,
      versionIdentifier: "100-0",
      status: "active",
      spaceId: 100,
      sections: [],
      counts: {
        assets: 1,
        items: 1,
        sections: 1,
        trash: 1,
      },
    },
  ],
  shareToken: "100",
  kitVersion,
  kitOutline: {
    ...kitVersion,
    sections: [
      {
        id: "1",
        shortId: "1",
        kitId: "100",
        displayOrder: 0,
        status: "active",
        name: "Section 1",
        version: 0,
        headers: [],
        counts: {
          items: 100,
        },
      },
      {
        id: "2",
        shortId: "2",
        kitId: "100",
        displayOrder: 1,
        status: "active",
        name: "Section 2",
        version: 0,
        headers: [
          {
            id: "1",
            shortId: "heading-1",
            name: "Heading 1",
            version: 0,
            displayOrder: 0,
          },
          {
            id: "2",
            shortId: "heading-2",
            name: "Heading 2",
            version: 0,
            displayOrder: 0,
          },
        ],
        counts: {
          items: 100,
        },
      },
      {
        id: "3",
        shortId: "3",
        kitId: "100",
        displayOrder: 2,
        status: "active",
        name: "Section 3",
        version: 0,
        headers: [],
        counts: {
          items: 100,
        },
      },
    ] as Section[],
  },
  canCreateVersions: false,
  canEditSections: false,
  pageContentType: null,
};

const mockHeading = {
  item: {
    id: "heading-1",
    shortId: "heading-1",
    kitId: "100",
    type: "heading",
    status: "active",
    version: 0,
    data: { content: "Heading 1" },
  },
};

const noteMarkdown = `
Lorem ipsum dolor sit amet, consectetur [adipiscing elit](https://www.youtube.com/watch?v=dQw4w9WgXcQ), sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
`;

const noteItem = factory.normalize.item(
  factory.createNoteItem({ data: { content: noteMarkdown } })
);

const MockGalleryHeading: React.FC<any> = props => <GalleryHeading {...props} />;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 20px;
  margin-top: 24px;
  div {
    aspect-ratio: 1;
  }
`;

const DemoScrollContainer = styled.div`
  overflow: hidden;
  width: 100%;
  padding-top: 40px;
`;

const ThemeEditorGalleryView: React.FC = () => {
  const theme = useTheme();
  const space = useSelectSpace();

  function renderNotePreviews() {
    return (
      <Flex flexDirection="column" gap="8px">
        <Box p="m" mt="xl" borderRadius="default" background={theme.noteInfoColor}>
          <Text color={utils.getTextColor(theme.noteInfoColor)}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae mi lectus.
          </Text>
        </Box>
        <Box p="m" borderRadius="default" background={theme.noteWarningColor}>
          <Text color={utils.getTextColor(theme.noteWarningColor)}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae mi lectus.
          </Text>
        </Box>
        <Box p="m" borderRadius="default" background={theme.noteSuccessColor}>
          <Text color={utils.getTextColor(theme.noteSuccessColor)}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vitae mi lectus.
          </Text>
        </Box>
      </Flex>
    );
  }

  return (
    <Flex height="100%" width="100%">
      <SubNavContainer>
        <KitNavInternals {...mockKitNavProps} />
      </SubNavContainer>
      <Flex flexDirection="column" width="100%" height="100%" overflow="hidden">
        <KitTitle
          kit={mockKitNavProps.kit}
          selectedItems={[]}
          clearSelection={noop}
          kitVersion={null}
          pageContentType={PageContentTypes.section}
          space={space}
        />
        <DemoScrollContainer>
          <ContentContainer>
            <SectionTitle>{mockKitNavProps.kitOutline.sections[1].name}</SectionTitle>
            <InViewTracker id="heading-1" type="header" />
            {/* Some hackery with the margins here to get alignment square */}
            <MockGalleryHeading ml="-s" {...mockHeading} />
            <Box mt="20px" mx="-m">
              <GalleryNote item={noteItem} />
            </Box>
            <Grid>
              {Array(6)
                .fill("")
                .map((_noop, i) => (
                  <Box
                    key={i}
                    border={i === 0 && `2px solid ${theme.primaryColor}`}
                    borderRadius="default"
                    background={i % 2 === 0 ? theme.assetLightColor : theme.assetDarkColor}
                  />
                ))}
            </Grid>
            {renderNotePreviews()}
            <SectionFooterNav
              prevSection={{ path: "", name: mockKitNavProps.kitOutline.sections[0].name }}
              nextSection={{ path: "", name: mockKitNavProps.kitOutline.sections[2].name }}
              currentSectionIndex={1}
              totalSections={3}
            />
          </ContentContainer>
        </DemoScrollContainer>
      </Flex>
    </Flex>
  );
};

export default ThemeEditorGalleryView;
