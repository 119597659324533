import React from "react";
import { Box, Flex, Text } from "@thenounproject/lingo-core";
import styled from "styled-components";

const Line = styled(Box).attrs({ background: "gray", height: "1px", width: "100%" })`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

export default function OrSeparator() {
  return (
    <Flex width={"100%"} position={"relative"} justifyContent="center" alignItems="center">
      <Line />
      <Box background="white" p="0 8px">
        <Text font="ui.smallBold" color="grayDarkest">
          OR
        </Text>
      </Box>
    </Flex>
  );
}
