import suggestionTypes from "../constants/suggestionTypes";
import { SearchFilterInput } from "@thenounproject/lingo-core";

export default function constructKeywordFilter(keyword: string) {
  return {
    type: suggestionTypes.keyword,
    id: `kw:${keyword}`,
    name: "Keyword",
    style: "text",
    display: keyword,
    value: keyword,
  } as SearchFilterInput;
}
