import { Kit, API } from "@thenounproject/lingo-core";
import { createQueryAction } from "@actions/actionCreators";

const [useShowcaseKits, fetchShowcaseKits] = createQueryAction<void, Kit[], number[]>(
  {
    entity: "kits",
    action: "queryShowcaseKits",
  },
  async () => {
    const res = await API.call<{ kits: number[] }>({
      endpoint: `showcase/kits`,
      method: "GET",
      entity: API.Entity.kit,
    });

    return { result: res.result.kits, entities: res.entities };
  }
);
export default useShowcaseKits;
export { fetchShowcaseKits };
