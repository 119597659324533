import { createAsyncAction } from "@actions/actionCreators";
import { API, ErrorCode } from "@thenounproject/lingo-core";

type Args = {
  spaceId: string | number;
  name: string;
};

const [useCreateKitCollection, createKitCollection] = createAsyncAction(
  "kitCollections/update",
  async ({ spaceId, name }: Args) => {
    if (!name.trim())
      throw {
        code: ErrorCode.invalidParams,
        message: "Name is required",
      };
    return await API.call<string>({
      endpoint: "kit_collections",
      method: "POST",
      entity: API.Entity.kitCollection,
      data: {
        space_id: spaceId,
        name,
      },
    });
  }
);
export default useCreateKitCollection;
export { createKitCollection };
