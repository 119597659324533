import { Plan } from "@redux/actions/billing/useAvailablePlans";
import { SpaceSubscription } from "@thenounproject/lingo-core";

/**
 * Renders object with textual representations of any plan's cost. For new plans, the object
 * includes basePrice (minimum cost), pricePerUser (cost for additional users above base), and
 * fullPrice (actual cost for space accounting for joined users). Legacy plans will just show full
 * price, since they have a flat rate per tier.
 */
export function renderSubscriptionCost(subscription: SpaceSubscription): string {
  if (subscription.plan === "tier-0") {
    return "Free";
  }

  const intervalName = subscription.interval === "year" ? "yr" : "mo";
  if (subscription.plan.startsWith("tier-ent")) {
    return `$${(subscription.price * subscription.quantity) / 100}/${intervalName}`;
  }
  // Some legacy plans don't include the totalPrice
  const finalPrice = (subscription.totalPrice ?? subscription.price) / 100;
  return `$${finalPrice}/${intervalName}`;
}

export const renderPlanCost = (plan: Plan, userCount: number) => {
  const planId = plan.planId;

  const basePrice = plan.price / 100,
    intervalName = plan.interval === "year" ? "yr" : "mo";

  if (planId.startsWith("tier-ent")) {
    return {
      fullPrice: "Bespoke pricing",
      basePrice: null,
      pricePerUser: null,
    };
  }
  let fullPrice = basePrice;

  const { pricePerUser, minUsers = 0, maxUsers } = plan;
  if (pricePerUser) {
    const usersAboveBase = pricePerUser ? userCount - minUsers : 0,
      userSurcharge = usersAboveBase > 0 ? (pricePerUser * usersAboveBase) / 100 : 0;
    fullPrice = basePrice + userSurcharge;
  }

  return {
    fullPrice: `$${fullPrice}/${intervalName}`,
    basePrice: `$${basePrice}/${intervalName}`,
    includedUsers: `${minUsers || maxUsers} users`,
    pricePerUser: pricePerUser
      ? `$${pricePerUser / 100}/${intervalName} (up to ${maxUsers})`
      : `Limited to ${maxUsers} users`,
  };
};
