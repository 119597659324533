import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  spaceId: number | string;
  newPrefs: Record<string, boolean>;
};

const [useUpdateSpaceNotifications, updateSpaceNotifications] = createAsyncAction(
  "spaces/updateSpaceNotifications",
  async ({ spaceId, newPrefs }: Args) => {
    return await API.call<string>({
      endpoint: `me/spaces/${spaceId}/notification-prefs${window.location.search}`,
      method: "PUT",
      data: newPrefs,
      entity: API.Entity.spaceMember,
    });
  }
);

export default useUpdateSpaceNotifications;
export { updateSpaceNotifications };
