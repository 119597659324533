import React, { useCallback, useMemo } from "react";
import { Flex, SpaceTheme, utils } from "@thenounproject/lingo-core";
import styled from "styled-components";
import TextareaAutosize from "react-autosize-textarea";

import { SpaceThemeTextOptions } from "@features/theming/types";
import { useThemeDataContext } from "@contexts/ThemeDataProvider";

interface Props {
  themeTextOption: SpaceThemeTextOptions;
  newTheme: SpaceTheme;
  updateTheme: ReturnType<typeof useThemeDataContext>["updateEditingTheme"];
  placeholder?: string;
}

const Wrapper = styled(Flex).attrs({
  width: "100%",
})``;

const TextArea = styled(TextareaAutosize)`
  width: 100%;
  resize: none;
  ${props => utils.getFont("ui.small", null, props)}
  border: ${utils.getBorder("default")};
  border-radius: ${utils.getBorderRadius("default")};
  padding: 6px;
  min-height: 60px;

  &:placeholder {
    color: ${utils.getColor("grayLight")};
  }
`;

const ThemeTextArea: React.FC<Props> = ({
  newTheme,
  updateTheme,
  themeTextOption,
  placeholder = "Add a description",
}) => {
  const themeKeyMap: { [K in SpaceThemeTextOptions]: string } = useMemo(() => {
    return {
      [SpaceThemeTextOptions.description]: "description",
    };
  }, []);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      return updateTheme({
        [themeKeyMap[themeTextOption]]: e.currentTarget.value.replace(/\n/g, " "),
      });
    },
    [themeKeyMap, themeTextOption, updateTheme]
  );

  return (
    <Wrapper>
      <TextArea
        aria-label={`${themeTextOption} input`}
        onChange={handleChange}
        value={newTheme[themeKeyMap[themeTextOption]]}
        placeholder={placeholder}
      />
    </Wrapper>
  );
};

export default ThemeTextArea;
