import React from "react";
import styled from "styled-components";
import { Box, Text, Button, Tooltip } from "@thenounproject/lingo-core";

import { Inspectable } from "@constants/Inspector";
import { InspectorModes } from "@contexts/ViewModeContext";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";

const DisabledExports = styled.div`
  border: 1px dashed #dadada;
  padding: 10px;
`;

const ManageButton = styled(Button).attrs<{ mode: InspectorModes }>(props => {
  return {
    fontStyle: props.mode === "simple" ? "ui.smallBold" : "ui.regular",
    tintColor: props.mode === "simple" ? "white" : null,
    text: "Manage exports",
    buttonStyle: "tertiary",
    size: "small",
  };
})<{ mode: InspectorModes }>``;

type Props = {
  inspectable: Inspectable;
  mode: InspectorModes;
  canEdit: boolean;
};

const InspectorDownloadsDisabled: React.FC<Props> = ({ inspectable, canEdit, mode }) => {
  const { showModal } = useShowModal();

  const showFilecutModal = React.useCallback(
    () => showModal(ModalTypes.MANAGE_FILECUTS, { inspectable }),
    [showModal, inspectable]
  );

  const messaging = canEdit ? (
    <Text
      color={mode === "detailed" ? "black" : "white"}
      font={mode === "simple" ? "ui.small" : null}>
      All exports have been disabled for this asset.{" "}
      <ManageButton
        id="disabled-cuts-manage-button"
        data-test="open-filecut-manager"
        mode={mode}
        onClick={showFilecutModal}
      />
    </Text>
  ) : (
    <Text color={mode === "detailed" ? "black" : "white"}>
      All exports have been disabled for this asset. Contact your company’s admin for more
      information.
    </Text>
  );

  if (mode === "detailed") {
    return <DisabledExports>{messaging}</DisabledExports>;
  } else {
    const tooltipId = "disabled-download-tooltip";
    return (
      <Box data-tooltip-source={tooltipId}>
        <Tooltip maxWidth="240" source={tooltipId} direction={Tooltip.Direction.Top}>
          {messaging}
        </Tooltip>
        <Button
          id="asset-download-button"
          text="Download"
          icon="triangle-double"
          width="100%"
          disabled={true}
        />
      </Box>
    );
  }
};

export default InspectorDownloadsDisabled;
