import { createAsyncAction } from "../actionCreators";
import { API, camelize } from "@thenounproject/lingo-core";
import { FigmaIntegrationStatus, FigmaIntegrationTeam } from "./useFigmaIntegrationStatus";

type Args = {
  spaceId: number;
  teamId: string;
};

const [useRemoveFigmaTeam, removeFigmaTeam] = createAsyncAction(
  "figma/removeFigmaTeam",
  async ({ spaceId, teamId }: Args) => {
    const res = await API.call<{
      auth: FigmaIntegrationStatus["auth"];
      team: FigmaIntegrationTeam;
    }>({
      endpoint: `spaces/${spaceId}/figma/team/${teamId}`,
      method: "DELETE",
    });
    return { result: camelize(res.result) };
  }
);

export default useRemoveFigmaTeam;
export { removeFigmaTeam };
