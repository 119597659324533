import { Asset, AssetType, bytesToSize } from "@thenounproject/lingo-core";
import { getHexStringFromAsset } from "./getHexStringFromAsset";

type AssetFileDetailsConfig = {
  showColorValue?: boolean;
};

export default function assetFileDetails(asset: Asset, config?: AssetFileDetailsConfig) {
  const isColor = asset.type === AssetType.color;
  const isFont = asset.type === AssetType.textStyle;
  const isLink = asset.type === AssetType.URL;

  if (isColor) {
    let val = "Color";
    if (config?.showColorValue) {
      val += ` • ${getHexStringFromAsset(asset)}`;
    }
    return val;
  }

  if (isLink) {
    return `URL${asset.meta.content.fld ? ` • ${asset.meta.content.fld}` : ""}`;
  }

  const formattedSize = bytesToSize(asset.size);
  if (isFont) {
    // imported fonts from APIs like Google do not have a filesize
    let base = asset.meta.font.extension?.toUpperCase() || "Font";
    if (formattedSize !== "0 Bytes") base += ` • ${formattedSize}`;
    return base;
  }

  const type = AssetType.displayName(asset.type);
  if ([...AssetType.imageTypes, ...AssetType.sketchTypes].includes(asset.type)) {
    return `${type} • ${asset.dimensions}`;
  }

  return `${type} • ${formattedSize}`;
}
