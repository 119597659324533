import React, { CSSProperties } from "react";

import transparentBackground from "../../../../images/web-access/pattern@2x.png";
import { ThumbnailProps } from "./types";
import ThumbnailWrapper from "./ThumbnailWrapper";

const sharedStyles: CSSProperties = {
  width: `calc(100% - 8px)`,
  height: `calc(100% - 8px)`,
  position: "absolute",
  borderRadius: 4,
};

function ColorThumbnail({ asset }: ThumbnailProps) {
  return (
    <ThumbnailWrapper>
      <div
        style={{
          ...sharedStyles,
          background: `url(${transparentBackground})`,
        }}></div>
      <div
        style={{
          ...sharedStyles,
          zIndex: 1,
          backgroundColor: asset.colors[0].hex8 as string,
        }}></div>
    </ThumbnailWrapper>
  );
}

export default React.memo(ColorThumbnail);
