import { TinyColor } from "@ctrl/tinycolor";

type Props = {
  hex: string;
};

export function parseColorApproachingWhite({ hex }: Props) {
  const color = new TinyColor(hex);
  const white = new TinyColor("#ffffff");
  const rgb = color.toRgb();
  const whiteRgb = white.toRgb();
  const threshold = 30;

  // Calculate the Euclidean distance between the color and white
  const distance = Math.sqrt(
    Math.pow(whiteRgb.r - rgb.r, 2) +
      Math.pow(whiteRgb.g - rgb.g, 2) +
      Math.pow(whiteRgb.b - rgb.b, 2)
  );

  return distance < threshold;
}
