import { Space, API, Account, camelize } from "@thenounproject/lingo-core";
import { createQueryAction } from "@actions/actionCreators";

type Args = {
  spaceId: number;
};

const [useBillingData, fetchBillingData] = createQueryAction<Args, Account, Account>(
  {
    entity: "spaces",
    action: "fetchBillingData",
    denormalize: false,
  },
  async ({ args }) => {
    const res = await API.call<{ space: Space & Account }>({
      endpoint: `spaces/${args.spaceId}/account`,
      method: "GET",
    });
    const { balance, card, charges } = res.result.space;
    return {
      result: {
        balance: balance,
        card: card ? camelize(card) : null,
        charges: charges.map(c => camelize(c)),
      },
    };
  }
);

export default useBillingData;
export { fetchBillingData };
