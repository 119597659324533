import React, { useState, useCallback } from "react";
import styled from "styled-components";
import {
  Button,
  Text,
  Box,
  Flex,
  Textarea,
  Notice,
  ActivityIndicator,
} from "@thenounproject/lingo-core";

import CreditCardInput from "./CreditCardInput";
import ModalBody from "../ModalBody";
import ModalHeader from "../ModalHeader";
import ModalFooter from "../ModalFooter";
import { renderPlanCost } from "../../constants/plans";
import { analyzeChargePreview } from "@helpers/analyzeChargePreview";
import useUpdateSubscription from "@redux/actions/billing/useUpdateSubscription";
import useChargePreview from "@redux/actions/billing/useChargePreview";
import { Plan } from "@redux/actions/billing/useAvailablePlans";
import { useSelectSpace } from "@redux/selectors/entities/spaces";
import useBillingData from "@redux/actions/billing/useBillingData";

type Props = {
  spaceId: number;
  plan: Plan;
  onCompletion: () => void;
  inline?: boolean;
};

const ChargeLines = styled(Flex).attrs({
  flexDirection: "column",
  mt: "xl",
})``;

const ChargeLine = styled(Text).attrs<{ color: string }>({ mb: "s", color: "grayDarkest" })``;

const ChangeSubscriptionModal: React.FC<Props> = ({ spaceId, plan, onCompletion, inline }) => {
  const space = useSelectSpace(spaceId);
  const { data: accountData } = useBillingData({ spaceId }, { refetchOnMount: true });
  const accountCard = accountData?.card;
  const [error, setError] = useState(null),
    [processing, setProcessing] = useState(false),
    [replaceCard, setReplacingCard] = useState(false),
    [cardComplete, setCardComplete] = useState(false),
    [stripeToken, setStripeToken] = useState(null),
    [receiptExtra, setReceiptExtra] = useState(""),
    addingCard = replaceCard || !accountCard;

  const [updateSubscription] = useUpdateSubscription(),
    {
      status: previewStatus,
      data: chargePreview,
      error: previewError,
    } = useChargePreview({ spaceId: space.id, plan: plan.planId }, { refetchOnMount: true });

  const toggleCardInput = useCallback(() => {
    setStripeToken(null);
    setReplacingCard(!replaceCard);
    setError(null);
  }, [replaceCard]);

  // Handlers for stripe input
  const onCardChange = useCallback(isComplete => {
    setCardComplete(isComplete);
    setError(null);
    setStripeToken(null);
  }, []);

  const onCardError = useCallback(cardError => {
    setError(cardError.message);
  }, []);

  const onTokenCreated = useCallback(token => {
    setError(null);
    setStripeToken(token);
  }, []);

  const submit = useCallback(async () => {
    const card = addingCard ? stripeToken : accountCard;
    if (!card) return;

    setProcessing(true);
    const { error: responseError } = await updateSubscription({
      spaceId: space.id,
      plan: plan.planId,
      date: chargePreview.prorationDate,
      stripeToken,
      receiptExtra,
    });
    setProcessing(false);
    if (responseError) {
      setError(responseError.message || "Oops! That didn’t work.");
    } else {
      onCompletion();
    }
  }, [
    addingCard,
    updateSubscription,
    chargePreview?.prorationDate,
    onCompletion,
    plan.planId,
    receiptExtra,
    accountCard,
    space.id,
    stripeToken,
  ]);

  const openHelp = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    window?.Intercom("showNewMessage");
  }, []);

  function renderPlanDescription(userCount: number) {
    if (!plan) return null;
    const planCost = renderPlanCost(plan, userCount);
    return `${plan.name} (${planCost.fullPrice})`;
  }

  function renderChargePreview() {
    if (!chargePreview) return;

    const dollarFmt = cents => {
      return (cents / 100).toFixed(2);
    };

    const { newCharges, total } = chargePreview,
      { balance, creditApplied, willHaveCredit } = analyzeChargePreview(chargePreview);

    return (
      <ChargeLines>
        {creditApplied || balance ? (
          <ChargeLine>
            <strong>Amount due:</strong> ${dollarFmt(newCharges)}
          </ChargeLine>
        ) : null}
        {creditApplied ? (
          <ChargeLine>
            <strong>Credit applied:</strong> ${dollarFmt(creditApplied)}
          </ChargeLine>
        ) : null}
        {balance ? (
          <ChargeLine>
            <strong>Previous balance:</strong> ${dollarFmt(balance)}
          </ChargeLine>
        ) : null}
        <ChargeLine color="black" my="m">
          <strong>Total today:</strong> ${dollarFmt(Math.max(total, 0))}
        </ChargeLine>
        {willHaveCredit ? (
          <ChargeLine font="ui.small">
            <strong>Remaining credit (for next billing cycles):</strong> $
            {dollarFmt(Math.abs(total))}
          </ChargeLine>
        ) : null}
      </ChargeLines>
    );
  }

  function renderCard() {
    return (
      <Box data-testid="saved-card-info" textAlign="left">
        <Text>
          Charge to {accountCard.brand} on file
          <br />
          <span title={`Expiration: ${accountCard.expMonth} / ${accountCard.expYear}`}>
            &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull; &bull;&bull;&bull;&bull;{" "}
            {accountCard.last4}
          </span>
        </Text>
        <Button
          id="change-card-button"
          buttonStyle="tertiary"
          size="small"
          text="Replace card"
          onClick={toggleCardInput}
        />
      </Box>
    );
  }

  function renderCardInput() {
    return (
      <>
        <CreditCardInput
          onChange={onCardChange}
          onTokenCreated={onTokenCreated}
          onCardError={onCardError}
          error={error}
        />

        <Box textAlign="left">
          <Textarea
            mt="m"
            label="Extra receipt info"
            onChange={e => setReceiptExtra(e.target.value)}
            value={receiptExtra}
            placeholder="VAT No., Address, Job No., etc"
          />
          {accountCard && (
            <Button
              id="cancel-change-card-button"
              mt="s"
              buttonStyle="tertiary"
              size="small"
              text="Cancel and use existing card"
              onClick={toggleCardInput}
            />
          )}
        </Box>
      </>
    );
  }

  function renderBody() {
    if (previewStatus === "pending") {
      return (
        <Box height="150">
          <ActivityIndicator center />
        </Box>
      );
    } else if (previewError) {
      return (
        <Notice
          mb="l"
          message={previewError.message}
          noticeStyle="error"
          button={{
            text: "Contact us",
            onClick: openHelp,
          }}
        />
      );
    } else {
      const showCardOnFile = chargePreview && !addingCard,
        showCardInput = chargePreview && addingCard;
      return (
        <>
          {showCardOnFile ? renderCard() : null}
          {showCardInput ? renderCardInput() : null}
          {!showCardInput && error ? <Notice mt="l" message={error} noticeStyle="error" /> : null}
          {renderChargePreview()}
        </>
      );
    }
  }

  function renderFooter() {
    const canSubmit = !processing && previewStatus === "fulfilled" && (!addingCard || cardComplete);
    let buttonText = "Save plan";
    if (chargePreview?.total > 0) {
      buttonText = "Charge & upgrade";
    } else if (processing) {
      buttonText = "Processing...";
    }
    return (
      <ModalFooter
        primary={{
          id: "submit-button",
          text: buttonText,
          disabled: !canSubmit,
          onClick: submit,
        }}
        styleOverrides={inline ? { borderTop: "none", background: "none" } : {}}
      />
    );
  }

  return (
    <>
      <ModalHeader
        title="Finish upgrade"
        styleOverrides={{ borderBottom: inline ? "none" : "default" }}
        message={
          <>
            Upgrading <strong>{space.name}</strong> to{" "}
            <strong>{renderPlanDescription(space.counts.users)}</strong>
          </>
        }
      />

      <ModalBody>{renderBody()}</ModalBody>
      {renderFooter()}
    </>
  );
};

export default ChangeSubscriptionModal;
