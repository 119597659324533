/**
 * Contains form used to sign in with SSO
 */

import React, { useCallback } from "react";
import QueryString from "query-string";
import { Button, Box, buildURL, Notice, SpacePreview, Redirect } from "@thenounproject/lingo-core";

import { AuthFormWrapper, AuthFormMessage } from "./AuthElements";
import UserBox from "./UserBox";
import useAuthLinkSSO from "@redux/actions/auth/useLinkSSO";
import AuthFormTitle from "./AuthFormTitle";
import { AuthToken } from "./useAuthState";
import useAuthHandler from "./useAuthHandler";
import useLinkGoogle from "@redux/actions/auth/useLinkGoogle";
import { CurrentUser } from "@queries/useCurrentUser";

type Props = {
  user: CurrentUser;
  token: AuthToken;
  space: SpacePreview;
  responseHandler: ReturnType<typeof useAuthHandler>;
};

const AccountLinkConfirmation: React.FC<Props> = ({
  user,
  token: { token, type: tokenType } = {},
  space,
  responseHandler,
}) => {
  const [linkSSOAccount] = useAuthLinkSSO();
  const [linkGoogleAcount] = useLinkGoogle();
  const confirmLink = useCallback(() => {
    if (tokenType === "link-sso") {
      void responseHandler.process(() => linkSSOAccount({ token }));
    } else if (tokenType === "link-google") {
      void responseHandler.process(() => linkGoogleAcount({ token }));
    }
  }, [linkGoogleAcount, linkSSOAccount, responseHandler, token, tokenType]);

  const next = `${window.location.pathname}${window.location.search}`,
    queryString = QueryString.stringify({ next }),
    loginUrl = `/login/?${queryString}`;

  if (!user?.id) {
    return <Redirect to={loginUrl} />;
  }

  // If the sso token is not valid we either won't get the space preview back
  // OR we won't get it back with the members since that only happens for "invite" tokens
  // If that's the case we can assume the token we had is invalid
  if (tokenType === "link-sso" && !space?.members) {
    return <Redirect to="/error?code=8305" />;
  }
  const { error, isProcessing } = responseHandler;

  return (
    <AuthFormWrapper>
      {tokenType === "link-sso" && (
        <AuthFormTitle
          title="Enable SSO"
          message="Link your account to your SSO provider to enable logging in with SSO. Linking your account
          will allow your organization to manage it."
          space={space}
        />
      )}
      {tokenType === "link-google" && (
        <AuthFormTitle
          title="Link Google Account"
          message="Link your account to Google to enable Sign in with Google."
          space={space}
        />
      )}

      <UserBox user={user} />

      {error && <Notice noticeStyle="error" message={error.message} mt="m" />}

      <Box mt="xl">
        <Button
          id="link-account-button"
          disabled={isProcessing}
          fullWidth
          onClick={confirmLink}
          text="Link my account"
        />
        <Button
          disabled={isProcessing}
          mt="m"
          fullWidth
          buttonStyle="secondary"
          link={space ? buildURL("/dashboard", { space }) : "/space"}
          text="I will link my account later"
        />
      </Box>
      <AuthFormMessage>
        <Button
          disabled={isProcessing}
          buttonStyle="tertiary"
          size="small"
          text="Use a different account."
          link={loginUrl}
        />
      </AuthFormMessage>
    </AuthFormWrapper>
  );
};

export default AccountLinkConfirmation;
