import { AnyObject, API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

export type LoginArgs = {
  email?: string;
  password?: string;
  token?: string;
  otp?: string;
  queryString?: string;
};

const [useAuthLogin, login] = createAsyncAction("auth/login", async (args: LoginArgs) => {
  const endpoint = ["login/", args.queryString].filter(Boolean).join("");
  return await API.call<{
    user: number;
    intercom_id?: string;
    notifications?: AnyObject[];
  }>({
    endpoint,
    method: "POST",
    data: { email: args.email, password: args.password, token: args.token, otp: args.otp },
    entity: API.Entity.user,
  });
});
export default useAuthLogin;
export { login };
