import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Box, utils } from "@thenounproject/lingo-core";
import { useInView } from "react-intersection-observer";

type WrapperProps = {
  paddingBottom: string;
  highResImageLoaded: boolean;
  backgroundColor?: string;
} & utils.ThemedProps;

const LazyImageWrapper = styled(Box).attrs<WrapperProps>(props => {
  return {
    background: props.backgroundColor || "transparent",
    position: "relative",
    overflow: "hidden",
    pb: props.paddingBottom,
    ...props,
  };
})`
  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: opacity 0.4s;

    &.low-res-image {
      z-index: 10;
      opacity: ${(props: WrapperProps) => (props.highResImageLoaded ? 0 : 1)};
    }

    &.high-res-image {
      z-index: 5;
      opacity: ${(props: WrapperProps) => (props.highResImageLoaded ? 1 : 0)};
    }
  }
`;

type Props = {
  alt: string;
  imagePadding?: string;
  src: string;
  placeholderSrc?: string;
  backgroundColor?: string;
  aspectRatio?: number;
};

const LazyImage: React.FC<Props> = ({
  alt,
  imagePadding,
  src,
  placeholderSrc,
  backgroundColor,
  ...rest
}) => {
  const [showImage, setShowImage] = useState(false);
  const [highResImageLoaded, setHighResImageLoaded] = useState(false);

  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView && !showImage) setShowImage(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  const wrapperProps = {
    ref,
    paddingBottom: imagePadding,
    highResImageLoaded,
    backgroundColor,
    ...rest,
  };
  return (
    <LazyImageWrapper {...wrapperProps}>
      {showImage && (
        <img
          alt={alt}
          className="high-res-image"
          onLoad={() => {
            setHighResImageLoaded(true);
          }}
          src={src}
        />
      )}
      {placeholderSrc && <img alt={alt} className="low-res-image" src={placeholderSrc} />}
    </LazyImageWrapper>
  );
};

export default LazyImage;
