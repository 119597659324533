import { v4 as genUuid } from "uuid";

import { AnyObject, AssetType, ContentType, ErrorCode, ItemType } from "@thenounproject/lingo-core";

import {
  type InsertPosition,
  type Upload,
  type UploadAction,
  getItemData,
  generateInsertPositionForUploads,
} from "@actions/uploads";
import { fontFileExtensions } from "@constants/fontFileExtensions";
import { getFileName, typeErrorMessage } from "@helpers/files";
import formatBytes from "@helpers/bytesToSize";
import { createAsyncAction } from "../actionCreators";
import { fetchContentTypes } from "../useFetchContentTypes";
import { enqueueUpload } from "../uploads/enqueueUpload";

export enum AssetSourceVendors {
  Figma = "figma",
  GoogleFonts = "google-fonts",
  GoogleDrive = "google-drive",
  Dropbox = "dropbox",
}

export type ExternalSource = {
  source: AssetSourceVendors;
  name?: string;
  url?: string;
  authToken?: string;
  fileSize?: number;
};

type Args = {
  sources: ExternalSource[];
  insertPosition: InsertPosition;
  itemType?: ItemType;
};

const [useCreateAssetsFromSource, createAssetsFromSource] = createAsyncAction(
  "assets/createFromSource",
  async (
    { sources, insertPosition: startingInsertPosition, itemType = ItemType.asset }: Args,
    thunkApi
  ) => {
    const contentTypes = await fetchContentTypes.lazy(thunkApi, { itemType });
    const batchId = genUuid();
    const insertPosition = generateInsertPositionForUploads(sources.length, startingInsertPosition);

    // Queue the uploads
    sources
      .map(source => generateUploadAction(source, insertPosition, itemType, batchId, contentTypes))
      .forEach(uploadAction => thunkApi.dispatch(enqueueUpload(uploadAction)));
  }
);

export default useCreateAssetsFromSource;
export { createAssetsFromSource };

const generateUploadAction = (
  source: ExternalSource,
  insertPosition: InsertPosition,
  itemType: ItemType,
  batchId: string,
  contentTypes: ContentType[]
): UploadAction => {
  const { item, entity } = getItemData(insertPosition);

  const upload: Upload = {
      id: genUuid().toUpperCase(),
      /**
       * If no batch ID is provided, it's likely a one-off upload.
       * This is currently used by the Figma modal to either insert a
       * single Figma frame or fetch the contents of an entire page.
       */
      batchId: batchId || undefined,
      status: "pending",
      type: source.source,
      name: source.name,
      size: source.fileSize ?? 0,
      insertPosition,
      isAsset: itemType === ItemType.asset,
    },
    asset = {
      auth_token: source.authToken,
      meta: {} as AnyObject,
      space_id: insertPosition.spaceId,
      item,
    } as AnyObject;
  switch (source.source) {
    case AssetSourceVendors.Figma:
      asset.source = source.source;
      upload.type = "Figma asset";
      upload.name = source.name;
      asset.meta.figma = { url: source.url };
      break;
    case AssetSourceVendors.GoogleFonts:
      asset.source = source.source;
      upload.type = "Google font";
      upload.name = source.name;
      asset.meta.font = { font_name: source.name };
      break;
    case AssetSourceVendors.GoogleDrive:
      upload.type = "Google Drive";
      processExternalFile(source, upload, asset, contentTypes);
      asset.source_headers = { Authorization: `Bearer ${source.authToken}` };
      break;
    case AssetSourceVendors.Dropbox:
      upload.type = "Dropbox";
      processExternalFile(source, upload, asset, contentTypes);
      break;
  }
  return {
    upload,
    requestData: {
      data: asset,
      endpoint: "assets",
      method: "POST",
      entity: entity,
    },
  };
};

function processExternalFile(
  source: ExternalSource,
  upload: Upload,
  asset: AnyObject,
  contentTypes: ContentType[]
) {
  upload.name = source.name;
  asset.source_url = source.url;
  const { name, extension: fileExtension } = getFileName(source.name);

  if (fontFileExtensions.includes(fileExtension)) {
    asset.type = AssetType.textStyle;
    asset.meta = { font: { extension: fileExtension } };
    return upload;
  }

  const type = contentTypes.find(
    t => t.fileExtension.toLowerCase() === fileExtension || t.aliases.includes(fileExtension)
  );

  if (!type) {
    // We couldn't determine the file type
    upload.status = "failed";
    upload.error = {
      code: ErrorCode.invalidParams,
      message: typeErrorMessage(contentTypes),
    };
  } else if (type.maxSize && source.fileSize > type.maxSize) {
    // File too large (some sources like google drive provide a size)
    upload.status = "failed";
    upload.error = {
      code: ErrorCode.fileTooLarge,
      message: `The file must be smaller than ${formatBytes(type.maxSize)}.`,
    };
  } else if (type.assetType === AssetType.textStyle) {
    // e.g. Google fonts
    asset.type = AssetType.textStyle;
    asset.meta = { font: { extension: fileExtension } };
  } else {
    asset.type = type.assetType;
    asset.name = name;
  }
}
