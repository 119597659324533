import React from "react";
import styled, { useTheme } from "styled-components";

import { Flex, Button, Text, utils, styles, FlexProps, BoxProps } from "@thenounproject/lingo-core";

type WrapperProps = {
  secondaryStyle: boolean;
};
const TagPillWrapper = styled(Flex).attrs<WrapperProps>(props => {
  return {
    as: "li",
    mr: "xxs",
    mt: "xs",
    borderRadius: utils.getBorderRadius(props?.theme?.themeName, "default"),
    background: props.secondaryStyle ? styles.color.grayLight : props.theme.primaryColor,
    transition: "all 0.3s ease",
    height: "24px",
    overflow: "hidden",
    alignItems: "center",
    ...props,
  };
})<WrapperProps>``;

const TagPillButton = styled(Button).attrs<WrapperProps>(props => {
  return {
    borderRadius: "none",
    fontStyle: "ui.small",
    height: "24px",
    py: 0,
    px: "s",
    buttonStyle: props.secondaryStyle ? "secondary" : "primary",
  };
})<WrapperProps>``;

const TagPillText = styled(Text).attrs(() => {
  return {
    font: "ui.small",
    px: "s",
  };
})``;

const TagPill = styled(Button).attrs<WrapperProps>(props => {
  return {
    borderRadius: "none",
    height: "24px",
    width: "24px",
    p: 0,
    buttonStyle: props.secondaryStyle ? "secondary" : "primary",
  };
})<WrapperProps>`
  svg {
    width: 16px;
    height: 16px;
  }
`;

type Props = {
  tag: string;
  onClick?: () => any;
  onDelete?: () => any;
  onAdd?: () => any;
  theme?: any;
  canEdit?: boolean;
  disabled?: boolean;
  styleOverrides?: BoxProps & FlexProps;
};

function InspectorTagPill({
  tag,
  onClick,
  onDelete,
  onAdd,
  canEdit = false,
  disabled,
  styleOverrides,
}: Props) {
  const theme = useTheme();

  function renderActionButton() {
    if (!onAdd && !onDelete) return null;
    if (!canEdit) return null;

    let actionButtonProps;
    if (onDelete) {
      actionButtonProps = {
        title: "Remove tag",
        onClick: onDelete,
        icon: "close",
      };
    } else if (onAdd) {
      actionButtonProps = {
        title: "Add tag",
        onClick: onAdd,
        icon: "action.add",
        secondaryStyle: true,
      };
    }
    return <TagPill disabled={disabled} {...actionButtonProps} />;
  }

  return (
    <TagPillWrapper data-testid="tag-pill" secondaryStyle={Boolean(onAdd)} {...styleOverrides}>
      {onClick && !disabled ? (
        <TagPillButton text={tag} onClick={onClick} secondaryStyle={Boolean(onAdd)} />
      ) : (
        <TagPillText color={utils.getColor(theme.primaryColorTint, "white")}>{tag}</TagPillText>
      )}
      {renderActionButton()}
    </TagPillWrapper>
  );
}
export default InspectorTagPill;
