import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Box, Text, Button, ActivityIndicator, useNavigation } from "@thenounproject/lingo-core";

import { AuthContainer, AuthContent } from "./AuthElements";
import EmptyState from "../EmptyState";
import useGuestLogin from "@redux/actions/auth/useGuestLogin";
import useSearchParams from "@hooks/useSearchParams";

export default function AuthGuestLogin() {
  const [loginGuest] = useGuestLogin();
  const [error, setError] = useState<string>();
  const params = useParams<{ token: string }>();

  const navigation = useNavigation();
  const searchParams = useSearchParams();
  const nextPath = searchParams.get("next");

  useEffect(() => {
    if (!params.token) return setError("No token provided");
    if (!nextPath) return setError("No next path provided");

    const login = async () => {
      const { response, error } = await loginGuest({ token: params.token });
      if (error) return setError(error.message);
      if (response.result.guest) {
        navigation.push(nextPath);
      }
    };
    void login();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContainer>
      <AuthContent withHeader styleOverrides={{ flexDirection: "column", textAlign: "center" }}>
        {!error && (
          <Box>
            <ActivityIndicator size="large" height={"200px"} center />
          </Box>
        )}
        {error && (
          <EmptyState
            title="Something went wrong"
            subtitle={error}
            size="large"
            extra={
              <>
                <Text mt="l" font="ui.small">
                  If you need help, please{" "}
                  <Button
                    buttonStyle="tertiary"
                    size="small"
                    text="contact us."
                    link="mailto:info@lingoapp.com"
                  />
                </Text>
              </>
            }
          />
        )}
      </AuthContent>
    </AuthContainer>
  );
}
