export default function formatBytes(bytes: number): string {
  if (!bytes) return "0 Bytes";
  if (bytes === 1) return "1 Byte";
  const k = 1000;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const decimals = 0;
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const val = parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)),
    unit = sizes[i];
  return `${val} ${unit}`;
}
