import { API, ItemType, Item } from "@thenounproject/lingo-core";

import { createAsyncAction } from "../actionCreators";
import { fetchContentTypes } from "../useFetchContentTypes";
import { type UploadAction, uploadActionForFile } from "../uploads";
import { enqueueUpload } from "../uploads/enqueueUpload";

type Args = {
  item: Item;
  file: File;
};
/**
 * Add an asset to an existing item that has no asset yet.
 * Currently, this situation could only really be applicable to guides.
 */
const [useCreateItemAsset, createItemAsset] = createAsyncAction(
  "assets/createItemAsset",
  async ({ item, file }: Args, thunkApi) => {
    const contentTypes = await fetchContentTypes.lazy(thunkApi, {
      itemType: item.type ?? ItemType.asset,
      assetType: item.asset?.type,
    });

    // Provide the kitId & sectionId for the upload modal details
    const { upload, asset } = uploadActionForFile(file, contentTypes, {
      kitId: item.kitId,
      sectionId: item.sectionId,
    });

    asset.item = { uuid: item.id, kit_uuid: item.kitId, type: item.type };

    const uploadAction: UploadAction = {
      upload,
      requestData: {
        endpoint: `assets`,
        entity: API.Entity.item,
        method: "POST",
        file: file,
        data: asset,
      },
    };

    thunkApi.dispatch(enqueueUpload(uploadAction));
  }
);
export default useCreateItemAsset;
export { createItemAsset };
