import { PageContent } from "../../pages/templates/PageLayout";

const content: PageContent = {
  style: "lightGradient",
  sections: [
    {
      type: "FormSection",
      title: "Take Lingo for a spin",
      subtitle:
        "Please provide your contact information and we'll get you set up with a free, fully featured 30 day trial.",
      badge: "Request a free trial",
      form: "trial",
      position: { mb: "none", pb: "200" },
    },
    { type: "CustomerLogosSection", position: { mt: "-xxl" } },
  ],
};

export default content;
