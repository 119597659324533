// Modal reducer is used for showing & hiding modals, as well as passing data to modals. For a
// modal to be rendered, it must have a constant mapped to the component in constants/modalTypes.
//
// []component: constant representing component to be rendered
// []props: single object to be passed to modal component as props

import { dismissModal, ModalTypes, showModal } from "@redux/actions/useModals";
import { createReducer } from "@reduxjs/toolkit";
import { ComponentProps } from "react";
import { modalTypes } from "@constants/modalTypes";

type Modal<T extends ModalTypes = ModalTypes> = {
  component: T;
  props: ComponentProps<(typeof modalTypes)[T]>;
};
export default createReducer([] as Modal[], builder => {
  builder
    .addCase(showModal, (state, action) => {
      const newModal = {
        component: action.payload.component,
        props: action.payload.props,
      };
      return action.payload.closeAll ? [newModal] : [...state, newModal];
    })
    .addCase(dismissModal, (state, action) => {
      const { ofType } = action.payload;
      if (typeof ofType === "boolean" && ofType === true) {
        return [];
      } else if (typeof ofType === "string" && ModalTypes[ofType]) {
        return state.filter(modal => modal.component !== ofType);
      } else {
        return state.slice(0, -1);
      }
    });
});
