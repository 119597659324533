import React from "react";

import Heading from "../Heading";
import KitHeaderActions from "./KitHeaderActions";
import KitTrashedItemActions from "./KitRecentlyDeletedItemActions";
import KitRecoveredAssetsActions from "./KitRecoveredItemActions";
import { PageContentTypes } from "./Kit";
import HeaderAvatar from "../HeaderAvatar";
import { Kit, KitVersion, Flex, Space, Portal } from "@thenounproject/lingo-core";

type Props = {
  kit: Kit;
  space: Space;
  portal?: Portal;
  kitVersion: KitVersion;
  pageContentType: PageContentTypes;
  selectedItems: string[];
  clearSelection: () => void;
};

const KitTitle = ({
  kit,
  portal,
  kitVersion,
  pageContentType,
  selectedItems,
  clearSelection,
  space,
}: Props) => {
  if (!kit) {
    return null;
  }

  const content = () => {
    if (pageContentType === PageContentTypes.trashedItems) {
      return (
        <KitTrashedItemActions
          kit={kit}
          kitVersion={kitVersion}
          selectedItems={selectedItems}
          clearSelection={clearSelection}
        />
      );
    } else if (pageContentType === PageContentTypes.recoveredAssets) {
      return (
        <KitRecoveredAssetsActions
          kit={kit}
          kitVersion={kitVersion}
          selectedItems={selectedItems}
        />
      );
    } else {
      return <KitHeaderActions kit={kit} space={space} portal={portal} kitVersion={kitVersion} />;
    }
  };

  return (
    <Heading
      styleOverrides={{
        variations: { "mq.s": { display: "none !important" } },
        borderBottom: "default",
      }}>
      <Flex alignItems="center" justifyContent="space-between">
        <HeaderAvatar />
        {content()}
      </Flex>
    </Heading>
  );
};

export default KitTitle;
