import { createAction } from "@actions/actionCreators";

const [useClearRecentSearches, clearRecentSearches] = createAction(
  "search/clearRecentSearches",
  () => {
    // Ensure that click events aren't passed in
    return { payload: null };
  }
);

export default useClearRecentSearches;
export { clearRecentSearches };
