import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  kitId: string;
  spaceId: number;
};

// We should consider merging this for shared and owned kits.
const [useRemoveSharedKit, removeSharedKit] = createAsyncAction(
  "kits/removeShared",
  (args: Args) => {
    return API.call<string>({
      endpoint: `kits/${args.spaceId}/${args.kitId}`,
      method: "DELETE",
      entity: API.Entity.kit,
    });
  }
);

export default useRemoveSharedKit;
export { removeSharedKit };
