import _sortBy from "lodash/sortBy";

import type { AppDispatch, RootState } from "@redux/store";

import * as actionTypes from "@redux/legacy-actions/actionTypes";
import { Repo } from "@thenounproject/lingo-core";

export const sortSectionItems =
  (sectionIds: string[]) => (dispatch: AppDispatch, getState: () => RootState) => {
    const repo = new Repo(getState());
    return dispatch({
      type: actionTypes.SORT_SECTION_ITEMS,
      sections: sectionIds.reduce((acc, sId) => {
        acc[`${sId}-0`] = {
          items: _sortBy(repo.getSection(sId, 0).items, [
            item => repo.getItem(item, 0).displayOrder,
          ]),
        };
        return acc;
      }, {}),
    });
  };
