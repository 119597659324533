import { API, SpacePreview } from "@thenounproject/lingo-core";
import { createQueryAction } from "../actionCreators";

const [useJoinableSpaces] = createQueryAction<void, SpacePreview[], SpacePreview[]>(
  {
    entity: "spaces",
    action: "fetchJoinable",
    denormalize: false,
  },
  async () => {
    return await API.call<SpacePreview[]>({
      endpoint: `me/join-spaces`,
      method: "GET",
      entity: API.Entity.spacePreview,
    });
  }
);
export default useJoinableSpaces;
