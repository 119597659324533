import React, { useCallback } from "react";
import { Text, Input, CustomField, CustomFieldOperation } from "@thenounproject/lingo-core";

import useAssetsCustomFieldUpdate from "@redux/actions/assets/useAssetsCustomFieldUpdate";
import { clone } from "lodash";

export interface Props {
  field: CustomField;
  updateCustomFieldValue: ReturnType<typeof useAssetsCustomFieldUpdate>[0];
  assetFieldValue: string;
  canEdit: boolean;
  assetUuids: string[];
}

export default function TextCustomField({
  field,
  updateCustomFieldValue,
  assetFieldValue,
  canEdit,
  assetUuids,
}: Props) {
  const [value, setValue] = React.useState<string>(String(clone(assetFieldValue) || ""));
  const [error, setError] = React.useState<string>();

  React.useEffect(() => {
    if (assetFieldValue !== value) setValue(assetFieldValue || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assetFieldValue]);

  const handleValueUpdate = useCallback(
    async (fieldId: number, value: string) => {
      const { error } = await updateCustomFieldValue({
        assetUuids,
        fieldId,
        value: value || null,
        operation: CustomFieldOperation.set,
      });
      if (error) {
        setError(error.message);
      }
    },
    [assetUuids, updateCustomFieldValue]
  );

  const handleSave = useCallback(async () => {
    if (value !== assetFieldValue) {
      await handleValueUpdate(field.id, value);
    }
  }, [assetFieldValue, field.id, handleValueUpdate, value]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);

  if (!canEdit && field.public) {
    return <Text font="ui.small">{assetFieldValue || "None"}</Text>;
  }

  return (
    <Input
      data-testid="inspector-text-field"
      size="small"
      placeholder={"Add some text"}
      value={value}
      onChange={handleChange}
      onSubmit={handleSave}
      onBlur={handleSave}
      type={"text"}
      inputStyle={error ? "error" : null}
      message={error}
    />
  );
}
