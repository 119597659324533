import { API, APIResultList, Asset, Kit, Section } from "@thenounproject/lingo-core";

import { createAsyncAction } from "../actionCreators";
import { v4 as genUUID } from "uuid";

type Args = {
  assetIds: Asset["id"][];
  kitId: Kit["kitId"];
  sectionId: Section["id"];
};

const [useCreateItemsFromAssets, createItemsFromAssets] = createAsyncAction(
  "items/createFromAssets",
  async (args: Args) => {
    const data = {
      items: args.assetIds.map(asset_uuid => ({
        uuid: genUUID().toUpperCase(),
        asset_uuid,
        kit_uuid: args.kitId,
        section_uuid: args.sectionId,
        type: "asset",
      })),
    };
    return await API.call<APIResultList<"items", string>>({
      endpoint: `sync/items`,
      method: "POST",
      entity: API.Entity.item,
      data,
    });
  }
);
export default useCreateItemsFromAssets;
export { createItemsFromAssets };
