import React from "react";
import styled from "styled-components";
import { Button, Flex, Text, Asset } from "@thenounproject/lingo-core";

import AssetDownloadOptionThumbnail from "./AssetDownloadOptionThumbnail";
import ExportOption from "./ExportOption";
import { DownloadAsset } from "./AssetDownloadPopup";

const MetaDetailWrapper = styled(Flex).attrs({
  height: "40px",
  flexDirection: "column",
  justifyContent: "center",
})``;

const Title = styled(Text).attrs({
  font: "ui.small",
  truncate: true,
  width: "160px",
})``;
const Subtitle = styled(Text).attrs({
  font: "ui.small",
  truncate: true,
  width: "160px",
  color: "grayDarker",
})``;

type Props = {
  asset: Asset;
  type: string;
  description: string;
  size: string;
  dpi: number;
  downloadAsset: DownloadAsset;
};

const PresetExportOption: React.FC<Props> = ({
  asset,
  type,
  description,
  size,
  dpi,
  downloadAsset,
}) => {
  return (
    <ExportOption>
      <Flex>
        <AssetDownloadOptionThumbnail asset={asset} />
        <MetaDetailWrapper>
          <Title>
            {type} {description && <strong title={description}>{description}</strong>}
          </Title>
          <Subtitle font="ui.small">{size}</Subtitle>
        </MetaDetailWrapper>
      </Flex>
      <Button
        id={`dl-button-${type.toLowerCase()}-${size}-${dpi || 0}`}
        aria-label={`Download ${type} ${size}`}
        onClick={() => downloadAsset(type, size, dpi)}
        data-hover="shown"
        icon="download"
      />
    </ExportOption>
  );
};

export default PresetExportOption;
