import { noop } from "lodash";
import React from "react";

export type SelectionType = "kitGallery" | "assetLibrary" | "tagLibrary" | "portal" | "dashboard";

export type SelectionUpdateInput = {
  type: SelectionType;
  ids?: string[];
};
export type SelectionState = {
  kitGallery: string[];
  assetLibrary: string[];
  tagLibrary: string[];
  portal: string[];
  dashboard: string[];
};

export type SelectionContextType = {
  selectionState: SelectionState;
  selectItems: ({ type, ids }: SelectionUpdateInput) => void;
  deselectItems: ({ type, ids }: SelectionUpdateInput) => void;
  clearSelection: ({ type }: SelectionUpdateInput) => void;
};

export const initialState: SelectionState = {
  kitGallery: [],
  assetLibrary: [],
  tagLibrary: [],
  portal: [],
  dashboard: [],
};

export const SelectionContext = React.createContext<SelectionContextType>({
  selectionState: initialState,
  selectItems: noop,
  deselectItems: noop,
  clearSelection: noop,
});

SelectionContext.displayName = "SelectionContext";
