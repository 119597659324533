import React, { ComponentProps } from "react";
import * as Sentry from "@sentry/react";
import QueryString from "query-string";
import { Box, Text, Button, Icon } from "@thenounproject/lingo-core";

function DebugError() {
  const { debugErrorBoundary } = QueryString.parse(window.location.search);
  if (debugErrorBoundary) {
    throw Error("Nothing to see here");
  }
  return null;
}

type Props = ComponentProps<typeof Sentry.ErrorBoundary>;

const ErrorBoundary: React.FC<Props> = ({ children, ...props }) => {
  function renderError() {
    return (
      <Box p="m" textAlign="center" top="25%" height="100%" width="100%">
        <Icon iconId="error" size="60" fill="error" mb="12px" />
        <Text as="h1" font="ui.title">
          Hrmm... something broke.
        </Text>
        <Text mt="12px">
          Our team has been notified. If the problem continues, please{" "}
          <Button
            text="let us know"
            buttonStyle="tertiary"
            link="mailto:info@lingoapp.com"
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              if (window.Intercom) {
                event.preventDefault();
                window.Intercom("showNewMessage");
              }
            }}
          />
          .
        </Text>
        <Button
          mt="12px"
          text="Reload"
          onClick={() => {
            window.location.reload();
          }}
        />
      </Box>
    );
  }
  return (
    <Sentry.ErrorBoundary fallback={renderError} {...props}>
      <>
        <DebugError />
        {children}
      </>
    </Sentry.ErrorBoundary>
  );
};

export default ErrorBoundary;
