import { PageContent } from "../pages/templates/PageLayout";

import Images from "./images";
import { MarketingButtons } from "../components/MarketingButtons";

const content: PageContent = {
  style: "dark",
  sections: [
    {
      type: "HeroSection",
      title: "Lingo Partnerships",
      style: "dark",
      subtitle:
        "Are you an agency looking to help your client move beyond the static PDF brand guideline? Lingo can help you accomplish this, and you can earn money.",
      image: {
        src: Images.Affiliate.hero,
        alt: "",
      },
      primaryButton: MarketingButtons.contact,
    },
    { type: "CustomerLogosSection" },
    {
      type: "MediaSection",
      title: "How it works",
      subtitle:
        "Become a Lingo affiliate partner, and earn money every time you refer a new Lingo customer.",
      image: {
        src: Images.Affiliate.content,
        alt: "Slide 1",
      },
      layoutDirection: "image-left",
      body: {
        style: "iconList",
        items: [
          {
            title: "Apply to become a partner",
            body: "Our application process is fast, and begins with [contacting our team](/contact)",
          },
          {
            title: "Get your clients using Lingo",
            body: "Use Lingo to organize and distribute your clients' brand systems.",
          },
          {
            title: "Earn money",
            body: "Once your client becomes a Lingo customer, you'll get 40% of what they pay us.",
          },
          {
            title: "Partnership program",
            body: "Promote Lingo on your site and get paid anytime you refer a customer. [Become an affiliate](https://signup.cj.com/member/signup/publisher/?cid=6564185#/branded)",
          },
        ],
      },
    },
    {
      type: "ShowcaseSection",
      title: "Showcase your brand in Lingo",
      image: {
        src: Images.Home.showcase,
        alt: "Slide 1",
      },
    },
  ],
};
export default content;
