import React, { useCallback } from "react";
import {
  Text,
  DropdownButton,
  PopupMenu,
  useBoolean,
  CustomField,
  CustomFieldOperation,
  Asset,
} from "@thenounproject/lingo-core";
import useNotifications from "@actions/useNotifications";

import useAssetsCustomFieldUpdate from "@redux/actions/assets/useAssetsCustomFieldUpdate";

export type Props = {
  assets: Asset[];
  field: CustomField;
  assetUuids: string[];
  updateCustomFieldValue: ReturnType<typeof useAssetsCustomFieldUpdate>[0];
  canEdit: boolean;
};

const SelectCustomFieldMulti = ({
  assets,
  field,
  assetUuids,
  updateCustomFieldValue,
  canEdit,
}: Props) => {
  const assetFieldValues = assets.map(asset => asset.fields?.[field.id]?.[0]);
  const fieldValuesAreEqual = assetFieldValues.every(
    fieldValue => fieldValue === assetFieldValues[0]
  );
  const selectedOptionName = field.options?.find(
    option => option.id === Number(assetFieldValues[0])
  )?.name;
  const [menuShown, showMenu, hideMenu] = useBoolean(false);
  const { showNotification } = useNotifications();

  const handleFieldOptionUpdate = useCallback(
    async (fieldId: number, optionId: number, operation: CustomFieldOperation) => {
      const { error } = await updateCustomFieldValue({
        assetUuids,
        fieldId,
        optionId,
        operation,
      });
      if (error) {
        showNotification({ message: error.message, level: "error" });
      }
    },
    [assetUuids, updateCustomFieldValue, showNotification]
  );

  if (!canEdit)
    return (
      <Text font="ui.small">{!fieldValuesAreEqual ? "Varied" : selectedOptionName || "None"}</Text>
    );

  return (
    <>
      <DropdownButton
        data-testid="inspector-select-field"
        text={!fieldValuesAreEqual ? "Varied" : selectedOptionName || "None"}
        styleOverrides={{ width: "100%" }}
        data-popup-source={`${field.name}-dropdown`}
        onClick={showMenu}
      />
      <PopupMenu source={`${field.name}-dropdown`} open={menuShown} close={hideMenu}>
        <PopupMenu.Item
          key="none"
          title="None"
          onClick={() => handleFieldOptionUpdate(field.id, null, CustomFieldOperation.remove)}
          checked={assetFieldValues?.includes(undefined)}
        />
        {field.options?.map(o => (
          <PopupMenu.Item
            key={o.id}
            title={o.name}
            onClick={() => handleFieldOptionUpdate(field.id, o.id, CustomFieldOperation.add)}
            checked={assetFieldValues?.includes(String(o.id))}
          />
        ))}
      </PopupMenu>
    </>
  );
};

export default SelectCustomFieldMulti;
