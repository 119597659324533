import React from "react";
import styled from "styled-components";
import { Box, Text, Flex, AssetType, Button, Item } from "@thenounproject/lingo-core";

import ColorAssetCopyPopup from "../inspector/use/ColorAssetCopyPopup";
import GalleryAssetErrorButton from "./GalleryAssetErrorButton";
import { getAssetError } from "@helpers/getAssetError";
import assetFileDetails from "./GalleryAssetContent/assetFileDetails";

type WrapperProps = {
  hasError: boolean;
};

const HiddenWrapper = styled(Flex).attrs<WrapperProps>(props => {
  return { style: { opacity: props.hasError ? 1 : 0 } };
})<WrapperProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateY(100%);
  transition: opacity 0.3s ease;
`;

const DetailedWrapper = styled(Flex).attrs<WrapperProps>(props => {
  return { style: { paddingRight: props.hasError ? "36px" : 0 } };
})<WrapperProps>`
  position: relative;
  padding-right: 0;
  transition: padding-right 0.3s ease;
`;

const DetailedButton = styled(Button).attrs({
  size: "small",
  position: "absolute",
  right: 0,
  top: "4px",
  className: "action-button",
  themeOverrides: {
    primaryColor: "black",
    primaryColorTint: "white",
  },
})`
  opacity: 0;
`;

const Wrapper = styled(Box)`
  position: relative;

  &:hover {
    ${HiddenWrapper} {
      opacity: 1 !important;
    }
    ${DetailedWrapper} {
      padding-right: 36px !important;
    }
    .action-button {
      opacity: 1;
    }
  }
`;

type Props = {
  item: Item;
  assetViewMode: string;
  downloadButton: React.ReactNode;
  children: React.ReactNode;
  canEdit: boolean;
};

function GalleryAssetMeta({
  item,
  assetViewMode,
  downloadButton,
  children,
  canEdit = false,
}: Props) {
  const isDetailedMode = assetViewMode === "detailed";
  const error = getAssetError(item?.asset);

  const assetErrorButtonProps = {
    itemId: item.id,
    assetId: item.asset.id,
    error,
    compact: !isDetailedMode,
    canEdit,
  };

  const isColor = item.asset.type === AssetType.color,
    isFont = item.asset.type === AssetType.textStyle,
    isLink = item.asset.type === AssetType.URL;

  function getDownloadIcon() {
    switch (item.asset.type) {
      case AssetType.color:
        return "action.copy";
      case AssetType.URL:
        return "action.external-link";
      default:
        return "action.download";
    }
  }

  function renderFileName() {
    if (isColor) return item.asset.name || item.asset.colors[0].name;
    if (isFont) return item.asset.name || item.asset.meta.font.family;
    return item.asset.name || "Untitled asset";
  }

  const sharedLinkButtonProps = {
    icon: getDownloadIcon(),
    onClick: e => {
      const url = item.asset.meta.content?.url;
      if (e) e.stopPropagation();
      if (url) window.open(url, "_blank");
    },
  };

  function renderHiddenDownloadOrCopyButton() {
    if (isColor)
      return (
        <ColorAssetCopyPopup
          inspectable={{ item, asset: item.asset }}
          popupVPos="alignTop"
          popupHPos="floatLeft">
          {buttonProps => {
            return (
              <Button
                buttonStyle="tertiary"
                size="small"
                icon={getDownloadIcon()}
                {...buttonProps}
              />
            );
          }}
        </ColorAssetCopyPopup>
      );
    if (isLink) return <Button buttonStyle="tertiary" size="small" {...sharedLinkButtonProps} />;

    if (error) return <GalleryAssetErrorButton {...assetErrorButtonProps} />;
    return downloadButton;
  }

  function renderDetailedDownloadOrCopyButton() {
    if (isColor)
      return (
        <ColorAssetCopyPopup
          inspectable={{ item, asset: item.asset }}
          popupVPos="alignTop"
          popupHPos="floatLeft">
          {buttonProps => {
            return <DetailedButton icon={getDownloadIcon()} {...buttonProps} />;
          }}
        </ColorAssetCopyPopup>
      );
    if (isLink) return <DetailedButton {...sharedLinkButtonProps} />;

    if (error) return <GalleryAssetErrorButton {...assetErrorButtonProps} />;
    return downloadButton;
  }

  return (
    <Wrapper>
      {children}
      {isDetailedMode ? (
        <DetailedWrapper hasError={Boolean(error)} flexDirection="column" mt="4px" px="xs">
          <Text font="ui.small" truncate>
            {renderFileName()}
          </Text>
          <Text color="grayDarkest" font="ui.small" truncate>
            {assetFileDetails(item.asset)}
          </Text>
          {renderDetailedDownloadOrCopyButton()}
        </DetailedWrapper>
      ) : (
        <HiddenWrapper
          hasError={Boolean(error)}
          justifyContent="space-between"
          alignItems="center"
          px="xs">
          <Text font="ui.small" truncate>
            {renderFileName()}
          </Text>
          <Flex>{renderHiddenDownloadOrCopyButton()}</Flex>
        </HiddenWrapper>
      )}
    </Wrapper>
  );
}

export default GalleryAssetMeta;
