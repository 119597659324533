import { useMemo } from "react";
import useSearchParams from "./useSearchParams";

function useSearchQuery<ReturnType = string>(
  key: string,
  parser?: (val: string) => ReturnType
): ReturnType {
  const params = useSearchParams();
  return useMemo(() => {
    const val = params.get(key);
    return (parser ? parser(val) : val) as ReturnType;
  }, [params, key, parser]);
}

export default useSearchQuery;
