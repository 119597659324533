import { API } from "@thenounproject/lingo-core";

import { createAsyncAction } from "../actionCreators";

/**
 * Delete all items for the provided assets. Multiple asset ids can be provided allowing for all items for multiple assets to be deleted in one request.
 * @returns
 */

type Args = {
  userId: number;
  spaceId: number;
  kitId: string;
};

const [useRemoveKitMember, removeKitMember] = createAsyncAction(
  "kitMembers/delete",
  async ({ kitId, userId, spaceId }: Args) => {
    return await API.call<string>({
      endpoint: `kits/${spaceId}/${kitId}/members/${userId}`,
      method: "DELETE",
      entity: API.Entity.kitMember,
    });
  }
);
export default useRemoveKitMember;
export { removeKitMember };
