/**
 * Contains form used to create a Lingo account.
 */
import React, { useCallback, useState } from "react";
import {
  Button,
  Input,
  Checkbox,
  Text,
  buildURL,
  Notice,
  Flex,
  useBoolean,
  SpacePreview,
  Box,
} from "@thenounproject/lingo-core";

import { AuthFormWrapper, authButtonStyle } from "./AuthElements";
import useSignup from "@redux/actions/auth/useSignup";
import GoogleSignInButton, { GoogleAuthResponse } from "./GoogleSignInButton";
import AuthFormTitle from "./AuthFormTitle";
import useAuthHandler from "./useAuthHandler";
import useLoginWithGoogleToken from "@redux/actions/auth/useLoginWithGoogleToken";
import SpacePreviewTitle from "./SpacePreviewTitle";

type Props = {
  token?: { token: string; type: string };
  space?: SpacePreview;
  responseHandler: ReturnType<typeof useAuthHandler>;
};

export default function SignupForm({ responseHandler, space, token }: Props) {
  const [name, setName] = useState(""),
    [email, setEmail] = useState(""),
    [password, setPassword] = useState(""),
    [productEmailOptIn, setProductEmailOptIn] = useState(true),
    [newsletterEmailOptIn, setNewsletterEmailOptIn] = useState(false),
    [touAgree, setTouAgree] = useState(false),
    [isFormShown, showForm] = useBoolean(false),
    queryString = window.location.search || "";

  const [signup] = useSignup(),
    onSignup = useCallback(async () => {
      await responseHandler.process(() =>
        signup({
          name,
          email,
          password,
          productEmailOptIn,
          newsletterEmailOptIn,
          touAgree,
          queryString,
          skipAutoJoin: ["invitation", "join-link"].includes(token?.type),
        })
      );
    }, [
      email,
      name,
      newsletterEmailOptIn,
      password,
      productEmailOptIn,
      queryString,
      responseHandler,
      signup,
      token?.type,
      touAgree,
    ]);

  const [_loginWithGoogleToken] = useLoginWithGoogleToken(),
    loginWithGoogle = useCallback(
      (data: GoogleAuthResponse) => {
        void responseHandler.process(() =>
          _loginWithGoogleToken({ token: data.credential, queryString })
        );
      },
      [_loginWithGoogleToken, queryString, responseHandler]
    );

  function interpretError(): { error?: string | null; fieldErrors?: { [key: string]: string } } {
    const { error } = responseHandler;
    if (!error) return {};
    const details = error.details || {},
      fieldErrors = ["name", "email", "company", "password"]
        .filter(k => details[k])
        .reduce((res, key) => ({ ...res, [key]: details[key] }), {});
    return {
      error: Object.keys(fieldErrors).length === 0 ? error.message : null,
      fieldErrors,
    };
  }

  const loginUrl = `/login${queryString}`,
    {
      error: errorMessage,
      fieldErrors: { name: nameError, email: emailError, password: passwordError } = {},
    } = interpretError();

  // If we are logging in with a link account token, we don't need to show the SSO link
  // The user is loggin in to consume the sso token
  const allowSSO = token?.type !== "link-account";

  function renderTitle() {
    return token && space ? (
      <SpacePreviewTitle titlePrefix="Create an account to join " space={space} />
    ) : (
      <AuthFormTitle title="Get started" message="Create a Lingo account for free" />
    );
  }

  function renderForm() {
    if (!isFormShown) {
      return <Button mb="m" text="Sign up with email" onClick={showForm} />;
    }

    return (
      <Box
        as="form"
        borderBottom="default"
        pb="l"
        mb="l"
        onSubmit={e => {
          e.preventDefault();
          void onSignup();
        }}>
        <Input
          onChange={e => setName(e.target.value)}
          value={name}
          placeholder="First and Last"
          type="text"
          maxLength={255}
          id="id_name"
          label="Name"
          styleOverrides={{ pb: "m" }}
          message={nameError}
          inputStyle={nameError ? "error" : null}
          autoComplete="name"
        />
        <Input
          onChange={e => setEmail(e.target.value)}
          value={email}
          placeholder="work@email.com"
          maxLength={255}
          type="email"
          id="id_email"
          label="Work Email"
          styleOverrides={{ pb: "m" }}
          message={emailError}
          inputStyle={emailError ? "error" : null}
          autoComplete="email"
        />
        <Input
          onChange={e => setPassword(e.target.value)}
          value={password}
          placeholder="••••••••"
          type="password"
          id="id_password"
          label="Password"
          styleOverrides={{ pb: "m" }}
          message={passwordError}
          inputStyle={passwordError ? "error" : null}
          autoComplete="new-password"
        />

        <div>
          <Checkbox
            id="product-email-checkbox"
            isSelected={productEmailOptIn}
            label="Send me educational and announcement emails"
            onClick={() => {
              setProductEmailOptIn(!productEmailOptIn);
            }}
          />
          <Checkbox
            id="newsletter-email-checkbox"
            isSelected={newsletterEmailOptIn}
            label="Send me a monthly newsletter about design"
            onClick={() => {
              setNewsletterEmailOptIn(!newsletterEmailOptIn);
            }}
          />
          <Checkbox
            id="tou-checkbox"
            isSelected={touAgree}
            onClick={() => {
              setTouAgree(!touAgree);
            }}
            label={
              <Text>
                I agree to the{" "}
                <Button
                  text="Terms of Use"
                  link={buildURL("/legal")}
                  buttonStyle="tertiary"
                  newWindow
                />
              </Text>
            }
          />
        </div>

        {errorMessage && <Notice noticeStyle="error" message={errorMessage} mt="m" />}
        <Button
          {...authButtonStyle}
          mb="none"
          disabled={responseHandler.isProcessing}
          type="submit"
          text="Create account"
        />
      </Box>
    );
  }

  return (
    <AuthFormWrapper>
      {renderTitle()}
      <Flex flexDirection="column">
        {renderForm()}
        <GoogleSignInButton context="signup" onSuccess={loginWithGoogle} />
        {allowSSO && (
          <Button
            mt="m"
            text="Log in with SSO"
            buttonStyle="secondary"
            width="100%"
            link={`/login/sso/${queryString}`}
          />
        )}
      </Flex>
      <Text textAlign="center" font="ui.small" mt="l">
        Already have an account?{" "}
        <Button
          id="login-button"
          text="Log in."
          buttonStyle="tertiary"
          size="small"
          link={loginUrl}
        />
      </Text>
    </AuthFormWrapper>
  );
}
