import { Asset } from "@thenounproject/lingo-core";

export const getHexStringFromAsset = (asset: Asset): string => {
  const [color] = asset.colors;

  if (!color) return "Invalid color asset";
  let hex = color.hex;
  if (color.rgb.startsWith("rgba")) {
    const opacity = Math.round(parseFloat(color.rgb.split(", ")[3].replace(")", "")) * 100);
    hex += ` ${opacity}%`;
  }

  return hex.toUpperCase();
};
