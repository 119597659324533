import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  itemId: string;
};

const [useDeleteHeadingWithItems, deleteHeadingWithItems] = createAsyncAction(
  "items/deleteHeadingWithItems",
  async (args: Args) => {
    return await API.call<{ items: string[] }>({
      endpoint: `headings/${args.itemId}`,
      method: "DELETE",
      entity: API.Entity.item,
    });
  }
);
export default useDeleteHeadingWithItems;
export { deleteHeadingWithItems };
