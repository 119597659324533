import { createSelector } from "reselect";
import { Space } from "@thenounproject/lingo-core";
import { isEqual } from "lodash";
import { useAppSelectorV1 } from "@redux/hooks";

import { getSpaceId, getSpaces } from "../getters";

export const selectSpace = createSelector(
  [getSpaces, getSpaceId],
  (spaces: Record<string, Space>, spaceId: number) => {
    return spaces[spaceId] || null;
  }
);

export const useSelectSpace = (spaceId: number = null): Space =>
  useAppSelectorV1(state => selectSpace(state, { spaceId }), isEqual);
