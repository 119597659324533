import React from "react";
import styled from "styled-components";
import {
  Flex,
  Text,
  Box,
  Button,
  Tooltip,
  pluralize,
  useNavigation,
  buildURL,
} from "@thenounproject/lingo-core";

import { Inspectable } from "@constants/Inspector";

import useInspectableActions from "@features/context-menus/hooks/useInspectableActions";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import { useSelectSpace } from "@selectors/entities/spaces";
import { buildTagSearchInputFromTag } from "@features/library/utils";
import { useAssetLibraryContext } from "@contexts/AssetLibraryProvider";

const TitleContainer = styled(Box).attrs({
  /* NOTE Might need to pass vars on this max-width
   *  in the future to solve for all edge cases
   */
  maxWidth: "calc(100% - 300px)",
  flexShrink: 1,
})``;
const ControlsContainer = styled(Flex).attrs({ alignItems: "center" })`
  flex-shrink: 0;
`;

const ActionButton = styled(Button).attrs({
  size: "small",
  mr: "s",
  themeOverrides: {
    primaryColor: "grayLightest",
    primaryColorTint: "black",
    primaryColorDark: "grayLight",
  },
})``;

export type Props = {
  inspectables: Inspectable[];
};
const InspectorSimpleTags: React.FC<Props> = ({ inspectables }) => {
  const navigation = useNavigation();
  const space = useSelectSpace();

  const { showModal } = useShowModal();
  const { deleteTags } = useInspectableActions(inspectables);
  const { setFilters } = useAssetLibraryContext();

  /**
   * Shouldn't happen, but a safeguard to ensure tags are provided
   */
  if (!inspectables.filter(f => Boolean(f.tag)).length) return null;

  function renderTitleSection() {
    let title = `${inspectables.length} ${pluralize("tag", inspectables.length)} selected`;
    if (inspectables.length === 1) {
      title = inspectables[0].tag.value;
    }
    return (
      <TitleContainer>
        <Text font="ui.regularBold" truncate>
          {title}
        </Text>
      </TitleContainer>
    );
  }

  function editButton() {
    if (inspectables.length !== 1) return null;
    return (
      <>
        <Tooltip source="edit-button" direction={Tooltip.Direction.Top}>
          Edit
        </Tooltip>
        <ActionButton
          data-tooltip-source="edit-button"
          id="inspector-simple-edit-button"
          aria-label="Edit"
          icon="editable"
          onClick={() => showModal(ModalTypes.EDIT_TAG, { tag: inspectables[0].tag })}
        />
      </>
    );
  }

  function deleteButton() {
    if (!deleteTags) return null;
    return (
      <>
        <Tooltip source="delete-button" direction={Tooltip.Direction.Top}>
          Delete
        </Tooltip>
        <ActionButton
          data-tooltip-source="delete-button"
          icon="action.trash"
          onClick={deleteTags}
          aria-label="Delete tags"
        />
      </>
    );
  }

  function viewButton() {
    return (
      <Button
        text="View tagged assets"
        id="view-tagged-assets"
        size="small"
        onClick={() => {
          navigation.push(buildURL("/library", { space }));
          setFilters(inspectables.map(d => buildTagSearchInputFromTag(d.tag)));
        }}
      />
    );
  }

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      data-testid="inspector-simple">
      {renderTitleSection()}
      <ControlsContainer>
        {editButton()}
        {deleteButton()}
        {viewButton()}
      </ControlsContainer>
    </Flex>
  );
};

export default InspectorSimpleTags;
