import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  name?: string;
  email?: string;
};

const [useUpdateUserProfile, updateUserProfile] = createAsyncAction(
  "user/updateProfile",
  async ({ name, email }: Args) => {
    return await API.call<{ user: number }>({
      endpoint: `me/`,
      method: "PUT",
      entity: API.Entity.user,
      data: {
        name,
        email,
      },
    });
  }
);

export default useUpdateUserProfile;
export { updateUserProfile };
