import { PageContent } from "../pages/templates/PageLayout";

import Images from "../data/images";
import featureCards from "./featureCards";
import { MarketingButtons } from "../components/MarketingButtons";

const content: PageContent = {
  style: "dark",
  sections: [
    {
      type: "HeroSection",
      title: "Create a home for your brand",
      subtitle:
        "**Digital brand guidelines, asset libraries and documentation.** All in one place.",
      style: "darkGradient",
      image: {
        src: Images.Home.hero,
        alt: "Slide 1",
      },
      primaryButton: MarketingButtons.bookDemo,
      position: { mb: "none", pb: "200" },
    },
    { type: "CustomerLogosSection", position: { mt: "-xxl" } },
    {
      type: "MediaSection",
      title: "Build your brand",
      subtitle:
        "Use Lingo's easy to use DAM + customizable front end to publish an unlimited number of brand websites, no technical expertise needed.",
      image: {
        src: Images.Home.defineBrand,
        alt: "Slide 1",
      },
    },
    {
      type: "MediaSection",
      title: "Empower teams to create on their own",
      subtitle:
        "With Lingo, every team member will be able to find the file they need, in the format and size they need, all without needing your help.",
      layoutDirection: "image-left",
      image: {
        src: Images.Home.empowerTeams,
        alt: "Slide 1",
      },
    },
    {
      type: "MediaSection",
      title: "Always up to date",
      subtitle:
        "Lingo connects with the tools you use everyday so your assets are always up to date. Learn more about our Figma integration.",
      image: {
        src: Images.Home.upToDate,
        alt: "Slide 1",
      },
      primaryButton: {
        text: "Learn more about Figma & Lingo",
        link: "/integrations/figma",
      },
    },
    {
      type: "CardSection",
      title: "More features to help manage your brand",
      items: [
        featureCards.search,
        featureCards.customBranding,
        featureCards.fileConversions,
        featureCards.downloadRequests,
        featureCards.publicSharing,
        featureCards.customDomains,
      ],
      footerButton: {
        text: "View all features",
        buttonStyle: "outline",
        link: "/product/features",
      },
    },
    {
      type: "ShowcaseSection",
      title: "Showcase your brand in Lingo",
      image: {
        src: Images.Home.showcase,
        alt: "Slide 1",
      },
    },
  ],
};

export default content;
