import { API } from "@thenounproject/lingo-core";
import { UploadAction } from "./";
import { createAsyncAction } from "../actionCreators";

const MAX_UNCHUNKED_UPLOAD_SIZE = 20000000;
const MAX_UPLOAD_CHUNK_SIZE = 10000000;

const [, uploadFile] = createAsyncAction("uploads/uploadFile", async (args: UploadAction) => {
  const { file, data, ...requestData } = args.requestData;
  if (!file) {
    return await API.call<string>({
      ...requestData,
      data,
    });
  } else if (args.upload.size > MAX_UNCHUNKED_UPLOAD_SIZE) {
    const uploadId = await startUploadSession();

    await uploadChunks(uploadId, file, args.upload.size);
    await completeUploadSession(uploadId, args.upload.size);

    return API.call<string>({
      ...requestData,
      data: {
        ...data,
        upload_id: uploadId,
      },
    });
  } else {
    const formData = new FormData();
    formData.append("asset", file);
    formData.append("json", JSON.stringify(data));
    return await API.call<string>({
      ...requestData,
      formData: formData,
    });
  }
});

export { uploadFile };

// Returns the upload ID,
async function startUploadSession() {
  const res = await API.call<{ upload_id: string }>({
    endpoint: "/upload_session/start",
    method: "POST",
  });
  return res.result.upload_id;
}

async function uploadChunks(uploadId: string, file: File, size: number) {
  const chunkCount = Math.ceil(size / MAX_UPLOAD_CHUNK_SIZE);
  for (let idx = 0; idx < chunkCount; idx += 1) {
    await appendUploadSession(uploadId, idx + 1, file);
  }
}

// Appends a chunk to an upload session
async function appendUploadSession(uploadId: string, chunkNumber: number, file: File) {
  const data = JSON.stringify({
      upload_id: uploadId,
      chunk_number: chunkNumber,
    }),
    startByte = MAX_UPLOAD_CHUNK_SIZE * (chunkNumber - 1),
    blob = file.slice(startByte, startByte + MAX_UPLOAD_CHUNK_SIZE),
    formData = new FormData();
  formData.append("chunk", blob);
  formData.append("json", data);

  return await API.call<string>({
    endpoint: "/upload_session/append",
    method: "POST",
    formData,
  });
}

// completes and upload session
async function completeUploadSession(uploadId: string, size: number) {
  return await API.call<string>({
    endpoint: "/upload_session/complete",
    method: "POST",
    data: {
      upload_id: uploadId,
      size,
    },
  });
}
