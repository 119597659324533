import { API, APIResultList } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  portalId: string;
  userIds: number[];
  role?: string;
};

const [useAddPortalMembers, addPortalMembers] = createAsyncAction(
  "portalMembers/add",
  async ({ portalId, userIds, role }: Args) => {
    const data = {
      members: userIds.map(id => ({
        user_id: id,
        role,
      })),
    };
    return await API.call<APIResultList<"members", string>>({
      endpoint: `portals/${portalId}/members`,
      method: "POST",
      entity: API.Entity.portalMember,
      data,
    });
  }
);

export default useAddPortalMembers;
export { addPortalMembers };
