import { Item, ItemType } from "@thenounproject/lingo-core";

export type GalleryOutline = Record<
  string,
  {
    id: string;
    version: number;
    type: ItemType;
    position: "left" | "right" | "full";
    index: number;
    guidePairId?: string;
  }
>;

/**
 * Returns an object of brittle gallery items (half guides, thus far)
 * @param {Array} items
 */
export const getGalleryOutline = (items: Item[]): GalleryOutline => {
  let position = "full";
  return items.reduce((acc, item, index) => {
    if (item.type !== ItemType.guide) position = "full";
    else if (!item.data || item.data.displaySize <= 1) position = "full";
    else if (position === "left") position = "right";
    else position = "left";
    if (["right", "left"].includes(position)) {
      const nextItem = items[index + 1];
      const prevItem = items[index - 1];
      acc[item.id] = {
        id: item.id,
        version: item.version,
        type: ItemType.guide,
        position,
        index,
      };
      if (
        position === "left" &&
        nextItem &&
        nextItem.type === ItemType.guide &&
        nextItem.data.displaySize > 1
      ) {
        acc[item.id].guidePairId = nextItem.id;
      }
      if (
        position === "right" &&
        prevItem &&
        prevItem.type === ItemType.guide &&
        prevItem.data.displaySize > 1
      ) {
        acc[item.id].guidePairId = prevItem.id;
      }
    }
    return acc;
  }, {});
};
