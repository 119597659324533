import { API, KitVersion, Section } from "@thenounproject/lingo-core";
import { createQueryAction } from "../actionCreators";
import { getVersionedIdentifier } from "@redux/utils/identifiers";

type Args = {
  spaceId: string | number;
  kitId: string;
  version?: number;
};

type KitOutline = Omit<KitVersion, "sections"> & { sections: Section[] };

const [useKitOutline, fetchKitOutline] = createQueryAction<Args, KitOutline, string>(
  {
    entity: "kitVersions",
    action: "fetchOutline",
    condition: ({ args }) => Boolean(args.kitId),
    denormalize: { sections: "sections" },
    prefetchData: ({ args }, { getState }) => {
      if (!args.version) {
        // maybe we look at the kit/kit access to determine the fallback version?
        return null;
      }
      const state = getState();
      const kitId = state.entities.shortIds.kits?.[args.kitId] ?? args.kitId;

      const versionedId = getVersionedIdentifier(kitId, args.version);
      const outline = state.entities.kitVersions.objects[versionedId];
      if (!outline?.sections) return null;
      return {
        result: versionedId,
        entities: {
          kitVersions: {
            [versionedId]: outline,
          },
        },
      };
    },
  },
  async ({ args }) => {
    return await API.call<string>({
      endpoint: `spaces/${args.spaceId}/kits/${args.kitId}/outline`,
      method: "GET",
      entity: API.Entity.kitVersion,
      query: {
        v: args.version,
      },
    });
  }
);

export default useKitOutline;
export { fetchKitOutline };
