import { API, Space, Kit, Asset, CustomField } from "@thenounproject/lingo-core";
import { createQueryAction } from "../actionCreators";

type Args = {
  spaceId: Space["id"];
  kitId?: Kit["kitId"];
  assetId?: Asset["id"];
  assetToken?: Asset["shareToken"];
};

const [useCustomFields, fetchCustomFields] = createQueryAction<Args, CustomField[], number[]>(
  {
    entity: "fields",
    action: "fetch",
    condition: ({ args }) => Boolean(args.spaceId),
    cacheKey: args => ({ spaceId: args.spaceId }),
  },
  async ({ args }) => {
    const res = await API.call<{ fields: number[] }>({
      endpoint: `spaces/${args.spaceId}/fields`,
      method: "GET",
      entity: API.Entity.customField,
      query: {
        kit_uuid: args.kitId,
        asset_uuid: args.assetId,
        asset_token: args.assetToken,
      },
    });
    return { result: res.result.fields, entities: res.entities };
  }
);

export default useCustomFields;
export { fetchCustomFields };
