/* eslint-disable @typescript-eslint/no-unsafe-call */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let google: any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let window: any;

import { AssetSourceVendors, ExternalSource } from "@redux/actions/items/useCreateAssetsFromSource";
import { useCallback, useEffect, useState } from "react";
import useScript from "react-script-hook";

import GOOGLE_CREDENTIALS from "../constants/googleAuth";

const { clientId, developerKey, appId } = GOOGLE_CREDENTIALS;

// A simple auth cache to avoid re-authorizing every time we open the picker.
let authResultCache: AuthResult = null;

export default function useGoogleDrivePicker(): Result {
  const [loadingGAPI, errorGAPI] = useScript({
    src: "https://apis.google.com/js/api.js",
    id: "google-drive-picker",
  });
  const [loadingGSI, errorGSI] = useScript({
    src: "https://accounts.google.com/gsi/client",
    id: "google-signin",
  });
  const isGAPIReady = !loadingGAPI && !errorGAPI;
  const isGSIReady = !loadingGSI && !errorGSI;
  const [pickerApiLoaded, setpickerApiLoaded] = useState(false);

  // load the Drive picker api
  useEffect(() => {
    if (isGAPIReady && isGSIReady && !pickerApiLoaded) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      window.gapi.load("auth");
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      window.gapi.load("picker", { callback: () => setpickerApiLoaded(true) });
    }
  }, [pickerApiLoaded, isGAPIReady, isGSIReady]);

  const createPicker = useCallback((options: Options, token: string) => {
    const { multiselect = false, viewMimeTypes = allowedTypes, onSuccess, onCancel } = options;

    const view = new google.picker.DocsView(google.picker.ViewId["DOCS"]);
    if (viewMimeTypes) view.setMimeTypes(viewMimeTypes);
    view.setIncludeFolders(true);
    // If we want to allow folder selection we need to add logic to fetch files from the selected folders
    // if (multiselect) view.setSelectFolderEnabled(true);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const picker = new google.picker.PickerBuilder()
      .setAppId(appId)
      .setOAuthToken(token)
      .setDeveloperKey(developerKey)
      .setLocale("en")
      .setCallback(data => {
        if (data.action === "picked") {
          const sources = data.docs.map(doc => ({
            source: AssetSourceVendors.GoogleDrive,
            name: doc.name,
            url: `https://www.googleapis.com/drive/v2/files/${doc.id}?alt=media`,
            authToken: token,
            fileSize: doc.sizeBytes,
          }));
          onSuccess(sources);
        } else if (data.action === "cancel") {
          onCancel?.();
        }
      })
      .addView(view);

    if (multiselect) {
      picker.enableFeature(google.picker.Feature.MULTISELECT_ENABLED);
    }
    picker.enableFeature(google.picker.Feature.SUPPORT_DRIVES);
    picker.build().setVisible(true);
  }, []);

  return useCallback(
    (options: Options) => {
      // global scope given conf
      if (authResultCache && authResultCache.expires_at > Date.now()) {
        createPicker(options, authResultCache.access_token);
      } else {
        // if we don't have a valid token, get one
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        const client = google.accounts.oauth2.initTokenClient({
          client_id: clientId,
          scope: "https://www.googleapis.com/auth/drive.readonly",
          callback: (tokenResponse: AuthResult) => {
            const res = tokenResponse;
            // Subtract 30 minutes from the expiration time so we are sure the API
            // has plenty of time to fetch the files after they are selected.
            const actualExpiration = tokenResponse.expires_in * 1000,
              offset = 30 * 60 * 1000;
            res.expires_at = Date.now() + actualExpiration - offset;
            authResultCache = res;
            createPicker(options, authResultCache.access_token);
          },
        });
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        client.requestAccessToken();
      }
    },
    [createPicker]
  );
}

export type Options = {
  viewMimeTypes?: string;
  multiselect?: boolean;
  onSuccess: (docs: ExternalSource[]) => void;
  onCancel?: () => void;
};

type Result = (options: Options) => void;

export type GoogleDoc = {
  id: string;
  name: string;
  sizeBytes: number;
  // mimeType: string;
  // type: string;
  // Other values we aren't using, here for reference in case they become useful later
  // url: string;
  // rotation: number;
  // rotationDegree: number;
  // serviceId: string;
  // isShared: boolean;
  // lastEditedUtc: number;
  // uploadState?: string;
  // description: string;
  // driveSuccess: boolean;
  // embedUrl: string;
  // iconUrl: string;
};

type AuthResult = {
  access_token: string;
  token_type: string;
  expires_in: number;
  expires_at: number;
  scope: string;
  authuser: string;
  prompt: string;
};

// Idea: It would be nice to map these to our internal types for easier use
// That would mostly come into play if/when we allow replacing files from google
// and need to restrict which types are allowed
const allowedTypes = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/gif",
  "image/tiff",
  "image/heic",
  "image/svg+xml",
  "image/svg",
  "application/pdf",
  "application/zip",
  "text/plain",
  // Audio
  "audio/mpeg", // mp3
  "audio/mp3",
  "audio/x-m4a",
  // Video
  "video/mp4",
  "video/quicktime", //mov
  // Fonts
  "font/otf",
  "font/ttf",
  "font/woff",
  "font/woff2",
  // MS Office
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  // iWork
  "application/x-iwork-keynote-sffkey",
  "application/x-iwork-pages-sffpages",
  "application/vnd.apple.numbers",
  "application/vnd.apple.pages",
  // Misx
  "application/octet-stream", // INDD
  "application/postscript", // AI, EPS
  "application/json", // Lottie
].join(",");
