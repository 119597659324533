import _merge from "lodash/merge";
import { APIToken } from "@thenounproject/lingo-core";

import createEntityReducer from "../helpers/createEntityReducer";

import { deleteApiToken } from "@redux/actions/apiTokens/useDeleteApiToken";
import { fetchApiTokens } from "@redux/actions/apiTokens/useApiTokens";
import { createApiToken } from "@redux/actions/apiTokens/useCreateApiToken";

export default createEntityReducer<APIToken>(
  "apiTokens",
  queryBuilder => {
    queryBuilder
      .addCase(deleteApiToken.fulfilled, (state, action) => {
        const { token, spaceId } = action.payload.entities.apiTokens[action.payload.result];
        fetchApiTokens.getQueryData(state, { spaceId }).forEach(q => {
          q.data = q.data?.filter(t => t !== token);
        });
      })
      .addCase(createApiToken.fulfilled, (state, action) => {
        const token = action.payload.entities.apiTokens[action.payload.result];
        const { spaceId } = action.meta.arg;
        fetchApiTokens.getQueryData(state, { spaceId }).forEach(q => {
          q.data.push(token.token);
        });
      });
  },
  objectBuilder => {
    objectBuilder.addDefaultCase((state, action: any) => {
      const tokens = (action.response?.entities ?? action.payload?.entities)?.apiTokens;
      if (tokens) {
        return _merge(state, tokens);
      }
    });
  }
);
