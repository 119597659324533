import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "@actions/actionCreators";

type Args = {
  spaceId: string | number;
  kitId: string;
  version: number;
};

/**
 * Update the user version of a kit for the current user, i.e. the last version the user has opened.
 */
const [useUpdateKitUserVersion, updateKitUserVersion] = createAsyncAction(
  "kits/updateUserVersion",
  async ({ spaceId, kitId, version }: Args, thunkAPI) => {
    const userId = thunkAPI.getState().user.id;
    return await API.call<string>({
      endpoint: `kits/${spaceId}/${kitId}/members/${userId}`,
      method: "PUT",
      entity: API.Entity.kitMember,
      data: {
        use_version: version,
      },
    });
  }
);

export default useUpdateKitUserVersion;
export { updateKitUserVersion };
