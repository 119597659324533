import { createAsyncAction } from "../actionCreators";
import { API, camelize } from "@thenounproject/lingo-core";
import { FigmaIntegrationStatus } from "./useFigmaIntegrationStatus";

type Args = {
  spaceId: number;
};

const [useDisableFigmaIntegration, disableFigmaIntegration] = createAsyncAction(
  "figma/disableFigmaIntegration",
  async ({ spaceId }: Args) => {
    const res = await API.call<FigmaIntegrationStatus>({
      endpoint: `spaces/${spaceId}/figma/auth`,
      method: "DELETE",
    });
    return { result: camelize(res.result) };
  }
);

export default useDisableFigmaIntegration;
export { disableFigmaIntegration };
