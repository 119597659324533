import React from "react";
import { Box, ActivityIndicator, OptionCard } from "@thenounproject/lingo-core";
import { CurrentUser } from "@queries/useCurrentUser";

const UserBox: React.FC<{ user: CurrentUser }> = ({ user }) => {
  const isLoading = !user.name;
  if (isLoading) {
    return (
      <Box width="100%" height="40">
        <ActivityIndicator center size="small" />
      </Box>
    );
  }
  return (
    <OptionCard
      title={user.name}
      detail={user.email}
      cardStyle="default"
      image={{
        style: "circle",
        icon: "navigation.user",
        src: user.avatar,
      }}
    />
  );
};

export default UserBox;
