import { createSelector } from "reselect";
import { Kit } from "@thenounproject/lingo-core";
import { useAppSelectorV1 } from "@redux/hooks";

import { getKits, getKitId } from "../getters";

export const selectKit = createSelector(
  [getKits, getKitId],
  (kits: Record<string, Kit>, kitId: string) => {
    return kits[kitId] || null;
  }
);

export const useSelectKit = (kitId?: string) =>
  useAppSelectorV1(state => selectKit(state, { kitId }));
