import {
  Asset,
  Space,
  buildURL,
  buildPath,
  Tag,
  base64,
  SearchFilterInput,
  SearchFilterTypes,
} from "@thenounproject/lingo-core";
import moment from "moment";

export const buildLibraryAssetUrl = (asset: Asset, space: Space) =>
  buildURL(`/library/a/${asset.id}`, { space });

export const canViewAssetLibrary = (space: Space) =>
  space?.access?.permissions?.includes("view_asset_library") === true;

export const canCreateAssets = (space: Space) =>
  space.access?.permissions?.includes("create_asset") === true;

export const buildLibraryAssetPath = (space: Space, asset: Asset, viewId?: number) =>
  buildPath(`/library/a/${asset.id}`, { space }, { view: viewId || undefined });

export const buildTagSearchInputFromQuery = (query: string): SearchFilterInput => ({
  type: SearchFilterTypes.tag,
  value: query,
  id: query,
  display: query,
});

export const buildTagSearchInputFromTag = (tag: Tag): SearchFilterInput => ({
  type: SearchFilterTypes.tag,
  value: tag.value,
  id: tag.value,
  display: tag.value,
});

export const buildTaggedAssetsResultsPath = (tags: Tag[], space: Space) =>
  buildPath("/library", { space }, { q: base64.encode(tags.map(buildTagSearchInputFromTag)) });

export const formatSearchFilterDate = (value: string) => moment(value).utc().format("yyyy-MM-DD");
