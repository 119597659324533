import React from "react";
import styled, { useTheme } from "styled-components";
import {
  buildPath,
  TabBar,
  Box,
  Space,
  KitCollection,
  SpaceThemeNames,
} from "@thenounproject/lingo-core";

import { SpaceKitsViews } from "../pages/SpaceKits";

const Container = styled(Box).attrs(props => {
  return {
    mx: "auto",
    maxWidth: "100%",
    background: "toolbarBackground",
    position: "sticky",
    top: props.theme.themeName === SpaceThemeNames.wyman ? "62px" : "67px",
    style: { zIndex: 10, overflowX: "auto" },
    variations: {
      "mq.s": {
        top: 0,
      },
    },
  };
})``;

type Props = {
  space: Space;
  spaceKitsViewType: string;
  kitCollectionId: string;
  kitCollections: KitCollection[];
};

const themeVals = {
  [SpaceThemeNames.classic]: { container: { maxWidth: "960px" } },
  [SpaceThemeNames.scher]: { container: { maxWidth: "1000px" } },
  [SpaceThemeNames.wyman]: {
    container: { maxWidth: "1140px" },
  },
};

const SpaceKitCollectionNav: React.FC<Props> = ({
  space,
  spaceKitsViewType,
  kitCollectionId,
  kitCollections,
}) => {
  const themeName = useTheme().themeName || SpaceThemeNames.classic;
  if (!kitCollections?.length) return null;

  return (
    <>
      <Container {...themeVals[themeName].container}>
        <TabBar center>
          <TabBar.Item
            text="All"
            selected={spaceKitsViewType === SpaceKitsViews.ALL_KITS}
            link={buildPath("/", { space })}
            subtext={null}
            disabled={false}
          />
          {kitCollections.map(kc => {
            return (
              <TabBar.Item
                key={kc.uuid}
                text={kc.name}
                selected={kitCollectionId === kc.uuid}
                link={buildPath(kc.url, { space })}
                subtext={null}
                disabled={false}
              />
            );
          })}
        </TabBar>
      </Container>
      {(themeName === SpaceThemeNames.classic || themeName === SpaceThemeNames.scher) && (
        <Box borderTop="default" width="100%" />
      )}
    </>
  );
};

export default SpaceKitCollectionNav;
