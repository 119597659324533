import { PageContent } from "../pages/templates/PageLayout";

import Images from "../data/images";
import { MarketingButtons } from "../components/MarketingButtons";

const content: PageContent = {
  style: "dark",
  sections: [
    {
      type: "HeroSection",
      title: "Enterprise digital asset management (DAM)",
      style: "darkGradient",
      subtitle:
        "Advanced security and flexible controls that allow you to share your brand with your entire organization and the outside world",
      image: {
        src: Images.Enterprise.hero,
        alt: "",
      },
      primaryButton: MarketingButtons.contactSales,
      secondaryButton: MarketingButtons.bookDemo,
      position: { mb: "none", pb: "200" },
    },
    { type: "CustomerLogosSection", position: { mt: "-xxl" } },
    {
      type: "MediaSection",
      title: "Org - wide consistency ",
      subtitle:
        "Create a brand hub where everyone on your team can find the content and guidelines needed to create on brand experiences. ",
      image: {
        src: Images.Enterprise.consistency,
        alt: "Slide 1",
      },
      body: {
        style: "iconList",
        items: [
          {
            title: "Public and private access ",
            body: "Easily publish kits to your brand portal. Make them public, private or password protected.",
          },
          {
            title: "Custom domains",
            body: "Create an on brand link to your library to make it feel official and easy for team members to remember.",
          },
          {
            title: "Advanced custom branding",
            body: "Your brand. Your product. Choose a custom font, accent color and theme to make things feel official and on-brand.",
          },
        ],
      },
    },
    {
      type: "MediaSection",
      title: "Security you can count on",
      subtitle: "Keep your data safe with enterprise grade features and security audits",
      image: {
        src: Images.Enterprise.security,
        alt: "Slide 1",
      },
      layoutDirection: "image-left",
      body: {
        style: "iconList",
        items: [
          {
            title: "SSO",
            body: "Simplify secure access to your brand assets with SAML-based SSO. You can also manage employees at scale with SCIM provisioning.",
          },
          {
            title: "SOC - 2",
            body: "Lingo is SOC - 2 certified. See our full [trust report](https://trust-report.lingoapp.com).",
          },
          {
            title: "Custom controls",
            body: "Control granular details on your metadata and individual asset links",
          },
        ],
      },
    },
    {
      type: "MediaSection",
      title: "Support you can trust",
      subtitle:
        "Our world class support team is always ready to lend a hand and set your team up for sucess.",
      image: {
        src: Images.Enterprise.support,
        alt: "Slide 1",
      },
      body: {
        style: "iconList",
        items: [
          {
            title: "Batch importing",
            body: "Already have your assets on another platform? Our team will help you batch import your assets. ",
          },
          {
            title: "Priority support.",
            body: "Get answers to your questions in minutes not hours ",
          },
        ],
      },
    },
    {
      type: "ShowcaseSection",
      title: "Showcase your brand in Lingo",
      image: {
        src: Images.Home.showcase,
        alt: "Slide 1",
      },
    },
  ],
};
export default content;
