import React from "react";
import { noop } from "lodash";
import {
  SearchQueryFilterOption,
  RowSize,
  SearchSortTypes,
  SearchFilterPreset,
} from "@features/library/types";
import qs from "query-string";

import { SearchFilterInput, AssetView } from "@thenounproject/lingo-core";

export const ASSET_ROW_STORAGE_KEY = "lingo_asset_row_size";
export const CUSTOM_FIELD_ROW_STORAGE_KEY = "lingo_asset_row_fields";
const initialAssetRowSize = (localStorage?.getItem(ASSET_ROW_STORAGE_KEY) || "medium") as RowSize;
const initialShowTableCustomFields =
  localStorage?.getItem(CUSTOM_FIELD_ROW_STORAGE_KEY) === "false" ? false : true;

const initialSort =
  (qs.parse(window.location.search).sort as SearchSortTypes) || SearchSortTypes.recentDesc;

export type AssetLibraryContextType = {
  availableFilters: SearchQueryFilterOption[];
  appliedFilters: SearchFilterInput[];
  addFilter: (filter: SearchFilterInput) => void;
  removeFilter: (index: number) => void;
  setFilters: (filters: SearchFilterInput[]) => void;
  clearFilters: () => void;
  sort: SearchSortTypes;
  setSortBy: (sort: SearchSortTypes) => void;
  assetRowSize: RowSize;
  setAssetRowSize: React.Dispatch<React.SetStateAction<RowSize>>;
  showTableCustomFields: boolean;
  setShowTableCustomFields: React.Dispatch<React.SetStateAction<boolean>>;
  presetViews: SearchFilterPreset[];
  activeView?: AssetView;
  activePreset?: SearchFilterPreset;
  viewId?: number;
};

export const initialState: AssetLibraryContextType = {
  availableFilters: [],
  appliedFilters: [],
  addFilter: noop,
  removeFilter: noop,
  setFilters: noop,
  clearFilters: noop,
  sort: initialSort,
  setSortBy: noop,
  assetRowSize: initialAssetRowSize,
  setAssetRowSize: noop,
  showTableCustomFields: initialShowTableCustomFields,
  setShowTableCustomFields: noop,
  presetViews: [],
  activeView: undefined,
  activePreset: undefined,
  viewId: undefined,
};

export const AssetLibraryContext = React.createContext<AssetLibraryContextType>(initialState);

AssetLibraryContext.displayName = "AssetLibraryContext";
