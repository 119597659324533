import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

export type Args = {
  token: string;
};

const [useAcceptInvite, acceptInvite] = createAsyncAction(
  "auth/acceptInvite",
  async ({ token }: Args) => {
    return await API.call<string>({
      endpoint: `invitation/${token}/redeem`,
      method: "POST",
      entity: API.Entity.space,
    });
  }
);

export default useAcceptInvite;
export { acceptInvite };
