import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  kitId: string;
  spaceId: number;
  name: string;
  notes?: string;
};

const [useCreateKitVersion, createKitVersion] = createAsyncAction(
  "kitVersion/create",
  async (args: Args) => {
    return await API.call<string>({
      endpoint: `kits/${args.kitId}/version`,
      method: "POST",
      entity: API.Entity.kitVersion,
      data: {
        version_identifier: args.name,
        notes: args.notes || "",
      },
    });
  }
);

export default useCreateKitVersion;
export { createKitVersion };
