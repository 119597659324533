/**
 * Custom fields reducer
 */

import _merge from "lodash/merge";
import _remove from "lodash/remove";

import { Portal } from "@thenounproject/lingo-core";

import createEntityReducer from "../helpers/createEntityReducer";
import { getEntities } from "../helpers/getEntities";
import { addPortalMembers } from "@redux/actions/portalMembers/useAddPortalMembers";
import { queryPortalMembers } from "@redux/actions/portalMembers/usePortalMembers";
import { removePortalMember } from "@redux/actions/portalMembers/useRemovePortalMember";
import { removePortalMembers } from "@redux/actions/portalMembers/useRemovePortalMembers";

export default createEntityReducer<Portal>(
  "portalMembers",
  queryBuilder => {
    queryBuilder
      .addCase(addPortalMembers.fulfilled, (state, action) => {
        action.payload.result.members.forEach(res => {
          if (!res.error) {
            queryPortalMembers
              .getQueryData(state, { portalId: action.meta.arg.portalId })
              .forEach(query => {
                query.data.members.push(res.result);
                query.data.total += 1;
              });
          }
        });
      })
      .addCase(removePortalMember.fulfilled, (state, action) => {
        queryPortalMembers
          .getQueryData(state, { portalId: action.meta.arg.portalId })
          .forEach(query => {
            const removed = _remove(query.data.members, id => id === action.payload.result);
            query.data.total -= removed.length;
          });
      })
      .addCase(removePortalMembers.fulfilled, (state, action) => {
        const members = action.payload.result.members;
        const removedPortalMembers = new Set(
          members.filter(res => res.success).map(res => res.result)
        );
        queryPortalMembers
          .getQueryData(state, { portalId: action.meta.arg.portalId })
          .forEach(query => {
            const removed = _remove(query.data.members, id => removedPortalMembers.has(id));
            query.data.total -= removed.length;
          });
      });
  },
  objectBuilder => {
    objectBuilder.addDefaultCase((state, action) => {
      const portalItems = getEntities(action)?.portalMembers;
      if (portalItems) {
        return _merge(state, portalItems);
      }
    });
  }
);
