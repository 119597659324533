import { dismissNotification, showNotification } from "@redux/actions/useNotifications";
import { Middleware } from "@reduxjs/toolkit";

const notifications: Middleware = store => next => action => {
  if (showNotification.match(action)) {
    const timeout = action.payload.link ? 8000 : 5000;
    setTimeout(() => {
      store.dispatch(dismissNotification(action.payload.id));
    }, timeout);
  }
  return next(action);
};

export default notifications;
