/**
 * Metadata sidebar shown when multiple assets are selected.
 */

import React, { useState, useMemo } from "react";
import styled from "styled-components";
import {
  Box,
  Text,
  AssetType,
  ItemType,
  Flex,
  Button,
  useBoolean,
} from "@thenounproject/lingo-core";

import AccordionSection from "../AccordionSection";
import InspectorMultiAssetTags from "./InspectorMultiAssetTags";
import InspectorMultiAssetColors from "./InspectorMultiAssetColors";
import InspectorMultiAssetDownload from "./use/InspectorMultiAssetDownload";
import GalleryContextMenu, { hasContextMenu } from "@features/context-menus/GalleryContextMenu";
import { Inspectable, isAssetInspectable } from "@constants/Inspector";
import InspectorActions from "./InspectorActions";
import InspectorCustomFields from "./InspectorCustomFields";
import { useViewModeContext } from "@contexts/ViewModeProvider";

const Meta = styled(Box).attrs({
  width: "100%",
  height: "100%",
  borderTop: "1px solid border",
  overflow: "auto",
})`
  @media (min-width: 768px) {
    width: 260px;
    border-top: none;
  }
`;

const AssetName = styled(Text).attrs({
  font: "ui.regularBold",
  mb: "xxs",
})`
  overflow-wrap: break-word;
`;

export type Props = {
  inspectables: Inspectable[];
  canEdit: boolean;
};

function InspectorDetailedMultiple({ inspectables, canEdit }: Props) {
  const { setInspectorMode } = useViewModeContext();
  const [colorsExpanded, setColorsExpanded] = useState(true),
    [actionsMenuOpen, setMenuOpen, setMenuClosed] = useBoolean(false);

  const hasNonAssetItems = useMemo(
      () => inspectables.some(i => i.item && i.item?.type !== ItemType.asset),
      [inspectables]
    ),
    hasFiles = useMemo(
      () => inspectables.some(i => isAssetInspectable(i) && i.asset.permalink),
      [inspectables]
    ),
    hasColors = useMemo(
      () => inspectables.some(i => i.asset && i.asset.type === AssetType.color),
      [inspectables]
    ),
    showTags = useMemo(
      () =>
        !hasNonAssetItems &&
        (canEdit || inspectables.some(i => isAssetInspectable(i) && i.asset.keywords.length)),
      [canEdit, hasNonAssetItems, inspectables]
    ),
    contentId = useMemo(() => {
      return inspectables
        .reduce((res, i) => {
          res.push(i.asset?.id ?? i.item?.id);
          return res;
        }, [])
        .join("-");
    }, [inspectables]),
    assetArray = useMemo(() => {
      return inspectables
        .filter(i => (i.item ? i.item.type === ItemType.asset : i.asset))
        .map(i => {
          return i.asset;
        });
    }, [inspectables]);

  const onlyColorAssets = hasColors && !hasFiles && !hasNonAssetItems,
    onlyNonAssetItems = hasNonAssetItems && !hasFiles && !hasColors;

  function renderFileActions() {
    if (onlyNonAssetItems) return null;
    const showMore = canEdit && hasContextMenu(inspectables);
    return (
      <Flex mt="l" mb="s">
        {hasFiles && (
          <InspectorMultiAssetDownload
            inspectables={inspectables}
            styleOverrides={{ mr: "s", px: "none" }}
          />
        )}
        {showMore && (
          <Button
            data-popup-source="asset-actions"
            text="More"
            size="small"
            buttonStyle="outline"
            fontStyle="ui.small"
            icon="navigation.chevron-down"
            truncate={true}
            width={onlyColorAssets ? "100%" : 70}
            px="s"
            onClick={() => setMenuOpen()}
          />
        )}
        {actionsMenuOpen && (
          <GalleryContextMenu
            inspectables={inspectables}
            onCloseMenu={() => setMenuClosed()}
            canEdit={canEdit}
            popupSource="asset-actions"
          />
        )}
      </Flex>
    );
  }

  function renderHeaderSection() {
    return (
      <Box p="m" borderBottom="default">
        <Flex justifyContent="space-between" alignItems="center">
          <AssetName>{inspectables.length} selected</AssetName>
        </Flex>
        {renderFileActions()}
      </Box>
    );
  }

  function renderNonAssetActions() {
    if (!onlyNonAssetItems) return null;
    return (
      <AccordionSection>
        <InspectorActions inspectables={inspectables} canEdit={canEdit} />
      </AccordionSection>
    );
  }

  function renderColors() {
    if (!hasColors) return null;

    const colorsProps = { inspectables };
    return (
      <AccordionSection
        title="Copy colors"
        expanded={colorsExpanded}
        setExpanded={setColorsExpanded}
        contentId={contentId}>
        <InspectorMultiAssetColors {...colorsProps} />
      </AccordionSection>
    );
  }

  function renderSharedTags() {
    if (!showTags) return null;

    const assetTagsProps = {
      inspectables,
      canEdit,
    };
    return <InspectorMultiAssetTags {...assetTagsProps} />;
  }

  function renderCustomFields() {
    if (hasNonAssetItems) return null;
    return (
      <AccordionSection>
        <InspectorCustomFields assets={assetArray} canEdit={canEdit} />
      </AccordionSection>
    );
  }

  return (
    <Meta data-testid="inspector-detailed-multiple">
      <Button
        icon="navigation.chevron-right"
        buttonStyle="tertiary"
        size="small"
        onClick={() => setInspectorMode("simple")}
        width="28px"
        height="28px"
        p="6"
        position="absolute"
        top={8}
        right={8}
        zIndex={1}
      />
      {renderHeaderSection()}
      {renderNonAssetActions()}
      {renderColors()}
      {renderSharedTags()}
      {renderCustomFields()}
    </Meta>
  );
}

export default InspectorDetailedMultiple;
