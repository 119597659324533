import { Space, API } from "@thenounproject/lingo-core";
import { SearchFilterPreset } from "../types";
import { createQueryAction } from "@actions/actionCreators";

type Data = { presets: SearchFilterPreset[] };
type Args = { spaceId: Space["id"] };

const [useAssetLibraryPresets, fetchAssetLibraryPresets] = createQueryAction<Args, Data>(
  {
    entity: "search",
    action: "fetchAssetLibraryPresets",
    denormalize: false,
  },
  async ({ args }) => {
    return await API.call<Data>({
      endpoint: `search/spaces/${args.spaceId}/presets?context=library`,
      method: "GET",
    });
  }
);

export default useAssetLibraryPresets;
export { fetchAssetLibraryPresets };
