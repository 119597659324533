import React from "react";
import styled from "styled-components";
import { Text, buildURL, Icon } from "@thenounproject/lingo-core";

const PoweredBy = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default function PoweredByLingo() {
  return (
    <PoweredBy
      href={buildURL(
        "/",
        {},
        { utm_source: "kit_footer", utm_medium: "web", utm_campaign: "powered_by_lingo" }
      )}
      className="powered-by"
      data-testid="powered-by-lingo">
      <Text as="span" color="grayDarker" font="ui.small">
        Powered by
      </Text>
      <Icon width="40" ml="6px" fill="primary" iconId="logo.full" />
    </PoweredBy>
  );
}
