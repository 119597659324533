import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "@actions/actionCreators";

export type PortalUpdates = {
  display_order?: string;
  data?: { content?: string; background_color?: string };
};

type Args = {
  portalItemId: string;
  updates: PortalUpdates;
};

const [useUpdatePortalItem, updatePortalItem] = createAsyncAction(
  "portalItems/update",
  async ({ portalItemId, updates }: Args) =>
    await API.call<string>({
      endpoint: `portal_items/${portalItemId}`,
      method: "PUT",
      entity: API.Entity.portalItem,
      data: updates,
    })
);

export default useUpdatePortalItem;
export { updatePortalItem };
