import React from "react";
import styled from "styled-components";
import {
  Flex,
  styles,
  Icon,
  PopupMenu,
  Text,
  //Space,
  Portal,
} from "@thenounproject/lingo-core";

import { PortalInsertType } from "../../constants/portalInsertType";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import { type InsertPosition } from "@actions/uploads";
import { useSelectSpace } from "@selectors/entities/spaces";

const MenuItem = styled.button`
  display: flex;
  padding: 8px 16px;
  background: transparent;

  transition: background 0.3s ease;

  &:hover {
    background: ${styles.color.grayLighter};
  }
`;

type Props = {
  portal: Portal;
  insertPosition: InsertPosition;
  // If showing manually from a source (should not be provided with coordinates)
  sourceId?: string;
  coordinates?: { xPos: number | string; yPos: number | string };
  close: () => void;
};

// type Context = { space: Space };

const InsertPortalKitMenu: React.FC<Props> = ({
  insertPosition,
  coordinates,
  close,
  sourceId = "insert-portal-asset-menu",
  portal,
}) => {
  const space = useSelectSpace();

  const { showModal } = useShowModal();

  const assetMenuItems = [
    {
      type: PortalInsertType.kit,
      title: "Create new kit",
      icon: "action.add",
      label: "Add an empty kit to the portal",
      modal: ModalTypes.CREATE_KIT,
    },
    {
      type: PortalInsertType.kit,
      title: "Select exisiting kits",
      icon: "content.kit",
      label: "Add existing kits to the portal",
      modal: ModalTypes.ADD_EXISTING_KIT,
    },
  ];

  const menuItemModalProps = {
    [PortalInsertType.kit]: { insertPosition, portalId: portal.id, space: space },
  };

  const menu = (
    <PopupMenu
      width={320}
      source={sourceId}
      close={close}
      vPos="floatBelow"
      hPos="alignLeft"
      maxHeight={500}>
      <Text py="xs" px="m" font="ui.smallBold" color="grayDarkest">
        Add kits to portal
      </Text>
      {assetMenuItems.map((item, idx) => {
        return (
          <MenuItem
            data-testid={"portal-menu-item-".concat(item.type).toLowerCase()}
            key={`${item.type}- ${idx}`}
            onClick={() => showModal(item.modal, menuItemModalProps[item.type])}>
            <Flex
              borderRadius="default"
              flexShrink="0"
              background="grayLighter"
              justifyContent="center"
              alignItems="center"
              height="40px"
              width="40px"
              mr="s">
              <Icon iconId={item.icon} />
            </Flex>
            <Flex flexDirection="column" textAlign="left">
              <Flex alignItems="center">
                <Text font="ui.regular">{item.title}</Text>
              </Flex>
              <Text font="ui.small" color="grayDarkest">
                {item.label}
              </Text>
            </Flex>
          </MenuItem>
        );
      })}
    </PopupMenu>
  );

  return coordinates ? (
    <PopupMenu.ContextMenuAnchor data-popup-source={sourceId} {...coordinates}>
      {menu}
    </PopupMenu.ContextMenuAnchor>
  ) : (
    menu
  );
};

export default InsertPortalKitMenu;
