/* eslint-disable complexity */
import React, { useState, useRef, useCallback } from "react";
import styled from "styled-components";
import { Flex, PortalItemType } from "@thenounproject/lingo-core";

import { getDragDirection } from "@helpers/drag";
import {
  portalInsertData as portalInsertDataMap,
  PortalInsertType,
} from "../../constants/portalInsertType";

import useCreatePortalFileAssets from "@redux/actions/portalItems/useCreatePortalFileAssets";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import useNotifications from "@actions/useNotifications";

import { useGetDraggingState } from "@selectors/getters";
import useSetDraggingEntity, { DragEntities } from "@actions/useSetDraggingEntity";
import useCreatePortalItems from "@redux/actions/portalItems/useCreatePortalItems";
import useReorderPortalItems from "@redux/actions/portalItems/useReorderPortalItems";

type DropZoneProps = {
  catchAllDrag: boolean;
  dragEnabled: boolean;
};

const DropZone = styled.div<DropZoneProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 5;

  ${props =>
    props.catchAllDrag &&
    `
    border: 2px dashed ${props.theme.primaryColor};
    border-radius: 4px;
    `}

  /**
     *  The drop borders are generated by the dragPosition value.
     *  They will match the .drag-{direction} format
     */

  &:before {
    content: "";
    position: absolute;
    height: 4px;
    border-radius: 12px;
    width: calc(100% - 20px);
    background: ${props => props.theme.primaryColor};
    opacity: 0;
  }

  &.drag-left:before {
    height: 100%;
    width: 4px;
    top: 0;
    left: -2px;
    opacity: 1;
  }

  &.drag-right:before {
    height: 100%;
    width: 4px;
    top: 0;
    right: -2px;
    opacity: 1;
  }

  &.drag-top:before {
    top: -4px;
    left: 10px;
    opacity: 1;
  }

  &.drag-bottom:before {
    bottom: -4px;
    left: 10px;
    opacity: 1;
  }
  /**
  * We want the user to be able to click through the dropzone if they aren't drag/dropping
  */
  ${props =>
    !props.dragEnabled &&
    `
    pointer-events: none;
  `}
`;

export const DropZoneWrapper = styled(Flex).attrs<typeof Flex>(props => {
  return {
    width: props.width || "100%",
    mb: props.mb || "m",
    mt: props.mt || "none",
    position: "relative",
  };
})``;

type Props = {
  itemType?: PortalItemType;
  itemIndex?: number;
  portalItemId?: string;
  portalId: string;
  startEditingItem: (itemId: string) => void;
  itemWrapperRef?: React.MutableRefObject<HTMLDivElement>;
  nextItemId?: string;
  prevItemId?: string;
  catchAll?: boolean;
  openInsertAssetMenu?: (e, insertPosition) => void;
};

function PortalDropZone({
  itemType,
  itemIndex,
  portalItemId,
  portalId,
  startEditingItem,
  nextItemId,
  catchAll,
  openInsertAssetMenu,
}: Props) {
  const [dragPosition, setDragPosition] = useState<ReturnType<typeof getDragDirection>>(null);
  const [catchAllDrag, setCatchAllDrag] = useState(false);
  const dragRef = useRef(null);
  const { showModal } = useShowModal();

  const draggingState = useGetDraggingState();
  const dragEnabled = draggingState.entity === DragEntities?.PORTAL_ITEM;

  const [createPortalItems] = useCreatePortalItems();
  const [createPortalFileAssets] = useCreatePortalFileAssets();

  const { showNotification } = useNotifications();
  const setDraggingEntity = useSetDraggingEntity();
  const [reorderPortalItems] = useReorderPortalItems();

  /**
   * When the drag leaves a dropzone
   */
  const endLocalDrag = useCallback(() => {
    setCatchAllDrag(false);
    setDragPosition(null);
  }, []);

  /**
   * When the is dropped on a dropzone
   */
  const endGlobalDrag = useCallback(() => {
    endLocalDrag();
    setDraggingEntity({ entity: undefined });
  }, [endLocalDrag, setDraggingEntity]);

  const _handleDragOver = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      if (!dragEnabled) return;
      /**
       * preventDefault is required so that onDragOver
       * does not overwrite the onDrop event.
       * https://stackoverflow.com/questions/50230048/react-ondrop-is-not-firing/50230145
       */
      e.preventDefault();
      /**
       * If it's a catch-all dropzone, we just need the boolean value
       */
      if (catchAll) return setCatchAllDrag(true);
      /**
       * Since the drag event runs at a very high interval, only set the state
       * if one of the major axis sides has changed to avoid constant rerenders
       */
      const newDragPosition = getDragDirection({
        e,
        dragRef,
        itemType,
      });
      if (dragPosition === newDragPosition) return;
      setDragPosition(newDragPosition);
    },
    [catchAll, dragEnabled, dragPosition, itemType]
  );

  const _handleDragLeave = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      if (!dragEnabled) return;
      e.preventDefault();
      endLocalDrag();
    },
    [dragEnabled, endLocalDrag]
  );

  const _handleDrop = useCallback(
    async (e: React.DragEvent<HTMLDivElement>) => {
      if (!dragEnabled) return;
      e.preventDefault();

      // Shared variables for both reordering and inserting new items
      const before = ["top", "left"].includes(dragPosition);

      const displayOrder = before ? `before:${portalItemId}` : `after:${portalItemId}`;

      // If its a reorder, run reorder action & end the drag.
      let reorderItemIds: string | string[] = e.dataTransfer.getData("reorderItemIds");
      if (reorderItemIds) {
        reorderItemIds = reorderItemIds.split(",") as string[];

        void reorderPortalItems({
          reorderItemIds,
          displayOrder,
          portalId,
        });
        return endGlobalDrag();
      }
      const portalInsertType = e.dataTransfer.getData("insertType");

      const portalInsertData = portalInsertDataMap[portalInsertType];

      const insertPosition = {
        displayOrder,
        insertIndex: before ? itemIndex : itemIndex + 1,
        portalId,
        nextItemId,
      };

      if (catchAll) {
        insertPosition.displayOrder = "append";
        insertPosition.insertIndex = 0;
      }

      // Callback for adding an item with an asset
      const onSelectFiles = files =>
        createPortalFileAssets({
          files,
          itemType: PortalItemType.supportingImage,
          insertPosition,
          portalId,
        });

      if (portalInsertType === PortalInsertType.kit) {
        endGlobalDrag();
        openInsertAssetMenu(e, insertPosition);
      } else if (portalInsertType === PortalInsertType.supportingImage) {
        // If it's a support asset, launch the file picker with callback
        endGlobalDrag();
        showModal(ModalTypes.PICK_FILE, {
          onUploadFiles: onSelectFiles,
          itemType: portalInsertData.type,
          multiple: false,
        });
      } else if (portalInsertType) {
        const newItem = {
          type: portalInsertData.type,
          text: portalInsertData.data.content,
        };

        const insertConfig = {
          insertIndex: insertPosition.insertIndex,
          displayOrder: insertPosition.displayOrder,
          targetIndex: itemIndex,
          dragPosition,
          insertType: portalInsertType,
        };
        endGlobalDrag();

        const { error, response } = await createPortalItems({
          portalId,
          items: [newItem],
          insertPosition: insertConfig,
        });
        if (error) {
          showNotification({ message: error.message, level: "error" });
        } else if (startEditingItem) {
          const editableTypes = [PortalItemType.heading, PortalItemType.note];

          const newItem = response.entities.portalItems[response.result.portalItems[0].result];
          if (editableTypes.includes(newItem.type)) startEditingItem(newItem.id);
        }
      }
    },
    [
      dragEnabled,
      dragPosition,
      portalItemId,
      itemIndex,
      portalId,
      catchAll,
      createPortalItems,
      reorderPortalItems,
      endGlobalDrag,
      nextItemId,
      showModal,
      createPortalFileAssets,
      startEditingItem,
      showNotification,
      openInsertAssetMenu,
    ]
  );

  return (
    <>
      <DropZone
        data-testid="portal-drop-zone"
        ref={dragRef}
        onDragOver={_handleDragOver}
        onDragLeave={_handleDragLeave}
        onDrop={_handleDrop}
        catchAllDrag={catchAllDrag}
        dragEnabled={dragEnabled}
        className={dragPosition ? `drag-${dragPosition}` : null}
      />
    </>
  );
}

export default PortalDropZone;
