import { createAsyncAction } from "../actionCreators";
import { API, camelize } from "@thenounproject/lingo-core";
import { FigmaIntegrationTeam } from "./useFigmaIntegrationStatus";

type Args = {
  spaceId: number;
  teamId: string;
};

const [useAddFigmaTeam, addFigmaTeam] = createAsyncAction(
  "figma/addFigmaTeam",
  async ({ spaceId, teamId }: Args) => {
    const res = await API.call<{ team: FigmaIntegrationTeam }>({
      endpoint: `spaces/${spaceId}/figma/team`,
      method: "POST",
      data: { team_id: teamId },
    });
    return { result: camelize(res.result.team) };
  }
);

export default useAddFigmaTeam;
export { addFigmaTeam };
