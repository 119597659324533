import React from "react";
import { Button, pluralize, BoxProps } from "@thenounproject/lingo-core";

import MultiAssetDownload from "./MultiAssetDownload";

import { Inspectable } from "@constants/Inspector";

type Props = {
  inspectables: Inspectable[];
  styleOverrides?: BoxProps;
};

const InspectorMultiAssetDownload: React.FC<Props> = ({ inspectables, styleOverrides }) => {
  return (
    <MultiAssetDownload inspectables={inspectables}>
      {({ buttonProps, numItems }) => (
        <Button
          id="asset-download-button"
          text={`Download ${numItems} ${pluralize("file", numItems)}`}
          icon="download"
          width="100%"
          size="small"
          {...buttonProps}
          {...styleOverrides}
        />
      )}
    </MultiAssetDownload>
  );
};

export default InspectorMultiAssetDownload;
