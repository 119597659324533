import { API, camelize } from "@thenounproject/lingo-core";
import { createQueryAction } from "@actions/actionCreators";

export type ChargePreview = {
  newCharges?: number;
  existingCredit: number;
  // plan: string;
  prorationDate: number;
  // spaceId: number;
  total: number;
};

const [useChargePreview, fetchChargePreview] = createQueryAction<
  { spaceId: number; plan: string },
  ChargePreview,
  ChargePreview
>(
  {
    entity: "spaces",
    action: "fetchChargePreview",
    denormalize: false,
  },
  async ({ args }) => {
    const res = await API.call<{ charge_preview: ChargePreview }>({
      endpoint: `spaces/${args.spaceId}/subscription/preview/?plan=${args.plan}`,
      method: "GET",
    });
    return {
      result: camelize(res.result.charge_preview) as ChargePreview,
    };
  }
);

export default useChargePreview;
export { fetchChargePreview };
