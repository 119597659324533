import { AssetType, ItemType } from "@thenounproject/lingo-core";

export enum InsertType {
  file = "FILE",
  color = "COLOR",
  font = "FONT",
  figma = "FIGMA",
  heading = "HEADING",
  note = "NOTE",
  supportContent = "SUPPORT_CONTENT",
  code = "CODE",
  guide = "GUIDE",
  assets = "ASSETS",
  library = "LIBRARY",
  link = "LINK",
  gallery = "GALLERY",
}

export const insertData = {
  [InsertType.file]: { type: ItemType.asset },
  [InsertType.link]: { type: ItemType.asset },
  [InsertType.color]: {
    type: ItemType.asset,
    asset: { type: AssetType.color },
  },
  [InsertType.font]: { type: ItemType.asset },
  [InsertType.heading]: {
    type: ItemType.heading,
    data: { content: "Heading" },
  },
  [InsertType.note]: {
    type: ItemType.note,
    data: { content: "Add a note..." },
  },
  [InsertType.supportContent]: { type: ItemType.supportingImage },
  [InsertType.code]: {
    type: ItemType.codeSnippet,
    data: {
      content: "Add code...",
    },
  },
  [InsertType.guide]: {
    type: ItemType.guide,
    data: {
      title: "DO",
      content: "Add guideline...",
      display_style: "image",
      display_size: 0,
    },
  },
};
