import styled from "styled-components";

import { Flex, ElementPositioningProps } from "@thenounproject/lingo-core";

const ModalBody = styled(Flex).attrs<ElementPositioningProps>(props => {
  return {
    flexDirection: "column",
    px: props.px || "80px",
    py: props.py || "24px",
    textAlign: "left",
    overflow: "auto",
    variations: {
      "mq.s": {
        px: props.px || "16px",
      },
    },
  };
})``;

export default ModalBody;
