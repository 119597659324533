import { API } from "@thenounproject/lingo-core";
import { showNotification } from "@actions/useNotifications";
import { createAsyncAction } from "../actionCreators";

type Args = {
  spaceId: number;
  kitId: string;
  assetId: string;
};
const [useUpdateKitCoverWithAsset, updateKitCoverWithAsset] = createAsyncAction(
  "kits/updateCoverWithAsset",
  async ({ spaceId, kitId, assetId }: Args, thunkApi) => {
    try {
      const res = await API.call<string>({
        endpoint: `kits/${spaceId}/${kitId}/images/cover`,
        method: "PUT",
        data: {
          asset_uuid: assetId,
        },
        entity: API.Entity.kit,
      });
      thunkApi.dispatch(showNotification({ message: "Kit cover updated" }));
      return res;
    } catch (err) {
      thunkApi.dispatch(showNotification({ message: err.message, level: "error" }));
      return err;
    }
  }
);

export default useUpdateKitCoverWithAsset;
export { updateKitCoverWithAsset };
