import React, { Fragment, useState, useCallback } from "react";
import _debounce from "lodash/debounce";
import { OptionCard, Box, Text, Flex, Select, Kit } from "@thenounproject/lingo-core";

import useKits from "@actions/kits/useKits";

import ModalHeader from "../../../components/ModalHeader";
import ModalBody from "../../../components/ModalBody";
import ModalFooter from "../../../components/ModalFooter";
import useShowModal from "@redux/actions/useModals";
import useNavPoint from "@hooks/useNavPoint";

export type KitCallbackArgs = {
  selectedKit: Kit;
  copyAs: string;
};
export type Props = {
  type: "move" | "copy";
  callback: (kitCallbackArgs: KitCallbackArgs) => void;
  entityText?: string;
};

enum AssetCopyTypes {
  reference = "reference",
  duplicate = "duplicate",
}

type ReferenceOptionProps = {
  title: string;
  body: string;
};
export const ReferenceOption: React.FC<ReferenceOptionProps> = ({ title, body }) => {
  return (
    <Flex flexDirection="column">
      <Text>{title}</Text>
      <Text font="ui.small" color="grayDarkest">
        {body}
      </Text>
    </Flex>
  );
};

const MoveCopyToKitModal: React.FC<Props> = ({ type, callback, entityText }) => {
  const navPoint = useNavPoint();
  const { data } = useKits({ spaceId: navPoint?.spaceId });
  const { kits = [] } = data || {};
  const [selectedKitId, setSelectedKitId] = useState(null);
  const [copyAs, setCopyAs] = useState(AssetCopyTypes.reference);
  const { dismissModal } = useShowModal();

  const selectedKit = kits?.find(k => k.kitId === selectedKitId);

  const isMoving = type === "move";
  const verbActive = isMoving ? "Moving" : "Copying";
  const buttonText = isMoving ? "Move" : "Copy";

  const onSubmit = useCallback(() => {
    callback({ selectedKit, copyAs });
    dismissModal();
  }, [callback, dismissModal, copyAs, selectedKit]);

  const renderListHeader = () => {
    return <Text font="ui.regularSemiBold">All kits</Text>;
  };

  return (
    <Fragment>
      <ModalHeader title={`${isMoving ? "Move" : "Copy"} to`} />
      <ModalBody>
        <Flex mb="m" justifyContent="space-between" alignItems="center">
          {entityText && (
            <Text font="ui.regularBold">
              {verbActive} {entityText}
            </Text>
          )}
          {!isMoving && (
            <Flex alignItems="center">
              <Text mr="s">Copy assets as:</Text>
              <Select
                options={[
                  {
                    value: AssetCopyTypes.reference,
                    label: "Reference",
                    labelNode: (
                      <ReferenceOption
                        title={"Reference"}
                        body={
                          "References are copies linked to the original asset. Any changes to these assets are reflected in all references."
                        }
                      />
                    ),
                  },
                  {
                    value: AssetCopyTypes.duplicate,
                    label: "Duplicate",
                    labelNode: (
                      <ReferenceOption
                        title={"Duplicate"}
                        body={"Duplicates are new assets copied from the original."}
                      />
                    ),
                  },
                ]}
                onChange={val => setCopyAs(AssetCopyTypes[val])}
                size="small"
                buttonStyle="dropdown"
                value={copyAs}
              />
            </Flex>
          )}
        </Flex>
        <Box border="default" borderRadius="default">
          <Box px="m" py="s" borderBottom="default">
            {renderListHeader()}
          </Box>
          <Box p="m">
            {kits?.map(k => {
              if (isMoving && k.kitId === navPoint.kitId) return null;
              return (
                <OptionCard
                  key={k.kitId}
                  size="small"
                  onClick={() => setSelectedKitId(k.kitId)}
                  title={k.name || "Untitled kit"}
                  cardStyle="radio"
                  selected={selectedKitId === k.kitId}
                  image={{
                    src: k.images.cover,
                    icon: "content.kit",
                    style: "rounded",
                  }}
                />
              );
            })}
          </Box>
        </Box>
      </ModalBody>
      <ModalFooter
        primary={{
          text: buttonText,
          onClick: onSubmit,
          disabled: !selectedKitId,
        }}
      />
    </Fragment>
  );
};

export default MoveCopyToKitModal;
