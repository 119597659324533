import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  spaceId: number | string;
  kitId: string;
};

const [useClearRecentlyDeleted, clearRecentlyDeleted] = createAsyncAction(
  "items/clearRecentlyDeleted",
  async ({ kitId, spaceId }: Args) => {
    await API.call<void>({
      endpoint: `kits/${spaceId}/${kitId}/trash`,
      method: "DELETE",
      entity: API.Entity.item,
    });
  }
);
export default useClearRecentlyDeleted;
export { clearRecentlyDeleted };
