import { API, Kit, base64 } from "@thenounproject/lingo-core";

import { createQueryAction } from "@actions/actionCreators";
import { DEFAULT_PAGE_SIZE } from "../actionCreators/createQueryAction";

type Args = {
  portalId: string;
};

type NormalizedResult = {
  kits: string[];
  total: number;
};

type Result = {
  kits: Kit[];
  total: number;
};

const [usePortalKits, queryPortalKits] = createQueryAction<Args, Result, NormalizedResult>(
  {
    entity: "kits",
    action: "query",
    condition: ({ args: { portalId } }) => Boolean(portalId),
    pagingKey: "kits",
    denormalize: {
      entity: null,
      kits: {
        entity: "kits",
      },
    },
  },
  async ({ args, paging }) => {
    return await API.call<NormalizedResult>({
      endpoint: `portals/${args.portalId}/kits`,
      method: "GET",
      entity: API.Entity.kit,
      query: {
        query: base64.encode({
          limit: DEFAULT_PAGE_SIZE,
          offset: DEFAULT_PAGE_SIZE * (paging.page - 1),
        }),
      },
    });
  }
);

export default usePortalKits;
export { queryPortalKits };
