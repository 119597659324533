import _merge from "lodash/merge";
import _remove from "lodash/remove";

import { DownloadRequest, DownloadRequestStatus } from "@thenounproject/lingo-core";

import createEntityReducer from "../helpers/createEntityReducer";
import { QueryData } from "@redux/actions/actionCreators/createQueryAction";

import { updateDownloadRequest } from "@redux/actions/downloadRequests/updateDownloadRequests";
import { fetchDownloadRequests } from "@redux/actions/downloadRequests/useDownloadRequests";

function updateRequestQuery(
  state: Record<number, QueryData<unknown, unknown>>,
  request: DownloadRequest,
  spaceId: number,
  status: DownloadRequestStatus,
  change: "add" | "remove"
) {
  fetchDownloadRequests
    .getQueryData(state, {
      spaceId: spaceId,
      status: status,
    })
    .forEach(q => {
      const includes = q.data.requests?.includes(request.id);
      if (change === "add" && includes === false) {
        q.data.requests.push(request.id);
        q.data.total++;
      } else if (change === "remove" && includes === true) {
        _remove(q.data.requests, r => r === request.id);
        q.data.total--;
      }
    });
}

export default createEntityReducer<DownloadRequest>(
  "downloadRequests",
  queryBuilder => {
    queryBuilder.addCase(updateDownloadRequest.fulfilled, (state, action) => {
      const request = action.payload.entities.downloadRequests[action.payload.result];
      updateRequestQuery(
        state,
        request,
        action.meta.arg.spaceId,
        "pending",
        request.status !== "pending" ? "remove" : "add"
      );
      updateRequestQuery(
        state,
        request,
        action.meta.arg.spaceId,
        "approved",
        request.status === "approved" ? "remove" : "add"
      );
      updateRequestQuery(
        state,
        request,
        action.meta.arg.spaceId,
        "denied",
        request.status !== "denied" ? "remove" : "add"
      );
    });
  },
  objectBuilder => {
    objectBuilder.addDefaultCase((state, action: any) => {
      const tokens = (action.response?.entities ?? action.payload?.entities)?.downloadRequests;
      if (tokens) {
        return _merge(state, tokens);
      }
    });
  }
);
