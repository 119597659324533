import React, { useCallback } from "react";
import { Text, Input, CustomField, CustomFieldOperation, Asset } from "@thenounproject/lingo-core";

import useAssetsCustomFieldUpdate from "@redux/actions/assets/useAssetsCustomFieldUpdate";
import { clone } from "lodash";

export interface Props {
  field: CustomField;
  updateCustomFieldValue: ReturnType<typeof useAssetsCustomFieldUpdate>[0];
  assets: Asset[];
  canEdit: boolean;
  assetUuids: string[];
}

export default function TextCustomField({
  field,
  updateCustomFieldValue,
  assets,
  canEdit,
  assetUuids,
}: Props) {
  const assetFieldValues = assets.map(asset => asset.fields?.[field.id]) as string[];
  const fieldValuesAreEqual = assetFieldValues.every(
    fieldValue => fieldValue === assetFieldValues[0]
  );

  const [value, setValue] = React.useState<string>(
    fieldValuesAreEqual ? String(clone(assetFieldValues[0]) || "") : ""
  );
  const [error, setError] = React.useState<string>();

  React.useEffect(() => {
    if (!fieldValuesAreEqual) {
      setValue("");
    } else if (assetFieldValues[0] !== value) {
      setValue(assetFieldValues[0] || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assets]);

  const handleValueUpdate = useCallback(
    async (fieldId: number, value: string) => {
      const { error } = await updateCustomFieldValue({
        assetUuids,
        fieldId,
        value: value || null,
        operation: CustomFieldOperation.set,
      });
      if (error) {
        setError(error.message);
      }
    },
    [assetUuids, updateCustomFieldValue]
  );

  const handleSave = useCallback(async () => {
    if (fieldValuesAreEqual && assetFieldValues.includes(value)) return;
    await handleValueUpdate(field.id, value);
  }, [assetFieldValues, field.id, handleValueUpdate, value, fieldValuesAreEqual]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  }, []);

  if (!canEdit) {
    return (
      <Text font="ui.small">{!fieldValuesAreEqual ? "Varied" : assetFieldValues[0] || "None"}</Text>
    );
  }

  return (
    <Input
      data-testid="inspector-text-field-multi"
      size="small"
      placeholder={"Add some text"}
      value={value}
      onChange={handleChange}
      onSubmit={handleSave}
      onBlur={handleSave}
      type={"text"}
      inputStyle={error ? "error" : null}
      message={error}
    />
  );
}
