/**
 * Styled component used for download/copy button shown on asset thumbnails
 */

import styled, { withTheme } from "styled-components";
import { Button } from "@thenounproject/lingo-core";

type Props = {
  icon?: string;
  buttonPosition?: "center" | "bottom";
};

export const AssetActionButton = styled(Button).attrs<Props>(props => {
  return {
    icon: props.icon || "download",
    size: "small",
    theme: {
      ...props.theme,
      primaryColorTint: "white",
      primaryColor: "black",
    },
    position: "absolute",
    bottom: props.buttonPosition === "center" ? "50%" : "4px",
    transform: props.buttonPosition === "center" ? "translateY(50%)" : null,
    right: props.buttonPosition === "center" ? "s" : "4px",
  };
})<Props>`
  opacity: 0;
  line-height: 0;
`;

export default withTheme(AssetActionButton);
