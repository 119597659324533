// All kits view

import React, { Fragment } from "react";
import styled, { useTheme } from "styled-components";
import { Flex, Box, Kit, KitCollection, Text, SpaceThemeNames } from "@thenounproject/lingo-core";

import KitCollectionCard from "./KitCollectionCard";
import KitList from "../components/KitList";

const AllKitsSection = styled(Flex).attrs({ flexWrap: "wrap" })``;

type Props = {
  kitCollections: KitCollection[];
  kits: Kit[];
};

const CollectionContainer = styled(Flex).attrs(() => {
  return {
    flexDirection: "column",
  };
})``;

const CollectionGridContainer = styled(Box).attrs({ mb: "xxxl" })`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: 1fr;
  grid-auto-rows: auto;
  @media screen and (min-width: 500px) {
    gap: 60px;
  }
  @media screen and (min-width: 768px) {
    gap: 80px;
  }
`;

export const themeVals = {
  [SpaceThemeNames.classic]: {
    favoriteKitList: { columns: 5, kitCardSize: "compact" as const, gap: "16px" },
    normalKitList: { columns: 3, kitCardSize: "normal" as const, gap: "24px 16px" },
    kitCollectionCardsSpacing: { borderTop: "default", mb: "xl", mt: "xl" },
    favoriteKitsTitle: { font: "ui.regularSemiBold", color: "grayDarkest" },
  },
  [SpaceThemeNames.scher]: {
    favoriteKitList: { columns: 4, kitCardSize: "compact" as const, gap: "16px" },
    normalKitList: { columns: 2, kitCardSize: "normal" as const, gap: "16px" },
    kitCollectionCardsSpacing: { mt: "xl" },
    favoriteKitsTitle: { font: "ui.pageTitle" },
  },
  [SpaceThemeNames.wyman]: {
    favoriteKitList: { columns: 4, kitCardSize: "compact" as const, gap: "16px" },
    normalKitList: { columns: 3, kitCardSize: "normal" as const, gap: "24px 16px" },
    kitCollectionCardsSpacing: {},
    favoriteKitsTitle: { font: "ui.title" },
  },
};

const AllKitsView: React.FC<Props> = ({ kits, kitCollections }) => {
  const themeName = useTheme().themeName;
  const { favoriteKits, normalKits } = React.useMemo(() => {
    return kits.reduce(
      (acc, k) => {
        const list = k.access.isFavorite ? acc.favoriteKits : acc.normalKits;
        if (k.status === "active") list.push(k);
        return acc;
      },
      { normalKits: [], favoriteKits: [] }
    );
  }, [kits]);

  function renderFavoriteKits() {
    if (!favoriteKits.length) return null;
    return (
      <AllKitsSection mb="xxxl">
        <Text mb="l" {...themeVals[themeName].favoriteKitsTitle}>
          My Favorites
        </Text>
        <KitList
          kits={favoriteKits}
          identifier="favorites"
          {...themeVals[themeName].favoriteKitList}
        />
      </AllKitsSection>
    );
  }

  function renderNormalKits() {
    if (!normalKits.length) return null;
    return (
      <AllKitsSection>
        <KitList kits={normalKits} {...themeVals[themeName].normalKitList} />
      </AllKitsSection>
    );
  }

  function renderCollections() {
    if (!kitCollections.length) return null;
    const cols = kitCollections.map(k => (
      <Box key={k.uuid} width="100%">
        <KitCollectionCard collection={k} themeName={themeName} />
        <Box {...themeVals[themeName].kitCollectionCardsSpacing} />
      </Box>
    ));

    switch (themeName) {
      case SpaceThemeNames.wyman: {
        return <CollectionGridContainer>{cols}</CollectionGridContainer>;
      }
      case SpaceThemeNames.classic:
      case SpaceThemeNames.scher: {
        return <CollectionContainer>{cols}</CollectionContainer>;
      }
    }
  }
  return (
    <Fragment>
      {renderFavoriteKits()}
      {renderCollections()}
      {renderNormalKits()}
    </Fragment>
  );
};

export default AllKitsView;
