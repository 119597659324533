import { SpaceTheme } from "@thenounproject/lingo-core";
import { NewFiles, SpaceThemeImageOptions } from "@features/theming/types";

export const parseFilesFromTheme = (theme: SpaceTheme): NewFiles => {
  const n: NewFiles = {};
  for (const val in SpaceThemeImageOptions) {
    n[val] = theme[val] || null;
  }
  return n;
};
