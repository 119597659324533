/* eslint-disable complexity */
import React, { useState, useRef, Fragment, useCallback } from "react";
import styled from "styled-components";
import { Flex, ItemType, Item } from "@thenounproject/lingo-core";
import { v4 as genUuid } from "uuid";

import { getDragDirection } from "@helpers/drag";
import { isValidSelfDropPosition } from "@helpers/layoutValidation";
import { insertData as insertDataMap, InsertType } from "../../constants/InsertType";

import useCreateFileAssets from "@redux/actions/items/useCreateFileAssets";
import useBatchSaveItems from "@redux/actions/items/useBatchSaveItems";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import useNotifications from "@actions/useNotifications";
import useReorderSectionItems from "@redux/actions/items/useReorderSectionItems";

import { useGetDraggingState } from "@selectors/getters";
import useSetDraggingEntity, { DragEntities } from "@actions/useSetDraggingEntity";

type DropZoneProps = {
  catchAllDrag: boolean;
  isHalfGuide: boolean;
  isLeftHalfGuide: boolean;
  isRightHalfGuide: boolean;
  dragEnabled: boolean;
};

const DropZone = styled.div<DropZoneProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 5;

  ${props =>
    props.catchAllDrag &&
    `
    border: 2px dashed ${props.theme.primaryColor};
    border-radius: 4px;
    `}

  /**
     *  The drop borders are generated by the dragPosition value.
     *  They will match the .drag-{direction} format
     */

  &:before {
    content: "";
    position: absolute;
    height: 4px;
    border-radius: 12px;
    width: calc(100% - 20px);
    background: ${props => props.theme.primaryColor};
    opacity: 0;
  }

  &.drag-left:before {
    height: 100%;
    width: 4px;
    top: 0;
    left: -2px;
    opacity: 1;
  }

  &.drag-right:before {
    height: 100%;
    width: 4px;
    top: 0;
    right: -2px;
    opacity: 1;
  }

  ${props =>
    !props.isHalfGuide &&
    `
    &.drag-top:before {
      top: -4px;
      left: 10px;
      opacity: 1;
    }

    &.drag-bottom:before {
      bottom: -4px;
      left: 10px;
      opacity: 1;
    }
  `}

  /**
     *  Make the inside drop border between guides seem like one dropzone
     */

  ${props =>
    props.isLeftHalfGuide &&
    `
    &.drag-right:before {
      right: -18px;
    }

  `}
  ${props =>
    props.isRightHalfGuide &&
    `
    &.drag-left:before {
      left: -18px;
    }

  `}



  /**
  * We want the user to be able to click through the dropzone if they aren't drag/dropping
  */
  ${props =>
    !props.dragEnabled &&
    `
    pointer-events: none;
  `}
`;

type HorizontalIndicatorProps = {
  width: string;
  offsetLeft: string;
  offsetTop: string;
};

const HorizontalIndicator = styled.div<HorizontalIndicatorProps>`
  position: fixed;
  border-radius: 12px;
  height: 4px;
  background: ${props => props.theme.primaryColor};
  width: ${props => props.width};
  left: ${props => props.offsetLeft};
  top: ${props => props.offsetTop};
  z-index: 10;
`;

export const DropZoneWrapper = styled(Flex).attrs<typeof Flex>(props => {
  return {
    width: props.width || "100%",
    mb: props.mb || "m",
    mt: props.mt || "none",
    position: "relative",
  };
})``;

type Props = {
  itemType?: ItemType;
  itemDisplayStyle?: Item["data"]["displayStyle"];
  itemIndex?: number;
  itemId?: string;
  kitId: string;
  sectionId: string;
  startEditingItem: (itemId: string) => void;
  guidePosition?: "left" | "right" | "full";
  itemWrapperRef?: React.MutableRefObject<HTMLDivElement>;
  nextItemId?: string;
  prevItemId?: string;
  catchAll?: boolean;
  openInsertAssetMenu?: (e, insertPosition) => void;
};

function GalleryDropZone({
  itemType,
  itemDisplayStyle,
  itemIndex,
  itemId,
  kitId,
  sectionId,
  startEditingItem,
  guidePosition,
  itemWrapperRef,
  nextItemId,
  prevItemId,
  catchAll,
  openInsertAssetMenu,
}: Props) {
  const [dragPosition, setDragPosition] = useState<ReturnType<typeof getDragDirection>>(null);
  const [catchAllDrag, setCatchAllDrag] = useState(false);
  const dragRef = useRef(null);

  const draggingState = useGetDraggingState();
  const dragEnabled = draggingState.entity === DragEntities?.GALLERY_ITEM;

  const [createFileAssets] = useCreateFileAssets();
  const [batchSaveItems] = useBatchSaveItems();
  const { showModal } = useShowModal();
  const { showNotification } = useNotifications();
  const setDraggingEntity = useSetDraggingEntity();
  const [reorderSectionItems] = useReorderSectionItems();

  /**
   * When the drag leaves a dropzone
   */
  const endLocalDrag = useCallback(() => {
    setCatchAllDrag(false);
    setDragPosition(null);
  }, []);

  /**
   * When the is dropped on a dropzone
   */
  const endGlobalDrag = useCallback(() => {
    endLocalDrag();
    setDraggingEntity({ entity: undefined });
  }, [endLocalDrag, setDraggingEntity]);

  const _handleDragOver = useCallback(
    e => {
      if (!dragEnabled) return;
      /**
       * preventDefault is required so that onDragOver
       * does not overwrite the onDrop event.
       * https://stackoverflow.com/questions/50230048/react-ondrop-is-not-firing/50230145
       */
      e.preventDefault();
      /**
       * If it's a catch-all dropzone, we just need the boolean value
       */
      if (catchAll) return setCatchAllDrag(true);
      /**
       * Since the drag event runs at a very high interval, only set the state
       * if one of the major axis sides has changed to avoid constant rerenders
       */
      const newDragPosition = getDragDirection({
        e,
        dragRef,
        itemType,
        guidePosition,
        itemDisplayStyle,
      });
      if (dragPosition === newDragPosition) return;
      setDragPosition(newDragPosition);
    },
    [catchAll, dragEnabled, dragPosition, guidePosition, itemDisplayStyle, itemType]
  );

  const _handleDragLeave = useCallback(
    e => {
      if (!dragEnabled) return;
      e.preventDefault();
      endLocalDrag();
    },
    [dragEnabled, endLocalDrag]
  );

  const _handleDrop = useCallback(
    async e => {
      if (!dragEnabled) return;
      e.preventDefault();

      // Shared variables for both reordering and inserting new items
      const before = ["top", "left"].includes(dragPosition);
      const displayOrder = before ? `before:${itemId}` : `after:${itemId}`;

      // If its a reorder, run reorder action & end the drag.
      let reorderItemIds = e.dataTransfer.getData("reorderItemIds");
      if (reorderItemIds) {
        reorderItemIds = reorderItemIds.split(",");

        /**
         * End the drag if trying to drop reorder selection on top of one of the selected items
         * unless we're dealing with a valid self-drop (reordering half guide on its own top/bottom)
         */
        const isSelfDrop =
            reorderItemIds && reorderItemIds.length === 1 && reorderItemIds.includes(`${itemId}-0`),
          validSelfDropPosition = isValidSelfDropPosition(dragPosition, guidePosition),
          validSelfDrop = isSelfDrop && validSelfDropPosition;

        if (isSelfDrop && !validSelfDropPosition) {
          return endGlobalDrag();
        }

        void reorderSectionItems({
          reorderItemIds,
          displayOrder,
          sectionId,
          guidePosition,
          dragPosition,
          validSelfDrop,
        });
        return endGlobalDrag();
      }

      const rawFileDrop = e.dataTransfer.types && e.dataTransfer.types.includes("Files"),
        insertType =
          e.dataTransfer.getData("insertType") || (rawFileDrop ? InsertType.file : undefined);
      const insertData = insertDataMap[insertType] || null;

      const insertPosition = {
        displayOrder,
        insertIndex: before ? itemIndex : itemIndex + 1,
        kitId,
        sectionId,
        nextItemId,
      };

      /**
       * If its a catchall dropzone, just append it to the empty section
       *
       * TODO: Maybe we could use this in the background of sections that have items as well.
       * In which case we'd need to figure out how to handle the insert index, etc.
       */
      if (catchAll) {
        insertPosition.displayOrder = "append";
        insertPosition.insertIndex = 0;
      }
      /**
       * If we're dropping on bottom of left guide, or top of right guide,
       * adjust the insert index & display order
       */
      if (guidePosition === "left" && dragPosition === "bottom") {
        insertPosition.insertIndex += 1;
        insertPosition.displayOrder = `after:${nextItemId}`;
      }
      if (guidePosition === "right" && dragPosition === "top") {
        insertPosition.insertIndex -= 1;
        insertPosition.displayOrder = `before:${prevItemId}`;
      }

      // Callback for adding an item with an asset
      const onSelectFiles = files =>
        createFileAssets({
          files,
          itemType:
            insertType === InsertType.supportContent ? ItemType.supportingImage : ItemType.asset,
          insertPosition,
        });

      // If it's a set of raw dragged files, upload them directly
      if (rawFileDrop) {
        endGlobalDrag();
        e.stopPropagation();
        e.persist(); // NOTE: Remove with react 17: https://reactjs.org/docs/events.html

        const files = (e.dataTransfer && Array.from(e.dataTransfer.files)) || [];
        void onSelectFiles(files);
      } else if (insertType === InsertType.assets) {
        // If it's an asset, open the asset insert menu
        endGlobalDrag();
        openInsertAssetMenu(e, insertPosition);
      } else if (insertType === InsertType.supportContent) {
        // If it's a support asset, launch the file picker with callback
        endGlobalDrag();
        showModal(ModalTypes.PICK_FILE, {
          onUploadFiles: onSelectFiles,
          itemType: insertData.type,
          multiple: false,
        });
      } else if (insertType) {
        /**
         * Default to use the items/sync endpoint to both:
         * A) Add a new item created by the drop
         * B) Update any surrounding items affected by guide drag/drop
         */
        const newItem = {
          uuid: genUuid().toUpperCase(),
          kit_uuid: kitId,
          section_uuid: sectionId,
          display_order: insertPosition.displayOrder,
          ...insertData,
        };
        endGlobalDrag();
        const res = await batchSaveItems({
          updatedItems: [],
          insertConfig: {
            sectionId,
            newItems: [newItem],
            insertIndex: insertPosition.insertIndex,
            targetIndex: itemIndex,
            guidePosition,
            dragPosition,
            insertType,
          },
        });
        const { response: { newItems } = {}, error: responseError } = res;
        if (responseError) {
          showNotification({ message: responseError.message, level: "error" });
        } else if (startEditingItem) {
          const editableTypes = [
            ItemType.heading,
            ItemType.note,
            ItemType.codeSnippet,
            ItemType.guide,
          ];
          const item = newItems[0];
          if (editableTypes.includes(item.type)) startEditingItem(item.uuid);
        }
      }
    },
    [
      dragEnabled,
      dragPosition,
      itemId,
      itemIndex,
      kitId,
      sectionId,
      catchAll,
      guidePosition,
      reorderSectionItems,
      endGlobalDrag,
      nextItemId,
      prevItemId,
      createFileAssets,
      showModal,
      batchSaveItems,
      startEditingItem,
      showNotification,
      openInsertAssetMenu,
    ]
  );

  /**
   * Render a fixed fullwidth horizontal drop indicator
   * if the dropZone is on a half guide & the drop is top/bottom
   */
  const isHalfGuide = guidePosition && ["left", "right"].includes(guidePosition),
    isRightHalfGuide = guidePosition && ["right"].includes(guidePosition),
    isLeftHalfGuide = guidePosition && ["left"].includes(guidePosition),
    shouldRenderHorizontalGuideIndicator = isHalfGuide && ["top", "bottom"].includes(dragPosition);

  function renderHorizontalGuideIndicator() {
    if (!shouldRenderHorizontalGuideIndicator) return null;
    const width = itemWrapperRef.current && itemWrapperRef.current.scrollWidth;

    let top, bottom, x;
    if (dragRef.current) {
      ({ top, bottom } = dragRef.current.getBoundingClientRect());
    }
    if (itemWrapperRef.current) {
      ({ x } = itemWrapperRef.current.getBoundingClientRect());
    }

    const topMap = {
      // Arbitrary offset values for consistent styling
      top: top + 4,
      bottom: bottom + 4,
    };

    return (
      <HorizontalIndicator
        // Values offset here to account for padding on ItemWrapper
        width={`${width - 20}px`}
        offsetLeft={`${x + 10}px`}
        offsetTop={`${topMap[dragPosition]}px`}
      />
    );
  }

  return (
    <Fragment>
      <DropZone
        data-testid="gallery-drop-zone"
        ref={dragRef}
        onDragOver={_handleDragOver}
        onDragLeave={_handleDragLeave}
        onDrop={_handleDrop}
        catchAllDrag={catchAllDrag}
        dragEnabled={dragEnabled}
        className={dragPosition ? `drag-${dragPosition}` : null}
        isHalfGuide={isHalfGuide}
        isRightHalfGuide={isRightHalfGuide}
        isLeftHalfGuide={isLeftHalfGuide}
      />
      {renderHorizontalGuideIndicator()}
    </Fragment>
  );
}

export default GalleryDropZone;
