/**
 * Renders a table containing Sketch asset style attributes, intended to be displayed in the sidebar
 * of the AssetDetail component.
 */

import React from "react";
import { TinyColor } from "@ctrl/tinycolor";

import AssetMetaTable from "./AssetMetaTable";

type Props = {
  styles: {
    font_display_name?: string;
    font_size?: string;
    font_weight?: string;
    color?: string;
    letter_spacing?: string;
    line_height?: string;
    text_decoration?: string;
    fills?: string[];
    borders?: string[];
    shadows?: string[];
    inner_shadows?: string[];
  };
};

const SketchStyleAttrs: React.FC<Props> = props => {
  // Controls labels and ordering
  const style_labels = {
    font_display_name: "Font",
    font_size: "Size",
    font_weight: "Weight",
    color: "Color",
    letter_spacing: "Letter spacing",
    line_height: "Line height",
    text_decoration: "Decoration",
    fills: "Fill",
    borders: "Border",
    shadows: "Shadow",
  };

  // Formats content for display
  const style_formatters = {
    color: (raw: string) => {
      let comps: (number | string)[];
      if (raw.startsWith("#")) {
        const c = new TinyColor(raw);
        const a = c.getAlpha();
        comps = [c.r, c.g, c.b, a === 1 ? 1 : a.toFixed(2)];
      } else {
        comps = raw.split(",").map((v, k) => {
          // Final value is opacity, format as int for 1, or fixed length float for < 1
          if (k === 3) return parseFloat(v) === 1 ? 1 : parseFloat(v).toFixed(2);
          return parseFloat(v) * 256;
        });
      }
      const rgba = `rgba(${comps.join(", ")})`;
      return comps[3] === 1 ? new TinyColor(rgba).toHexString() : rgba;
    },
    fills: (raw: string[]) => {
      // Fills is an array of rgba colors
      return raw
        .map(v => {
          return style_formatters.color(v);
        })
        .reverse()[0];
    },
    borders: (raw: string[]) => {
      // Border format: r,g,b,a,style,width
      return raw
        .map(v => {
          const b = v.split(","),
            c = style_formatters.color(`${b[0]},${b[1]},${b[2]},${b[3]}`);
          return `${b[5]}px ${b[4]} ${c}`;
        })
        .reverse()[0];
    },
    shadows: (raw: string[]) => {
      // Shadow format: r,g,b,a,offset-x,offset-y,blur,spread,inset
      return raw
        .map(v => {
          const s = v.split(",");
          const placements = s.slice(-4);
          const c = style_formatters.color(s[0].startsWith("#") ? s[0] : s.slice(0, 4).join(","));
          return placements
            .map(p => `${p}px`)
            .concat([c])
            .join(" ");
          // `${s[8] ? `${s[8]} ` : ""}${s[4]}px ${
          // s[5]
          // }px ${s[6]}px ${s[7]}px ${c}`;
        })
        .join("\n");
    },
  };

  const styles = Object.assign({}, props.styles);

  // Sketch separates inner shadows from shadows
  // We will merge them with shadows and add inset modifier
  if (styles.inner_shadows) {
    if (!styles.shadows) styles.shadows = [];
    styles.shadows = styles.shadows.concat(
      styles.inner_shadows.map(is => {
        return `${is},inset`;
      })
    );
  }

  return (
    <AssetMetaTable>
      <tbody>
        {Object.entries(style_labels).map(l => {
          if (!styles[l[0]]) return null;

          const value = style_formatters[l[0]]
            ? // eslint-disable-next-line @typescript-eslint/no-unsafe-call
              style_formatters[l[0]](styles[l[0]])
            : styles[l[0]];

          if (!value || value.length === 0) return null;

          return (
            <tr key={l[0]}>
              <th>{l[1]}:</th>
              <td>{value}</td>
            </tr>
          );
        })}
      </tbody>
    </AssetMetaTable>
  );
};
export default SketchStyleAttrs;
