import React from "react";
import styled from "styled-components";
import { Box, AssetType } from "@thenounproject/lingo-core";

import { Inspectable } from "@constants/Inspector";
import ColorDisplay from "../ColorDisplay";
import FontAssetSquare from "./GalleryAssetSquares/FontAssetSquare";
import ImageAssetSquare from "./GalleryAssetSquares/ImageAssetSquare";

type Props = {
  inspectable: Inspectable;
  selected: boolean;
};

type AssetWrapperProps = {
  selected: boolean;
};

const AssetWrapper = styled(Box).attrs<AssetWrapperProps>(props => {
  return {
    width: "100%",
    pb: "100%",
    position: "relative",
    borderRadius: "default",
    overflow: "hidden",
    border: props.selected ? `2px solid ${props.theme.primaryColor}` : "2px solid white",
  };
})<AssetWrapperProps>``;

const AssetWrapperInner = styled(Box).attrs({
  width: "100%",
  height: "100%",
  position: "absolute",
  top: 0,
  left: 0,
})``;

function GalleryAssetContentSquare({ inspectable, selected }: Props) {
  function renderContent() {
    switch (inspectable.asset?.type) {
      case AssetType.color:
        return <ColorDisplay asset={inspectable.asset} />;
      case AssetType.textStyle:
        return <FontAssetSquare asset={inspectable.asset} />;
      default:
        return <ImageAssetSquare inspectable={inspectable} />;
    }
  }
  return (
    <AssetWrapper selected={selected} data-testid="gallery-asset-content-square">
      <AssetWrapperInner>{renderContent()}</AssetWrapperInner>
    </AssetWrapper>
  );
}

export default React.memo(GalleryAssetContentSquare);
