import React, { ComponentProps } from "react";
import { Button } from "@thenounproject/lingo-core";

import AssetActionButton from "./AssetActionButton";
import MultiAssetDownload from "../inspector/use/MultiAssetDownload";
import { Inspectable } from "@constants/Inspector";

type Props = {
  inspectables: Inspectable[];
  position?: "default" | "center";
  hiddenMode: boolean;
};

const InAssetMultiDownload: React.FC<Props> = ({ inspectables, position, hiddenMode }) => {
  function renderDownloadButtonElement(buttonProps: Partial<ComponentProps<typeof Button>>) {
    const sharedProps = {
      id: "asset-download-button",
      className: "action-button",
      icon: "action.download",
      onClick: (e: React.MouseEvent<HTMLButtonElement>) => {
        if (e) e.stopPropagation();
        buttonProps.onClick(e);
      },
      ...buttonProps,
    };
    return hiddenMode ? (
      <Button size="small" buttonStyle="tertiary" {...sharedProps} />
    ) : (
      <AssetActionButton buttonPosition={position} {...sharedProps} />
    );
  }
  return (
    <MultiAssetDownload inspectables={inspectables}>
      {({ buttonProps }) => renderDownloadButtonElement(buttonProps)}
    </MultiAssetDownload>
  );
};

export default InAssetMultiDownload;
