import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  kitId: string;
  confirmDelete?: boolean;
};

// We should migrate this to delete the kit itself and return the kit, not the draft.
// See additional not in useRemovePurchasedKit
const [useDeleteKit, deleteKit] = createAsyncAction(
  "kits/delete",
  ({ kitId, confirmDelete = false }: Args) => {
    return API.call<string>({
      endpoint: `kits/${kitId}`,
      method: "PUT",
      entity: API.Entity.kitVersion,
      data: {
        status: "deleted",
        confirm_delete: confirmDelete,
      },
    });
  }
);
export default useDeleteKit;
export { deleteKit };
