import React, { useCallback } from "react";
import styled from "styled-components";
import { Button, Flex, Text, Icon, FlexProps, BoxProps, utils } from "@thenounproject/lingo-core";

import useShowModal, { ModalTypes } from "@redux/actions/useModals";

const SearchButtonMask = styled(Flex).attrs<BoxProps & FlexProps>(props => {
  return {
    as: "button",
    background: "white",
    border: "1px solid grayLight",
    p: props.p || "5px 10px",
    borderRadius: utils.getBorderRadius(props?.theme?.themeName, "default"),
    width: props.width || "200px",
    maxWidth: "100%",
    justifyContent: "space-between",
    alignItems: "center",
  };
})``;

const MobileSearchButtonMask = styled(Flex).attrs({
  mr: "s",
})``;

type Props = {
  size?: "regular" | "small";
  iconSize?: number;
  font?: string;
} & FlexProps &
  BoxProps;

const SearchBarButton: React.FC<Props> = ({ size, iconSize, font, ...style }) => {
  const { showModal } = useShowModal();
  const onClick = useCallback(() => showModal(ModalTypes.SEARCH_MODAL), [showModal]);
  return (
    <>
      {size === "small" ? (
        <MobileSearchButtonMask>
          <Button
            icon="navigation.search"
            onClick={onClick}
            size="small"
            themeOverrides={{
              primaryColor: "transparent",
              primaryColorDark: "grayLight",
              primaryColorTint: "black",
            }}
          />
        </MobileSearchButtonMask>
      ) : (
        <SearchButtonMask data-testid="search-v2-button" onClick={onClick} {...style}>
          <Text color="grayDark" font={font}>
            Search
          </Text>
          <Icon iconId="navigation.search" fill="black" size={iconSize} />
        </SearchButtonMask>
      )}
    </>
  );
};

export default SearchBarButton;
