import React, { ComponentProps, ReactNode } from "react";
import { Button, Notice } from "@thenounproject/lingo-core";

import { AuthFormWrapper, AuthFormMessage, authButtonStyle } from "./AuthElements";
import UserBox from "./UserBox";
import AuthFormTitle from "./AuthFormTitle";
import { CurrentUser } from "@queries/useCurrentUser";

type Props = {
  buttonText: string;
  user: CurrentUser;
  // Use onConfirmSelects OR link
  onConfirmSelected: () => void;
  onGoBack?: () => void;
  link?: string;
  header: ReactNode;
  // Notice props
  notice?: ComponentProps<typeof Notice>;
  disabled: boolean;
};

const ConfirmIdentity: React.FC<Props> = ({
  user,
  header,
  link,
  buttonText,
  onConfirmSelected,
  disabled,
  notice,
  onGoBack,
}) => {
  const logoutLink = encodeURIComponent(link || window.location.toString());
  const isLoading = !user.name;

  return (
    <AuthFormWrapper>
      {header || <AuthFormTitle title="Signed in as" />}

      <UserBox user={user} />
      {notice && <Notice {...notice} />}
      <Button
        {...authButtonStyle}
        id="confirm-identity-button"
        mt="m"
        text={buttonText}
        title={buttonText}
        onClick={onConfirmSelected}
        link={link}
        disabled={isLoading || disabled}
        fullWidth
      />
      <AuthFormMessage>
        <span>
          Not you?{" "}
          <Button
            text="Use another account to log in."
            buttonStyle="tertiary"
            size="small"
            link={`/logout/?next=${logoutLink}`}
          />
        </span>
      </AuthFormMessage>
      {onGoBack && (
        <AuthFormMessage>
          <span>
            <Button text="Go back" buttonStyle="tertiary" size="small" onClick={onGoBack} />
          </span>
        </AuthFormMessage>
      )}
    </AuthFormWrapper>
  );
};

export default ConfirmIdentity;
