/**
 * Placeholder for some sort of join space form
 */

import React from "react";
import { SpacePreview, Button, OptionCard } from "@thenounproject/lingo-core";

import { useNavigate } from "react-router-dom";
import { AuthFormWrapper } from "./AuthElements";
import AuthFormTitle from "./AuthFormTitle";
import { AuthHeader } from "./AuthHeader";
import Footer from "../Footer";

type Props = {
  space: SpacePreview;
  spaceIdentifier: string | number;
};

const JoinSpaceForm: React.FC<Props> = ({ space, spaceIdentifier }) => {
  const title = space ? space.name : "your organization",
    subtitle = space
      ? "Join this space using one of the following options."
      : "Join your organization on Lingo using one of the following options.";

  const query = window.location.search;

  const navigate = useNavigate();

  function handleBackClick() {
    if (window.history.length > 1) navigate(-1);
    else navigate(`/login/${query || ""}`);
  }
  return (
    <AuthFormWrapper>
      {space ? null : <AuthHeader />}
      <AuthFormTitle space={space} title={`Join ${title}`} message={subtitle} />

      <OptionCard
        title="Sign in with SSO"
        onClick={() => navigate(`/login/sso${query}`)}
        cardStyle="disclosure"
      />
      {spaceIdentifier ? (
        <OptionCard
          title="Join with your company email"
          onClick={() => navigate(`/enter-email${query}`)}
          cardStyle="disclosure"
        />
      ) : null}
      <OptionCard
        title="Ask for an invite"
        detail="Contact an admin from your organization for an invite."
        cardStyle="default"
      />
      <Button mt="m" buttonStyle="tertiary" size="small" onClick={handleBackClick} text="Go back" />
      <Footer />
    </AuthFormWrapper>
  );
};

export default JoinSpaceForm;
