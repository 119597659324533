import React, { useState, useMemo, useEffect } from "react";
import _uniqBy from "lodash/uniqBy";
import { Flex, Box, AssetType, PopupMenu, useClipboard, Asset } from "@thenounproject/lingo-core";
import styled from "styled-components";
import { Inspectable } from "@constants/Inspector";

const ColorBox = styled(Box).attrs({
  borderRadius: "default",
  position: "absolute",
  minHeight: "inherit",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
})`
  cursor: pointer;
`;
const PatternBackground = styled(ColorBox)`
  background: url(${require("../../images/web-access/pattern@2x.png")});
  background-size: 32px;
`;

export type Props = {
  inspectables: Inspectable[];
};

function InspectorMultiAssetColors({ inspectables }: Props) {
  const [selected, setSelected] = useState(null);
  const [itemCopyData, setItemCopyData] = useState(null);

  const { onCopy } = useClipboard(itemCopyData);

  useEffect(() => {
    if (itemCopyData) {
      onCopy();
    }
  }, [onCopy, itemCopyData]);

  const colorAssets = useMemo(() => {
    const assets = inspectables
      .filter(i => i.asset && i.asset.type === AssetType.color)
      .map(i => i.asset);
    return _uniqBy(assets, a => a.id);
  }, [inspectables]);

  function renderMenu(asset: Asset) {
    if (selected !== asset.id) return;
    const { hex, rgb, hsb } = asset.colors[0];

    function renderMenuItem(value: string) {
      return (
        <PopupMenu.Item
          title={value}
          bordered={false}
          onClick={() => {
            setItemCopyData(value);
            setSelected(null);
          }}
        />
      );
    }

    return (
      <PopupMenu source={asset.id} close={() => setSelected(null)}>
        <PopupMenu.Section title="Copy as...">
          {renderMenuItem(hex)}
          {renderMenuItem(rgb)}
          {renderMenuItem(hsb)}
        </PopupMenu.Section>
      </PopupMenu>
    );
  }

  function renderAsset(asset: Asset) {
    return (
      <Box position="relative" key={asset.id} m="xs">
        <Box width="40px" height="40px" data-popup-source={asset.id}>
          <PatternBackground />
          <ColorBox background={asset.colors[0].rgb} onClick={() => setSelected(asset.id)} />
        </Box>
        {renderMenu(asset)}
      </Box>
    );
  }

  return (
    <Flex m="-xs" flexWrap="wrap" data-testid="inspector-multi-asset-colors">
      {colorAssets.map(renderAsset)}
    </Flex>
  );
}

export default InspectorMultiAssetColors;
