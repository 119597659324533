import { PortalItemType } from "@thenounproject/lingo-core";

type VerticalSpacing = {
  mt: string;
  mb: string;
};

export function getPortalHeadingSpacing(index: number): VerticalSpacing {
  let mt = "xl";
  const isFirstItem = index === 0;

  if (isFirstItem) {
    mt = "none";
  }

  return { mt, mb: "m" };
}

export function getPortalNoteSpacing(prevItemType: PortalItemType, index: number): VerticalSpacing {
  let mt = "l";
  const isFirstItem = index === 0,
    isAfterNoteOrHeading = [PortalItemType.heading, PortalItemType.note].includes(prevItemType);

  if (isFirstItem || isAfterNoteOrHeading) {
    mt = "none";
  }

  return { mt, mb: "m" };
}

export function getGallerySupportSpacing(
  prevItemType: PortalItemType,
  index: number
): VerticalSpacing {
  let mt = "l";
  const isFirstItem = index === 0,
    isAfterHeading = PortalItemType.heading === prevItemType;

  if (isFirstItem || isAfterHeading) {
    mt = "none";
  }

  return { mt, mb: "m" };
}
