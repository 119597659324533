import React from "react";
import styled from "styled-components";
import { Box, Text } from "@thenounproject/lingo-core";

import assetFileDetails from "../assetFileDetails";
import { Inspectable } from "@constants/Inspector";

export const MetaWrapper = styled(Box).attrs({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
})``;

type Props = {
  inspectable: Inspectable;
};

const DefaultMeta = ({ inspectable }: Props) => {
  return (
    <MetaWrapper key="meta-element">
      <Box>
        <Text font="ui.regular">
          {inspectable.asset?.name || inspectable.asset.colors[0]?.name || "Unnamed asset"}
        </Text>
      </Box>
      <Box>
        <Text font="ui.small" color="grayDarker">
          {assetFileDetails(inspectable.asset, { showColorValue: true })}
        </Text>
      </Box>
    </MetaWrapper>
  );
};

export default DefaultMeta;
