import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

const [useDeleteTOTP, deleteTOTP] = createAsyncAction(
  "auth/deleteTOTP",
  async (password: string) => {
    return await API.call<string>({
      endpoint: "me/mfa/totp/",
      method: "DELETE",
      data: { password },
    });
  }
);

export default useDeleteTOTP;
export { deleteTOTP };
