import React from "react";
import {
  CustomField,
  CustomFieldOperation,
  formatDate,
  Text,
  Box,
  Asset,
} from "@thenounproject/lingo-core";

import DatePickerComponent from "@features/library/components/DatePicker";

import useNotifications from "@actions/useNotifications";
import useAssetsCustomFieldUpdate from "@redux/actions/assets/useAssetsCustomFieldUpdate";
import { parseLocalDateFromString } from "@helpers/dates";

export type Props = {
  field: CustomField;
  updateCustomFieldValue: ReturnType<typeof useAssetsCustomFieldUpdate>[0];
  canEdit: boolean;
  assetUuids: string[];
  assets: Asset[];
};

const DateCustomField = ({ field, updateCustomFieldValue, assets, canEdit, assetUuids }: Props) => {
  const assetFieldValues = assets.map(asset => asset.fields?.[field.id]) as string[];
  const fieldValuesAreEqual = assetFieldValues.every(
    fieldValue => fieldValue === assetFieldValues[0]
  );

  const { showNotification } = useNotifications();

  const handleValueUpdate = React.useCallback(
    async (fieldId: number, value: string) => {
      const { error } = await updateCustomFieldValue({
        assetUuids,
        fieldId,
        value,
        operation: CustomFieldOperation.set,
      });
      if (error) {
        showNotification({ message: error.message, level: "error" });
      }
    },
    [assetUuids, updateCustomFieldValue, showNotification]
  );

  const handleSave = React.useCallback(
    async (d: Date | undefined) => {
      if (d === undefined) {
        await handleValueUpdate(field.id, null);
      } else {
        const isoString = d?.toISOString()?.slice(0, 10);
        await handleValueUpdate(field.id, isoString);
      }
    },
    [field.id, handleValueUpdate]
  );

  if (!canEdit && field.public) {
    return (
      <Text font="ui.small">
        {!fieldValuesAreEqual
          ? "Varied"
          : formatDate(assetFieldValues[0], "MMMM do, yyyy") || "None"}
      </Text>
    );
  }

  return (
    <Box data-testid="inspector-date-field">
      <DatePickerComponent
        selected={
          (fieldValuesAreEqual && parseLocalDateFromString(assetFieldValues[0])) || undefined
        }
        onChange={handleSave}
      />
    </Box>
  );
};

export default DateCustomField;
