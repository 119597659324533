import React, { useCallback, useEffect } from "react";
import { Button, Box, Flex, Text, SpacePreview } from "@thenounproject/lingo-core";
import { useNavigate } from "react-router-dom";
import { AuthFormWrapper } from "./AuthElements";
import AuthFormTitle from "./AuthFormTitle";

import OrSeparator from "./OrSeparator";
import useResendJoinEmail from "@redux/actions/auth/useSendVerificationEmail";
import useNotifications from "@actions/useNotifications";
import { CurrentUser } from "@queries/useCurrentUser";

type Props = {
  user: CurrentUser;
  space?: SpacePreview;
};

const SignupVerificationConfirmation: React.FC<Props> = ({ user, space }) => {
  const navigate = useNavigate();
  const [resendEmail] = useResendJoinEmail();
  const { showNotification } = useNotifications();

  const onResendEmail = useCallback(async () => {
    const response = await resendEmail();
    if (response.error) {
      showNotification({ message: response.error.message, level: "error" });
    } else {
      showNotification({ message: "Email sent.  The link will expire in 10 minutes!" });
    }
  }, [resendEmail, showNotification]);

  /**
   * Should only be accessible by an authenticated user post-signup
   */
  useEffect(() => {
    if (!user?.id) {
      navigate("/signup");
    }
  }, [navigate, user]);

  function renderTitle() {
    return <AuthFormTitle title={"Welcome to Lingo"} space={space} />;
  }

  function renderForm() {
    return (
      <Flex flexDirection="column" gap="24px">
        <Box border="default" borderRadius="default" p="16px">
          <Text font="ui.regularBold" mb="4px">
            Join your team
          </Text>
          <Text font="ui.regular">
            Your team has a workspace on Lingo. We’ve sent you an email with instructions to join
            them.
          </Text>
        </Box>
        <OrSeparator />
        <Button text="Create a new space" link="/space/new" fullWidth />
      </Flex>
    );
  }

  function renderFooter() {
    return (
      <>
        <Text textAlign="center" mt="40px" font="ui.small">
          Didn&apos;t get the email?{" "}
          <Button
            id="resend email button"
            buttonStyle="tertiary"
            size="small"
            type="button"
            text="Resend"
            onClick={onResendEmail}
          />
        </Text>
      </>
    );
  }

  return (
    <AuthFormWrapper>
      {renderTitle()}
      {renderForm()}
      {renderFooter()}
    </AuthFormWrapper>
  );
};

export default React.memo(SignupVerificationConfirmation) as typeof SignupVerificationConfirmation;
