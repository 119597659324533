import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  sectionId: string;
};

const [useDeleteSection, deleteSection] = createAsyncAction(
  "sections/delete",
  async ({ sectionId }: Args) => {
    return await API.call<string>({
      endpoint: `/sections/${sectionId}`,
      method: "PUT",
      data: { status: "deleted" },
      entity: API.Entity.section,
    });
  }
);

export default useDeleteSection;
export { deleteSection };
