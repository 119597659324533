import React, { DragEvent } from "react";
import styled from "styled-components";
import { Flex, styles, Icon, Tooltip } from "@thenounproject/lingo-core";

import { InsertType } from "../../constants/InsertType";
import { SUPER_NAV_WIDTH, KIT_NAV_WIDTH } from "../../constants/layouts";
import { useGetDraggingState } from "@selectors/getters";
import useSetDraggingEntity, { DragEntities } from "@actions/useSetDraggingEntity";

type PanelWrapperProps = {
  activeBanner: boolean;
  superNavVisible: boolean;
};

const InsertPanelWrapper = styled(Flex).attrs<PanelWrapperProps>(props => {
  return {
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    background: "transparent",
    top: props.activeBanner ? "60px" : "0",
    width: "48px",
    left: props.superNavVisible ? `${SUPER_NAV_WIDTH + KIT_NAV_WIDTH}px` : `${KIT_NAV_WIDTH}px`,
    height: "100%",
    position: "fixed",
    zIndex: 100,
    maxHeight: props.activeBanner ? "calc(100vh - 60px)" : "100vh",
    px: "xs",
    variations: {
      "mq.s": {
        display: "none !important",
      },
    },
  };
})<PanelWrapperProps>`
  overflow: auto;
`;

const PanelItem = styled(Flex).attrs({
  flexShrink: 0,
  borderRadius: "default",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  width: "32px",
  height: "32px",
  my: "s",
  background: "white",
})`
  box-shadow: none;
  transition: 0.3s background ease;
  cursor: pointer;
  svg {
    margin: 0;
  }
  &[draggable="true"] {
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
    &:hover {
      background: ${styles.color.grayLighter};
    }
  }
`;

type ItemWrapperProps = {
  divider?: boolean;
};
const PanelItemWrapper = styled(Flex).attrs<ItemWrapperProps>(props => {
  return {
    borderBottom: props.divider ? "default" : undefined,
  };
})<ItemWrapperProps>``;

const panelItems = [
  {
    label: "Drag to add assets",
    icon: "content.file",
    type: InsertType.assets,
    divider: true,
  },
  {
    label: "Drag to add a heading",
    icon: "content.heading",
    type: InsertType.heading,
  },
  {
    label: "Drag to add a note",
    icon: "content.note",
    type: InsertType.note,
    divider: true,
  },
  {
    label: "Drag to add supporting content",
    icon: "content.supporting-image",
    type: InsertType.supportContent,
  },
  {
    label: "Drag to add guidelines",
    icon: "content.guide",
    type: InsertType.guide,
    divider: true,
  },
  {
    label: "Drag to add a code snippet",
    icon: "content.code",
    type: InsertType.code,
  },
];

type Props = {
  activeBanner: boolean;
  superNavVisible?: boolean;
};

const InsertPanel: React.FC<Props> = ({ activeBanner, superNavVisible = true }) => {
  const setDragEntity = useSetDraggingEntity();
  const draggingState = useGetDraggingState();
  const galleryDragActive = draggingState.entity === DragEntities.GALLERY_ITEM;

  function handleDragStart(e: DragEvent, insertType: InsertType) {
    e.dataTransfer.setData("insertType", insertType);
    setDragEntity({ entity: DragEntities.GALLERY_ITEM });
  }

  function handleDragEnd(e: DragEvent) {
    e.preventDefault();
    setDragEntity({ entity: undefined });
  }

  const renderPanelItems = () => {
    return panelItems.map(p => {
      const labelId = "panel-item-".concat(p.type.split(" ").join("-").toLowerCase());
      return (
        <PanelItemWrapper divider={p.divider} key={labelId}>
          <PanelItem
            data-testid={labelId}
            data-tooltip-source={labelId}
            onDragStart={e => handleDragStart(e, p.type)}
            onDragEnd={handleDragEnd}
            draggable="true">
            <Icon iconId={p.icon} fill="black" />
            {!galleryDragActive && (
              <Tooltip direction="right" source={labelId} border="none">
                {p.label}
              </Tooltip>
            )}
          </PanelItem>
        </PanelItemWrapper>
      );
    });
  };

  return (
    <InsertPanelWrapper
      className="intercom-insert-panel"
      activeBanner={activeBanner}
      superNavVisible={superNavVisible}>
      {renderPanelItems()}
    </InsertPanelWrapper>
  );
};

export default InsertPanel;
