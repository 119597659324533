import React from "react";
import { Box, Text, ImageView, Flex, Icon } from "@thenounproject/lingo-core";

import { MetaWrapper } from "./DefaultMeta";
import { Inspectable } from "@constants/Inspector";

type Props = {
  inspectable: Inspectable;
};

const LinkMeta = ({ inspectable }: Props) => {
  const { fld, favicon } = inspectable.asset.meta.content;
  return (
    <MetaWrapper key="meta-element">
      <Box>
        <Text font="ui.regular">
          {inspectable.asset?.name || inspectable.asset?.meta?.content?.url || "Unnamed asset"}
        </Text>
      </Box>
      <Flex>
        {favicon ? (
          <ImageView src={favicon} height="16" width="16" mr="4px" />
        ) : (
          <Icon size="16" iconId="globe" mr="4px" fill="grayDarker" />
        )}
        <Box>
          <Text font="ui.small" color="grayDarker">
            URL{fld ? ` • ${fld}` : ""}
          </Text>
        </Box>
      </Flex>
    </MetaWrapper>
  );
};

export default LinkMeta;
