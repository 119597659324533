import useScript from "react-script-hook";
import { useCallback } from "react";
import { AssetSourceVendors, ExternalSource } from "@redux/actions/items/useCreateAssetsFromSource";

const DROPBOX_SDK_URL = "https://www.dropbox.com/static/api/2/dropins.js";
const SCRIPT_ID = "dropboxjs";
const appKey = "0bj5ckglyir430c";

export default function useDropboxPicker(): Result {
  const [loading, error] = useScript({
    src: DROPBOX_SDK_URL,
    id: SCRIPT_ID,
    "data-app-key": appKey,
  });
  const isDropboxReady = !loading && !error;

  return useCallback(
    ({ onSuccess, onCancel = null, multiselect = false, extensions = allExtensions }: Options) => {
      if (!isDropboxReady) return;

      const success = (files: File[]) => {
        const sources = files.map(file => ({
          source: AssetSourceVendors.Dropbox,
          name: file.name,
          url: file.link,
          fileSize: file.bytes,
        }));
        onSuccess(sources);
      };

      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      window.Dropbox.choose({
        success,
        cancel: onCancel,
        linkType: "direct",
        multiselect,
        extensions,
      });
    },
    [isDropboxReady]
  );
}

// MARK : Types
// -------------------------------------------------------------------------------
const allExtensions = [
  ".jpg",
  ".jpeg",
  ".j2k",
  ".jpx",
  ".jp2",
  ".png",
  ".tiff",
  ".tif",
  ".gif",
  ".eps",
  ".pdf",
  ".eps",
  ".jpeg",
  ".svg",
  ".txt",

  ".mp4",
  ".mov",

  ".mp3",
  ".wav",
  ".m4a",

  ".ttf",
  ".otf",

  ".docx",
  ".dotx",
  ".pptx",
  ".ppt",
  ".potx",
  ".xls",
  ".xlsx",
  ".keynote",
  ".key",
  ".kth",
  ".pages",
  ".ai",
  ".psd",
  ".indd",

  ".json",
  ".zip",
];

type Options = {
  onSuccess: (files: ExternalSource[]) => void;
  onCancel?: () => void;
  linkType?: "direct" | "preview";
  multiselect?: boolean;
  extensions?: string[];
};

type Result = (options: Options) => void;

type File = {
  // Unique ID for the file, compatible with Dropbox API v2.
  id: string;
  // Name of the file.
  name: string; // "filename.txt";
  // URL to access the file, which varies depending on the linkType specified when the
  link: string;
  // Size of the file in bytes.
  bytes: number;
  // URL to a 64x64px icon for the file based on the file's extension.
  icon: string;
  // A thumbnail URL generated when the user selects images and videos.
  // If the user didn't select an image or video, no thumbnail will be included.
  thumbnailLink: string;
  // Boolean, whether or not the file is actually a directory
  isDir: false;
};
