import React, { useState, useCallback, useMemo, useEffect } from "react";
import {
  SpacePreview,
  Button,
  Box,
  Input,
  Notice,
  ActivityIndicator,
  Text,
  ErrorCode,
} from "@thenounproject/lingo-core";
import { AuthFormWrapper, authButtonStyle } from "./AuthElements";
import GoogleSignInButton, { GoogleAuthResponse } from "./GoogleSignInButton";
import AuthFormTitle from "./AuthFormTitle";
import useAuthHandler from "./useAuthHandler";
import useLogin, { LoginArgs } from "@redux/actions/auth/useLogin";
import useLoginWithGoogleToken from "@redux/actions/auth/useLoginWithGoogleToken";
import SpacePreviewTitle from "./SpacePreviewTitle";

type Props = {
  space?: SpacePreview;
  isAccessingSpace: boolean;
  token?: { token: string; type: string };
  responseHandler: ReturnType<typeof useAuthHandler>;
};

enum LoginState {
  Login = "Login",
  TOTP = "TOTP",
  Recovery = "Recovery",
}

const formTitleMap = {
  [LoginState.Login]: "Login",
  [LoginState.TOTP]: "Enter authentication code",
  [LoginState.Recovery]: "Enter recovery code",
};

const formButtonMap = {
  [LoginState.Login]: "Login with email",
  [LoginState.TOTP]: "Login",
  [LoginState.Recovery]: "Login",
};

type LoginErrorState = {
  email?: string;
  password?: string;
  totpFieldError?: string;
  processingError?: string;
  errorCode?: number;
};

const LoginForm: React.FC<Props> = ({ isAccessingSpace, space, token, responseHandler }) => {
  const [email, setEmail] = useState(""),
    [password, setPassword] = useState(""),
    [otp, setOTP] = useState(""), // TOTP code or fallback recovery code
    queryString = window.location.search || "",
    [loginState, setLoginState] = useState<LoginState>(LoginState.Login),
    [errors, setErrors] = useState<LoginErrorState>({});

  useEffect(() => {
    setOTP("");
    setErrors({});
  }, [loginState]);

  useEffect(() => {
    const error = responseHandler?.error;
    if (!error) return setErrors({});
    const details = error.details || {},
      fieldErrors = ["email", "password"]
        .filter(k => details[k])
        .reduce((res, key) => ({ ...res, [key]: details[key] }), {});
    return setErrors({
      processingError: Object.keys(fieldErrors).length === 0 ? error.message : undefined,
      errorCode: error.code,
      ...fieldErrors,
    });
  }, [responseHandler?.error]);

  const [_login] = useLogin(),
    login = useCallback(
      (args: LoginArgs) => responseHandler.process(async () => await _login(args)),
      [responseHandler, _login]
    ),
    loginWithEmail = useCallback(
      () => void login({ email, password, otp: otp || undefined, queryString }),
      [email, login, password, otp, queryString]
    ),
    loginWithToken = useCallback(
      (token: string) => void login({ token, queryString }),
      [login, queryString]
    ),
    [_loginWithGoogleToken] = useLoginWithGoogleToken(),
    loginWithGoogle = useCallback(
      (data: GoogleAuthResponse) => {
        void responseHandler.process(() =>
          _loginWithGoogleToken({ token: data.credential, queryString })
        );
      },
      [_loginWithGoogleToken, queryString, responseHandler]
    );

  React.useEffect(() => {
    if (errors.errorCode === ErrorCode.totpRequired) {
      setLoginState(LoginState.TOTP);
    }
  }, [errors]);

  // If we have a migic-login token, automatically submit it
  useEffect(() => {
    if (token?.type === "magic-login") {
      loginWithToken(token.token);
    }
    // Having the dependencies here causes an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // MARK : Rendering
  // -------------------------------------------------------------------------------
  const isLinkingAccounts = ["link-sso", "link-google"].includes(token?.type),
    createAccountLink = useMemo(() => {
      const { type } = token ?? {};
      if (isLinkingAccounts) return null;
      if (["invitation", "join-link"].includes(type)) {
        return {
          text: "Create one.",
          link: `/signup/${queryString}`,
        };
      } else if (isAccessingSpace) {
        return {
          text: "Get access.",
          link: `/join-space/${queryString}`,
        };
      } else {
        return {
          text: "Create one.",
          link: `/signup/${queryString}`,
        };
      }
    }, [isAccessingSpace, isLinkingAccounts, queryString, token]);

  function renderTitle() {
    switch (token?.type) {
      case "join-link":
      case "invitation":
        if (!space) break;
        return <SpacePreviewTitle titlePrefix="Log in to join " space={space} />;
      case "link-sso":
        return (
          <AuthFormTitle
            space={space}
            title="Login to enable SSO"
            message="Log into your Lingo account to connect it to your SSO provider."
          />
        );
      case "link-google":
        return (
          <AuthFormTitle
            space={space}
            title="Login"
            extra={
              <Notice
                mt="m"
                noticeStyle="warning"
                message="It looks like you already have an account on Lingo. "
              />
            }
          />
        );
      default:
        return <AuthFormTitle title={formTitleMap[loginState]} space={space} />;
    }
  }

  function renderForm() {
    return (
      <form
        onSubmit={e => {
          e.preventDefault();
          if (responseHandler.isProcessing) return;
          loginWithEmail();
        }}>
        {loginState === LoginState.Login && (
          <>
            <Input
              onChange={e => setEmail(e.target.value)}
              value={email}
              placeholder="your@email.com"
              type="email"
              id="id_email"
              label="Email"
              styleOverrides={{ pb: "m" }}
              message={errors.email}
              inputStyle={errors.email ? "error" : null}
              autoComplete="email"
            />
            <Input
              onChange={e => setPassword(e.target.value)}
              value={password}
              placeholder="••••••••"
              type="password"
              id="id_password"
              label="Password"
              styleOverrides={{ pb: "m" }}
              message={errors.password}
              inputStyle={errors.password ? "error" : null}
              autoComplete="current-password"
            />
            <Box textAlign="right">
              <Button
                buttonStyle="tertiary"
                type="button"
                size="small"
                text="Forgot your password?"
                link="/password-reset"
              />
            </Box>
          </>
        )}
        {loginState === LoginState.TOTP && (
          <Input
            onChange={e => setOTP(e.target.value)}
            value={otp}
            placeholder="6-digit authentication code"
            type="text"
            id="id_totp"
            label="Authentication code"
            message={errors.totpFieldError}
            inputStyle={errors.totpFieldError ? "error" : null}
            autoComplete="one-time-code"
          />
        )}
        {loginState === LoginState.Recovery && (
          <Input
            onChange={e => setOTP(e.target.value)}
            value={otp}
            placeholder="8-digit recovery code"
            type="text"
            id="id_totp_recovery"
            label="Recovery code"
            message={errors.totpFieldError}
            inputStyle={errors.totpFieldError ? "error" : null}
            autoComplete="one-time-code"
          />
        )}
        {errors.processingError && (
          <Notice noticeStyle="error" message={errors.processingError} mt="m" />
        )}

        <Button
          {...authButtonStyle}
          disabled={responseHandler.isProcessing}
          type="submit"
          text={formButtonMap[loginState]}
          mb="none"
        />
      </form>
    );
  }

  function renderFooter() {
    return (
      <>
        <Box borderBottom="default" my="l" height="1px" width="100%" />
        {!isLinkingAccounts && loginState === LoginState.Login && (
          <>
            <GoogleSignInButton context="signin" onSuccess={loginWithGoogle} />
            <Button
              mt="m"
              text="Login with SSO"
              buttonStyle="secondary"
              type="button"
              width="100%"
              link={`/login/sso/${queryString}`}
            />

            {createAccountLink && (
              <Text mt="l" textAlign="center" font="ui.small">
                Don’t have an account?{" "}
                <Button
                  id="create-account-button"
                  buttonStyle="tertiary"
                  type="button"
                  size="small"
                  {...createAccountLink}
                />
              </Text>
            )}
          </>
        )}
        {loginState === LoginState.Recovery && (
          <>
            <Button
              text="Back to login"
              buttonStyle="tertiary"
              fontStyle="ui.small"
              width="100%"
              type="button"
              onClick={() => setLoginState(LoginState.Login)}
            />
          </>
        )}
        {loginState === LoginState.TOTP && (
          <>
            <Text textAlign="center" font="ui.small">
              Can’t access your mobile device?{" "}
              <Button
                id="create-account-button"
                buttonStyle="tertiary"
                size="small"
                type="button"
                text="Login with a recovery code"
                onClick={() => setLoginState(LoginState.Recovery)}
              />
            </Text>
            <Button
              mt="s"
              text="Back to login"
              buttonStyle="tertiary"
              fontStyle="ui.small"
              type="button"
              width="100%"
              onClick={() => setLoginState(LoginState.Login)}
            />
          </>
        )}
      </>
    );
  }

  // If the token is a magic link
  if (token?.type === "magic-login") {
    return (
      <AuthFormWrapper>
        {renderTitle()}
        {errors.processingError ? (
          <Notice noticeStyle="error" message={errors.processingError} mt="m" />
        ) : (
          <ActivityIndicator center />
        )}
      </AuthFormWrapper>
    );
  }

  return (
    <AuthFormWrapper>
      {renderTitle()}
      {renderForm()}
      {renderFooter()}
    </AuthFormWrapper>
  );
};

export default LoginForm;
