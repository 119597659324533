export function parseVersionedIdentifier(id: string, version = undefined) {
  const uuidPattern = "^(X{8}-X{4}-4X{3}-[89abAB]X{3}-X{12})(?:-(\\d+))?".replace(
    /X/g,
    "[0-9a-fA-F]"
  );
  const match = id.match(uuidPattern) || [];
  return {
    id: match[1] || id,
    version: [version, match[2]].map(v => Number(v)).find(v => !Number.isNaN(v)),
  };
}

export function getVersionedIdentifier(id: string, version: string | number) {
  return `${id}-${version}`;
}

function isUUID(id: string) {
  const uuidPattern = "^(X{8}-X{4}-4X{3}-[89abAB]X{3}-X{12})$".replace(/X/g, "[0-9A-F]");
  const match = id.match(uuidPattern) || [];
  return match && match[1];
}

/**
 *
 * @param id A uuid, shortId or named short id (e.g. brand-kit-<shortId>)
 * @returns The uuid or short id
 */
export function parseIdentifier(id: string) {
  if (!id) return id;
  if (isUUID(id)) return id;
  return id.split("-").pop();
}
