import _debounce from "lodash/debounce";
import { Space, API, Tag, base64, SearchFilterInput } from "@thenounproject/lingo-core";

import { SearchQuery, TagSearchSortTypes } from "@features/library/types";
import { createQueryAction } from "@redux/actions/actionCreators";

export type Args = {
  spaceId: Space["id"];
  filters: SearchFilterInput[];
  sort: TagSearchSortTypes;
};

type Result = {
  total: number;
  tags: Tag[];
};

const [useAssetTags, fetchAssetTags] = createQueryAction<Args, Result, Result>(
  {
    entity: "tags",
    action: "query",
    pagingKey: "tags",
    denormalize: false,
    debounce: true,
    cacheKey: args => {
      return {
        spaceId: args.spaceId,
        filtered: args.filters?.length ? true : false,
      };
    },
  },
  async ({ args, paging }) => {
    const res = await API.call<{
      queries: { tags: { total: number; results: { object: Tag }[] } };
    }>({
      endpoint: `search/spaces/${args.spaceId}`,
      method: "GET",
      query: { query: buildQueryString(args.filters, args.sort, paging.page) },
    });

    const { total, results } = res.result.queries.tags;
    return {
      result: {
        total,
        tags: results.map(r => r.object),
      },
      entities: {},
    };
  }
);
export default useAssetTags;
export { fetchAssetTags };

// The tag API does not support paging so we just need to fetch as many as we can
const PAGE_SIZE = 10000;

const buildQueryString = (filters: SearchFilterInput[], sort: TagSearchSortTypes, page: number) => {
  const query: SearchQuery = {
    filters,
    queries: {
      tags: {
        offset: (page - 1) * PAGE_SIZE,
        limit: PAGE_SIZE,
        context: "library",
        type: "tags",
        sort,
      },
    },
  };
  return base64.encode(query);
};
