import { AnyObject, API, SpaceTheme, SpaceThemeNames } from "@thenounproject/lingo-core";

import { createAsyncAction } from "../actionCreators";

type ThemeUpdates = {
  description?: string;
  headerBackgroundColor?: string;
  headerTitleStyle?: string;
  headerTitleColor?: string;
  headerDescriptionColor?: string;
  noteSuccessColor?: string;
  noteInfoColor?: string;
  noteWarningColor?: string;
  primaryColor?: string;
  themeName?: SpaceThemeNames;
  fonts?: SpaceTheme["fonts"];
  headerBackgroundImage?: undefined | null | File;
  headerLogoImage?: undefined | null | File;
};
type Args = {
  portalId: string;
  updates: ThemeUpdates;
};

const [useUpdatePortalTheme, updatePortalTheme] = createAsyncAction(
  "portals/updateTheme",
  async ({ portalId, updates }: Args) => {
    const theme = {
      description: updates.description,
      header_background_color: updates.headerBackgroundColor,
      header_title_style: updates.headerTitleStyle,
      header_title_color: updates.headerTitleColor,
      header_description_color: updates.headerDescriptionColor,
      note_success_color: updates.noteSuccessColor,
      note_info_color: updates.noteInfoColor,
      note_warning_color: updates.noteWarningColor,
      primary_color: updates.primaryColor,
      theme_name: updates.themeName,
      fonts: updates.fonts,
    };

    const images = {
      header_background_image: updates.headerBackgroundImage,
      header_logo_image: updates.headerLogoImage,
    };

    let formData: FormData;
    let data: AnyObject;

    Object.entries(images).forEach(([key, value]) => {
      if (value === null) {
        theme[key] = null;
      } else if (value) {
        if (!formData) formData = new FormData();
        formData.append(key, value);
      }
    });

    if (formData) {
      formData.append("json", JSON.stringify({ theme }));
      data = undefined;
    } else {
      data = { theme };
    }
    return await API.call<string>({
      endpoint: `/portals/${portalId}`,
      method: "PUT",
      entity: API.Entity.portal,
      data,
      formData,
    });
  }
);

export default useUpdatePortalTheme;
export { updatePortalTheme };
