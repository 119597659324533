import { API, elementIds, Item } from "@thenounproject/lingo-core";
import { type InsertPosition } from "@actions/uploads";
import { KitDisplaySize } from "../components/kits/types";

export function getVersionedItemId(item: Item): string {
  const { id, version } = item;
  return `${id}-${version}`;
}

export function findItemIndexByVersionedId(versionedId: string, itemArray: Item[]): number {
  return itemArray.findIndex(item => versionedId === getVersionedItemId(item));
}

/**
 * @param {Object} event click event
 * @param {String} itemId versioned id of item being selected
 * @param {Array} allItems array of full item objects comprising all possible selections
 * @param {Array} selectedIds array of selected IDs in current inspector view
 * @param {Func} selectedHandler handler to update selected ID state
 */
export function handleInspectorItemClick(
  event: React.MouseEvent,
  itemId: string,
  allItems: Item[],
  selectedIds: string[],
  selectedHandler: (ids: string[]) => void
) {
  if (event?.shiftKey && selectedIds.length > 0) {
    const lastSelected = selectedIds[selectedIds.length - 1],
      indexFrom = findItemIndexByVersionedId(lastSelected, allItems),
      indexTo = findItemIndexByVersionedId(itemId, allItems),
      indexes = [indexFrom, indexTo].sort((a, b) => a - b),
      itemsBetween = allItems
        .slice(indexes[0] + 1, indexes[1])
        .map(item => `${item.id}-${item.version}`),
      newSelectionMinusClicked = new Set([...selectedIds, ...itemsBetween]);

    newSelectionMinusClicked.delete(itemId);
    const newSelection = [...newSelectionMinusClicked, itemId]; // This enforces the last clicked item

    selectedHandler(newSelection);
  } else if (event?.metaKey || event?.ctrlKey) {
    if (selectedIds.includes(itemId)) selectedHandler(selectedIds.filter(id => id !== itemId));
    else selectedHandler([...selectedIds, itemId]);
  } else selectedHandler([itemId]);
}

export function getAssetsPerRow(
  scrollContainer: HTMLDivElement,
  kitDisplaySize: KitDisplaySize
): number {
  const minWidthSizes = {
    small: 100,
    medium: 140,
    large: 220,
  };
  const minWidth = kitDisplaySize ? minWidthSizes[kitDisplaySize] : 140,
    assetPadding = 16,
    totalWidth = scrollContainer ? scrollContainer.offsetWidth : 880;

  return Math.floor(totalWidth / (minWidth + assetPadding));
}

export function getScrollContainer(): HTMLDivElement {
  return document.querySelector(`#${elementIds.SCROLL_CONTAINER}`);
}

export function scrollToItem(id: string) {
  const anchor =
    document.querySelector(`[data-shortid="${id}"]`) || document.querySelector(`[id="${id}"]`);
  if (anchor) {
    // Workaround for scrollIntoView not triggering on load, in Chrome
    // https://github.com/facebook/react/issues/23396
    window.requestAnimationFrame(() => {
      anchor.scrollIntoView({});
    });
    return true;
  }
  return false;
}

export function scrollItemOnScreen(id: string) {
  const scrollContainer = getScrollContainer(),
    anchor: HTMLDivElement =
      document.querySelector(`[data-shortid="${id}"]`) || document.querySelector(`[id="${id}"]`);

  if (anchor && scrollContainer && scrollContainer.scrollTo) {
    const marginAboveContent = 24,
      headerVisibleHeight = 76,
      bottomBuffer = 70,
      containerHeightMinusHeader =
        scrollContainer.offsetHeight - marginAboveContent - headerVisibleHeight - bottomBuffer;
    if (anchor.offsetTop < scrollContainer.scrollTop - marginAboveContent) {
      scrollContainer.scrollTo({ top: anchor.offsetTop + marginAboveContent, behavior: "smooth" });
    } else if (
      anchor.offsetTop + anchor.offsetHeight >
      scrollContainer.scrollTop + containerHeightMinusHeader
    ) {
      scrollContainer.scrollTo({
        top: anchor.offsetTop + anchor.offsetHeight - containerHeightMinusHeader,
        behavior: "smooth",
      });
    }
    return true;
  }
  return false;
}

/**
  Formats query item data based on insert position
 */
export const getItemData = (insertPosition: InsertPosition) => {
  const { displayOrder, kitId, sectionId, itemId } = insertPosition;
  const useItemSchema = !!itemId || !!kitId;
  const schema = useItemSchema ? API.Entity.item : API.Entity.asset;
  let item;
  if (kitId && !itemId) {
    item = {
      display_order: displayOrder,
      kit_uuid: kitId,
      section_uuid: sectionId,
    };
  } else if (itemId) {
    item = {
      item_uuid: itemId,
      kit_uuid: kitId,
    };
  } else {
    item = null;
  }
  return { item, schema };
};
