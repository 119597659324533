import { API, Item } from "@thenounproject/lingo-core";
import { createQueryAction } from "../actionCreators";

type Args = {
  spaceId: string | number;
  kitId: string;
};

const [useRecentlyDeletedItems, fetchRecentlyDeletedItems] = createQueryAction<
  Args,
  Item[],
  string[]
>(
  {
    entity: "items",
    action: "queryRecentlyDeleted",
    denormalize: { asset: "assets" },
  },
  async ({ args, paging }) => {
    const res = await API.call<{ items: string[] }>({
      endpoint: `kits/${args.spaceId}/${args.kitId}/trash`,
      method: "GET",
      entity: API.Entity.item,
      query: {
        limit: 200,
        page: paging.page,
      },
    });
    return { result: res.result.items, entities: res.entities };
  }
);

export default useRecentlyDeletedItems;
export { fetchRecentlyDeletedItems };
