import { ActionReducerMapBuilder, combineReducers, createReducer } from "@reduxjs/toolkit";

import {
  invalidateQueryAction,
  handleInvalidationAction,
  type QueryData,
} from "@redux/actions/actionCreators/createQueryAction";
import { mergeQuery } from "./mergeQuery";
import { logout } from "@redux/actions/auth/useLogout";
import { login } from "@redux/actions/auth/useLogin";
import { ssoLogin } from "@redux/actions/auth/useSSOLogin";
import { loginWithGoogle } from "@redux/actions/auth/useLoginWithGoogleToken";

export default function createEntityReducer<Entity>(
  entityName: string,
  queryReducer: (queryBuilder: ActionReducerMapBuilder<Record<string, QueryData<unknown>>>) => void,
  objectReducer: (objectBuilder: ActionReducerMapBuilder<Record<string, Entity>>) => void
) {
  const queries = createReducer<Record<string, QueryData<unknown>>>({}, builder => {
    builder
      .addCase(logout.fulfilled, () => {
        return {};
      })
      .addCase(login.fulfilled, () => {
        return {};
      })
      .addCase(ssoLogin.fulfilled, () => {
        return {};
      })
      .addCase(loginWithGoogle.fulfilled, () => {
        return {};
      });
    queryReducer(builder);
    builder
      .addCase(invalidateQueryAction, (state, action) => {
        if (action.payload.entity === entityName) {
          handleInvalidationAction(state, action);
        }
      })
      .addDefaultCase((state, action) => {
        mergeQuery(state, action, entityName);
      });
  });

  const objects = createReducer<Record<string, Entity>>({}, builder => {
    builder.addCase(logout.fulfilled, () => {
      return {};
    });
    objectReducer(builder);
  });

  return combineReducers({
    queries,
    objects,
  });
}
