import { MutableRefObject, useEffect, useRef } from "react";
import { ResizeObserver } from "@juggle/resize-observer";

export default function useResizeObserver(
  scrollContainer: MutableRefObject<HTMLElement>,
  callback: (node: Element) => void
) {
  const observer = useRef<ResizeObserver>();

  useEffect(() => {
    observer.current = new ResizeObserver(entries => {
      callback(entries[0].target);
    });
  }, [callback, scrollContainer]);

  useEffect(() => {
    const cachedObserver = observer.current,
      cachedScrollContainer = scrollContainer.current;
    if (!cachedObserver || !cachedScrollContainer) return;

    cachedObserver.observe(cachedScrollContainer);
    return () => {
      cachedObserver.unobserve(cachedScrollContainer);
    };
  }, [scrollContainer, callback]);
}
