import { AnyObject } from "@thenounproject/lingo-core";
import _merge from "lodash/merge";
import createEntityReducer from "../helpers/createEntityReducer";

export default createEntityReducer<AnyObject>(
  "analytics",
  _queryBuilder => {
    // Nothing to do
  },
  _objectBuilder => {
    // Nothing to do
  }
);
