import { PortalItem } from "@thenounproject/lingo-core";

export function findPortalItemIndexById(id: string, itemArray: PortalItem[]): number {
  return itemArray.findIndex(item => id === item.id);
}

/**
 * @param {Object} event click event
 * @param {String} itemId versioned id of item being selected
 * @param {Array} allItems array of full item objects comprising all possible selections
 * @param {Array} selectedIds array of selected IDs in current inspector view
 * @param {Func} selectedHandler handler to update selected ID state
 */
export function handleInspectorPortalItemClick(
  event: React.MouseEvent,
  itemId: string,
  allItems: PortalItem[],
  selectedIds: string[],
  selectedHandler: (ids: string[]) => void
) {
  if (event?.shiftKey && selectedIds.length > 0) {
    const lastSelected = selectedIds[selectedIds.length - 1],
      indexFrom = findPortalItemIndexById(lastSelected, allItems),
      indexTo = findPortalItemIndexById(itemId, allItems),
      indexes = [indexFrom, indexTo].sort((a, b) => a - b),
      itemsBetween = allItems.slice(indexes[0] + 1, indexes[1]).map(item => item.id),
      newSelectionMinusClicked = new Set([...selectedIds, ...itemsBetween]);

    newSelectionMinusClicked.delete(itemId);
    const newSelection = [...newSelectionMinusClicked, itemId]; // This enforces the last clicked item

    selectedHandler(newSelection);
  } else if (event?.metaKey || event?.ctrlKey) {
    if (selectedIds.includes(itemId)) selectedHandler(selectedIds.filter(id => id !== itemId));
    else selectedHandler([...selectedIds, itemId]);
  } else selectedHandler([itemId]);
}
