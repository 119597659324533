import styled from "styled-components";
import { Box } from "@thenounproject/lingo-core";

const ThumbnailWrapper = styled(Box).attrs(() => {
  return {
    height: 58,
    width: 58,
    p: "4px",
    border: "default",
    borderRadius: "2px",
    position: "relative",
  };
})``;

export default ThumbnailWrapper;
