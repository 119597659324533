import React from "react";
import { Box, Text, BoxProps } from "@thenounproject/lingo-core";

type Props = {
  title: string;
  message?: string | React.ReactNode;
  children?: React.ReactNode;
  styleOverrides?: BoxProps;
};

const ModalHeader: React.FC<Props> = ({ title, message, children, styleOverrides }) => {
  return (
    <Box
      zIndex={20}
      background="white"
      borderBottom="default"
      as="header"
      width="100%"
      pt="8px"
      display="block"
      {...styleOverrides}>
      <Box
        mx="auto"
        textAlign="center"
        py="16px"
        px={"80px"}
        variations={{ "mq.s": { px: "16px" } }}>
        <Text as="h1" font="ui.title">
          {title}
        </Text>
        {message ? <Text mt="4px">{message}</Text> : null}
      </Box>
      {children}
    </Box>
  );
};

export default ModalHeader;
