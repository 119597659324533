import React, { useCallback } from "react";
import {
  Text,
  DropdownButton,
  PopupMenu,
  useBoolean,
  CustomField,
  CustomFieldOperation,
} from "@thenounproject/lingo-core";
import useNotifications from "@actions/useNotifications";

import useAssetsCustomFieldUpdate from "@redux/actions/assets/useAssetsCustomFieldUpdate";

export type Props = {
  field: CustomField;
  assetUuids: string[];
  updateCustomFieldValue: ReturnType<typeof useAssetsCustomFieldUpdate>[0];
  canEdit: boolean;
  assetFieldValue: string[];
};

const SelectCustomField = ({
  field,
  assetUuids,
  updateCustomFieldValue,
  canEdit,
  assetFieldValue,
}: Props) => {
  const selectedOption = field.options?.find(option => option.id === Number(assetFieldValue?.[0]));
  const [menuShown, showMenu, hideMenu] = useBoolean(false);
  const { showNotification } = useNotifications();

  const handleFieldOptionUpdate = useCallback(
    async (fieldId: number, optionId: number, operation: CustomFieldOperation) => {
      const { error } = await updateCustomFieldValue({
        assetUuids,
        fieldId,
        optionId,
        operation,
      });
      if (error) {
        showNotification({ message: error.message, level: "error" });
      }
    },
    [assetUuids, updateCustomFieldValue, showNotification]
  );

  if (!canEdit) return <Text font="ui.small">{selectedOption?.name || "None"}</Text>;
  return (
    <>
      <DropdownButton
        data-testid="inspector-select-field"
        text={selectedOption?.name || "None"}
        styleOverrides={{ width: "100%" }}
        data-popup-source={`${field.name}-dropdown`}
        onClick={showMenu}
      />
      <PopupMenu source={`${field.name}-dropdown`} open={menuShown} close={hideMenu}>
        <PopupMenu.Item
          key="none"
          title="None"
          onClick={() =>
            handleFieldOptionUpdate(
              field.id,
              Number(assetFieldValue?.[0]),
              CustomFieldOperation.remove
            )
          }
          checked={!assetFieldValue}
        />
        {field.options?.map(o => (
          <PopupMenu.Item
            key={o.id}
            title={o.name}
            onClick={() => handleFieldOptionUpdate(field.id, o.id, CustomFieldOperation.add)}
            checked={assetFieldValue?.includes(String(o.id))}
          />
        ))}
      </PopupMenu>
    </>
  );
};

export default SelectCustomField;
