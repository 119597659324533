/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import {
  Flex,
  SpaceThemeNames,
  AnyObject,
  ButtonStyle,
  Button,
  Text,
  Box,
  Icon,
  useSpaceThemeName,
  useNavigation,
} from "@thenounproject/lingo-core";
import { TabletDown, TabletUp } from "@features/media-queries";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";

type SectionData = {
  path: string;
  name: string;
};

export type SectionFooterProps = {
  prevSection: SectionData;
  nextSection: SectionData;
  totalSections: number;
  currentSectionIndex: number;
};

const SectionFooterNav: React.FC<SectionFooterProps> = ({
  prevSection,
  nextSection,
  totalSections,
  currentSectionIndex,
}) => {
  const themeName = useSpaceThemeName();
  const { showModal } = useShowModal();
  const navigate = useNavigation();

  const themeStrings: { [key: string]: string } = {
    [SpaceThemeNames.classic]: { prevSection: prevSection?.name, nextSection: nextSection?.name },
    [SpaceThemeNames.scher]: {
      prevSection: "Previous",
      nextSection: "Up Next",
      prevSubtitle: prevSection?.name,
      nextSubtitle: nextSection?.name,
    },
    [SpaceThemeNames.wyman]: { prevSection: "Previous", nextSection: "Up next" },
  }[themeName];

  const themeButtonFontStyle: string = {
    [SpaceThemeNames.classic]: "ui.regular",
    [SpaceThemeNames.scher]: "ui.title",
    [SpaceThemeNames.wyman]: "ui.regularSemiBold",
  }[themeName];

  const themeButtonStyle: ButtonStyle = {
    [SpaceThemeNames.classic]: ButtonStyle.tertiary,
    [SpaceThemeNames.scher]: ButtonStyle.tertiary,
    [SpaceThemeNames.wyman]: ButtonStyle.outline,
  }[themeName];

  const themeButtonOverrides: AnyObject = {
    [SpaceThemeNames.classic]: {},
    [SpaceThemeNames.scher]: { primaryColor: "black" },
    [SpaceThemeNames.wyman]: {},
  }[themeName];

  return (
    <>
      {(prevSection || nextSection) && (
        <TabletUp>
          <Flex justifyContent="space-between" borderTop="default" py="l" mt="80px">
            <Flex alignItems="center">
              {prevSection && (
                <Flex flexDirection="column">
                  <Button
                    iconBefore="navigation.chevron-left"
                    link={prevSection?.path}
                    text={themeStrings.prevSection}
                    buttonStyle={themeButtonStyle}
                    disabled={!prevSection}
                    fontStyle={themeButtonFontStyle}
                    themeOverrides={themeButtonOverrides}
                  />
                  {themeStrings.prevSubtitle && (
                    <Text color="grayDarkest" ml="24px">
                      {themeStrings.prevSubtitle}
                    </Text>
                  )}
                </Flex>
              )}
            </Flex>
            <Flex alignItems="center">
              {nextSection && (
                <Flex flexDirection="column" textAlign="right">
                  <Button
                    icon="navigation.chevron-right"
                    link={nextSection?.path}
                    text={themeStrings.nextSection}
                    buttonStyle={themeButtonStyle}
                    disabled={!nextSection}
                    fontStyle={themeButtonFontStyle}
                    themeOverrides={themeButtonOverrides}
                  />
                  {themeStrings.nextSubtitle && (
                    <Text color="grayDarkest" mr="24px">
                      {themeStrings.nextSubtitle}
                    </Text>
                  )}
                </Flex>
              )}
            </Flex>
          </Flex>
        </TabletUp>
      )}
      <TabletDown>
        <Flex
          alignItems="center"
          borderTop="default"
          height={65}
          bottom={0}
          left={0}
          zIndex={100}
          width="100%"
          background="grayLightest"
          px="16px"
          position="fixed">
          <Flex flex={"50% 0 1"} maxWidth={`calc(50% - 40px)`}>
            {prevSection && (
              <Flex
                style={{
                  cursor: "pointer",
                }}
                alignItems="center"
                maxWidth="100%"
                tabIndex={0}
                role="button"
                onClick={() => navigate.push(prevSection.path)}>
                <Flex alignItems="center">
                  <Icon iconId="navigation.chevron-left" />
                </Flex>
                <Box
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}>
                  {prevSection.name}
                </Box>
              </Flex>
            )}
          </Flex>
          <Flex flex={"80px 0 0"} justifyContent="center">
            <Box
              as="button"
              background="grayLighter"
              p="10px 12px"
              borderRadius="default"
              onClick={() => showModal(ModalTypes.KIT_NAVIGATION, {})}>
              <Text font="ui.small">
                {currentSectionIndex + 1} of {totalSections}
              </Text>
            </Box>
          </Flex>
          <Flex flex={"50% 0 1"} maxWidth={`calc(50% - 40px)`} justifyContent="flex-end">
            {nextSection && (
              <Flex
                style={{
                  cursor: "pointer",
                }}
                alignItems="center"
                maxWidth="100%"
                tabIndex={0}
                role="button"
                onClick={() => navigate.push(nextSection.path)}>
                <Box
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}>
                  {nextSection.name}
                </Box>
                <Flex alignItems="center">
                  <Icon iconId="navigation.chevron-right" />
                </Flex>
              </Flex>
            )}
          </Flex>
        </Flex>
      </TabletDown>
    </>
  );
};

export default SectionFooterNav;
