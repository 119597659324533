import { API, ErrorCode } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

const templates = {
  styleGuide: "brand_style_guide",
  uiLibrary: "ui_component_library",
  assetLibrary: "digital_asset_library",
} as const;
export type TemplateName = keyof typeof templates;

type Args = {
  spaceId: number;
  name?: string;
  templateName?: TemplateName;
};

const [useCreateKit, createKit] = createAsyncAction(
  "kits/create",
  async ({ spaceId, name, templateName }: Args) => {
    if (name?.trim().length === 0) {
      throw {
        code: ErrorCode.invalidParams,
        message: "That is not a valid name.",
      };
    }
    return await API.call<string>({
      endpoint: "kits",
      method: "POST",
      data: {
        space_id: spaceId,
        name,
        template_id: templates[templateName],
      },
      entity: API.Entity.kit,
    });
  }
);

export default useCreateKit;
export { createKit };
