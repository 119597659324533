import { createAsyncAction } from "@actions/actionCreators";
import { API } from "@thenounproject/lingo-core";

type Args = {
  kitCollectionId: string;
};

const [useDeleteKitCollection, deleteKitCollection] = createAsyncAction(
  "kitCollections/delete",
  async ({ kitCollectionId }: Args) => {
    return await API.call<string>({
      endpoint: `kit_collections/${kitCollectionId}`,
      method: "DELETE",
      entity: API.Entity.kitCollection,
    });
  }
);
export default useDeleteKitCollection;
export { deleteKitCollection };
