import { PageContent } from "../pages/templates/PageLayout";

import Images from "../data/images";
import { MarketingButtons } from "../components/MarketingButtons";
import featureCards from "./featureCards";

const content: PageContent = {
  style: "light",
  sections: [
    {
      type: "HeroSection",
      title: "Affordable plans tailored to your needs",
      subtitle:
        "Whether you’re a small business or a fortune 500 enterprise Lingo can help strengthen your brand for an affordable and fair price.",
      image: {
        src: Images.Pricing.hero,
        alt: "",
      },
      primaryButton: MarketingButtons.requestQuote,
      secondaryButton: MarketingButtons.bookDemo,
    },
    {
      type: "MediaSection",
      title: "Maximize Brand ROI",
      layoutDirection: "image-left",
      subtitle:
        "Companies use Lingo to build awareness of their brand, ensure consistency and to empower others to create on their own.",
      image: {
        src: Images.Pricing.roi,
        alt: "Slide 1",
      },
      body: {
        style: "tabList",
        items: [
          {
            title: "Company-wide time savings",
            body: "Ensure your entire company and outside partners can always find the latest brand assets and documentation.",
          },
          {
            title: "Robust analytics",
            body: "Track how often your content is being downloaded, and monitor the performance of each uploaded asset.",
          },
          {
            title: "User management",
            body: "Provision access to your content with a granular permissions system.",
          },
          {
            title: "An on-brand experience",
            body: "Access your Lingo space via a custom domain, and apply your brand colors and fonts to the user interface.",
          },
        ],
      },
    },
    {
      type: "CardSection",
      title: "More powerful features to manage your assets",
      footerButton: {
        link: "/product/features",
        text: "View all features",
        buttonStyle: "outline",
      },
      items: [
        featureCards.customBranding,
        featureCards.customDomains,
        featureCards.usageGuidelines,
        featureCards.fileConversions,
        featureCards.tags,
        featureCards.kitVersioning,
      ],
    },
    { type: "CtaSection" },
  ],
};
export default content;
