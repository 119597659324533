import { API, Space, Account, camelize } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  spaceId: number;
  stripeToken: string;
  receiptExtra?: string;
};

const [useReplaceCreditCard, replaceCreditCard] = createAsyncAction(
  "billing/replaceCreditCard",
  async ({ spaceId, stripeToken, receiptExtra }: Args) => {
    const res = await API.call<{ space: Space & { card: Account["card"] } }>({
      endpoint: `spaces/${spaceId}/payment-source/`,
      method: "PUT",
      data: {
        stripe_token: stripeToken,
        receipt_extra: receiptExtra,
      },
    });

    return { result: camelize(res.result.space.card) };
  }
);

export default useReplaceCreditCard;
export { replaceCreditCard };
