import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

export type LinkSSOArgs = {
  token: string;
};

const [useLinkSSO, linkSSO] = createAsyncAction("auth/linkSSO", async (args: LinkSSOArgs) => {
  return await API.call<{ result: number }>({
    endpoint: `link-account/${args.token}`,
    method: "POST",
    entity: API.Entity.space,
  });
});

export default useLinkSSO;
export { linkSSO };
