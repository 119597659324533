import { useAppDispatchV1 } from "@redux/hooks";

import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  APIResponse,
  ErrorCode,
  IfVoid,
  isError,
  LingoError,
  RequireOnlyOne,
} from "@thenounproject/lingo-core";
import type { AppDispatch, RootState } from "@redux/store";
import { useCallback, useState } from "react";
import { captureException } from "../../../adapters/sentry";

type ActionConfig = {
  state: RootState;
  rejectValue: LingoError;
  dispatch: AppDispatch;
};

type AsnycActionPayloadCreator<Result, Args> = (
  arg: Args,
  api: { getState: () => RootState; dispatch: AppDispatch }
) => Promise<Result>;

export type AsyncResponse<Result = unknown> = Promise<
  { isSuccess: boolean } & RequireOnlyOne<{ response?: Result; error?: LingoError }>
>;

export type HookAction<Result, Args> = (payload: Args) => AsyncResponse<Result>;

export type HookStatus<Result> = {
  isProcessing: boolean;
  error?: LingoError;
  result?: Result;
  reset: () => void;
};

export type AsyncHook<Result, Args> = () => readonly [HookAction<Result, Args>, HookStatus<Result>];

type ActionResult<Result, Extra> = IfVoid<Result, void, APIResponse<Result>> & Extra;
const asyncThunkCreator = createAsyncThunk.withTypes<ActionConfig>();

export default function createAsyncAction<Result = unknown, Args = void, Extra = void>(
  typePrefix: string,
  payloadCreator: AsnycActionPayloadCreator<ActionResult<Result, Extra>, Args>
): [AsyncHook<ActionResult<Result, Extra>, Args>, typeof action] {
  const action = asyncThunkCreator(typePrefix, async (args: Args, thunkApi) => {
    try {
      const res = await payloadCreator(args, thunkApi);
      return thunkApi.fulfillWithValue(res);
    } catch (err) {
      if (isError(err)) {
        return thunkApi.rejectWithValue(err);
      } else if (err) {
        captureException(err);
        throw err;
      }
    }
  });
  const useAction = () => {
    const [isProcessing, setProcessing] = useState(false);
    const [error, setError] = useState<LingoError>();
    const [result, setResult] = useState<ActionResult<Result, Extra>>();
    const dispatch = useAppDispatchV1();

    const reset = useCallback(() => {
      setError(undefined);
      setResult(undefined);
    }, []);
    const performAction = useCallback(
      async (args: Args) => {
        setProcessing(true);
        setError(undefined);
        const res = await dispatch(action(args));
        setProcessing(false);
        if (action.fulfilled.match(res)) {
          setResult(res.payload);
          return { isSuccess: true, response: res.payload };
        } else if (action.rejected.match(res)) {
          if (!res.payload && res.error) {
            const err = {
              code: ErrorCode.unknown,
              message:
                "Something went wrong. We've been notified and are working on it. Please try again.",
            };
            setError(err);
            return { isSuccess: false, error: err };
          } else {
            setError(res.payload);
            return { isSuccess: false, error: res.payload };
          }
        }
      },
      [dispatch]
    );
    return [performAction, { isProcessing, error, result, reset }] as const;
  };
  return [useAction, action];
}
