import React from "react";
import styled from "styled-components";
import { Text, Flex, Button, useBoolean, PopupMenu } from "@thenounproject/lingo-core";
import KitCollectionMenuItems from "./KitCollectionMenuItems";

const Container = styled(Flex).attrs(() => {
  return {
    width: "100%",
    alignItems: "center",
    flexWrap: "wrap",
    mb: "l",
  };
})``;

type Props = {
  name: string;
  kitCollectionId?: string;
};

const SpaceKitsSectionTitle: React.FC<Props> = ({ name, kitCollectionId }) => {
  const [menuOpen, setMenuOpen, setMenuClosed] = useBoolean(false);
  const id = "collection-section-title-pop-".concat(kitCollectionId);

  const popupProps = {
    source: id,
    close: setMenuClosed,
    open: menuOpen,
    vPos: undefined,
    hPos: undefined,
    width: 220,
    maxHeight: undefined,
    stayOpenOnClick: false,
    clickAwayClose: true,
    "data-testid": id,
  };

  return (
    <Container>
      <Text font="ui.mediumSubtitle">{name}</Text>
      {kitCollectionId && (
        <Button
          icon="navigation.overflow"
          themeOverrides={{
            primaryColor: "white",
            primaryColorDark: "white",
            primaryColorTint: "black",
          }}
          onClick={setMenuOpen}
          data-popup-source={id}
        />
      )}
      {kitCollectionId && menuOpen && (
        <PopupMenu {...popupProps}>
          <KitCollectionMenuItems kitCollectionId={kitCollectionId} />
        </PopupMenu>
      )}
    </Container>
  );
};

export default SpaceKitsSectionTitle;
