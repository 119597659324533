import { MarketingButtons } from "../../components/MarketingButtons";
import { PageContent } from "../../pages/templates/PageLayout";
import featureCards from "../featureCards";
import Images from "../images";
import { exampleLinks } from "./exampleLinks";

const data: PageContent = {
  style: "light",
  sections: [
    {
      type: "HeroSection",
      title: "Brand management software",
      subtitle: `Ensure brand consistency at scale by creating a team wide hub where everyone can find the assets they need and the guidelines on how to use them.  [See an example](${exampleLinks.brandTeams}).`,
      primaryButton: MarketingButtons.getStarted,
      secondaryButton: MarketingButtons.bookDemo,
      image: {
        src: Images.Solutions.brandTeam_hero,
        alt: "Brand teams",
      },
    },
    {
      type: "MediaSection",
      title: "A single source of truth for your brand",
      subtitle:
        "One location for all your product shots, logos, videos, brand guidelines and any other type of brand asset. ",
      layoutDirection: "image-left",
      body: {
        style: "tabList",
        items: [
          {
            title: "Easy to build",
            body: "Lingo's drag n drop interface and custom branding means you can build your brand hub in days not months.",
            image: {
              src: Images.Solutions.brandTeam_easyToBuild,
              alt: "Easy to build",
            },
          },
          {
            title: "Integrates with Figma",
            body: "Assets created in Figma can be synced to Lingo so you can build style guides and asset libraries that can be used by your entire organization",
            image: {
              src: Images.Solutions.brandTeam_figmaIntegration,
              alt: "Integrates with Figma",
            },
          },
          {
            title: "Private and public sharing",
            body: "Keep your brand hub private so only people with permission can have access, or make your hub public so anyone with the link can have access - no account required.",
            image: {
              src: Images.Solutions.brandTeam_publicSharing,
              alt: "Private and public sharing",
            },
          },
          {
            title: "Automatic file conversion",
            body: "Have an SVG but need a PNG? Lingo auto-generates alternate file types of every asset you put in. No more time wasted on requests to export assets for different file types.",
            image: {
              src: Images.Solutions.brandTeam_fileConversion,
              alt: "Automatic file conversion",
            },
          },
        ],
      },
    },
    {
      type: "CardSection",
      title: "More powerful features to manage your assets",
      footerButton: {
        link: "/product/features",
        text: "View all features",
        buttonStyle: "outline",
      },
      items: [featureCards.customBranding, featureCards.customDomains, featureCards.insights],
    },
    { type: "CtaSection" },
  ],
};

export default data;
