import { API, Item } from "@thenounproject/lingo-core";
import { createQueryAction } from "../actionCreators";

type Args = {
  spaceId: string | number;
  kitId: string;
};

const [useRecoveredItems, fetchRecoveredItems] = createQueryAction<Args, Item[], string[]>(
  {
    entity: "items",
    action: "queryRecovered",
    denormalize: { asset: "assets" },
  },
  async ({ args, paging }) => {
    const res = await API.call<{ items: string[] }>({
      endpoint: `kits/${args.spaceId}/${args.kitId}/recovery`,
      method: "GET",
      entity: API.Entity.item,
      query: {
        limit: 200,
        page: paging.page,
      },
    });
    return { result: res.result.items, entities: res.entities };
  }
);

export default useRecoveredItems;
export { fetchRecoveredItems };
