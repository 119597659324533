import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  kitId: string;
  kitCollectionId: string;
};
const [useRemoveKitFromCollection, removeKitFromCollection] = createAsyncAction(
  "kitCollections/removeKit",
  async ({ kitId, kitCollectionId }: Args) => {
    return await API.call<string>({
      endpoint: `kit_collections/${kitCollectionId}/kits/${kitId}`,
      method: "DELETE",
      entity: API.Entity.kitCollection,
    });
  }
);
export { removeKitFromCollection };
export default useRemoveKitFromCollection;
