import React, { ComponentProps, Fragment, useMemo } from "react";
import styled from "styled-components";
import { Flex, Text, Box, Button, Tooltip, ItemType } from "@thenounproject/lingo-core";

import useInspectableActions from "@features/context-menus/hooks/useInspectableActions";
import useWindowSize from "../hooks/useWindowSize";
import InspectorMultiAssetDownload from "./use/InspectorMultiAssetDownload";

import { Inspectable } from "@constants/Inspector";
import { useViewModeContext } from "@contexts/ViewModeProvider";

const DownloadAssetWrapper = styled(Box).attrs({ ml: "l" })``;
const TitleContainer = styled(Box).attrs({
  /* NOTE Might need to pass vars on this max-width
   *  in the future to solve for all edge cases
   */
  maxWidth: "calc(100% - 300px)",
  flexShrink: 1,
})``;
const ControlsContainer = styled(Flex).attrs({ alignItems: "center" })`
  flex-shrink: 0;
`;

const ActionButton = styled(Button).attrs({
  size: "small",
  mr: "s",
  themeOverrides: {
    primaryColor: "grayLightest",
    primaryColorTint: "black",
    primaryColorDark: "grayLight",
  },
})``;

export type Props = {
  inspectables: Inspectable[];
  canEdit: boolean;
};

const InspectorSimpleMultiple: React.FC<Props> = ({ inspectables, canEdit }) => {
  const { belowInspectorThreshold } = useWindowSize();
  const { setInspectorMode } = useViewModeContext();

  const hasFiles = inspectables.some(i => i?.asset?.permalink);
  const sectionId = inspectables.find(i => i?.item?.sectionId)?.item?.sectionId;

  const { moveItems, copyItems, deleteAssets, deleteItems, trashItems, addToKit } =
    useInspectableActions(inspectables);
  const hasAssets = useMemo(
    () => inspectables.some(i => !i.item || i.item?.type === ItemType.asset),
    [inspectables]
  );

  function renderTitleSection() {
    return (
      <TitleContainer>
        <Text font="ui.regularBold" truncate>
          {`${inspectables.length} selected`}
        </Text>
      </TitleContainer>
    );
  }

  function renderControlsSection() {
    function viewDetailsButton() {
      if (belowInspectorThreshold) return null;
      return (
        <>
          <Tooltip source="view-details-button" direction={Tooltip.Direction.Top}>
            View details
          </Tooltip>
          <ActionButton
            data-tooltip-source="view-details-button"
            aria-label="View details"
            icon="info.info"
            onClick={() => setInspectorMode("detailed")}
          />
        </>
      );
    }

    const moveToButton = () => {
      if (!canEdit) return null;
      if (!moveItems) {
        return null;
      }
      if (!sectionId) return null;
      return (
        <>
          <Tooltip source="move-to-button" direction={Tooltip.Direction.Top}>
            Move to...
          </Tooltip>
          <ActionButton
            data-tooltip-source="move-to-button"
            aria-label="Move to..."
            onClick={moveItems}
            icon="action.move_to"
          />
        </>
      );
    };

    const copyToButton = () => {
      if (!canEdit) return null;
      if (!copyItems) return null;
      if (!sectionId) return null;
      return (
        <>
          <Tooltip source="copy-to-button" direction={Tooltip.Direction.Top}>
            Copy to...
          </Tooltip>
          <ActionButton
            data-tooltip-source="copy-to-button"
            aria-label="Copy to..."
            onClick={copyItems}
            icon="action.copy_to"
          />
        </>
      );
    };

    function addToKitButton() {
      if (!canEdit) return null;
      if (!addToKit) return null;
      return (
        <>
          <Tooltip source="add-to-button" direction={Tooltip.Direction.Top}>
            Add to
          </Tooltip>
          <ActionButton
            data-tooltip-source="add-to-button"
            icon="action.add-to"
            aria-label="Add to kit"
            onClick={addToKit}
          />
        </>
      );
    }

    function deleteButton() {
      if (!canEdit) return null;
      const buttonProps: Partial<ComponentProps<typeof ActionButton>> = {};
      if (deleteAssets) {
        buttonProps.onClick = deleteAssets;
        buttonProps.title = "Delete";
      } else if (trashItems) {
        // Don't show remove from kit button for assets
        return null;
      } else if (deleteItems) {
        buttonProps.onClick = deleteItems;
        buttonProps.title = "Delete";
      } else {
        return null;
      }
      return (
        <>
          <Tooltip source="delete-assets-button" direction={Tooltip.Direction.Top}>
            {buttonProps.title}
          </Tooltip>
          <ActionButton
            data-tooltip-source="delete-assets-button"
            aria-label="Delete"
            icon="action.trash"
            {...buttonProps}
          />
        </>
      );
    }

    function downloadButton() {
      if (!hasAssets) return null;
      let el = null;
      if (hasFiles) {
        const assetDownloadProps = {
          inspectables,
        };
        el = <InspectorMultiAssetDownload {...assetDownloadProps} />;
      } else {
        el = (
          // We need this wrapper because disabled elements don't fire the events
          // that the tooltip relies on
          <Box data-tooltip-source="no-downloads">
            <Button text="Download" disabled icon="download" size="small" />
            <Tooltip source="no-downloads" direction={Tooltip.Direction.Top}>
              No files to download
            </Tooltip>
          </Box>
        );
      }

      return el && <DownloadAssetWrapper>{el}</DownloadAssetWrapper>;
    }

    return (
      <ControlsContainer>
        <Fragment>
          {moveToButton()}
          {copyToButton()}
          {addToKitButton()}
          {deleteButton()}
          {viewDetailsButton()}
          {downloadButton()}
        </Fragment>
      </ControlsContainer>
    );
  }

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      data-testid="inspector-simple-multiple">
      {renderTitleSection()}
      {renderControlsSection()}
    </Flex>
  );
};

export default InspectorSimpleMultiple;
