import _merge from "lodash/merge";
import _uniqBy from "lodash/uniqBy";
import _sortBy from "lodash/sortBy";
import { createReducer } from "@reduxjs/toolkit";
import { combineReducers } from "redux";

import { SearchFilterInput, SearchFilterTypes } from "@thenounproject/lingo-core";

import { QueryData } from "@redux/actions/actionCreators/createQueryAction";
import { mergeQuery } from "../helpers/mergeQuery";

import { addQueryFilter } from "@redux/actions/search/useAddQueryFilter";
import { replaceQueryFilters } from "@redux/actions/search/useReplaceQueryFilters";
import { removeQueryFilter } from "@redux/actions/search/useRemoveQueryFilter";
import { clearQueryFilter } from "@redux/actions/search/useClearQueryFilter";
import { changeKeywordFilter } from "@redux/actions/search/useChangeKeywordFilter";
import { changeSort } from "@redux/actions/search/useChangeSort";
import { clearRecentSearches } from "@redux/actions/search/useClearRecentSearches";
import { searchItems } from "@redux/actions/search/useSearchItems";
import { searchJumpTo } from "@redux/actions/search/useSearchJumpTo";

import constructKeywordFilter from "@helpers/constructKeywordFilter";
import {
  SearchContext,
  SearchContextState,
  setSearchContext,
} from "@redux/actions/search/useSetSearchContext";

const DEFAULT_SORT = "-recent";

export type Aggregations = {
  kit?: SearchFilterInput[];
  orientation?: SearchFilterInput[];
  type?: SearchFilterInput[];
};

type DefaultState = {
  filters: SearchFilterInput[];
  query: null | string;
  context: SearchContextState;
  sort: string;
  prefixes: any[]; // Update this with proper type
  searchHistory: SearchFilterInput[][]; // Update this with proper type
};

const state: DefaultState = {
  filters: [],
  query: null,
  context: { context: SearchContext.global },
  sort: DEFAULT_SORT,
  prefixes: [],
  searchHistory: JSON.parse(window.localStorage.getItem("search_history")) || [],
};

function pushSearchHistory(state: DefaultState, filters: SearchFilterInput[]) {
  let { searchHistory } = state;
  if (filters.length > 0) {
    const newHistory = [...state.searchHistory];
    newHistory.unshift(_sortBy(filters, [f => f.type === "keyword", f => f.type]));
    searchHistory = _uniqBy(
      newHistory,
      recent =>
        recent.find(f => f.type === "keyword")?.value ||
        recent
          .map(f => JSON.stringify(f.id + f.display))
          .sort()
          .join(",")
    ).slice(0, 3);
    window.localStorage.setItem("search_history", JSON.stringify(searchHistory));
  }
  return searchHistory;
}

const queries = createReducer<Record<string, QueryData<unknown, unknown>>>({}, builder => {
  builder.addDefaultCase((state, action) => {
    mergeQuery(state, action, "search");
  });
});

const objects = createReducer(state, builder => {
  builder
    .addCase(addQueryFilter, (state, action) => {
      const existingFiltersWithoutDuplicate = action.payload.id
        ? state.filters.filter(f => f.id !== action.payload.id)
        : state.filters;
      state.filters = [...existingFiltersWithoutDuplicate, action.payload];
    })
    .addCase(replaceQueryFilters, (state, action) => {
      state.filters = action.payload;
      if (!state.filters.length) {
        state.sort = DEFAULT_SORT;
      }
    })
    .addCase(clearQueryFilter, state => {
      state.filters = [];
      state.sort = DEFAULT_SORT;
    })
    .addCase(removeQueryFilter, (state, action) => {
      const newFilters = action.payload.filterId
        ? state.filters.filter(f => f.id !== action.payload.filterId)
        : state.filters.slice(0, -1);
      state.filters = newFilters;
      if (!newFilters.length) {
        state.sort = DEFAULT_SORT;
      }
    })
    .addCase(changeKeywordFilter, (state, action) => {
      const tokenFilters = state.filters.filter(f => f.type !== "keyword"),
        newKeywordFilter = constructKeywordFilter(action.payload),
        newFilters = action.payload ? [...tokenFilters, newKeywordFilter] : tokenFilters;
      state.filters = newFilters;
      if (!newFilters.length) {
        state.sort = DEFAULT_SORT;
      }
    })
    .addCase(changeSort, (state, action) => {
      state.sort = action.payload;
    })
    .addCase(setSearchContext, (state, action) => {
      if (
        state.context.context !== action.payload.context ||
        action.payload.portalId !== state.context.portalId
      ) {
        state.filters = state.filters.filter(f => f.type !== SearchFilterTypes.kit);
      }
      state.context = action.payload;
    })
    .addCase(clearRecentSearches, state => {
      window.localStorage.removeItem("search_history");
      state.searchHistory = [];
    })
    .addCase(searchItems.fulfilled, (state, action) => {
      const searchHistory = pushSearchHistory(state, action.payload.result.filters);
      state.searchHistory = searchHistory;
    })
    .addCase(searchJumpTo.fulfilled, (state, action) => {
      const searchHistory = pushSearchHistory(state, action.payload.result.filters);
      state.searchHistory = searchHistory;
    });
});

export default combineReducers({
  queries,
  objects,
});
