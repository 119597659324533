import _merge from "lodash/merge";
import _remove from "lodash/remove";
import { Invitation } from "@thenounproject/lingo-core";
import { sendInvitations } from "@redux/actions/invitations/useSendInvitations";
import { fetchSpaceInvitations } from "@redux/actions/invitations/useSpaceInvitations";
import { deleteInvitations } from "@redux/actions/invitations/useDeleteInvitations";
import createEntityReducer from "../helpers/createEntityReducer";

export default createEntityReducer<Invitation>(
  "invitations",
  queryBuilder => {
    queryBuilder
      .addCase(sendInvitations.fulfilled, (state, action) => {
        const { spaceId } = action.meta.arg;
        const { result } = action.payload;
        const invitationIds = result.invitations.filter(i => i.success).map(i => i.result);
        fetchSpaceInvitations.getQueryData(state, { spaceId: spaceId }).forEach(q => {
          q.data.invitations.push(...invitationIds);
          q.data.total += invitationIds.length;
        });
      })
      .addCase(deleteInvitations.fulfilled, (state, action) => {
        const { spaceId } = action.meta.arg;
        const { result } = action.payload;
        const removedInvitationIds = result.invitations.filter(i => i.success).map(i => i.result);
        fetchSpaceInvitations.getQueryData(state, { spaceId: spaceId }).forEach(q => {
          _remove(q.data.invitations, id => removedInvitationIds.includes(id));
          q.data.total -= removedInvitationIds.length;
        });
      });
  },
  objectBuilder => {
    objectBuilder.addDefaultCase((state, action: any) => {
      const invitations =
        action?.response?.entities?.invitations ?? action.payload?.entities?.invitations;
      if (invitations) {
        return _merge(state, invitations);
      }
    });
  }
);
