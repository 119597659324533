import { v4 as genUuid } from "uuid";
import { ItemType } from "@thenounproject/lingo-core";

import {
  generateInsertPositionForUploads,
  getItemData,
  UploadAction,
  uploadActionForFile,
  type InsertPosition,
} from "@actions/uploads";
import { createAsyncAction } from "../actionCreators";
import { fetchContentTypes } from "../useFetchContentTypes";
import { enqueueUpload } from "../uploads/enqueueUpload";

type Args = {
  files: File[];
  itemType: ItemType;
  insertPosition: InsertPosition;
};

const [useCreateFileAssets, createFileAssets] = createAsyncAction(
  "assets/createFileAsset",
  async ({ files, itemType, insertPosition: startingInsertPosition }: Args, thunkApi) => {
    const contentTypes = await fetchContentTypes.lazy(thunkApi, { itemType });
    const batchId = genUuid();
    const insertPosition = generateInsertPositionForUploads(files.length, startingInsertPosition);

    /**
     * Queue up the uploads with meta
     */
    files
      .map(file => {
        const { upload, asset } = uploadActionForFile(file, contentTypes, insertPosition, batchId);
        asset.space_id = insertPosition.spaceId;
        upload.isAsset = itemType === ItemType.asset;
        const { entity, item } = getItemData(insertPosition);
        if (item) {
          item.type = itemType;
        }
        asset.item = item;
        return {
          upload,
          requestData: {
            endpoint: "assets",
            entity,
            method: "POST",
            file: file,
            data: asset,
          },
        } as UploadAction;
      })
      .forEach(action => {
        thunkApi.dispatch(enqueueUpload(action));
      });
  }
);
export default useCreateFileAssets;
export { createFileAssets };
