import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  spaceId: number;
};

const [useJoinSpace, joinSpace] = createAsyncAction("spaces/join", async ({ spaceId }: Args) => {
  return await API.call<string>({
    endpoint: `spaces/${spaceId}/join`,
    method: "POST",
    entity: API.Entity.space,
  });
});

export default useJoinSpace;
export { joinSpace };
