import React, { useEffect, useState, useContext } from "react";
import { KitDisplaySize } from "../components/kits/types";
import { initialState, KitContext, KIT_DISPLAY_STORAGE_KEY, KitContextType } from "./KitContext";
import { useObject } from "@hooks/useObject";

export default function KitProvider({ children }: { children: React.ReactNode }) {
  const [kitDisplaySize, setKitDisplaySize] = useState<KitDisplaySize>(initialState.kitDisplaySize);

  useEffect(() => {
    localStorage.setItem(KIT_DISPLAY_STORAGE_KEY, kitDisplaySize);
  }, [kitDisplaySize]);

  const context: KitContextType = useObject({
    kitDisplaySize: kitDisplaySize,
    setKitDisplaySize: setKitDisplaySize,
  });

  return <KitContext.Provider value={context}>{children}</KitContext.Provider>;
}

export const useKitContext = () => useContext(KitContext);
