import React, { Fragment, useEffect, useMemo } from "react";
import {
  Flex,
  Box,
  OptionCard,
  Text,
  Button,
  Tooltip,
  Space,
  ActivityIndicator,
} from "@thenounproject/lingo-core";
import useSpaces from "@redux/actions/spaces/useSpaces";

function canSelectSpace(s: Space) {
  if (s.access.role !== "owner") return false;
  if (!s.subscription.plan) return true;
  if (s.subscription.plan.includes("ent")) return false;
  return true;
}

type Props = {
  onSpaceSelected: (space: Space) => void;
};

const SelectSpace: React.FC<Props> = ({ onSpaceSelected }) => {
  const { data: _spaces, isLoading } = useSpaces();

  const {
    spaces = [],
    ownedSpaces = [],
    joinedSpaces = [],
  } = useMemo(() => {
    if (!_spaces) return {};
    const compare = (a, b) => {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    };

    const spaces = [..._spaces];
    spaces.sort((a, b) => compare(a.name, b.name));
    return {
      spaces,
      ownedSpaces: spaces?.filter(s => s.access.role === "owner"),
      joinedSpaces: spaces?.filter(s => s.access.role !== "owner"),
    };
  }, [_spaces]);

  useEffect(() => {
    // If we only have one space, auto select it
    if (spaces.length === 1 && canSelectSpace(spaces[0])) {
      onSpaceSelected(spaces[0]);
    }
  }, [onSpaceSelected, spaces]);

  function tooltip(space: Space) {
    if (
      space.subscription.plan &&
      space.subscription.plan.includes("ent") &&
      space.access.role === "owner"
    ) {
      return (
        <Text color="white">
          This space is on an enterprise plan.{" "}
          <Button
            buttonStyle="tertiary"
            themeOverrides={{
              primaryColor: "white",
              primaryColorDark: "white",
            }}
            fontStyle="ui.regularBold"
            text="Contact us"
          />{" "}
          to update your plan.
        </Text>
      );
    }
  }

  function renderSpaces(label: string, spaceList: Space[]) {
    if (!spaceList) return null;
    return (
      <Fragment>
        <Text as="label" mt="l" mb="m">
          {label}
        </Text>
        {spaceList.map(space => {
          const _tooltip = tooltip(space);
          return (
            <Box key={space.id} width="100%" data-tooltip-source={String(space.id)}>
              {_tooltip && (
                <Tooltip direction={Tooltip.Direction.Top} source={String(space.id)}>
                  {_tooltip}
                </Tooltip>
              )}
              <OptionCard
                width="100%"
                cardStyle="disclosure"
                title={space.name}
                disabled={!canSelectSpace(space)}
                image={{ style: "circle", src: space.avatar }}
                onClick={() => onSpaceSelected(space)}
              />
            </Box>
          );
        })}
      </Fragment>
    );
  }

  return (
    <Box px="xl">
      <Text mb="l" mt="l" font="ui.title">
        Select a space to upgrade
      </Text>

      {isLoading ? (
        <Box height="200">
          <ActivityIndicator center />
        </Box>
      ) : (
        <Flex flexDirection="column" textAlign="left">
          {renderSpaces("Your spaces", ownedSpaces)}
          {renderSpaces("Spaces you're joined to", joinedSpaces)}
        </Flex>
      )}
    </Box>
  );
};

export default SelectSpace;
