import React from "react";
import {
  Flex,
  SpaceTheme,
  SelectOption,
  Select,
  SpaceThemeNames,
} from "@thenounproject/lingo-core";
import styled from "styled-components";

import { SpaceThemeSelectOptions } from "@features/theming/types";
import { useThemeDataContext } from "@contexts/ThemeDataProvider";

interface Props<ValueType extends string> {
  themeSelectOption: SpaceThemeSelectOptions;
  newTheme: SpaceTheme;
  updateTheme: ReturnType<typeof useThemeDataContext>["updateEditingTheme"];
  options: SelectOption<ValueType>[];
}

const Wrapper = styled(Flex).attrs({
  width: "100%",
  flexDirection: "column",
})``;

function ThemeSelect<ValueType extends string>({
  options,
  newTheme,
  updateTheme,
  themeSelectOption,
}: Props<ValueType>) {
  const handleChange = (val: string) => {
    updateTheme({ [themeSelectOption]: val });
  };

  return (
    <Wrapper>
      <Select
        options={options}
        onChange={handleChange}
        size="small"
        buttonStyle="dropdown"
        value={newTheme[themeSelectOption] || SpaceThemeNames.classic}
        width="100%"
      />
    </Wrapper>
  );
}

export default ThemeSelect;
