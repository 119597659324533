import { API, camelize } from "@thenounproject/lingo-core";
import { createQueryAction } from "../actionCreators";
import { SnakeCasedPropertiesDeep } from "type-fest";

type Args = {
  spaceId: number;
};

type Result = {
  memberCounts: {
    totalMembers: number;
    owner: number;
    admin: number;
    member: number;
    limitedMember: number;
    invitations: number;
  };
};

// Currently this only returns member counts
const [useSpaceMeta, fetchSpaceMeta] = createQueryAction<Args, Result, Result>(
  {
    entity: "spaces",
    action: "fetchMeta",
    denormalize: false,
    condition: ({ args: { spaceId } }) => Boolean(spaceId),
  },
  async ({ args: { spaceId } }) => {
    const res = await API.call<SnakeCasedPropertiesDeep<Result>>({
      endpoint: `spaces/${spaceId}/meta`,
    });
    return { result: camelize(res.result), entities: {} };
  }
);
export default useSpaceMeta;
export { fetchSpaceMeta };
