import React from "react";
import { KitDisplaySize } from "../components/kits/types";
import { noop } from "lodash";

export const KIT_DISPLAY_STORAGE_KEY = "lingo_kit_display_size";

const initialKitDisplaySize = (localStorage?.getItem(KIT_DISPLAY_STORAGE_KEY) ||
  "medium") as KitDisplaySize;

export type KitContextType = {
  kitDisplaySize: KitDisplaySize;
  setKitDisplaySize: React.Dispatch<React.SetStateAction<KitDisplaySize>>;
};

export const initialState: KitContextType = {
  kitDisplaySize: initialKitDisplaySize,
  setKitDisplaySize: noop,
};

export const KitContext = React.createContext<KitContextType>(initialState);

KitContext.displayName = "KitContext";
