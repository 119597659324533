import { API, Item } from "@thenounproject/lingo-core";
import { createQueryAction } from "../actionCreators";

type Args = {
  spaceId: string | number;
  itemId: string | number;
  version: number;
  assetToken?: string;
};

const [useItem, fetchItem] = createQueryAction<Args, Item, string>(
  {
    entity: "items",
    action: "fetch",
    denormalize: { asset: "assets" },
    condition: ({ args }) => Boolean(args.itemId),
  },
  async ({ args }) => {
    return await API.call<string>({
      endpoint: `spaces/${args.spaceId}/items/${args.itemId}`,
      method: "GET",
      entity: API.Entity.item,
      query: {
        asset_token: args.assetToken,
        v: args.version,
      },
    });
  }
);

export default useItem;
export { fetchItem };
