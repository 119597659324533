import { API, KitVersion } from "@thenounproject/lingo-core";
import { createQueryAction } from "@redux/actions/actionCreators";

type Args = {
  spaceId: number | string;
  kitId: string;
  version: number;
};

const [useKitVersion, fetchKitVersion] = createQueryAction<Args, KitVersion, string>(
  {
    entity: "kitVersions",
    action: "fetch",
  },
  async ({ args }) => {
    return await API.call<string>({
      endpoint: `kits/${args.kitId}`,
      method: "GET",
      entity: API.Entity.kitVersion,
      query: {
        v: args.version,
      },
    });
  }
);

export default useKitVersion;
export { fetchKitVersion };
