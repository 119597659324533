/**
 * This hook will redirect to login using the current url as the "next" param to redirect to
 */

// eslint-disable-next-line no-unused-vars
import { useEffect } from "react";
import { useAppSelectorV1 } from "@redux/hooks";

import type { RootState } from "@redux/store";
import useNavPoint from "@hooks/useNavPoint";
import { buildURL, useNavigation } from "@thenounproject/lingo-core";

export default function useRequireLogin(signup = false) {
  const { space } = useNavPoint();
  const user = useAppSelectorV1((state: RootState) => state.user),
    navigation = useNavigation();

  useEffect(() => {
    if (!user.email) {
      const target = window.location.toString();
      navigation.replace(buildURL(`/${signup ? "signup" : "login"}/`, { space }, { next: target }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return user.email ? user : null;
}
