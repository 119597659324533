/*
 * Container component for kits in a space. Also serves as "landing page" for space.
 * Handles fetching required data & passing down to presentational components.
 */

import React from "react";
import Helmet from "react-helmet";
import _sortBy from "lodash/sortBy";
import {
  ActivityIndicator,
  Box,
  buildPath,
  Space,
  SpacePermission,
  SpaceThemeNames,
  useSpaceThemeName,
  Redirect,
} from "@thenounproject/lingo-core";
import { useInView } from "react-intersection-observer";

import ScrollToTopOnMount from "../../../components/ScrollToTopOnMount";
import SpaceHeaderFixedNav from "../components/SpaceHeaderFixedNav";
import ContentContainer from "../../../components/ContentContainer";
import SpaceHeader from "../components/SpaceHeader";
import AllKitsView from "../components/AllKitsView";
import KitCollectionView from "../components/KitCollectionView";
import SpaceKitCollectionNav from "../components/SpaceKitCollectionNav";

import { useLocation } from "react-router-dom";
import SpaceKitsEmptyView from "../components/SpaceKitsEmptyView";
import useKitCollections from "@actions/kitCollections/useKitCollections";
import useKits from "@actions/kits/useKits";
import useUpgradeOpener from "@hooks/useUpgradeOpener";
import { TabletUp } from "@features/media-queries";

type Props = {
  space: Space;
  kitCollectionId: string;
};

export enum SpaceKitsViews {
  ALL_KITS = "ALL_KITS",
  KIT_COLLECTION = "KIT_COLLECTION",
}

export const sharedThemeVals = {
  [SpaceThemeNames.classic]: {
    contentWrapper: { px: "l" },
    contentContainer: { maxWidth: "960px" },
  },
  [SpaceThemeNames.scher]: {
    contentWrapper: { px: "l" },
    contentContainer: { maxWidth: "1000px" },
  },
  [SpaceThemeNames.wyman]: {
    contentWrapper: { px: "l" },
    contentContainer: { maxWidth: "1140px" },
  },
};

const SpaceKits: React.FC<Props> = ({ space, kitCollectionId }) => {
  const themeName = useSpaceThemeName();
  const { ref: spaceTitleRef, inView: spaceTitleInView } = useInView({ initialInView: true });

  const { data, isLoading: kitsLoading } = useKits({ spaceId: space.id });
  const { kits = [] } = data || {};

  const {
    data: kitCollections,
    isLoading: kitCollectionsLoading,
    error,
  } = useKitCollections({
    spaceId: space.id,
  });

  const isLoading = kitsLoading || kitCollectionsLoading;

  // This will handle upgrade=plan in the url.
  useUpgradeOpener();

  /**
   * Due to the method in which we load our nav points,
   * there's a bit of a lag when navigating from SpaceKits to Kit
   *
   * This injects a loader if the SpaceKits view is loaded when the location matches Kit
   * TODO: Audit navpoints logic
   */
  const location = useLocation(),
    isLoadingKit = location.pathname.includes("/k/");
  if (isLoadingKit) return <ActivityIndicator center size="large" />;

  if (space.access.permissions.includes(SpacePermission.viewDashboard)) {
    return <Redirect to={buildPath(`/dashboard`, { space })} />;
  }

  const spaceKitsViewType = kitCollectionId
    ? SpaceKitsViews.KIT_COLLECTION
    : SpaceKitsViews.ALL_KITS;

  function renderHeader() {
    const { name, theme } = space;
    return (
      <>
        <TabletUp>
          <SpaceHeaderFixedNav spaceTitleInView={spaceTitleInView} />
        </TabletUp>
        <SpaceHeader
          {...{
            space,
            spaceTitleRef,
            name,
            theme,
          }}
        />
      </>
    );
  }

  function renderView() {
    if (error) {
      return (
        <Box width="100%" height="500px">
          {error.message}
        </Box>
      );
    }
    if (isLoading)
      return (
        <Box width="100%" height="500px">
          <ActivityIndicator center size="large" />
        </Box>
      );

    if (!isLoading && !kits.length && !kitCollections.length) {
      return <SpaceKitsEmptyView />;
    }

    switch (spaceKitsViewType) {
      case SpaceKitsViews.ALL_KITS:
        return <AllKitsView kits={kits} kitCollections={kitCollections} />;
      case SpaceKitsViews.KIT_COLLECTION:
        return <KitCollectionView />;
    }
  }

  return (
    <ContentContainer
      poweredBy={space.access.isPublic}
      styleOverrides={{ maxWidth: "none", px: "0", m: "0" }}>
      <ScrollToTopOnMount />
      <Helmet title={`${space.name} kits`} />
      {renderHeader()}
      <SpaceKitCollectionNav
        space={space}
        spaceKitsViewType={spaceKitsViewType}
        kitCollectionId={kitCollectionId}
        kitCollections={kitCollections}
      />
      <Box width="100%" px="l">
        <Box
          mt="xl"
          variations={{ "mq.s": { mt: "l" } }}
          mx="auto"
          width="100%"
          {...sharedThemeVals[themeName].contentContainer}>
          {renderView()}
        </Box>
      </Box>
    </ContentContainer>
  );
};

export default SpaceKits;
