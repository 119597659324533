import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "@actions/actionCreators";

type Args = {
  spaceId: string | number;
  kitId: string;
  isFavorite: boolean;
};

/**
 * Set a kit as a favorite for the current user.
 */

const [useUpdateKitFavorite, updateKitFavorite] = createAsyncAction(
  "kits/updateFavorite",
  async ({ spaceId, kitId, isFavorite }: Args, thunkAPI) => {
    const userId = thunkAPI.getState().user.id;
    return await API.call<string>({
      endpoint: `kits/${spaceId}/${kitId}/members/${userId}`,
      method: "PUT",
      entity: API.Entity.kitMember,
      data: {
        is_favorite: isFavorite,
      },
    });
  }
);

export default useUpdateKitFavorite;
export { updateKitFavorite };
