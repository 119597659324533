import React, { useEffect, useState } from "react";
import queryString from "query-string";
import {
  Box,
  buildURL,
  Text,
  Button,
  ActivityIndicator,
  formatDate,
} from "@thenounproject/lingo-core";

import useExtendTrial from "@actions/billing/useExtendTrial";
import { AuthContainer, AuthContent } from "../auth/AuthElements";
import { useLocation } from "react-router-dom";
import useSpace from "@redux/actions/spaces/useSpace";
import { AuthHeader } from "../auth/AuthHeader";
import Footer from "../Footer";

function ExtendTrial() {
  const location = useLocation(),
    query = queryString.parse(location.search),
    spaceId = query.spaceId as string,
    { data: space } = useSpace({ spaceId }),
    _spaceId = space?.id || spaceId,
    [error, setError] = useState<string>(
      !_spaceId ? "Invalid trial extension link. You may need to log in" : null
    ),
    [processing, setProcessing] = useState(!error);

  const [extendTrial] = useExtendTrial();

  const subState = space?.subscription?.status;
  useEffect(() => {
    if (["trialing", "active"].includes(subState)) {
      setError(null);
      setProcessing(false);
    }
  }, [subState]);

  useEffect(() => {
    if (_spaceId) {
      void extendTrial({ spaceId: _spaceId }).then(res => {
        setProcessing(false);
        if (res.error) {
          setError(res.error.message);
        }
      });
    }
  }, [_spaceId, extendTrial]);

  function renderLoading() {
    return (
      <Box>
        <Text as="h1" font="ui.title">
          Extending Lingo trial
        </Text>
        <ActivityIndicator height={"150px"} center />
      </Box>
    );
  }

  function renderResult() {
    function renderSuccess() {
      return (
        <Box>
          <Text as="h1" font="ui.title">
            Trial successfully extended
          </Text>
          <Text mt="xs">
            Trial for <strong>{space.name}</strong> is extended until{" "}
            <strong>{formatDate(space.subscription.currentPeriodEnd, "MMM do, yyyy")}.</strong>
          </Text>
        </Box>
      );
    }

    function renderError() {
      return (
        <Box>
          <Text as="h1" font="ui.title">
            Oops! Something went wrong
          </Text>
          <Text mt="xs">
            {error}
            <br />
            If you need help, please{" "}
            <Button
              buttonStyle="tertiary"
              size="small"
              text="contact us."
              link="mailto:info@lingoapp.com"
            />
          </Text>
        </Box>
      );
    }
    return (
      <>
        {error ? renderError() : renderSuccess()}
        <Button
          link={space ? buildURL("/", { space }) : "/login"}
          mt="l"
          text={space ? "Go to Space" : "Log in"}
        />
      </>
    );
  }

  return (
    <AuthContainer>
      <AuthHeader />
      <AuthContent withHeader styleOverrides={{ flexDirection: "column", textAlign: "center" }}>
        {processing ? renderLoading() : renderResult()}
      </AuthContent>
      <Footer />
    </AuthContainer>
  );
}

export default ExtendTrial;
