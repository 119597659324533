import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

export type Args = {
  encrypt: boolean;
};

const [useFetchMagicToken, fetchMagicToken] = createAsyncAction(
  "auth/fetchMagicToken",
  async ({ encrypt }: Args) => {
    return await API.call<{ token: string }>({
      endpoint: "me/magic-token/",
      method: "GET",
      query: { encrypt: encrypt ? true : undefined },
    });
  }
);

export default useFetchMagicToken;
export { fetchMagicToken };
