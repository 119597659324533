import { API, KitMember, SpaceMember } from "@thenounproject/lingo-core";
import { createQueryAction } from "@redux/actions/actionCreators";
import { sortBy } from "lodash";

type Args = {
  spaceId: number;
  kitId: string;
};

type AmmendedKitMember = KitMember & {
  spaceRole: string;
};

const [useKitMembers, fetchKitMembers] = createQueryAction<
  Args,
  { members: AmmendedKitMember[]; unjoinedMembers: SpaceMember[] },
  { members: string[]; unjoinedMembers: string[] }
>(
  {
    entity: "kitMembers",
    action: "query",
    pagingKey: "kitMembers",
    denormalize: {
      entity: null,
      members: {
        entity: "kitMembers",
        user: "users",
      },
      unjoinedMembers: {
        entity: "spaceMembers",
        user: "users",
      },
    },
    prepareData: data => {
      if (!data) return data;
      return {
        members: sortBy(data.members, ["user.name"]),
        unjoinedMembers: data.unjoinedMembers,
      };
    },
  },
  async ({ args: { spaceId, kitId } }) => {
    return await API.call<{ members: string[]; unjoinedMembers: string[] }>({
      endpoint: `kits/${spaceId}/${kitId}/members`,
      method: "GET",
      query: { include: "unjoined,space_role" },
      entity: API.Entity.kitMember,
    });
  }
);

export default useKitMembers;
export { fetchKitMembers };
