import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

export type Args = {
  email: string;
};

const [useRequestPasswordReset, requestPasswordReset] = createAsyncAction(
  "auth/requestPasswordReset",
  async (args: Args) => {
    await API.call({
      endpoint: "auth/password-reset/request/",
      method: "POST",
      data: args,
    });
  }
);

export default useRequestPasswordReset;
export { requestPasswordReset };
