import { MarketingButtons } from "../../components/MarketingButtons";
import { PageContent } from "../../pages/templates/PageLayout";
import { exampleLinks } from "./exampleLinks";
import featureCards from "../featureCards";
import Images from "../images";

const data: PageContent = {
  style: "light",
  sections: [
    {
      type: "HeroSection",
      title: "Digital asset management",
      subtitle: `Easily store, organize and share all your digital assets, so you can empower your team to create on their own while ensuring consistency at scale.  [See an example.](${exampleLinks.digitalAssetManagement})`,
      primaryButton: MarketingButtons.getStarted,
      secondaryButton: MarketingButtons.bookDemo,
      image: {
        src: Images.Solutions.DAM_hero,
        alt: "Brand guidelines",
      },
    },
    {
      type: "MediaSection",
      title: "All your assets in one place",
      subtitle:
        "Product shots, editorial photos, and every other content type you can think of in a single place.",
      layoutDirection: "image-left",
      body: {
        style: "tabList",
        items: [
          {
            title: "Visually browsable",
            body: "Stop wasting time diving through complicated folder trees. In Lingo all your assets are visible and easy to locate within Lingo’s gallery.",
            image: {
              src: Images.Solutions.DAM_visuallyBrowse,
              alt: "Content and Context. Together.",
            },
          },
          {
            title: "Stay organized with custom metadata",
            body: "Custom fields and tags make your assets easier to organize, find and manage.",
            image: {
              src: Images.Solutions.DAM_customMetadata,
              alt: "Advanced custom branding",
            },
          },
          {
            title: "No breaking the bank",
            body: "Save your budget to create that next great experience. Why can’t digital asset managers be DAM affordable after all?",
            image: {
              src: Images.Solutions.DAM_affordable,
              alt: "No breaking the bank",
            },
          },
          {
            title: "Self-serve",
            body: "Empower your team to find the assets they need in the format they need all on their own.",
            image: {
              src: Images.Solutions.DAM_selfServe,
              alt: "Self serve assets",
            },
          },
        ],
      },
    },
    {
      type: "CardSection",
      title: "More powerful features to manage your assets",
      footerButton: {
        link: "/product/features",
        text: "View all features",
        buttonStyle: "outline",
      },
      items: [
        featureCards.search,
        featureCards.unlimitedKits,
        featureCards.insights,
        featureCards.integrations,
        featureCards.customFields,
        featureCards.fileConversions,
      ],
    },
    {
      type: "FaqSection",
      title: "Frequently asked questions",
      faqs: [
        {
          question: "What is a digital asset library?",
          answer:
            "A digital asset library is a collection of digital assets that is showcased and organized in a way that makes the assets easy to find, share, understand and use.",
        },
        {
          question: "What is an example of a digital asset?",
          answer:
            "Examples of digital asset are the photos, logos, icons, color swatches, videos, pdfs, illustrations or any other type of digital content you use to construct your brand's digital and physical experiences.",
        },
        {
          question: "Who needs a digital asset library?",
          answer:
            "If your brand has a strong digital or physical presence then you could benefit from establishing a digital asset library.",
        },
        {
          question: "Why is Lingo so affordable compared to competitors?",
          answer:
            "We believe most digital asset library solutions have overshot the needs of 99% of modern businesses - they’re too complex, hard to use and expensive. Lingo is focused on building an affordable and easy to use tool for digital asset management that can be used by both small businesses and large enterprises.",
        },
      ],
    },
    { type: "CtaSection" },
  ],
};

export default data;
