import { AnyObject, API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  sectionId: string;
  updates: AnyObject;
};

const [useUpdateSection, updateSection] = createAsyncAction(
  "sections/update",
  async ({ sectionId, updates }: Args) => {
    return await API.call<string>({
      endpoint: `/sections/${sectionId}`,
      method: "PUT",
      data: updates,
      entity: API.Entity.section,
    });
  }
);

export default useUpdateSection;
export { updateSection };
