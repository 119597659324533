import { createSelector } from "reselect";
import { Section } from "@thenounproject/lingo-core";
import { useAppSelectorV1 } from "@redux/hooks";

import { getSectionId, getSections } from "../getters";

export const selectSection = createSelector(
  [getSections, getSectionId],
  (sections: Record<string, Section>, sectionId: string) => {
    return sections[sectionId] || null;
  }
);

export const useSelectSection = (sectionId?: string, version?: number) =>
  useAppSelectorV1(state => selectSection(state, { sectionId, version }));
