// MARK : Generators
/* -------------------------------------------------------------------------------*/

export type Period = "monthly" | "yearly";

export type Plan = {
  id: string;
  name: string;
  previous_plan?: string;
  monthly: {
    id: string;
    base_price: string;
    additional_users: string;
  };
  yearly: {
    id: string;
    base_price: string;
    additional_users: string;
  };
  storage: number;
  maxUsers: number;
  additional_users_title: string;
  features: Record<string, boolean | string | number>;
  uniqueFeatures: PlanFeature[];
};

export type PlanFeature = {
  name: string;
  description: string;
  enabled: boolean;
};

export type Feature = {
  id: string;
  name: string;
  description: string;
};

function feature(id: string, name: string, description: string): Feature {
  return { id, name, description };
}

const features = [
  feature("roles", "User roles and permissions", "Specify who on your team can/can’t contribute"),
  feature("team_access", "Share with users on your team", "Access for users on your team only"),
  feature("custom_exports", "Custom exports", "Change the dimensions of an asset before download"),
  feature("public_access", "Share with anyone", "Access for people not on your team via link/URL"),
  feature("versioning", "Versions and drafts", "The ability to version your libraries"),
  // Advanced themes is only use for unique features, it is filtered out below so it isn't included in the pricing table
  feature("custom_themes", "Custom branding", "Replace Lingo’s branding with your own"),
  feature("basic_themes", "Custom branding", "Replace Lingo’s logo and accent color with your own"),
  feature(
    "advanced_themes",
    "Advanced branding",
    "Replace Lingo's logo, accent color and fonts with your own. Also choose different themes to showcase your brand. "
  ),
  feature(
    "kit_collections",
    "Kit collections",
    "Group your kits together to further organize your Lingo space."
  ),
  feature("export_controls", "Export controls ", "Control export options for your assets"),
  feature("tag_manager", "Tag manager ", "Edit and manage all your tags from a single view"),
  feature("custom_fields", "Custom fields ", "Add custom metadata to your assets"),
  feature(
    "custom_views",
    "Custom views",
    "Create custom views of your assets so they're easier to manage and use"
  ),
  feature(
    "passwords",
    "Password protected links",
    "Allow only people with your password to access your kit"
  ),
  feature("api", "API access", "Access your content programmatically"),
  feature(
    "domains",
    "Custom domains",
    "Make your library easier to access with a custom subdomain"
  ),
  feature(
    "subdomains",
    "Custom subdomains",
    "Make your library easier to access with a custom subdomain"
  ),
  feature(
    "brand_setup",
    "Custom brand setup",
    "You send us your pdf style guide and assets and we'll build your brand hub for you."
  ),
  feature(
    "full_domains",
    "Custom domains",
    "Make your library feel more official with your own top level domain"
  ),
  feature(
    "custom_fonts",
    "Custom fonts",
    "Make your library feel more official by replacing Lingo's fonts with your own."
  ),
  feature("sso", "SAML SSO ", "Automate and simplify secure access to your brand assets."),
  feature(
    "portals",
    "Brand portals",
    "Allow people who are not members of your workspace to access multiple kits all from the same location"
  ),
  feature(
    "download_requests",
    "Download requests",
    "Make visitors to your kits provide their email before downloading an asset. Manually approve or deny individual download requests."
  ),
  feature(
    "insights",
    "Insights",
    "Measure the performance and impact of your assets with powerful analytics."
  ),
  feature(
    "support",
    "Priority support",
    "Our team will help you import assets, set up your workspace or help solve any other issue you come across."
  ),
];

function generateFeatures(planFeatures: string[]): PlanFeature[] {
  return planFeatures.map(id => {
    const f = features.find(_f => _f.id === id);
    return {
      name: f.name,
      description: f.description,
      enabled: true,
    };
  });
}

const plans: Plan[] = [
  {
    name: "Small Team",
    id: "small",
    monthly: {
      id: "tier-smt-month-30",
      base_price: "$30",
      additional_users: "Limited to 5 users",
    },
    yearly: {
      id: "tier-smt-year-300",
      base_price: "$300",
      additional_users: "Limited to 5 users",
    },
    storage: 50,
    maxUsers: 5,
    additional_users_title: "Additional users",
    features: { roles: true, team_access: true, custom_exports: true },
    uniqueFeatures: generateFeatures(["roles", "team_access", "custom_exports"]),
  },
  {
    name: "Standard",
    id: "standard",
    monthly: {
      id: "tier-std-month-60-6",
      base_price: "$60",
      additional_users: "$6 / month / user",
    },
    yearly: {
      id: "tier-std-year-600-60",
      base_price: "$600",
      additional_users: "$60 / year / user",
    },
    storage: 100,
    maxUsers: 50,
    additional_users_title: "Additional users (up to 50)",
    features: {
      roles: true,
      team_access: true,
      custom_exports: true,
      public_access: true,
      custom_themes: "Basic",
      versioning: true,
    },
    uniqueFeatures: generateFeatures([
      "basic_themes",
      "roles",
      "team_access",
      "public_access",
      "custom_exports",
      "versioning",
    ]),
  },
  {
    name: "Plus",
    id: "plus",
    previous_plan: "Standard",
    monthly: {
      id: "tier-pls-month-80-8",
      base_price: "$80",
      additional_users: "$8 / month / user",
    },
    yearly: {
      id: "tier-pls-year-800-80",
      base_price: "$800",
      additional_users: "$80 / year / user",
    },
    storage: 200,
    maxUsers: 50,
    additional_users_title: "Additional users (up to 50)",
    features: {
      roles: true,
      team_access: true,
      custom_exports: true,
      public_access: true,
      tag_manager: true,
      custom_fields: true,
      export_controls: true,
      versioning: true,
      kit_collections: true,
      custom_themes: "Advanced",
      domains: "Subdomains",
      api: true,
      custom_views: true,
      passwords: true,
    },
    uniqueFeatures: generateFeatures([
      "advanced_themes",
      "subdomains",
      "passwords",
      "kit_collections",
      "tag_manager",
      "custom_fields",
      "custom_views",
      "export_controls",
      "api",
    ]),
  },
  {
    name: "Enterprise",
    id: "enterprise",
    previous_plan: "Plus",
    monthly: {
      id: "tier-ent-month",
      base_price: "",
      additional_users: "",
    },
    yearly: {
      id: "tier-ent-year",
      base_price: "",
      additional_users: "",
    },
    storage: 0,
    maxUsers: 0,
    additional_users_title: "Additional users (up to 50)",
    features: {
      roles: true,
      team_access: true,
      custom_exports: true,
      public_access: true,
      tag_manager: true,
      custom_fields: true,
      export_controls: true,
      versioning: true,
      kit_collections: true,
      custom_themes: "Advanced",
      domains: "Custom",
      api: true,
      passwords: true,
      brand_setup: 1,
      custom_fonts: 1,
      download_requests: 1,
      portals: 1,
      support: 1,
      insights: 1,
      sso: 1,
      custom_views: true,
    },
    uniqueFeatures: generateFeatures([
      "brand_setup",
      "custom_fonts",
      "full_domains",
      "portals",
      "insights",
      "download_requests",
      "sso",
      "support",
    ]),
  },
];

const content = {
  title: "Pricing",
  subtitle: "Plans for every business—big or small.",

  comparisonTitle: "Compare Plans",

  faqTitle: "Frequently asked questions",
  faqSubtitle: "Pricing related questions",

  faqs: [
    {
      question: "Do non-contributors count toward the user tally?",
      answer:
        "Yes, all users joined to your Lingo team, regardless of role and access, count toward your user tally. This includes contributors and non-contributors.",
    },
    {
      question: "Do you offer discounts for non-profits or educational institutions?",
      answer: "No, we do not offer any discounts currently.",
    },
  ],
  // Used by comparison table
  features: features.filter(
    f => !["advanced_themes", "basic_themes", "subdomains", "full_domains"].includes(f.id)
  ),
  plans,
};

export default content;
