import React, { useCallback } from "react";
import { buildURL, Redirect, SpacePreview } from "@thenounproject/lingo-core";
import ConfirmIdentity from "./ConfirmIdentity";
import SpacePreviewTitle from "./SpacePreviewTitle";
import useRedeemJoinLink from "@redux/actions/auth/useRedeemJoinLink";
import { AuthToken } from "./useAuthState";
import useAuthHandler from "./useAuthHandler";
import useAcceptInvite from "@redux/actions/auth/useAcceptInvite";
import useCurrentUser from "@queries/useCurrentUser";

type Props = {
  // The token type must be "invitation" or "join-link"
  token: AuthToken;
  space: SpacePreview;
  responseHandler: ReturnType<typeof useAuthHandler>;
};

export default function RedeemJoinToken({ responseHandler, token, space }: Props) {
  // useRequireLogin can't be used here since it relies on nav points which Auth doesn't use
  const { user } = useCurrentUser();

  const [acceptInvite] = useAcceptInvite(),
    [redeemJoinLink] = useRedeemJoinLink(),
    submit = useCallback(() => {
      const act: typeof acceptInvite = {
        invitation: acceptInvite,
        "join-link": redeemJoinLink,
      }[token.type];
      void responseHandler.process(async () => await act({ token: token.token }));
    }, [acceptInvite, redeemJoinLink, responseHandler, token.token, token.type]);

  if (!user.id) {
    const next = window.location.toString();
    const context = (space.domain ?? space.subdomain) ? { space } : {};
    const signupUrl = buildURL("/signup", context, { next });
    return <Redirect to={signupUrl} />;
  }

  const { isProcessing, error } = responseHandler;

  const header = <SpacePreviewTitle titlePrefix="Confirm identity to join" space={space} />;
  return (
    <ConfirmIdentity
      header={header}
      buttonText={isProcessing ? "Joining space" : "Continue with this account"}
      user={user}
      disabled={isProcessing}
      notice={error ? { message: error.message, noticeStyle: "error" } : null}
      onConfirmSelected={submit}
    />
  );
}
