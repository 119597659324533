import React, { useEffect } from "react";
import { Button, PopupMenu, useClipboard, Asset, useBoolean } from "@thenounproject/lingo-core";
import useTrackColorUsage from "@redux/actions/analytics/trackColorUsage";
import { Inspectable } from "@constants/Inspector";

type Props = {
  inspectable: Inspectable;
};

const InspectorColorCopyButton = ({ inspectable }: Props) => {
  const [menuIsOpen, openMenu, closeMenu] = useBoolean(false);
  const [itemCopyData, setItemCopyData] = React.useState<string>(null);
  const trackColorUsage = useTrackColorUsage();
  const { onCopy } = useClipboard(itemCopyData);

  useEffect(() => {
    if (itemCopyData) {
      if (inspectable.item) {
        trackColorUsage({ item: inspectable.item, format: itemCopyData });
      }
      onCopy();
    }
  }, [onCopy, itemCopyData, inspectable.item, trackColorUsage]);

  function renderMenu(asset: Asset) {
    const { hex, rgb, hsb } = asset.colors[0];

    function renderMenuItem(value: string) {
      return (
        <PopupMenu.Item
          title={value}
          bordered={false}
          onClick={() => {
            setItemCopyData(value);
          }}
        />
      );
    }

    return (
      <PopupMenu source={asset.id} close={closeMenu}>
        <PopupMenu.Section title="Copy as...">
          {renderMenuItem(hex)}
          {renderMenuItem(rgb)}
          {renderMenuItem(hsb)}
        </PopupMenu.Section>
      </PopupMenu>
    );
  }
  return (
    <>
      <Button
        data-popup-source={inspectable.asset.id}
        text="Copy color"
        size="small"
        icon="navigation.chevron-down"
        fullWidth={true}
        px="10"
        onClick={openMenu}
      />
      {menuIsOpen && renderMenu(inspectable.asset)}
    </>
  );
};

export default InspectorColorCopyButton;
