import { API, APIResultList } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  invitations: number[];
  spaceId: number;
};

const [useDeleteInvitations, deleteInvitations] = createAsyncAction(
  "invitations/delete",
  async ({ spaceId, invitations }: Args) => {
    return await API.call<APIResultList<"invitations", string>>({
      endpoint: `spaces/${spaceId}/invite`,
      method: "DELETE",
      entity: API.Entity.invitation,
      data: {
        invitations: invitations.map(id => ({ invitation_id: id })),
      },
    });
  }
);
export default useDeleteInvitations;
export { deleteInvitations };
