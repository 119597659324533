import React from "react";
import styled from "styled-components";
import { Flex, Asset, ImageView } from "@thenounproject/lingo-core";

const ThumbnailWrapper = styled(Flex).attrs({
  height: "36px",
  width: "36px",
  padding: "xs",
  background: "grayLighter",
  borderRadius: "default",
  justifyContent: "center",
  alignItems: "center",
  mr: "8px",
  flexShrink: 0,
})`
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
type Props = { asset: Asset };

const AssetDownloadOptionThumbnail: React.FC<Props> = ({ asset }) => {
  return (
    <ThumbnailWrapper>
      <ImageView
        width={36}
        height={36}
        src={asset?.thumbnails?.[292]}
        background={"grayLighter"}
        borderRadius="default"
      />
    </ThumbnailWrapper>
  );
};

export default AssetDownloadOptionThumbnail;
