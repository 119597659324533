import { API, PortalItem } from "@thenounproject/lingo-core";
import { createQueryAction } from "../actionCreators";

type Args = {
  portalItemId: string | number;
  assetToken?: string;
};

const [usePortalItem, fetchPortalItem] = createQueryAction<Args, PortalItem, string>(
  {
    entity: "portalItems",
    action: "fetch",
    denormalize: {
      entity: null,
      portalItems: {
        entity: "portalItems",
        asset: "assets",
      },
    },
    condition: ({ args }) => Boolean(args.portalItemId),
  },
  async ({ args }) => {
    return await API.call<string>({
      endpoint: `portal_items/${args.portalItemId}`,
      method: "GET",
      entity: API.Entity.portalItem,
      query: {
        asset_token: args.assetToken,
      },
    });
  }
);

export default usePortalItem;
export { fetchPortalItem };
