import React, { useMemo } from "react";
import { useInView } from "react-intersection-observer";
import styled, { useTheme } from "styled-components";
import { Flex, ImageView, ActivityIndicator, AssetType, Icon } from "@thenounproject/lingo-core";

import SquareWrapper from "./SquareWrapper";
import { Inspectable } from "@constants/Inspector";
import getAssetBackground from "@helpers/getAssetBackground";
import { getAssetError } from "@helpers/getAssetError";
import { getAssetIcon } from "@helpers/getAssetIcon";

const BadgeContainer = styled(Flex).attrs({
  background: "white",
  height: "16px",
  width: "20px",
  position: "absolute",
  borderRadius: "default",
  justifyContent: "center",
  alignItems: "center",
})`
  z-index: 99;
  top: 4px;
  left: 4px;
  box-shadow: 0px 0px 1px rgba(42, 42, 42, 0.25);
  pointer-events: none;
  overflow: hidden;
`;

function ImageAssetSquare({ inspectable: { asset } }: { inspectable: Inspectable }) {
  const { ref, inView } = useInView({ triggerOnce: true });
  const assetError = getAssetError(asset);
  const assetIcon = getAssetIcon(asset);
  const theme = useTheme();

  const backgroundColor = useMemo(() => {
    return getAssetBackground(asset, theme);
  }, [asset, theme]);

  function renderBadge() {
    const isSketch = AssetType.sketchTypes.has(asset.type);
    const isMotion = AssetType.motionTypes.has(asset.type);
    if (!isSketch && !isMotion) {
      return null;
    }

    const iconId = isSketch ? "info.sketch" : "action.play";
    const width = 12;
    return (
      <BadgeContainer>
        <Icon {...{ width, iconId }} />
      </BadgeContainer>
    );
  }

  function renderThumbnail() {
    if (asset.meta.assetProcessing === "error" || assetError) {
      return (
        <ImageView width="100%" height="100%" icon={"info.error"} iconSize={32} iconFill="error" />
      );
    }

    if (inView && asset.meta.assetProcessing === "complete") {
      return (
        <ImageView
          width="100%"
          height="100%"
          src={asset.thumbnails[292]}
          icon={assetIcon}
          iconSize={32}
        />
      );
    }

    return <ActivityIndicator size="small" color="gray" center />;
  }

  return (
    <SquareWrapper ref={ref} {...backgroundColor}>
      <Flex
        height="100%"
        width="100%"
        justifyContent="center"
        alignItems="center"
        borderRadius="default"
        overflow="hidden">
        {renderBadge()}
        {renderThumbnail()}
      </Flex>
    </SquareWrapper>
  );
}

export default React.memo(ImageAssetSquare);
