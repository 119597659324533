import { AnyObject, API, SpaceThemeNames } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

export type Font = {
  system: { family: string; source: string };
};

type ThemeUpdates = {
  active?: boolean;
  description?: string;
  primaryColor?: string;
  fonts?: Font;
  themeName?: SpaceThemeNames;
  headerBackgroundColor?: string;
  headerTitleStyle?: string;
  headerTitleColor?: string;
  headerDescriptionColor?: string;
  headerBackgroundImage?: undefined | null | File;
  headerLogoImage?: undefined | null | File;
  noteSuccessColor?: string;
  noteInfoColor?: string;
  noteWarningColor?: string;
  assetLightColor?: string;
  assetDarkColor?: string;
};

type Args = {
  spaceId: number;
  updates: ThemeUpdates;
};

const [useUpdateSpaceTheme, updateSpaceTheme] = createAsyncAction(
  "spaces/updateSpaceTheme",
  async ({ spaceId, updates }: Args) => {
    const theme = {
      active: updates.active,
      description: updates.description,
      header_background_color: updates.headerBackgroundColor,
      header_title_style: updates.headerTitleStyle,
      header_title_color: updates.headerTitleColor,
      header_description_color: updates.headerDescriptionColor,
      note_success_color: updates.noteSuccessColor,
      note_info_color: updates.noteInfoColor,
      note_warning_color: updates.noteWarningColor,
      primary_color: updates.primaryColor,
      theme_name: updates.themeName,
      fonts: updates.fonts,
    };

    const images = {
      header_background_image: updates.headerBackgroundImage,
      header_logo_image: updates.headerLogoImage,
    };

    let formData: FormData;
    let data: AnyObject;

    Object.entries(images).forEach(([key, value]) => {
      if (value === null) {
        theme[key] = null;
      } else if (value) {
        if (!formData) formData = new FormData();
        formData.append(key, value);
      }
    });

    if (formData) {
      formData.append("json", JSON.stringify({ theme }));
      data = undefined;
    } else {
      data = { theme };
    }
    return await API.call<number>({
      endpoint: `spaces/${spaceId}`,
      method: "PUT",
      entity: API.Entity.space,
      data,
      formData,
    });
  }
);

export default useUpdateSpaceTheme;
export { updateSpaceTheme };
