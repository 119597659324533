import _sortBy from "lodash/sortBy";
import { API, AssetView, Space } from "@thenounproject/lingo-core";
import { createQueryAction } from "../actionCreators";

interface Args {
  spaceId: Space["id"];
}

const [useAssetViews, fetchAssetViews] = createQueryAction<Args, AssetView[], string[]>(
  {
    entity: "views",
    action: "query",
    condition: ({ args }) => Boolean(args.spaceId),
    prepareData: views => _sortBy(views, v => v.name.toLowerCase()),
  },
  async ({ args }) => {
    const res = await API.call<{ views: string[] }>({
      endpoint: `spaces/${args.spaceId}/views`,
      method: "GET",
      entity: API.Entity.assetView,
    });
    return { result: res.result.views, entities: res.entities };
  }
);
export default useAssetViews;
export { fetchAssetViews };
