import { API, Item } from "@thenounproject/lingo-core";
import { createQueryAction } from "../actionCreators";
import { DEFAULT_PAGE_SIZE } from "../actionCreators/createQueryAction";

type Args = {
  itemId: string;
  version?: number;
};

type Result = { items: Item[]; total: number };
type NormalizedResult = { items: string[]; total: number };
const [useGalleryItems, fetchGalleryItems] = createQueryAction<Args, Result, NormalizedResult>(
  {
    entity: "items",
    action: "queryGallery",
    denormalize: {
      entity: null,
      items: {
        entity: "items",
        asset: "assets",
      },
    },
    pagingKey: "items",
    condition: ({ args }) => Boolean(args.itemId),
  },
  async ({ args: { itemId, version = 0 }, paging }) => {
    const res = await API.call<string>({
      endpoint: `items/${itemId}`,
      method: "GET",
      entity: API.Entity.item,
      query: {
        limit: DEFAULT_PAGE_SIZE,
        v: version,
        page: paging.page,
        include: "items",
      },
    });
    const item = res.entities.items[res.result];
    const items = item.items;
    return { result: { items, total: item.data.assets }, entities: res.entities };
  }
);

export default useGalleryItems;
export { fetchGalleryItems };
