import React, { ComponentProps } from "react";
import styled from "styled-components";
import {
  Text,
  Flex,
  PopupMenu,
  buildPath,
  Icon,
  Box,
  Space,
  Kit,
  KitCollection,
  Callback,
  KitCollectionItem,
  useNavigation,
} from "@thenounproject/lingo-core";

import KitCollectionMenuItems from "./KitCollectionMenuItems";

import KitList from "./KitList";

const Overflow = styled.button`
  padding: 0;
  line-height: 0;
`;

const KitListWrapper = styled(Box).attrs({
  width: "100%",
  p: "xs",
})`
  aspect-ratio: 16/10;
`;

const Container = styled(Flex).attrs({
  width: "100%",
  flexDirection: "column",
  border: "default",
  borderRadius: "large",
})`
  &:hover {
    cursor: pointer;
  }
`;

const DetailsContainer = styled(Flex).attrs(() => {
  return {
    p: "m",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  };
})``;

const KitsContainer = styled(Flex).attrs({ width: "100%" })``;

const EmptyCollectionContainer = styled(Flex).attrs({
  width: "100%",
  textAlign: "center",
  alignItems: "center",
  flexDirection: "column",
  borderRadius: "medium",
  background: "grayLightest",
  justifyContent: "center",
  px: "s",
  m: "xs",
})`
  aspect-ratio: 16/10;
`;

type Props = {
  collection: Omit<KitCollection, "items"> & { items: KitCollectionItem[] };
  popupProps: ComponentProps<typeof PopupMenu>;
  setMenuOpen: Callback;
  menuOpen: boolean;
  navigate: ReturnType<typeof useNavigation>;
  space?: Space;
};

const KitCollectionCardDockweiler: React.FC<Props> = ({
  collection,
  space,
  popupProps,
  menuOpen,
  setMenuOpen,
  navigate,
}) => {
  const kits = collection.items.slice(0, 4).map(item => item.kit as Kit);

  function renderKitsElements() {
    if (kits.length === 0) {
      return (
        <EmptyCollectionContainer>
          <Box width="100%" maxWidth="400px" m="auto">
            <Text font="ui.regularBold" color="grayDarker" mb="xs">
              Add Kits
            </Text>
            <Text color="grayDarker">Open this collection to add Kits.</Text>
          </Box>
        </EmptyCollectionContainer>
      );
    }

    return (
      <KitListWrapper>
        <KitList
          kits={kits}
          kitCardSize="compact"
          identifier={collection.uuid}
          showKitDetails={false}
          gap={kits.length === 1 ? "0" : "8px"}
          columns={kits.length === 1 ? 1 : 2}
          maxRows={kits.length === 1 ? 1 : 2}
        />
      </KitListWrapper>
    );
  }

  return (
    <Container
      p={0}
      onClick={() => {
        navigate.push(buildPath(collection.url, { space }));
      }}>
      <KitsContainer>{renderKitsElements()}</KitsContainer>
      <DetailsContainer maxWidth="100%">
        <Flex width="100%" justifyContent="space-between" alignItems="center">
          <Text font="ui.mediumSubtitle" truncate>
            {collection.name}
          </Text>
          <Flex>
            <Text font="ui.subtitle" color="grayDarkest" mr="s" flexShrink="0">
              {collection.counts.kits || 0} Kits
            </Text>
            <Overflow
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                setMenuOpen();
              }}
              data-popup-source={collection.uuid}>
              <Icon iconId="navigation.overflow" size="24" />
            </Overflow>
          </Flex>
          {menuOpen && (
            <PopupMenu {...popupProps}>
              <KitCollectionMenuItems kitCollectionId={collection.uuid} />
            </PopupMenu>
          )}
        </Flex>
      </DetailsContainer>
    </Container>
  );
};

export default KitCollectionCardDockweiler;
