export default {
  backspace: "Backspace",
  tab: "Tab",
  enter: "Enter",
  escape: "Escape",
  delete: "Delete",
  shift: "Shift",
  meta: "Meta",
  alt: "Alt",
  control: "Control",
  comma: ",",
  space: " ",
  forwardSlash: "/",
  leftArrow: "ArrowLeft",
  rightArrow: "ArrowRight",
  upArrow: "ArrowUp",
  downArrow: "ArrowDown",
};
