import _merge from "lodash/merge";
import _remove from "lodash/remove";

import { APIResponse, Asset } from "@thenounproject/lingo-core";

import createEntityReducer from "../helpers/createEntityReducer";

import { removeAssetThumbnail } from "@redux/actions/assets/useRemoveAssetThumbnail";
import { deleteAssetItems } from "@redux/actions/assets/useDeleteAssetItems";
import { deleteAssets } from "@redux/actions/assets/useDeleteAssets";
import { assetCustomFieldUpdate } from "@redux/actions/assets/useAssetsCustomFieldUpdate";
import { updateAssetThumbnail } from "@redux/actions/assets/useUpdateAssetThumbnail";
import { saveAssetMetadata } from "@redux/actions/assets/useSaveAssetMetadata";
import { batchUpdateItemStatus } from "@redux/actions/items/useBatchUpdateItemStatus";
import { getVersionedItemId } from "@helpers/items";
import { fetchLibraryAssets } from "@redux/actions/search/useLibraryAssets";
import { getEntities } from "../helpers/getEntities";

const getAssetsFromResponse = (action): APIResponse["entities"]["assets"] => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return getEntities(action)?.assets;
};

export default createEntityReducer<Asset>(
  "assets",
  queryBuilder => {
    queryBuilder.addCase(deleteAssets.fulfilled, (state, action) => {
      const { result, entities } = action.payload;
      const deletedIds = result.assets.filter(r => r.success).map(r => r.result);
      if (!deletedIds.length) return;
      const { spaceId } = entities.assets[deletedIds[0]];
      const idSet = new Set(deletedIds);

      fetchLibraryAssets.getQueryData(state, { spaceId }).forEach(q => {
        const removed = _remove(q.data.assets, a => idSet.has(a));
        q.data.total -= removed.length;
      });
    });
  },
  objectBuilder => {
    objectBuilder
      .addCase(deleteAssets.fulfilled, (state, action) => {
        const { assetIds } = action.meta.arg;
        assetIds.forEach(id => {
          delete state[id];
        });
        return state;
      })
      .addCase(batchUpdateItemStatus.fulfilled, (state, action) => {
        const { entities, result } = action.payload;
        const successfulItems = result.items
          .filter(i => i.success)
          .map(i => {
            const item = entities.items[i.result];
            return {
              itemId: getVersionedItemId(item),
              assetId: item.assetId,
            };
          })
          .filter(i => i.assetId);

        const remove = ["trashed", "deleted"].includes(action.meta.arg.status);
        successfulItems.forEach(item => {
          const items = state[item.assetId].items as string[];
          if (!items) return;
          if (remove) {
            _remove(items, i => i === item.itemId);
          } else if (!items.includes(item.itemId)) {
            items.push(item.itemId);
          }
        });
      })
      .addCase(deleteAssetItems.fulfilled, (state, action) => {
        const { assetIds } = action.meta.arg;
        assetIds.forEach(uuid => {
          state[uuid].items = undefined;
        });
      })
      .addCase(assetCustomFieldUpdate.fulfilled, (state, action) => {
        const assets = getAssetsFromResponse(action);
        action.payload.result.assets.forEach(asset => {
          if (asset.result) {
            state[asset.result].fields = assets[asset.result].fields;
          }
        });
      })
      .addCase(saveAssetMetadata.fulfilled, (state, action) => {
        const assetId = action.meta.arg.assetId;
        if (state[assetId]) {
          state[assetId] = action.payload.entities.assets[assetId];
        }
      })
      .addCase(updateAssetThumbnail.pending, (state, action) => {
        const assetId = action.meta.arg.assetId;
        if (state[assetId]) {
          state[assetId].meta.assetProcessing = "processing";
        }
      })
      .addCase(updateAssetThumbnail.fulfilled, (state, action) => {
        const assetId = action.meta.arg.assetId;
        if (state[assetId]) {
          state[assetId].meta.assetProcessing = "complete";
          state[assetId].meta.preview = action.payload.entities.assets[assetId].meta.preview;
          state[assetId].thumbnails = action.payload.entities.assets[assetId].thumbnails;
        }
      })
      .addCase(updateAssetThumbnail.rejected, (state, action) => {
        const assetId = action.meta.arg.assetId;
        if (state[assetId]) {
          state[assetId].meta.assetProcessing = "complete";
          state[assetId].meta.preview = undefined;
          // state[assetId].thumbnails = action.payload.entities.assets[assetId].thumbnails;
        }
      })
      .addCase(removeAssetThumbnail.fulfilled, (state, action) => {
        const { assetId } = action.meta.arg;
        if (state[assetId]) {
          state[assetId].meta.preview = undefined;
          state[assetId].thumbnails = action.payload.entities.assets[assetId].thumbnails;
        }
      })
      .addDefaultCase((state, action) => {
        const assets = getAssetsFromResponse(action);
        if (assets) {
          _merge(state, assets);
        }
      });
  }
);
