import React, {
  useState,
  useCallback,
  useEffect,
  Fragment,
  KeyboardEventHandler,
  FormEvent,
  useRef,
} from "react";
import styled from "styled-components";
import TextareaAutosize from "react-autosize-textarea";
import {
  AssetType,
  Box,
  Text,
  styles,
  utils,
  Flex,
  Button,
  useBoolean,
  useClipboard,
} from "@thenounproject/lingo-core";

import bytesToSize from "@helpers/bytesToSize";
import AccordionSection from "../AccordionSection";
import { Inspectable } from "@constants/Inspector";
import useSaveAssetMetadata from "@redux/actions/assets/useSaveAssetMetadata";
import InspectorAssetDownload from "./use/InspectorAssetDownload";
import InspectorColorCopyButton from "./use/InspectorColorCopyButton";
import GalleryContextMenu from "@features/context-menus/GalleryContextMenu";

type InlineInputProps = {
  font?: string;
};

const InlineInput = styled(TextareaAutosize)<InlineInputProps>`
  max-height: initial;
  min-height: initial;
  width: 100%;
  ${styles.padding.y.xs};
  ${props => utils.getFont(props.font, "ui.regular", props)}
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  resize: none;
`;

type InputContainerProps = {
  canEdit: boolean;
  isEditing: boolean;
};

const InputContainer = styled(Box).attrs<InputContainerProps>(props => {
  return {
    mx: "-xs",
    px: "xs",
    borderRadius: "default",
    background: props.canEdit && props.isEditing ? "grayLighter" : null,
    variations: props.canEdit
      ? {
          "&:hover": {
            background: "grayLighter",
          },
        }
      : null,
    style: {
      transition: "background 0.3s ease, min-height 0.3s ease",
    },
  };
})<InputContainerProps>``;

export type Props = {
  inspectable: Inspectable;
  canEdit: boolean;
  shouldShowCollapseButton: boolean;
};

export default function InspectorAssetOverview({ inspectable, canEdit }: Props) {
  const [isEditingName, setIsEditingName] = useState(false),
    [isEditingNotes, setIsEditingNotes] = useState(false),
    [name, setName] = useState(inspectable.asset.name || ""),
    [notes, setNotes] = useState(inspectable.asset.notes || ""),
    [expanded, setExpanded] = useState(false),
    [actionsMenuOpen, setMenuOpen, setMenuClosed] = useBoolean(false),
    [saveAssetMetadata] = useSaveAssetMetadata();

  const ref = useRef<HTMLTextAreaElement>(null);
  const { onCopy, hasCopied } = useClipboard(inspectable.asset?.meta?.content?.url || null);

  // 72 is the approximate height of three lines of text
  const shouldBeExpandable = ref?.current?.scrollHeight >= 72;

  useEffect(() => {
    setExpanded(false);
  }, [inspectable.asset.id]);

  useEffect(() => {
    setName(inspectable.asset.name);
    setNotes(inspectable.asset.notes);
  }, [inspectable.asset.name, inspectable.asset.notes]);

  const saveChanges = useCallback(async () => {
    setIsEditingName(false);
    setIsEditingNotes(false);

    const changes: { name?: string; notes?: string } = {};
    const cName = inspectable.asset.name || "";
    const cNotes = inspectable.asset.notes || "";
    if (cName !== name.trim()) changes.name = name.trim();
    if (cNotes !== notes.trim()) changes.notes = notes.trim();

    if (Object.keys(changes).length === 0) return;

    // No need to handle the result. An error notification is shown in the action itself
    // and we don't need a success state
    const result = await saveAssetMetadata({ assetId: inspectable.asset.id, data: changes });
    if (result.error) {
      setName(inspectable.asset.name);
      setNotes(inspectable.asset.notes);
    }
  }, [
    inspectable.asset.id,
    inspectable.asset.name,
    inspectable.asset.notes,
    name,
    notes,
    saveAssetMetadata,
  ]);

  const nameOnKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = useCallback(e => {
    // No newline allowed in name field
    if (e.key === "Enter") {
      e.preventDefault();
      e.currentTarget.blur();
    }
  }, []);

  const notesOnKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = e => {
    // Allow newlines in notes but submit on shift/cmd enter
    if ((e.shiftKey || e.metaKey) && e.key === "Enter") {
      e.preventDefault();
      e.currentTarget.blur();
    }
  };

  // MARK : Rendering
  // -------------------------------------------------------------------------------

  // Renders line below name with immutable metadata (type, size, etc)
  function renderAssetDetails() {
    function renderFileSize() {
      const noSize = [AssetType.color, AssetType.textStyle, AssetType.URL];
      return noSize.includes(inspectable.asset.type) ? null : (
        <span> &bull; {bytesToSize(inspectable.asset.size)}</span>
      );
    }

    function renderDimensions() {
      const noDimensions = [
        AssetType.color,
        AssetType.sketchLayerStyle,
        AssetType.sketchTextStyle,
        AssetType.textStyle,
        AssetType.URL,
        ...AssetType.genericTypes,
        ...AssetType.audioTypes,
      ];
      return noDimensions.includes(inspectable.asset.type) ? null : (
        <span> &bull; {inspectable.asset.dimensions}</span>
      );
    }

    function renderDuration() {
      const { duration } = inspectable.asset.meta,
        getDurationString = () => {
          const hours = Math.floor(duration / 3600),
            minutes = (Math.floor(duration / 60) % 60).toString().padStart(2, "0"),
            seconds = (Math.floor(duration) % 60).toString().padStart(2, "0"),
            displayString = `${minutes}:${seconds}`;

          return hours ? `${hours}:${displayString}` : displayString;
        };

      return [...AssetType.videoTypes, ...AssetType.audioTypes].includes(inspectable.asset.type) ? (
        <span> &bull; {getDurationString()}</span>
      ) : null;
    }

    return (
      <Fragment>
        <strong key="asset-type">{AssetType.displayName(inspectable.asset.type)}</strong>
        {renderFileSize()}
        {renderDuration()}
        {renderDimensions()}
      </Fragment>
    );
  }

  const defaultName =
    inspectable.asset.type === AssetType.color ? inspectable.asset.colors[0].name : "Unnamed Asset";

  function renderActionButton() {
    switch (inspectable.asset.type) {
      case AssetType.color:
        return <InspectorColorCopyButton inspectable={inspectable} />;
      case AssetType.URL:
        return (
          <Button
            size="small"
            text={hasCopied ? "Copied" : "Copy link"}
            onClick={onCopy}
            width="100%"
          />
        );
      default:
        return (
          <InspectorAssetDownload inspectable={inspectable} canEdit={canEdit} mode={"detailed"} />
        );
    }
  }

  return (
    <AccordionSection expanded disableControls styleOverrides={{ borderBottom: "default" }}>
      <Flex justifyContent="space-between" mr="l">
        <InputContainer
          canEdit={canEdit}
          isEditing={isEditingName}
          data-testid="inspector-asset-overview"
          width="100%">
          <InlineInput
            readOnly={!canEdit}
            onChange={(e: FormEvent<HTMLTextAreaElement>) => setName(e.currentTarget.value)}
            value={canEdit ? name : name || defaultName}
            data-testid="asset-name-input"
            placeholder={defaultName}
            onFocus={() => setIsEditingName(true)}
            onBlur={saveChanges}
            onKeyDown={nameOnKeyDown}
            rows={1}
            font="ui.regularBold"
          />
        </InputContainer>
      </Flex>
      <Text font="ui.small" color="grayDarker" mb="s" data-testid="asset-details">
        {renderAssetDetails()}
      </Text>
      {notes.length || canEdit ? (
        <>
          <InputContainer
            canEdit={canEdit}
            isEditing={isEditingNotes}
            minHeight={canEdit && isEditingNotes ? "72px" : 0}>
            <InlineInput
              readOnly={!canEdit}
              onChange={(e: FormEvent<HTMLTextAreaElement>) => setNotes(e.currentTarget.value)}
              value={notes}
              onFocus={() => {
                setIsEditingNotes(true);
              }}
              onBlur={saveChanges}
              onKeyDown={notesOnKeyDown}
              data-testid="asset-notes-input"
              placeholder="Add a description"
              maxRows={expanded || isEditingNotes ? undefined : 3}
              ref={ref}
            />
          </InputContainer>
          {!isEditingNotes && shouldBeExpandable && (
            <Button
              text={expanded ? "Show less" : "Show more"}
              buttonStyle="tertiary"
              mt="s"
              onClick={() => setExpanded(!expanded)}
            />
          )}
        </>
      ) : null}
      <Flex mt="l" justifyContent="space-between">
        {renderActionButton()}
        <Button
          data-popup-source="asset-actions"
          text="More"
          size="small"
          buttonStyle="outline"
          icon="navigation.chevron-down"
          fontStyle="ui.small"
          width="100%"
          maxWidth="110px"
          ml="s"
          onClick={() => setMenuOpen()}
        />
        {actionsMenuOpen && (
          <GalleryContextMenu
            inspectables={[inspectable]}
            onCloseMenu={() => setMenuClosed()}
            canEdit={canEdit}
            popupSource="asset-actions"
            backgroundColorhPos="floatLeft"
          />
        )}
      </Flex>
    </AccordionSection>
  );
}
