import _merge from "lodash/merge";
import _cloneDeep from "lodash/cloneDeep";
import _get from "lodash/get";
import _set from "lodash/set";

import { AnyObject, APIResponse } from "@thenounproject/lingo-core";
import { isQueryAction, QueryData } from "@redux/actions/actionCreators/createQueryAction";

export function mergeQuery(
  state: Record<string, QueryData<unknown, unknown>>,
  action,
  entity: string
) {
  if (isQueryAction(action, entity)) {
    const meta = _cloneDeep(action.meta);
    switch (meta.requestStatus) {
      case "rejected": {
        // Don't update paging data if the request fails.
        delete meta.arg.paging;
        return _merge(state, {
          [meta.queryKey]: {
            ...meta,
            error: action.payload,
          },
        });
      }
      case "pending": {
        // Don't save paging info until the action succeeds.
        delete meta.arg.paging;
        return _merge(state, {
          [meta.queryKey]: {
            error: null,
            ...meta,
            dateFullfilled: Math.max(meta.dateFullfilled ?? 0, 0),
          },
        });
      }
      case "fulfilled": {
        let newData = (action.payload as APIResponse)?.result;

        const { page } = meta.arg.paging ?? {};
        if (page > 1) {
          if (meta.pagingKey) {
            const existingData = state[meta.queryKey]?.data as AnyObject;
            const existingArr = _get(existingData, meta.pagingKey, []) as unknown[];
            const newArr = _get(newData, meta.pagingKey, []) as unknown[];
            const mergedArr = existingArr.concat(newArr);
            // Merge in case there is any new non-result data (e.g. offset/paging data from the API)
            _merge(existingData, newData);
            // The set the results to make sure the data is set, not merged
            _set(existingData, meta.pagingKey, mergedArr);
            newData = existingData;
          } else if (Array.isArray(newData)) {
            const existingData = (state[meta.queryKey]?.data as AnyObject[]) || [];
            newData = existingData.concat(newData);
          }
        }
        _merge(state, {
          [meta.queryKey]: {
            ...meta,
            error: null,
            dateFullfilled: Date.now(),
          },
        });
        // Make sure data is set, not merged
        state[meta.queryKey].data = newData;
      }
    }
  }
}
