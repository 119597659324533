import { API, APIResultList } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  userIds: number[];
  spaceId: number;
};

const [useDeleteSpaceMembers, deleteSpaceMembers] = createAsyncAction(
  "spaceMembers/delete",
  async ({ spaceId, userIds }: Args) => {
    return await API.call<APIResultList<"members", string>>({
      endpoint: `spaces/${spaceId}/members`,
      method: "PUT",
      entity: API.Entity.spaceMember,
      data: {
        members: userIds.map(id => ({ user_id: id, status: "revoked" })),
      },
    });
  }
);
export default useDeleteSpaceMembers;
export { deleteSpaceMembers };
