import { ComponentProps } from "react";

import { Button } from "@thenounproject/lingo-core";

export const MarketingButtons: Record<string, ComponentProps<typeof Button>> = {
  // getStarted: { text: "Try Lingo for free", link: "/signup" },
  getStarted: { text: "Request a free trial", link: "/request-trial" },
  contactSales: { text: "Contact sales", link: "/contact-sales" },
  contact: { text: "Contact us ", link: "/contact" },
  bookDemo: { text: "Book a demo", link: "/book-demo" },
  requestQuote: { text: "Request a quote", link: "/request-quote" },
};
