import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "./actionCreators";
import QueryString from "query-string";
import { Inspectable } from "@constants/Inspector";

type UseGenerateFilecutVars = {
  inspectable: Inspectable;
  fileType: string;
  dimensions: string;
  dpi?: number;
  // Only admins can generate filecuts from the asset source.
  // Everyone else generates a download from an item source to get authorized.
  isAdmin: boolean;
  portalId?: string;
};

const [useGenerateFilecut, generateFilecut] = createAsyncAction(
  "assets/generateFilecut",
  async ({
    inspectable,
    fileType,
    dimensions,
    dpi,
    portalId,
    isAdmin = false,
  }: UseGenerateFilecutVars) => {
    const asset = inspectable.asset,
      { shareToken, id: assetId } = asset,
      itemId = inspectable.item?.id,
      version = inspectable.item?.version;

    const queryString = QueryString.stringify({
      type: fileType,
      dimensions,
      dpi,
      asset_token: shareToken,
      response: "json",
      version,
      portal_uuid: portalId,
    });

    const endpoint = isAdmin
      ? `assets/${assetId}/download?${queryString}`
      : `items/${itemId}/download?${queryString}`;

    const response = await API.call<{ url: string }>({
      endpoint,
      method: "GET",
    });

    return response;
  }
);

export default useGenerateFilecut;
export { generateFilecut };
