import React from "react";
import {
  PopupMenu,
  Input,
  Text,
  Flex,
  Box,
  useBoolean,
  TagSuggestion,
} from "@thenounproject/lingo-core";
import { useCombobox } from "downshift";
import { debounce } from "lodash";

import InspectorTagPill from "./InspectorTagPill";

import useTagSuggestions from "@redux/actions/tags/useTagSuggestions";
import { useSelectSpace } from "@selectors/entities/spaces";

export type Props = {
  onTagSelect: (tags: string[]) => void;
};

const InspectorAssetTagInput = ({ onTagSelect }: Props) => {
  const space = useSelectSpace();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [value, setValue] = React.useState("");
  const [query, setQuery] = React.useState("");

  const [dropdownOpen, openDropdown, closeDropdown] = useBoolean(false);

  const { data, error } = useTagSuggestions({ spaceId: space.id, value: query });
  const createButton = React.useMemo(() => {
    return { value };
  }, [value]);
  const { items, showCreateButton } = React.useMemo(() => {
    type Item = TagSuggestion;
    const items: Item[] = data ? [...data] : [];

    //Add the create button to the end of the array for useCombobox to work properly
    const queriedTagExists = data?.map(tag => tag?.value).includes(query);
    const showCreateButton = query?.trim() && !queriedTagExists;
    return { items, showCreateButton };
  }, [data, query]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setQueryDebounced = React.useCallback(debounce(setQuery, 250), []);
  React.useEffect(() => {
    setQueryDebounced(value);
  }, [value, setQueryDebounced]);

  function resetInput() {
    setValue("");
    closeDropdown();
    inputRef.current.blur();
  }

  const { getInputProps, getItemProps, getMenuProps, highlightedIndex, inputValue } = useCombobox({
    onSelectedItemChange({ selectedItem }) {
      if (selectedItem) {
        onTagSelect(selectedItem.value.split(",").map(i => i.trim()));
        resetInput();
      }
    },
    itemToString() {
      // Since we don't want to display the selected item in the input, we return an empty string
      return "";
    },
    items: [...items, createButton],
    inputValue: value,
    selectedItem: null,
    isOpen: dropdownOpen,
  });

  function renderCreateButton() {
    if (!showCreateButton) return null;
    const index = items.length;
    return (
      <PopupMenu.Section>
        <PopupMenu.Item
          isActive={highlightedIndex === index}
          key={`create-tag-${createButton.value}`}
          {...getItemProps({
            item: createButton,
            index,
          })}
          title={
            <Flex alignItems="center" justifyContent="center">
              <Text mr="s">Create</Text>
              <InspectorTagPill tag={inputValue} styleOverrides={{ mt: 0 }} />
            </Flex>
          }
        />
      </PopupMenu.Section>
    );
  }

  function renderSearchSuggestions() {
    return (
      <PopupMenu.Section>
        {items?.map((item, index) => (
          <PopupMenu.Item
            isActive={highlightedIndex === index}
            key={`item-${index}`}
            {...getItemProps({
              item,
              index,
            })}
            title={<InspectorTagPill tag={item?.value} styleOverrides={{ mt: 0 }} />}
          />
        ))}
      </PopupMenu.Section>
    );
  }

  function renderMostUsedSection() {
    if (!data || data?.length === 0) return null;
    return (
      <PopupMenu.Section title="Most used">
        {data?.map((item, index) => (
          <PopupMenu.Item
            isActive={highlightedIndex === index}
            key={`item-${index}`}
            {...getItemProps({
              item,
              index,
            })}
            title={<InspectorTagPill tag={item?.value} styleOverrides={{ mt: 0 }} />}
          />
        ))}
      </PopupMenu.Section>
    );
  }

  function inputOnKeyDown(event) {
    if (event.key === "Enter") {
      //If there is a highlighten item, select it, otherwise create a new tag
      if (items[highlightedIndex]) {
        onTagSelect(items[highlightedIndex].value.split(",").map(i => i.trim()));
        resetInput();
      } else if (value) {
        onTagSelect(value.split(",").map(i => i.trim()));
        setValue("");
      }
    }
  }

  const hasPopupContent = data?.length || showCreateButton;
  return (
    <>
      <Input
        id="tag-input"
        data-popup-source="tag-input"
        placeholder="Search for or create a tag"
        size="small"
        message={error?.message}
        inputStyle={error ? "error" : null}
        {...getInputProps({
          onKeyDown: inputOnKeyDown,
          ref: e => {
            inputRef.current = e;
          },
        })}
        onChange={event => setValue(event.target.value)}
        onFocus={() => openDropdown()}
        onBlur={() => closeDropdown()}
      />
      <Box display={dropdownOpen && hasPopupContent ? "block" : "none"}>
        <PopupMenu.MenuContainer position="absolute" {...getMenuProps()}>
          {dropdownOpen && (value ? renderSearchSuggestions() : renderMostUsedSection())}
          {renderCreateButton()}
        </PopupMenu.MenuContainer>
      </Box>
    </>
  );
};

export default InspectorAssetTagInput;
