import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  spaceId: number;
  email: string;
  userEstimate: string;
  features: string;
};

const [useRequestEnterpriseSubscription, requestEnterpriseSubscription] = createAsyncAction(
  "billing/requestEnterpriseSubscription",
  async ({ spaceId, email, userEstimate, features }: Args) => {
    await API.call({
      endpoint: "billing/enterprise-request/",
      method: "POST",
      data: {
        space_id: spaceId,
        user_estimate: userEstimate,
        email,
        features,
      },
    });
  }
);

export default useRequestEnterpriseSubscription;
export { requestEnterpriseSubscription };
