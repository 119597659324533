import { PageContent } from "../../pages/templates/PageLayout";

const content: PageContent = {
  style: "lightGradient",
  sections: [
    {
      type: "FormSection",
      title: "Take a look at Lingo",
      subtitle:
        "Secure an appointment with a Lingo rep to see how Lingo can work for your specific needs.",
      badge: "Book a demo",
      form: "demo",
      position: { mb: "none", pb: "200" },
    },
    { type: "CustomerLogosSection", position: { mt: "-xxl" } },
  ],
};

export default content;
