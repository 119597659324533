import { ItemType, AssetType } from "@thenounproject/lingo-core";

type VerticalSpacing = {
  mt: string;
  mb: string;
};

export function getGalleryHeadingSpacing(index: number): VerticalSpacing {
  let mt = "xl";
  const isFirstItem = index === 0;

  if (isFirstItem) {
    mt = "none";
  }

  return { mt, mb: "m" };
}

export function getGalleryNoteSpacing(prevItemType: ItemType, index: number): VerticalSpacing {
  let mt = "l";
  const isFirstItem = index === 0,
    isAfterNoteOrHeading = [ItemType.heading, ItemType.note].includes(prevItemType);

  if (isFirstItem || isAfterNoteOrHeading) {
    mt = "none";
  }

  return { mt, mb: "m" };
}

export function getGallerySupportSpacing(prevItemType: ItemType, index: number): VerticalSpacing {
  let mt = "l";
  const isFirstItem = index === 0,
    isAfterHeading = ItemType.heading === prevItemType;

  if (isFirstItem || isAfterHeading) {
    mt = "none";
  }

  return { mt, mb: "m" };
}

export function getGalleryCodeSpacing(prevItemType: ItemType, index: number): VerticalSpacing {
  let mt = "l";
  const isFirstItem = index === 0,
    isAfterHeading = [ItemType.heading].includes(prevItemType);

  if (isFirstItem || isAfterHeading) {
    mt = "none";
  }

  return { mt, mb: "m" };
}

export function getGalleryGuideSpacing(
  prevItemType: string,
  secondPreviousItemType: string,
  guidePosition: "left" | "right" | "full",
  index: number
): VerticalSpacing {
  let mt = "l";
  const isFirstItem = index === 0,
    isAfterHeading = ItemType.heading === prevItemType,
    isHalfGuideInSecondPosition = guidePosition === "right" && index === 1,
    isSecondHalfGuideAfterHeading =
      guidePosition === "right" && ItemType.heading === secondPreviousItemType;

  if (
    isFirstItem ||
    isAfterHeading ||
    isHalfGuideInSecondPosition ||
    isSecondHalfGuideAfterHeading
  ) {
    mt = "none";
  }

  return { mt, mb: "m" };
}

export function getGalleryFontSpacing(
  prevAssetType: string,
  prevItemType: ItemType,
  nextAssetType: string,
  index: number
): VerticalSpacing {
  let mt = "l",
    mb = "m";

  const isAfterHeadingOrNote = [ItemType.heading, ItemType.note].includes(prevItemType);
  const isAfterFont = prevAssetType === AssetType.textStyle;
  const isBeforeFont = nextAssetType === AssetType.textStyle;
  const isFirstItem = index === 0;

  if (isAfterHeadingOrNote || isAfterFont || isFirstItem) {
    mt = "none";
  }

  if (isBeforeFont) {
    mb = "4px";
  }

  return { mt, mb };
}

export function getGalleryGenericAssetSpacing(
  prevAssetType: string,
  prevItemType: ItemType,
  nextAssetType: string,
  index: number
): VerticalSpacing {
  let mt = "l",
    mb = "m";

  const isAfterHeadingOrNote = [ItemType.heading, ItemType.note].includes(prevItemType);
  const isAfterGeneric = AssetType.genericTypes.has(prevAssetType);
  const isBeforeGeneric = AssetType.genericTypes.has(nextAssetType);
  const isAfterAudio = AssetType.audioTypes.has(prevAssetType);
  const isBeforeAudio = AssetType.audioTypes.has(nextAssetType);
  const isFirstItem = index === 0;

  if (isAfterHeadingOrNote || isAfterGeneric || isAfterAudio || isFirstItem) {
    mt = "none";
  }

  if (isBeforeGeneric || isBeforeAudio) {
    mb = "4px";
  }

  return { mt, mb };
}

export function getGalleryAssetSpacing({
  isFullWidthAsset,
  prevAssetIsFullWidth,
  hiddenMode,
  isFirstItem,
}: {
  isFullWidthAsset: boolean;
  prevAssetIsFullWidth: boolean;
  hiddenMode: boolean;
  isFirstItem: boolean;
}): VerticalSpacing {
  let mt = "l",
    mb = "m";

  if ((isFullWidthAsset && prevAssetIsFullWidth) || (isFirstItem && isFullWidthAsset)) mt = "none";
  if (!isFullWidthAsset && hiddenMode) mb = "l";

  return { mt, mb };
}

export function getGalleryItemSpacing(prevItemType: ItemType, index: number): VerticalSpacing {
  let mt = "l";
  const isFirstItem = index === 0,
    isAfterHeading = ItemType.heading === prevItemType;

  if (isFirstItem || isAfterHeading) {
    mt = "none";
  }

  return { mt, mb: "m" };
}

/**
 * Returns an array of grouped asset IDs which should have no margin
 */
export const getGalleryAssetMarginOutline = (
  items: { id: string; type: ItemType }[],
  assetsPerRow: number
): string[] => {
  const nonGroupedAssetTypes = [
    ...AssetType.genericTypes,
    ...AssetType.audioTypes,
    ...AssetType.textTypes,
    ...AssetType.graphicTypes,
    AssetType.textStyle,
  ];

  /**
   * Generate an array of grouped items along with the starting index of each one
   */
  const assetGroups = [];
  let currentGroup = null;
  const isGroupedAsset = (item: { type: string; asset?: any }): boolean =>
    item.type === ItemType.asset && !nonGroupedAssetTypes.includes(item.asset.type);

  items.forEach((item, index) => {
    if (isGroupedAsset(item)) {
      if (!currentGroup) {
        currentGroup = { startingIndex: index, items: [item.id] };
      } else currentGroup.items.push(item.id);
    } else {
      if (currentGroup) assetGroups.push(currentGroup);
      currentGroup = null;
    }
  });
  if (currentGroup) assetGroups.push(currentGroup);

  /**
   * Based on assetsPerRow, determine the assets which should have no top margin
   */
  const assetsWithNoTopMargin = [];

  if (assetGroups.length) {
    const isAfterHeadingOrNote = (startingIndex: number): boolean =>
      [ItemType.heading, ItemType.note].includes(items[startingIndex - 1]?.type);

    assetGroups.forEach(group => {
      if (isAfterHeadingOrNote(group.startingIndex) || group.startingIndex === 0) {
        group.items.forEach((id: string) => assetsWithNoTopMargin.push(id));
      } else if (group.items.length > assetsPerRow) {
        const itemsAfterTopRow = group.items.slice(assetsPerRow);
        itemsAfterTopRow.forEach((id: string) => assetsWithNoTopMargin.push(id));
      }
    });
  }

  return assetsWithNoTopMargin;
};
