import { AnyObject, API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = { token: string; queryString?: string };

const [useLoginWithGoogle, loginWithGoogle] = createAsyncAction(
  "auth/loginWithGoogle",
  async (args: Args) => {
    const endpoint = ["auth/google/", args.queryString].filter(Boolean).join("");
    return await API.call<{
      user: number;
      intercom_id?: string;
      notifications?: AnyObject[];
    }>({
      endpoint,
      method: "POST",
      data: {
        token: args.token,
      },
      entity: API.Entity.user,
    });
  }
);
export default useLoginWithGoogle;
export { loginWithGoogle };
