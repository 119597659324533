import { useCallback } from "react";
import { useAppDispatchV1 } from "@redux/hooks";
import * as actionTypes from "@redux/legacy-actions/actionTypes";

export enum DragEntities {
  GALLERY_ITEM = "GALLERY_ITEM",
  KIT_NAV_SECTION = "KIT_NAV_SECTION",
  KIT_NAV_HEADING = "KIT_NAV_HEADING",
  PORTAL_ITEM = "PORTAL_ITEM",
}

interface Args {
  entity: DragEntities | undefined;
}
const setDraggingEntity = ({ entity }: Args) => {
  return {
    type: actionTypes.SET_DRAGGING_ENTITY,
    entity,
  };
};

const useSetDraggingEntity = () => {
  const dispatch = useAppDispatchV1();
  return useCallback(
    (args: Args) => {
      return dispatch(setDraggingEntity(args));
    },
    [dispatch]
  );
};

export default useSetDraggingEntity;
