import React, { useCallback } from "react";
import {
  Checkbox,
  DropdownButton,
  PopupMenu,
  useBoolean,
  Box,
  CustomField,
  Text,
  Flex,
  CustomFieldOperation,
} from "@thenounproject/lingo-core";
import useNotifications from "@actions/useNotifications";

import useAssetsCustomFieldUpdate from "@redux/actions/assets/useAssetsCustomFieldUpdate";

export type Props = {
  field: CustomField;
  assetUuids: string[];
  updateCustomFieldValue: ReturnType<typeof useAssetsCustomFieldUpdate>[0];
  assetFieldValue: string[];
  canEdit: boolean;
};

const ChecklistCustomField = ({
  field,
  updateCustomFieldValue,
  assetUuids,
  assetFieldValue,
  canEdit,
}: Props) => {
  const [menuShown, showMenu, hideMenu] = useBoolean(false);
  const { showNotification } = useNotifications();

  const handleFieldOptionUpdate = useCallback(
    async (fieldId: number, optionId: number, operation: CustomFieldOperation) => {
      const { error } = await updateCustomFieldValue({
        assetUuids,
        fieldId,
        optionId,
        operation,
      });
      if (error) {
        showNotification({ message: error.message, level: "error" });
      }
    },
    [assetUuids, updateCustomFieldValue, showNotification]
  );

  function onClickItem(isSelected, optionUuid) {
    return isSelected
      ? handleFieldOptionUpdate(field.id, optionUuid, CustomFieldOperation.remove)
      : handleFieldOptionUpdate(field.id, optionUuid, CustomFieldOperation.add);
  }

  function renderChecklist() {
    return field.options?.slice(0, 4).map(o => {
      const isSelected = assetFieldValue?.includes(String(o.id));
      return (
        <Checkbox
          key={o.id}
          label={o.name}
          size="small"
          onClick={() => onClickItem(isSelected, o.id)}
          isSelected={isSelected}
        />
      );
    });
  }

  function renderOverflowDropdown() {
    if (field.options?.length < 5) return null;
    const options = field.options?.slice(4);
    const selectedOptions = options?.filter(o => assetFieldValue?.includes(String(o.id)));
    const nonSelectedOptions = options?.filter(o => !assetFieldValue?.includes(String(o.id)));

    return (
      <>
        {selectedOptions.map(o => {
          return (
            <Checkbox
              key={o.id}
              label={o.name}
              size="small"
              onClick={() => onClickItem(true, o.id)}
              isSelected={true}
            />
          );
        })}
        {nonSelectedOptions.length > 0 && (
          <>
            <Box mt="s" data-testid="inspector-checklist-dropdown">
              <DropdownButton
                text="More options"
                styleOverrides={{ width: "100%" }}
                data-popup-source={`${field.name}-dropdown`}
                onClick={showMenu}
              />
            </Box>
            <PopupMenu open={menuShown} close={hideMenu} source={`${field.name}-dropdown`}>
              {nonSelectedOptions.map(o => {
                return (
                  <PopupMenu.Item
                    key={o.id}
                    onClick={() => onClickItem(false, o.id)}
                    title={o.name}
                    checked={false}
                  />
                );
              })}
            </PopupMenu>
          </>
        )}
      </>
    );
  }

  if (!canEdit && field.public) {
    const selectedOptions = field.options?.filter(o => assetFieldValue?.includes(String(o.id)));
    return (
      <Flex flexDirection="column" gap="4px">
        {selectedOptions.length ? (
          selectedOptions.map(o => (
            <Text key={o.id} font="ui.small">
              {o.name}
            </Text>
          ))
        ) : (
          <Text font="ui.small">None</Text>
        )}
      </Flex>
    );
  }

  return (
    <Box data-testid="inspector-checklist-field">
      {renderChecklist()}
      {renderOverflowDropdown()}
    </Box>
  );
};

export default ChecklistCustomField;
