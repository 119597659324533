import { useCallback, useEffect } from "react";
import { useAppDispatchV1, useAppSelectorV1 } from "@redux/hooks";
import { AnyObject, API, APIResponse, isError, LingoError } from "@thenounproject/lingo-core";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "@redux/store";

export type CurrentUser = RootState["user"];

export const fetchCurrentUser = createAsyncThunk<
  APIResponse<{ user: number; intercom_id?: string; notifications?: AnyObject[] }>,
  void,
  {
    state: RootState;
    serializedErrorType: LingoError;
    rejectValue: LingoError;
  }
>(
  "user/fetch",
  async (_, thunkApi) => {
    try {
      return await API.call<{
        user: number;
        intercom_id?: string;
        notifications?: AnyObject[];
      }>({
        endpoint: "me/?spaces=true",
        method: "GET",
        entity: API.Entity.user,
      });
    } catch (err) {
      if (isError(err)) {
        return thunkApi.rejectWithValue(err);
      } else {
        throw err;
      }
    }
  },
  {
    serializeError: error => error as LingoError,
  }
);

export default function () {
  const dispatch = useAppDispatchV1();
  const user = useAppSelectorV1(state => state.user);

  const fetch = useCallback(() => {
    return dispatch(fetchCurrentUser());
  }, [dispatch]);

  useEffect(() => {
    if (!user.id && !user.isFetching && !user.isFetched && !user.error) void fetch();
  }, [fetch, user.error, user.id, user.isFetched, user.isFetching]);

  return { user, fetch };
}
