import { API, ItemType } from "@thenounproject/lingo-core";
import { Inspectable } from "@constants/Inspector";

import { createAsyncAction } from "../actionCreators";
import { fetchContentTypes } from "../useFetchContentTypes";
import { type UploadAction, uploadActionForFile } from "../uploads";
import { enqueueUpload } from "../uploads/enqueueUpload";

type Args = {
  inspectable: Inspectable;
  file: File;
};

const [useReplaceAssetFile, replaceAssetFile] = createAsyncAction(
  "assets/replaceFile",
  async ({ inspectable, file }: Args, thunkApi) => {
    const contentTypes = await fetchContentTypes.lazy(thunkApi, {
      itemType: inspectable.item?.type ?? ItemType.asset,
      assetType: inspectable.asset?.type,
    });

    const { upload, asset } = uploadActionForFile(file, contentTypes, {
      // Provide an insertPosition,
      // We aren't inserting but we need the insert position for the upload modal details
      kitId: inspectable.item?.kitId,
      sectionId: inspectable.item?.sectionId,
      spaceId: inspectable.item?.spaceId ?? inspectable.asset?.spaceId,
    });

    upload.isAsset = !inspectable.item?.type || inspectable.item?.type === ItemType.asset;

    const uploadAction: UploadAction = {
      upload,
      requestData: {
        endpoint: `assets/${inspectable.asset.id}`,
        entity: API.Entity.asset,
        method: "PUT",
        file,
        data: asset,
      },
    };
    thunkApi.dispatch(enqueueUpload(uploadAction));
  }
);

export default useReplaceAssetFile;
export { replaceAssetFile };
