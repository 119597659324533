import { API, ItemType } from "@thenounproject/lingo-core";
import { InsertPosition } from "@actions/uploads";
import { createAsyncAction } from "../actionCreators";

type Args = {
  insertPosition: InsertPosition;
  viewId?: number;
  name?: string;
};

const [useCreateGallery, createGallery] = createAsyncAction(
  "items/createGallery",
  async ({ viewId, insertPosition, name }: Args) => {
    const { displayOrder, kitId, sectionId } = insertPosition;
    return await API.call<string>({
      endpoint: "items",
      method: "POST",
      entity: API.Entity.item,
      data: {
        kit_uuid: kitId,
        section_uuid: sectionId,
        display_order: displayOrder,
        type: ItemType.gallery,
        view_id: viewId,
        data: {
          name: name,
        },
      },
    });
  }
);
export default useCreateGallery;
export { createGallery };
