import { API, SpacePreview } from "@thenounproject/lingo-core";

import { createQueryAction } from "@actions/actionCreators";

type Args = {
  spaceId: number | string;
};

const [useSpacePreview, fetchSpacePreview] = createQueryAction<Args, SpacePreview, SpacePreview>(
  {
    entity: "spaces",
    action: "fetchPreview",
    denormalize: false,
    condition: ({ args: { spaceId } }) => Boolean(spaceId),
  },
  async ({ args }) => {
    return await API.call<SpacePreview>({
      endpoint: `spaces/${args.spaceId}/preview`,
      method: "GET",
      entity: API.Entity.spacePreview,
    });
  }
);

export default useSpacePreview;
export { fetchSpacePreview };
