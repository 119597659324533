import { API, APIResultList } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  users: number[];
  spaceId: number;
  kitId: string;
  role: string;
  note?: string;
  notify?: boolean;
};

const [useAddKitMembers, addKitMembers] = createAsyncAction(
  "kitMembers/add",
  async ({ users, spaceId, kitId, role, note = "", notify = true }: Args) => {
    const members = users.map(id => {
      return {
        space_id: spaceId,
        user_id: id,
        kit_uuid: kitId,
        role: role,
        note: note,
        notify: notify,
      };
    });

    return await API.call<APIResultList<"members", string>>({
      endpoint: `kits/${spaceId}/${kitId}/members`,
      method: "POST",
      entity: API.Entity.kitMember,
      data: {
        members,
      },
    });
  }
);
export default useAddKitMembers;
export { addKitMembers };
