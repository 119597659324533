import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

/**
 * Used to complete the process of adding a join domain to a space
 * The token is emailed to the user so that we can verify they
 * have access to the domain.
 * @returns
 */
type Args = {
  token: string;
};

const [useVerifySpaceEmailDomain, verifySpaceEmailDomain] = createAsyncAction(
  "spaces/verifyEmailDomain",
  async ({ token }: Args) => {
    return await API.call<string>({
      endpoint: `domains/verify/${token}`,
      method: "POST",
      entity: API.Entity.space,
    });
  }
);

export default useVerifySpaceEmailDomain;
export { verifySpaceEmailDomain };
