import { API, camelize } from "@thenounproject/lingo-core";
import { createQueryAction } from "@actions/actionCreators";

export type Plan = {
  availability: "current" | "available" | "request" | "unavailable";
  currency: "usd";
  featureGroups: string[];
  hidden: boolean;
  inStripe: boolean;
  interval: "month" | "year";
  maxAssets?: number;
  maxGb: number;
  maxUsers?: number;
  minUsers?: null;
  planName: string;
  name: string;
  planId: string;
  price: number;
  pricePerUser?: number;
  selfServe: boolean;
  totalPrice: number;
};

type Response = {
  plans: Plan[];
  owner: { name: string; email: string };
};

const [useAvailablePlans, fetchAvailablePlans] = createQueryAction<
  { spaceId: number },
  Response,
  Response
>(
  {
    entity: "spaces",
    action: "fetchAvailablePlans",
    denormalize: false,
  },
  async ({ args }) => {
    const res = await API.call<{ plans: Plan[]; owner: { name: string; email: string } }>({
      endpoint: `spaces/${args.spaceId}/plans`,
      method: "GET",
    });

    return {
      result: {
        plans: res.result.plans.map(plan => camelize(plan) as Plan),
        owner: res.result.owner,
      },
    };
  }
);

export default useAvailablePlans;
export { fetchAvailablePlans };
