import useFetchMagicToken from "@actions/auth/useFetchMagicToken";
import { fetchSpace } from "@actions/spaces/useSpace";
import { AsyncResponse } from "@redux/actions/actionCreators/createAsyncAction";
import { useAppDispatchV1 } from "@redux/hooks";
import {
  buildURL,
  getCustomDomainComponents,
  LingoError,
  ErrorCode,
  SpacePreview,
  useNavigation,
} from "@thenounproject/lingo-core";
import { useCallback, useEffect, useState } from "react";
import { parseHostname } from "shared";
import { AuthToken, AuthType, SignupStep } from "./useAuthState";

type Args = {
  token: AuthToken;
  authType: AuthType;
  redirectUrl: string;
  space: SpacePreview;
};

type Handler = (action: () => AsyncResponse) => AsyncResponse;
type Result = {
  process: Handler;
  isProcessing: boolean;
  error: LingoError;
};

export default function ({ authType, redirectUrl, space, token }: Args): Result {
  const [isProcessing, setProcessing] = useState(false);
  const [error, setError] = useState<LingoError>();
  const navigation = useNavigation();
  const [fetchMagicToken] = useFetchMagicToken();
  const dispatch = useAppDispatchV1();

  const handleRedirect = useCallback(() => {
    const domain = getCustomDomainComponents();

    let url = redirectUrl;
    if (!redirectUrl) {
      url = "/space";
      if (space) {
        url = buildURL("/", { space: space });
      } else if (domain.identifier) {
        url = buildURL("/", { space: { subdomain: domain.subdomain, domain: domain.host } });
      }
    }

    // If the url we are redirecting to is at a differnt host we need to pass the redirect through login again with a magic token so they are reauthenticated on the new domain
    try {
      const { hostname: target } = new URL(url);
      const { hostname: current } = window.location;
      if (target !== current) {
        const { identifier } = parseHostname(target);
        // Fetch the space to make sure this user has access to it.
        // We don't want to redirect to arbitrary domains with a magic token
        // If there are members, then we must have a valid token
        let _spaceFetch: Promise<unknown>;
        if (space.members?.length) {
          _spaceFetch = Promise.resolve();
        } else {
          _spaceFetch = identifier
            ? dispatch(fetchSpace({ args: { spaceId: identifier } })).then(res => {
                if (fetchSpace.fulfilled.match(res)) {
                  return res;
                } else if (fetchSpace.rejected.match(res)) {
                  throw res.payload;
                }
              })
            : Promise.resolve();
        }
        void _spaceFetch
          .then(() => fetchMagicToken({ encrypt: false }))
          .then(({ response, error }) => {
            if (error) {
              return null;
            }
            const { token } = response.result;
            window.location.replace(`http://${target}/login/${token}?next=${url}`);
          })
          .catch(err => {
            console.error(`Failed to fetch space or magic token. ${err}`);
            // fallback to default space
            navigation.replace("/space");
          });
        return;
      }
    } catch (e) {
      // pass
    }
    navigation.replace(url);
  }, [dispatch, fetchMagicToken, navigation, redirectUrl, space]);

  const process: Handler = useCallback(
    async action => {
      if (isProcessing) return;
      setProcessing(true);
      const res = await action();

      setProcessing(false);
      if (res.error) {
        if (res.error.code === ErrorCode.googleAuthConfirmationRequired) {
          navigation.push(`/link-account/google/${res.error.details.link_token}`);
        } else {
          setError(res.error);
        }
        return;
      }

      const result = (res.response as any)?.result;
      /**
       * Do not redirect if the user just signed up.
       * They'll be prompted to join or create a space.
       */
      const isJoining = ["invitation", "join-link"].includes(token?.type);
      if (!isJoining && result?.verification_email_sent) {
        navigation.push(`/signup/${SignupStep.verificationSent}`);
      } else if (!isJoining && result?.can_join_spaces) {
        navigation.push("/join-spaces");
      } else {
        handleRedirect();
      }
      return res;
    },
    [isProcessing, token?.type, navigation, handleRedirect]
  );

  useEffect(() => {
    setError(null);
  }, [authType]);

  return {
    process,
    error,
    isProcessing,
  };
}
