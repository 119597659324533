import { v4 as genUuid } from "uuid";
import { PortalItemType, API } from "@thenounproject/lingo-core";

import { UploadAction, uploadActionForFile, type InsertPosition } from "@actions/uploads";
import { createAsyncAction } from "../actionCreators";
import { fetchContentTypes } from "../useFetchContentTypes";
import { enqueueUpload } from "../uploads/enqueueUpload";

type Args = {
  portalId: string;
  files: File[];
  itemType: PortalItemType;
  insertPosition: InsertPosition;
};

const [useCreatePortalFileAssets, createPortalFileAssets] = createAsyncAction(
  "portalItem/createFileAsset",
  async ({ files, itemType, insertPosition, portalId }: Args, thunkApi) => {
    const contentTypes = await fetchContentTypes.lazy(thunkApi, { itemType });
    const batchId = genUuid();

    /**
     * Queue up the uploads with meta
     */
    files
      .map(file => {
        const { upload, asset } = uploadActionForFile(file, contentTypes, insertPosition, batchId);

        return {
          upload,
          requestData: {
            endpoint: `portal_items`,
            entity: API.Entity.portalItem,
            method: "POST",
            file: file,
            data: {
              portal_uuid: portalId,
              asset,
              type: itemType,
              display_order: insertPosition.displayOrder,
            },
          },
        } as UploadAction;
      })
      .forEach(action => {
        thunkApi.dispatch(enqueueUpload(action));
      });
  }
);
export default useCreatePortalFileAssets;
export { createPortalFileAssets };
