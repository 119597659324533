import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function GAListener({ children }: { children: React.ReactNode }) {
  const path = useLocation().pathname;

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    window.ga?.("send", "pageview", path);
  }, [path]);

  return <>{children}</>;
}
