import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

const [useSendVerificationEmail, sendVerificationEmail] = createAsyncAction(
  "auth/sendEmailVerification",
  async () => {
    await await API.call({
      endpoint: "email/verify/send-email",
      method: "POST",
    });
  }
);

export default useSendVerificationEmail;
export { sendVerificationEmail };
