import { AnyObject, API, ErrorCode } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

export type SignupArgs = {
  name: string;
  email: string;
  password: string;
  productEmailOptIn: boolean;
  newsletterEmailOptIn: boolean;
  touAgree: boolean;
  token?: string;
  queryString?: string;
  skipAutoJoin?: boolean;
};

const [useSignup, signup] = createAsyncAction("auth/signup", async (args: SignupArgs) => {
  if (!args.touAgree) {
    throw {
      code: ErrorCode.invalidParams,
      message: "Please agree to the Terms of Use.",
    };
  }

  const emailPrefs = [];
  if (!args.newsletterEmailOptIn) emailPrefs.push("newsletter");
  if (!args.productEmailOptIn) {
    emailPrefs.push("announcement");
    emailPrefs.push("educational");
  }

  const data = {
    name: args.name,
    email: args.email,
    password: args.password,
    token: args.token,
    email_prefs: emailPrefs.join(","),
    skip_auto_join: args.skipAutoJoin,
  };

  let endpoint = "signup/";
  if (args.queryString) endpoint += args.queryString;

  return await API.call<{
    user: number;
    intercom_id?: string;
    notifications?: AnyObject[];
  }>({
    endpoint,
    method: "POST",
    data,
    entity: API.Entity.user,
  });
});

export default useSignup;
export { signup };
