import React, { ReactNode, useCallback, useEffect, useMemo } from "react";
import { useInView } from "react-intersection-observer";

import { InViewContext } from "./InViewContext";

interface Props {
  children: ReactNode;
}

type InViewItemType = "header";

export type InViewHandlerInput = {
  type: InViewItemType;
  id: string;
};

export default function InViewProvider({ children }: Props) {
  const [headers, setHeaders] = React.useState<string[]>([]);

  const onEntry = useCallback(({ type, id }: InViewHandlerInput) => {
    if (type === "header") {
      setHeaders(headers => [...headers, id]);
    }
  }, []);

  const onExit = useCallback(({ type, id }: InViewHandlerInput) => {
    if (type === "header") {
      setHeaders(headers => headers.filter(header => header !== id));
    }
  }, []);

  const context = useMemo(() => ({ headers, onEntry, onExit }), [headers, onEntry, onExit]);

  return <InViewContext.Provider value={context}>{children}</InViewContext.Provider>;
}

export const useInViewContext = () => React.useContext(InViewContext);

/**
 * Component to render for tracking items in the viewport
 */
export const InViewTracker: React.FC<InViewHandlerInput> = ({ type, id }) => {
  const [ref, inView] = useInView({ threshold: 0 });
  const { onEntry, onExit } = React.useContext(InViewContext);

  useEffect(() => {
    if (inView) {
      onEntry({ type, id });
    } else {
      onExit({ type, id });
    }
    return () => {
      if (inView) onExit({ type, id });
    };
  }, [id, inView, onEntry, onExit, type]);

  return <span ref={ref} />;
};
