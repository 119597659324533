import _merge from "lodash/merge";
/**
 * Manages user's email preferences (used in emailPrefs component).
 */

import { createReducer } from "@reduxjs/toolkit";
import { AnyObject } from "@thenounproject/lingo-core";
import { fetchEmailPrefs } from "@redux/actions/users/useFetchEmailPrefs";
import { updateEmailPrefs } from "@redux/actions/users/useUpdateEmailPrefs";

function getEmailPrefs(action: any) {
  const user = action.payload.entities.users[action.payload.result.user];
  return {
    prefs: user.emailPrefs,
    email: user.email,
    error: null,
  };
}

type Prefs = {
  prefs: AnyObject;
  email: string | null;
  error: string | null;
};

export default createReducer<Prefs>({ prefs: null, email: null, error: null }, builder => {
  builder
    .addCase(fetchEmailPrefs.fulfilled, (state, action) => {
      _merge(state, getEmailPrefs(action));
    })
    .addCase(updateEmailPrefs.fulfilled, (state, action) => {
      _merge(state, getEmailPrefs(action));
    })
    .addCase(fetchEmailPrefs.rejected, (state, action: any) => {
      return {
        prefs: null,
        email: null,
        error: action.error,
      };
    });
});
