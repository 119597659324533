import { APIResultList, API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "@actions/actionCreators";

type Args = {
  assetIds: string[];
};

const [useDeleteAssets, deleteAssets] = createAsyncAction(
  "assets/delete",
  async ({ assetIds }: Args) => {
    const data = {
      assets: assetIds.map(uuid => ({ uuid, status: "deleted" })),
    };

    return await API.call<APIResultList<"assets", string> & APIResultList<"items", string>>({
      endpoint: "sync/assets",
      method: "POST",
      entity: API.Entity.asset,
      data,
    });
  }
);

export default useDeleteAssets;
export { deleteAssets };
