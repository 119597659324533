/**
 * Place this component in the render method of any other component to scroll page to top on mount.
 */

import React from "react";

export default class ScrollToTopOnMount extends React.Component {
  componentDidMount() {
    const contentArea = document.querySelector("#contentArea");
    const contentAreaInner = document.querySelector("#contentAreaInner");
    try {
      window.scrollTo(0, 0);
      contentArea.scrollTo(0, 0);
      contentAreaInner.scrollTo(0, 0);
    } catch (e) {
      // If this fails ¯\_(ツ)_/¯
    }
  }

  render() {
    return null;
  }
}
