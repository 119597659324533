import React from "react";
import { PopupMenu, buildURL, copy } from "@thenounproject/lingo-core";

import { useSelectSpace } from "@selectors/entities/spaces";
import { useAppSelectorV1 } from "@redux/hooks";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import useDeleteKitCollection from "@actions/kitCollections/useDeleteKitCollection";

type Props = {
  kitCollectionId: string;
};

const KitCollectionMenuItems: React.FC<Props> = ({ kitCollectionId }) => {
  const space = useSelectSpace(),
    kitCollection = useAppSelectorV1(
      state => state.entities.kitCollections.objects[kitCollectionId]
    ),
    canEdit = space.access.permissions.includes("edit_kit_collection"),
    hasCollections = space.features.includes("kit_collections");

  const { showModal } = useShowModal();
  const [deleteKitCollection] = useDeleteKitCollection();

  const copyShareLink = React.useCallback(() => {
    copy(buildURL(`/c/${kitCollectionId}`, { space }));
  }, [space, kitCollectionId]);

  const manageKitsInCollection = React.useCallback(
    () =>
      showModal(ModalTypes.MANAGE_COLLECTION_KITS, {
        kitCollectionId,
      }),
    [showModal, kitCollectionId]
  );

  const renameCollection = React.useCallback(
    () =>
      showModal(ModalTypes.CREATE_EDIT_KIT_COLLECTION, {
        kitCollectionId: kitCollection.uuid,
        editing: true,
        preloadedState: {
          name: kitCollection.name,
        },
      }),
    [showModal, kitCollection]
  );

  const deleteCollection = React.useCallback(() => {
    showModal(ModalTypes.CONFIRMATION, {
      title: "Delete Collection?",
      message:
        "This Collection will no longer be accessible to members or visitors. The Kits in this collection will not be deleted. Are you sure?",
      buttonText: "Delete Collection",
      buttonProcessingText: "Deleting Collection...",
      onConfirm: () => deleteKitCollection({ kitCollectionId }),
    });
  }, [showModal, kitCollectionId, deleteKitCollection]);

  return (
    <>
      <PopupMenu.Item title="Copy share link" onClick={copyShareLink} />
      {canEdit && (
        <>
          {hasCollections && (
            <PopupMenu.Item title="Manage kits in collection" onClick={manageKitsInCollection} />
          )}
          <PopupMenu.Item title="Rename collection" onClick={renameCollection} bordered />
          <PopupMenu.Item title="Delete collection" onClick={deleteCollection} />
        </>
      )}
    </>
  );
};

export default KitCollectionMenuItems;
