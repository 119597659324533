import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  kitId: string;
  spaceId: number;
  version: number;
};

const [useDeleteKitVersion, deleteKitVersion] = createAsyncAction(
  "kitVersions/delete",
  async ({ kitId, version }: Args) => {
    return await API.call<string>({
      endpoint: `kits/${kitId}`,
      method: "PUT",
      entity: API.Entity.kitVersion,
      data: {
        version: version,
        status: "deleted",
      },
    });
  }
);
export default useDeleteKitVersion;
export { deleteKitVersion };
