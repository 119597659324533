import React, { useState, useEffect, useCallback, FormEvent } from "react";
import {
  buildURL,
  Button,
  Input,
  Kit,
  Portal,
  PortalPermission,
  PortalPreview,
  SpacePermission,
  SpacePreview,
} from "@thenounproject/lingo-core";

import { AuthFormWrapper, authButtonStyle, AuthFormMessage } from "./AuthElements";

import useEnterPassword from "@redux/actions/auth/useEnterPassword";
import AuthFormTitle from "./AuthFormTitle";
import useAuthHandler from "./useAuthHandler";
import useCurrentUser from "@queries/useCurrentUser";
import useSpace from "@redux/actions/spaces/useSpace";
import usePortal from "@redux/actions/portals/usePortal";

type Props = {
  portalId?: Portal["id"];
  kitId?: Kit["kitId"];
  spaceIdentifier: string | number;
  space?: SpacePreview;
  portal?: PortalPreview;
  autoPassword?: string;
  responseHandler?: ReturnType<typeof useAuthHandler>;
};

function PasswordEntry({
  kitId,
  portalId,
  spaceIdentifier,
  space,
  portal,
  autoPassword,
  responseHandler,
}: Props) {
  const { user } = useCurrentUser(),
    userIsAuthenticated = Boolean(user?.id);
  const { data: authenticatedSpace } = useSpace({ spaceId: space?.id }, { skip: !space });
  const { data: accessiblePortal } = usePortal(
    { portalId: portal?.id },
    { skip: !kitId && !portal }
  );
  const [password, setPassword] = useState(autoPassword || ""),
    [enterPassword] = useEnterPassword(),
    submitPassword = useCallback(
      (pass: string) => {
        const act = enterPassword({
          password: pass,
          spaceId: spaceIdentifier,
          kitId,
          portalId,
        });
        void responseHandler.process(async () => await act);
      },
      [enterPassword, spaceIdentifier, kitId, portalId, responseHandler]
    ),
    onPasswordSubmit = useCallback(
      (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        submitPassword(password);
      },
      [submitPassword, password]
    );

  /**
   * Mount effect
   */
  useEffect(() => {
    if (autoPassword) {
      setPassword(autoPassword);
      submitPassword(autoPassword);
    }
  }, [autoPassword, submitPassword]);

  const { isProcessing, error } = responseHandler;
  function renderLoginOptions() {
    if (!userIsAuthenticated) {
      return (
        <>
          <AuthFormMessage>
            <span>
              Already a member of this space?{" "}
              <Button
                id="login-button"
                text="Login instead"
                buttonStyle="tertiary"
                size="small"
                link={`/login/${window.location.search || ""}`}
              />
            </span>
          </AuthFormMessage>
          <AuthFormMessage>
            <Button
              text="Login with SSO"
              buttonStyle="tertiary"
              size="small"
              link={`/login/sso/${window.location.search || ""}`}
            />
          </AuthFormMessage>
        </>
      );
    }

    if (accessiblePortal?.access?.permissions.includes(PortalPermission.view)) {
      return (
        <AuthFormMessage>
          <span>
            <Button
              id="dashboard-button"
              text="Back to portal"
              buttonStyle="tertiary"
              size="small"
              link={buildURL(`/`, { space, portal: accessiblePortal })}
            />
          </span>
        </AuthFormMessage>
      );
    }

    if (authenticatedSpace?.access?.permissions.includes(SpacePermission.viewDashboard))
      return (
        <AuthFormMessage>
          <span>
            <Button
              id="dashboard-button"
              text="Go to dashboard"
              buttonStyle="tertiary"
              size="small"
              link={
                authenticatedSpace?.access?.permissions.includes(SpacePermission.viewDashboard)
                  ? buildURL(`/dashboard`, { space: authenticatedSpace })
                  : buildURL(`/`, { space, portal })
              }
            />
          </span>
        </AuthFormMessage>
      );
  }

  return (
    <AuthFormWrapper>
      <AuthFormTitle title="Password required" space={space} portal={portal} />

      <form data-testid="password-entry-form" onSubmit={onPasswordSubmit}>
        <Input
          id="password"
          onChange={e => {
            setPassword(e.target.value);
          }}
          value={password}
          type="password"
          label="Password"
          styleOverrides={{ pb: "m" }}
          message={error?.message}
          inputStyle={error ? "error" : null}
        />
        <Button
          id="submit-button"
          {...authButtonStyle}
          type="submit"
          text="Enter"
          disabled={isProcessing}
        />
        {renderLoginOptions()}
      </form>
    </AuthFormWrapper>
  );
}

export default PasswordEntry;
