import React, { useCallback, useMemo } from "react";
import styled from "styled-components";

import {
  AnyObject,
  Box,
  Button,
  ContextMenu,
  Flex,
  Kit,
  pluralize,
  PopupMenu,
  Section,
  SpaceThemeNames,
  styles,
  useBoolean,
  useDrag,
  utils,
  copy,
  buildURL,
  useNavigation,
} from "@thenounproject/lingo-core";

import useMoveItems from "@actions/items/useMoveItems";
import useNotifications from "@actions/useNotifications";
import useSetDraggingEntity, { DragEntities } from "@actions/useSetDraggingEntity";
import { KitCallbackArgs } from "@features/content-management/modals/MoveCopyToKitModal";
import { AssetCopyTypes } from "@features/content-management/modals/MoveCopyToSectionModal";
import { textItemDragStyle } from "@features/navigation/consts";
import stripVersion from "@helpers/stripVersion";
import useBatchUpdateSections from "@redux/actions/sections/useBatchUpdateSections";
import useDeleteSection from "@redux/actions/sections/useDeleteSection";
import useDuplicateSection from "@redux/actions/sections/useDuplicateSection";
import useMoveHeading from "@redux/actions/sections/useMoveHeading";
import useMoveSection from "@redux/actions/sections/useMoveSection";
import useUpdateSection from "@redux/actions/sections/useUpdateSection";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import { useGetDraggingState, useGetNavPoint } from "@selectors/getters";

import { KitNavHeaderData } from "./KitNavHeaderItem";
import useNavPoint from "@hooks/useNavPoint";

export type KitNavSectionData = {
  kit: Kit;
  kitPath: string;
  section: Section;
  allSectionIds: string[];
  selected: boolean;
  canEdit: boolean;
  shareLink: string;
  headers: KitNavHeaderData[];
};

type ParentNavProps = {
  selected: boolean;
  hasChildren: boolean;
  dragDirection?: string;
  isGalleryOrHeadingDrag?: boolean;
  [key: string]: any;
};

const UNTITLED = "Untitled section";

const getParentThemeStyles = (props: ParentNavProps & utils.ThemedProps) => {
  return {
    [SpaceThemeNames.classic]: `
      font-weight: 600;
      padding: 4px 8px;
      margin-bottom: 4px;
      @media not (hover: none) {
        &:hover {
          background: ${styles.color.grayLighter}
        }
      }
      ${
        props.selected &&
        `
        background: ${styles.color.grayLighter};
      `
      }
    `,
    [SpaceThemeNames.scher]: `
      margin-bottom: 16px;
      padding: 2px;
      color: ${styles.color.grayDarkest};
      @media not (hover: none) {
        &:hover {
          color: ${styles.color.black};
        }
      }
      ${
        props.selected &&
        `
        color: ${styles.color.black};
        font-weight: 600;
      `
      }
      ${
        props.selected &&
        props.hasChildren &&
        `
        margin-bottom: 16px;
      `
      }
    `,
    [SpaceThemeNames.wyman]: `
    padding: 4px 8px;
    ${
      props.selected &&
      `
      color: ${utils.getPrimaryColor(props)};
      font-weight: 600;
    `
    }
    `,
  }[props.theme.themeName];
};

const ParentItem = styled(Flex).attrs({
  justifyContent: "space-between",
  alignItems: "center",
  transition: "all 0.3s ease",
  p: "0",
  borderRadius: "4px",
  textAlign: "left",
  position: "relative",
  cursor: "pointer",
  as: "button",
})<ParentNavProps>`
  ${styles.ellipsis};
  ${props => utils.getFont("ui.regular", null, props)};
  ${props => getParentThemeStyles(props)};

  &:after {
    content: "";
    height: 2px;
    width: 100%;
    top: 0;
    left: 0;
    background: ${props => utils.getPrimaryColor(props)};
    z-index: 10;
    position: absolute;
    opacity: 0;
  }

  &:before {
    content: "";
    height: 2px;
    width: 100%;
    bottom: 0;
    left: 0;
    background: ${props => utils.getPrimaryColor(props)};
    z-index: 10;
    position: absolute;
    opacity: 0;
  }

  ${props =>
    props.isGalleryOrHeadingDrag &&
    props.dragDirection &&
    `
    background: ${utils.getColor("grayLighter")};
  `}

  ${props =>
    !props.isGalleryOrHeadingDrag &&
    props.dragDirection === "top" &&
    `
  &:after {
    opacity: 1;
  }
`};
  ${props =>
    !props.isGalleryOrHeadingDrag &&
    props.dragDirection === "bottom" &&
    `
  &:before {
    opacity: 1;
  }
`};

  .overflow {
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  @media not (hover: none) {
    &:hover {
      .overflow {
        opacity: 1;
      }
    }
  }
`;

const Wrapper = styled(Box)`
  position: relative;
  .overflow {
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  @media not (hover: none) {
    &:hover {
      .overflow {
        opacity: 1;
      }
    }
  }
`;

const getInputThemeStyles = (props: AnyObject) => {
  return {
    [SpaceThemeNames.classic]: `
      font-weight: 600;
      padding: 4px 8px;
      margin-bottom: 4px;
      border-radius: 4px;
      &:hover {
        background: ${styles.color.grayLighter}
      }
      ${
        props.selected &&
        `
        background: ${styles.color.grayLighter};
      `
      }
    `,
    [SpaceThemeNames.scher]: `
      margin-bottom: 24px;
      color: ${styles.color.grayDarkest};
      &:hover {
        color: ${styles.color.black};
      }
      ${
        props.selected &&
        `
        color: ${styles.color.black};
        font-weight: 600;
      `
      }
      ${
        props.selected &&
        props.hasChildren &&
        `
        margin-bottom: 16px;
      `
      }
    `,
    [SpaceThemeNames.wyman]: `
    ${
      props.selected &&
      `
      color: ${utils.getPrimaryColor(props)};
      font-weight: 600;
    `
    }
    `,
  }[props.theme.themeName];
};

export const NavInput = styled(Flex)<{ selected: boolean; hasChildren: boolean }>`
  input {
    ${(props: AnyObject) => utils.getFont("ui.regular", null, props)};
    background: transparent;
    width: 100%;
    line-height: 1.4;
  }
  ${(props: AnyObject) => getInputThemeStyles(props)}
`;

const KitNavSectionItem: React.FC<KitNavSectionData> = ({
  kit,
  kitPath,
  section,
  allSectionIds,
  selected,
  shareLink,
  canEdit,
}) => {
  const navigation = useNavigation();
  const ref = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const { showNotification } = useNotifications();
  const [menuOpen, setMenuOpen, setMenuClosed] = useBoolean(false);
  const [editing, setEditing, setNotEditing] = useBoolean(false);
  const [editValue, setEditValue] = React.useState(section.name || UNTITLED);
  const { space, portal } = useNavPoint();
  const navPoint = useGetNavPoint();
  const draggingState = useGetDraggingState();
  const isGalleryOrHeadingDrag = [DragEntities.GALLERY_ITEM, DragEntities.KIT_NAV_HEADING].includes(
    draggingState.entity
  );
  const { showModal } = useShowModal();
  const [moveItems] = useMoveItems();
  const [moveHeading] = useMoveHeading();
  const setDragEntity = useSetDraggingEntity();
  const [updateSection] = useUpdateSection();
  const [deleteSection] = useDeleteSection();
  const [batchUpdateSections] = useBatchUpdateSections();
  const [duplicateSection] = useDuplicateSection();
  const [moveSection] = useMoveSection();

  const { id, label } = useMemo(() => {
    return {
      id: section?.id as string,
      label: (section?.name || UNTITLED) as string,
    };
  }, [section]);

  React.useEffect(() => {
    if (editing) {
      inputRef.current.select();
    }
  }, [editing]);

  /**
   * Section action handlers
   */
  const onClickSectionLink = useCallback(() => {
    const queryParams = {
      kit_token: kit.shareToken || undefined,
      v: section.version,
    };
    navigation.push(buildURL(`/s/${section.urlId}/`, { space, portal }, queryParams));
  }, [kit.shareToken, navigation, portal, section.urlId, section.version, space]);

  const onDoubleClickSectionLink = useCallback(() => {
    if (!canEdit) return;
    setEditing();
  }, [canEdit, setEditing]);

  const onEditSection = useCallback(() => {
    if (editValue === label || !editValue.trim()) return setNotEditing();
    void updateSection({ sectionId: id, updates: { name: editValue } });
  }, [editValue, id, label, setNotEditing, updateSection]);

  const onDeleteSection = useCallback(() => {
    showModal(ModalTypes.CONFIRMATION, {
      title: "Are you sure you want to delete this section?",
      message: "Deleting this section will also remove all the assets in it.",
      buttonText: "Delete",
      buttonProcessingText: "Deleting...",
      onConfirm: () =>
        deleteSection({ sectionId: id }).then(result => {
          if (!result.error && selected) {
            navigation.push(kitPath);
          }
          return result;
        }),
    });
  }, [deleteSection, id, kitPath, navigation, selected, showModal]);

  const moveSectionCallback = useCallback(
    async ({ selectedKit }: KitCallbackArgs) => {
      const { error } = await moveSection({
        kitId: selectedKit.kitId,
        fromKitId: kit.kitId,
        sectionId: id,
      });
      if (error) {
        showNotification({ message: `Unable to move section ${error.message}`, level: "error" });
      } else {
        showNotification({
          message: `Section moved to`,
          link: {
            text: selectedKit?.name || "Untitled Kit",
            url: buildURL(`/k/${selectedKit?.urlId}`, { space }, { v: 0 }),
          },
        });

        /**
         * If we move the section we're viewing, route to the top level kit.
         */
        if (navPoint.sectionId === id) {
          navigation.push(buildURL(`/k/${kit.urlId}`, { space, portal }));
        }
      }
    },
    [
      id,
      kit.kitId,
      kit.urlId,
      moveSection,
      navPoint.sectionId,
      navigation,
      portal,
      showNotification,
      space,
    ]
  );

  const copySectionCallback = useCallback(
    async ({ selectedKit, copyAs }: KitCallbackArgs) => {
      const { error } = await duplicateSection({
        kitId: selectedKit.kitId,
        sectionId: id,
        createReferences: copyAs === AssetCopyTypes.reference,
      });

      if (error) {
        showNotification({
          message: `Unable to duplicate section ${error.message}`,
          level: "error",
        });
      } else {
        showNotification({
          message: `Section copied to`,
          link: {
            text: selectedKit?.name || "Untitled Kit",
            url: buildURL(
              `/k/${selectedKit?.urlId}`,
              { space, portal: selectedKit.kitId === kit.kitId ? portal : null },
              { v: 0 }
            ),
          },
        });
      }
    },
    [duplicateSection, id, kit.kitId, portal, showNotification, space]
  );

  const onCopySection = useCallback(() => {
    showModal(ModalTypes.MOVE_COPY_TO_KIT, {
      type: "copy",
      callback: copySectionCallback,
      entityText: section.name,
    });
  }, [copySectionCallback, section.name, showModal]);

  const onMoveSection = useCallback(() => {
    showModal(ModalTypes.MOVE_COPY_TO_KIT, {
      type: "move",
      callback: moveSectionCallback,
      entityText: section.name,
    });
  }, [moveSectionCallback, section.name, showModal]);

  const onReorderSection = useCallback(
    async ({ dragId, dropId, dragDirection }) => {
      /** Remove the dragged item from array */
      const sectionIds = allSectionIds.filter(id => id !== dragId);

      /** Determine the index where the section should be placed */
      const dropIndex = sectionIds.indexOf(dropId);
      const spliceIndex = dropIndex + (dragDirection === "top" ? 0 : 1);
      sectionIds.splice(spliceIndex, 0, dragId);

      const { error } = await batchUpdateSections({
        data: sectionIds.map((id, index) => {
          return { uuid: id, display_order: index * 10 };
        }),
      });
      if (error) {
        showNotification({ message: error.message, level: "error" });
      } else {
        // TODO: Migrate to query mutation
        /** Fetch the updated kit sections order */
        // void fetchKitOutline({ spaceId: space.id, kitId: kit.kitId });
      }
    },
    [allSectionIds, batchUpdateSections, showNotification]
  );

  /**
   * Drag & drop handlers
   */
  const doOnDragStart = (e: React.DragEvent) => {
    e.dataTransfer.effectAllowed = "all";
    const elem: HTMLElement = document.createElement("div");
    elem.setAttribute("style", textItemDragStyle);
    elem.innerHTML = section.name || UNTITLED;
    document.body.appendChild(elem);
    e.dataTransfer.setDragImage(elem, 0, 0);
    e.dataTransfer.setData("sectionId", id);
    setDragEntity({ entity: DragEntities.KIT_NAV_SECTION });
    setTimeout(() => {
      document.body.removeChild(elem);
    }, 0);
  };

  const doOnDrop = async (e: React.DragEvent) => {
    if (e) e.preventDefault();

    switch (draggingState.entity) {
      case DragEntities.GALLERY_ITEM:
        {
          const items = e.dataTransfer.getData("reorderItemIds");
          const fromSectionId = e.dataTransfer.getData("fromSectionId");

          if (items && fromSectionId) {
            const itemArray = items.split(",");
            const res = await moveItems({
              itemIds: itemArray.map((uuid: string) => stripVersion(uuid)),
              fromSectionId,
              toSectionId: id,
            });
            if (res.isSuccess) {
              showNotification({
                message: `${itemArray.length} ${pluralize("asset", itemArray.length)} moved to`,
                link: {
                  text: label,
                  url: buildURL(`/s/${section?.urlId}`, { space, portal }),
                },
              });
              // TODO: Migrate to query mutation
              // resetSection({ sectionId: id });
              // resetSection({ sectionId: fromSectionId });
              // void fetchKitOutline({
              //   spaceId: navPoint.spaceId,
              //   kitId: navPoint.kitId,
              //   version: 0,
              // });
            }
          }
        }
        break;
      case DragEntities.KIT_NAV_SECTION:
        {
          const dragId = e.dataTransfer.getData("sectionId"),
            dropId = id;
          if (dragId && dragId !== dropId) {
            void onReorderSection({ dragId, dropId, dragDirection: d.dragDirection.vertical });
          }
        }
        break;
      case DragEntities.KIT_NAV_HEADING: {
        const headingId = e.dataTransfer.getData("navHeadingId"),
          fromSectionId = e.dataTransfer.getData("fromSectionId"),
          toSectionId = id;

        if (headingId && fromSectionId) {
          const res = await moveHeading({ headingId, fromSectionId, toSectionId });
          if (res.isSuccess) {
            showNotification({
              message: "Heading moved to",
              link: {
                text: section?.name || "Untitled Section",
                url: buildURL(`/s/${section?.urlId}`, { space, portal }),
              },
            });
          }
        }
      }
    }
  };

  const d = useDrag({ dragRef: ref, doOnDragStart, doOnDrop });
  const dragProps = canEdit ? d.dragProps : {};

  const contextMenuItems = [];
  if (!editing) {
    if (shareLink) {
      contextMenuItems.push(
        <PopupMenu.Section key="share-link">
          <PopupMenu.Item onClick={() => copy(shareLink)} title="Copy share link" />
        </PopupMenu.Section>
      );
    }
    if (canEdit) {
      contextMenuItems.push(
        <PopupMenu.Section key="update-items">
          <PopupMenu.Item key="edit-item" onClick={setEditing} title="Rename Section" />
          <PopupMenu.Item onClick={onMoveSection} title="Move to..." />
          <PopupMenu.Item onClick={onCopySection} title="Copy to..." />
        </PopupMenu.Section>
      );

      contextMenuItems.push(
        <PopupMenu.Section key="edit-items">
          <PopupMenu.Item key="delete-item" onClick={onDeleteSection} title="Delete Section" />
        </PopupMenu.Section>
      );
    }
  }

  function renderContextMenus() {
    if (contextMenuItems.length === 0) return;
    return (
      <>
        {/* Context menu for right click */}
        <ContextMenu data-testid="nav-context-menu" id={id} parentRef={ref}>
          {contextMenuItems}
        </ContextMenu>
        {/* Context menu for overflow button */}
        {menuOpen && (
          <PopupMenu source={`${id}-overflow-button`} close={setMenuClosed}>
            {contextMenuItems}
          </PopupMenu>
        )}
      </>
    );
  }

  return (
    <Wrapper>
      {renderContextMenus()}
      {editing ? (
        <NavInput
          width={"100%"}
          as="form"
          onSubmit={e => {
            e.preventDefault();
            onEditSection();
          }}
          selected={selected}
          hasChildren={Boolean(section.headers?.length)}>
          <input
            ref={inputRef}
            type="text"
            value={editValue}
            onBlur={e => {
              e.preventDefault();
              onEditSection();
            }}
            onChange={e => setEditValue(e.target.value)}
          />
        </NavInput>
      ) : (
        <>
          <ParentItem
            ref={ref}
            width={"100%"}
            hasChildren={Boolean(section.headers?.length)}
            selected={selected}
            isGalleryOrHeadingDrag={isGalleryOrHeadingDrag}
            dragDirection={d?.dragDirection?.vertical}
            onClick={onClickSectionLink}
            onDoubleClick={onDoubleClickSectionLink}
            {...dragProps}>
            <Box
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                paddingRight: "12px",
              }}>
              <span style={{ fontWeight: 600, color: styles.color.black }}>
                {section.name || UNTITLED}
              </span>
            </Box>
          </ParentItem>
          <Button
            className="overflow"
            icon="navigation.overflow"
            size="small"
            p="none"
            position="absolute"
            right={0}
            top={2}
            themeOverrides={{
              primaryColor: "transparent",
              primaryColorDark: "transparent",
              primaryColorTint: "black",
            }}
            data-popup-source={`${id}-overflow-button`}
            onClick={e => {
              e.stopPropagation();
              setMenuOpen();
            }}
          />
        </>
      )}
    </Wrapper>
  );
};
export default React.memo(KitNavSectionItem) as typeof KitNavSectionItem;
