import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "@actions/actionCreators";

type Args = {
  portalItemId: string;
  portalId: string;
};

const [useDeletePortalItem, deletePortalItem] = createAsyncAction(
  "portalItems/delete",
  (args: Args) => {
    return API.call<string>({
      endpoint: `portal_items/${args.portalItemId}`,
      method: "DELETE",
      entity: API.Entity.portalItem,
    });
  }
);

export default useDeletePortalItem;
export { deletePortalItem };
