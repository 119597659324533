import React, { useCallback } from "react";
import styled from "styled-components";
import QueryString from "query-string";
import { Button, Kit, KitVersion, pluralize } from "@thenounproject/lingo-core";

import GalleryViewToggle from "./GalleryViewToggle";
import useNotifications from "@actions/useNotifications";
import useBatchUpdateItemStatus from "@actions/items/useBatchUpdateItemStatus";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import useClearRecentlyDeleted from "@redux/actions/items/useClearRecentlyDeleted";

const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  display: contents;
`;
type Props = {
  kit: Kit;
  kitVersion: KitVersion;
  selectedItems: string[];
  clearSelection: () => void;
};

const KitTrashedItemActions: React.FC<Props> = ({
  kit,
  kitVersion,
  selectedItems,
  clearSelection,
}) => {
  const { showNotification } = useNotifications();
  const { showModal } = useShowModal();
  const [batchUpdateItemStatus] = useBatchUpdateItemStatus();
  const [clearRecentlyDeleted] = useClearRecentlyDeleted();

  const hasItems = kitVersion.counts.trash !== 0,
    selectedItemIds = selectedItems.map(i => i.substring(0, i.lastIndexOf("-"))),
    assetNumString = `${selectedItemIds.length} ${pluralize("asset", selectedItemIds.length)}`;

  const restoreSelection = useCallback(async () => {
    const { error } = await batchUpdateItemStatus({ items: selectedItemIds, status: "active" });
    if (!error) {
      showNotification({
        message: `${pluralize("Asset", selectedItemIds.length)} restored to "${kit.name}".`,
        level: "info",
      });
      clearSelection();
    }
  }, [batchUpdateItemStatus, clearSelection, kit.name, selectedItemIds, showNotification]);

  const deleteAssets = useCallback(() => {
    const modalProps = selectedItemIds.length
      ? {
          title: `Remove ${assetNumString}?`,
          message: `The selected ${pluralize(
            "asset",
            selectedItemIds.length
          )} will be removed from this kit permanently.`,
          buttonText: "Delete",
          buttonProcessingText: "Deleting...",
          onConfirm: () =>
            batchUpdateItemStatus({ items: selectedItemIds, status: "deleted" }).then(action => {
              if (action.isSuccess) clearSelection();
              return action;
            }),
        }
      : {
          title: "Clear all recently removed assets?",
          message: "All recently removed assets will be permanently remvoed from this kit.",
          buttonText: "Delete",
          buttonProcessingText: "Deleting...",
          onConfirm: () => clearRecentlyDeleted({ spaceId: kit.spaceId, kitId: kit.kitId }),
        };
    showModal(ModalTypes.CONFIRMATION, modalProps);
  }, [
    assetNumString,
    batchUpdateItemStatus,
    clearSelection,
    clearRecentlyDeleted,
    kit.kitId,
    selectedItemIds,
    showModal,
    kit.spaceId,
  ]);

  if (!kit) return null;
  const permissions = kit && kit.access ? kit.access.permissions : [];
  if (!permissions.includes("edit_content")) return null;

  const query = QueryString.parse(window.location.search);
  const returnUrl = (query?.previous as string) || `/k/${kit.urlId}`;

  return (
    <Container data-inspector-clear="true">
      <GalleryViewToggle styleOverrides={{ mr: "l" }} />
      <Button buttonStyle="secondary" size="small" text="Done" link={returnUrl} />
      <Button
        buttonStyle="primary"
        size="small"
        text="Restore"
        disabled={!selectedItemIds.length || !hasItems}
        ml="s"
        onClick={restoreSelection}
      />
      <Button
        flexShrink={0}
        buttonStyle="primary"
        size="small"
        text={selectedItemIds.length ? `Delete ${assetNumString}` : "Delete All"}
        disabled={!hasItems}
        ml="s"
        onClick={deleteAssets}
      />
    </Container>
  );
};

export default KitTrashedItemActions;
