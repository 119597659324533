import _remove from "lodash/remove";

import { Tag } from "@thenounproject/lingo-core";
import createEntityReducer from "../helpers/createEntityReducer";

import { fetchAssetTags } from "@redux/actions/tags/useAssetTags";
import { deleteTags } from "@redux/actions/tags/useDeleteTags";
import { updateTags } from "@redux/actions/tags/useUpdateTags";

export default createEntityReducer<Tag>(
  "tags",
  queryBuilder => {
    queryBuilder
      .addCase(updateTags.fulfilled, (state, action) => {
        const successfullUpdates = action.payload.result.keywords.reduce((acc, update) => {
          if (!update.success) return acc;
          acc[update.result.keyword] = update.result.newKeyword;
          return acc;
        }, {});

        fetchAssetTags.getQueryData(state, { spaceId: action.meta.arg.spaceId }).forEach(query => {
          query.data.tags.forEach(tag => {
            if (successfullUpdates[tag.value]) {
              tag.value = successfullUpdates[tag.value];
            }
          });
        });
      })
      .addCase(deleteTags.fulfilled, (state, action) => {
        const deletedKeywords = action.payload.result.keywords.reduce((acc, update) => {
          if (!update.success) return acc;
          acc.add(update.result.keyword);
          return acc;
        }, new Set<string>());

        fetchAssetTags.getQueryData(state, { spaceId: action.meta.arg.spaceId }).forEach(query => {
          const removed = _remove(query.data.tags, tag => deletedKeywords.has(tag.value));
          query.data.total -= removed.length;
        });
      });
  },
  _queryBuilder => {
    // Currently tags are not normalized so we don't need to do anything here
  }
);
