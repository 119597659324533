import React, { useCallback } from "react";
import {
  Button,
  PopupMenu,
  Flex,
  useBoolean,
  Text,
  PopupMenuProps,
  SpacePermission,
} from "@thenounproject/lingo-core";

import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import { useSelectSpace } from "@selectors/entities/spaces";

const CreateButton = () => {
  const space = useSelectSpace();
  const { showModal } = useShowModal();
  const canCreateKit = space?.access?.permissions.includes(SpacePermission.createKit),
    canCreateCollection = space.access?.permissions.includes(SpacePermission.createKitCollection),
    canManagePortals = space?.access?.permissions.includes(SpacePermission.managePortals);

  const [menuShown, showMenu, hideMenu] = useBoolean(false);

  const openKitModal = useCallback(() => {
    showModal(ModalTypes.CREATE_KIT, { space });
  }, [showModal, space]);

  const openPortalModal = useCallback(() => {
    showModal(ModalTypes.CREATE_PORTAL, { space });
  }, [showModal, space]);

  const openCollectionModal = useCallback(() => {
    showModal(ModalTypes.CREATE_EDIT_KIT_COLLECTION, {});
  }, [showModal]);

  if (!(canCreateKit || canManagePortals || canCreateCollection)) return null;

  function renderMenu() {
    if (!menuShown) return null;

    const popupProps: PopupMenuProps = {
      source: `[data-testid='create-button']`,
      close: hideMenu,
      open: menuShown,
      vPos: "floatBelow",
      hPos: "alignRight",
      width: 232,
      maxHeight: undefined,
      stayOpenOnClick: false,
      clickAwayClose: true,
      "data-testid": "create-button",
    };

    const upSell = { badge: null, tooltip: null };
    if (!canCreateCollection) {
      upSell.badge = "Upgrade";
      upSell.tooltip = (
        <Text color="white" font="ui.small">
          Collections are not available on your current plan.{" "}
          <Button
            fontStyle="ui.smallBold"
            size="small"
            buttonStyle="tertiary"
            link="/pricing"
            text="View plans"
            themeOverrides={{ primaryColor: "white", primaryColorDark: "white" }}
            newWindow
          />
        </Text>
      );
    }

    return (
      <PopupMenu {...popupProps}>
        {canManagePortals ? (
          <PopupMenu.Item title="Create Portal" onClick={openPortalModal} />
        ) : (
          <PopupMenu.Item
            id={"create-collection-".concat(String(space.id))}
            onClick={canCreateCollection ? openCollectionModal : null}
            title={"Create Collection"}
            {...upSell}
          />
        )}
        <PopupMenu.Item onClick={openKitModal} title={"Create Kit"} />
      </PopupMenu>
    );
  }

  return (
    <Flex>
      <Button id="create-button" text="Create" onClick={showMenu} icon="action.add" />
      {renderMenu()}
    </Flex>
  );
};

export default React.memo(CreateButton) as React.FC;
