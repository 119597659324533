import { API } from "@thenounproject/lingo-core";
import { showNotification } from "@actions/useNotifications";
import { createAsyncAction } from "../actionCreators";

type Args = {
  spaceId: number;
  name?: string;
};

const [useCreateApiToken, createApiToken] = createAsyncAction(
  "apiTokens/create",
  async ({ spaceId, name }: Args, thunkApi) => {
    const res = await API.call<string>({
      endpoint: `spaces/${spaceId}/api-tokens`,
      method: "POST",
      data: { name },
      entity: API.Entity.apiToken,
    });
    thunkApi.dispatch(showNotification({ message: "API token created" }));
    return res;
  }
);
export default useCreateApiToken;
export { createApiToken };
