import React, { Fragment, useState, useCallback } from "react";
import _debounce from "lodash/debounce";
import {
  OptionCard,
  Box,
  Text,
  Flex,
  Button,
  Select,
  Kit,
  Section,
} from "@thenounproject/lingo-core";

import useShowModal, { ModalTypes } from "@redux/actions/useModals";

import useKits from "@actions/kits/useKits";
import { useSelectSpace } from "@selectors/entities/spaces";

import ModalHeader from "../../../components/ModalHeader";
import ModalFooter from "../../../components/ModalFooter";
import ModalBody from "../../../components/ModalBody";

import { ReferenceOption } from "./MoveCopyToKitModal";
import useKitOutline from "@redux/actions/kitVersions/useKitOutline";
import useNavPoint from "@hooks/useNavPoint";

export type SectionCallbackArgs = {
  selectedKit: Kit;
  fromSectionId: string;
  selectedSection: Section;
  copyAs?: AssetCopyTypes;
};

export type Props = {
  type: "move" | "copy";
  kitId?: string;
  fromSectionId: string;
  toSectionId?: string;
  callback: (sectionCallbackArgs: SectionCallbackArgs) => void;
  entityText?: string;
};

export enum AssetCopyTypes {
  reference = "reference",
  duplicate = "duplicate",
}

const MoveCopyToSectionModal: React.FC<Props> = ({
  type,
  kitId = null,
  fromSectionId,
  toSectionId = null,
  callback,
  entityText,
}) => {
  const space = useSelectSpace();
  const navPoint = useNavPoint();

  const { data } = useKits({ spaceId: space?.id });
  const { kits = [] } = data || {};

  const { showModal, dismissModal } = useShowModal();

  const [selectedKitId, setSelectedKitId] = useState(kitId);
  const [selectedSectionId, setSelectedSectionId] = useState(toSectionId);
  const [copyAs, setCopyAs] = useState(AssetCopyTypes.reference);

  const selectedKit = kits.find(k => k.kitId === selectedKitId);

  const isMoving = type === "move";
  const verbActive = isMoving ? "Moving" : "Copying";
  const buttonText = isMoving ? "Move" : "Copy";

  const { data: kitOutline } = useKitOutline({
    spaceId: navPoint?.spaceId,
    kitId: selectedKitId,
    version: 0,
  });
  const selectedKitSections = kitOutline?.sections;
  const selectedSection = selectedKitSections?.find(s => s.id === selectedSectionId);

  const clearSelection = React.useCallback(() => {
    setSelectedKitId(null);
    setSelectedSectionId(null);
  }, []);

  const createSectionCallback = React.useCallback(() => {
    showModal(ModalTypes.CREATE_SECTION, {
      kitId: selectedKitId,
      space,
      onCreateSection: (toSectionId: string) => {
        setSelectedSectionId(toSectionId);
        dismissModal(ModalTypes.CREATE_SECTION);
      },
    });
  }, [dismissModal, selectedKitId, showModal, space]);

  const onSubmit = useCallback(() => {
    callback({ selectedKit, fromSectionId, selectedSection, copyAs });
    dismissModal();
  }, [callback, copyAs, dismissModal, fromSectionId, selectedKit, selectedSection]);

  const renderListHeader = () => {
    if (!selectedKitId) {
      return <Text font="ui.regularSemiBold">All kits</Text>;
    }

    return (
      <Flex width="100%" justifyContent="space-between">
        <Button
          text={selectedKit.name}
          buttonStyle="tertiary"
          fontStyle="ui.regularSemiBold"
          themeOverrides={{ primaryColor: "black" }}
          iconBefore="navigation.chevron-left"
          onClick={clearSelection}
        />
        <Button
          text="Create section"
          buttonStyle="tertiary"
          icon="action.add"
          onClick={createSectionCallback}
        />
      </Flex>
    );
  };

  return (
    <Fragment>
      <ModalHeader title={`${isMoving ? "Move" : "Copy"} to`} />
      <ModalBody>
        <Flex mb="m" justifyContent="space-between" alignItems="center">
          {entityText && (
            <Text font="ui.regularBold">
              {verbActive} {entityText}
            </Text>
          )}
          {!isMoving && (
            <Flex alignItems="center">
              <Text mr="s">Copy as:</Text>
              <Select
                options={[
                  {
                    value: AssetCopyTypes.reference,
                    label: "Reference",
                    labelNode: (
                      <ReferenceOption
                        title={"Reference"}
                        body={
                          "References are copies linked to the original asset. Any changes to these assets are reflected in all references."
                        }
                      />
                    ),
                  },
                  {
                    value: AssetCopyTypes.duplicate,
                    label: "Duplicate",
                    labelNode: (
                      <ReferenceOption
                        title={"Duplicate"}
                        body={"Duplicates are new assets copied from the original."}
                      />
                    ),
                  },
                ]}
                onChange={val => setCopyAs(AssetCopyTypes[val])}
                size="small"
                buttonStyle="dropdown"
                value={copyAs}
              />
            </Flex>
          )}
        </Flex>
        <Box border="default" borderRadius="default">
          <Box px="m" py="s" borderBottom="default">
            {renderListHeader()}
          </Box>
          <Box p="m">
            {!selectedKitId &&
              kits?.map(k => (
                <OptionCard
                  key={k.kitId}
                  size="small"
                  onClick={() => setSelectedKitId(k.kitId)}
                  title={k.name || "Untitled kit"}
                  cardStyle="disclosure"
                  image={{
                    src: k.images.cover,
                    icon: "content.kit",
                    style: "rounded",
                  }}
                />
              ))}
            {selectedKitId &&
              selectedKitSections?.map(s => {
                return (
                  <OptionCard
                    key={s.id}
                    size="small"
                    onClick={() => setSelectedSectionId(s.id)}
                    title={s.name || "Untitled section"}
                    cardStyle="radio"
                    selected={selectedSectionId === s.id}
                    /**
                     * Can't "move" to the same section.
                     */
                    disabled={isMoving && s.id === fromSectionId}
                  />
                );
              })}
          </Box>
        </Box>
      </ModalBody>
      <ModalFooter
        primary={{
          text: buttonText,
          onClick: onSubmit,
          disabled: !selectedSectionId,
        }}
      />
    </Fragment>
  );
};

export default MoveCopyToSectionModal;
