import { API, AnyObject } from "@thenounproject/lingo-core";
import { createAsyncAction } from "@actions/actionCreators";

type Args = {
  itemId: string;
  updates: AnyObject;
};

const [useUpdateItem, updateItem] = createAsyncAction(
  "items/update",
  async ({ itemId, updates }: Args) =>
    await API.call<string>({
      endpoint: `items/${itemId}`,
      method: "PUT",
      entity: API.Entity.item,
      data: updates,
    })
);

export default useUpdateItem;
export { updateItem };
