import { API, APIResultList } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  data: { uuid: string; display_order: number }[];
};

const [useBatchUpdateSections, batchUpdateSections] = createAsyncAction(
  "sections/batchUpdate",
  async ({ data }: Args) => {
    return await API.call<APIResultList<"sections", string>>({
      endpoint: "sync/sections",
      method: "POST",
      data: { sections: data },
      entity: API.Entity.section,
    });
  }
);

export default useBatchUpdateSections;
export { batchUpdateSections };
