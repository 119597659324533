import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  sectionId: string;
  kitId: string;
  // It would be cool if we could append this to the action from state, but createAsyncAction won't let us define generic Meta.
  fromKitId: string;
};

const [useMoveSection, moveSection] = createAsyncAction(
  "sections/move",
  async ({ sectionId, kitId }: Args) => {
    return await API.call<string>({
      endpoint: `/sections/${sectionId}/move`,
      method: "PUT",
      entity: API.Entity.section,
      data: { kit_uuid: kitId },
    });
  }
);

export default useMoveSection;
export { moveSection };
