import React, { useCallback } from "react";
import { Text, Input, CustomField, CustomFieldOperation, Asset } from "@thenounproject/lingo-core";

import useAssetsCustomFieldUpdate from "@redux/actions/assets/useAssetsCustomFieldUpdate";
import { validateNumber } from "@helpers/validateNumber";
import { sanitizeNumberInput } from "@helpers/sanitizeNumberInput";

export interface Props {
  field: CustomField;
  updateCustomFieldValue: ReturnType<typeof useAssetsCustomFieldUpdate>[0];
  assets: Asset[];
  canEdit: boolean;
  assetUuids: string[];
}

export default function NumberCustomField({
  field,
  updateCustomFieldValue,
  assets,
  canEdit,
  assetUuids,
}: Props) {
  const assetFieldValues = assets.map(asset => asset.fields?.[field.id]) as string[];
  const fieldValuesAreEqual =
    assetFieldValues.every(fieldValue => fieldValue === assetFieldValues[0]) &&
    assetFieldValues[0] !== undefined;

  // Keep the variable stored as string
  // Number inputs don't provide reasonable ways to handle decimals & exponents
  const [value, setValue] = React.useState<string>(
    fieldValuesAreEqual ? String(assetFieldValues[0]) || "" : ""
  );
  const [error, setError] = React.useState<string>();

  React.useEffect(() => {
    setError(undefined);
    if (!fieldValuesAreEqual) {
      setValue("");
    } else if (String(assetFieldValues[0]) !== value && fieldValuesAreEqual) {
      setValue(String(assetFieldValues[0]) || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assets]);

  React.useEffect(() => {
    setError(undefined);
  }, [value]);

  const handleValueUpdate = useCallback(
    async (fieldId: number, value: string) => {
      if (!validateNumber(value)) {
        setError("Please enter a valid number");
        return;
      }
      const { error } = await updateCustomFieldValue({
        assetUuids,
        fieldId,
        value: value || null,
        operation: CustomFieldOperation.set,
      });
      if (error) {
        setError(error.message);
      }
    },
    [assetUuids, updateCustomFieldValue]
  );

  const handleSave = useCallback(async () => {
    if (fieldValuesAreEqual && assetFieldValues.includes(value)) return;
    await handleValueUpdate(field.id, value);
  }, [assetFieldValues, field.id, handleValueUpdate, value, fieldValuesAreEqual]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(sanitizeNumberInput(e.target.value));
  }, []);

  if (!canEdit) {
    return (
      <Text font="ui.small">{!fieldValuesAreEqual ? "Varied" : assetFieldValues[0] || "None"}</Text>
    );
  }

  return (
    <Input
      data-testid="inspector-number-field-multi"
      size="small"
      placeholder={"Add a number"}
      value={value}
      onChange={handleChange}
      onSubmit={handleSave}
      onBlur={handleSave}
      type={"text"}
      inputStyle={error ? "error" : null}
      message={error}
    />
  );
}
