import React, { Fragment, useState, useCallback } from "react";
import {
  Input,
  Flex,
  Notice,
  AssetView,
  Text,
  buildURL,
  Checkbox,
  useNavigation,
} from "@thenounproject/lingo-core";

import ModalHeader from "../../../components/ModalHeader";
import ModalFooter from "../../../components/ModalFooter";
import ModalBody from "../../../components/ModalBody";

import { useSelectSpace } from "@selectors/entities/spaces";

import useNotifications from "@actions/useNotifications";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import { useAssetLibraryContext } from "@contexts/AssetLibraryProvider";
import useAssetViewsCreate from "@redux/actions/views/useCreateView";
import useAssetViewsUpdate from "@redux/actions/views/useUpdateView";

type Props = {
  view?: AssetView;
};

const CreateEditAssetViewModal: React.FC<Props> = ({ view: renamingView }) => {
  const space = useSelectSpace();
  const { appliedFilters, activeView } = useAssetLibraryContext();

  const { showNotification } = useNotifications();
  const { dismissModal } = useShowModal();
  const navigation = useNavigation();

  const [createView, createStatus] = useAssetViewsCreate();
  const [updateView, updateStatus] = useAssetViewsUpdate();
  const error = createStatus.error || updateStatus.error;
  const isProcessing = createStatus.isProcessing || updateStatus.isProcessing;

  const [name, setName] = useState<string>(renamingView?.name || "");

  const [assignToExistingView, setAssignToExistingView] = useState<boolean>(Boolean(activeView));

  React.useEffect(() => {
    createStatus.reset();
    updateStatus.reset();
  }, [createStatus, name, updateStatus]);

  const handleCreateView = useCallback(async () => {
    const res = await createView({
      spaceId: space.id,
      name,
      filters: appliedFilters,
    });
    if (res.isSuccess) {
      showNotification({ message: "Asset view created" });
      navigation.push(buildURL(`/library`, { space }, { view: res.response.result }));
      dismissModal();
    }
  }, [appliedFilters, createView, dismissModal, navigation, name, showNotification, space]);

  const handleSaveToExistingView = useCallback(async () => {
    const res = await updateView({
      spaceId: space.id,
      viewId: activeView.id,
      updates: { name: activeView.name, filters: appliedFilters },
    });
    if (res.isSuccess) {
      showNotification({ message: "Asset view updated" });
      dismissModal();
    }
  }, [
    activeView?.id,
    activeView?.name,
    appliedFilters,
    dismissModal,
    showNotification,
    space.id,
    updateView,
  ]);

  const handleRenameView = useCallback(async () => {
    const res = await updateView({
      spaceId: space.id,
      viewId: renamingView.id,
      updates: { name: name, filters: renamingView.filters },
    });
    if (res.isSuccess) {
      showNotification({ message: "Asset view renamed" });
      dismissModal();
    }
  }, [
    name,
    updateView,
    space.id,
    renamingView?.id,
    renamingView?.filters,
    showNotification,
    dismissModal,
  ]);

  const onSubmit = useCallback(
    async (e?: React.FormEvent) => {
      e?.preventDefault();
      if (isProcessing) return;

      if (!renamingView && assignToExistingView) {
        await handleSaveToExistingView();
      }
      if (!renamingView && !assignToExistingView) {
        await handleCreateView();
      }
      if (renamingView) {
        await handleRenameView();
      }
    },
    [
      assignToExistingView,
      handleCreateView,
      handleRenameView,
      handleSaveToExistingView,
      isProcessing,
      renamingView,
    ]
  );

  const buttonText = renamingView ? "Rename" : "Save";
  const headerText = renamingView ? "Rename View" : "Save View";

  const renderCreateFields = () => {
    if (renamingView) return null;

    return (
      <>
        {activeView && (
          <>
            <Flex>
              <Checkbox
                type="radio"
                isSelected={assignToExistingView}
                onClick={() => setAssignToExistingView(true)}
                label={
                  <Text>
                    Save changes to{" "}
                    <Text as="span" font="ui.regularBold">
                      {activeView.name}
                    </Text>
                  </Text>
                }
              />
            </Flex>
            <Flex>
              <Checkbox
                id="create-new-view"
                type="radio"
                isSelected={!assignToExistingView}
                onClick={() => setAssignToExistingView(false)}
                label={<Text>Create new view</Text>}
              />
            </Flex>
          </>
        )}
        {!assignToExistingView && (
          <Input
            id="view-name"
            label="Name"
            value={name}
            placeholder="Enter a view name..."
            onChange={e => {
              setName(e.target.value);
            }}
            onSubmit={onSubmit}
            autoFocus
          />
        )}
      </>
    );
  };

  const renderUpdateFields = () => {
    if (!renamingView) return null;
    return (
      <>
        <Input
          id="view-name"
          label="Name"
          value={name}
          placeholder="Enter a view name..."
          onChange={e => {
            setName(e.target.value);
          }}
          onSubmit={onSubmit}
        />
      </>
    );
  };

  return (
    <Fragment>
      <ModalHeader title={headerText} />
      <ModalBody>
        <Flex flexDirection="column" gap="16px">
          {renderCreateFields()}
          {renderUpdateFields()}
          {error && <Notice noticeStyle="error" message={error.message} mt="24px" />}
        </Flex>
      </ModalBody>
      <ModalFooter
        primary={{
          text: buttonText,
          onClick: onSubmit,
          disabled: isProcessing,
        }}
      />
    </Fragment>
  );
};

export default CreateEditAssetViewModal;

export const useShowCreateEditAssetViewModal = () => {
  const { showModal } = useShowModal();
  return useCallback(
    (componentProps?: Props) => {
      showModal(ModalTypes.CREATE_EDIT_ASSET_VIEW, componentProps);
    },
    [showModal]
  );
};
