import React, { useEffect, useState, useCallback } from "react";
import QueryString from "query-string";

import {
  Box,
  buildURL,
  Text,
  Button,
  ActivityIndicator,
  useNavigation,
} from "@thenounproject/lingo-core";

import { AuthContainer, AuthContent } from "../auth/AuthElements";
import EmptyState from "../EmptyState";
import useCurrentUser from "@queries/useCurrentUser";
import useFetchMagicToken from "@actions/auth/useFetchMagicToken";

const schemes = {
  alpha: "lingo-alpha://",
  beta: "lingo-beta://",
  prod: "lingo://",
};

enum State {
  loading = "loading",
  error = "error",
  opening = "opening",
}

function AuthMac() {
  const [state, setState] = useState(State.loading);
  const [error, setError] = useState<string>();

  const params = QueryString.parse(window.location.search) || {};
  const appScheme = schemes[params.app as string] ?? schemes.prod;

  const navigation = useNavigation();

  const { user } = useCurrentUser();
  const [fetchMagicToken] = useFetchMagicToken();

  const openApp = useCallback(async () => {
    setState(State.loading);
    const res = await fetchMagicToken({ encrypt: true });
    if (res.error) {
      setState(State.error);
      setError(res.error.message);
      return null;
    }
    setState(State.opening);
    const { token } = res.response.result;
    const appLink = `${appScheme}login?token=${token}`;
    window.open(appLink, "_self");
  }, [appScheme, fetchMagicToken]);

  useEffect(() => {
    if (user.isFetching === false && !user.id) {
      const url = buildURL("/login/mac", {}, { app: params.app });
      return navigation.replace(buildURL("/login", {}, { next: url }));
    } else if (user.id) {
      void openApp();
    }
  }, [navigation, openApp, params.app, user.id, user.isFetched, user.isFetching]);

  // MARK : Rendering
  // -------------------------------------------------------------------------------

  function renderContent() {
    switch (state) {
      case State.loading:
        return (
          <Box>
            <ActivityIndicator height={"150px"} center />
          </Box>
        );
      case State.error:
        return (
          <EmptyState
            title="Something went wrong"
            subtitle={error}
            size="large"
            extra={
              <>
                <Button link={"/space"} mt="l" text={"Go to Spaces"} />
                <Text mt="l" font="ui.small">
                  If you need help, please{" "}
                  <Button
                    buttonStyle="tertiary"
                    size="small"
                    text="contact us."
                    link="mailto:info@lingoapp.com"
                  />
                </Text>
              </>
            }
          />
        );
      case State.opening:
        return (
          <EmptyState
            title="Opening Lingo..."
            subtitle="The app should open in a few seconds."
            size="large"
            extra={
              <>
                <Text mt="l" font="ui.small">
                  If it does not,{" "}
                  <Button
                    buttonStyle="tertiary"
                    size="small"
                    text="contact us"
                    link="mailto:info@lingoapp.com"
                  />{" "}
                  or continue to your account{" "}
                  <Button buttonStyle="tertiary" size="small" text="in the browser" link="/space" />
                </Text>
              </>
            }
          />
        );
    }
  }
  return (
    <AuthContainer>
      <AuthContent withHeader styleOverrides={{ flexDirection: "column", textAlign: "center" }}>
        {renderContent()}
      </AuthContent>
    </AuthContainer>
  );
}

export default AuthMac;
