/**
 * Inserts the Lingo app Root component into the DOM.
 */

import "intersection-observer";

// Reset/global css, self-hosted fonts
import "./styles/reset.css";
import "./styles/fonts/stylesheet.css";

import React from "react";
import { createRoot } from "react-dom/client";

import Root from "./root";
import configureSentry from "./adapters/sentry";

try {
  configureSentry();
} catch (e) {
  console.warn("Unable to initialize Sentry client.", e);
}

const appEl = document.getElementById("root");

if (appEl) {
  const root = createRoot(appEl);
  root.render(<Root />);
}
