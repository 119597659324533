import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  token: string;
};

const [useLinkGoogle, linkGoogle] = createAsyncAction("auth/linkGoogle", async (args: Args) => {
  return await API.call<{ user: number }>({
    endpoint: `auth/google/link-account/${args.token}`,
    method: "POST",
    entity: API.Entity.user,
  });
});
export default useLinkGoogle;
export { linkGoogle };
