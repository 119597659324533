/**
 * https://flaviocopes.com/javascript-swap-array-elements/
 */
import _cloneDeep from "lodash/cloneDeep";

export default function swapArrayItems<T>(array: T[], item1: T, item2: T) {
  const a = _cloneDeep(array);
  const firstIndex = a.indexOf(item1);
  const secondIndex = a.indexOf(item2);
  [a[firstIndex], a[secondIndex]] = [a[secondIndex], a[firstIndex]];
  return a;
}
