import { API, PortalItem } from "@thenounproject/lingo-core";

import { createQueryAction } from "@actions/actionCreators";

type Args = {
  portalId: string;
};

type Result = {
  portalItems: PortalItem[];
  total: number;
};

type NormalizedResult = {
  portalItems: string[];
  total: number;
};

const [usePortalItems, queryPortalItems] = createQueryAction<Args, Result, NormalizedResult>(
  {
    entity: "portalItems",
    action: "query",
    denormalize: {
      entity: null,
      portalItems: {
        entity: "portalItems",
        asset: "assets",
        kit: "kits",
      },
    },
    condition: ({ args: { portalId } }) => Boolean(portalId),
  },
  async ({ args, paging }, thunkApi) => {
    let offset = 0;
    if (paging.page > 1) {
      const queries = thunkApi.getState().entities.portalItems.queries;
      offset = queryPortalItems.getQueryData(queries, args)[0]?.data?.portalItems.length || 0;
    }

    return await API.call<{ portalItems: string[]; total: number }>({
      endpoint: `portals/${args.portalId}/items`,
      method: "GET",
      entity: API.Entity.portalItem,
      query: {
        limit: 100,
        offset,
      },
    });
  }
);

export default usePortalItems;
export { queryPortalItems };
