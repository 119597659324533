/**
 * Custom fields reducer
 */

import _merge from "lodash/merge";
import _remove from "lodash/remove";

import { PortalItem } from "@thenounproject/lingo-core";

import createEntityReducer from "../helpers/createEntityReducer";
import { getEntities } from "../helpers/getEntities";
import { createPortalItems } from "@redux/actions/portalItems/useCreatePortalItems";
import { queryPortalItems } from "@redux/actions/portalItems/usePortalItems";
import { reorderPortalItems } from "@redux/actions/portalItems/useReorderPortalItems";
import { deletePortalItem } from "@redux/actions/portalItems/useDeletePortalItem";
import { deletePortalItems } from "@redux/actions/portalItems/useDeletePortalItems";
import { uploadFile } from "@redux/actions/uploads/uploadFile";

export default createEntityReducer<PortalItem>(
  "portalItems",
  queryBuilder => {
    queryBuilder
      .addCase(createPortalItems.fulfilled, (state, action) => {
        const { result, insertIndex } = action.payload;

        const successfulItems = result.portalItems
          .filter(res => res.success)
          .map(res => res.result);
        let indexCount = insertIndex;
        queryPortalItems.getQueryData(state, { portalId: action.meta.arg.portalId }).forEach(q => {
          q.data.portalItems.splice(indexCount, 0, ...successfulItems);
          q.data.total += 1;
          indexCount++;
        });
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        const { result, entities } = action.payload;
        const portalItem = entities?.portalItems?.[result];
        if (portalItem) {
          const { displayOrder, portalId, insertIndex } = action.meta.arg.upload.insertPosition;
          if (!displayOrder) {
            // For replacing an existing file, we don't need to update the display order
            return;
          }
          queryPortalItems.getQueryData(state, { portalId }).forEach(q => {
            const portalItems = q.data.portalItems;
            q.data.total += 1;

            if (displayOrder === "append") {
              if (portalItems.length === q.data.total - 1) {
                portalItems.push(portalItem.id);
              }
            } else if (insertIndex !== undefined) {
              portalItems.splice(insertIndex, 0, portalItem.id);
            } else {
              const [direction, targetId] = displayOrder.split(":");
              let spliceIndex = portalItems.indexOf(`${targetId}-0`);
              if (direction === "after") spliceIndex++;
              portalItems.splice(spliceIndex, 0, portalItem.id);
            }
          });
        }
      })
      .addCase(reorderPortalItems.fulfilled, (state, action) => {
        const { portalId } = action.meta.arg;
        const { reorderedPortalItemIds } = action.payload;

        queryPortalItems.getQueryData(state, { portalId }).forEach(query => {
          query.data.portalItems = reorderedPortalItemIds;
        });
      })
      .addCase(deletePortalItem.fulfilled, (state, action) => {
        const { portalItemId, portalId } = action.meta.arg;
        queryPortalItems.getQueryData(state, { portalId }).forEach(query => {
          const removed = _remove(query.data.portalItems, item => item === portalItemId);
          query.data.total -= removed.length;
        });
      })
      .addCase(deletePortalItems.fulfilled, (state, action) => {
        const { portalId } = action.meta.arg;
        const portalItems = action.payload.result.portalItems;
        const removedItems = new Set(portalItems.filter(res => res.success).map(res => res.result));
        queryPortalItems.getQueryData(state, { portalId }).forEach(query => {
          const removed = _remove(query.data.portalItems, id => removedItems.has(id));
          query.data.total -= removed.length;
        });
      });
  },
  objectBuilder => {
    objectBuilder.addDefaultCase((state, action) => {
      const portalItems = getEntities(action)?.portalItems;
      if (portalItems) {
        return _merge(state, portalItems);
      }
    });
  }
);
