/* eslint-disable complexity */
import { Item, ItemType, Section } from "@thenounproject/lingo-core";
import _without from "lodash/without";
import _cloneDeep from "lodash/cloneDeep";

import { InsertType } from "../constants/InsertType";
import { GalleryOutline, getGalleryOutline } from "./gallery";
import swapArrayItems from "./swapArrayItems";
import { Args } from "@redux/actions/items/useReorderSectionItems";
import { InsertConfig, NewItem } from "@redux/actions/items/useBatchSaveItems";
import { getVersionedItemId } from "./items";

/**
 * Validates the layout of an array of gallery items.
 * Looks for standalone half guides & updates them.
 * Returns { valid: Boolean, updates: [{uuid, data}]} }
 * @param {Array} items Array of items [{uuid, type, data}]
 */
export function validateLayout(items: Item[]) {
  const updates = [],
    proxiedItems = _cloneDeep(items);
  let currentGrouping = [];

  /**
   * These bool returning functions are written as such so that they can be used
   * in the context of the forEach function below, as well as the final check on
   * whatever grouping is left.
   */

  // Wes - I'm not 100% sure why these were setup to handle both displaySize and display_size, I left it into to avoid breakage but it seems like it could be simplified
  type ModifiedItem = Item & { data: { display_size?: number } };
  function groupingHasSingleHalfGuide(grouping: ModifiedItem[]): boolean {
    return (
      grouping.length === 1 &&
      grouping[0].type === ItemType.guide &&
      (grouping[0].data.displaySize > 0 || grouping[0].data.display_size > 0)
    );
  }

  function itemIsFullGuide(item: ModifiedItem) {
    if (!item) return false;
    return (
      item.type === ItemType.guide && (item.data.displaySize < 2 || item.data.display_size < 2)
    );
  }

  function itemIsHalfGuide(item: ModifiedItem) {
    if (!item) return false;
    return (
      item.type === ItemType.guide && (item.data.displaySize >= 2 || item.data.display_size >= 2)
    );
  }

  /**
   * Loop over the items in the array & construct groupings based on item type.
   */
  proxiedItems.forEach((item, index) => {
    const currentItemIsFullGuide = itemIsFullGuide(item),
      currentItemIsHalfGuide = itemIsHalfGuide(item),
      previousItem = proxiedItems[index - 1];

    /**
     * If the current item is a full guide or not a guide, it resets the grouping.
     * Check current grouping for an uneven grouping configuration & push any required updates.
     */
    if (item.type !== ItemType.guide || currentItemIsFullGuide) {
      if (groupingHasSingleHalfGuide(currentGrouping)) {
        updates.push({ uuid: currentGrouping[0].id, data: { display_size: 0 } });
        previousItem.data.displaySize = 0;
      }
      currentGrouping = [];
      /**
       * If the current item is a half guide, it will either:
       *  A. Complete a pair, in which case it resets the grouping
       *  B. Start a new grouping as the first guide in a potential pair
       */
    } else if (currentItemIsHalfGuide) {
      if (groupingHasSingleHalfGuide(currentGrouping)) {
        currentGrouping = [];
      } else currentGrouping = [item];
    }
  });

  /**
   * Finally, if the last group is uneven push an update
   */
  if (groupingHasSingleHalfGuide(currentGrouping)) {
    updates.push({ uuid: currentGrouping[0].id, data: { display_size: 0 } });
  }

  return {
    valid: !updates.length,
    updates,
  };
}

/**
 * Mutates an array of section items for a reorder
 * @param {Object} config object containing itemIds, displayOrder, guidePosition, dragPosition, validSelfDrop, reorderItemGuidePosition, reorderItemIndex
 * @param {Object} section section from redux state
 * @param {Array} items items from redux state
 */

export function mutateLayoutByReorder(
  { reorderItemIds, displayOrder, guidePosition, dragPosition, validSelfDrop }: Args,
  sectionItems: Item[]
) {
  const sectionItemIds = sectionItems.map(getVersionedItemId),
    proxiedItems = _cloneDeep(sectionItems),
    itemIndex = proxiedItems.reduce(
      (acc, item) => {
        acc[getVersionedItemId(item)] = item;
        return acc;
      },
      {} as Record<string, Item>
    );
  let proxiedDisplayOrder = displayOrder,
    updates = [],
    layout = [];

  /**
   * The reorderItemIds can be in any order as selected by the user.
   * If there is more than one item being reordered, make sure the
   * order is maintained by sorting them by their index in current state.
   */
  const orderedReorderItemIds = _cloneDeep(reorderItemIds);
  if (orderedReorderItemIds.length > 1) {
    const indexMap = sectionItemIds.reduce((acc, id, idx) => {
      acc[id] = idx;
      return acc;
    }, {});
    orderedReorderItemIds.sort((a, b) => indexMap[a] - indexMap[b]);
  }

  const galleryOutline = getGalleryOutline(proxiedItems);
  const [direction, targetId] = displayOrder.split(":");
  const versionedTargetId = `${targetId}-0`;
  let reorderedSectionItemIds = _without(sectionItemIds, ...orderedReorderItemIds);
  let index = reorderedSectionItemIds.indexOf(versionedTargetId);
  if (direction === "after") index += 1;

  const droppingOnGuide = Boolean(guidePosition);
  const droppingOnFullGuide = guidePosition === "full";
  const droppingOnHalfGuide = ["left", "right"].includes(guidePosition);
  const someReorderItemsAreGuides = orderedReorderItemIds.some(
    id => itemIndex[id].type === ItemType.guide
  );
  const singleItemBeingReordered = orderedReorderItemIds.length === 1;
  const firstReorderItem = itemIndex[orderedReorderItemIds[0]];
  const reorderingSingleGuide =
    singleItemBeingReordered && firstReorderItem.type === ItemType.guide;
  const reorderingSingleHalfGuide = reorderingSingleGuide && firstReorderItem.data.displaySize > 1;

  const guidePartnerDrop = Boolean(
    reorderingSingleGuide &&
      galleryOutline[firstReorderItem.id] &&
      galleryOutline[firstReorderItem.id].guidePairId === targetId
  );

  /**
   * If we're not dropping a guide on itself or its partner,
   * adjust the insert index in the following cases:
   */
  if (!validSelfDrop || !guidePartnerDrop) {
    if (dragPosition === "top" && guidePosition === "right") {
      proxiedDisplayOrder = `before:${galleryOutline[targetId].guidePairId}`;
      index -= 1;
    }
    if (dragPosition === "bottom" && guidePosition === "left" && !guidePartnerDrop) {
      proxiedDisplayOrder = `after:${galleryOutline[targetId].guidePairId}`;
      index += 1;
    }
  }

  /**
   * If the drop target happens to be in the items being reordered,
   * we need to calculate the insert index based on its initial position
   * & subtract by however many items before it are also being reordered.
   */
  if (orderedReorderItemIds.includes(versionedTargetId)) {
    index = sectionItemIds.indexOf(versionedTargetId);
    orderedReorderItemIds.forEach(itemId => {
      if (itemId === versionedTargetId) return;
      if (sectionItemIds.indexOf(itemId) < sectionItemIds.indexOf(versionedTargetId)) {
        index -= 1;
      }
    });
  }
  reorderedSectionItemIds.splice(index, 0, ...orderedReorderItemIds);

  /**
   * There are a handful of cases we're watching for at the highest level:
   * 1. No half guides involved in the reorder or drop
   * 2. Multiple items being reordered with guides involved
   * 3. Single guide is being reordered onto non-guide
   * 4. Single guide is being reordered onto another guide
   */

  /**
   * 1. No half guides involved in the reorder or drop:
   * Just run the splice and return the result.
   */
  if ((!droppingOnGuide || droppingOnFullGuide) && !someReorderItemsAreGuides) {
    return { reorderedSectionItemIds, updates, displayOrder: proxiedDisplayOrder };
  }

  /**
   * 2. Multiple items being reordered with guides involved:
   * Run the splice, then run the new layout through validateLayout for any updates
   */
  if (!singleItemBeingReordered && someReorderItemsAreGuides) {
    layout = reorderedSectionItemIds.map(itemId => itemIndex[itemId]);
    updates = validateLayout(layout).updates;
    return { reorderedSectionItemIds, updates, displayOrder: proxiedDisplayOrder };
  }

  /**
   * 3. Single guide is being reordered onto non-guide:
   * Check if it's a half guide, update it and its former partner
   */
  if (reorderingSingleGuide && !droppingOnGuide) {
    if (galleryOutline[firstReorderItem.id]) {
      updates.push({ uuid: firstReorderItem.id, data: { display_size: 0 } });
      updates.push({
        uuid: galleryOutline[firstReorderItem.id].guidePairId,
        data: { display_size: 0 },
      });
    }
    return { reorderedSectionItemIds, updates, displayOrder: proxiedDisplayOrder };
  }

  /**
   * 4. Single guide is being reordered onto another guide:
   * There are a few cases to look out for
   *  A. Self-drop: dropping a guide on itself
   *  B. Partner-drop: dropping a guide somewhere on it's partner
   *  C. Moving any guide onto half guide
   *  D. Moving any guide onto a full guide
   */

  /*  A. Self-drop: dropping a guide on itself */
  if (validSelfDrop) {
    // top of right guide
    if (dragPosition === "top" && guidePosition === "right") {
      reorderedSectionItemIds = swapArrayItems(
        reorderedSectionItemIds,
        targetId.concat("-0"),
        galleryOutline[targetId].guidePairId.concat("-0")
      );
    }
    // bottom of left guide
    if (dragPosition === "bottom" && guidePosition === "left") {
      reorderedSectionItemIds = swapArrayItems(
        reorderedSectionItemIds,
        targetId.concat("-0"),
        galleryOutline[targetId].guidePairId.concat("-0")
      );
    }
    updates.push({ uuid: targetId, data: { display_size: 0 } });
    updates.push({ uuid: galleryOutline[targetId].guidePairId, data: { display_size: 0 } });
    return { reorderedSectionItemIds, updates, displayOrder: proxiedDisplayOrder };
  }

  /*  B. Partner-drop: dropping a guide somewhere on it's partner  */
  if (guidePartnerDrop) {
    if (
      (dragPosition === "left" && guidePosition === "right") ||
      (dragPosition === "right" && guidePosition === "left")
    ) {
      return null;
    }

    if (
      (dragPosition === "top" && guidePosition === "right") ||
      (dragPosition === "bottom" && guidePosition === "right") ||
      (dragPosition === "top" && guidePosition === "left") ||
      (dragPosition === "bottom" && guidePosition === "left")
    ) {
      updates.push({ uuid: targetId, data: { display_size: 0 } });
      updates.push({ uuid: galleryOutline[targetId].guidePairId, data: { display_size: 0 } });
    }
    /**
     * In these cases, nothing is being reordered
     */
    if (
      (dragPosition === "top" && guidePosition === "right") ||
      (dragPosition === "bottom" && guidePosition === "left")
    ) {
      reorderedSectionItemIds = sectionItemIds;
    }
    return { reorderedSectionItemIds, updates, displayOrder: proxiedDisplayOrder };
  }

  /**
   * Dropping single guides on other guides:
   * 1. If we're reordering a half guide, we need to update its former partner
   * 2. If we drop on a full guide, pair them
   * 3. If we drop on a half guide, create the new pair, update the former partner
   */
  if (reorderingSingleGuide && droppingOnGuide) {
    /**
     * 1. If we're moving a half guide, update its partner
     */
    if (reorderingSingleHalfGuide) {
      updates.push({
        uuid: galleryOutline[firstReorderItem.id].guidePairId,
        data: { display_size: 0 },
      });
    }

    /**
     * 2. If we're dropping on a full guide
     */
    if (droppingOnFullGuide) {
      if (["top", "bottom"].includes(dragPosition)) {
        layout = reorderedSectionItemIds.map(itemId => itemIndex[itemId]);
        updates = validateLayout(layout).updates;
        return { reorderedSectionItemIds, updates, displayOrder: proxiedDisplayOrder };
      } else {
        updates.push({
          uuid: targetId,
          data: { display_size: 2 },
        });
        updates.push({
          uuid: firstReorderItem.id,
          data: { display_size: 2 },
        });
        return { reorderedSectionItemIds, updates, displayOrder: proxiedDisplayOrder };
      }
    }

    /**
     * 3. If we're dropping on a half guide
     */
    if (droppingOnHalfGuide) {
      if (["top", "bottom"].includes(dragPosition)) {
        layout = reorderedSectionItemIds.map(itemId => itemIndex[itemId]);
        updates = validateLayout(layout).updates;
        return { reorderedSectionItemIds, updates, displayOrder: proxiedDisplayOrder };
      } else {
        updates.push({
          uuid: galleryOutline[targetId].guidePairId,
          data: { display_size: 0 },
        });
        updates.push({
          uuid: targetId,
          data: { display_size: 2 },
        });
        updates.push({
          uuid: firstReorderItem.id,
          data: { display_size: 2 },
        });
        return { reorderedSectionItemIds, updates, displayOrder: proxiedDisplayOrder };
      }
    }
  }

  /**
   * Catch all case - anything else at this point, just validate the layout
   */
  layout = reorderedSectionItemIds.map(itemId => itemIndex[itemId]);
  updates = validateLayout(layout).updates;
  return { reorderedSectionItemIds, updates, displayOrder: proxiedDisplayOrder };
}

/**
 * Mutates an array of section items for an update
 * @param {Object} config object containing array of updated items
 * @param {Object} section section from redux state
 * @param {Array} items items from redux state
 */
type MutateLayoutByUpdatesConfig = {
  updatedItems: DeepPartial<Item>[];
  galleryOutline: GalleryOutline;
};

export function mutateLayoutByUpdates(
  { updatedItems, galleryOutline }: MutateLayoutByUpdatesConfig,
  section: Section,
  items: Record<string, Item>
) {
  /**
   * Build the current layout based on state
   */
  const sectionItemIds = _cloneDeep(section.items),
    proxiedItems = _cloneDeep(items),
    updates = [];
  let layout: (Item | NewItem)[] = sectionItemIds.map(id => proxiedItems[id]);

  /**
   * Push any updates to the layout items array if they are in updatedItems
   */
  layout = layout.map(item => {
    const updatedItem = updatedItems.find(_updatedItem => _updatedItem.id === item.id);

    if (updatedItem) {
      return {
        ...item,
        ...updatedItem,
        data: {
          ...item.data,
          ...updatedItem.data,
        },
      } as Item;
    } else return item;
  });

  /**
   * Cases that require updates to surrounding items:
   * - Deleting 1 half of a paired guide set
   */
  const deletedItems = updatedItems.filter(item => item.status === "trashed");

  /* - Deleting 1 half of a paired guide set */
  if (deletedItems.length) {
    const deletedItemIds = deletedItems.map(item => item.id);
    deletedItems.forEach(item => {
      if (
        galleryOutline[item.id] &&
        galleryOutline[item.id].guidePairId &&
        !deletedItemIds.includes(galleryOutline[item.id].guidePairId)
      ) {
        updates.push({
          uuid: galleryOutline[item.id].guidePairId,
          data: {
            display_size: 0,
          },
        });
      }
    });
  }

  /**
   * Filter out any deleted items & return
   */
  layout = layout.filter(item => !["trashed"].includes(item.status));
  return { layout, updates };
}

/**
 * Mutates an array of section items for an insert
 * @param {Object} config object containing newItems, insertIndex, targetIndex, insertType, guidePosition, dragPosition
 * @param {Object} items The section items that the new items will be inserted into
 */
export function mutateLayoutByInserts(
  {
    newItems = [],
    insertIndex,
    targetIndex,
    insertType,
    guidePosition,
    dragPosition,
  }: InsertConfig,
  sectionItems: Item[]
) {
  /**
   * Build the current layout based on state
   */

  const layout: (Item | NewItem)[] = _cloneDeep(sectionItems),
    updates: Partial<NewItem>[] = _cloneDeep(newItems);

  /**
   * Cases that require updates to surrounding items:
   * - Insert guide as a pair on a full guide
   * - Insert guide on horizontal sides of already paired guides
   * - Insert non-guide in between two paired guides
   */

  const isSingleInsert = newItems.length === 1;
  const insertIsGuide = insertType === InsertType.guide;
  const targetIsFullGuide = guidePosition === "full";
  const targetIsRightGuide = guidePosition === "right";
  const targetIsLeftGuide = guidePosition === "left";
  const targetIsHalfGuide = targetIsRightGuide || targetIsLeftGuide;
  const dragIsRight = dragPosition === "right";
  const dragIsLeft = dragPosition === "left";
  const dragIsHorizontal = dragIsRight || dragIsLeft;

  /* - Insert guide as a pair on a full guide */
  if (isSingleInsert && targetIsFullGuide && insertIsGuide && dragIsHorizontal) {
    updates[0].data.display_size = 2;
    updates.push({
      uuid: layout[targetIndex].id,
      type: ItemType.guide,
      data: {
        display_size: 2,
      },
    });
  }

  /* - Insert guide on horizontal sides of already paired guides */
  if (isSingleInsert && targetIsHalfGuide && insertIsGuide && dragIsHorizontal) {
    updates[0].data.display_size = 2;

    if ((targetIsRightGuide && dragIsRight) || (targetIsRightGuide && dragIsLeft)) {
      updates.push({
        uuid: layout[targetIndex - 1].id,
        data: {
          display_size: 0,
        },
      });
    }
    if ((targetIsLeftGuide && dragIsLeft) || (targetIsLeftGuide && dragIsRight)) {
      updates.push({
        uuid: layout[targetIndex + 1].id,
        data: {
          display_size: 0,
        },
      });
    }
  }

  /* - Insert non-guide in between two paired guides */
  if (
    isSingleInsert &&
    !insertIsGuide &&
    ((targetIsRightGuide && dragIsLeft) || (targetIsLeftGuide && dragIsRight))
  ) {
    updates.push({
      uuid: layout[targetIndex].id,
      data: {
        display_size: 0,
      },
    });
    updates.push({
      uuid: layout[targetIsRightGuide ? targetIndex - 1 : targetIndex + 1].id,
      data: {
        display_size: 0,
      },
    });
  }

  /**
   * Insert new item(s) at a given index
   */
  layout.splice(insertIndex, 0, ...newItems);
  return { layout, updates };
}

/**
 * In most cases, a reorder in which a user drops the 1st item in selection on
 * itself is invalid, so we just stop the drag event.  This function will return true
 * for the special cases where dropping a reorder item on itself is valid.
 * @param {String} dragPosition top | bottom | left | right
 * @param {String} guidePosition right | left | full
 */
export function isValidSelfDropPosition(
  dragPosition: "top" | "bottom" | "left" | "right",
  guidePosition: "right" | "left" | "full"
) {
  return (
    (dragPosition === "top" && guidePosition === "right") ||
    (dragPosition === "bottom" && guidePosition === "left") ||
    (dragPosition === "bottom" && guidePosition === "right") ||
    (dragPosition === "top" && guidePosition === "left")
  );
}
