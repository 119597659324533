import { MarketingButtons } from "../../components/MarketingButtons";
import { PageContent } from "../../pages/templates/PageLayout";
import featureCards from "../featureCards";
import Images from "../images";
import { exampleLinks } from "./exampleLinks";

const data: PageContent = {
  style: "light",
  sections: [
    {
      type: "HeroSection",
      title: "Build better brands",
      subtitle: `Save time and deliver more value to your clients by using Lingo to build, manage and share digital style guides and brand systems. [See an example](${exampleLinks.agencies}).`,
      primaryButton: MarketingButtons.getStarted,
      secondaryButton: MarketingButtons.bookDemo,
      image: {
        src: Images.Solutions.brandGuidelines_hero,
        alt: "Brand guidelines",
      },
    },
    {
      type: "MediaSection",
      title: "No more stale PDF style guides for your clients",
      subtitle:
        "All the tools you need to create a centralized set of living brand guidelines that are easy to update and intuitive for your clients to use.",
      layoutDirection: "image-left",
      body: {
        style: "tabList",
        items: [
          {
            title: "A solution for all your clients",
            body: "Inside a single Lingo workspace you can create, manage and share an unlimited number of style guides and asset libraries.",
            image: {
              src: Images.Solutions.agencies_allClients,
              alt: "A solution for all your clients",
            },
          },
          {
            title: "Always up to date",
            body: "Need to make an update to a style guide? Simply make the change and you're done. No more sending countless versions as email attachments.",
            image: {
              src: Images.Solutions.agencies_upToDate,
              alt: "Always up to date",
            },
          },
          {
            title: "Automatic file conversion",
            body: "Lingo automatically converts files for you so you don't have to. Your clients will be able to find the file they need in the format and size they need, all without needing your help.",
            image: {
              src: Images.Solutions.agencies_fileConversion,
              alt: "Automatic file conversion",
            },
          },
          {
            title: "Affiliate program",
            body: "Make money by using Lingo. If you introduce Lingo to one of your clients, we'll share a percentage of revenue back with you!",
            image: {
              src: Images.Solutions.agencies_affiliateProgram,
              alt: "Affiliate program for agencies",
            },
          },
        ],
      },
    },
    {
      type: "CardSection",
      title: "More powerful features to manage your assets",
      footerButton: {
        link: "/product/features",
        text: "View all features",
        buttonStyle: "outline",
      },
      items: [
        featureCards.customBranding,
        featureCards.customDomains,
        featureCards.usageGuidelines,
      ],
    },
    { type: "CtaSection" },
  ],
};

export default data;
