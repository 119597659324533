import { useEffect, useState, useCallback } from "react";
import KeyCode from "../constants/keyCodes";

/**
 * Listen for non-inspector related clicks:
 * Anything a user needs to click on to clear inspector
 * should have [data-inspector-clear="true"] attr
 */
export const useClearInspectorOnClick = (clearInspector: () => void) => {
  useEffect(() => {
    function detectElementClickTarget(e: MouseEvent) {
      // Uncomment to debug more easily
      // console.log(e.target);
      if (e?.target instanceof HTMLElement && e.target.dataset.inspectorClear) {
        clearInspector();
      }
    }

    window.addEventListener("click", detectElementClickTarget);
    return () => {
      window.removeEventListener("click", detectElementClickTarget);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export function useShiftKey(): boolean {
  const [shiftDown, setShiftDown] = useState(false);
  // Make shift-selection feel more natural
  const handleShift = useCallback((event: KeyboardEvent) => {
    if (event.key === KeyCode.shift) {
      if (event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement)
        return;

      setShiftDown(event.shiftKey);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleShift, false);
    document.addEventListener("keyup", handleShift, false);
    return () => {
      document.removeEventListener("keydown", handleShift, false);
      document.removeEventListener("keyup", handleShift, false);
    };
  }, [handleShift]);

  return shiftDown;
}
