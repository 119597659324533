import React from "react";
import { Flex, SpaceTheme, Icon, Text, Button } from "@thenounproject/lingo-core";
import styled from "styled-components";
import { useDropzone } from "react-dropzone";

import { SpaceThemeImageOptions } from "@features/theming/types";
import useNotifications from "@actions/useNotifications";
import { useThemeDataContext } from "@contexts/ThemeDataProvider";

interface Props {
  themeImageOption: SpaceThemeImageOptions;
  newTheme: SpaceTheme;
  updateTheme: ReturnType<typeof useThemeDataContext>["updateEditingTheme"];
  updateFiles: ReturnType<typeof useThemeDataContext>["updateFiles"];
  sizeRecommendation?: string;
}

const Wrapper = styled(Flex).attrs({
  width: "100%",
  flexDirection: "column",
})`
  cursor: pointer;
`;

type ImageDropZoneProps = {
  dragging: boolean;
};
const ImageDropZone = styled(Flex).attrs<ImageDropZoneProps>(props => {
  return {
    width: "100%",
    background: "grayLightest",
    borderRadius: "default",
    border: props.dragging ? "1px dashed black" : "1px dashed grayLight",
    p: "8px",
    justifyContent: "space-between",
    position: "relative",
  };
})<ImageDropZoneProps>``;

const ThemeImagePicker: React.FC<Props> = ({
  newTheme,
  updateTheme,
  updateFiles,
  themeImageOption,
  sizeRecommendation,
}) => {
  const { showNotification } = useNotifications();

  const onUploadFile = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file) {
      /**
       * URL.createObjectURL
       * This method is the least resource intensive for simply displaying
       * an image that has been uploaded.
       *
       * We'll also keep the raw file data stored in the event that the
       * user decides to add it to their theme.
       */
      updateTheme({ [themeKeyMap[themeImageOption]]: URL.createObjectURL(file) });
      updateFiles({ [themeKeyMap[themeImageOption]]: file });
    } else {
      showNotification({
        level: "error",
        message: "File type must be png or jpg/jpeg.",
      });
    }
  };

  const removeImage = () => {
    updateTheme({ [themeKeyMap[themeImageOption]]: null });
    updateFiles({ [themeKeyMap[themeImageOption]]: null });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    maxFiles: 1,
    onDrop: onUploadFile,
  });

  const themeKeyMap: { [K in SpaceThemeImageOptions]: string } = {
    [SpaceThemeImageOptions.headerBackgroundImage]: "headerBackgroundImage",
    [SpaceThemeImageOptions.headerLogoImage]: "headerLogoImage",
  };

  const themeKey = themeKeyMap[themeImageOption];
  const currentImage = newTheme[themeKey];
  const icon = currentImage ? "content.file" : "action.add";
  const buttonText = isDragActive
    ? "Drop image here"
    : currentImage
      ? "Image added"
      : "Upload an image";

  return (
    <Wrapper {...getRootProps()}>
      <ImageDropZone dragging={isDragActive}>
        <Flex alignItems="center">
          <Flex width="16px">
            <Icon iconId={icon} size="16" fill="grayDarkest" />
          </Flex>
          <Text font="ui.small" color="grayDarkest" ml="6px" truncate>
            {buttonText}
          </Text>
        </Flex>
        {currentImage ? (
          <Button size="small" buttonStyle="tertiary" text="Change" onClick={removeImage} />
        ) : (
          <>
            <Text color={newTheme.primaryColor} font="ui.small">
              Upload
            </Text>
            <input {...getInputProps()} aria-label={`${themeImageOption} input`} />
          </>
        )}
      </ImageDropZone>
      {sizeRecommendation && (
        <Text font="ui.small" color="grayDarker" mt="4px">
          Recommended: {sizeRecommendation}
        </Text>
      )}
    </Wrapper>
  );
};

export default ThemeImagePicker;
