import React, { ComponentProps, MouseEvent } from "react";
import { Asset, Button, Item } from "@thenounproject/lingo-core";

import AssetActionButton from "./AssetActionButton";
import AssetDownloadPopup from "../inspector/use/AssetDownloadPopup";

type Props = {
  item: Item;
  position: "default" | "center";
  selected: boolean;
  hiddenMode: boolean;
};

const InAssetDownload: React.FC<Props> = ({ item, selected, position, hiddenMode }) => {
  const exportsDisabled =
    item.asset.meta.filecuts &&
    item.asset.meta.filecuts.presets.length === 0 &&
    getCustomTypeOptions(item.asset).length === 0;

  function getCustomTypeOptions(asset: Asset) {
    if (!asset.meta.filecuts) return null;
    const { availableTypes } = asset.meta.filecuts;
    return availableTypes.filter(({ enabled }) => enabled);
  }

  function renderDownloadButtonElement(buttonProps: Partial<ComponentProps<typeof Button>>) {
    const sharedProps = {
      id: "asset-download-button",
      className: "action-button",
      icon: "action.download",
      onClick: (e: MouseEvent<HTMLButtonElement>) => {
        if (e && selected) e.stopPropagation();
        buttonProps.onClick(e);
      },
      ...buttonProps,
    };
    return hiddenMode ? (
      <Button {...sharedProps} size="small" buttonStyle="tertiary" />
    ) : (
      <AssetActionButton buttonPosition={position} assetType={item.asset} {...sharedProps} />
    );
  }

  function renderDownloadButton() {
    const popupProps = {
      inspectable: { item, asset: item.asset },
      popupVPos: "alignTop",
      popupHPos: "alignLeft",
    };
    return (
      <AssetDownloadPopup {...popupProps}>
        {({ buttonProps }) => renderDownloadButtonElement(buttonProps)}
      </AssetDownloadPopup>
    );
  }

  return exportsDisabled ? null : renderDownloadButton();
};

export default InAssetDownload;
