import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  file: File;
};
const [useUpdateUserAvatar, updateUserAvatar] = createAsyncAction(
  "user/updateAvatar",
  async ({ file }: Args) => {
    const formData = new FormData();
    if (file) {
      formData.append("avatar", file);
    } else {
      formData.append("reset", "true");
    }
    return await API.call<{ user: number }>({
      endpoint: `me/avatar/`,
      method: "PUT",
      entity: API.Entity.user,
      formData,
    });
  }
);

export default useUpdateUserAvatar;
export { updateUserAvatar };
