import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  spaceId: number;
  userId: number;
};

const [useLeaveSpace, leaveSpace] = createAsyncAction(
  "spaceMembers/leave",
  async ({ spaceId, userId }: Args) => {
    return await API.call<string>({
      endpoint: `spaces/${spaceId}/members/${userId}`,
      method: "DELETE",
      entity: API.Entity.spaceMember,
    });
  }
);

export default useLeaveSpace;
export { leaveSpace };
