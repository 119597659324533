import QueryString from "query-string";
import { Space, API, TagSuggestion, base64 } from "@thenounproject/lingo-core";
import { createQueryAction } from "@actions/actionCreators";

import { SearchQuery } from "@features/library/types";

export type FetchTagsArgs = {
  spaceId: Space["id"];
  value?: string;
};

const buildQueryString = value => {
  const query: SearchQuery = {
    filters: [],
    queries: {
      suggestions: {
        type: "suggestion",
        filter_type: "tag",
        value,
      },
    },
  };
  return QueryString.stringify({
    query: base64.encode(query),
  });
};

const [useTagSuggestions, tagSuggestions] = createQueryAction<
  FetchTagsArgs,
  TagSuggestion[],
  TagSuggestion[]
>(
  {
    entity: "tags",
    action: "fetchTagSuggestions",
    debounce: true,
    denormalize: false,
  },
  async ({ args }) => {
    const queryString = buildQueryString(args.value);
    const res = await API.call<{
      queries: { suggestions: TagSuggestion[] };
    }>({
      endpoint: `search/spaces/${args.spaceId}?${queryString}`,
      method: "GET",
    });
    return { result: res.result.queries.suggestions };
  }
);

export default useTagSuggestions;
export { tagSuggestions };
