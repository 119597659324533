import React, { ComponentProps } from "react";
import styled from "styled-components";

import {
  Text,
  Flex,
  PopupMenu,
  buildPath,
  Icon,
  Box,
  Space,
  Kit,
  KitCollection,
  Button,
  KitCollectionItem,
  Callback,
  useNavigation,
} from "@thenounproject/lingo-core";

import KitCollectionMenuItems from "./KitCollectionMenuItems";

import KitList from "./KitList";

const Overflow = styled.button`
  padding: 0;
  line-height: 0;
`;

const KitListWrapper = styled(Box).attrs({
  width: "100%",
})`
  @media screen and (max-width: 500px) {
    display: none;
  }
`;

const Container = styled(Flex).attrs(() => {
  return {
    borderRadius: "default",
    p: "s",
    style: {
      position: "relative",
      transition: "background .3s ease",
    },
    variations: {
      "mq.xs": {
        p: "m",
      },
    },
  };
})``;
const DetailsContainer = styled(Flex).attrs(() => {
  return {
    p: "xs",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "80px",
  };
})`
  width: 100%;
  @media screen and (min-width: 500px) {
    width: 50%;
    flex-direction: column;
    align-items: flex-start;
  }
  @media screen and (min-width: 768px) {
    width: 38%;
  }
`;
const KitsContainer = styled(Flex)`
  display: none;
  @media screen and (min-width: 500px) {
    width: 50%;
    display: flex;
  }
  @media screen and (min-width: 768px) {
    width: 62%;
  }
`;

const EmptyCollectionContainer = styled(Flex).attrs({
  width: "100%",
  textAlign: "center",
  alignItems: "center",
  flexDirection: "column",
  borderRadius: "default",
  background: "grayLightest",
  justifyContent: "center",
  px: "s",
  minHeight: "138px",
})`
  @media screen and (max-width: 500px) {
    display: none;
  }
`;

type Props = {
  collection: Omit<KitCollection, "items"> & { items: KitCollectionItem[] };
  popupProps: ComponentProps<typeof PopupMenu>;
  setMenuOpen: Callback;
  menuOpen: boolean;
  navigate: ReturnType<typeof useNavigation>;
  space?: Space;
};

const KitCollectionCardClassic: React.FC<Props> = ({
  collection,
  space,
  popupProps,
  menuOpen,
  setMenuOpen,
  navigate,
}) => {
  const kits = collection.items.slice(0, 3).map(item => item.kit as Kit);

  function renderKitsElements() {
    if (kits.length === 0) {
      return (
        <EmptyCollectionContainer>
          <Box width="100%" maxWidth="400px" m="auto">
            <Text font="ui.regularBold" color="grayDarker" mb="xs">
              Add Kits
            </Text>
            <Text color="grayDarker">Open this collection to add Kits.</Text>
          </Box>
        </EmptyCollectionContainer>
      );
    }

    return (
      <KitListWrapper>
        <KitList kits={kits} kitCardSize="compact" gap="16px" columns={3} maxRows={1} />
      </KitListWrapper>
    );
  }

  return (
    <Container>
      <DetailsContainer>
        <Flex flexDirection="column" width="100%">
          <Flex
            alignItems="center"
            variations={{
              "mq.xs": {
                justifyContent: "space-between",
              },
            }}>
            <Text font="marketing.subtitle1" mr="s">
              {collection.name}
            </Text>
            <Overflow
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                setMenuOpen();
              }}
              data-popup-source={collection.uuid}>
              <Icon iconId="navigation.overflow" />
            </Overflow>
          </Flex>
          {menuOpen && (
            <PopupMenu {...popupProps}>
              <KitCollectionMenuItems kitCollectionId={collection.uuid} />
            </PopupMenu>
          )}
          <Text color="grayDarkest" mb="l">
            {collection.counts.kits || 0} Kits
          </Text>
          <Box>
            <Button
              buttonStyle="tertiary"
              text="View collection"
              onClick={() => {
                navigate.push(buildPath(collection.url, { space }));
              }}
            />
          </Box>
        </Flex>
      </DetailsContainer>
      <KitsContainer>{renderKitsElements()}</KitsContainer>
    </Container>
  );
};

export default KitCollectionCardClassic;
