import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

export type LoginSSOArgs = {
  organizationId: string | number;
};

const [useSSOLogin, ssoLogin] = createAsyncAction(
  "auth/ssoLogin",
  async ({ organizationId }: LoginSSOArgs) => {
    return await API.call<{ url: string }>({
      endpoint: `saml/login/${organizationId}`,
      method: "GET",
    });
  }
);

export default useSSOLogin;
export { ssoLogin };
