import { API, Space, AssetView, ErrorCode } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  spaceId: Space["id"];
  viewId: AssetView["id"];
  updates: Pick<AssetView, "name" | "filters">;
};

const [useUpdateView, updateView] = createAsyncAction(
  "views/update",
  async ({ viewId, updates }: Args) => {
    if (updates.name?.trim().length === 0)
      throw {
        code: ErrorCode.invalidParams,
        error: "Name is required",
      };
    return await API.call<string>({
      endpoint: `/views/${viewId}`,
      method: "PUT",
      data: updates,
      entity: API.Entity.assetView,
    });
  }
);

export default useUpdateView;
export { updateView };
