import React from "react";
import { Button } from "@thenounproject/lingo-core";

import InspectorDownloadsDisabled from "./InspectorDownloadsDisabled";
import AssetDownloadPopup from "./AssetDownloadPopup";
import { Inspectable } from "@constants/Inspector";
import { InspectorModes } from "@contexts/ViewModeContext";

type Props = {
  inspectable: Inspectable;
  canEdit: boolean;
  mode: InspectorModes;
};

const InspectorAssetDownload = ({ inspectable, canEdit, mode }: Props) => {
  const { asset } = inspectable;
  const exportsDisabled =
    asset.meta.filecuts &&
    asset.meta.filecuts.presets.length === 0 &&
    getCustomTypeOptions(asset).length === 0;

  function getCustomTypeOptions(asset: Inspectable["asset"]) {
    if (!asset.meta.filecuts) return null;
    const { availableTypes } = asset.meta.filecuts;
    return availableTypes.filter(({ enabled }) => enabled);
  }

  function renderDisabledExports() {
    return <InspectorDownloadsDisabled {...{ inspectable, canEdit, mode }} />;
  }

  function renderDownloadButton({ buttonProps, noCuts }) {
    return (
      <Button
        id="asset-download-button"
        text="Download"
        icon={!noCuts ? "navigation.chevron-down" : "download"}
        size="small"
        fullWidth={true}
        {...buttonProps}
      />
    );
  }

  const popupProps = {
    inspectable,
    canEdit,
    popupVPos: mode === "detailed" ? "floatBelow" : "floatAbove",
    popupHPos: "alignRight",
  };
  return (
    <AssetDownloadPopup {...popupProps}>
      {/* TODO: Get rid of render props */}
      {popupRenderProps =>
        exportsDisabled ? renderDisabledExports() : renderDownloadButton(popupRenderProps)
      }
    </AssetDownloadPopup>
  );
};

export default InspectorAssetDownload;
