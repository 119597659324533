import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

const [useSaveMultipleAssetTags, saveMultipleAssetTags] = createAsyncAction(
  "assets/saveMultipleAssetTags",
  async (updates: { uuid: string; keywords: string }[]) => {
    const response = await API.call<string>({
      endpoint: "sync/assets",
      method: "POST",
      entity: API.Entity.asset,
      data: { assets: updates },
    });
    return response;
  }
);

export default useSaveMultipleAssetTags;
export { saveMultipleAssetTags };
