import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

const [useLogout, logout] = createAsyncAction("auth/logout", async () => {
  await API.call<void>({
    endpoint: "logout/",
    method: "POST",
  });
  return;
});

export default useLogout;
export { logout };
