import { API, Item } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  sectionId: string;
  headingId: Item["id"];
  displayOrder: string; // (after|before):Item["id"]
};

const [useReorderHeading, reorderHeading] = createAsyncAction(
  "items/reorderHeading",
  async ({ headingId, displayOrder }: Args) => {
    return await API.call<{ items: string[] }>({
      endpoint: `headings/${headingId}/reorder`,
      method: "PUT",
      entity: API.Entity.item,
      data: {
        position: displayOrder,
      },
    });
  }
);
export default useReorderHeading;
export { reorderHeading };
