import { ContentType } from "@thenounproject/lingo-core";

export function getFileExtension(fileName: string): string | undefined {
  if (!fileName.includes(".")) return undefined;
  return fileName.split(".").pop().toLowerCase();
}

export function getFileName(fileName: string): { name: string; extension: string | undefined } {
  const extension = getFileExtension(fileName)?.toLowerCase(),
    name = fileName.replace(RegExp(`\\.${extension}$`, "gi"), "");
  return { name, extension };
}

export function typeErrorMessage(types: ContentType[]): string {
  const _types = types.map(t => t.fileExtension);
  const typesStr = [_types.slice(0, -1).join(", "), _types.slice(-1)[0]]
      .filter(i => i)
      .join(" or "),
    article = typesStr.match(/^[aieouAIEOU]/) ? "an" : "a";
  return `The file must be ${article} ${typesStr}.`;
}

export function loadFile(file: File): Promise<string | ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = () => {
      reject(new Error(`Error reading file: ${reader.error}`));
    };
    reader.readAsDataURL(file);
  });
}
