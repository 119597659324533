import React, { useEffect, useMemo } from "react";
import _sortBy from "lodash/sortBy";
import {
  Flex,
  Text,
  utils,
  buildPath,
  SearchFilterInput,
  AssetView,
  Button,
  PopupMenu,
  SpacePermission,
  useNavigation,
} from "@thenounproject/lingo-core";
import _isEqual from "lodash/isEqual";
import styled from "styled-components";

import { useSelectSpace } from "@selectors/entities/spaces";
import { useAssetLibraryContext } from "../../../contexts/AssetLibraryProvider";
import { SearchSortTypes } from "../types";
import { NavPoint, NavPointTypes } from "@redux/legacy-actions/navPoints";

import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import useAssetViewsDelete from "@redux/actions/views/useDeleteView";
import useNotifications from "@actions/useNotifications";
import { useShowCreateEditAssetViewModal } from "../modals/CreateEditAssetViewModal";
import useAssetViews from "@redux/actions/views/useAssetViews";
import { SubNavTitle, SubNavWrapper } from "../../../components/navigation/SubNav";
import useUpsell, { AccessStatus } from "@hooks/useUpsell";

type NavItem = {
  title: string;
  onClick: () => void;
  onDelete?: () => void;
  active: boolean;
  view?: AssetView;
  isPicker?: boolean;
};

const NavButton = styled.button<{ active: boolean }>`
  width: 100%;
  padding: 4px 8px;
  border-radius: 4px;
  transition: all 0.3s ease;
  color: ${utils.getColor("black")};
  text-align: left;
  background: ${props => utils.getColor(props.active ? "grayLight" : "white")};
  position: relative;
  ${props => utils.getFont(props.active ? "ui.regularBold" : "ui.regular", null, props)};

  &:hover {
    background: ${utils.getColor("grayLight")};
  }
`;

const ButtonWrapper = styled.div`
  .overflow {
    opacity: 0;
    transition: all 0.3s ease;
  }
  &:hover {
    .overflow {
      opacity: 1;
    }
  }
`;

export const filtersAreEqual = (a: SearchFilterInput[], b: SearchFilterInput[]) => {
  return _isEqual(a, b);
};

type Props = {
  navPoint: NavPoint;
  // Only shows query presets
  // Other features like tag management are hidden.
  isPicker?: boolean;
};

const NavItemComponent: React.FC<NavItem> = item => {
  const { showModal } = useShowModal();
  const [showMenu, setShowMenu] = React.useState(false);
  const [deleteAssetView] = useAssetViewsDelete();
  const showAssetViewsModal = useShowCreateEditAssetViewModal();
  return (
    <ButtonWrapper
      style={{ position: "relative" }}
      data-testid={item.title.split(" ").join("-").toLowerCase()}>
      <NavButton
        onClick={item.onClick}
        active={item.active}
        data-testid={item.active ? "active-nav-item" : undefined}>
        <Text truncate>{item.title || "Unnamed view"}</Text>
      </NavButton>
      {item.view && !item.isPicker && (
        <>
          <Button
            data-popup-source={String(item.view.id).concat("-overflow")}
            onClick={() => setShowMenu(true)}
            className="overflow"
            icon="navigation.overflow"
            size="small"
            position="absolute"
            themeOverrides={{
              primaryColor: "transparent",
              primaryColorDark: "transparent",
              primaryColorTint: "black",
            }}
            right={0}
            top={2}
          />
          <PopupMenu
            open={showMenu}
            close={() => setShowMenu(false)}
            source={String(item.view.id).concat("-overflow")}>
            <PopupMenu.Item
              title="Rename"
              onClick={() => showAssetViewsModal({ view: item.view })}
            />
            <PopupMenu.Item
              title="Delete"
              onClick={() =>
                showModal(ModalTypes.CONFIRMATION, {
                  title: "Delete View",
                  message: "Are you sure? This will delete the custom view from the sidebar.",
                  buttonText: "Delete",
                  buttonProcessingText: "Deleting...",
                  onConfirm: async () => {
                    const result = await deleteAssetView({
                      spaceId: item.view.spaceId,
                      viewId: item.view.id,
                    });
                    if (!result.error) {
                      item.onDelete?.();
                    }
                    return result;
                  },
                })
              }
            />
          </PopupMenu>
        </>
      )}
    </ButtonWrapper>
  );
};

const NavItemMemo = React.memo(NavItemComponent) as typeof NavItemComponent;

const AssetLibraryNav: React.FC<Props> = ({ navPoint, isPicker = false }) => {
  const navigate = useNavigation();
  const space = useSelectSpace();
  const { appliedFilters, setFilters, setSortBy, activeView, presetViews, activePreset, viewId } =
    useAssetLibraryContext();
  const { data: views = [], isLoading: loadingViews } = useAssetViews({ spaceId: space.id });
  const libraryPath = buildPath("/library/", { space });
  const { showNotification } = useNotifications();
  const isViewingTagsOrFields =
    navPoint?.type === NavPointTypes.TagManagement || navPoint?.type === NavPointTypes.CustomFields;

  useEffect(() => {
    if (loadingViews) return;
    if (!activeView) {
      if (!viewId) return;
      showNotification({ message: "Asset view not found.", level: "error" });
      return;
    }

    if (!filtersAreEqual(appliedFilters, activeView.filters)) {
      setFilters(activeView.filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewId, loadingViews]);

  const presetNavItems: NavItem[] = React.useMemo(() => {
    return (
      presetViews?.map(p => ({
        title: p.name,
        onClick: () => {
          setFilters(p.filters);
          if (!isPicker) {
            navigate.push(libraryPath);
          } else if (viewId) {
            navigate.push(window.location.pathname);
          }
        },
        active: !isViewingTagsOrFields && p.name === activePreset?.name && !activeView,
      })) || []
    );
  }, [
    presetViews,
    isViewingTagsOrFields,
    activePreset?.name,
    activeView,
    setFilters,
    isPicker,
    viewId,
    navigate,
    libraryPath,
  ]);

  const customNavItems: NavItem[] = React.useMemo(() => {
    return (
      views?.map(p => ({
        title: p.name,
        onClick: () => {
          if (isPicker) {
            navigate.push(`${window.location.pathname}?view=${p.id}`);
            return setFilters(p.filters);
          }
          navigate.push(buildPath(`/library`, { space }, { view: p.id }));
        },
        onDelete: () => {
          navigate.push(libraryPath);
          setFilters([]);
        },
        active: p.id === viewId,
        view: p,
        isPicker,
      })) || []
    );
  }, [views, viewId, isPicker, navigate, space, setFilters, libraryPath]);

  const navItems = useMemo(
    () => [
      {
        title: "All assets",
        onClick: () => {
          setFilters([]);
          setSortBy(SearchSortTypes.recentDesc);
          if (!isPicker) {
            navigate.push(libraryPath);
          } else if (viewId) {
            navigate.push(window.location.pathname);
          }
        },
        active: !isViewingTagsOrFields && !activePreset && !activeView,
      },
      ...presetNavItems,
      ...customNavItems,
    ],
    [
      activePreset,
      activeView,
      customNavItems,
      navigate,
      isPicker,
      isViewingTagsOrFields,
      libraryPath,
      presetNavItems,
      setFilters,
      setSortBy,
      viewId,
    ]
  );

  const renderNavItems = () => {
    return navItems.map((f, i) => {
      return <NavItemMemo key={i} {...f} />;
    });
  };

  const renderNavHeading = (text: string) => (
    <Text mt="l" mb="s" font="ui.smallBold" color="grayDark">
      {text}
    </Text>
  );

  const tagManagerAccess =
    useUpsell(SpacePermission.manageTags).accessStatus !== AccessStatus.insufficientRole;
  const customFieldAccess =
    useUpsell(SpacePermission.editFields).accessStatus !== AccessStatus.insufficientRole;

  const shouldSeeMetadataNav = !isPicker && (tagManagerAccess || customFieldAccess);

  return (
    <SubNavWrapper>
      <SubNavTitle>Library</SubNavTitle>
      <Flex
        px="l"
        pb="l"
        flexDirection="column"
        width={"100%"}
        data-inspector-clear="true"
        overflow="auto">
        {renderNavHeading("Assets")}
        <Flex as="nav" flexDirection="column" gap="8px">
          {renderNavItems()}
        </Flex>
        {shouldSeeMetadataNav && (
          <>
            {renderNavHeading("Metadata")}
            <Flex as="nav" width="100%" flexDirection="column" gap="8px">
              {tagManagerAccess && (
                <NavButton
                  key="Tags"
                  active={navPoint?.type === NavPointTypes.TagManagement}
                  onClick={() => navigate.push(buildPath("/library/tags", { space }))}>
                  Tags
                </NavButton>
              )}
              {customFieldAccess && (
                <NavButton
                  key="Custom Fields"
                  active={navPoint?.type === NavPointTypes.CustomFields}
                  onClick={() => navigate.push(buildPath("/library/fields", { space }))}>
                  Custom Fields
                </NavButton>
              )}
            </Flex>
          </>
        )}
      </Flex>
    </SubNavWrapper>
  );
};

export default AssetLibraryNav;
