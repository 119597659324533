import { createAsyncAction } from "../actionCreators";
import { API, AccountCharge } from "@thenounproject/lingo-core";

type Args = {
  chargeId: number;
  spaceId: number;
  receiptExtra?: string;
};

const [useEditInvoice, editInvoice] = createAsyncAction(
  "billing/editInvoice",
  async ({ chargeId, receiptExtra }: Args) => {
    return await API.call<AccountCharge>({
      endpoint: `invoices/${chargeId}/`,
      method: "PUT",
      data: {
        charge_id: chargeId,
        receipt_extra: receiptExtra,
      },
      entity: API.Entity.charge,
    });
  }
);

export default useEditInvoice;
export { editInvoice };
