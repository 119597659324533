import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  portalId: string;
  userId: number;
};

const [useRemovePortalMember, removePortalMember] = createAsyncAction(
  "portalMembers/remove",
  async ({ portalId, userId }: Args) => {
    return await API.call<string>({
      endpoint: `portals/${portalId}/members/${userId}`,
      method: "DELETE",
      entity: API.Entity.portalMember,
    });
  }
);

export default useRemovePortalMember;
export { removePortalMember };
