import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  kitId: string;
  name: string;
};

const [useCreateSection, createSection] = createAsyncAction(
  "sections/create",
  async ({ kitId, name }: Args) => {
    return await API.call<string>({
      endpoint: `/sections`,
      method: "POST",
      data: {
        kit_uuid: kitId,
        name,
      },
      entity: API.Entity.section,
    });
  }
);

export default useCreateSection;
export { createSection };
