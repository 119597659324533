import { createReducer } from "@reduxjs/toolkit";
import {
  dismissNotification,
  Notification,
  showNotification,
} from "@redux/actions/useNotifications";

export default createReducer([] as Notification[], builder => {
  builder
    .addCase(showNotification, (state, action) => {
      state.push(action.payload);
    })
    .addCase(dismissNotification, (state, action) => {
      return state.filter(n => n.id !== action.payload.id);
    });
});
