import React, { useState, useContext, useCallback, useMemo } from "react";

import { isEqual, merge, cloneDeep } from "lodash";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { getTheme, ThemeDataContext } from "./ThemeDataContext";
import { SpaceTheme } from "@thenounproject/lingo-core";
import { useObject } from "@hooks/useObject";
import { NavPoint, NavPointTypes } from "@redux/legacy-actions/navPoints";
import useCustomFavicon from "../components/useCustomFavicon";
import { NewFiles } from "@features/theming/types";
import { parseFilesFromTheme } from "@features/theming/utils/parseFilesFromTheme";
import { useCustomFont } from "@hooks/useCustomFont";

type Props = {
  navPoint: NavPoint;
  children: React.ReactNode;
};

const ThemeProvider: React.FC<Props> = ({ children, navPoint }) => {
  const portal = navPoint?.type === NavPointTypes.Portal ? navPoint?.portal : null;
  const kit = navPoint?.space?.features?.includes("portals") ? navPoint?.kit : null;

  const existingTheme = useMemo(() => {
    return getTheme(navPoint?.space, portal, kit);
  }, [navPoint?.space, portal, kit]);
  const [editingTheme, setEditingTheme] = useState<SpaceTheme>(undefined);

  const theme = editingTheme ?? existingTheme;

  useCustomFavicon(navPoint?.space, navPoint?.portal);
  useCustomFont({
    url: theme?.fonts?.css,
    family: theme?.fonts?.system?.family,
  });

  // Files
  const existingFiles = useMemo(() => {
    return { ...parseFilesFromTheme(existingTheme) };
  }, [existingTheme]);

  const [newFiles, setNewFiles] = React.useState<NewFiles>(cloneDeep(existingFiles));

  const filesChanged = useMemo(() => {
    if (editingTheme) {
      return !isEqual(newFiles, existingFiles);
    }
  }, [editingTheme, newFiles, existingFiles]);

  const updateFiles = React.useCallback(
    (data: NewFiles) => {
      setNewFiles(merge({}, newFiles, data));
    },
    [newFiles]
  );

  const endEditing = useCallback(() => {
      setEditingTheme(undefined);
      setNewFiles(cloneDeep(existingFiles));
    }, [existingFiles]),
    updateEditingTheme = useCallback(
      (updates: Partial<SpaceTheme>) => {
        const start = editingTheme ? {} : theme;
        setEditingTheme(prevState => merge({}, start, prevState, updates));
      },
      [editingTheme, theme]
    );

  const themeChanged = useMemo(() => {
    if (editingTheme) {
      return (
        !isEqual(editingTheme.description, existingTheme.description) ||
        !isEqual(editingTheme.primaryColor, existingTheme.primaryColor) ||
        !isEqual(editingTheme.headerBackgroundColor, existingTheme.headerBackgroundColor) ||
        !isEqual(editingTheme.headerBackgroundImage, existingTheme.headerBackgroundImage) ||
        !isEqual(editingTheme.headerLogoImage, existingTheme.headerLogoImage) ||
        !isEqual(editingTheme.headerTitleColor, existingTheme.headerTitleColor) ||
        !isEqual(editingTheme.headerDescriptionColor, existingTheme.headerDescriptionColor) ||
        !isEqual(editingTheme.headerTitleStyle, existingTheme.headerTitleStyle) ||
        !isEqual(editingTheme.themeName, existingTheme.themeName) ||
        !isEqual(editingTheme.fonts?.system.family, existingTheme.fonts?.system.family) ||
        !isEqual(editingTheme.noteSuccessColor, existingTheme.noteSuccessColor) ||
        !isEqual(editingTheme.noteInfoColor, existingTheme.noteInfoColor) ||
        !isEqual(editingTheme.noteWarningColor, existingTheme.noteWarningColor) ||
        !isEqual(editingTheme.assetLightColor, existingTheme.assetLightColor) ||
        !isEqual(editingTheme.assetDarkColor, existingTheme.assetDarkColor)
      );
    }
  }, [editingTheme, existingTheme]);

  const context = useObject({
    theme,
    existingTheme,
    editingTheme,
    updateEditingTheme,
    endEditing,
    themeChanged: themeChanged || filesChanged,
    newFiles,
    updateFiles,
    filesChanged,
  });

  return (
    <ThemeDataContext.Provider value={context}>
      <StyledThemeProvider theme={editingTheme ?? theme}>{children}</StyledThemeProvider>
    </ThemeDataContext.Provider>
  );
};
export default ThemeProvider;

export const useThemeDataContext = () => useContext(ThemeDataContext);
