import React, { useCallback, useState } from "react";
import { Button, PopupMenu, Flex, BoxProps, FlexProps } from "@thenounproject/lingo-core";

import { useKitContext } from "@contexts/KitProvider";
import { useViewModeContext } from "@contexts/ViewModeProvider";

type Props = {
  styleOverrides?: BoxProps & FlexProps;
};

function GalleryViewToggle({ styleOverrides }: Props) {
  const { kitDisplaySize, setKitDisplaySize } = useKitContext();
  const [showMenu, setShowMenu] = useState(false);
  const { setGalleryAssetViewMode, galleryAssetViewMode } = useViewModeContext();

  const detailedGalleryAssetMode = galleryAssetViewMode === "detailed";

  const handleGalleryAssetModeToggle = useCallback(() => {
    setGalleryAssetViewMode(detailedGalleryAssetMode ? "hidden" : "detailed");
  }, [setGalleryAssetViewMode, detailedGalleryAssetMode]);

  const openMenu = useCallback(() => setShowMenu(true), []);
  const closeMenu = useCallback(() => setShowMenu(false), []);

  function renderMenu() {
    if (!showMenu) return null;
    return (
      <PopupMenu
        source="[data-testid='layout-button']"
        vPos="floatBelow"
        hPos="alignRight"
        close={closeMenu}
        width={220}>
        <PopupMenu.Item title="Adjust Asset Size">
          <PopupMenu.Item
            title="Small"
            checked={kitDisplaySize === "small"}
            onClick={() => setKitDisplaySize("small")}
          />
          <PopupMenu.Item
            title="Medium (default)"
            checked={kitDisplaySize === "medium"}
            onClick={() => setKitDisplaySize("medium")}
          />
          <PopupMenu.Item
            title="Large"
            checked={kitDisplaySize === "large"}
            onClick={() => setKitDisplaySize("large")}
          />
        </PopupMenu.Item>
        <PopupMenu.Item
          onClick={handleGalleryAssetModeToggle}
          title={`${detailedGalleryAssetMode ? "Hide" : "Show"} Asset Details`}
        />
      </PopupMenu>
    );
  }

  return (
    <Flex flexShrink={0} {...styleOverrides}>
      <Button
        ml="s"
        id="layout-button"
        text="Display"
        icon="navigation.chevron-down"
        size="small"
        buttonStyle="secondary"
        onClick={openMenu}
      />
      {renderMenu()}
    </Flex>
  );
}

export default GalleryViewToggle;
