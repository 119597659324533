import { API, SpaceTheme } from "@thenounproject/lingo-core";

import { createAsyncAction } from "../actionCreators";

type ThemeUpdates = {
  noteSuccessColor?: string;
  noteInfoColor?: string;
  noteWarningColor?: string;
  assetLightColor?: string;
  assetDarkColor?: string;
  primaryColor?: string;
  themeName?: string;
  fonts?: SpaceTheme["fonts"];
};
type Args = {
  spaceId: number;
  kitId: string;
  theme: ThemeUpdates;
};

const [useUpdateKitTheme, updateKitTheme] = createAsyncAction(
  "kits/updateTheme",
  async (args: Args) => {
    const theme = {
      note_success_color: args.theme.noteSuccessColor,
      note_info_color: args.theme.noteInfoColor,
      note_warning_color: args.theme.noteWarningColor,
      asset_light_color: args.theme.assetLightColor,
      asset_dark_color: args.theme.assetDarkColor,
      primary_color: args.theme.primaryColor,
      theme_name: args.theme.themeName,
      fonts: args.theme.fonts,
    };

    return await API.call<string>({
      endpoint: `kits/${args.spaceId}/${args.kitId}`,
      method: "PUT",
      data: { theme },
      entity: API.Entity.kit,
    });
  }
);

export default useUpdateKitTheme;
export { updateKitTheme };
