import { API } from "@thenounproject/lingo-core";
import { createAsyncAction } from "../actionCreators";

type Args = {
  spaceId: number;
};

const [useDeleteSpace, deleteSpace] = createAsyncAction(
  "spaces/delete",
  async ({ spaceId }: Args) => {
    return await API.call<number>({
      endpoint: `spaces/${spaceId}`,
      method: "DELETE",
      entity: API.Entity.space,
    });
  }
);

export default useDeleteSpace;
export { deleteSpace };
