import React from "react";
import { noop } from "lodash";
import { Space } from "@thenounproject/lingo-core";

export type Alert = Space["alert"];

export type BannerContextType = {
  activeSpaceAlert: boolean;
  setActiveSpaceAlert?: React.Dispatch<React.SetStateAction<boolean>>;
  alert: Alert;
  setAlert: React.Dispatch<React.SetStateAction<Alert>>;
  dismissAlert: () => void;
};

export const initialState: BannerContextType = {
  activeSpaceAlert: false,
  setActiveSpaceAlert: noop,
  alert: null,
  setAlert: noop,
  dismissAlert: noop,
};

export const BannerContext = React.createContext<BannerContextType>(initialState);

BannerContext.displayName = "BannerContext";
